<template>
    <b-container>
        <div class="confirm-email">
            <h1
                v-if="!isRequestSend"
                class="confirm-email__title"
            >
                Sign Up Complete
            </h1>
            <h1
                v-else
                class="confirm-email__title"
            >
                Email Sent
            </h1>
            <form
                v-if="!isRequestSend"
                class="confirm-email__form"
                @submit.prevent="handleForm"
            >
                <p class="confirm-email__text confirm-email__text--margin confirm-email__text--center">
                    Please check your inbox in order 
                    to confirm your email address
                </p>
                <button class="confirm-email__btn-submit">
                    Resend Confirmation Email
                </button>
            </form>
            <div
                v-else
                class="confirm-email__message"
            >
                <h6 class="confirm-email__text confirm-email__text--center">
                    Please check your inbox to add your email address
                </h6>
                <button
                    v-if="blockSendBtn"
                    class="confirm-email__btn-submit confirm-email__btn-submit--disabled confirm-email__btn-submit--margin"
                >
                    {{ seconds }} sec Countdown
                </button>
                <button
                    v-else
                    class="confirm-email__btn-submit confirm-email__btn-submit--margin"
                    @click="resendConfirmationEmail"
                >
                    Resend confirmation email
                </button>
            </div>
            <div
                v-if="isLoading"
                class="confirm-email__spinner"
            >
                <Spinner />
            </div>
        </div>
    </b-container>
</template>

<script>
import Spinner from '../../components/Spinner';
import { mapGetters } from 'vuex';
import { store } from '@/store';
import { customSuccessNotification, failNotification } from '@/services/notificationService/notificationService';

export default {
    components: {
        Spinner,
    },
    data() {
        return {
            isLoading: false,
            isRequestSend: false,
            secondsOfWaiting: 120,
            seconds: 0,
            blockSendBtn: true,
            timeToRedirectToSingIn: 3000,
        };
    },
    async created() {
        if (!this.$route.params.email) {
            await this.$router.push('/sign-in');
        }
        this.seconds = this.secondsOfWaiting;
    },
    computed: {
        ...mapGetters({
            errors: 'authentication/errors',
        }),
    },
    methods: {
        countdown() {
            setTimeout(() => {
                this.seconds--;

                if (this.seconds === 0) {
                    this.blockSendBtn = false;
                } else {
                    this.countdown();
                }
            }, 1000);
        },
        async resendConfirmationEmail() {
            await this.sendRequest();
            const verifyError = await this.isVerifiedError();
            const timeoutError = await this.isTimeoutError();

            if (!verifyError && !timeoutError) {
                this.blockSendBtn = true;
                this.seconds = this.secondsOfWaiting;
                this.countdown();
            }
        },
        async handleForm() {
            await this.sendRequest();
            const verifyError = await this.isVerifiedError();
            const timeoutError = await this.isTimeoutError();

            if (!verifyError && !timeoutError) {
                this.isRequestSend = true;
                this.countdown();
            }
        },
        async sendRequest() {
            store.commit('authentication/clearErrors');

            if (this.$route.params.email) {
                this.isLoading = true;
                await this.$auth.resendConfirmEmail({ email: this.$route.params.email });
                if (this.isVerifiedError()) {
                    this.customSuccessNotification(this.errors.verifyEmail);
                    setTimeout(async () => {
                        await this.$router.push('/sign-in');
                    }, this.timeToRedirectToSingIn);
                } else if (this.isTimeoutError()) {
                    this.failNotification();
                    console.log(this.errors.timeout);
                }
                this.isLoading = false;
            }
        },
        isVerifiedError() {
            return !!(Object.keys(this.errors).length && this.errors.verifyEmail);
        },
        isTimeoutError() {
            return !!(Object.keys(this.errors).length && this.errors.timeout);
        },
        customSuccessNotification,
        failNotification,
    },
};
</script>

<style lang="scss">
.confirm-email {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    &__alert {
        position: absolute;
    }

    &__title {
        font-family: Raleway, serif;
        font-weight: bold;
        font-size: 72px;
        color: #242424;
        margin: 60px 0;
    }

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 595px;
        margin-bottom: 131px;
    }

    &__message {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__inputs-wrapper {
        margin: 36px 0;
        width: 100%;

        .react-code-input-container {
            width: 100% !important;
        }

        div.react-code-input {
            display: flex;
            justify-content: center;
            width: 100% !important;
        }

        input {
            background: #FFFFFF;
            border: 1px solid #F0F0F0 !important;
            box-sizing: border-box;
            border-radius: 5px !important;
            font-family: Cabin, serif !important;
            font-weight: normal;
            font-size: 14px !important;
            color: #B2B9C4;
            width: 35px !important;
            height: 35px !important;

            &:not(:last-child) {
                margin-right: 10px;
            }

            &:focus {
                outline: none !important;
                border: 2px solid #025FCC !important;
            }
        }
    }

    &__text {
        margin: 0;
        font-family: Cabin, serif;
        font-weight: 500;
        font-size: 18px;
        color: #706969;
        line-height: 150%;

        &--center {
            text-align: center;
        }

        &--margin {
            margin-bottom: 39px;
        }
    }

    &__btn-submit {
        background: #FFB400;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Cabin, serif;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        min-width: 250px;
        margin: 0 0 28px 0;
        transition: 0.3s;

        &:focus {
            border: 2px solid #FFB400;
        }

        &:hover {
            opacity: 0.7;
        }

        &--disabled {
            background-color: #E0E0E0;
            border: 2px solid #BDBDBD !important;
        }

        &--margin {
            margin-top: 40px;
        }
    }
}

@media (max-width: 768px) {
    .confirm-email {
        padding: 0 20px;

        &__title {
            font-size: 40px;
            margin: 30px 0;
            text-align: center;
        }

        &__form {
            margin-bottom: 30px;
        }

        &__btn-submit {
            padding: 10px 0;
            width: 100%;
            margin: 15px 0 28px 0;
        }
    }
}
</style>