<template>
    <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="35"
            cy="35"
            r="35"
            :fill="fillColor"
        />
        <g :opacity="opacity">
            <path
                d="M21 50H49M22.2727 20H47.7273M23.5455 20V50M46.4545 20V50M29.9091 26.25H32.4545M29.9091 31.25H32.4545M29.9091 36.25H32.4545M37.5455 26.25H40.0909M37.5455 31.25H40.0909M37.5455 36.25H40.0909M29.9091 50V44.375C29.9091 43.34 30.7644 42.5 31.8182 42.5H38.1818C39.2356 42.5 40.0909 43.34 40.0909 44.375V50"
                :stroke="strokeColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
    </svg>
</template>
<script>
export default {
    props: {
        state: {
            type: String,
            require: true,
        },
    },
    computed: {
        fillColor() {
            if(this.state === 'active') {
                return '#FF7A08';
            }
            else if(this.state === 'passed') {
                return '#FFD7B5';
            }

            return '#E8E7EF';
            
        },
        strokeColor() {
            if(this.state === 'active' || this.state === 'passed') {
                return '#FFF';
            }
            return '#797E86';
        },
        opacity() {
            if(this.state === 'active' || this.state === 'passed') {
                return 1;
            }
            return 0.4;
        },
    },
};
</script>
