<template>
    <div class="folder_settings__add_folder">
        <GeneralPopup
            ref="EditFolder"
            :title-text="''"
            :popup-id="'EditFolder'"
            :hide-footer="true"
            :hide-header="true"
            :size="'md'"
        >
            <div class="report__create-folder">
                <img
                    :src="closeModal"
                    alt="close modal"
                    class="modal-close"
                    @click="closeEditModal"
                >
                <div class="modal-title">
                    Rename Folder
                </div>
            </div>
            <div class="input_block">
                <input
                    v-model="newFolderName"
                    name="folderName"
                    class="input create-folder__input"
                    :class="{ error_input: errorsIntegration.folderName || errorsJob.folderName || inputError, active_input: checkForActiveInput(newFolderName) }"
                    type="text"
                    @input="handleInput"
                >
                <img
                    v-if="newFolderName.length > 0"
                    class="cross-icon"
                    src="../../assets/images/closeModal_new.svg"
                    alt="cross icon"
                    @click.stop="clearInputAndErrors(['newFolderName'])"
                >
                <img
                    v-if="errorsIntegration.folderName || errorsJob.folderName || inputError"
                    class="cross-icon"
                    src="../../assets/images/closeModal_new_error.svg"
                    alt="cross icon"
                    @click.stop="clearInputAndErrors(['newFolderName', 'inputError'])"
                >
                <label
                    for="folderName"
                    class="create-folder__label"
                    :class="{ active_input: checkForActiveInput(newFolderName) }"
                    :style="{ color: checkForActiveInput(newFolderName) && !inputError ? '#3180F6' : '' }"
                >
                    {{ checkForActiveInput(newFolderName) ? 'Folder Name' : 'Update Folder Name' }}
                </label>
                <span
                    id="folderNameError"
                    class="create-folder__error"
                >
                    <span
                        v-if="inputError"
                        class="folder_settings__error-text"
                    >
                        {{ inputError }}
                    </span>
                </span>
            </div>

            <div class="create-folder__btns">
                <ConfigurablePopupButton
                    :button-text="'Cancel'"
                    :button-type="'cancel'"
                    :icon="false"
                    :disabled="false"
                    @buttonClick="closeEditModal"
                />
                <ConfigurablePopupButton
                    :button-text="'Rename Folder'"
                    :button-type="'create'"
                    :icon="false"
                    :disabled="!checkForActiveInput(newFolderName)"
                    @buttonClick="confirmEditedFolder(newFolderName)"
                />
            </div>
        </GeneralPopup>
    </div>
</template>
<script>

import { store } from '@/store';
import { mapGetters } from 'vuex';
import closeModal from '../../assets/images/closeModal_new.svg';
import GeneralPopup from '../popups/GeneralPopup';
import ConfigurablePopupButton from '../popups/ConfigurablePopupButton';

export default {
    name: 'FolderSettingsPopUp',
    components: {
        GeneralPopup,
        ConfigurablePopupButton,
    },

    props: {
        folder: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            closeModal,
            folderName: '',
            newFolderName: '',
            inputError: '',
        };
    },
    computed: {
        ...mapGetters({
            errorsIntegration: 'integrations/errors',
            errorsJob: 'jobs/errors',
        }),
    },
    watch: {},
    methods: {
        showModal() {
            this.$bvModal.show('general-popupEditFolder');
        },
        closeEditModal() {
            this.$bvModal.hide('general-popupEditFolder');
            this.$emit('toggleFolderSettingsPopUp', true);
            this.clearInputAndErrors(['newFolderName', 'folderName', 'inputError']);
        },
        confirmEditedFolder(editedFolderName) {
            if( editedFolderName === this.folder.name) {
                this.inputError = 'New folder name cannot be the same as the old one';
            } else {
                this.$emit('confirm-edit-folder', editedFolderName);
                this.$bvModal.hide('general-popupEditFolder');
                this.clearInputAndErrors(['newFolderName', 'folderName', 'inputError']);
            }
        },
        clearInputAndErrors(itemsToClear) {
            itemsToClear.forEach(item => {
                this[item] = '';
            });
            store.commit('integrations/clearErrors');
            store.commit('jobs/clearErrors');
        },
        handleInput() {
            this.clearInputAndErrors(['inputError']);
        },
        checkForActiveInput(inputValue) {
            // check if input is not empty and don't have whitespaces
            return inputValue && inputValue.trim().length >= 0;
        },

    },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/fonts.scss";
@import "../../assets/css/variables.scss";

.folder_settings {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1053;

    // Footer (Add folder button)
    &__add_folder {
        width: 80%;
        margin: 0 auto 0;
        padding: 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .add_folder__title {
            @include Aspekta-font(600, 18px, normal, #3180F6);
            letter-spacing: 0.54px;
            margin-left: 15px;
            cursor: pointer;
        }
    }

    // errors
    &__error {
        width: 100%;
        max-width: 831px;
        position: relative;
    }

    &__error-cross {
        position: absolute;
        top: -21px;
        right: 67px;
        cursor: pointer;
        z-index: 3;
    }

    &__error-text {
        @include Aspekta-font(400, 10px, 150%, #FE2027);
        position: absolute;
        top: 0px;
        left: 5px;
        margin: 0;
        display: contents;
    }

    /** end */
}

.label {
    @include Aspekta-font(400, 14px, 140%, #949DB8);
    letter-spacing: 0.28px;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 80%;
    transition: font-size 40ms linear, transform 40ms linear;
    -moz-transition: font-size 40ms linear, transform 40ms linear;
    -webkit-transition: font-size 40ms linear, transform 40ms linear;
    z-index: 1;
}
:active {
    color: #3180F6
}

.activeInput{
    @include Aspekta-font(400, 10px, 140%, #3180F6);
    letter-spacing: 0.28px;
    transition: none !important;
    transform: none !important;
    top: -18px;
    left: 0px;
}


.input_block {
    display: flex;
    flex-direction: column;
    position: relative;

    .cross-icon {
        position: absolute;
        top: 60px;
        right: 20px;
        z-index: 2;
        cursor: pointer;
    }
}

.create-folder__btns {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
}

// End Create Folder Popup



@media (max-width: 800px) {
    .folder_settings {
        &__background {
            min-width: 90%;
            max-width: 90%;
        }
    }

    .create-folder__btns {
        flex-direction: column-reverse;
    }

}
</style>
