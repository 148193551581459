<template>
    <div class="benefits-of-being-partner">
        <div class="title">
            <span class="title__high">{{ TitleHigh }}</span>
            <span class="title__small">{{ TitleSmall }}</span>
        </div>
        <div class="blocks">
            <div class="cards-row">
                <div class="simple-card narrow-card">
                    <div class="simple-card-title narrow-card-title">
                        <div class="simple-card-title-main">
                            <img
                                src="@/assets/images/partner/benefits-icon.svg"
                                alt="icon"
                            >
                            <span class="simple-card-title-main__text">
                                {{ cardText_1 }}
                            </span>
                        </div>

                        <span class="simple-card-title__description narrow-card-text">
                            {{ cardDescription_1 }}
                        </span>
                    </div>
                    <img
                        class="vector__access"
                        src="@/assets/images/partner/access_vector.svg"
                        alt="vector"
                    >
                </div>
                <div class="simple-card narrow-card">
                    <div class="simple-card-title narrow-card-title">
                        <div class="simple-card-title-main">
                            <img
                                class="simple-card-title-main__icon"
                                src="@/assets/images/partner/benefits-icon.svg"
                                alt="icon"
                            >
                            <span class="simple-card-title-main__text">
                                {{ cardText_2 }}
                            </span>
                        </div>

                        <span class="simple-card-title__description narrow-card-text">
                            {{ cardDescription_2 }}
                        </span>
                    </div>
                    <img
                        class="vector__marketing"
                        src="@/assets/images/partner/marketing_vector.svg"
                        alt="vector"
                    >
                </div>
            </div>
            <div class="simple-card wide-card">
                <div class="simple-card-title wide-card-title">
                    <div class="simple-card-title-main">
                        <img
                            class="simple-card-title-main__icon"
                            src="@/assets/images/partner/benefits-icon.svg"
                            alt="icon"
                        >
                        <span class="simple-card-title-main__text">
                            {{ cardText_3 }}
                        </span>
                    </div>
                    <span class="simple-card-title__description">
                        {{ cardDescription_3 }}
                    </span>
                </div>
                <img
                    class="vector__training"
                    src="@/assets/images/partner/training_vector.svg"
                    alt="vector"
                >
            </div>
            <div class="cards-row">
                <div class="simple-card narrow-card">
                    <div class="simple-card-title narrow-card-title">
                        <div class="simple-card-title-main">
                            <img
                                src="@/assets/images/partner/benefits-icon.svg"
                                alt="icon"
                            >
                            <span class="simple-card-title-main__text">
                                {{ cardText_4 }}
                            </span>
                        </div>

                        <span class="simple-card-title__description">
                            {{ cardDescription_4 }}
                        </span>
                    </div>
                    <img
                        class="vector__flexible"
                        src="@/assets/images/partner/flexible_vector.svg"
                        alt="vector"
                    >
                </div>
                <div class="simple-card narrow-card">
                    <div class="simple-card-title narrow-card-title">
                        <div class="simple-card-title-main">
                            <img
                                class="simple-card-title-main__icon"
                                src="@/assets/images/partner/benefits-icon.svg"
                                alt="icon"
                            >
                            <span class="simple-card-title-main__text">
                                {{ cardText_5 }}
                            </span>
                        </div>

                        <span class="simple-card-title__description">
                            {{ cardDescription_5 }}
                        </span>
                    </div>
                    <img
                        class="vector__long-term"
                        src="@/assets/images/partner/long-term_vector.svg"
                        alt="vector"
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    components: {  },
    data() {
        return {
            windowWidth: window.innerWidth,
            TitleHigh: 'Benefits of Being a Partner',
            TitleSmall: 'Unleash Revenue Opportunities',
            cardText_1: 'Access to Cutting-Edge Technology',
            cardDescription_1: `Partners gain access to our vulnerability scanning technology,
            enabling them to offer top-notch solution on any market`,
            cardText_2: 'Marketing Support',
            cardDescription_2: `Leverage our marketing resources,
            co-branded materials, and support to drive lead generation and brand awareness`,
            cardText_3: 'Training and Onboarding',
            cardDescription_3: `Access comprehensive training and onboarding programs that equip
            you with the knowledge and tools to excel as a partner`,
            cardText_4: 'Flexible Partnership Models',
            cardDescription_4: `Choose from flexible partnership models that align with your
            business strategy and goals.`,
            cardText_5: 'Long-Term Partnership',
            cardDescription_5: `Build a lasting and mutually beneficial partnership with us
            that drives success for years to come`,

        };
    },
    computed: {
        isMobile() {
            return this.windowWidth < 768;
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },

};
</script>
<style lang="scss" scoped>
@import "../../assets/css/variables.scss";
@import "../../assets/css/variables/variablesForBenefitsOfBeingPartner.scss";
@import "../../assets/css/fonts";


.benefits-of-being-partner {
    width: 100vw;
    background: var(--dark-blue, #021631);
    display: flex;
    flex-direction: column;
    padding: 160px $dynamic-benefits-padding-l-r ;
}
.title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
    &__high {
        @include Aspekta-font(700, $dynamic-benefits-title-high-font-size, normal, #FFF);
    }
    &__small {
        @include Aspekta-font(400, $dynamic-benefits-title-small-font-size, normal, #FFF);
        opacity: 0.5;

    }
}
.blocks {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.cards-row {
    display: flex;
    gap: 20px;
}

.simple-card {
    display: flex;
    border-radius: 40px;
    background: rgba(167, 180, 205, 0.05);
    justify-content: space-between;
    max-height: 390px;
    min-height: 360px;
    
    &-title {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &-main {
            display: flex;
            width: max-content;
            gap: 11px;

            &__text {
                @include Aspekta-font(700, $dynamic-card-title-font-size, normal, #FFF);

            }
        }
        &__description {
            @include Aspekta-font(400, $dynamic-card-description-font-size, normal, #FFF);
            opacity: 0.5;
            text-align: start;
        }
    }
}
.narrow-card {
    padding: $dynamic-narrow-card-padding 0 0 $dynamic-narrow-card-padding;
    width: 50%;

    &-title {
        width: 50%;
    }

    &-text {
        width: 120%;
    }

}
.wide-card {
    padding: 0 85px 0 80px;

    &-title {
        align-self: center;
        width: 45%;
    }
}
.vector {

    &__access {
        padding: 0 20px 0 10px;
        opacity: 0.6;
        width: 49%;
        margin-top: $dynamic-vector-access-margin-top;
    }

    &__marketing {
        padding: 0 10px ;
        width: 56%;
        margin-top: $dynamic-vector-marketing-margin-top;
    }

    &__training{
        opacity: 0.6;
        width: 50%;
    }

    &__flexible {
        width: 50%;
        align-items: baseline;
        margin-top: $dynamic-vector-flexible-margin-top;
    }
    
    &__long-term {
        padding: 0 10px;
        width: 50%;
        align-items: baseline;
        margin-top: $dynamic-vector-long_term-margin-top;
    }
}

@media (max-width: 1170px) {
    .narrow-card {
        flex-direction: column;
        align-items: center;
        padding: 60px 20px 0 20px;
        width: 80%;

        &-title {
            width: 80%
        }

        &-text {
            width: 110%;
        }
    }
    .simple-card-title-main {
       width: 100%;
    }

    .vector {
        &__long-term {
            padding-top: 10px;
            margin-top: 0;
        }

        &__flexible {
            padding-top: 10px;
            margin-top: 0;
        }

        &__access {
            margin-top: 0;
        }

        &__marketing {
            margin-top: 0;
        }

        &__training {
            margin-top: 0;
        }
    }
}



@media (max-width: 768px) {

    .blocks {
        align-items: center;
    }

    .cards-row {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .simple-card {
        flex-direction: column;
        align-items: center;
        width: 80%;
    }
    .wide-card {
        padding: 60px 20px 10px 20px;

        &-title {
            width: 80%;
        }
    }

    .title {
        flex-direction: column;
    }
    
}


</style>
