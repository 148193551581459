var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"integration-history",attrs:{"id":"integrationHistory"}},[(_vm.historyScans && _vm.historyScans.count)?_c('div',{staticClass:"integration-history__container"},[(_vm.windowWidth > _vm.mediaWidthForCheckScreen)?_c('div',{staticClass:"integration-history__block-first"},[_vm._m(0),_vm._l((_vm.historyScans.rows),function(item,index){return _c('div',{key:index,staticClass:"integration-history__list"},[(_vm.checkStatusIntegration(item.status))?_c('div',{staticClass:"integration-history__list--wrapper",class:{'integration-history__list--wrapper-active': _vm.isActiveItemId === item.id},on:{"click":function($event){return _vm.showIntegrationVersions(item.id)}}},[_c('div',{staticClass:"integration-history__list-item integration-history__list-item--first"},[_c('img',{attrs:{"id":item.id,"alt":"","src":item.signedUrl ? item.signedUrl : _vm.errorLoaded},on:{"error":_vm.errorLoaded}}),_c('p',[_vm._v(_vm._s(_vm.checkAndUpdatePlatformText(item.platform)))])]),_c('div',{staticClass:"integration-history__list-item integration-history__list-item--second-small",on:{"click":function($event){return _vm.showIntegrationVersions(item.id)}}},[_c('p',[_vm._v(_vm._s(item.integrationName))])]),_c('div',{staticClass:"integration-history__list-item integration-history__list-item--second"},[_c('p',{attrs:{"title":item.integrationName}},[_vm._v(" "+_vm._s(item.integrationName)+" ")]),_c('div',{staticClass:"integration-history__list-item--second-block-info"},[(item.appId)?_c('p',{staticClass:"integration-history__list-item--second-block-info-title",attrs:{"title":item.appId}},[_vm._v(" "+_vm._s(item.appId)+" ")]):_c('p',{staticClass:"integration-history__list-item--second-block-info-title"},[_vm._v(" - ")]),_c('p',{staticClass:"integration-history__integ-status",class:{
                                    'integration-history__deleted-status': _vm.getIntegrationStatus(item.status) === 'Deleted',
                                    'integration-history__expired-status': _vm.getIntegrationStatus(item.status) === 'Expired',
                                }},[_vm._v(" "+_vm._s(_vm.getIntegrationStatus(item.status))+" ")]),_c('img',{staticClass:"integration-history__list-item--arrow",class:{'integration-history__list-item--arrow-active': _vm.isActiveItemId === item.id},attrs:{"src":require("../../assets/images/vulnerability-show-version.svg"),"alt":""}})])])]):_c('div',{staticClass:"integration-history__list--wrapper-disabled",class:{'integration-history__list--wrapper-active': _vm.isActiveItemId === item.id}},[_c('div',{staticClass:"integration-history__list-item integration-history__list-item--first"},[_c('img',{attrs:{"id":item.id,"src":item.signedUrl ? item.signedUrl : _vm.errorLoaded,"alt":""},on:{"error":_vm.errorLoaded}}),_c('p',[_vm._v(_vm._s(_vm.checkAndUpdatePlatformText(item.platform)))])]),_c('div',{staticClass:"integration-history__list-item integration-history__list-item--second-small",on:{"click":function($event){return _vm.showIntegrationVersions(item.id)}}},[_c('p',[_vm._v(_vm._s(item.integrationName))])]),_c('div',{staticClass:"integration-history__list-item integration-history__list-item--second"},[_c('p',{attrs:{"title":item.integrationName}},[_vm._v(" "+_vm._s(item.integrationName)+" ")]),_c('div',{staticClass:"integration-history__list-item--second-block-info"},[(item.appId)?_c('p',{staticClass:"integration-history__list-item--second-block-info-title",attrs:{"title":item.appId}},[_vm._v(" "+_vm._s(item.appId)+" ")]):_c('p',[_vm._v(" - ")]),_c('p',{staticClass:"integration-history__integ-status",class:{
                                    'integration-history__deleted-status': _vm.getIntegrationStatus(item.status) === 'Deleted',
                                    'integration-history__expired-status': _vm.getIntegrationStatus(item.status) === 'Expired',
                                }},[_vm._v(" "+_vm._s(_vm.getIntegrationStatus(item.status))+" ")]),_c('img',{staticClass:"integration-history__list-item--arrow",class:{'integration-history__list-item--arrow-active': _vm.isActiveItemId === item.id},attrs:{"src":require("../../assets/images/vulnerability-show-version.svg"),"alt":""}})])])])])}),_c('Pagination',{staticClass:"integration-history__pagination",attrs:{"count":_vm.historyScans.count,"limit":_vm.limit,"current-page":_vm.currentPage,"show-spinner":_vm.showSpinner},on:{"pageChanged":_vm.updatePage}})],2):_vm._e(),(_vm.windowWidth < _vm.mediaWidthForCheckScreen)?_c('div',{staticClass:"integration-history__block-first-mobile"},[_vm._m(1),_vm._l((_vm.historyScans.rows),function(item,index){return _c('div',{key:index,staticClass:"integration-history__list"},[(_vm.checkStatusIntegration(item.status))?_c('router-link',{staticClass:"integration-history__mobilel-version-link",attrs:{"to":{ path: `${_vm.makeLinkToAnchorSettings(`integration/${item.id}`)}`}}},[_c('div',{staticClass:"integration-history__list--wrapper"},[_c('div',{staticClass:"integration-history__list-item integration-history__list-item--first"},[_c('img',{attrs:{"id":item.id,"src":item.signedUrl ? item.signedUrl : _vm.errorLoaded,"alt":""},on:{"error":_vm.errorLoaded}}),_c('p',[_vm._v(_vm._s(_vm.checkAndUpdatePlatformText(item.platform)))])]),_c('div',{staticClass:"integration-history__list-item integration-history__list-item--second-small"},[_c('p',[_vm._v(_vm._s(item.integrationName))])]),_c('p',{staticClass:"integration-history__list-item integration-history__list-item--third",class:{
                                'integration-history__deleted-status': _vm.getIntegrationStatus(item.status) === 'Deleted',
                                'integration-history__expired-status': _vm.getIntegrationStatus(item.status) === 'Expired',
                            }},[_vm._v(" "+_vm._s(_vm.getIntegrationStatus(item.status))+" ")])])]):_c('div',{staticClass:"integration-history__list--wrapper integration-history__mobilel-version-link-disabled"},[_c('div',{staticClass:"integration-history__list-item integration-history__list-item--first"},[_c('img',{attrs:{"id":item.id,"src":item.signedUrl ? item.signedUrl : _vm.errorLoaded,"alt":""},on:{"error":_vm.errorLoaded}})]),_c('div',{staticClass:"integration-history__list-item integration-history__list-item--second-small"},[_c('p',[_vm._v(_vm._s(item.integrationName))])]),_c('p',{staticClass:"integration-history__list-item integration-history__list-item--third",class:{
                            'integration-history__deleted-status': _vm.getIntegrationStatus(item.status) === 'Deleted',
                            'integration-history__expired-status': _vm.getIntegrationStatus(item.status) === 'Expired',
                        }},[_vm._v(" "+_vm._s(_vm.getIntegrationStatus(item.status))+" ")])])],1)}),_c('Pagination',{staticClass:"integration-history__pagination",attrs:{"count":_vm.historyScans.count,"limit":_vm.limit,"current-page":_vm.currentPage,"show-spinner":_vm.showSpinner},on:{"pageChanged":_vm.updatePage}})],2):_vm._e(),(_vm.windowWidth > _vm.mediaWidthForCheckScreen && _vm.checkStatusIntegration(_vm.activeIntegrationStatus))?_c('div',{staticClass:"integration-history__block-second"},[_vm._m(2),(!_vm.checkStatusIntegration(_vm.activeIntegrationStatus))?_c('div'):(_vm.checkStatusIntegration(_vm.activeIntegrationStatus) && !_vm.integrationVersionsList.length)?_c('p',{staticClass:"empty-versions-message"},[_vm._v(" No version has been uploaded yet ")]):_c('div',{staticClass:"integration-history__versions-list--wrapper",attrs:{"id":"versionsList"}},[_c('div',{staticClass:"integration-history__versions-list--wrapper-scroll"},_vm._l((_vm.integrationVersionsList),function(version,index){return _c('div',{key:index,staticClass:"integration-history__versions-list"},[_c('div',{staticClass:"integration-history__versions-list--first"},[(version.version)?_c('p',[_vm._v(" "+_vm._s(version.version)+" ")]):_c('p',[_vm._v(" - ")])]),_c('div',{staticClass:"integration-history__versions-list--second"},[(!_vm.isVersionInProgress(version.status) || version.isDeleted)?_c('p',{style:(_vm.isLessThenOneMinuteTime(version.completionTime)? 'color: #61D4B3;' : '')},[_vm._v(" "+_vm._s(_vm.createDateView(version.completionTime)))]):_c('p',[_vm._v(" - ")])]),_c('div',{staticClass:"integration-history__versions-list--third"},[(_vm.integrationVersionsList.length > 0)?_c('p',{class:{
                                    'integration-history__deleted-status': _vm.getVersionStatus(version.status, version.isDeleted) === 'Deleted',
                                    'integration-history__expired-status': _vm.getVersionStatus(version.status, version.isDeleted) === 'Failed',
                                }},[_vm._v(" "+_vm._s(_vm.getVersionStatus(version.status, version.isDeleted))+" ")]):_c('p',[_vm._v(" - ")])])])}),0)])]):_vm._e()]):_vm._e(),(_vm.showSpinner)?_c('div',{staticClass:"integration-history__spinner"},[_c('Spinner')],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"integration-history__header"},[_c('div',{staticClass:"integration-history__list-item--first"}),_c('div',{staticClass:"integration-history__list-item--second"},[_c('p',[_vm._v("Integration")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"integration-history__header"},[_c('div',{staticClass:"integration-history__list-item--first"}),_c('div',{staticClass:"integration-history__list-item--second"},[_c('p',[_vm._v("App name")])]),_c('div',{staticClass:"integration-history__list-item--third"},[_c('p',[_vm._v("Status")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"integration-history__header"},[_c('div',{staticClass:"integration-history__list-item--versions-block"},[_c('div',{staticClass:"integration-history__list-item--third"},[_c('p',[_vm._v("Version")])]),_c('div',{staticClass:"integration-history__list-item--fourth"},[_c('p',[_vm._v("Scan Completion Time")])]),_c('div',{staticClass:"integration-history__list-item--fifth"},[_c('p',[_vm._v("Version Status")])])])])
}]

export { render, staticRenderFns }