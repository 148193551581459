<template>
    <div>
        <form id="card-form" class="card">
            <b-row>
                <b-col class="col-6">
                    <img class="card-chip" src="../assets/images/card/chip.svg" alt="card">
                </b-col>
                <!-- show card brand icon - uncomment when new card design created -->
                <!-- <b-col class="col-6">
                    <img v-if="brand" class="card-brand" :src="brand" alt="card">
                </b-col> -->
            </b-row>
            <b-row class="card-number-block">
                <b-col class="col-12">
                    <div id="card-number" tabindex="-1" ref="card-number"/>
                </b-col>
            </b-row>
            <b-row class="card-data-block">
                <b-col class="col-4 card-info">
                    <div class="card-text cabin">Expiry</div>
                    <div id="card-expiry" tabindex="-1" ref="card-expiry"/>
                </b-col>
                <b-col class="col-3 card-info">
                    <div class="card-text cabin">CVV</div>
                    <div id="card-cvc" tabindex="-1" ref="card-cvc"/>
                </b-col>
                <b-col v-if="saveCard !== null" class="col-5">
                    <label for="save-card" class="save-card-label">
                        Save card
                        <input type="checkbox" id="save-card" v-model="isSaveCard"/>
                        <span class="checkmark"></span>
                    </label>
                </b-col>
            </b-row>
        </form>
        <div id="card-errors" role="alert"/>
    </div>
</template>
<script>
import {getBankIcon} from '@/utils/helper-functions.js'

    export default {
        props: {
            cardElement: {
                type: Object,
                default: null
            },
            elements: {
                type: Object,
                default: null
            },
            saveCard: {
                type: Boolean,
                default: null,
            }
        },
        data() {
            return {
                cardCommonStyle: {
                    base: {
                        iconColor: '#c4f0ff',
                        color: 'white',
                        fontWeight: 500,
                        fontFamily: 'Cabin, sans-serif',
                        letterSpacing: '0.24em',
                        textTransform: 'uppercase',
                        // float: 'center',
                        fontSmoothing: 'antialiased',
                        ':-webkit-autofill': {
                            color: 'white',
                        },
                        '::placeholder': {
                            color: 'white',
                        },
                    },
                    invalid: {
                        iconColor: '#FB8D62',
                        color: '#FB8D62',
                    },
                },
                newCard: {},
                brand: null,
                isSaveCard: this.saveCard,
            }
        },
        mounted() {
            this.initAddCardForm()
        },
        beforeDestroy() {
            this.$emit('on-change', false)
            this.newCard = {}
        },
        watch: {
            isSaveCard(newVal) {
                this.$emit('save-card', newVal)
            }
        },
        methods: {
            initAddCardForm() {
                let cardNumberStyle = Object.assign(this.cardCommonStyle, {})
                cardNumberStyle.base.fontSize = '18px'
                cardNumberStyle.base.textAlign = 'center'

                this.cardElement.cardNumber = this.initElement(this.elements, 'cardNumber', '#card-number', {
                    placeholder: '•••• •••• •••• ••••',
                    style: cardNumberStyle
                })
                let cardParamsStyle = Object.assign(this.cardCommonStyle, {})
                cardParamsStyle.base.fontSize = '14px'

                this.cardElement.cardExpiry = this.initElement(this.elements, 'cardExpiry', '#card-expiry', {
                    placeholder: '00/00',
                    style: cardParamsStyle
                })
                this.cardElement.cardCvc = this.initElement(this.elements, 'cardCvc', '#card-cvc', {
                    placeholder: '***',
                    style: cardParamsStyle
                })
            },
            initElement(stripeElements, type, id, options) {
                const element = stripeElements.create(type, options)
                element.mount(id)
                element.addEventListener('change', (data) => {
                    const {elementType, complete, brand} = data
                    this.newCard[elementType] = complete
                    if (this.newCard.cardNumber && this.newCard.cardExpiry && this.newCard.cardCvc) {
                        this.$emit('on-change', true)
                    } else {
                        this.$emit('on-change', false)
                    }
                    // show card brand icon - uncomment when new card design created
                    // this.brand = brand !== 'unknown' ? getBankIcon(brand) : null

                    // todo card error
                    // const displayError = document.getElementById('card-errors')
                    // if (error) {
                    //     displayError.textContent = error.message
                    // } else {
                    //     displayError.textContent = ''
                    // }
                })
                return element
            },
            clearNewCard() {
                this.cardElement.cardNumber.clear()
                this.cardElement.cardCvc.clear()
                this.cardElement.cardExpiry.clear()
                this.$emit('on-change', false)
                this.newCard = {}
            },
            destroyElements() {
                this.cardElement.cardNumber.destroy()
                this.cardElement.cardCvc.destroy()
                this.cardElement.cardExpiry.destroy()
            },
        }
    }
</script>
<style lang="scss">
@import "@/assets/css/variables";

.save-card-label {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 8px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    #save-card {
        position: absolute;
        opacity: 0;
    }
    .checkmark {
        right: 0;
        height: 14px;
        width: 14px;
        border: 1px solid #fff;
        border-radius: 5px;
        position: relative;
    }
    .checkmark::after {
        content: "";
        position: absolute;
        left: -6px;
        bottom: 5px;
        height: 2px;
        width: 7px;
        border-radius: 2px;
        transform: translateX(10px) rotate(-45deg);
        background-color: $ghost-text;
        display: none;
    }
    .checkmark::before {
        content: "";
        position: absolute;
        left: -7px;
        top: 5px;
        height: 5px;
        width: 2px;
        border-radius: 2px 2px 0 0;
        background-color: $ghost-text;
        transform: translateX(10px) rotate(-45deg);
        display: none;
    }
    #save-card:checked ~ .checkmark {
        background-color: #fff;
    }
    #save-card:checked ~ .checkmark::after,
    #save-card:checked ~ .checkmark::before {
        display: block;
    }
}
    .InputElement {
        user-focus: normal;
    }
    .card {
        width: 325px !important;
        height: 182px;
        background: linear-gradient(119.41deg, #FFB400 0%, #FFD05F 27.26%, #FFB400 100%);
        border-radius: 10px;

        .card-chip {
            width: 31.56px;
            height: 24.92px;
            margin-top: 25px;
            margin-left: 31px;
        }
        .card-brand {
            width: 30px;
            height: 30px;
            margin-top: 15px;
            margin-left: 60px;
        }

        .card-provider {
            float: right;
            margin-top: 25px;
            margin-right: 36px;
        }

        .card-number-block {
            padding: 25px 15px;
            margin: 0;
        }

        .card-data-block {
            padding: 10px 16px;
            margin: 0;

            .card-text {
                font-size: 12px;
                text-transform: uppercase;
                color: #FFEEC7;
                text-align: center;
            }
        }
        .card-info {
            padding: 0 14px;
        }
    }

    @media (max-width: 500px) {
        .card {
            .card-number-block {
                padding: 25px 0;
            }

            .card-info {
                padding: 0;
            }
        }
    }

</style>
