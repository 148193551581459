<template>
    <div class="new-version-integration">
        <div v-if="integration.showPage">
            <p class="new-version-integration__platform">
                Platform:<span class="new-version-integration__platform-value">{{ checkAndUpdatePlatformText(integration.platform) }}</span>
            </p>
            <p class="inner-integration-header__platform">
                Integration ID:<span class="inner-integration-header__platform-value">{{ integration.id }}</span>
            </p>
            <div class="new-version-integration__wrapper">
                <img
                    id="integration_logo"
                    class="new-version-integration__icon"
                    alt=""
                    :src="integration.signedUrl ? integration.signedUrl : DefaultLogo"
                    @load="isShowSpinner = false"
                    @error="errorLoaded"
                >
                <div class="inner-integration-header__box">
                    <h1 class="new-version-integration__file-name">
                        {{ integration.integrationName }}
                    </h1>
                    <p class="inner-integration-header__text">
                        {{ integration.integrationDescription }}
                    </p>
                </div>
            </div>
            <div
                v-if="!isFileUploaded && !isVersionInProgressToggle && showDropZone "
                class="dropzone__wrapper"
            >
                <div
                    v-if="progressBar"
                    class="dropzone-disable"
                />
                <vue-dropzone
                    id="dropzoneNewIntegrationVersion"
                    ref="fileDropzoneNewIntegrationVersion"
                    :class="['dropzone-block']"
                    :options="dropzoneOptions"
                    :destroy-dropzone="true"
                    :duplicate-check="true"
                    :include-styling="false"
                    :use-custom-slot="true"
                    @vdropzone-drop="onFileDrop"
                    @vdropzone-duplicate-file="onDuplicateFileUpload"
                    @vdropzone-sending="sendFile"
                    @vdropzone-upload-progress="uploadProgress"
                    @vdropzone-total-upload-progress="totalUploadProgress"
                    @vdropzone-complete="onComplete"
                >
                    <div>
                        <div
                            v-if="!progressBar > 0"
                            class="new-version-integration__dz-alert"
                        >
                            1 GB max per file
                        </div>
                        <div
                            v-if="!progressBar > 0"
                            class="dropzone-custom-content"
                        >
                            <div
                                id="upload-clickable"
                                class="new-version-integration__svg"
                            >
                                <img
                                    src="../../assets/images/new-scan-icon-dropzone.svg"
                                    alt=""
                                >
                            </div>
                            <div class="new-version-integration__select-branch">
                                <p class="new-version-integration__select-branch__title-text">
                                    Upload New Version
                                </p>
                                <div class="new-version-integration__select-branch__dropdown">
                                    <DropDown
                                        :options="branches"
                                        :show-label="true"
                                        :value="branchId"
                                        name="branches"
                                        label="Select Branch..."
                                        empty-option-label="Select branch"
                                        @changeBranch="changeBranch"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="integration.platform === 'android'"
                                id="upload-clickable"
                            >
                                <div class="new-version-integration__dz-text">
                                    Drag & Drop files
                                </div>
                                <div class="dropzone-custom-block">
                                    <p class="dropzone-custom-platform-text">
                                        Available for Android:
                                    </p>
                                    <span class="dropzone-custom-platform-sign">.apk</span>
                                    <span class="dropzone-custom-platform-sign">.aab</span>
                                </div>
                            </div>
                            <div
                                v-if="integration.platform === 'ios'"
                                id="upload-clickable"
                            >
                                <div class="new-version-integration__dz-text">
                                    Drag & Drop files
                                </div>
                                <div class="dropzone-custom-block">
                                    <p class="dropzone-custom-platform-text">
                                        Available for iOS:
                                    </p>
                                    <span class="dropzone-custom-platform-sign">.zip</span>
                                    <span class="dropzone-custom-platform-sign-description">(zipped Xcode project)</span>
                                </div>
                            </div>
                            <div
                                id="upload-clickable"
                                class="new-version-integration__dz-text__secondary"
                            >
                                or
                            </div>
                            <div
                                id="upload-clickable"
                                class="new-version-integration__upload-btn"
                            >
                                <ConfigurablePopupButton
                                    :button-text="'Upload File'"
                                    :button-type="'upload'"
                                    :icon="'upload-file'"
                                    :disabled="false"
                                    @buttonClick="() => {}"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="new-version-integration__spinner-dropzone">
                        <Spinner />
                    </div>
                </vue-dropzone>
            </div>
            <h3
                v-if="isVersionInProgressToggle"
                class="new-version-integration__version-in-progress"
            >
                You already have a scanning version. Wait until it's finished or delete it.
            </h3>
            <div
                v-if="isFileUploaded"
                class="new-version-integration__result"
            >
                <div class="new-version-integration__result-file-name">
                    {{ file }}
                </div>
                <div v-if="!loadScan">
                    <div class="new-version-integration__result-btns">
                        <ConfigurablePopupButton
                            :button-text="'Scan'"
                            :button-type="'add'"
                            :icon="false"
                            :disabled="false"
                            @buttonClick="createVersionScan"
                        />
                        <ConfigurablePopupButton
                            :button-text="'Delete'"
                            :button-type="'delete'"
                            :icon="true"
                            :disabled="false"
                            @buttonClick="cleanDropZone"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <PageNotFound />
        </div>
        <div
            v-if="loadScan"
            class="new-version-integration__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import DropDown from '../innerIntegration/modalWindow/dropDownModal';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { onDuplicateFileUpload, template } from '../../services/newScan/newScanFunctions';
import { mapGetters } from 'vuex';
import { VersionStatusEnum } from '@/utils/constants/common';
import PageNotFound from '../../components/pageNotFound/PageNotFound';
import Spinner from '../../components/Spinner';
import DefaultLogo from '../../assets/images/default-integration-icon.png';
import { checkAndUpdatePlatformText } from '../../services/functions';
import { validateFile, sanitizeFileName } from '../../services/fileService';
import { failNotification } from '@/services/notificationService/notificationService';
import ConfigurablePopupButton from '../popups/ConfigurablePopupButton';

export default {
    components: {
        vueDropzone: vue2Dropzone,
        ConfigurablePopupButton,
        DropDown,
        PageNotFound,
        Spinner,
    },
    data() {
        let me = this;
        return {
            DefaultLogo,
            isShowSpinner: false,
            loadListFiles: [],
            isVersionInProgressToggle: false,
            fileUpload: {},
            file: null,
            isFileUploaded: false,
            filesToProcess: 0,
            isInvalidFiles: false,
            isFileBroken: false,
            invalidFiles: [],
            validFilesCount: 0,
            invalidFilesCount: 0,
            progressBar: 0,
            loadScan: false,
            showDropZone: false,
            branchName: null,
            branchId: null,
            branches: [],
            dropzoneOptions: {
                url: '/',
                method: 'put',
                thumbnailWidth: 150,
                maxFilesize: 1024,
                acceptedFiles: '.apk, .aab, .zip',
                parallelUploads: 1,
                clickable: '#upload-clickable',
                maxFiles: 1,
                previewTemplate: this.template(),
                /**
                 * Accept function to handle the file validation and uploading process.
                 * @param {File} file - The file object that is being uploaded.
                 * @param {Function} done - The callback function to be called after the validation and uploading process.
                 */
                async accept(file, done) {
                    
                    // Check if files are more than one then remove all files
                    if(me.filesToProcess > 1) {
                        this.removeAllFiles();
                        return;
                    }

                    // Check if the invalid files flag is not set (during drop event)
                    if (!me.isInvalidFiles) {

                        // Validate the file based on appropriate file build
                        let validate = await validateFile(file);
                        if (!validate.valid) {
                            me.invalidFiles.push(sanitizeFileName(file.name));
                            me.filesToProcess > 0 ? me.filesToProcess-- : '';
                            if (me.filesToProcess === 0) {
                                // If only single invalid file ? show detailed error message : otherwise - more generic one if error message exists
                                me.invalidFiles.length === 1 ? me.showIsInvalidFilesAlert(validate.errorMessage) : validate.errorMessage ? me.showIsInvalidFilesAlert() : '';
                            }
                            this.removeFile(file);
                            return;
                        }

                        // If during drop of several files at least one didn't pass validation it was marked as broken flag, in order not to move to uploading
                        if (me.isFileBroken) {
                            me.filesToProcess--;
                            this.removeFile(file);
                            return;
                        }

                        if (me.filesToProcess === 0 && me.invalidFiles.length >= 1) {
                            // If only single invalid file ? show detailed error message : otherwise - more generic one
                            me.invalidFiles.length === 1 ? me.showIsInvalidFilesAlert(validate.errorMessage) : me.showIsInvalidFilesAlert();
                            this.removeAllFiles();
                        }

                        // Extract the file extension
                        const typeFile = sanitizeFileName(file.name).split('.').pop();

                        //REWRITE THE LOGIC
                        // Check if the file extension and platform are valid
                        if ((typeFile === 'apk' && me.integration.platform === 'android' || typeFile === 'aab' && me.integration.platform === 'android') ||
                            (typeFile === 'zip' && me.integration.platform === 'ios')) {

                            if (!file.isDuplicate) {
                                const dropzoneSpinner = document.querySelector('.new-version-integration__spinner-dropzone');
                                const dropzone = document.getElementById('dropzoneNewIntegrationVersion');
                                dropzoneSpinner.style.display = 'flex';
                                dropzone.style['pointer-events'] = 'none';

                                // Make an API call to get the file upload data (URL and bucket key)
                                const { data } = await me.axios.post('/private/ui/getFileUploadData', {
                                    fileName: file.name,
                                    platform: me.integration.platform,
                                });

                                const { url, bucketKey } = data;

                                // Set the file upload object with relevant data
                                me.fileUpload = {
                                    name: file.name,
                                    url: url,
                                    file: file,
                                    bucketKey: bucketKey,
                                    removeElement: file.previewElement.querySelector('.dz-remove'),
                                };

                                // Set the file URL
                                file.url = url;

                                dropzoneSpinner.style.display = 'none';
                            }

                            done(); // Call the done function to continue the uploading process if there are no invalid files

                        } else {
                            me.failNotification('wrongPlatform');
                            this.removeAllFiles();
                        }

                    } else {
                        this.removeAllFiles();
                    }
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            integration: 'integrations/integrationById',
            branchList: 'integrations/branchList',
            versionsInProgress: 'integrations/versionsInProgress',
        }),
    },
    async beforeMount() {
        this.loadScan = true;
        this.integrationId = this.$route.params.id;
        this.branchName = this.$route.params.branchName;
        await Promise.all([
            this.$store.dispatch('integrations/loadIntegrationById', {
                id: this.integrationId, branchName: this.branchName, page: 1, limit: 10,
            }),
            this.$store.dispatch('integrations/getBranchList', { integrationId: this.$route.params.id }),
            this.$store.dispatch('integrations/loadVersionsInProgress', { id: this.integrationId, branchName: this.branchName }),
        ]);
        document.title = `New Version — ${this.integration.integrationName} | Oversecured`;
        this.isVersionInProgressToggle = this.versionsInProgress.length > 0;
        this.branchList.forEach(branch => {
            if (!branch.lastVersion || (branch.lastVersion && !this.isVersionInProgress([branch.lastVersion]))) {
                this.branches.push({ value: branch.id, label: branch.name });
            }
        });

        const branchOnFirstRouteLoad = this.branchList.find(branch => branch.name === this.$route.params.branchName);
        this.branchId = branchOnFirstRouteLoad.id;
        this.showDropZone = true;
        this.loadScan = false;
    },
    mounted() {
        this.$store.commit('integrations/setCurrentBranchName', this.branchName);
    },
    methods: {
        template,
        onDuplicateFileUpload,
        checkAndUpdatePlatformText,
        /**
         * Handles file drop event and performs validation checks.
         * @param {*} event - The event object related to the file(s) drop.
         */
        async onFileDrop(event) {
            this.cleanDropZone();

            let droppedFiles = event.dataTransfer.files;

            let arrayFromDroppedFiles = Array.from(droppedFiles);
            this.filesToProcess = arrayFromDroppedFiles.length;

            arrayFromDroppedFiles.forEach(async (file) => {
                const typeFile = sanitizeFileName(file.name).split('.').pop();
                let validPlatform = (typeFile === 'apk' && this.integration.platform === 'android'
                    || typeFile === 'aab' && this.integration.platform === 'android')
                    || typeFile === 'zip' && this.integration.platform === 'ios';

                // Validate platform
                if (!validPlatform) {
                    this.isInvalidFiles = true;
                    this.invalidFilesCount++;
                } else {
                    this.validFilesCount++;
                }

                // Validate the file based on appropriate file build
                let validate = await validateFile(file);
                if (!validate.valid) {
                    this.isFileBroken = true;
                    this.invalidFilesCount++;
                }
            });

            // Show alert logic
            // If there more than two dragged files and at least one of them is invalid show customized alert and remove all files in accept step
            if (this.isInvalidFiles && this.validFilesCount + this.invalidFilesCount > 1) {
                this.failNotification('wrongPlatform');
            // If there is only one file and it is invalid show single error alert and remove all files in accept step
            } else if (this.isInvalidFiles && this.validFilesCount + this.invalidFilesCount === 1) {
                this.failNotification('wrongPlatform');
                return;
            // If all the files are valid but more than one show info alert and remove all files in accept step
            } else if (!this.isInvalidFiles && this.invalidFilesCount === 0 && this.validFilesCount > 1) {
                this.failNotification('onlyOneFile');
            }
        },
        /**
         * Sends a file using XMLHttpRequest (XHR) with a PUT request.
         * @param {File} file - The file object to be uploaded.
         * @param {XMLHttpRequest} xhr - The XMLHttpRequest object for handling the file upload.
         */
        sendFile(file, xhr) {
            // Save the original send method of the xhr object
            const originalSend = xhr.send;

            // Set the timeout for the xhr request
            const xhrTimeout = 3000 * 1000; // milliseconds

            // Override the send method of the xhr object
            xhr.send = () => {
                try {
                    // Open a PUT request to the file's URL
                    xhr.open('PUT', file.url);

                    // Set the timeout for the xhr request
                    xhr.timeout = xhrTimeout;

                    // Call the original send method with the file object
                    originalSend.call(xhr, file);
                } catch (error) {
                    this.showAlert('An error occurred while sending the file', 4000, 'error');
                    console.error('An error occurred while sending the file:', error);
                }
            };
        },
        /**
         * Updates the progress bar as the file upload progresses.
         * @param {File} file - The file object being uploaded.
         * @param {number} progress - The current upload progress in percentage (0-100).
         */
        uploadProgress(file, progress) {
            // Select the progress bar element
            const progressBarElement = document.querySelector('.progress-bar-success');

            // Set the current file name
            this.fileName = file.name;

            // Update the progress bar only if progress is between 0 and 100 (exclusive)
            if (progress >= 0 && progress <= 100) {
                // Set the progress bar transition for smoother animation
                progressBarElement.style.transition = 'width 0.6s ease';
                // If progress is greater than 85, set the progress bar to 100
                // Otherwise, set the progress bar to the current progress value
                this.progressBar = progress > 85 ? 100 : progress;
            }
        },
        /**
         * Updates the total upload progress bar and related elements.
         *
         * @param {number} progress - The total upload progress in percentage (0-100).
         */
        totalUploadProgress(progress) {
            // if (this.invalidFilesCount === 0) {
            this.isDropzoneContentVisible = false;
            // Select the required elements
            const progressBarElement = document.querySelector('.progress-bar');
            const preview = document.querySelector('.dz-preview');
            const fileName = document.querySelector('.dz-filename');

            if (fileName) fileName.textContent = this.fileUpload.name;
            if (preview) preview.style.display = 'block';

            // Update the elements only if progress is between 0 and 100 (inclusive)
            if (progress >= 0 && progress <= 100) {
                // Update the progress bar width
                if (progressBarElement) progressBarElement.style.width = this.progressBar + '%';
            }
            // }
        },
        onComplete(response) {
            if (response && response.status === 'success') {
                this.isFileUploaded = true;
                this.file = response.name;
            }
        },
        cleanDropZone() {
            this.fileUpload = {};
            this.file = null;
            this.isFileUploaded = false;
            this.progressBar = 0;
            this.filesToProcess = 0;
            this.isInvalidFiles = false;
            this.isFileBroken = false;
            this.invalidFiles = [];
            this.validFilesCount = 0;
            this.invalidFilesCount = 0;
        },
        showIsInvalidFilesAlert(errorMessage) {
            let message;
            if (errorMessage) {
                message = errorMessage;
            } else {
                message = `Invalid files: ${this.invalidFiles.slice(0, 3).join(', ')}`;
                if (this.invalidFilesCount > 3) {
                    message += ', ...';
                }
            }
            this.showAlert(message, 4000, 'error');
            this.cleanDropZone();
        },
        showAlert(message, timer, status) {
            this.$swal({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                icon: status,
                title: message,
                timer: timer,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer);
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                },
            });
        },
        errorLoaded() {
            this.isShowSpinner = false;
            document.getElementById('integration_logo').src = this.DefaultLogo;
        },
        isVersionInProgress(versions) {
            return versions.some(version => version.status === VersionStatusEnum.SCANNING
                || version.status === VersionStatusEnum.CREATED
                || version.status === VersionStatusEnum.IN_QUEUE,
            );
        },
        async createVersionScan() {
            this.loadScan = true;
            try {
                const integrationId = this.$route.params.id;
                await this.$store.dispatch('integrations/createNewVersionIntegration', {
                    integrationId: integrationId,
                    branchName: this.branchName,
                    platform: this.integration.platform,
                    file: this.fileUpload,
                });
                this.$router.push(`/integration/${integrationId}/branch/${this.branchName}`);
                this.loadScan = false;
            } catch (error) {
                this.loadScan = false;
                console.log(error);
            }
        },
        changeBranch(id) {
            const findBranch = this.branchList.find(branch => branch.id === id);
            if (findBranch.name !== this.$route.params.branchName) {
                this.$router.push({
                    path: `/integration/${this.integrationId}/branch/${findBranch.name}/new-version`,
                    replace: true,
                });
                this.branchName = findBranch.name;
                this.branchId = id;
            }
        },
        failNotification,
    },
};
</script>
<style lang="scss">
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

@mixin spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 100;
}

.new-version-integration {
    position: relative;
    margin: 0 7.5%;

	&__platform {
        @include Aspekta-font(500, $dynamic-integration-description-font-size, normal, #949DB8);
        letter-spacing: $dynamic-integration-description-letter-spacing;
        margin-left: 50px;
        margin-bottom: 10px;

        &-value {
            @include Aspekta-font(400, $dynamic-integration-description-font-size, normal, #5C6A7A);
            letter-spacing: $dynamic-integration-description-letter-spacing;
            padding-left: 6px;
        }
    }

	&__wrapper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 40px;
    }

	&__icon {
        margin-top: calc(0px + (10 - 0) * ((100vw - 320px) / (1920 - 320)));
        width: 30px;
        height: 30px;
        border-radius: 5px;
    }

	&__file-name {
        @include Aspekta-font(700, $dynamic-integration-name-font-size, $dynamic-integration-line-height, #021631);
        letter-spacing: 0.03em;
        word-break: break-word;
    }

    &__spinner-dropzone {
        @include spinner;
        display: none;
        align-items: center;
    }

    &__spinner {
        @include spinner;
    }

    &__dz-alert {
        @include Aspekta-font(400, 14px, 20px, #949DB8);
        position: absolute;
        right: 20px;
        top: 20px;

    }

	&__svg {
		display: flex;
		margin: 40px 0 0;
		align-items: center;
		justify-content: center;
		align-content: center;
	}

    &__dz-text {
        @include Aspekta-font(450, 18px, 27px, #5C6A7A);
        letter-spacing: 0.36px;
		margin-bottom: 8px;

        &__secondary {
            @include Aspekta-font(500, 14px, 27px, #5C6A7A);
            letter-spacing: 0.42px;
            margin-bottom: 8px;
            margin-top: 8px;
        }
    }

    &__result {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    &__dropzone-hide {
        display: none;
    }

    &__dropzone-block {
        display: block;
    }

    &__result-btns {
        display: flex;
        width: calc(280px + (504 - 280) * ((100vw - 320px) / (1920 - 320)));
        flex-direction: row;
        justify-content: center;
        gap: 8px;

    }

    &__version-in-progress {
        font-weight: bold;
        text-align: center;
        margin-top: 50px;
    }

    &__result-file-name {
        @include Aspekta-font(700, 24px, normal, #021631);
        width: 100%;
        margin: 0 auto 8px auto;
    }

	&__alert {
        font-size: 14px;
        color: black;
        width: 100%;
        text-align: right;
        margin-top: 10px;

        &--red {
            color: #FB8D62;
        }
    }

    &__select-branch {
        &__dropdown {
            display: flex;
            justify-content: center;
            text-align: left;
            margin-bottom: 8px;
            z-index: 200;
        }

        &__title-text {
            @include Aspekta-font(650, 22px, normal, #242424);
            letter-spacing: 0.66px;
            margin-bottom: 10px;
        }
    }

    &__upload-btn {
        max-width: 252px;
        margin: 0 auto;
    }
}

.dropzone__wrapper {
    position: relative;
}

.dropzone-disable {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 9;
}

.dropzone-custom-content {
	margin-bottom: 50px !important;
}

.dropzone-custom-block {
	position: relative;
}

.dropzone-block	{
	padding: 0 !important;
	background-color: #fff;
}
#dropzoneNewIntegrationVersion.dropzone-block {
  height: auto;
}
.dropzone-custom-platform-text {
	display: flex;
	justify-content: center;
}

.dz-message {
    color: transparent;
}

.dropzone-block {
  height: 100%;
  width: 100%;
  background-color: #FFF;
  padding: 0 15px 15px 15px;

  border: 2px dashed rgba(173, 204, 251, 1);
  box-sizing: border-box;
  border-radius: 20px;

  .dz-message {
    .dropzone-custom-content {
      position: relative;
      text-align: center;
      font-size: 14px;
      line-height: 150%;
      color: #706969;

      .dropzone-custom-limit {
        font-size: 12px;
        float: right;
        color: #B2B9C4;
        padding: 20px 27px 0;
      }

      .dropzone-custom-title {
        padding-top: 45px;
        font-size: 18px;
        line-height: 150%;
        color: #706969;
      }

      .dropzone-custom-available-block {
        font-family: Cabin;
        font-style: normal;
        font-weight: normal;
        margin-top: 15px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
      }

      .dropzone-custom-android-block {
        text-align: left;
        margin-left: 45px;
      }

      .dropzone-custom-ios-block {
        margin-left: 50px;
      }

      .dropzone-custom-platform-text {
        @include Aspekta-font(400, 12px, 150%, #949DB8);
        letter-spacing: 0.6px;
        margin: 0 0 8px 0;
        text-align: left;
      }

      .dropzone-custom-platform-sign {
        @include Aspekta-font(500, 14px, 150%, #021631);
        letter-spacing: 0.42px;
        background: #F5F7FA;
        border-radius: 4px;
        padding: 2px 6px;
        margin-right: 8px;
      }

      .dropzone-custom-platform-sign-description {
        @include Aspekta-font(400, 12px, 150%, #5C6A7A);
      }
    }
  }

  .dz-preview {
    display: none;

    .dz-details .dz-filename {
      padding-top: 45px;
      text-align: center;
      font-size: 18px;
      line-height: 140%;
      color: #706969;
      word-break: break-all;
    }

    .progress-bar-custom {

      margin: 20px auto;
      max-width: 476px;
      height: 8px;
      background: #F0F0F0;
      border-radius: 50px;

      .progress {
        max-width: 100%;
        height: 8px;

        .progress-bar {
          background: rgba(255, 180, 0, 0.4);
          border: 1px solid #FFB400;
          box-sizing: border-box;
          border-radius: 50px;
        }
      }
    }

    .dz-remove {
      display: none;
    }
  }

  .dz-number {
    padding-top: 15px;
    text-align: center;
    font-size: 18px;
    line-height: 140%;
    color: #706969;
  }
}

@media (max-width: 576px) {
    .new-version-integration {
        margin: 0 20px;

        &__result-btns {
            flex-direction: column;
            gap: 8px;
            width: 80vw;
        }
    }

    .dropzone-block .dz-preview .progress-bar-custom {
        margin: 20px 5px;
    }
}
</style>
