var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.integration)?_c('div',[_c('div',{staticClass:"inner-integration-header"},[_c('div',[_c('p',{staticClass:"inner-integration-header__platform"},[_vm._v(" Platform:"),_c('span',{staticClass:"inner-integration-header__platform-value"},[_vm._v(_vm._s(_vm.checkAndUpdatePlatformText(_vm.integration.platform)))])]),_c('p',{staticClass:"inner-integration-header__platform"},[_vm._v(" Integration ID:"),_c('span',{staticClass:"inner-integration-header__platform-value"},[_vm._v(_vm._s(_vm.integration.id))])]),_c('div',{staticClass:"inner-integration-header__wrapper"},[_c('img',{staticClass:"inner-integration-header__icon",attrs:{"id":"integration_logo","alt":"","src":_vm.integration.signedUrl ? _vm.integration.signedUrl : _vm.DefaultLogo},on:{"load":function($event){_vm.isShowSpinner = false},"error":_vm.errorLoaded}}),_c('div',{staticClass:"inner-integration-header__box"},[_c('div',{staticClass:"inner-integration-header__file-name"},[_vm._v(" "+_vm._s(_vm.integration.integrationName)+" ")]),_c('p',{staticClass:"inner-integration-header__text"},[_vm._v(" "+_vm._s(_vm.integration.integrationDescription)+" ")])])])]),_c('div',{staticClass:"inner-integration-header__wrapper-links"},[_c('div',{staticClass:"inner-integration-header__links"},[(_vm.renewalRequestStatus())?_c('p',{staticClass:"inner-integration-header__renewal-request-text"},[_vm._v(" Integration update request has been sent ")]):_vm._e(),(!_vm.isExpired && _vm.integration.status !== 'SAMPLE'
                        && _vm.countVersionsFinish !== 0
                        && _vm.integration.isAllowAddVersion
                        && (_vm.isAllowAddVersionToIntegration()|| !_vm.integration.limited))?_c('b-nav-item',{staticClass:"inner-integration-header__add-new-version-btn",attrs:{"to":{ path: `/integration/${_vm.integration.id}/branch/${_vm.branchName}/new-version`},"exact":""}},[_vm._v(" Add New Version ")]):_vm._e(),(_vm.integration.status === 'SAMPLE'|| (_vm.integration.status === 'ACTIVE'
                        && _vm.countVersionsFinish > 0
                        && (_vm.integration.trial && !_vm.integration.isAllowAddVersion || _vm.integration.limited && !_vm.isAllowAddVersionToIntegration())))?_c('div',{staticClass:"inner-integration-header__disable_container"},[_c('Tooltip',{attrs:{"position":"top","mobile-position":"top","tooltip":_vm.addVersionTooltipText()}},[_c('div',{staticClass:"inner-integration-header__add-new-version-btn inner-integration-header__add-new-version-btn--disable-gray"},[_vm._v(" Add New Version ")])])],1):_vm._e(),(_vm.isExpired && _vm.integration.isIntegrationOwner)?_c('b-nav-item',{staticClass:"inner-integration-header__add-new-version-btn",class:{'inner-integration-header__add-new-version-btn--disable': _vm.renewalRequestStatus()},attrs:{"disabled":_vm.renewalRequestStatus()},on:{"click":function($event){return _vm.renewalIntegrationRequest()}}},[_vm._v(" Request Renewal ")]):_vm._e(),_c('Tooltip',{attrs:{"position":"top","mobile-position":"left","tooltip":'Integration settings'}},[_c('b-nav-item',{staticClass:"inner-integration-header__settings-btn",attrs:{"to":{ path: `/integration/${_vm.integration.id}/settings/general`},"exact":""}},[_c('img',{staticClass:"settings-btn",attrs:{"src":require("../../assets/images/settings-btn.svg"),"alt":""}})])],1)],1),(_vm.isIntegrationExpired && _vm.integration.status !== 'SAMPLE')?_c('p',{staticClass:"inner-integration-header__alert inner-integration-header__alert--red"},[_vm._v(" Expired ")]):_vm._e(),(!_vm.isIntegrationExpired)?_c('p',{class:_vm.isLessThan14Days
                    ? 'inner-integration-header__alert inner-integration-header__alert--red'
                    : 'inner-integration-header__alert'},[_vm._v(" Expires on "+_vm._s(_vm.changeDateFormat(_vm.getExpireDate(_vm.integration.dateExpiredIntegration)))+" ")]):_vm._e(),(!_vm.isIntegrationExpired && _vm.integration.limited)?_c('p',{class:_vm.isScansLimit
                    ? 'inner-integration-header__alert inner-integration-header__alert--red'
                    : 'inner-integration-header__alert'},[_vm._v(" Available Scans: "+_vm._s(_vm.calculateAvailableScans(_vm.integration.limitedCount - _vm.integration.usedVersionsCount))+" ")]):_vm._e()])]),_c('b-skeleton-wrapper',{attrs:{"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"inner-integration-header"},[_c('b-skeleton',{staticStyle:{"margin-top":"29px"},attrs:{"type":"button","width":"252px"}})],1)]},proxy:true}],null,false,881969753)},[_c('div',{staticClass:"inner-integration-header"},[_c('div',{staticClass:"row"},[_c('DropDown',{attrs:{"options":_vm.branches,"show-label":true,"value":_vm.branchId,"name":"branches","label":"","empty-option-label":"Select branch"},on:{"changeBranch":_vm.changeBranch}}),_c('b-nav-item',{staticClass:"all-branches-count",attrs:{"to":{path: `/integration/${this.$route.params.id}/branches`}}},[_vm._v(" All ("+_vm._s(_vm.branchList && _vm.branchList.length)+") ")])],1)])]),(_vm.countVersionsFinish === 0 && _vm.versionsInProgress)?_c('div',[_c('FirstVersionIntegration',{attrs:{"is-expired":_vm.isExpired,"renewal-request":_vm.renewalRequestStatus(),"integration-status":_vm.integration.status,"integration":_vm.integration,"branch-list":_vm.branchList,"branch-name":_vm.branchName},on:{"renewalIntegrationRequest":_vm.renewalIntegrationRequest}})],1):_vm._e(),(_vm.isShowSpinner)?_c('div',{staticClass:"inner-integration-header__spinner"},[_c('Spinner')],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }