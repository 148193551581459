<template>
    <div class="order">
        <h2 class="order__header">
            Order Summary
        </h2>
        <div class="order__scans">
            <div class="order__scans-text">
                {{ filesUploaded.length }} {{ isScansAmountPlural(filesUploaded.length, true) }}
            </div>
            <div class="order__scans-price">
                {{ filesPrice }} $
            </div>
        </div>
        <div
            v-if="scanPack"
            class="order__scan-packs"
        >
            <h4 class="order__scan-packs-header">
                Ordered Scan Pack
            </h4>
            <div class="order__scan-packs-container">
                <div class="order__scan-packs-container-text">
                    Scan Pack ({{ scanPackAmount }})
                </div>
                <div class="order__scan-packs-container-price">
                    {{ scanPackPrice }} $
                </div>
            </div>
        </div>
        <div
            v-if="prepaidAmount"
            class="order__scans-used"
        >
            <h4 class="order__scans-used-header">
                Scans used
            </h4>
            <div
                v-if="prepaidAmount && !!prepaidUsed"
                class="order__scans-used-prepaid"
            >
                <div class="order__scans-prepaid-text">
                    Prepaid Scans
                </div>
                <div class="order__scans-prepaid-amount">
                    {{ prepaidUsed }} {{ isScansAmountPlural(prepaidUsed) }}
                </div>
            </div>
        </div>
        <div class="order__total">
            <h4 class="order__total-text">
                Total Price
            </h4>
            <h2 class="order__total-price">
                {{ totalCost }} $
            </h2>
        </div>
        <div class="order__control">
            <div
                v-if="!showCodeInput"
                class="order__control-code"
            >
                Activation Code
                <button
                    class="order__control-code-add"
                    @click.prevent="addCode"
                >
                    Add Here
                </button>
            </div>
            <div
                v-else
                style="position: relative;"
            >
                <label
                    for="activationInput"
                    class="activation-code__label"
                    :class="{'with-text': activationCode}"
                >
                    Activation Code
                </label>
                <input
                    id="activationInput"
                    ref="activationInput"
                    v-model.trim="activationCode"
                    class="activation-code__input"
                    type="text"
                    placeholder="Activation Code"
                    @focus="clearError"
                >
                <button
                    v-if="activationCode"
                    class="activation-code__clear"
                    @click.prevent="activationCodeClear"
                />
            </div>
            <slot />
        </div>
        <div
            v-if="isPaymentDetails"
            class="order__payment-details"
        >
            <h2 class="order__payment-details-header">
                Payment Details
            </h2>
            <button
                v-if="cardSelected"
                class="order__payment-details-card"
                @click.prevent="selectCard"
            >
                <span>
                    End with {{ last4 }}
                </span>
                <span>
                    Change
                </span>
            </button>
            <button
                v-else
                class="order__payment-details-select"
                @click.prevent="selectCard"
            >
                Select Card
            </button>
        </div>
    </div>
</template>

<script>
import { isScansAmountPlural } from '../../utils/helper-functions';

export default {
    name: 'OrderContainer',
    props: ['files', 'prepaidAmount', 'scanPack', 'isPaymentDetails', 'cardSelected', 'cards', 'showCodeInput'],
    data() {
        return {
            activationCode: '',
        };
    },
    computed: {
        filesUploaded() {
            return this.files.filter(file => file.status === 'success');
        },
        prepaidUsed() {
            const files = this.filesUploaded.length;
            return files < this.prepaidAmount ? files : this.prepaidAmount;
        },
        filesPrice() {
            const freeScans = this.prepaidAmount + this.scanPackAmount;
            const payedScans = [...this.filesUploaded];
            payedScans.splice(0, freeScans);
            return payedScans.reduce((total, file) => total + file.price, 0)/100;
        },
        scanPackAmount() {
            return this.scanPack ? this.scanPack.number : 0;
        },
        scanPackPrice() {
            return this.scanPack ? (this.scanPack.price*this.scanPack.number/100) : 0;
        },
        totalCost() {
            const totalCost = this.filesPrice + this.scanPackPrice;
            this.$emit('should-pay', totalCost);
            return totalCost;
        },
        last4() {
            const selected = this.cards.find(card => card.id === this.cardSelected);
            return selected.last4;
        },
    },
    watch: {
        activationCode(code) {
            this.$emit('activation-code', code);
        },
        showCodeInput(isShow) {
            if (isShow === false) {
                this.activationCode = '';
            }
        },
    },
    methods: {
        selectCard() {
            this.$emit('select-card');
        },
        addCode() {
            this.$emit('add-code');
        },
        showError() {
            this.$refs.activationInput.classList.add('error');
        },
        clearError() {
            this.$refs.activationInput.classList.remove('error');
        },
        activationCodeClear() {
            this.activationCode = '';
            this.$refs.activationInput.focus();
        },
        isScansAmountPlural,
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables";

.order {
    width: 535px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    border-radius: 20px;
    background-color: #fff;
    font-size: 18px;
    font-weight: 600;
    h2, h4 {
        margin: 0;
    }
    h2 {
        font-size: 36px;
        font-weight: 700;
    }
    h4 {
        font-size: 22px;
        font-weight: 650;
    }
    &__scans, &__scan-packs, &__scans-used, &__total {
        padding-bottom: 20px;
        border-bottom: 1px solid $ghost-border;
    }
    &__scans, &__scan-packs-container, &__scans-used-prepaid, &__total, &__control-code {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__scan-packs-container, &__scans-used-prepaid, &__total {
        margin-top: 20px;
    }
    &__scans-price, &__scan-packs-container-price {
        font-size: 22px;
    }
    &__control-code-add {
        font-size: 22px;
        font-weight: 600;
        color: $main-blue-color;
        background-color: transparent;
    }
    .activation-code {
        &__label {
            position: absolute;
            top: -17px;
            opacity: 0;
            color: $ghost-text;
            font-size: 12px;
            &:has(+ input.activation-code__input:focus),
            &.with-text{
                opacity: 1;
            }
            &:has(+ input.activation-code__input.error){
                color: $error-red-color;
            }
        }
        &__input {
            border: 1px solid #F0F0F0;
            border-radius: 8px;
            font-size: 16px;
            padding: 10px 35px 10px 20px;
            color: $main-text;
            width: 100%;
            height: 50px;
            position: relative;
            &.error {
                border: 1px solid $error-red-color;
                & ~ .activation-code__clear::after {
                    color: $error-red-color;
                }
            }
            &:focus {
                outline: 1px solid $main-blue-color;
                &::placeholder {
                    opacity: 0;
                }
            }
        }
        &__clear {
            position: absolute;
            top: 12px;
            right: 15px;
            &::after {
                content: '\2715';
                font-size: 18px;
                color: $ghost-text;
            }
        }
    }
    &__scans {
        &-prepaid-amount {
            color: $new-orange-color;
        }
    }
}

@media (max-width: 1500px) {
    .order {
        max-width: 400px;
        padding: 30px 40px;
        gap: 20px;
        font-size: 14px;
        font-weight: 500;
        h2 {
            font-size: 22px;
            font-weight: 650;
        }
        h4 {
            font-size: 18px;
            font-weight: 600;
        }
        &__scans-price, &__scan-packs-container-price {
            font-size: 18px;
        }
        &__control-code-add {
            font-size: 18px;
            font-weight: 600;
            color: $main-blue-color;
        }
    }
}

@media (max-width: 1200px) {
    .order {
        max-width: 300px;
    }
}
@media (max-width: 900px) {
    .order {
        max-width: 100%;
        width: 100%;
        &__control {
            padding-bottom: 20px;
            border-bottom: 1px solid $ghost-border;
        }
        &__payment-details {
            &-select, &-card {
                width: 100%;
                padding: 15px 20px;
                border-radius: 8px;
                margin-top: 15px;
            }
            &-select {
                border: 2px solid $border-orange-color;
                color: $new-orange-color;
                font-size: 17px;
                font-weight: 600;
                transition: all .3s;
                &:hover {
                    border-color: $new-orange-color;
                    box-shadow: $box-shadow-orange;
                }
            }
            &-card {
                display: flex;
                justify-content: space-between;
                border: 1px solid $disabled-text;
                background-color: $background-disabled;
                span:first-child {
                    color: $disabled-text;
                }
                span:last-child {
                    color: $main-blue-color;
                }
            }
        }
    }
}
@media (max-width: 505px) {
    .order {
        max-width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 40px;
        border-radius: 20px;
        font-size: 18px;
        font-weight: 600;
        h2, h4 {
            margin: 0;
        }
        h2 {
            font-size: 21px;
            font-weight: 700;
        }
        h4 {
            font-size: 18px;
            font-weight: 650;
        }
        &__scans, &__scan-packs, &__scans-used, &__total {
            padding-bottom: 20px;
            border-bottom: 1px solid $ghost-border;
        }
        &__scans, &__scan-packs-container, &__scans-used-prepaid, &__total, &__control-code {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__scan-packs-container, &__scans-used-prepaid {
            margin-top: 20px;
        }
        &__scans-price, &__scan-packs-container-price {
            font-size: 22px;
        }
        &__control-code-add {
            font-size: 22px;
            font-weight: 600;
            color: $main-blue-color;
        }
        &__scans-text, &__scan-packs-container-text, &__scans-prepaid-amount, &__scans-prepaid-text {
            font-size: 17px;
            font-weight: 450;
        }
        &__total {
            margin-top: 0;
        }
    }
}
</style>
