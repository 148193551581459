<template>
    <div class="single-scans-payment-history">
        <div v-if="payedJobsHistory && payedJobsHistory.rows.length > 0">
            <div class="single-scans-payment-history__table-section">
                <p class="single-scans-payment-history__table-item single-scans-payment-history__table-item--gray">
                    File name
                </p>
                <p class="single-scans-payment-history__table-item single-scans-payment-history__table-item--gray">
                    Payment date
                </p>
                <p class="single-scans-payment-history__table-item single-scans-payment-history__table-item--gray">
                    Price
                </p>
            </div>
            <div
                v-for="job in payedJobsHistory.rows"
                :key="job.id"
                class="single-scans-payment-history__table-section"
            >
                <p class="single-scans-payment-history__table-item single-scans-payment-history__table-item--file-name">
                    {{ job.fileName }}
                </p>
                <p class="single-scans-payment-history__table-item">
                    {{ getTime(job.createdAt) }}
                </p>
                <p class="single-scans-payment-history__table-item single-scans-payment-history__table-item--price">
                    {{ convertCentsToDollars(job.price) }} $
                </p>
            </div>
            <Pagination
                class="single-scans-payment-history__pagination"
                :count="payedJobsHistory.count"
                :limit="limit"
                :current-page="currentPage"
                :show-spinner="showSpinner"
                @pageChanged="updatePage"
            />
        </div>
        <div v-else>
            <p class="single-scans-payment-history__table-empty-message">
                Single scans have not been purchased yet
            </p>
        </div>
        <div
            v-if="showSpinner"
            class="single-scans-payment-history__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import Spinner from '../../components/Spinner';
import { convertCentsToDollars } from '../../services/functions';
import Pagination from '../Pagination.vue';

export default {
    components: {
        Spinner,
        Pagination,
    },
    data() {
        return {
            currentPage: 1,
            showSpinner: false,
            limit: 10,
        };
    },
    computed: {
        ...mapGetters({
            payedJobsHistory: 'jobs/payedJobsHistory',
        }),
    },
    watch: {
        async currentPage() {
            this.showSpinner = true;
            await this.$store.dispatch('jobs/loadPayedJobsHistory', this.currentPage);
            this.showSpinner = false;
        },
    },
    async mounted() {
        this.showSpinner = true;
        await this.$store.dispatch('jobs/loadPayedJobsHistory');
        this.showSpinner = false;
    },
    methods: {
        getTime(time) {
            return `${moment(Date.parse(time)).format('MMM Do, y')}`;
        },
        updatePage(page) {
            this.currentPage = page;
        },
        convertCentsToDollars,
    },
};
</script>
<style lang="scss">
.single-scans-payment-history {
    position: relative;
    border-radius: 20px;
    background-color: white;

    &__table-empty-message {
        text-align: center;
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        color: #242424;
        margin: 20px 0;
    }

    &__spinner {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 20px;
        z-index: 10;
    }

    &__pagination {
        display: flex;
        align-items: center;
        padding: 0;
        height: 90px;
        background-color: white;
        border-radius: 20px;
    }

    &__table-section {
        display: flex;
        justify-content: space-between;
        border-radius: 20px;
    }

    &__table-item {
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 55px;
        background-color: white;
        width: 100%;
        margin: 0;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #706969;
        word-break: break-word;
        box-sizing: border-box !important;

        &--gray {
            color: #B2B9C4;
        }

        &--price {
            font-size: 16px;
            font-weight: bold;
        }

        &--file-name {
            padding-left: 10px;
            font-size: 16px;
            justify-content: flex-start !important;
            color: black;
        }
    }
}

@media (max-width: 768px) {
    .single-scans-payment-history {
        &__table-item {
            margin: 0 0 10px 0;
        }

        &__pagination {
            justify-content: space-between;
        }
    }
}
</style>
