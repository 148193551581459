import axios from "axios";

export const handleDownloadFile = async (link, fileName) => {
    let openedWindow = window.open('');
    openedWindow.close();

    const resp = await axios.get(link)
    const base64 = btoa(resp.data)

    let a = document.createElement('a')
    a.href = `data:text/plain;base64,${base64}`
    a.download = fileName
    a.target ='_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}
