<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="13"
        viewBox="0 0 22 13"
        fill="none"
    >
        <g clip-path="url(#clip0_2615_3204)">
            <path
                d="M1.83464 1.85711L11.0013 11.1428L20.168 1.85711"
                :stroke="arrowColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_2615_3204">
                <rect
                    width="22"
                    height="13"
                    :fill="arrowColor"
                    transform="matrix(-1 0 0 -1 22 13)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
<script>
export default {
    props: {
        clicked: {
            type: Boolean,
        },
        hovered: Boolean,
    },
    computed: {
        arrowColor() {
            if(this.hovered) {
                return '#FFF';
            }
            if(this.clicked) {
                return '#FF7A08';
            }
            
            return '#FFF';
        },
    },
};
</script>
