import { store } from '../store';

export const setNotFoundJobData = (error) => {
    const job = {};
    let isJobFound = false;
    if (error.response && error.response.status === 404) {
        isJobFound = true;
    }
    job.data = null;
    job.isJobFound = isJobFound;
    return job;
};

/**
 * @description A function that formats the given time into a readable date format.
 * @param {Number} time - The time in milliseconds.
 * @returns {String} A formatted date string in the format: Month Date, Year
 */
export const formatDate = (time) => {
    // An array of short month names for formatting purposes
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Get the day of the month from the time
    let date = new Date(time).getDate();
    // Add a leading zero if the day is less than 10
    date = date < 10 ? '0' + date : date;
    // Get the year from the time
    let year = new Date(time).getFullYear();
    // Get the month name from the months array based on the month index of the time
    let month = months[new Date(time).getMonth()];

    // Return the formatted date string
    return `${month} ${date}, ${year}`;
};

/**
 * checkVulnerabilitySeverityNumber
 * is a method that takes a list of statistics and a severity as input and returns the number of items associated with that severity
 * @param {Array} statistics - An array of objects containing 'severity' and 'count' as key/value pairs
 * @param {String} severity - The severity of statistics to consider for the count (e.g. 'high', 'medium', 'low', 'all')
 * @return {Number} - The total count of all items matching the given severity
 */
export const checkVulnerabilitySeverityNumberFunction = (statistics, severity) => {
    return statistics.reduce((acc, st) => {
        if (st.severity === severity || severity === 'all') {
            return acc + st.count;
        }

        return acc;
    }, 0);
};

/**
 * checkVulnerabilitySeverityBoolean
 * is a method that loop through an array of statistics, and checks if there are any elements with the given severity.
 * @param {Array} statistics - An array of objects containing 'severity' and 'count' as key/value pairs
 * @param {String} severity - The severity of statistics to consider for the check
 * @returns {Boolean} - true if it finds any element in the array with the specified severity (high / medium or low), false otherwise
 */
export const checkVulnerabilitySeverityBooleanFunction = (statistics, severity) => {
    let counts = 0;
    for (const st of statistics) {
        if (st.severity === severity) {
            counts += st.count;
        }
    }
    return counts > 0;
};

/**
 * this method is used to show correct form of word vulnerability
 * depending of number of vulnerabilities
 * spaceOption is used to add a space between the span and the word in order to avoid the word to be cut in two lines
 */
export const isVulnerabilityAmountPlural = (number, hasVerb, space = false) => {
    if (number) {
        if (hasVerb) {
            return space ? number === 1 ? `vulnerability${space}is` : `vulnerabilities${space}are`
                : number === 1 ? 'vulnerability is' : 'vulnerabilities are';
        } else {
            return number === 1 ? 'vulnerability' : 'vulnerabilities';
        }
    } else {
        return 'vulnerabilities';
    }
};

/**
 * this method is used to show correct form of word vulnerability
 * depending of number of vulnerabilities moved
 */
export const isVulnerabilityMovedPlural = (number) => {
    if (number) {
        return number === 1 ? 'vulnerability has been' : 'vulnerabilities have been';
    }
};

/**
 * this method is used to show correct form of word scan
 * depending on number of scans
 * capitalize is used to define whether word should begin with capital letter
 */
export const isScansAmountPlural = (number, capitalize = false) => {
    if(number > 1) {
        return capitalize  ? 'Scans' : 'scans';
    }
    else return capitalize  ? 'Scan' : 'scan';
};

/**
 * this method used to return bank icon for cards
 */
export const getBankIcon = (brand) => {
    switch (brand) {
        case 'Visa':
        case 'visa':
            return require('@/assets/images/stripe/visa.svg');
        case 'Discovery':
        case 'discover':
            return require('@/assets/images/stripe/discover.svg');
        case 'Diners Club':
        case 'diners':
            return require('@/assets/images/stripe/diners-club.svg');
        case 'UnionPay':
        case 'unionpay':
            return require('@/assets/images/stripe/unionpay.svg');
        case 'American Express':
        case 'amex':
            return require('@/assets/images/stripe/american-express.svg');
        case 'JCB':
        case 'jcb':
            return require('@/assets/images/stripe/jcb.svg');
        case 'MasterCard':
        case 'mastercard':
            return require('@/assets/images/stripe/master-card.svg');
        default:
            return require('@/assets/images/stripe/unknown.svg');
    }
};

export const checkGetStartedCompleted = (responseSteps, referentSteps) => {
    return referentSteps.every(step => responseSteps.includes(step));
};
export const closeSideBar = async (to, from, next) => {
    const toCategories = to.query?.categories;
    const toPage = to.query?.page;

    if (!toCategories && !toPage) {
        await store.commit('filters/setSidebarVisiblity', false);
    }
    next();

}; 