<template>
    <div>
        <div v-if="pageLoaded">
            <div
                class="all-integrations"
            >
                <div class="all-integrations__wrapper">
                    <div class="all-integrations__header">
                        <div class="integrations-list__sign-wrapper">
                            <h1 class="integrations-list__sign">
                                Integrations
                            </h1>
                            <Tooltip
                                position="right"
                                mobile-position="bottom"
                                :tooltip="tooltipInfoText"
                            >
                                <img
                                    class="integrations-list__sign-icon"
                                    src="../../../assets/images/integration-settings-toggle-icon.svg"
                                    alt=""
                                >
                            </Tooltip>
                        </div>
                        <div class="new_integration_btn_elem">
                            <div class="new_integration_btn">
                                <div class="hacker-tooltip">
                                    <Tooltip
                                        v-if="userType === 'hacker'"
                                        position="top"
                                        mobile-position="top"
                                        :tooltip="tooltipHackerText"
                                    >
                                        <b-nav-item
                                            v-if="userType === 'hacker'"
                                            :to="{ path: '/integrations/create'}"
                                            class="all-integrations__new-integration-btn "
                                            :class="{ 'all-integrations__disable-btn' : userType === 'hacker'}"
                                            :disabled="userType === 'hacker' "
                                        >
                                            Request Integration
                                        </b-nav-item>
                                    </Tooltip>
                                </div>
                                <b-nav-item
                                    v-if="userType === 'company' && listIntegrations && listIntegrations.length > 0"
                                    :to="{ path: '/integrations/create'}"
                                    class="all-integrations__new-integration-btn "
                                >
                                    Request Integration
                                </b-nav-item>
                            </div>
                        </div>
                    </div>
                    <IntegrationInProgress
                        v-if="listIntegrations && listIntegrations.length"
                        :integration-data="getInProgress(listIntegrations)"
                    />
                    <IntegrationsFinished
                        v-if="listIntegrations"
                        :integration-data="getFinish(listIntegrations)"
                        :integration-in-progress="getInProgress(listIntegrations)"
                        :user-type="userType"
                    />
                    <div
                        v-if="pageChange"
                        class="integrations-list__spinner"
                    >
                        <Spinner />
                    </div>
                </div>
            </div>
            <NewIntegration v-if="userType === 'hacker'" />
            <h2
                v-if="showSuccessUpgradeMessage"
                class="success-upgrade-message"
            >
                {{ showAlert() }}
            </h2>
        </div>
        <div
            v-else
            class="all-integrations"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import IntegrationInProgress from '../../../components/listIntegrations/IntegrationInProgress';
import IntegrationsFinished from '../../../components/listIntegrations/IntegrationFinished';
import NewIntegration from '../../../components/newIntegration/NewIntegration';
import Spinner from '../../../components/Spinner';
import { memberNotification } from '@/services/notificationService/notificationService';
import Tooltip from '../../../components/report/components/Tooltip.vue';

export default {
    name: 'ListIntegrations',
    components: {
        IntegrationInProgress,
        IntegrationsFinished,
        NewIntegration,
        Spinner,
        Tooltip,
    },
    data() {
        return {
            integrationsStatusFinished: null,
            showSuccessUpgradeMessage: false,
            pageLoaded: false,
            pageChange: false,
            tooltipInfoText:
                'Integration is a subscription for continuous scanning of a select \n' +
                'app that provides an unlimited number of app scans, CI/CD options,\n' +
                'and notifications about vulnerabilities detected.',
            tooltipHackerText: 'To use this feature, please contact us to upgrade to company profile',
        };
    },
    async beforeMount() {
        await this.$store.dispatch('user/fetchUserInfo');
        document.title = 'Integrations | Oversecured';
        if (this.userType === 'company' || this.userType === 'hacker') {
            await this.$store.dispatch('integrations/loadListAllIntegrationByUserId');
        }
        this.showPage(true);
        if (this.isSuccessAcceptInvite) {
            this.memberNotification('join');
            this.$store.commit('integrations/setIsSuccessAcceptInvite', false);
        }
        if (this.isLeftIntegration) {
            this.memberNotification('left');
            this.$store.commit('integrations/setIsLeftIntegration', false);
        }
        if (this.$route.params.showSuccess === true) this.showSuccessUpgradeMessage = true;
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        this.$store.commit('integrations/clearListIntegrations');
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
        ...mapGetters({
            listIntegrations: 'integrations/allIntegrationByUserId',
            userType: 'user/userType',
            isSuccessAcceptInvite: 'integrations/isSuccessAcceptInvite',
            isLeftIntegration: 'integrations/isLeftIntegration',
        }),
    },
    watch: {
        listIntegration() {
            this.showPage(true);
        },
    },
    methods: {
        showPage(status) {
            this.pageLoaded = status;
        },
        showAlert() {
            this.$swal({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                icon: 'success',
                title: 'Successfully upgraded!',
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer);
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                },
            });
        },
        getInProgress(list) {
            if (Array.isArray(list) && list.length){
                return list.filter(el => el.isScanning);
            } else {
                return [];
            }
        },
        getFinish(list) {
            if (Array.isArray(list) && list.length){
                return list.filter(el => !el.isScanning);
            } else {
                return [];
            }
        },
        memberNotification,
    },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/variables";
@import "../../../assets/css/fonts";

.integrations-list__sign {
    @include Aspekta-font(600, 30px, normal, #242424);
}

.success-upgrade-message {
    position: absolute;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.integrations-list {
    &__sign {
        font-size: 30px;
        color: #242424;
    }

    &__sign-icon {
        cursor: pointer;
    }

    &__sign-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 200px;
        width: 100%;
        margin-bottom: 30px;
    }

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255, 255, 255, 0.7);
        width: 100%;
        height: 100%;

        div.spinner-wrapper {
            width: 100%;
            height: 100%;
            padding: 0 !important;
        }
    }
}

.all-integrations {
    background-color: #F8FAFC;
    padding: 40px 0 70px 0;
    position: relative;

    &__white-background {
        background-color: white;
    }

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 7.5%;
    }

    &__new-integration-btn {
        a.nav-link {
            @include Aspekta-font(650, $dynamic-button-font-size, normal, #FF7A08);
            letter-spacing: $dynamic-button-letter-spacing;
            display: flex;
            justify-content: center;
            background: #FFFFFF;
            border: 2px solid #FFD2AB;
            box-sizing: border-box;
            border-radius: 8px;
            transition: 0.3s;
            padding: $dynamic-button-horizontal-padding $dynamic-button-vertical-padding;
            width: calc(200px + (300 - 200) * ((100vw - 320px) / (1920 - 320)));

            &:hover {
                box-shadow: 0px 8px 16px 0px rgba(255, 180, 0, 0.24);
            }

            &:active {
                opacity: 1;
            }
        }
    }

    &__disable-btn {
        a.nav-link {
            @include Aspekta-font(650, $dynamic-button-font-size, normal, #D9D9D9);
            letter-spacing: $dynamic-button-letter-spacing;
            display: flex;
            justify-content: center;
            background-color: #F9F9F9;
            border: 2px solid #D9D9D9;
            border-radius: 8px;
            padding: $dynamic-button-horizontal-padding $dynamic-button-vertical-padding;
            width: calc(200px + (300 - 200) * ((100vw - 320px) / (1920 - 320)));
            opacity: 0.7 !important;
        }
    }
}  

.hacker-tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.new_integration_btn_elem {
    position: relative;
    box-sizing: border-box;
}

.new_integration_btn:hover {
    visibility: visible;
}

@media (max-width: 1000px) {
    .integrations-list {
        &__sign-icon-text {
            transform: translate(-29.5%);
            top: -80px;
            width: 300px !important;

            &:after {
                display: none;
            }
        }

        &__sign-icon {
            margin-left: 5px;
        }
    }

    .all-integrations__header {
        flex-direction: column;
    }
}

@media (max-width: 576px) {
    .all-integrations__header {
        margin: 0 20px;
    }
}
</style>
