<template>
    <div>
        <b-container class="main-container">
            <h2 class="main-container__title">The Oversecured service offers:</h2>
            <div class="main-container__content-wrapper">
                <div class="main-container__content-box">
                    <div class="main-container__content-icon">
                        <img src="../../assets/images/about/about-content-icon.svg" alt="">
                    </div>
                    <p class="main-container__content-text">
                        An efficient way to search for all known Android and Java-specific vulnerabilities: you can
                        learn about each one in more detail on our blog or on the pages that are dedicated to describing
                        these vulnerabilities.
                    </p>
                </div>
                <div class="main-container__content-box">
                    <div class="main-container__content-icon">
                        <img src="../../assets/images/about/about-content-icon2.svg" alt="">
                    </div>
                    <p class="main-container__content-text">
                        The biggest database of dangerous data sources and the largest number of checks to help prevent
                        data leaks
                    </p>
                </div>
                <div class="main-container__content-box">
                    <div class="main-container__content-icon">
                        <img src="../../assets/images/about/about-content-icon3.svg" alt="">
                    </div>
                    <p class="main-container__content-text">
                        Support for the Java Standard Library, Android Framework, Androidx, Apache libraries and all
                        popular
                        network libraries.
                    </p>
                </div>
            </div>
            <b-nav-item :to="{ path: '/integrations'}" class="try-scan__btn" v-if="isAuthenticated">
                <div class="main-container__link-try-wrapper">
                    <p class="main-container__link-try">Start securing your apps</p>
                    <img src="../../assets/images/about/about-link-btn.svg" alt="">
                </div>
            </b-nav-item>
            <div class="try-scan__btn" v-if="!isAuthenticated" @click="login">
                <div class="main-container__link-try">
                    <span class="text_try">
                        Start securing your apps
                        <img src="../../assets/images/about/about-link-btn.svg" alt="icon_action_download">
                    </span>

                </div>

            </div>
        </b-container>
    </div>
</template>
<script>
import startSecuring from '../../assets/images/start_securing_your_apps.svg'
    export default {
    data(){
        return{
            startSecuring
        }
    },
        props: {
            isAuthenticated: Boolean,
        },
        methods: {
            login() {
                this.$auth.loginWithRedirect({appState: {targetUrl: `scans`}});
            }
        }
    }
</script>
<style lang="scss" scoped>

    .main-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__title {
            font-family: Raleway, serif;
            font-weight: bold;
            font-size: 64px;
            line-height: 115%;
            max-width: 615px;
            color: #000000;
            text-align: center;
        }

        &__content-wrapper {
            display: flex;
            margin-top: 40px;
        }

        &__content-box {
            margin-right: 65px;
        }

        &__content-text {
            font-family: Cabin, serif;
            font-size: 16px;
            line-height: 150%;
            color: #706969;
            max-width: 200px;
            margin-top: 20px;
        }

        &__link-try-wrapper {
            display: flex;
            align-items: center;

            &:hover .main-container__link-try{
                text-decoration: underline;
            }
        }

        &__link-try {
            margin: 0 24px 0 0;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            color: #242424;
            span:hover {
                text-decoration: underline;
            }
        }
    }

    .link {
        list-style: none;
        display: flex;
        margin-top: 60px;
        height: 50px;

        &__wrapper {
            display: flex;
            align-items: center;
        }

        &__text {
            font-family: Raleway, serif;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            color: #242424;
            margin: 0;
        }
    }

    .try-scan__btn {
        cursor: pointer;
        margin-top: 20px;
        list-style: none;
    }

    @media (max-width: 1280px) {
        .main-container {
            &__content-text {
                font-size: 14px;
            }
        }
    }

    @media (max-width: 840px) {
        .main-container {
            flex-direction: column;

            &__title {
                font-size: 36px;
            }

            &__content-wrapper {
                flex-direction: column;
            }

            &__content-text {
                font-size: 14px;
            }
        }
    }
</style>

