<template>
    <button
        @click="buttonClick"
        class="popup__button"
        :class="{disable: disable}"
    >
        <span class="popup__button-text">
            {{ buttonText }}
        </span>
    </button>
</template>

<script>
export default {
    props: ['buttonText', 'disable'],
    methods: {
        buttonClick() {
            this.$emit('buttonClick')
        }
    }
}
</script>

<style lang="scss" scoped>
.popup__button {
    background: #FF7A08;
    border: 2px solid #FF7A08;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Aspekta, serif;
    letter-spacing: 0.54px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 12px 0;
    min-width: 125px;
    &-text {
        margin: 0 1.5rem;
    }
    &:hover {
        background-color: #fff;
        color: #FF7A08;
        box-shadow: 0px 8px 16px 0px rgba(255, 180, 0, 0.24);
    }
    &.disable {
        opacity: .5;
        pointer-events: none;
    }
}
</style>
