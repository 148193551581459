<template>
    <div
        class="dropdown"
        :class="isMobile && 'mobile'"
    >
        <b-dropdown
            id="dropdown-move-to"
            toggle-class="dropdown__button"
            menu-class="dropdown__menu"
            variant="light"
            no-caret
            block
            lazy
            @show="showMenu($event)"
            @hide="hideMenu($event)"
        >
            <template v-slot:button-content>
                <slot name="button-icon" />
                <slot name="button-text" />
                <img
                    class="dropdown__button-arrow"
                    src="../../../assets/images/folders/dropdown-arrow.png"
                >
            </template>
            <b-dropdown-item-button
                button-class="dropdown__menu-item"
                @click.prevent="openCreateNewFolderPopup"
            >
                <CreateFolderIcon
                    :svg-color="'#3180F6'"
                    :svg-width="'22px'"
                    :svg-height="'18px'"
                    alt="add-folder"
                />
                <span class="dropdown__menu-item-text create">Create New Folder</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button
                v-for="item in items"
                :key="item.id"
                button-class="dropdown__menu-item"
                @click.prevent="clickOnItem(item, $event)"
            >
                <slot name="item-icon" />
                <div
                    v-if="isInteractiveIcons"
                    class="folders_list_item_folder"
                >
                    <FalsePositiveIcon
                        v-if="item.name === 'False Positives'"
                        style="margin-bottom: 5px;"
                        :svg-color="item.isEmptyFolder ? Empty : Default"
                        :svg-width="'24px'"
                        :svg-height="'21px'"
                        alt="delete icon"
                    />
                    <FolderIcon
                        v-else
                        :ref="`FolderIcon${item.id}`"
                        :svg-color="item.isEmptyFolder ? Empty : Default"
                        :svg-width="'20px'"
                        :svg-height="'18px'"
                        alt="folder icon"
                    />
                    <div
                        v-if="item.name !== 'False Positives'"
                        class="folder_list_vulnerabilities_dots"
                    >
                        <div
                            v-if="checkVulnerabilitySeverityBooleanFunction(item.statistics, 'high')"
                            class="red_dot"
                        />
                        <div
                            v-if="checkVulnerabilitySeverityBooleanFunction(item.statistics, 'medium')"
                            class="yellow_dot"
                        />
                        <div
                            v-if="checkVulnerabilitySeverityBooleanFunction(item.statistics, 'low')"
                            class="green_dot"
                        />
                    </div>
                </div>
                <span
                    class="dropdown__menu-item-text"
                    :class="item.isEmptyFolder && 'empty'"
                    :title="item.name"
                >
                    {{ item.name }}
                </span>
            </b-dropdown-item-button>
        </b-dropdown>
        <div
            v-if="isMobile"
            class="dark-background"
            :class="isDropdownOpen && 'open'"
        />
    </div>
</template>

<script>
import FolderIcon from '../../../assets/iconTemplates/FolderIcon';
import FalsePositiveIcon from '../../../assets/iconTemplates/FalsePositiveIcon';
import CreateFolderIcon from '../../../assets/iconTemplates/CreateFolderIcon';
import { checkVulnerabilitySeverityBooleanFunction } from '../../../utils/helper-functions';

export default {
    components: {
        FolderIcon,
        FalsePositiveIcon,
        CreateFolderIcon,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        componentId: {
            type: String,
            default: '',
        },
        integrationId: {
            type: String,
            default: '',
        },
        isMobile: Boolean,
        isInteractiveIcons: Boolean,
    },
    data() {
        return {
            isDropdownOpen: false,
            Hover: '#FFE7AD',
            Default: '#949DB8',
            Empty: '#949DB8',
        };
    },
    methods: {
        showMenu($event) {
            this.isDropdownOpen = true;
            this.$emit('show', true, $event);
        },
        hideMenu($event) {
            this.isDropdownOpen = false;
            this.$emit('hide', false, $event);
        },
        clickOnItem(item, event) {
            this.$emit('item-click', item, event);
        },
        openCreateNewFolderPopup() {
            this.$emit('create-folder');
        },
        checkVulnerabilitySeverityBooleanFunction,
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

@mixin linePosition{
    padding: 12px 18px;
    display: flex;
    align-items: center;
    gap: 10px;
}

@mixin outlineStyle {
    background: #fff;
    color: #B2B9C3;
    border: 1px solid #E3E6ED;
    border-radius: 16px;
}

@mixin vulnDot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: auto 1.5px;
}

@mixin containerFolder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

::v-deep .dropdown {
    &__button {
        @include outlineStyle;
        @include Aspekta-font(400, 14px, 20px, #5C6A7A);
        border-radius: 8px;
        @include linePosition;
        &-arrow {
            margin-left: auto;
            width: 12px;
            height: 7px;
            transition: rotate .3s ease-in-out;
        }
        &[aria-expanded="true"] {
            @include outlineStyle;
            color: #5C6A7A;
            border-radius: 8px;
            .dropdown__button-arrow {
                rotate: 180deg;
                transition: rotate .3s ease-in-out;
            }
        }
        &-text {
            font-size: 16px;
        }
    }
    &__menu {
        @include outlineStyle;
        top: 20px !important;
        max-height: 240px;
        width: 100%;
        overflow: auto;
        padding: 0;

        // Hide scrollbar for Chrome, Safari and Opera
        &::-webkit-scrollbar {
            display: none;
        }
        // Hide scrollbar for IE, Edge and Firefox
        scrollbar-width: none;

        & li:last-child button {
            border-bottom: none;
            padding: 0;
        }

        &-item {
            @include linePosition;
            border-bottom: 1px solid #F0F1F3;
            width: 87%;
            padding: 0 0 10px 0;
            margin: 12px 18px;
            .folders_list_item_folder {
                @include containerFolder;
                .folder_list_vulnerabilities_dots {
                    display: flex;
                    flex-direction: row;
                    height: fit-content;
                    margin-top: 7px;
                    .red_dot {
                        @include vulnDot;
                        background: #FB8D62;
                    }
                    .yellow_dot {
                        @include vulnDot;
                        background: #FDD365;
                    }
                    .green_dot {
                        @include vulnDot;
                        background: #61D4B3;
                    }
                }
            }
            &-text {
                @include Aspekta-font(400, 14px, 20px, #5C6A7A);
                overflow: hidden;
                text-overflow: ellipsis;
                &.empty {
                    color: #5C6A7A;
                }
                &.create {
                    color: #3180F6;
                }
            }
        }

        &.show {
            transform: translate3d(0px, 35px, 0px) !important;
        }
    }
    @media (max-width: 800px) {
        .dropdown__menu.show {
            width: 100%;
            transform: translate3d(0px, 35px, 0px) !important;
        }
    }
    .dark-background {
        display: none;
    }
}
::v-deep .dropdown-item:hover {
    background-color: transparent;
    opacity: .5;
}
@media (max-width: 450px) {
    .dropdown.mobile {
        position: absolute;
        top: -20px;
        width: 100%;
        ::v-deep .dropdown__button {
            position: absolute;
            &[aria-expanded="true"] {
                width: 150%;
                z-index: 1;
            }
        }
        ::v-deep .dropdown__menu.show {
            width: 150%;
            transform: translate3d(0px, 35px, 0px) !important;
        }
    }
    .dark-background.open {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(65, 62, 62, 0.7);
    }
}
</style>
