import Vue from 'vue';
import { store } from './index';
import { convertErrorMessageForUI, converFolderErrorMessagesForUI } from '../services/functions';
import { JobStatusEnum, FolderNameEnum } from '../utils/constants/common';

export const state = () => ({
    isRequestSendingNow: false,
    allIntegrationByUserId: [],
    integrationById: null,
    latestVersionStatistics: null,
    versionsInProgress: null,
    finishedVersions: null,
    allVersions: [],
    integration: null,
    currentBranchName: '',
    currentVersionPage: 1,
    urlFromS3ForIcon: null,
    historyScans: null,
    allIntegrationVersions: null,
    integrationMembersList: [],
    integrationMembersCount: 0,
    integrationUserType: '',
    integrationInvitedId: '',
    acceptedInviteInfo: {},
    errors: {},
    isSuccessAcceptInvite: false,
    isLeftIntegration: false,
    webhooksList: [],
    webhooksCount: 0,
    branchList: null,
    branchError: null,
    integrationFolders: [],
});

export const mutations = {
    toggleIisRequestSending: (state, status) => {
        state.isRequestSendingNow = status;
    },
    setAllIntegrationByUserId: (state, allIntegrationByUserId) => {
        state.allIntegrationByUserId = allIntegrationByUserId;
    },
    setIntegrationById: (state, integrationById) => {
        state.integrationById = integrationById.integration;
    },
    setFinishedVersions: (state, finishedVersions) => {
        state.finishedVersions = finishedVersions;
    },
    setFinishedVersionsCompareData: (state, {versionId, compareWithPreviousData, doneLoading}) => {
        let versionIndex;
        if (state.finishedVersions) {
            versionIndex = state.finishedVersions.versions.findIndex(version => version.id === versionId);
        }
        if (versionIndex >= 0) {
            Vue.set(state.finishedVersions.versions[versionIndex], 'compareWithPreviousData', compareWithPreviousData);
            Vue.set(state.finishedVersions.versions[versionIndex], 'doneLoading', doneLoading);
        }
    },
    setVersionsInProgress: (state, versionsInProgress) => {
        state.versionsInProgress = versionsInProgress;
    },
    setLatestVersionStatistics: (state, latestVersion) => {
        state.latestVersionStatistics = latestVersion;
    },
    setIntegrationUserType: (state, userType) => {
        state.integrationUserType = userType;
    },
    setIntegrationInvitedId: (state, invitedId) => {
        state.integrationInvitedId = invitedId;
    },
    setAllVersions: (state, allVersions) => {
        state.allVersions = allVersions;
    },
    setCurrentBranchName: (state, branchName) => {
        state.currentBranchName = branchName;
    },
    setCurrentVersionPage: (state, page) => {
        state.currentVersionPage = page;
    },
    setIsSuccessAcceptInvite: (state, isSuccessAcceptInvite) => {
        state.isSuccessAcceptInvite = isSuccessAcceptInvite;
    },
    setIsLeftIntegration: (state, isLeftIntegration) => {
        state.isLeftIntegration = isLeftIntegration;
    },
    setSignedLogoUrl: (state, url) => {
        state.urlFromS3ForIcon = url;
    },
    clearListIntegrations: (state) => {
        state.allIntegrationByUserId = [];
    },
    setHistoryScans: (state, historyScans) => {
        state.historyScans = historyScans;
    },
    setAllIntegrationVersions: (state, versions) => {
        state.allIntegrationVersions = versions;
    },
    setIntegration: (state, integration) => {
        state.integration = integration;
    },
    setAcceptedInviteInfo: (state, inviteInfo) => {
        state.acceptedInviteInfo = inviteInfo;
    },
    setAllIntegrationMembers: (state, members) => {
        state.integrationMembersList = members;
    },
    setIntegrationMembersCount(state, count) {
        state.integrationMembersCount = count;
    },
    setErrors: (state, response) => {
        state.errors = {
            [response.key]: response.body,
        };
    },
    clearErrors: (state) => {
        state.errors = {};
    },
    setWebhooksList: (state, webhooks) => {
        state.webhooksList = webhooks;
    },
    setWebhooksCount: (state, count) => {
        state.webhooksCount = count;
    },
    setBranchList: (state, branchList) => {
        state.branchList = branchList.sort((a, b) => a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1);
    },
    setFolders: (state, folders) => {
        state.integrationFolders = folders;
    },
    setBranchError: (state, error) => {
        state.branchError = error;
    },
    clearBranchError: (state) => {
        state.branchError = null;
    },
    clearIntegrationBranchData: (state) => {
        state.latestVersionStatistics = null;
        state.versionsInProgress = null;
        state.finishedVersions = null;
        state.currentVersionPage = 1;
    },
    clearIntegrationData: (state) => {
        state.latestVersionStatistics = null;
        state.versionsInProgress = null;
        state.finishedVersions = null;
        state.currentVersionPage = 1;
        state.integration = null;
        state.currentBranchName = '';
        state.branchList = null;
        state.integrationFolders = [];
    },
};

export const actions = {
    async updateVersion({commit, getters, dispatch, state}, updatedJob) {
        if (getters.integrationById && updatedJob.integrationId === getters.integrationById.id && state.currentBranchName) {
            dispatch('loadIntegrationById', {id: updatedJob.integrationId, branchName: state.currentBranchName});
        }
        if (getters.integration && updatedJob.integrationId === getters.integration.id && getters.branchList) {
            dispatch('getBranchList', {integrationId: getters.integration.id});
        }
        if (getters.integrationById && updatedJob.integrationId === getters.integrationById.id && updatedJob.branchName === state.currentBranchName && (updatedJob.status === JobStatusEnum.FINISHED || updatedJob.status === JobStatusEnum.ERROR)) {
            const { id } = getters.integrationById;
            const { branchName } = updatedJob;
            const [finished, latest, inprogress] = await Promise.all([
                Vue.axios.get(`/private/ui/integrations/${id}/branches/${branchName}/versions/page`, {
                    params: {
                        page: 1,
                        limit: 10,
                    },
                }),
                Vue.axios.get(`/private/ui/integrations/${id}/branches/${branchName}/versions/latest/statistics`),
                Vue.axios.get(`/private/ui/integrations/${id}/branches/${branchName}/versions/status/inprogress`),
            ]);
            Vue.set(state.finishedVersions, 'count', finished.data.count);
            commit('setLatestVersionStatistics', latest.data);
            commit('setVersionsInProgress', inprogress.data);
            if (state.currentVersionPage === 1) {
                Vue.set(state.finishedVersions, 'versions', finished.data.versions);
                const successFinishedVersions = finished.data.versions.filter(version => version.status === JobStatusEnum.FINISHED);
                await Promise.all(successFinishedVersions.map(async (version) => {
                    const compareWithPreviousDataResp = await Vue.axios.get(`/private/ui/integrations/${id}/branches/${branchName}/versions/${version.id}/compare/previous`);
                    commit('setFinishedVersionsCompareData', {versionId: version.id, compareWithPreviousData: compareWithPreviousDataResp.data, doneLoading: true});
                }));
            }
        } else if (getters.integrationById && updatedJob.integrationId === getters.integrationById.id && updatedJob.branchName === state.currentBranchName) {
            await dispatch('loadVersionsInProgress', {id: getters.integrationById.id, branchName: updatedJob.branchName});
        }
        else if (getters.allIntegrationByUserId.length) {
            await dispatch('loadListAllIntegrationByUserId');
        }
    },
    async createIntegration({commit, dispatch}, {integrationName, appId, platform, trialAccess}) {
        try {
            await Vue.axios.post('/private/ui/integrations', {
                integrationName,
                appId,
                platform,
                trialAccess,
            });
            store.commit('integrations/toggleIisRequestSending', false);
        } catch (e) {
            store.commit('integrations/toggleIisRequestSending', false);
        }
    },
    async loadListAllIntegrationByUserId({commit}) {
        try {
            const response = await Vue.axios.get('/private/ui/integrations');
            if (response.data && response.data.integrationsList) {
                if (response.data.integrationsList.length === 0) {
                    commit('clearListIntegrations');
                } else if (response.data.integrationsList.length > 0) {
                    commit('setAllIntegrationByUserId', response.data.integrationsList);
                }
            }

        } catch (error) {
            console.log(error);
        }
    },
    async compareIntegrationsVersions(context, {
        integrationId,
        branchName,
        versionId,
        currentVersionId,
        compareVersionId,
    }) {
        try {
            const response = await Vue.axios.get(
                `/private/ui/integrations/${integrationId}/branches/${branchName}/versions/${versionId}/statistics?newVersionId=${currentVersionId}&oldVersionId=${compareVersionId}`,
            );
            return response.data;
        } catch (e) {
            console.log(e);
        }
    },
    async loadIntegrationVersion({commit}, {integrationId, versionId, branchName, folderName}) {
        try {
            const job = {};
            let response = null;

            try {
                response = await Vue.axios.get(`/private/ui/integrations/${integrationId}/branches/${branchName}/versions/${versionId}?folderName=${folderName}`);
                job.data = response.data;
                job.isJobFound = true;
                store.commit('jobs/setCurrentJob', job);
            } catch (error) {
                let isJobFound = false;
                if (error.response && error.response.status === 403) {
                    isJobFound = true;
                }
                job.data = null;
                job.isJobFound = isJobFound;
                store.commit('jobs/setCurrentJob', job);
            }
        } catch (error) {
            console.error(error);
        }
    },
    async loadIntegrationById({commit}, data) {
        try {
            const integration = await Vue.axios.get(`/private/ui/integrations/${data.id}/branches/${data.branchName}/versions`, {
                params: {
                    page: data.page,
                    limit: data.limit,
                },
            });
            integration.data.integration.showPage = true;

            commit('setIntegrationById', integration.data);
        } catch (e) {
            console.log(e);
            commit('setIntegrationById', {
                integration: {
                    showPage: false,
                },
            });
        }
    },

    async loadAllVersionsForBranch({commit}, {integrationId, branchName}) {
        try {
            const allVersions = await Vue.axios.get(`/private/ui/integrations/${integrationId}/branches/${branchName}/versions/all`);

            commit('setAllVersions', allVersions.data.versions);
        } catch (e) {
            console.log(e);
        }
    },

    async loadComparingVersionsById({commit}, data) {
        try {
            const versions = await Vue.axios.get(`/private/ui/integrations/${data.id}/branches/${data.branchName}/versions/page`, {
                params: {
                    page: data.page,
                    limit: data.limit,
                },
            });
            const finishedVersions = {};
            Vue.set(finishedVersions, 'versions', versions.data.versions);
            Vue.set(finishedVersions, 'count', versions.data.count);
            commit('setFinishedVersions', finishedVersions);
            const successFinishedVersions = versions.data.versions.filter(version => version.status === JobStatusEnum.FINISHED);
            await Promise.all(successFinishedVersions.map(async (version) => {
                const compareWithPreviousDataResp = await Vue.axios.get(`/private/ui/integrations/${data.id}/branches/${data.branchName}/versions/${version.id}/compare/previous`);
                commit('setFinishedVersionsCompareData', {versionId: version.id, compareWithPreviousData: compareWithPreviousDataResp.data, doneLoading: true});
            }));
        } catch (e) {
            console.log(e);
        }
    },

    async loadLatestVersionStatistics({commit}, {id, branchName}) {
        try {
            const version = await Vue.axios.get(`/private/ui/integrations/${id}/branches/${branchName}/versions/latest/statistics`);

            commit('setLatestVersionStatistics', version.data);
        } catch (e) {
            console.log(e);
        }
    },

    async loadVersionsInProgress({commit}, {id, branchName}) {
        try {
            const versionsInProgress = await Vue.axios.get(`/private/ui/integrations/${id}/branches/${branchName}/versions/status/inprogress`);

            commit('setVersionsInProgress', versionsInProgress.data);
        } catch (e) {
            console.log(e);
        }
    },

    async getIntegration({commit}, data) {
        try {
            const res = await Vue.axios.get(`/private/ui/integrations/${data.id}`);
            commit('setIntegration', res.data);
        } catch (e) {
            console.log(e);
        }
    },
    async updateIntegration({commit}, {integrationId, data}) {
        try {
            await Vue.axios.put(`/private/ui/integrations/${integrationId}`, {
                ...data,
            });
        } catch (e) {
            console.log(e);
        }
    },
    async updateSettingsData({commit, dispatch}, {integrationId, data}) {
        try {
            const update = await Vue.axios.put(`/private/ui/integrations/${integrationId}`, {
                ...data,
            });
            commit('setSignedLogoUrl', {url: update.data.url, bucketKeyGenerated: update.data.bucketKeyGenerated});
        } catch (e) {
            console.log(e);
        }
    },

    async deleteIntegrationById({dispatch}, {id}) {
        try {
            await Vue.axios.delete(`/private/ui/integrations/${id}`);
        } catch (error) {
            console.log(error);
        }
    },

    async deleteVersion({dispatch, commit}, {id, branchName, versionId, page}) {
        try {
            let result = await Vue.axios.delete(`/private/ui/integrations/${id}/branches/${branchName}/versions/${versionId}`);
            dispatch('loadLatestVersionStatistics', {id, branchName});
            dispatch('loadComparingVersionsById', {id, branchName, page});
            return result;
        } catch (error) {
            commit('setErrors', convertErrorMessageForUI(error.response.data.message));

            return error;
        }
    },

    async deleteVersionInProgress({dispatch}, {id, branchName, versionId}) {
        try {
            await Vue.axios.delete(`/private/ui/integrations/${id}/branches/${branchName}/versions/${versionId}`);
            await dispatch('loadVersionsInProgress', {id, branchName});
        } catch (error) {
            console.log(error);
        }
    },

    async loadIntegrationHistoryScans({commit}, data) {
        try {
            const integrations = await Vue.axios.post('/private/ui/integrations/history', data);
            commit('setHistoryScans', integrations.data);
        } catch (error) {
            console.log(error);
        }
    },

    async loadAllIntegrationVersions({commit}, {integrationId, page, limit}) {
        try {
            const versions = await Vue.axios.get(`/private/ui/integrations/history/${integrationId}/versions/page`, {
                params: {
                    page,
                    limit,
                },
            });
            commit('setAllIntegrationVersions', versions.data);
        } catch (error) {
            console.log(error);
        }
    },

    async createNewVersionIntegration(context, data) {
        try {
            const params = {
                fileName: data.file.name,
                platform: data.platform,
                sourceBucketKey: data.file.bucketKey,
            };
            return await Vue.axios.post(`/private/ui/integrations/${data.integrationId}/branches/${data.branchName}/versions`, {file: params});
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    async renewalIntegrationRequest({commit}, data) {
        try {
            await Vue.axios.post(`/private/ui/integrations/${data.integrationId}/request_renewal`);
        } catch (error) {
            console.log(error);
        }
    },
    async sendInvite({commit}, {integrationId, email}) {
        try {
            return await Vue.axios.post(`/private/ui/integrations/${integrationId}/send_invite`, {email});
        } catch (error) {
            commit('setErrors', convertErrorMessageForUI(error.response.data));
            return error;
        }
    },

    async loadIntegrationInvite({commit}, {invitedId}) {
        try {
            const res = await Vue.axios.get(`/private/ui/integrations/invite_info/${invitedId}`);

            commit('setAcceptedInviteInfo', res.data);
        } catch (err) {
            throw err.response.status;
        }
    },

    async acceptIntegrationInvite({commit}, {invitedId}) {
        try {
            const res = await Vue.axios.post('/private/ui/integrations/accept_invite', {
                invitedId,
            });
            commit('setIsSuccessAcceptInvite', true);
            return res;
        } catch (err) {
            console.log(err);
        }
    },

    async loadIntegrationMembers({commit}, {id}) {
        try {
            const res = await Vue.axios.get(`/private/ui/integrations/${id}/members`);

            if (res.status === 200) {
                const {members, count, userType, invitedId} = res.data;
                commit('setAllIntegrationMembers', members);
                commit('setIntegrationMembersCount', count);
                commit('setIntegrationUserType', userType);
                commit('setIntegrationInvitedId', invitedId);
            }

        } catch (error) {
            console.log(error);
        }
    },

    async deleteIntegrationMember({commit}, {integrationId, id}) {
        try {
            return await Vue.axios.delete(`/private/ui/integrations/${integrationId}/leave/${id}`);
        } catch (error) {
            console.log(error);
        }
    },

    async getWebhooks({commit}, integrationId) {
        try {
            const res = await Vue.axios.get(`/private/ui/integrations/${integrationId}/webhooks`);

            if (res.status === 200) {
                const {webhooks, count} = res.data;
                commit('setWebhooksList', webhooks);
                commit('setWebhooksCount', count);
            }
        } catch (error) {
            console.log(error);
        }
    },

    async addWebhook({commit}, {integrationId, url, contentType}) {
        try {
            return await Vue.axios.post(`/private/ui/integrations/${integrationId}/webhooks`, {
                url,
                contentType,
            });
        } catch (error) {
            commit('setErrors', convertErrorMessageForUI(error.response.data.message));
            return error;
        }
    },

    async deleteWebhook({commit}, {integrationId, webhookId}) {
        try {
            return await Vue.axios.delete(`/private/ui/integrations/${integrationId}/webhooks/${webhookId}`);
        } catch (error) {
            console.log(error);
        }
    },

    async updateWebhook({commit}, {integrationId, webhookId, url, contentType}) {
        try {
            return await Vue.axios.put(`/private/ui/integrations/${integrationId}/webhooks/${webhookId}`, {
                url,
                contentType,
            });
        } catch (error) {
            commit('setErrors', convertErrorMessageForUI(error.response.data.message));
            return error;
        }
    },

    async getBranchList({commit, getters}, {integrationId}) {
        try {
            const res = await Vue.axios.get(`/private/ui/integrations/${integrationId}/branches`);
            const branches = res.data;
            if (getters.integration) {
                branches.forEach(branch => {
                    branch.isDefault = branch.id === getters.integration.defaultBranchId;
                });
            }
            commit('setBranchList', branches);
        } catch (err) {
            console.log(err);
        }
    },

    async createBranch({commit}, {integrationId, name}) {
        try {
            await Vue.axios.post(`/private/ui/integrations/${integrationId}/branches`, {name});
        } catch (err) {
            if (err.response.status === 422) {
                commit('setBranchError', 'Invalid branch name');
            } else {
                commit('setBranchError', 'Something went wrong');
            }
        }
    },

    async deleteBranch(_, { integrationId, branchName }) {
        try {
            await Vue.axios.delete(`/private/ui/integrations/${integrationId}/branches/${branchName}`);
        } catch (err) {
            console.log(err);
        }
    },

    async updateBranch({commit}, {integrationId, branchName, updateBranchName}) {
        try {
            await Vue.axios.put(`/private/ui/integrations/${integrationId}/branches/${branchName}`, {
                name: updateBranchName,
            });
        } catch (err) {
            if (err.response.status === 422) {
                commit('setBranchError', 'Invalid branch name');
            } else {
                commit('setBranchError', 'Something went wrong');
            }
            console.log(err);
        }
    },
    async getFolders({commit}, {integrationId, versionId}) {
        try {
            const {data} = await Vue.axios.get(`/private/ui/folders?integrationId=${integrationId}&versionId=${versionId}`);
            commit('setFolders', data);
        } catch (err) {
            console.log(err);
        }
    },
    async createNewFolder({ commit }, { integrationId, name }) {
        try {
            return await Vue.axios.post(`/private/ui/folders?integrationId=${integrationId}`, { name });
        } catch (error) {
            commit('setErrors', converFolderErrorMessagesForUI(error.response.data));
            return error;
        }
    },
    async deleteFolder({ commit }, { integrationId, folderId }) {
        try {
            return await Vue.axios.delete(`/private/ui/folders/${folderId}?integrationId=${integrationId}`);
        } catch (error) {
            return error;
        }
    },
    async updateFolderName({ commit }, { integrationId, folderId, name }) {
        try {
            return await Vue.axios.put(`/private/ui/folders/${folderId}?integrationId=${integrationId}`, { name });
        } catch (error) {
            commit('setErrors', converFolderErrorMessagesForUI(error.response.data));
            return error;
        }
    },
};

export const getters = {
    isRequestSendingNow: s => s.isRequestSendingNow,
    allIntegrationByUserId: s => s.allIntegrationByUserId,
    integrationById: s => s.integrationById,
    allFinishedVersionsByBranch: s => s.allVersions.filter(version => version.status === JobStatusEnum.FINISHED && version.isDeleted === false),
    finishedVersions: s => s.finishedVersions?.versions,
    countVersionsFinish: s => s.finishedVersions?.count,
    latestVersionStatistics: s => s.latestVersionStatistics,
    versionsInProgress: s => s.versionsInProgress,
    urlFromS3ForIcon: s => s.urlFromS3ForIcon,
    historyScans: s => s.historyScans,
    allIntegrationVersions: s => s.allIntegrationVersions,
    integration: s => s.integration,
    acceptedInviteInfo: s => s.acceptedInviteInfo,
    integrationMembersList: s => s.integrationMembersList,
    integrationMembersCount: s => s.integrationMembersCount,
    integrationUserType: s => s.integrationUserType,
    integrationInvitedId: s => s.integrationInvitedId,
    errors: s => s.errors,
    isSuccessAcceptInvite: s => s.isSuccessAcceptInvite,
    isLeftIntegration: s => s.isLeftIntegration,
    webhooksList: s => s.webhooksList,
    webhooksCount: s => s.webhooksCount,
    branchList: s => s.branchList,
    branchError: s => s.branchError,
    integrationFolders: s => {
        /**
         * move false positives folder to the end to
         * false positives must be the last folder in list
         * we check if false positives exist for shared report
         */
        s.integrationFolders.unshift(s.integrationFolders.splice(s.integrationFolders.findIndex(folder => folder.name === FolderNameEnum.FALSE_POSITIVES), 1)[0]);
        return s.integrationFolders;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
