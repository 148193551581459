<template>
    <div class="markdown">
        <b-nav-item :id="makeAnchor('SmartFilter configuration')" class="md__link-wrapper">
            <h2 class="md__item-title">SmartFilter configuration</h2>
        </b-nav-item>
        <p> 
            Oversecured SmartFilter is a filter that prevents vulnerabilities showing up in the report if they are 
            probably false positives. Filtering often takes place at the level of the origin of potentially insecure data. 
            For example, data from UI inputs are filtered for the Cross-site scripting category, because this is probably 
            either a low impact vulnerability or a risk that was taken into account when developing the app.
        </p>
        <p>
            For the Integrations option users can <strong>disable SmartFilter</strong>, which increases the size of a mobile 
            app scan report many times over. This can be done in Integrations settings.
        </p>
        <p>
            With SmartFilter disabled you can expect a very large number of false positives. Users for whom security is a 
            priority can also regularly examine the report with the filter disabled, where they mind find real but non-critical 
            vulnerabilities and fix them.
        </p>
    </div>
</template>

<script>
import {makeLinkToAnchorScanConfigurations, makeAnchor} from "../../../services/functions";

export default {
    methods: {
        makeLinkToAnchorScanConfigurations,
        makeAnchor
    }
}
</script>

<style scoped>

</style>
