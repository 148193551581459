<template>
    <div class="upgrade-to-company">
        <b-container>
            <ProfileInfo :userType="userType"/>
        </b-container>
    </div>
</template>
<script>
    import ProfileInfo from "../components/settings/ProfileInfo";
    import {mapGetters} from "vuex";

    export default {
        components: {
            ProfileInfo
        },
        computed: {
            ...mapGetters({
                userType: 'user/userType',
            }),
        },
    }
</script>
<style lang="scss">
    .upgrade-to-company {
        background-color: #FAFBFC;
        padding: 40px 0 70px;
    }

    @media (max-width: 768px) {
        .upgrade-to-company {
            background-color: #FAFBFC;
            padding: 20px 0 30px;
        }
    }
</style>
