<template>
    <div class="limitation-card">
        <div class="limitation-card__container">
            <div
                class="limitation-card__content"
                :class="{ 'limitation-card__content-open': openCardTable }"
            >
                <div class="limitation-card__text">
                    <div class="limitation-card__title">
                        <p>{{ cardTitle }}</p>
                    </div>
                    <div class="limitation-card__description">
                        <p>
                            {{ cardDescription }}
                        </p>
                    </div>
                </div>
                <div class="btn_n_table">
                    <div class="limitation-card__btn">
                        <button
                            :class="{ 
                                'limitation-card__btn-pros-and-cons': !openCardTable, 
                                'limitation-card__btn-clicked': openCardTable,
                                limitationCircle: isOpenTable, 
                                limitationOval: !openCardTable && !isOpenTable, 
                            }"
                            @click="toggleCardTable"
                            @mouseover="isHoverBtn = true"
                            @mouseout="isHoverBtn = false"
                        >
                            <span
                                :style="isOpenTable? 'display: none' : 'display: block'"
                            >
                                Show Pros & Cons
                            </span>
                            <ArrowIcon
                                class="down-arrow"
                                :clicked="openCardTable"
                                :hovered="isHoverBtn"
                            />
                        </button>
                    </div>
                    <div
                        :class="{ 'limitation-card__table': true, 'open': openCardTable }"
                    >
                        <div class="column">
                            <div class="column-title">
                                <img
                                    src="../../../assets/images/main/pros-plus-icon.svg"
                                    alt="plus"
                                    class="plus-icon"
                                > Pros
                            </div>
                            <ul>
                                <li
                                    v-for="(item, index) in pros"
                                    :key="index"
                                    class="column-item"
                                >
                                    <div class="column-item-title">
                                        <img
                                            src="../../../assets/images/main/pros-plus-icon.svg"
                                            alt="plus"
                                            class="plus-icon small-plus"
                                        > {{ item.title }}
                                    </div>
                                    <div class="column-item-description">
                                        {{ item.description }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="column">
                            <div class="column-title">
                                <img
                                    src="../../../assets/images/main/cons-minus-icon.svg"
                                    alt="minus"
                                    class="minus-icon"
                                > Cons
                            </div>
                            <ul>
                                <li
                                    v-for="(item, index) in cons"
                                    :key="index"
                                    class="column-item"
                                >
                                    <div class="column-item-title">
                                        <img
                                            src="../../../assets/images/main/cons-minus-icon.svg"
                                            alt="minus"
                                            class="minus-icon small-minus"
                                        > {{ item.title }}
                                    </div>
                                    <div class="column-item-description">
                                        {{ item.description }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ArrowIcon from '../../../assets/iconTemplates/ArrowIcon.vue';

export default {
    components: {
        ArrowIcon,
    },
    props: {
        cardTitle: String,
        cardDescription: String,
        pros: Array,
        cons: Array,
        isNavButtonClicked: Boolean,
        isDotButtonClicked: Boolean,
    },
    data() {
        return {
            openCardTable: false,
            isHoverBtn: false,
            isOpenTable: false,
        };
    },

    watch: {
        isNavButtonClicked(newValue) {
            if (newValue) {
                this.openCardTable = false;
                setTimeout(() => {
                    this.isOpenTable = false;
                }, 300);
            }
        },
        isDotButtonClicked(newValue) {
            if (newValue) {
                console.log('isDotButtonClicked', newValue);
                this.openCardTable = false;
            }
        },
    },
    methods: {
        toggleCardTable() {  
            this.isOpenTable = this.openCardTable;
            this.openCardTable = !this.openCardTable;
            if(this.isOpenTable) {
                setTimeout(() => {
                    this.isOpenTable = !this.isOpenTable;
                }, 1200);
            } else {
                this.isOpenTable = !this.isOpenTable;
            }
            
        },
    },
};

</script>

<style lang="scss" scoped>
@import "../../../assets/css/variables";
@import "../../../assets/css/fonts";

.limitationCircle {
    height: 60px;
    width: 60px;
}
.limitationOval {
    padding: 18px 24px 18px 30px;
}

.limitation-card {
    background-color: #FFFFFF99;
    border-radius: 24px;
    height: 100%;

    &__container {
        border-radius: 24px;
        border: 2px solid #E8E7EF;
        backdrop-filter: blur(20px);
        height: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 3.4rem 4rem;
        height: 100%;

        &-open {
            padding: 3.4rem 4rem 1rem 4rem;
        }
    }

    &__text {
        padding: 0% 37% 0% 0%;
    }

    &__title, .column-title {
        @include Aspekta-font(700, $dynamic-main-limitations-card-title-font-size, 115.188%, #0C0D0E);
        letter-spacing: 0em;
    }

    .column-title {
        line-height: normal;
    }

    &__description, .column-item {
        @include Aspekta-font(400, $dynamic-main-limitations-card-description-font-size, 150%, #797E86);
        letter-spacing: 0em;

        & p {
            height: 100px;
        }
    }

    &__btn {
        position: relative;

        &-pros-and-cons {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.9rem;
            @include Aspekta-font(700, $dynamic-main-check-button-font-size, normal, #FFFFFF);
            letter-spacing: 0em;
            background-color: $new-orange-color;
            border: 2px solid $new-orange-color;
            border-radius: 36px;
            text-decoration: none;
            position: relative;
            animation: moveTo 1.2s ease-out;
            z-index: 3;
        }

        &-pros-and-cons span{
            opacity: 1;
            transition: opacity 1.3s ease-out;
        }

        &-clicked span {
            transition: opacity 0.3s ease-out;
            display: none;
        }

        @keyframes moveTo {
            
            from {
                transform: translateX($half-dynamic-main-limitations-card-table-width) rotate(540deg);
            }

        }

        &-clicked {
            border-radius: 30px;
            border: 2px solid rgba(255, 122, 8, 0.3);
            height: 60px;
            width: 60px;
            position: relative;
            transform: translateX($half-dynamic-main-limitations-card-table-width) rotate(540deg);
            transition: transform 1.1s ease-out;
            z-index: 3;
        }

        &-clicked:hover {
            background: $new-orange-color;
        }

        &-pros-and-cons:hover {
            opacity: 0.7;
        }
    }

    &__table {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        bottom: 30px;
        opacity: 0;
        transition: opacity 0.8s ease, max-height 0.8s ease;
        max-height: 0;
        overflow: hidden;
    }

    &__table.open {
        opacity: 1;
        max-height: 1260px;
        transition: opacity 0.8s ease, max-height 0.8s ease;
    }

    .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-top: 2px solid #E8E7EF;
    }

    .column-title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-bottom: 2px solid #E8E7EF;
        padding: 4%;
        gap: 10px;

    }

    .column-item {
        padding: 2% 2% 3% 7%;
    }

    .column-item:first-child {
        padding-top: 7%;
    }

    .column-item-title {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        gap: 1rem;
        color: #0C0D0E;
    }

    .column-item-description {
        margin-left: 1.8rem;
    }

    .column:last-child {
        border-left: 2px solid #E8E7EF;
    }

    ul {
        list-style-type: none;
        padding: 0;
        width: 100%;
    }
}

@media(max-width: 1500px) and (min-width: 768px) {
    .limitation-card {
        &__text {
            padding: 0% 30% 0% 0%;
        }
    }
}

@media(max-width: 1150px) and (min-width: 768px) {
    .limitation-card {
        &__text {
            padding: 0% 15% 0% 0%;
        }
    }
}

@media(max-width: 1050px) and (min-width: 500px) {
    .limitation-card {
        .column-item:last-child {
            padding-bottom: 3% !important;
        }
        .column-item:first-child {
            padding-top: 2% !important;
        }
    }
}

@media (max-width: 1050px) {
    .limitation-card {

        &__container {
            backdrop-filter: blur(40px);
        }
        &__content {
            padding: 44px;
            &-open {
                padding: 44px 44px 0px 44px;
                margin-bottom: -23px;
            }
        }

        &__text {
            padding: 0%;
        }

        &__table {
            flex-direction: column;
        }

        &__btn {
            position: relative;

            &-pros-and-cons {
                position: relative;
                animation: moveToMobile 1.2s ease-out;
                padding: 16px 24px 16px 30px;
                font-weight: 650;
            }

            &-clicked {
                position: relative;
                margin-left: -25px;
                height: 51px;
                width: 51px;
                transform: translateX($dynamic-main-limitations-card-table-width) rotate(540deg);
            }
        }

        .column {
            width: 100%;
        }

        .column:last-child {
            border-left: none;
        }

        .column-item {
            padding: 5% 0% 3% 4%;
        }

        .column-item:last-child {
            padding-bottom: 8%;
        }

        .column-title {
            justify-content: flex-start;
            margin-bottom: 3%;
        }

        .plus-icon, .minus-icon {
            height: 10px;
            width: 10px;
        }

        .down-arrow {
            height: 11px;
            width: 19px;
        }

        .small-plus, .small-minus {
            height: 8px;
            width: 8px;
        }

        @keyframes moveToMobile {
            from {
                border-radius: 30px;
                border: 2px solid rgba(255, 122, 8, 0.3);
                height: 51px;
                width: 51px;
                transform: translateX($dynamic-main-limitations-card-table-width);
            }

            to {
                border: 2px solid $new-orange-color;
                border-radius: 36px;
                padding: 16px 24px 16px 30px;
                text-decoration: none;
            }
        }
    }
}

@media (max-width: 560px) {
    .limitation-card {
        &__description p {
            height: 160px;
        }
    }
}
</style>
