<template>
    <div>
        <!-- in viewBox 3rd argument is width 4th argument is height  -->
        <svg :width="width" :height="height" viewBox="0 0 24 24" :fill="fill" xmlns="http://www.w3.org/2000/svg">
            <g clip-rule="evenodd" :fill="fill" fill-rule="evenodd">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0.253418C9.85841 0.253418 8.06624 1.74943 7.61151 3.75342H1.5C0.947715 3.75342 0.5 4.20113 0.5 4.75342C0.5 5.3057 0.947715 5.75342 1.5 5.75342H2.5961L3.89362 18.576C3.96745 19.3057 4.02863 19.9104 4.11754 20.4024C4.21023 20.9152 4.34501 21.3825 4.60613 21.8163C5.01337 22.4928 5.61175 23.0337 6.32587 23.3708C6.82837 23.6079 7.3578 23.6903 7.93987 23.7254C8.11004 23.7357 8.28949 23.742 8.47859 23.7461C9.03074 23.7579 9.48795 23.3199 9.49979 22.7678C9.51162 22.2156 9.0736 21.7584 8.52144 21.7466C8.3491 21.7429 8.19684 21.7373 8.06016 21.729C7.58031 21.7001 7.34552 21.6404 7.17953 21.5621C6.82247 21.3936 6.52328 21.1231 6.31966 20.7848C6.23401 20.6426 6.15683 20.4405 6.08566 20.0467C6.01228 19.6407 5.95829 19.1141 5.87955 18.336L4.60631 5.75342H19.3937L19.1058 9.90441C19.0502 10.4539 19.4505 10.9444 20 11C20.5495 11.0556 21.04 10.6552 21.0956 10.1058L21.4039 5.75342H22.5C23.0523 5.75342 23.5 5.3057 23.5 4.75342C23.5 4.20113 23.0523 3.75342 22.5 3.75342H16.3885C15.9338 1.74943 14.1416 0.253418 12 0.253418ZM9.70802 3.75342C10.0938 2.87047 10.9748 2.25342 12 2.25342C13.0252 2.25342 13.9062 2.87047 14.292 3.75342H9.70802Z"/>
                <path d="M12.2575 14.0106C11.867 14.4011 11.867 15.0344 12.2575 15.4249L15.0859 18.2533L12.2575 21.0817C11.867 21.4722 11.867 22.1054 12.2575 22.4959C12.648 22.8864 13.2812 22.8864 13.6717 22.4959L16.5001 19.6675L19.3285 22.4959C19.719 22.8864 20.3523 22.8864 20.7428 22.4959C21.1333 22.1054 21.1333 21.4722 20.7428 21.0817L17.9143 18.2533L20.7428 15.4249C21.1333 15.0344 21.1333 14.4011 20.7428 14.0106C20.3523 13.6201 19.719 13.6201 19.3285 14.0106L16.5001 16.8391L13.6717 14.0106C13.2812 13.6201 12.648 13.6201 12.2575 14.0106Z"/>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    name: "FalsePositiveIcon",
    props: ["svgColor", "svgHeight", "svgWidth"],
    data() {
        return {
            fill: this.svgColor,
            width: this.svgWidth,
            height: this.svgHeight
        }
    },
    methods: {
        setFill(value) {
            this.fill = value;
        }
    }
};
</script>
