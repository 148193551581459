<template>
<span>
    <svg v-if="variant === 'join'" class="join" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="background" d="M19.5855 8.27458C19.5855 10.5808 17.7173 12.4491 15.411 12.4491C13.1048 12.4491 11.2366 10.5808 11.2366 8.27458C11.2366 5.96835 13.1048 4.1001 15.411 4.1001C17.7173 4.1001 19.5855 5.96835 19.5855 8.27458ZM15.411 13.3468C19.206 13.3468 22.5032 14.695 22.5032 17.6816C22.5032 19.2397 21.8877 19.9003 20.5862 19.9003H10.2358C8.93434 19.9003 8.31891 19.2397 8.31891 17.6816C8.31891 14.695 11.6161 13.3468 15.411 13.3468ZM5.53592 6.34444C5.98214 6.34444 6.34388 6.70618 6.34388 7.15241L6.34298 9.5763L8.76777 9.5763C9.214 9.5763 9.57574 9.93804 9.57574 10.3843C9.57574 10.8305 9.214 11.1922 8.76777 11.1922H6.34298L6.34388 13.6161C6.34388 14.0624 5.98214 14.4241 5.53592 14.4241C5.08969 14.4241 4.72795 14.0624 4.72795 13.6161L4.72705 11.1922H2.30406C1.85783 11.1922 1.49609 10.8305 1.49609 10.3843C1.49609 9.93804 1.85783 9.5763 2.30406 9.5763L4.72705 9.5763L4.72795 7.15241C4.72795 6.70618 5.08969 6.34444 5.53592 6.34444Z"/>
        <path class="main" d="M7.56658 7.007V7.00665C7.56658 6.05582 6.79578 5.28502 5.84495 5.28502C4.89424 5.28502 4.12352 6.05563 4.12332 7.00629C4.12332 7.00641 4.12332 7.00653 4.12332 7.00665L4.12282 8.36254L2.76743 8.36254C1.8166 8.36254 1.0458 9.13334 1.0458 10.0842C1.0458 11.035 1.8166 11.8058 2.76743 11.8058H4.12282L4.12332 13.1617C4.12332 13.1618 4.12332 13.1619 4.12332 13.162C4.12352 14.1127 4.89424 14.8833 5.84495 14.8833C6.79578 14.8833 7.56658 14.1125 7.56658 13.1617V13.1613L7.56608 11.8058H8.92248C9.87331 11.8058 10.6441 11.035 10.6441 10.0842C10.6441 9.96727 10.6325 9.85309 10.6102 9.74273C10.9755 10.759 11.6651 11.6203 12.5544 12.2019C11.6436 12.3824 10.7937 12.6657 10.0596 13.0672C8.59654 13.8675 7.5428 15.1814 7.5428 17.033C7.5428 17.8701 7.7028 18.6776 8.22795 19.2747C8.77763 19.8997 9.54916 20.098 10.3204 20.098H20.1766C20.9478 20.098 21.7194 19.8997 22.269 19.2747C22.7942 18.6776 22.9542 17.8701 22.9542 17.033C22.9542 15.1814 21.9005 13.8675 20.4373 13.0672C19.7033 12.6657 18.8534 12.3824 17.9426 12.2019C19.2876 11.3223 20.1759 9.8028 20.1759 8.07523C20.1759 5.35323 17.9705 3.14785 15.2485 3.14785C12.5265 3.14785 10.3211 5.35323 10.3211 8.07523C10.3211 8.53043 10.3828 8.97118 10.4982 9.38959C10.2312 8.78471 9.62616 8.36254 8.92248 8.36254L7.56608 8.36254L7.56658 7.007ZM15.2485 6.59111C16.0688 6.59111 16.7326 7.25488 16.7326 8.07523C16.7326 8.89558 16.0688 9.55936 15.2485 9.55936C14.4281 9.55936 13.7644 8.89558 13.7644 8.07523C13.7644 7.25488 14.4281 6.59111 15.2485 6.59111ZM19.3891 16.6548H11.1079C11.2403 16.4496 11.5005 16.2191 11.9807 15.9939C12.7654 15.6258 13.9202 15.3962 15.2485 15.3962C16.5768 15.3962 17.7316 15.6258 18.5163 15.9939C18.9965 16.2191 19.2567 16.4496 19.3891 16.6548Z" stroke-width="1.90449"/>
    </svg>
    <svg v-else class="leave" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="background" fill-rule="evenodd" clip-rule="evenodd" d="M15.246 12.4269C17.4407 12.4269 19.2185 10.6492 19.2185 8.45468C19.2185 6.2602 17.4407 4.48242 15.246 4.48242C13.0515 4.48242 11.2738 6.2602 11.2738 8.45468C11.2738 10.6492 13.0515 12.4269 15.246 12.4269Z"/>
        <path class="background" fill-rule="evenodd" clip-rule="evenodd" d="M21.9949 17.4061C21.9949 14.5643 18.8573 13.2812 15.246 13.2812C11.635 13.2812 8.4974 14.5643 8.4974 17.4061C8.4974 18.889 9.08304 19.5176 10.3215 19.5176H20.1708C21.4092 19.5176 21.9949 18.889 21.9949 17.4061Z"/>
        <path class="background" d="M2.85915 9.60803C2.38738 9.60803 2.00488 9.99053 2.00488 10.4623C2.00488 10.9341 2.38738 11.3166 2.85915 11.3166H8.83902C9.31079 11.3166 9.69329 10.9341 9.69329 10.4623C9.69329 9.99053 9.31079 9.60803 8.83902 9.60803H2.85915Z"/>
        <path class="main" d="M12.4307 12.5541C11.5594 12.736 10.7454 13.0135 10.0377 13.4007C8.5641 14.2067 7.4974 15.5341 7.4974 17.4061C7.4974 18.2475 7.65797 19.0687 8.1941 19.6783C8.75601 20.3173 9.54304 20.5176 10.3215 20.5176H20.1708C20.9492 20.5176 21.7363 20.3173 22.2982 19.6783C22.8343 19.0687 22.9949 18.2475 22.9949 17.4061C22.9949 15.5341 21.9282 14.2067 20.4546 13.4007C19.7468 13.0135 18.9329 12.736 18.0615 12.5541C19.3645 11.6577 20.2185 10.1562 20.2185 8.45468C20.2185 5.70788 17.993 3.48242 15.246 3.48242C12.4993 3.48242 10.2738 5.70791 10.2738 8.45468C10.2738 8.78105 10.3052 9.10006 10.3652 9.40885C10.0306 8.92501 9.47179 8.60803 8.83902 8.60803H2.85915C1.8351 8.60803 1.00488 9.43824 1.00488 10.4623C1.00488 11.4863 1.8351 12.3166 2.85915 12.3166H8.83902C9.86308 12.3166 10.6933 11.4863 10.6933 10.4623C10.6933 10.4606 10.6933 10.4589 10.6933 10.4573C11.0677 11.3076 11.6734 12.0331 12.4307 12.5541ZM15.246 7.01994C16.0391 7.01994 16.6808 7.66169 16.6808 8.45468C16.6808 9.24767 16.0391 9.88943 15.246 9.88943C14.4532 9.88943 13.8115 9.24773 13.8115 8.45468C13.8115 7.66163 14.4532 7.01994 15.246 7.01994ZM11.2027 16.9797C11.3415 16.803 11.5855 16.6066 12.0011 16.4117C12.7769 16.0478 13.9233 15.8191 15.246 15.8191C16.5689 15.8191 17.7153 16.0478 18.4912 16.4117C18.9067 16.6066 19.1508 16.803 19.2896 16.9797H11.2027Z" stroke-width="2"/>
    </svg>
</span>
</template>

<script>
export default {
    name: 'MemberIcon',
    props: {
        variant: {
            type: String,
            require: true
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

svg.join {
    .main {
        fill: $main-green-color;
        stroke: $background-green-color;
    }
    .background {
        fill: $background-green-color;
    }
}
svg.leave {
    .main {
        fill: $main-orange-color;
        stroke: $background-orange-color;
    }
    .background {
        fill: $background-orange-color;
    }
}
</style>