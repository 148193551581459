<template>
    <div class="markdown">
        <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('')}`}" class="md__link-wrapper">
            <h2 class="md__item-title">Supported CI/CD tools</h2>
        </b-nav-item>
        <p>We have created plugins based on the
            <b-nav-item
                :to="{ path: `${makeLinkToAnchor('')}`}">
                public API
            </b-nav-item>
            for a number of popular CI/CD tools. These plugins allow you to automate the process of testing new app
            versions with Oversecured and receiving alerts.
        </p>
        <p>This option is only available to users with a Company account and at least one active Integration.
            <b-nav-item
                :to="{ path: '/contact-us'}">
                Contact us
            </b-nav-item>
            to begin integrating Oversecured into your development process.
        </p>
        <p>Regardless of which CI/CD tool you will be using, you will need to:</p>
        <ol>
            <li>Get an Integration ID in the
                <b-nav-item
                    :to="{ path: '/integrations'}">
                    list of active Integrations
                </b-nav-item>
            </li>
            <li>Generate an API token in
                <b-nav-item
                    :to="{ path: '/settings/api-keys'}">
                    Settings
                </b-nav-item>
            </li>
        </ol>
        <p>The following tools are currently supported:</p>
        <ul>
            <li>
                <b-nav-item
                    :to="{ path: `${makeLinkToAnchorCICD('bitrise')}`}">
                    Bitrise
                </b-nav-item>
            </li>
            <li>
                <b-nav-item
                    :to="{ path: `${makeLinkToAnchorCICD('jenkins')}`}">
                    Jenkins
                </b-nav-item>
            </li>
            <li>
                <b-nav-item
                    :to="{ path: `${makeLinkToAnchorCICD('gradle')}`}">
                    Gradle
                </b-nav-item>
            </li>
            <li>
                <b-nav-item
                    :to="{ path: `${makeLinkToAnchorCICD('circleci')}`}">
                    CircleCI
                </b-nav-item>
            </li>
            <li>
                <b-nav-item
                    :to="{ path: `${makeLinkToAnchorCICD('travis')}`}">
                    Travis CI
                </b-nav-item>
            </li>
        </ul>
        <p>If you are using some other tool, <a href="https://support.oversecured.com/hc/en-us/requests/new">tell
            us</a> about it and we will add support for it.</p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {makeAnchor, makeLinkToAnchor, makeLinkToAnchorCICD} from "../../../services/functions";
import { GetStartedStepsEnum } from '@/utils/constants/common';
import {makeSubLinkToAnchor} from "@/services/functions";

export default {
    computed: {
        ...mapGetters({
            getStartedSteps: 'user/getStartedSteps',
            userType: 'user/userType',
        })
    },
    mounted() {
        const isStepCompleted = this.getStartedSteps.includes(GetStartedStepsEnum.WATCHED_CI_CD);
        if (this.userType === 'company' && !isStepCompleted) {
            this.$store.dispatch('user/updateGetStartedSteps', GetStartedStepsEnum.WATCHED_CI_CD);
        }
    },
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor,
        makeLinkToAnchorCICD,
    }
}
</script>

<style scoped>

</style>
