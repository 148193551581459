<template>
    <div class="no-vulnerabilities">
        <div class="no-vulnerabilities__container">
            <div class="no-vulnerabilities__title">
                {{ title }}
            </div>
            <div
                class="no-vulnerabilities__description"
                v-html="description"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FolderNameEnum } from '../../../utils/constants/common';
import { isEmpty } from '../../../services/functions';
import DOMPurify from 'dompurify';

export default {
    name: 'NoVulnerabilitiesBlock',
    props: {
        integrationId: {
            type: String,
        },
        jobId: {
            type: String,
        },
        branchName: {
            type: String,
            required: true,
            default: 'Main Report',
        },
        versionId: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            allSeveritiesUnchecked: 'filters/allSeveritiesUnchecked',
            filters: 'filters/filters',

        }),
        title() {
            return this.allSeveritiesUnchecked && !this.isEmpty(this.filters) ? 'No filters selected' : 'No vulnerabilitites found';
        },
        description() {
            return this.allSeveritiesUnchecked && !this.isEmpty(this.filters) ?
                DOMPurify.sanitize('There are Vulnerabilities in the folder, but no filters were selected. Please choose filters to refine your search and find the specific Vulnerabilities you\'re looking for.') :
                DOMPurify.sanitize('This folder has no Vulnerabilities yet. Try adding them from <a id="main-report-link" style="color: #3180F6; cursor: pointer;">Main Report</a> or go to another folder.');
        },
    },
    mounted() {
        this.$nextTick(function () {
            let mainReportLink = document.getElementById('main-report-link');
            if(mainReportLink) {
                mainReportLink.addEventListener('click', this.navigateToMainReport);
            }
        });
    },
    beforeDestroy() {
        let mainReportLink = document.getElementById('main-report-link');
        if(mainReportLink) {
            mainReportLink.removeEventListener('click', this.navigateToMainReport);
        }
    },
    methods: {
        navigateToMainReport() {
            this.$router.push(this.definePath('Main Report'));
        },
        /**
         * This method is used to define path for folder menu links
         * @param {string} folderName - Folder name
         * @returns {string} - Path for folder menu links
         */
        definePath(folderName) {
            let integrationVersionPath;
            let scanPath;
            // If folder name is "All Vulnerabilities" and no query param for folder name exists
            if (folderName === FolderNameEnum.ALL_VULNERABILITIES && !this.$route.query.folderName) {
                // Set path without query params
                integrationVersionPath = `/integration/${this.integrationId}/branch/${this.branchName}/version/${this.versionId}`;
                scanPath = `/scan/info/${this.jobId}`;
            } else {
                // Set path with query params
                integrationVersionPath = `/integration/${this.integrationId}/branch/${this.branchName}/version/${this.versionId}?folderName=${folderName}`;
                scanPath = `/scan/info/${this.jobId}?folderName=${folderName}`;
            }
            return this.integrationId ? integrationVersionPath : scanPath;
        },
        isEmpty,
    },


};
</script>
<style lang="scss" scoped>
@import '../../../assets/css/fonts';
@import '../../../assets/css/variables';

.no-vulnerabilities {
	display: flex;
	width: 100%;
	height: auto;
	margin-top: 40px;
	border-radius: 20px;
    border: 1px solid #E3E6ED;

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 10px;
		padding: 30px;
        z-index: 10;
	}

	&__title {
		@include Aspekta-font(700, 36px, 150%, #242424);
        letter-spacing: 1.44px;
	}

	&__description {
		@include Aspekta-font(400, 14px, 140%, #5C6A7A);
        letter-spacing: 0.28px;
	}
}

@media (max-width: 768px) {
    .no-vulnerabilities {
        &__title {
            @include Aspekta-font(650, 21px, normal, #242424);
            letter-spacing: 0.63px;
        }

        &__description {
            @include Aspekta-font(450, 17px, 25.5px, #5C6A7A);
            letter-spacing: 0.17px;
        }
    }
}

</style>
