<template>
    <div class="main-informed">
        <div class="main-informed__container">
            <div class="main-informed__content">
                <div class="main-informed__text">
                    <div class="main-informed__title">
                        <p>Stay Informed with Our Blog</p>
                    </div>
                    <div class="main-informed__sub-title">
                        <p>Explore expert insights on cybersecurity</p>
                    </div>
                </div>
                <div
                    class="main-informed__slider"
                >
                    <carousel
                        :per-page-custom="[[320, 1], [686, 2], [891, 3]]"
                        :mouse-drag="false"
                        :navigation-enabled="true"
                        :pagination-enabled="false"
                        :pagination-color="'rgba(114, 125, 141, 0.5)'"
                    >
                        <slide
                            v-for="(card, index) in posts"
                            :key="index"
                            :data-index="index"
                        >
                            <BlogCard
                                :card="card"
                            />
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BlogCard from './BlogCard.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        BlogCard,
    },
    computed: {
        ...mapGetters({
            posts: 'blogPosts/posts',
        }),
    },
    beforeMount() {
        this.$store.dispatch('blogPosts/getPosts');
    },
};
</script>
<style lang="scss" >
@import "../../../assets/css/variables";
@import "../../../assets/css/fonts";

.main-informed {
    display: flex;
    flex-direction: column;
    background-color: #F6F6F9;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin: 7%;
        justify-content: center;
        align-items: center;
        gap: 4rem;
    }

    &__text {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    &__slider {
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    &__title {
        @include Aspekta-font(700, $dynamic-main-risks-title-font-size, 120%, #0C0D0E);
        letter-spacing: 0em;
    }

    &__sub-title {
        @include Aspekta-font(400, $dynamic-main-limitations-sub-title-font-size, normal, #797E86);
        letter-spacing: 0em;
    }

    .VueCarousel-navigation {
        position: absolute;
        top: 40%;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .VueCarousel-navigation-prev, .VueCarousel-navigation-next {
        background-size: contain;
        background-repeat: no-repeat;
        text-indent: -9999px;
        height: 64px;
        width: 64px;
    }

    .VueCarousel-navigation-prev:hover, .VueCarousel-navigation-next:hover {
        opacity: 0.7;
    }

    .VueCarousel-navigation-prev {
        position: relative;
        background-image: url('../../../assets/images/main/main-slider-arrow-left.svg');
        left: -10% !important;
    }

    .VueCarousel-navigation-prev.VueCarousel-navigation--disabled {
        background-image: url('../../../assets/images/main/main-slider-arrow-left-disable.svg');
        opacity: 1;
    }
    .VueCarousel-navigation-next.VueCarousel-navigation--disabled {
      background-image: url('../../../assets/images/main/main-slider-arrow-right-disable.svg');
      opacity: 1;
    }

    .VueCarousel-navigation-next {
        position: relative;
        background-image: url('../../../assets/images/main/main-slider-arrow-right.svg');
        right: -10% !important;
    }

}

@media (max-width: 1250px) and (min-width: 768px) {
    .main-informed {
        .VueCarousel-navigation-prev, .VueCarousel-navigation-next {
            height: 56px;
            width: 56px;
        }

    }
}


@media (max-width: 1100px) and (min-width: 320px) {
     .main-informed {
        .VueCarousel-navigation-prev {
            left: -8% !important;
        }

        .VueCarousel-navigation-next {
            right: -8% !important;
        }

    }

}

@media (max-width: 1025px) and (min-width: 890px){
    .main-informed {
        &__slider {
            width: 85%;
        }

        .VueCarousel-navigation {
            width: 96%;
        }

        &__content {
            gap: 2rem;
        }
    }
}

@media (max-width: 890px) and (min-width: 500px) {
    .main-informed {
        .VueCarousel-navigation-prev, .VueCarousel-navigation-next {
            height: 46px;
            width: 46px;
        }

        &__slider {
            width: 75%;
        }

    }
}

@media (max-width: 768px) {
    .main-informed {

        &__content {
            margin: 5rem 1.25rem 2rem 1.25rem;
            gap: 2rem;
        }

        &__slider {
            width: 74%;
        }

        &__image img {
            width: 90%;
        }

        &__title {
            line-height: 130%;
        }

    }
}

@media (max-width: 500px) and (min-width: 320px) {
    .main-informed {
        .VueCarousel-navigation-prev, .VueCarousel-navigation-next {
            height: 40px;
            width: 40px;
        }

    }
}

</style>
