<template>
    <div
        class="partner-card"
    >
        <div class="author">
            <div class="author-name">
                <span class="">{{ author }}</span>
            </div>
            <span class="author-title">
                <span class="author-quote">{{ quote }}</span>
                <span
                    v-html="processedLastWords"
                />
            </span>
            <span class="author-text">{{ text }}</span>
        </div>
    </div>
</template>
<script>

import DOMPurify from 'dompurify';

export default {

    props:{
        author:{
            type: String,
            default: '',
        },
        title:{
            type: String,
            default: '',
        },
        text:{
            type: String,
            default: '',
        },
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            quote: '"',
            expanded: false,
            limit: 170,

        };
    },
    computed: {
        isMobile() {
            return this.windowWidth < 768;
        },

        truncatedCustomerText(){
            return this.expanded ? this.customerText : this.customerText.substring(0, this.limit);
        },
        showExpandButton() {
            return !this.expanded && this.customerText.length > this.limit;
        },
        displayText(){
            return this.isMobile ? this.truncatedCustomerText: this.customerText;
        } ,
        processedLastWords() {
            const words = this.title.split(' ');
            const lastWord = words.pop();
            const processedWord = DOMPurify.sanitize(`<span id="lastW" style="white-space: nowrap;">
                ${lastWord} <span id="color" style="color: #FF7A08;">${this.quote}</span> </span>`);
            words.push(processedWord);
            return words.join(' ');
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },

    },

};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/variables.scss";
@import "../../../assets/css/variables/variablesForPartnerSuccessStories.scss";
@import "../../../assets/css/fonts";



.partner-card {
    display: flex;
    flex-direction: column;
    width:  $dynamic-partner-card-width;
    height: 100%;
    padding: $dynamic-partner-card-padding; //60px; 4rem
    gap: $dynamic-partner-card-gap; //60px; 4rem
    border-radius: 40px;
    border: 2px solid var(--stroke, #E8E7EF);
    background: var(--white, #FFF);

}



.author {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem; //144px;
    text-align: left;

    &-name {
        display: flex;
        @include Aspekta-font(400, $dynamic-partner-name-font-size, normal, $general-black);
            // line-height: 130%;
    }
    &-title {
        @include Aspekta-font(700, $dynamic-partner-title-text-font-size, normal, $general-black);
            line-height: 130%;
            //width: 96%;
    }
    &-text {
        @include Aspekta-font(400, $dynamic-partner-text-description-font-size, normal, #797E86);
        text-align: left;
    }
    &-quote {
        @include Aspekta-font(700, $dynamic-partner-text-description-font-size, normal, #FF7A08);
        line-height: 130%; /* 31.2px */
    }
}

@media (max-width: 1335px ) {

    .partner-card {
        width:  $dynamic-middle-partner-card-width;
    }

}

@media (max-width: 768px) {

    .partner-card {
        width:  $dynamic-mobile-partner-card-width;
        padding: $dynamic-mobile-partner-card-padding;
    }

}

</style>
