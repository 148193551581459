<template>
    <b-skeleton-wrapper :loading="!finishedVersions">
        <template #loading>
            <b-skeleton-table
                :rows="8"
                :columns="4"
                :table-props="{ bordered: false, striped: false, borderless: false, outlined: false}"
            />
        </template>
        <div
            v-if="finishedVersions && finishedVersions.length > 0"
            ref="topElement"
        >
            <div class="inner-integration-finished__header-wrapper">
                <h2 class="inner-integration-finished__sign">
                    Finished
                </h2>
                <p class="inner-integration-finished__files">
                    ({{ countVersionsFinish }})
                </p>
            </div>
            <div class="inner-integration-finished">
                <div class="finished-integrations-block">
                    <b-table
                        id="finished-inner"
                        hover
                        :items="finishedVersions"
                        :fields="uploadedFilesFields"
                        :per-page="perPage"
                        :thead-class="`table-header`"
                        :tbody-class="`table-body`"
                        :class="countVersionsFinish <= perPage ? 'pagination-disabled' : ''"
                    >
                        <template #cell(version)="data">
                            <div
                                v-if="ifStatusNotError(data.item.status)"
                                class="active_version"
                                @click="showReport(data.item)"
                            >
                                {{ data.item.version }}
                            </div>
                            <div
                                v-else
                                class="failed_version"
                            >
                                {{ data.item.fileName }}
                            </div>
                        </template>
                        <template #cell(completionTime)="data">
                            <div :style="isLessThenOneMinuteTime(data.item.completionTime)? 'color: #61D4B3;' : ''">
                                {{ createDateView(data.item.completionTime) }}
                            </div>
                        </template>
                        <template #cell(highVul)="data">
                            <b-skeleton-wrapper :loading="data.item.doneLoading ? false : ifStatusNotError(data.item.status)">
                                <template #loading>
                                    <b-skeleton
                                        type="text"
                                        width="50%"
                                    />
                                </template>
                                <div
                                    v-if="ifStatusNotError(data.item.status)"
                                    :class="!data.item.doneLoading && 'loading'"
                                >
                                    <p class="table-vulnerability__positive">
                                        -{{ getLastCompareData(data.item, 'high') ? getLastCompareData(data.item, 'high') : 0 }}
                                    </p> |
                                    <p class="table-vulnerability__negative">
                                        {{ getNewCompareData(data.item, 'high', 2) ? getNewCompareData(data.item, 'high', 2) : 0 }}+
                                    </p>
                                </div>
                            </b-skeleton-wrapper>
                        </template>
                        <template #cell(mediumVul)="data">
                            <b-skeleton-wrapper :loading="data.item.doneLoading ? false : ifStatusNotError(data.item.status)">
                                <template #loading>
                                    <b-skeleton
                                        type="text"
                                        width="50%"
                                    />
                                </template>
                                <div
                                    v-if="ifStatusNotError(data.item.status)"
                                    :class="!data.item.doneLoading && 'loading'"
                                >
                                    <p class="table-vulnerability__positive">
                                        -{{ getLastCompareData(data.item, 'medium') ? getLastCompareData(data.item, 'medium') : 0 }}
                                    </p> |
                                    <p class="table-vulnerability__negative">
                                        {{ getNewCompareData(data.item, 'medium', 1) ? getNewCompareData(data.item, 'medium', 1) : 0 }}+
                                    </p>
                                </div>
                                <div
                                    v-else
                                    class="status-text failed"
                                >
                                    {{ 'Failed' }}
                                </div>
                            </b-skeleton-wrapper>
                        </template>
                        <template #cell(lowVul)="data">
                            <b-skeleton-wrapper :loading="data.item.doneLoading ? false : ifStatusNotError(data.item.status)">
                                <template #loading>
                                    <b-skeleton
                                        type="text"
                                        width="50%"
                                    />
                                </template>
                                <div
                                    v-if="ifStatusNotError(data.item.status)"
                                    :class="!data.item.doneLoading && 'loading'"
                                >
                                    <p class="table-vulnerability__positive">
                                        -{{ getLastCompareData(data.item, 'low') ? getLastCompareData(data.item, 'low') : 0 }}
                                    </p> |
                                    <p class="table-vulnerability__negative">
                                        {{ getNewCompareData(data.item, 'low', 0) ? getNewCompareData(data.item, 'low', 0) : 0 }}+
                                    </p>
                                </div>
                            </b-skeleton-wrapper>
                        </template>
                        <template #cell(compare)="data">
                            <button
                                v-if="filterFinishVersion(finishedVersions).length > 1
                                    && data.item.compareWithPreviousData !== null
                                    && ifFirstFinishVersionOnPage(data.item, filterFinishVersion(finishedVersions).pop())
                                    && ifStatusNotError(data.item.status)"
                                class="button_compare table__btn-icon"
                                @click="showComparePage(data.item)"
                            >
                                Compare
                            </button>
                        </template>
                        <template #cell(actions)="data">
                            <div v-if="ifStatusNotError(data.item.status)">
                                <Tooltip
                                    :position="tooltipPosition"
                                    mobile-position="left"
                                    :tooltip="tooltipMessage(data.item)"
                                >
                                    <button
                                        id="download-pdf-btn"
                                        class="table__btn-icon"
                                        :disabled="isAllowDownloadPDF(data.item)"
                                        @click.prevent="downloadJobPDFReport(data.item.integrationId, data.item.id)"
                                    >
                                        <DownloadIcon
                                            :disabled="isAllowDownloadPDF(data.item)"
                                            alt="icon_action_download"
                                        />
                                    </button>
                                </Tooltip>
                                <Tooltip
                                    :position="tooltipPosition"
                                    mobile-position="left"
                                    :tooltip="tooltipCopyText"
                                >
                                    <button
                                        id="copy-link-btn"
                                        class="inner-integration-finished__copy-link-btn"
                                        @click.stop="copyLinkSharedJobReportPage(data.item)"
                                    >
                                        <img
                                            class="table__btn-icon"
                                            src="../../assets/images/myScans/copy-link.svg"
                                            alt="icon_action_share"
                                        >
                                    </button>
                                </Tooltip>
                                <Tooltip
                                    :position="tooltipPosition"
                                    mobile-position="left"
                                    :tooltip="isUserHacker ? tooltipHackerText : tooltipDeleteText"
                                >
                                    <button
                                        id="delete-report-btn"
                                        v-b-modal.general-popupDeleteReport
                                        :disabled="isUserHacker"
                                        @click="deleteReport( data.item )"
                                    >
                                        <img
                                            class="table__btn-icon"
                                            src="../../assets/images/newScan/trash-icon.svg"
                                            alt="icon_action_delete"
                                        >
                                    </button>
                                </Tooltip>
                            </div>
                            <!-- delete buttom for error version -->
                            <div v-else>
                                <Tooltip
                                    position="top"
                                    mobile-position="left"
                                    :tooltip="tooltipDeleteText"
                                >
                                    <button
                                        id="delete-report-btn"
                                        @click.prevent="deleteVersion(data.item)"
                                    >
                                        <img
                                            class="table__btn-icon"
                                            src="../../assets/images/myScans/icon_action_delete.svg"
                                            alt="icon_action_delete"
                                        >
                                    </button>
                                </Tooltip>
                            </div>
                            <!-- delete popup -->
                            <GeneralPopup
                                :title-text="''"
                                :popup-id="'DeleteReport-' + data.item.id"
                                :hide-footer="true"
                                :hide-header="true"
                                :size="'md'"
                                @onHidden="closeModal('general-popupDeleteReport-' + data.item.id)"
                            >
                                <div class="report__delete-modal">
                                    <img
                                        src="../../assets/images/closeModal_new.svg"
                                        alt="close modal"
                                        class="modal-close"
                                        @click="closeModal('general-popupDeleteReport-' + data.item.id)"
                                    >
                                    <div class="modal-title">
                                        You`re about to permanently delete the report for {{ data.item.fileName }}
                                    </div>
                                    <div class="modal-description">
                                        This action cannot be undone. To confirm, please type in the amount of vulnerabilities found in this report:
                                    </div>
                                    <div class="modal-number">
                                        {{ calculateTotalStatisticsCount(data.item.allVulnerabilitiesFolder) }}
                                    </div>
                                </div>
                                <form
                                    class="report__modal-form"
                                    @submit.prevent="handleDeleteModal"
                                >
                                    <div class="report__form-input-wrapper">
                                        <input
                                            id="DeleteReport"
                                            v-model.trim="deleteReportInput"
                                            class="input"
                                            type="text"
                                            name="DeleteReport"
                                            :class="{ errorInput: isDeleteReportInputInvalid(data.item.allVulnerabilitiesFolder) && deleteReportInput.length > 0}"
                                        >
                                        <img
                                            v-if="deleteReportInput.length > 0"
                                            class="cross-icon"
                                            src="../../assets/images/closeModal_new.svg"
                                            alt="cross icon"
                                            @click="deleteReportInput = ''"
                                        >
                                        <label
                                            for="DeleteReport"
                                            class="label"
                                            :class="{ activeInput: deleteReportInput }"
                                        >
                                            Amount of vulnerabilities
                                        </label>
                                        <span
                                            v-if="(calculateTotalStatisticsCount(data.item.allVulnerabilitiesFolder) !== parsedDeleteReportInput) && deleteReportInput.length > 0"
                                            id="DeleteReportError"
                                            class="report__error"
                                        >
                                            <span class="report__error-text">Please enter a valid vulnerability count</span>
                                        </span>
                                        <span
                                            id="DeleteReportStatusError"
                                            class="report__error"
                                        >
                                            <span
                                                v-if="jobError"
                                                class="report__error-text"
                                            >
                                                {{ jobError.deleteReportStatusError ? jobError.deleteReportStatusError : 'Something went wrong' }}
                                            </span>
                                            <span
                                                v-if="versionError"
                                                class="report__error-text"
                                            >
                                                {{ versionError.deleteReportStatusError ? versionError.deleteReportStatusError : 'Something went wrong' }}
                                            </span>
                                        </span>
                                    </div>
                                </form>

                                <div class="delete-report__btns">
                                    <ConfigurablePopupButton
                                        :button-text="'Cancel'"
                                        :button-type="'cancel'"
                                        :icon="false"
                                        :disabled="false"
                                        @buttonClick="closeModal('general-popupDeleteReport-' + data.item.id)"
                                    />

                                    <ConfigurablePopupButton
                                        :button-text="'Delete'"
                                        :button-type="'delete'"
                                        :icon="true"
                                        :disabled="calculateTotalStatisticsCount(data.item.allVulnerabilitiesFolder) !== parsedDeleteReportInput || !isDeleteReportInputValid"
                                        @buttonClick="deleteVersion(data.item)"
                                    />
                                </div>
                            </GeneralPopup>
                        </template>
                    </b-table>
                    <div
                        v-if="isShowSpinner || showAllList"
                        class="inner-integration-finished__spinner"
                    >
                        <Spinner />
                    </div>
                    <Pagination
                        v-if="countVersionsFinish > perPage"
                        class="pagination-general finished-versions-pagination"
                        :count="countVersionsFinish"
                        :limit="perPage"
                        :show-spinner="isShowSpinner"
                        :current-page="currentPage"
                        @pageChanged="updatePage"
                    />
                </div>
            </div>
        </div>
    </b-skeleton-wrapper>
</template>
<script>
import { mapGetters } from 'vuex';
import { FolderNameEnum, VersionStatusEnum } from '../../utils/constants/common';
import { createDateView, isLessThenOneMinuteTime, calculateTotalStatisticsCount } from '../../services/functions';
import GeneralPopup from '../popups/GeneralPopup';
import ConfigurablePopupButton from '../popups/ConfigurablePopupButton';
import Tooltip from '../report/components/Tooltip.vue';
import Pagination from '../Pagination.vue';
import Spinner from '@/components/Spinner';
import { successNotification } from '@/services/notificationService/notificationService';
import DownloadIcon from '../../assets/iconTemplates/DownloadIcon.vue';

export default {
    components: {
        GeneralPopup,
        ConfigurablePopupButton,
        Tooltip,
        Pagination,
        Spinner,
        DownloadIcon,
},
    data() {
        return {
            showAllList: false,
            isShowSpinner: false,
            isCopiedId: null,
            integrationId: null,
            deleteReportInput: '',
            currentPage: 1,
            perPage: 10,
            windowWidth: window.innerWidth,
            // Tooltips
            tooltipCopyText: 'Copy link',
            tooltipHackerText: 'Users with the "Hacker" account type are barred from this action',
            tooltipDeleteText: 'Delete report',
            tooltipDownloadText: 'Download PDF',
            tooltipTrialMessage: `Please contact our team
             to unlock the download option`,
            uploadedFilesFields: [
                {
                    key: 'version',
                    tdClass: [],
                },
                {
                    key: 'completionTime',
                    tdClass: [],
                },
                {
                    key: 'highVul',
                    tdClass: [],
                },
                {
                    key: 'mediumVul',
                    tdClass: [],
                },
                {
                    key: 'lowVul',
                    tdClass: [],
                },
                {
                    key: 'compare',
                    tdClass: [],
                },
                {
                    key: 'actions',
                    tdClass: [],
                },
            ],
            branchName: '',
            isLoadingStatistics: true,
        };
    },
    computed: {
        ...mapGetters({
            finishedVersions: 'integrations/finishedVersions',
            integrationById: 'integrations/integrationById',
            countVersionsFinish: 'integrations/countVersionsFinish',
            showDeleteReportNotification: 'user/showDeleteReportNotification',
            userType: 'user/userType',
            jobError: 'jobs/errors',
            versionError: 'integrations/errors',
        }),
        isDeleteReportInputValid() {
            return /^\d+$/.test(this.deleteReportInput);
        },

        parsedDeleteReportInput() {
            return this.isDeleteReportInputValid ? parseInt(this.deleteReportInput) : NaN;
        },
        isUserHacker() {
            return this.userType === 'hacker';
        },
        tooltipPosition() {
            return this.windowWidth < 1440 && this.isUserHacker ? 'left' : 'top';
        },
    },
    watch: {
        finishedVersions() {
            this.showAllList = false;
        },
        async currentPage() {
            this.integrationId = this.$route.params.id;
            this.branchName = this.$route.params.branchName;
            this.$refs.topElement.scrollIntoView({ behavior: 'smooth' });
            this.$store.commit('integrations/setCurrentVersionPage', this.currentPage);
            this.$store.commit('integrations/setFinishedVersions', null);
            await this.$store.dispatch('integrations/loadComparingVersionsById', { id: this.integrationId, branchName: this.branchName, page: this.currentPage, limit: this.perPage });
        },
        '$route.params.branchName': {
            handler () {
                this.loadFinishedVersionData();
            },
            deep: true,
        },
    },
    beforeMount() {
        this.loadFinishedVersionData();
        if (this.showDeleteReportNotification) {
            this.successNotification('reportDeleted');
            this.$store.commit('user/setShowDeleteReportNotification', false);
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    created() {
        window.addEventListener('resize', this.updateWindowWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWindowWidth);
    },
    methods: {
        createDateView,
        isLessThenOneMinuteTime,
        calculateTotalStatisticsCount,
        successNotification,
        isAllowDownloadPDF(item){
            return item.integrationId === this.integrationById.id
                &&  this.integrationById.trial;
        },
        tooltipMessage(item){
            return this.isAllowDownloadPDF(item) ? this.tooltipTrialMessage
                : this.tooltipDownloadText;
        },
        isDeleteReportInputInvalid(issue) {
            return !this.isDeleteReportInputValid || this.calculateTotalStatisticsCount(issue) !== this.parsedDeleteReportInput;
        },
        deleteReport(item){
            const vulnerabilities = calculateTotalStatisticsCount(item.allVulnerabilitiesFolder);
            if (!this.ifStatusNotError(item.status) || vulnerabilities === 0) {
                this.deleteVersion(item);
            } else {
                this.openModal('general-popupDeleteReport-' + item.id);
            }
        },
        openModal(modalId) {
            this.$bvModal.show(modalId);
        },
        closeModal(modalId) {
            this.$bvModal.hide(modalId);
            this.deleteReportInput = '';
        },
        updatePage(page) {
            this.currentPage = page;
        },
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
    
        async deleteVersion(item) {
            this.showAllList = true;
            let res = await this.$store.dispatch('integrations/deleteVersion', {
                id: item.integrationId, branchName: this.$route.params.branchName, versionId: item.id, page: this.currentPage,
            });
            if (res && res.status === 200) {
                this.successNotification('reportDeleted');
            }
            this.closeModal('general-popupDeleteReport-' + item.id);
        },
        getNewCompareData(data, severityName) {
            if (data.compareWithPreviousData) {
                const compareWithPreviousData = data.compareWithPreviousData;
                return compareWithPreviousData[severityName] && compareWithPreviousData[severityName].created;
            }
        },
        getLastCompareData(data, severityName) {
            if (data.compareWithPreviousData) {
                return data.compareWithPreviousData[severityName] && data.compareWithPreviousData[severityName].fixed;
            } else {
                return 0;
            }
        },
        downloadJobPDFReport(integrationId, versionId) {
            const folderName = this.$route.query.folderName || FolderNameEnum.ALL_VULNERABILITIES;

            let url = `/private/ui/pdf_report/integrations/${integrationId}/branches/${this.branchName}/versions/${versionId}`;
            this.isShowSpinner = true;
            this.axios
                .post(url, {
                    filters: { vulnerabilityCategoriesIds: [] },
                    folderName,
                })
                .then(
                    response => {
                        window.location.href = response.data.url;
                        this.isShowSpinner = false;
                    },
                    error => {
                        console.log(error);
                        this.isShowSpinner = false;
                    },
                );
        },
        async copyToClipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
                this.tooltipCopyText = 'Link Copied!';
                setTimeout(() => {
                    this.tooltipCopyText = 'Copy link';
                }, 2000);
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        },
        copyLinkSharedJobReportPage(item) {
            let link = window.location.origin + `/integration/${item.integrationId}/branch/${this.branchName}/version/${item.id}`;
            this.copyToClipboard(link);
        },
        showReport(item) {
            this.showAllList = true;
            this.$router.push(`/integration/${item.integrationId}/branch/${this.$route.params.branchName}/version/${item.id}`);
        },
        filterVersion(listVersion) {
            return listVersion.filter(el => el.status === 'FINISH' || el.status === 'ERROR');
        },
        filterFinishVersion(listVersion) {
            return listVersion.filter(el => el.status === 'FINISH');
        },
        ifStatusNotError(status) {
            return status !== VersionStatusEnum.ERROR;
        },
        ifFirstFinishVersionOnPage(version, firstFinishVersionOnPage) {
            return version !== firstFinishVersionOnPage;
        },
        showComparePage(item){
            const currentIndexFinishVersion = this.filterFinishVersion(this.finishedVersions).findIndex(el=> el.id === item.id);
            this.showAllList = true;
            const old_version  =  this.filterFinishVersion(this.finishedVersions)[currentIndexFinishVersion+1].id;
            this.$router.push({ path : `${this.$route.params.branchName}/compare` ,  query: { old: `${old_version}`, new: `${item.id}` } });
        },
        async loadFinishedVersionData() {
            this.isLoadingStatistics = true;
            this.integrationId = this.$route.params.id;
            this.branchName = this.$route.params.branchName;
            this.$store.commit('integrations/setCurrentVersionPage', this.currentPage);
            await this.$store.dispatch('integrations/loadComparingVersionsById', { id: this.integrationId, branchName: this.branchName, page: 1, limit: 10 });
            this.isLoadingStatistics = false;
        },
    },
};
</script>
<style lang="scss">
@import "../../assets/css/fonts";
@import "../../assets/css/variables";

.inner-integration-finished__spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: rgba(256, 256, 256, .7);
    z-index: 1100;
}

.table td {
    vertical-align: middle;
}

.button_compare{
    @include Aspekta-font(600, 17px, 22px, #FF7A08);
    cursor: pointer;
    background: #FFFFFF;
    border: 2px solid #FFD2AB;
    padding: 16px 20px;
    border-radius: 8px;
    align-items: center;
    text-align: center;

    &:hover {
            box-shadow: 0px 8px 16px 0px rgba(255, 180, 0, 0.24);
        }
}

.pagination-disabled {
    border-radius: 20px !important;
}

.inner-integration-finished {
    overflow: auto;
    position: relative;

    &__header-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        margin: 0 7.5%;
    }

    &__sign {
        @include Aspekta-font(600, 18px, 26px, #242424);
        margin: 0;
    }

    &__files {
        @include Aspekta-font(500, 18px, 26px, #949DB8);
        margin: 0 40px 0 10px;
    }

    &__copy-link-btn {
        margin-left: 10%;
        margin-right: 10%;
        position: relative;
    }

    &__copy-link {
        position: absolute;
        top: -32px;
        left: -38px;
        width: 90px;
        text-align: center;
        height: 23px;
        background-color: black;
        border-radius: 3px;
        color: white;
        font-family: Cabin, serif;
        font-size: 14px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__copy-link::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0%);
        border: 6px solid transparent;
        border-top: 3px solid black;
    }

}

.b-skeleton-wrapper > .b-table {
    width: calc(100% - 15%);
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin: 0 auto;
    .status-text.failed {
        text-align: center;
        padding-right: 0;
    }
    .b-skeleton-text {
        margin: auto;
    }
}
.b-skeleton, .b-skeleton-wrapper {
    cursor: default;
}
.b-skeleton-wrapper {
    overflow: auto;
    .b-table {
        border-top: none;
        border-radius: 20px;
        th, td {
            height: 65px;
        }
    }
    .table th {
        border-top: none;
    }
}

#finished-inner {
    margin: 0 auto;
}

#finished-inner thead tr th {
    @include Aspekta-font(400, 14px, 140%, #B2B9C4);
    border: none;
    padding: 20px;
}

#finished-inner tbody tr td {
    padding: 20px 0 !important;
}

#finished-inner thead tr th:nth-child(3),
#finished-inner thead tr th:nth-child(4),
#finished-inner thead tr th:nth-child(5) {
    position: relative;
    padding-left: 25px;

    &:before {
        position: absolute;
        content: "";
        top: 23px;
        left: 27px;
        background-color: $background-orange-color;
        width: 11px;
        height: 11px;
        border: 1px solid $main-orange-color;
        border-radius: 3px;
    }
}

#finished-inner tbody tr td {
    vertical-align: middle;
}

#finished-inner tbody tr td:nth-child(7) {
    :nth-child(1) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
}

#finished-inner thead tr th:nth-child(4) {
    position: relative;

    &:before {
        left: 31px;
        background-color: $background-yellow-color;
        border: 1px solid $main-yellow-color;
    }
}

#finished-inner thead tr th:nth-child(5) {
    position: relative;

    &:before {
        left: 27px;
        background-color: $background-green-color;
        border: 1px solid $main-green-color;
    }
}

#finished-inner thead tr,
#finished-inner tbody tr {
    text-align: center;
}

#finished-inner tbody tr td:nth-child(2) {
    @include Aspekta-font(400, 14px, 140%, #B2B9C4);
}

#finished-inner tbody tr td:nth-child(1) {
    @include Aspekta-font(600, 18px, 140%, #242424);
    max-width: 115px;

    & div {
        overflow-wrap: break-word;
        padding: 0 10px;
    }
}

#finished-inner thead tr th:nth-child(2) {
    text-align: center;
}

.table-vulnerability__positive,
.table-vulnerability__negative {
    display: inline-block;
    font-family: Cabin, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: $main-green-color;
    margin: 0;
}

.table-vulnerability__negative {
    color: $main-orange-color;
}

div.loading .table-vulnerability__positive,
div.loading .table-vulnerability__negative {
    background: linear-gradient(to right, #dad8d8 60%, #B2B9C4 20%);
    background-size: 200% auto;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: loading 1s linear infinite;
    @keyframes loading {
        to {
        background-position: 200% center;
        }
  }
}

#finished-inner tbody tr:last-child {
    background-color: white;
    border-bottom: none;
}

#finished-inner tbody tr td:nth-child(3),
#finished-inner tbody tr td:nth-child(4),
#finished-inner tbody tr td:nth-child(5) {
    color: #F0F0F0;
}
#finished-inner thead tr th:nth-child(6),
#finished-inner thead tr th:nth-child(7) {
    color: transparent;
}

.active_version:hover {
    text-decoration: underline;
    cursor: pointer;
}

.failed_version {
    cursor: default;
}

.report {
    // Delete Popup section
    &__delete-modal {
        position: relative;

        .modal-close {
            position: absolute;
            top: 0px;
            right: 0px;
            cursor: pointer;
        }

        img.modal-close {
            cursor: pointer;
        }

        .modal-title {
            @include Aspekta-font(600, 18px, 26px, #021631);
            padding-top: 20px;
            //word-break: break-all;
            word-wrap: break-word; // break long words onto the next line
            // overflow-wrap: break-word; // break long words onto the next line
            display: -webkit-box;
            -webkit-line-clamp: 4; // limit text to 4 lines
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-align: center;
        }

        .modal-description {
            @include Aspekta-font(400, 14px, 20px, #706969);
            letter-spacing: 0.02em;
            margin-top: 20px;
        }

        .modal-number {
            @include Aspekta-font(700, 36px, 54px, #021631);
            letter-spacing: 0.04em;
            text-align: center;
            margin-bottom: 20px;

        }
    }

    &__form-input-wrapper {
        position: relative;

        .cross-icon {
            position: absolute;
            top: 20px;
            right: 30px;
            cursor: pointer;
            width: 24px;
            height: 24px;
        }

        input {
            @include Aspekta-font(450, 18px, 150%, #021631);
            letter-spacing: 0.02em;
            padding: 18px 52px 18px 24px;

            &:focus {
                border: 1px solid $main-blue-color;
                outline: none;

                + .label {
                    transform: translate(0px, 0px);
                }
            }

            &.errorInput {
                border: 1px solid $error-color;
            }
        }


        input:focus ~ label,
        .activeInput {
            top: -18px;
            left: 10px;
            font-size: 12px;
        }

        label {
            @include Aspekta-font(400, 18px, 150%, $default-text-color);
            letter-spacing: 0.02em;
            top: 18px;
            left: 24px;
        }

    }

    &__error {
        width: 100%;
        max-width: 380px;
    }

    &__error-cross {
        position: absolute;
        top: -25px;
        right: 20px;
        cursor: pointer;
    }

    &__error-text {
        @include Aspekta-font(400, 12px, 18px, $error-color);
        position: absolute;
        left: 0px;
        bottom: 10px;
    }
}

.delete-report__btns {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
}

#DeleteReportStatusError {
    display: none;
}

@media (max-width: 1120px) {
    #finished-inner, .b-skeleton-wrapper > .b-table {
        width: 1120px;
    }
}


@media (max-width: 1000px) {
    .table__btn-icon {
        visibility: visible !important;
    }
}

@media (max-width: 769px) {
    .inner-integration-finished {
        &__sign {
            margin-left: 20px;
        }
    }

    #finished, .b-skeleton-wrapper > .b-table {
        margin: 0 auto;
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    #finished-inner tbody tr td:nth-child(1) {
        width: 90px !important;
    }

    #finished-inner thead tr th:nth-child(2) {
        width: 180px !important;
    }

    #finished-inner tbody tr td:nth-child(2) {
        width: 180px !important;
    }

    #finished-inner thead tr th:nth-child(3),
    #finished-inner thead tr th:nth-child(4),
    #finished-inner thead tr th:nth-child(5) {
        width: 150px !important;
    }

    #finished-inner tbody tr td:nth-child(3),
    #finished-inner tbody tr td:nth-child(4),
    #finished-inner tbody tr td:nth-child(5) {
        width: 150px !important;
    }

    #finished-inner tbody tr td:nth-child(7) {
        width: 220px !important;
    }

    #finished-inner thead tr th:nth-child(3),
    #finished-inner thead tr th:nth-child(4),
    #finished-inner thead tr th:nth-child(5) {
        &:before {
            left: 35px;
        }
    }

    #finished-inner thead tr th:nth-child(4) {
        &:before {
            left: 25px;
        }
    }

    .delete-report__btns {
        flex-direction: column-reverse;
    }
}

@media (max-width: 576px) {
    .inner-integration-finished {

        &__header-wrapper {
            margin: 0 20px;
        }
    }
}
</style>
