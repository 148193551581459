<template>
    <div class="inner-branch-table">
        <div
            v-if="branchList.length"
            class="inner-branch-table__container"
        >
            <b-table
                id="branch-table"
                hover
                :fields="uploadedFilesFields"
                :items="branchList"
                :per-page="perPage"
                :current-page="currentPage"
                :thead-class="`table-header`"
                class="inner-branch-table__wrapper"
            >
                <template #cell(index)="data">
                    {{ (data.index + 1) + ((currentPage - 1) * perPage) }}
                </template>
                <template #cell(branch_name)="data">
                    <b-nav-item
                        class="inner-branch-table__link"
                        :to="{path: `/integration/${data.item.integrationId}/branch/${data.item.name}`}"
                        :title="data.item.name"
                    >
                        {{ data.item.name }}
                    </b-nav-item>
                </template>
                <template #cell(default_branch)="data">
                    <span
                        v-if="!data.item.isDefault"
                        class="inner-branch-table__branch-type"
                        @click="makeDefaultBranch(data.item)"
                    >
                        Make Default
                    </span>
                    <span v-else>Default</span>
                </template>
                <template #cell(status)="data">
                    <span :class="`inner-branch-table__${data.item.lastVersion ? data.item.lastVersion.status : 'FINISHED'}`">
                        {{ getVersionStatus(data.item.lastVersion ? data.item.lastVersion.status : '-') }}
                    </span>
                </template>
                <template #cell(last_updated)="data">
                    {{ data.item.lastVersion && data.item.lastVersion.completionTime ? createDateView(data.item.lastVersion.completionTime) : '-' }}
                </template>
                <template #cell(actions)="data">
                    <button 
                        :disabled="isUserHacker" 
                        @click.stop="toggleEditModal(data.item)"
                    >
                        <Tooltip
                            position="top"
                            mobile-position="left"
                            :tooltip="isUserHacker ? tooltipHackerText: tooltipEditText"
                        >
                            <EditIcon
                                class="branch-btn cabin"
                                :svg-color="Default"
                                :svg-width="'20px'"
                                :svg-height="'20px'"
                                alt="edit icon"
                            />
                        </Tooltip>
                    </button>
                    <button
                        v-if="!data.item.isDefault"
                        @click.stop="toggleDeleteModal(data.item)"
                    >
                        <Tooltip
                            position="top"
                            mobile-position="left"
                            :tooltip="tooltipDeleteText"
                        >
                            <DeleteIcon
                                class="branch-btn"
                                :svg-color="Default"
                                :svg-width="'20px'"
                                :svg-height="'20px'"
                                alt="delete icon"
                            />
                        </Tooltip>
                    </button>
                </template>
            </b-table>
            <Pagination
                class="inner-branch-table__pagination"
                :count="branchList.length"
                :limit="perPage"
                :current-page="currentPage"
                :show-spinner="isShowSpinner"
                @pageChanged="updatePage"
            />
        </div>
        <div
            v-if="showDeleteModal"
            class="inner-branch-table__modal-wrapper"
        >
            <DeleteModal
                :title="deleteModalData.title"
                :text="deleteModalData.text"
                :show-spinner-modal="showDeleteSpinner"
                @closeModal="toggleDeleteModal"
                @deleteItem="deleteBranch"
            />
        </div>
        <div
            v-if="showCreateModal || showEditModal"
            class="inner-branch-table__modal-wrapper"
        >
            <BranchModal
                :branch-error="branchError"
                :show-spinner-modal="showModalSpinner"
                :is-edit-modal="isEditModal"
                :modal-data="modalData"
                @closeModal="closeModal"
                @handleForm="handleBranchForm"
            />
        </div>
        <div
            v-if="isShowSpinner"
            class="inner-branches__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getVersionStatus, createDateView } from '../../../services/functions';
import { branchNotification } from '@/services/notificationService/notificationService';
import DeleteModal from '../modalWindow/DeleteModal';
import BranchModal from './BranchModal';
import DeleteIcon from '../../../assets/iconTemplates/DeleteIcon';
import EditIcon from '../../../assets/iconTemplates/EditIcon';
import Tooltip from '../../report/components/Tooltip.vue';
import Pagination from '../../Pagination.vue';
import Spinner from '@/components/Spinner';

export default {
    name: 'InnerBranchTable',
    components: {
        DeleteModal,
        BranchModal,
        DeleteIcon,
        EditIcon,
        Tooltip,
        Pagination,
        Spinner,
    },
    props: {
        branchList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            Default: '#949DB8',
            tooltipEditText: 'Edit branch',
            tooltipDeleteText: 'Delete branch',
            tooltipHackerText: 'Users with the "Hacker" account type are barred from this action',
            currentPage: 1,
            perPage: 10,
            isShowSpinner: false,
            showHintEditLabel: null,
            showHintDeleteLabel: null,
            showDeleteModal: false,
            showDeleteSpinner: false,
            showCreateModal: false,
            showModalSpinner: false,
            showEditModal: false,
            modalData: {
                branchId: '',
                branchName: '',
                isDefault: false,
            },
            isEditModal: false,
            integrationId: null,
            deleteModalData: {
                name: '',
                title: 'Delete branch',
                text: 'Are you sure you want to delete current branch?',
            },
            uploadedFilesFields: [
                {
                    key: 'index',
                    label: '',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-5', 'center'],
                },
                {
                    key: 'branch_name',
                    label: 'Branch Name',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-30', 'left'],
                },
                {
                    key: 'default_branch',
                    label: '',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-25', 'center'],
                },
                {
                    key: 'status',
                    label: 'Version Status',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-20', 'center'],
                },
                {
                    key: 'last_updated',
                    label: 'Last Updated',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-10', 'center'],
                },
                {
                    key: 'actions',
                    label: '',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-10', 'right'],
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            branchError: 'integrations/branchError',
            userType: 'user/userType',
        }),
        isUserHacker() {
            return this.userType === 'hacker';
        },
    },
    beforeMount() {
        this.integrationId = this.$route.params.id;
    },
    methods: {
        getVersionStatus,
        createDateView,
        updatePage(page) {
            this.currentPage = page;
        },
        toggleDeleteModal(event) {
            if (event) {
                this.deleteModalData.name = event.name;
                this.deleteModalData.title += ` ${event.name}`;
            } else {
                this.deleteModalData.name = '';
                this.deleteModalData.title = this.tooltipDeleteText;
            }
            this.showDeleteModal = !this.showDeleteModal;
        },
        async deleteBranch() {
            this.showDeleteSpinner = true;
            await this.$store.dispatch('integrations/deleteBranch', { integrationId: this.integrationId, branchName: this.deleteModalData.name });
            await this.$store.dispatch('integrations/getBranchList', { integrationId: this.integrationId });
            this.showDeleteSpinner = false;
            this.toggleDeleteModal();
            this.branchNotification('removed');
        },
        generateModal(branchName = '', isDefault = false, isEditModal = false, branchId = '') {
            this.modalData.branchId = branchId;
            this.modalData.branchName = branchName;
            this.modalData.isDefault = isDefault;
            this.isEditModal = isEditModal;
        },
        closeModal() {
            this.$store.commit('integrations/setBranchError', null);
            this.isEditModal ? this.toggleEditModal() : this.toggleNewBranchModal();
        },
        toggleEditModal(event) {
            if (event) {
                this.generateModal(event.name, event.isDefault, true, event.id);
            } else {
                this.generateModal();
            }
            this.showEditModal = !this.showEditModal;
        },
        toggleNewBranchModal() {
            this.generateModal();
            this.showCreateModal = !this.showCreateModal;
        },
        async handleBranchForm(data) {
            this.showModalSpinner = true;
            if (this.isEditModal) {
                await this.updateBranch(data);
                await this.$store.dispatch('integrations/getBranchList', { integrationId: this.integrationId });
                this.showModalSpinner = false;
                if (this.branchError) return;
                this.branchNotification('updated');
            } else {
                await this.createNewBranch(data);
                await this.$store.dispatch('integrations/getBranchList', { integrationId: this.integrationId });
                this.showModalSpinner = false;
                if (this.branchError) return;
                this.branchNotification('created');
            }
            this.closeModal();
        },
        async createNewBranch(data) {
            await this.$store.dispatch('integrations/createBranch', { integrationId: this.integrationId, name: data.branchName });
        },
        async updateBranch(data) {
            if (this.modalData.branchName !== data.branchName) {
                await this.$store.dispatch('integrations/updateBranch', {
                    integrationId: this.integrationId,
                    branchName: this.modalData.branchName,
                    updateBranchName: data.branchName,
                });
            }
            if (this.modalData.isDefault !== data.isDefault && !this.branchError) {
                await this.$store.dispatch('integrations/updateIntegration', {
                    integrationId: this.integrationId,
                    data: {
                        defaultBranchId: this.modalData.branchId,
                    },
                });
            }
            if (!this.branchError) await this.$store.dispatch('integrations/getIntegration', { id: this.integrationId });
        },
        async makeDefaultBranch(event) {
            this.isShowSpinner = true;
            await this.$store.dispatch('integrations/updateIntegration', {
                integrationId: this.integrationId, data: { defaultBranchId: event.id },
            });
            await this.$store.dispatch('integrations/getIntegration', { id: this.integrationId });
            await this.$store.dispatch('integrations/getBranchList', { integrationId: this.integrationId });
            this.branchNotification('updated');
            this.isShowSpinner = false;
        },
        branchNotification,
    },
};
</script>
<style lang="scss">
@import "../../../assets/css/fonts";
@import "../../../assets/css/variables";

a.nav-link {
    color: #3180F6;
    vertical-align: middle;
    display: flex !important;
}
.inner-branch-table {
    overflow-y: hidden;

    &__container {
        border-radius: 20px;
        background: #fff;
        min-width: 700px;
        width: 85%;
        margin: 0 auto;
        padding: 0;
    }

    &__wrapper {
        background: #fff;
        border-radius: 20px;
        min-width: 700px;
        font-family: Cabin, sans-serif;

        thead {
            th {
                @include Aspekta-font(500, 14px, normal, #949DB8);
                letter-spacing: 0.42px;
                border-bottom: 1px solid #E9E9E9;
                vertical-align: middle;
                height: 85px;

                &:nth-child(2) {
                    text-align: left;
                }
            }
        }

        th {
            border-top: none !important;
        }

        tr {
            height: 85px;
            border-bottom: 1px solid #E9E9E9;

            & td:nth-child(1) {
                @include Aspekta-font(500, 18px, normal, #949DB8);
                letter-spacing: 0.54px;
                width: 5%;
            }

            & td:nth-child(2) {
                max-width: 400px;
                width: 30%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 10px !important;
            }

            & td:nth-child(3) {
                @include Aspekta-font(400, 14px, normal, #949DB8);
                letter-spacing: 0.28px;
                width: 20%

            }

            & td:nth-child(4) {
                @include Aspekta-font(400, 14px, normal, #949DB8);
                letter-spacing: 0.28px;
                width: 20%;
            }

            & td:nth-child(5) {
                @include Aspekta-font(400, 14px, normal, #949DB8);
                letter-spacing: 0.28px;
                width: 15%;
            }

            & td:nth-child(6) {
                width: 10%;
                border-top: none !important;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__pagination {
        padding: 0 0 20px 56px;
    }

    &__link {
        a {
            @include Aspekta-font(600, 18px, normal, #021631);
            letter-spacing: 0.54px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block !important;
        }
    }
    &__branch-type {
        color: #3180F6;
        cursor: pointer;
    }
    &__edit-btn {
        position: relative;

        img {
            max-width: 19px;
        }
        span {
            position: absolute;
            top: 20%;
            left: 40%;
            color: #B2B9C3;
            font-size: 18px;
        }
    }
    &__CREATED, &__IN_QUEUE, &__PROCESSING {
        color: #949DB8;
    }
    &__SCANNING {
        color: #4CE074;
    }
    &__FINISHED {
        color: #5C6A7A;
    }
    &__ERROR {
        color: #FB7462;
    }
    &__modal-wrapper {
        background-color: rgba(64, 61, 61, 0.5);
        width: 100%;
        height: 100vh;
        min-height: 500px;
        position: fixed;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
    }
}

#branch-table tbody tr td:nth-child(6) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    height: 84px;
    margin-right: 10px;

    .branch-btn {
        visibility: hidden;
    }

    #edit-branch-btn::before {
            content: "_";
            color: #B2B9C3;
            position: absolute;
            bottom: 8px;
            right: -25px;
            font-size: 19px;
        }



    button:nth-child(1) {
        .edit-branch-btn-title {
            @extend .btn-title;
            width: 50px;
            top: -10px;
            left: 40px;
            visibility: visible;
        }
    }

    button:nth-child(2) {
        .delete-branch-btn-title {
            @extend .btn-title;
            top: -30px;
            left: 90px;
            width: 60px;
            visibility: visible;
        }
    }
}

// hover on row
#branch-table tbody tr:hover {
    &:hover {
        .branch-btn {
            visibility: visible;
        }
    }
}

.edit-btn_span {
    color: #B2B9C3;
    position: absolute;
    top: 36px;
    left: 39px;
    font-size: 19px;
}


@media (max-width: 990px) {
    .inner-branch-table {

        &__link a {
            font-size: 14px !important;
        }

        tr {
            border-bottom: 1px solid #E9E9E9;

            & td:nth-child(1) {
                font-size: 14px;
                width: 2%;

            }

            & td:nth-child(2) {
                max-width: 100px;
                width: 22%;
            }

            & td:nth-child(3),
            & td:nth-child(4),
            & td:nth-child(5) {
                font-size: 14px;
                width: 22%;
            }

            & td:nth-child(6) {
                width: 6%;
            }
        }

        &__pagination {
            padding: 0 0 44px;
        }
    }

    #branch-table tbody tr td:nth-child(6) {
        .branch-btn {
            visibility: visible;
        }
    }


}

</style>
