import WarningIcon from '@/assets/iconTemplates/WarningIcon.vue';
import ErrorIcon from '@/assets/iconTemplates/ErrorIcon.vue';
import BranchesIcon from '@/assets/iconTemplates/BranchesIcon.vue';
import WebhookIcon from '@/assets/iconTemplates/WebhookIcon.vue';
import ActivationCodeIcon from '@/assets/iconTemplates/ActivationCodeIcon.vue';
import SuccessIcon from '@/assets/iconTemplates/SuccessIcon.vue';
import FailedIcon from '@/assets/iconTemplates/FailedIcon.vue';
import MemberIcon from '@/assets/iconTemplates/MemberIcon.vue';
import TokenIcon from '@/assets/iconTemplates/TokenIcon.vue';
import PaymentCardIcon from '@/assets/iconTemplates/PaymentCardIcon.vue';

const TOAST_TITLE_CLASS = 'toast-title';
const NUMBER_SHOWING_FILES = 2;

const WARNING_WARNING = 'Warning.';

const ERROR_INVALID = 'Error. Invalid files:';
const ERROR_NOTFOUND = 'Error. Did not find ';
const ERROR_BAD_REQUEST = 'Error. Bad request:';
const ERROR_DEFAULT_MESSAGE = 'Something went wrong.';

const BRANCH_CREATED = 'Your new branch has been created successfully!';
const BRANCH_UPDATED = 'Your branch has been updated successfully!';
const BRANCH_REMOVED = 'Your branch has been removed successfully!';

const WEBHOOK_CREATED = 'Your webhook has been created successfully!';
const WEBHOOK_UPDATED = 'Your webhook settings have been updated successfully!';
const WEBHOOK_REMOVED = 'Your webhook has been removed successfully!';

const CODE_SUCCESS = 'Your code has been activated successfully!';
const CODE_INVALID = 'Oops! The code you entered is invalid. Please double-check and try again!';
const CODE_DIFFERENT = 'Sorry! This code has already been activated. Please try a different code!';

const SCANS_CHARGED = 'Scans have been charged to current account successfully!';

const FAIL = 'Oops! Something went wrong';
const FAIL_WRONG_PLATFORM = 'The file has a wrong platform. Please try again.';
const FAIL_ONLY_ONE_FILE = 'Only one file can be uploaded at a time.';
const FAIL_ALREADY_EXIST = 'Folder with this name already exists';

const MEMBER_JOIN = 'You\'ve been added to the Integration successfully!';
const MEMBER_LEFT = 'You\'ve been left from the Integration successfully!';
const MEMBER_LEFT_USER = 'has been removed successfully!';

const CARD_ADDED = 'Your payment card has been added successfully!';
const CARD_REMOVED = 'Payment card has been removed successfully!';
const CARD_DECLINED = 'We\'re sorry, but your payment method has been declined. Please try a different card or try again later';

const MFA_DEVICE_ASSIGNED = 'Your MFA device has been assigned successfully!';
const MFA_DEVICE_REMOVED = 'Your MFA device has been removed successfully!';
const MFA_DEVICE_ASSIGN_FAIL = 'Fail to assign MFA device!';

const TOKEN_REMOVED = 'Your token has been successfully removed';

const INTEGRATION_RENEWAL_SEND = 'Request has been sent!';
const INTEGRATION_MEMBER_SEND = 'Invitation has been sent successfully!';
const SETTINGS_UPDATED = 'Your general settings have been updated successfully!';
const COMPANY_INFO_UPDATED = 'Your company info has been updated successfully!';
const CONTACT_US_SEND = 'Request has been sent successfully!';
const QUESTION_SEND = 'Thank you for reaching out to us! We have received your message, and our team will reply soon.';
const REPORT_UNLOCKED = 'Report has been unlocked';
const REPORT_DELETED = 'Report has been deleted successfully!';
const FOLDER_DELETED = 'Folder has been deleted successfully!';
const FOLDER_CREATED = 'Folder has been created successfully!';
const FOLDER_UPDATED = 'Folder has been updated successfully!';

/**
 * functions to show different notifications
 * @param {String} typeVariant - different variants of notification types
 * @param {obj} {...params} - custom data for individual notification
 */

function warningScanNotification (fileNames) {
    const typeVariant = 'warning';
    const titleText = `${titleTextMap.warning[typeVariant]} Duplicated ${fileNames.length > 1 ? 'files' : 'file'} found and deleted:`;
    const titleIcon = titleIconMap.warning;
    const title = _createTitle(this.$createElement, titleText, titleIcon, typeVariant);
    const body = _createBodyWarningAndError(this.$createElement, 'warning', { fileNames });
    _createNotification(this, title, body);
}
function errorScanNotification (typeVariant, { fileNames, innerMissedFileNames }) {
    let title;
    if (typeVariant === 'notFound') {
        const titleText = _notFoundErrorText(innerMissedFileNames, fileNames);
        const titleIcon = titleIconMap.error;
        title = _createTitle(this.$createElement, titleText, titleIcon, typeVariant);
    } else {
        title = _createStaticTitle(this, 'error', typeVariant);
    }
    const body = _createBodyWarningAndError(this.$createElement, 'error', { fileNames });
    _createNotification(this, title, body);
}
function branchNotification (typeVariant) {
    const title = _createStaticTitle(this, 'branch', typeVariant);
    _createNotification(this, title);
}
function webhookNotification (typeVariant) {
    const title = _createStaticTitle(this, 'webhook', typeVariant);
    _createNotification(this, title);
}
function activationCodeNotification (typeVariant) {
    const title = _createStaticTitle(this, 'code', typeVariant);
    _createNotification(this, title);
}
//simple "warning" notification with custom text
function warningNotification (message) {
    const typeVariant = 'warning';
    const titleText = `${titleTextMap.warning[typeVariant]} ${message}`;
    const titleIcon = titleIconMap.warning;
    const title = _createTitle(this.$createElement, titleText, titleIcon, typeVariant);
    _createNotification(this, title);
}
//simple "success" notification with custom text
function successNotification (typeVariant) {
    const title = _createStaticTitle(this, 'success', typeVariant);
    _createNotification(this, title);
}
//simple "Oops" notification with constant text
function failNotification (failTypeVariant) {
    const typeVariant = failTypeVariant || 'fail';
    const title = _createStaticTitle(this, 'fail', typeVariant);
    _createNotification(this, title);
}
// simple Error notification with custom text
function errorNotification ({ typeVariant = 'badRequest', message = ERROR_DEFAULT_MESSAGE }) {
    const titleText = `${titleTextMap.error[typeVariant]} ${message}`;
    const titleIcon = titleIconMap.error;
    const title = _createTitle(this.$createElement, titleText, titleIcon, typeVariant);
    _createNotification(this, title);
}
// single Success notification with custom text
function customSuccessNotification (message) {
    const typeVariant = 'success';
    const titleText = `${message}`;
    const titleIcon = titleIconMap.success;
    const title = _createTitle(this.$createElement, titleText, titleIcon, typeVariant);
    _createNotification(this, title);
}

function memberNotification (typeVariant, userName) {
    let title;
    if (typeVariant === 'leftUser' && userName) {
        const titleText = `${userName} ${titleTextMap.member[typeVariant]}`;
        const titleIcon = titleIconMap.member;
        title = _createTitle(this.$createElement, titleText, titleIcon, typeVariant);
    } else {
        title = _createStaticTitle(this, 'member', typeVariant);
    }
    _createNotification(this, title);
}
function tokenNotification (typeVariant) {
    const title = _createStaticTitle(this, 'token', typeVariant);
    _createNotification(this, title);
}
function mfaDeviceNotification (typeVariant) {
    const title = _createStaticTitle(this, 'mfaDevice', typeVariant);
    _createNotification(this, title);
}
function paymentCardNotification (typeVariant) {
    const title = _createStaticTitle(this, 'card', typeVariant);
    _createNotification(this, title);
}

/**
 * helper functions
 */
const _createStaticTitle = (instance, type, typeVariant) => {
    const titleText = titleTextMap[type][typeVariant];
    const titleIcon = titleIconMap[type];
    return _createTitle(instance.$createElement, titleText, titleIcon, typeVariant);
};
const _createTitle = (createElement, titleText, titleIcon, typeVariant) => {
    const titleIconElement = createElement(titleIcon, { props: { variant: typeVariant } });
    const titleTextElement = createElement('div', titleText);
    return createElement('div', { class: TOAST_TITLE_CLASS }, [
        titleIconElement,
        titleTextElement,
    ]);
};
const _createBodyWarningAndError = (createElement, type, { fileNames }) => {
    if (fileNames.length > NUMBER_SHOWING_FILES) {
        const firstFileNameElement = createElement('div', fileNames[0]);
        const hiddenFileNameElements = fileNames.slice(NUMBER_SHOWING_FILES-1, fileNames.length).map(app => createElement('div', { class: 'hidden-files' }, app));
        const showMoreButton = createElement('div', { class: 'show-more', on: {
            click() {
                const element = document.querySelector(`.names-list-${type}`);
                element.classList.add('show');
            },
        } }, `Show ${fileNames.length-1} more`);
        const showLessButton = createElement('div', { class: 'show-less', on: {
            click() {
                const element = document.querySelector(`.names-list-${type}`);
                element.classList.remove('show');
            },
        } }, 'Show less');
        return createElement('div', { class: `names-list-${type}` }, [firstFileNameElement, showMoreButton, hiddenFileNameElements, showLessButton]);
    }
    const fileNameElements = fileNames.map(app => createElement('div', app));
    return createElement('div', { class: 'names-list' }, fileNameElements);
};
const _notFoundErrorText = (innerMissedFileNames, fileNames) => {
    return `${ERROR_NOTFOUND} ${innerMissedFileNames.join(' and ')} ${innerMissedFileNames.length < 2 ? 'file' : 'files'} in the following ${fileNames.length < 2 ? 'file' : 'files'}:`;
};
const _createNotification = (instance, title, body = []) => {
    instance.$bvToast.toast(body, {
        title,
        solid: true,
        noAutoHide: false,
        noCloseButton: true,
        toaster: 'b-toaster-top-center',
        toastClass: `general-toast ${body.tag ? 'body' : ''}`,
        headerClass: 'general-toast__header',
        bodyClass: 'general-toast__body',
    });
};

const titleIconMap = {
    warning: WarningIcon,
    error: ErrorIcon,
    branch: BranchesIcon,
    webhook: WebhookIcon,
    code: ActivationCodeIcon,
    success: SuccessIcon,
    fail: FailedIcon,
    member: MemberIcon,
    token: TokenIcon,
    card: PaymentCardIcon,
};
const titleTextMap = {
    warning: {
        warning: WARNING_WARNING,
    },
    error: {
        invalid: ERROR_INVALID,
        notFound: ERROR_NOTFOUND,
        badRequest: ERROR_BAD_REQUEST,
    },
    branch: {
        created: BRANCH_CREATED,
        updated: BRANCH_UPDATED,
        removed: BRANCH_REMOVED,
    },
    webhook: {
        created: WEBHOOK_CREATED,
        updated: WEBHOOK_UPDATED,
        removed: WEBHOOK_REMOVED,
    },
    code: {
        success: CODE_SUCCESS,
        invalid: CODE_INVALID,
        different: CODE_DIFFERENT,
    },
    success: {
        renewalSend: INTEGRATION_RENEWAL_SEND,
        memberSend: INTEGRATION_MEMBER_SEND,
        settingsUpdated: SETTINGS_UPDATED,
        companyInfoUpdated: COMPANY_INFO_UPDATED,
        contactUsSend: CONTACT_US_SEND,
        questionSend: QUESTION_SEND,
        reportUnlocked: REPORT_UNLOCKED,
        reportDeleted: REPORT_DELETED,
        folderCreated: FOLDER_CREATED,
        folderDeleted: FOLDER_DELETED,
        folderUpdated: FOLDER_UPDATED,
        scansCharged: SCANS_CHARGED,
    },
    fail: {
        fail: FAIL,
        wrongPlatform: FAIL_WRONG_PLATFORM,
        onlyOneFile: FAIL_ONLY_ONE_FILE,
        alreadyExist: FAIL_ALREADY_EXIST,
    },
    member: {
        join: MEMBER_JOIN,
        left: MEMBER_LEFT,
        leftUser: MEMBER_LEFT_USER,
    },
    token: {
        removed: TOKEN_REMOVED,
    },
    card: {
        added: CARD_ADDED,
        removed: CARD_REMOVED,
        declined: CARD_DECLINED,
    },
    mfaDevice: {
        removed: MFA_DEVICE_REMOVED,
        assigned: MFA_DEVICE_ASSIGNED,
        assignFail: MFA_DEVICE_ASSIGN_FAIL
    }
};

export {
    warningScanNotification,
    errorScanNotification,
    branchNotification,
    webhookNotification,
    activationCodeNotification,
    warningNotification,
    successNotification,
    customSuccessNotification,
    failNotification,
    errorNotification,
    memberNotification,
    tokenNotification,
    paymentCardNotification,
    mfaDeviceNotification
};
