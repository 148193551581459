import axios from "axios";
var jwt = require('jsonwebtoken');
import {
    deleteItemsFromLocalStorage,
    setItemsToLocalStorage,
} from "@/services/functions";
import {TEN_SECONDS_IN_MS} from "../../utils/constants/common";

const VUE_APP_AUTH_BASE_URL = process.env.VUE_APP_AUTH_BASE_URL

export const getAccessTokenHandler = async () => {
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    let decodedAccessToken = null
    let decodedRefreshToken = null
    let isAccessTokenExpired = null
    let isRefreshTokenExpired = null

    if (accessToken && refreshToken) {
        decodedAccessToken = jwt.decode(accessToken)
        decodedRefreshToken = jwt.decode(refreshToken)
        // Expiration time of access token is 1 min. But we go to keycloak for new one after 50 seconds
        isAccessTokenExpired = (decodedAccessToken.exp * 1000 - TEN_SECONDS_IN_MS) < Date.now()
        isRefreshTokenExpired = (decodedRefreshToken.exp * 1000) < Date.now()
    } else {
        return null
    }

    if (isAccessTokenExpired && !isRefreshTokenExpired) {
        try {
            const response = await axios.post(
                `${VUE_APP_AUTH_BASE_URL}/authorization/refresh-token`,
                {refresh_token: refreshToken}
            )
            const newAccessToken = response.data.access_token
            const newRefreshToken = response.data.refresh_token
            const idToken = response.data.id_token || null

            setItemsToLocalStorage([
                ['accessToken', newAccessToken],
                ['refreshToken', newRefreshToken],
                ['idToken', idToken]
            ])

            return  newAccessToken
        } catch (error) {
            deleteItemsFromLocalStorage(['accessToken', 'refreshToken', 'idToken', 'integrationsCount'])
            document.location.reload()
            console.log(error, 'error')
        }
    } else if (isAccessTokenExpired && isRefreshTokenExpired) {
        await axios.post(
            `${VUE_APP_AUTH_BASE_URL}/user/deactivate-session`,
            {},
            {headers: {Authorization: `Bearer ${accessToken}`}}
        )
        deleteItemsFromLocalStorage(['accessToken', 'refreshToken', 'idToken', 'integrationsCount'])
        document.location.reload()
        return null
    } else {
        return accessToken
    }
}
