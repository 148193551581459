<template>
    <div class="payment__wrapper">
        <div class="payment__switch">
            <div id="my" 
                class="payment__switch-button"
                :class="switchCards === 'my' && 'active'"
                @click="switchMethod"
            >
                My Cards
            </div>
            <div id="add" 
                class="payment__switch-button"
                :class="switchCards === 'add' && 'active'"
                @click="switchMethod"
            >
                Add New Card
            </div>
        </div>
        <div class="payment__type">
            <div v-if="switchCards === 'my'" class="payment__type-my-cards">
                <payment-card 
                    v-for="card in cards" 
                    ref="payment"
                    :key="card.id"
                    :card="card"
                    :selected="cardSelected === card.id"
                    @selectCard="selectedCard"
                />
            </div>
            <div v-else-if="switchCards === 'add'" class="payment__type-new-card">
                <div>
                    <new-card
                        ref="newCard" 
                        :cardElement="this.cardElement"
                        :elements="this.stripeElements"
                        :saveCard="saveCard"
                        @on-change="onCardDataChange"
                        @save-card="onSaveCard"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewCard from "../NewCard.vue";
import PaymentCard from './PaymentCard.vue';

const SELECTED_CARD = 'selected-card';

export default {
    name: 'PaymentCardsBlock',
    components: { 
        NewCard, 
        PaymentCard
    },
    props: ['cards', 'cardSelected', 'stripe', 'stripeElements', 'cardElement', 'saveCard'],
    data() {
        return {
            switchCards: this.cards.length ? 'my' : 'add',
        }
    },
    beforeDestroy() {
        this.destroyCard()
    },
    methods: {
        switchMethod({target}) {
            this.switchCards = target.id
            if (target.id === 'add') {
                this.$emit(SELECTED_CARD, null)
            } else {
                this.$emit(SELECTED_CARD, null)
                this.destroyCard()
            }
        },
        selectedCard(cardId) {
            this.$emit(SELECTED_CARD, cardId)
        },
        clearNewCard() {
            this.$refs.newCard.clearNewCard() 
        },
        destroyCard() {
            if (this.$refs.newCard) {
                this.$refs.newCard.destroyElements()
                this.$emit('stripe', null, null)
            }
        },
        onCardDataChange(data) {
            this.$emit('new-card', data)
        },
        onSaveCard(isSave) {
            this.$emit('save-card', isSave)
        },
    }
}
</script>

<style lang="scss">
@import "@/assets/css/variables";

.payment {
    &__wrapper {
        width: 100%;
    }
    &__switch {
        display: flex;
        width: 330px;
        background-color: $background-ghost;
        border-radius: 13px;
        padding: 4px;
        &-button {
            padding: 12px 24px;
            width: 50%;
            text-align: center;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 700;
            color: $secondary-text;
            background-color: transparent;
            &:hover {
                cursor: pointer;
            }
        }
        &-button.active {
            background-color: #fff;
            color: $main-text;
        }
    }
    &__type {
        &-my-cards, &-new-card {
            margin-top: 30px;
        }
        &-my-cards {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
            row-gap: 10px;
        }
    }
}

@media (max-width: 1500px) {
    .payment {
        &__type {
            &-my-cards, &-new-card {
                margin-top: 20px;
            }
        }
    }
}
@media (max-width: 1060px) {
    .payment {
        &__type {
            &-my-cards {
                    grid-template-columns: 1fr;
            }
        }
    }
}
</style>
