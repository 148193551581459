<template>
    <div class="api">
        <b-container>
            <h1 v-if="rootRoute === 'quick-start'" class="api__title">Quick Start</h1>
            <h1 v-if="rootRoute === 'ci-cd'" class="api__title">CI/CD tools</h1>
            <h1 v-if="rootRoute === 'api'" class="api__title">API Documentation</h1>
            <h1 v-if="rootRoute === 'webhooks'" class="api__title">Webhooks</h1>
            <h1 v-if="rootRoute === 'configurations'" class="api__title">Scan Configurations</h1>
            <div class="api-doc-wrapper">
                <div v-click-outside="hide" class="menu" @click="toggleMenu()">
                    <div class="menu__toggle-wrapper"/>
                    <div>
                        <b-nav-item :to="{ path: `${makeLinkToAnchorQuickStart('')}`}" class="menu__root-link-wrapper"
                                    :class="{ 'menu__root-link-wrapper--active': rootRoute === 'quick-start'}">
                            <h2 class="menu__item-title">Quick Start</h2>
                        </b-nav-item>
                        <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('')}`}" class="menu__root-link-wrapper"
                                    :class="{ 'menu__root-link-wrapper--active': rootRoute === 'ci-cd'}">
                            <h2 class="menu__item-title">CI/CD tools</h2>
                        </b-nav-item>
                        <div class="menu__links-wrapper" v-if="rootRoute === 'ci-cd'">
                            <div>
                                <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('')}`}" class="menu__link-wrapper"
                                            :class="{ 'menu__link-wrapper--active': route === 'ci-cd/'}">
                                    <h2 class="menu__item-title">Supported CI/CD tools</h2>
                                </b-nav-item>
                                <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('bitrise')}`}" class="menu__link-wrapper"
                                            :class="{ 'menu__link-wrapper--active': route === 'ci-cd/bitrise'}">
                                    <h2 class="menu__item-title">Bitrise</h2>
                                </b-nav-item>
                                <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('jenkins')}`}" class="menu__link-wrapper"
                                            :class="{ 'menu__link-wrapper--active': route === 'ci-cd/jenkins'}">
                                    <h2 class="menu__item-title">Jenkins</h2>
                                </b-nav-item>
                                <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('gradle')}`}" class="menu__link-wrapper"
                                            :class="{ 'menu__link-wrapper--active': route === 'ci-cd/gradle'}">
                                    <h2 class="menu__item-title">Gradle</h2>
                                </b-nav-item>
                                <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('circleci')}`}" class="menu__link-wrapper"
                                            :class="{ 'menu__link-wrapper--active': route === 'ci-cd/circleci'}">
                                    <h2 class="menu__item-title">CircleCI</h2>
                                </b-nav-item>
                                <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('travis')}`}" class="menu__link-wrapper"
                                            :class="{ 'menu__link-wrapper--active': route === 'ci-cd/travis'}">
                                    <h2 class="menu__item-title">Travis CI</h2>
                                </b-nav-item>
                            </div>
                        </div>

                        <b-nav-item :to="{ path: `${makeLinkToAnchor('')}`}" class="menu__root-link-wrapper"
                                    :class="{ 'menu__root-link-wrapper--active': rootRoute === 'api'}">
                            <h2 class="menu__item-title">API Documentation</h2>
                        </b-nav-item>
                        <div class="menu__links-wrapper" v-if="rootRoute === 'api'">
                            <div>
                                <b-nav-item :to="{ path: `${makeLinkToAnchor('')}`}" class="menu__link-wrapper"
                                            :class="{ 'menu__link-wrapper--active': route === 'api/'}">
                                    <h2 class="menu__item-title">Introduction</h2>
                                </b-nav-item>
                                <ul v-if="route === 'api/'" class="menu__list-sub-items">
                                    <li><a :href="makeSubLinkToAnchor('Different types of scan', '')">Different types of
                                        scan</a></li>
                                    <li><a :href="makeSubLinkToAnchor('Getting started with the Oversecured API', '')">Getting
                                        started
                                        with the Oversecured API</a></li>
                                    <li><a :href="makeSubLinkToAnchor('Creating an API access key', '')">Creating an API
                                        access
                                        key</a>
                                    </li>
                                    <li><a :href="makeSubLinkToAnchor('Restrictions', '')">Restrictions</a></li>
                                </ul>
                            </div>
                            <!--            Profile and Categories-->
                            <div>
                                <b-nav-item :to="{ path: `${makeLinkToAnchor('profile-and-categories')}`}"
                                            :class="{ 'menu__link-wrapper--active': route === 'api/profile-and-categories'}"
                                            class="menu__link-wrapper">
                                    <h2 class="menu__item-title">Profile and Categories</h2>
                                </b-nav-item>
                                <ul v-if="route === 'api/profile-and-categories'" class="menu__list-sub-items">
                                    <li><a
                                        :href="makeSubLinkToAnchor('Obtaining a list of cards', 'profile-and-categories')">Obtaining
                                        a list of cards</a>
                                    </li>
                                    <li><a
                                        :href="makeSubLinkToAnchor('General account data', 'profile-and-categories')">General
                                        account data</a></li>
                                    <li><a :href="makeSubLinkToAnchor('List of categories', 'profile-and-categories')">List
                                        of
                                        categories</a></li>
                                </ul>
                            </div>
                            <!--            integrations-->
                            <div>
                                <b-nav-item :to="{ path: `${makeLinkToAnchor('integrations')}`}"
                                            class="menu__link-wrapper"
                                            :class="{ 'menu__link-wrapper--active': route === 'api/integrations'}">
                                    <h2 class="menu__item-title">Integrations</h2>
                                </b-nav-item>
                                <ul v-if="route === 'api/integrations'" class="menu__list-sub-items">
                                    <li><a
                                        :href="makeSubLinkToAnchor('Possible Integrations statuses', 'integrations')">Possible
                                        Integrations
                                        statuses</a></li>
                                    <li><a
                                        :href="makeSubLinkToAnchor('Getting a list of Integrations', 'integrations')">Getting
                                        a list of Integrations</a></li>
                                    <li><a :href="makeSubLinkToAnchor('Changing Integration settings', 'integrations')">Changing
                                        Integration
                                        settings</a></li>
                                    <li><a :href="makeSubLinkToAnchor('List of versions', 'integrations')">List of
                                        versions</a>
                                    </li>
                                    <li><a :href="makeSubLinkToAnchor('Adding a new version', 'integrations')">Adding a
                                        new
                                        version</a></li>
                                    <li><a :href="makeSubLinkToAnchor('Deleting a version', 'integrations')">Deleting a
                                        version</a></li>
                                    <li><a :href="makeSubLinkToAnchor('Comparison of versions (Statistics)', 'integrations')">Comparison
                                      of
                                      versions (Statistics)</a></li>
                                    <li><a :href="makeSubLinkToAnchor('Comparison of versions (Vulnerabilities)', 'integrations')">Comparison
                                      of
                                      versions (Vulnerabilities)</a></li>
                                    <li><a :href="makeSubLinkToAnchor('Downloading a PDF of the comparison', 'integrations')">Downloading a PDF of the comparison</a></li>
                                    <li><a
                                        :href="makeSubLinkToAnchor('Reading the vulnerability report', 'integrations')">Reading
                                        the
                                        vulnerability report</a></li>
                                </ul>
                            </div>
                            <!--            Single Scans-->
                            <div>
                                <b-nav-item 
                                    v-show="!isUserCompany"
                                    :to="{ path: `${makeLinkToAnchor('single-scans')}`}"
                                    class="menu__link-wrapper"
                                    :class="{ 'menu__link-wrapper--active': route === 'api/single-scans'}">
                                    <h2 class="menu__item-title">Single Scans</h2>
                                </b-nav-item>
                                <ul v-if="route === 'api/single-scans'" class="menu__list-sub-items">
                                    <li><a :href="makeSubLinkToAnchor('Getting a list of apps', 'single-scans')">Getting
                                        a list
                                        of apps</a></li>
                                    <li><a :href="makeSubLinkToAnchor('Deleting a scan report', 'single-scans')">Deleting
                                        a scan
                                        report</a></li>
                                    <li><a :href="makeSubLinkToAnchor('Making a report public', 'single-scans')">Making
                                        a report
                                        public</a></li>
                                    <li><a :href="makeSubLinkToAnchor('Submitting apps to be scanned using a card', 'single-scans')">
                                        Submitting apps to be scanned using a card
                                    </a></li>
                                    <li><a :href="makeSubLinkToAnchor('Submitting apps to be scanned when you have available scans', 'single-scans')">Submitting apps to be scanned when you have available scans</a></li>
                                    <li><a
                                        :href="makeSubLinkToAnchor('Reading the vulnerability report', 'single-scans')">Reading
                                        the
                                        vulnerability report</a></li>
                                </ul>
                            </div>
                            <!--            upload-->
                            <div>
                                <b-nav-item :to="{ path: `${makeLinkToAnchor('uploading')}`}" class="menu__link-wrapper"
                                            :class="{ 'menu__link-wrapper--active': route === 'api/uploading'}">
                                    <h2 class="menu__item-title">Uploading files</h2>
                                </b-nav-item>
                                <ul v-if="route === 'api/uploading'" class="menu__list-sub-items">
                                    <li><a :href="makeSubLinkToAnchor('Uploading apps', 'uploading')">Uploading apps</a>
                                    </li>
                                    <li><a :href="makeSubLinkToAnchor('Uploading images', 'uploading')">Uploading
                                        images</a></li>
                                </ul>
                            </div>
                            <!--            vulnerabilities-->
                            <div>
                                <b-nav-item :to="{ path: `${makeLinkToAnchor('vulnerabilities')}`}"
                                            :class="{ 'menu__link-wrapper--active': route === 'api/vulnerabilities'}"
                                            class="menu__link-wrapper">
                                    <h2 class="menu__item-title">Working with vulnerabilities</h2>
                                </b-nav-item>
                                <ul v-if="route === 'api/vulnerabilities'" class="menu__list-sub-items">
                                    <li><a
                                        :href="makeSubLinkToAnchor('Possible scan statuses', 'vulnerabilities')">Possible
                                        scan statuses</a></li>
                                    <li><a
                                        :href="makeSubLinkToAnchor('General report statistics', 'vulnerabilities')">General
                                        report statistics</a>
                                    </li>
                                    <li><a
                                        :href="makeSubLinkToAnchor('Listing of vulnerabilities', 'vulnerabilities')">Listing
                                        of vulnerabilities</a>
                                    </li>
                                    <li><a
                                        :href="makeSubLinkToAnchor('Moving vulnerabilities between Folders', 'vulnerabilities')">Moving
                                      vulnerabilities between Folders</a></li>
                                    <li><a :href="makeSubLinkToAnchor('Downloading a PDF report', 'vulnerabilities')">Downloading
                                        a PDF report</a></li>
                                </ul>
                            </div>
                        </div>
                        <!--            Webhooks-->
                        <b-nav-item :to="{ path: `${makeLinkToAnchorWebhooks('webhooks')}`}" class="menu__root-link-wrapper"
                                    :class="{ 'menu__root-link-wrapper--active': rootRoute === 'webhooks'}">
                            <h2 class="menu__item-title">Webhooks</h2>
                        </b-nav-item>
                        <!--            Scan Configurations-->
                        <b-nav-item :to="{ path: `${makeLinkToAnchorScanConfigurations('')}`}" class="menu__root-link-wrapper"
                                    :class="{ 'menu__root-link-wrapper--active': rootRoute === 'configurations'}">
                            <h2 class="menu__item-title">Scan Configurations</h2>
                        </b-nav-item>
                        <div class="menu__links-wrapper" v-if="rootRoute === 'configurations'">
                            <div>
                                <b-nav-item :to="{ path: `${makeLinkToAnchorScanConfigurations('')}`}" class="menu__link-wrapper"
                                            :class="{ 'menu__link-wrapper--active': route === 'configurations/'}">
                                    <h2 class="menu__item-title">Introduction</h2>
                                </b-nav-item>
                                <b-nav-item :to="{ path: `${makeLinkToAnchorScanConfigurations('ios')}`}" class="menu__link-wrapper"
                                            :class="{ 'menu__link-wrapper--active': route === 'configurations/ios'}">
                                    <h2 class="menu__item-title">Configuration for iOS</h2>
                                </b-nav-item>
                                <b-nav-item :to="{ path: `${makeLinkToAnchorScanConfigurations('smart-filter')}`}" class="menu__link-wrapper"
                                            :class="{ 'menu__link-wrapper--active': route === 'configurations/smart-filter'}">
                                    <h2 class="menu__item-title">SmartFilter</h2>
                                </b-nav-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="md">
<!--                    <vue-markdown :source="source"/>-->
                    <router-view/>
                </div>
            </div>
        </b-container>
    </div>
</template>
<script>
import VueMarkdown from "vue-markdown/src/VueMarkdown";
import { mapGetters } from 'vuex';
import {makeAnchor, makeLinkToAnchor, makeLinkToAnchorCICD, makeLinkToAnchorQuickStart, makeLinkToAnchorScanConfigurations, makeLinkToAnchorWebhooks} from "../services/functions";
import {makeSubLinkToAnchor} from "@/services/functions";
import ClickOutside from 'vue-click-outside'

export default {
    components: {
        // VueMarkdown,
    },
    directives: {
        ClickOutside
    },
    data() {
        return {
            showMenu: false,
            route: '',
            rootRoute: '',
        };
    },
    computed: {
        ...mapGetters({
            userType: 'user/userType',
        }),
        isUserCompany(){
            return this.userType === 'company';
        },
    },
    mounted() {
        this.getRoute();
    },
    watch: {
        $route() {
            this.getRoute()
            this.toSection()
        },
        showMenu() {
            const element = document.querySelector('.menu')

            if (this.showMenu) {
                element.classList.add('menu__show-menu')
            } else {
                element.classList.remove('menu__show-menu')
            }
        }
    },
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor,
        makeLinkToAnchorQuickStart,
        makeLinkToAnchorCICD,
        makeLinkToAnchorScanConfigurations,
        makeLinkToAnchorWebhooks,
        getRoute() {
            const routePath = this.$route.path
            this.rootRoute = routePath.split('/')[2]
            this.route = `${routePath.split('/')[2]}/${routePath.split('/')[3]}`
        },
        toSection() {
            let hash = this.$route.hash.replace('#', '')

            if (hash) {
                setTimeout(() => {
                    document.getElementById(hash).scrollIntoView();
                    hash = null
                }, 0)
            }
        },
        toggleMenu() {
            this.showMenu = !this.showMenu
        },
        hide() {
            this.showMenu = false
        }
    }
}
</script>
<style lang="scss">
.api {
    background-color: #FAFBFC;
    padding-top: 50px;

    &__title {
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 56px;
        line-height: 110%;
        color: #242424;

        margin-bottom: 20px;
    }
}

.api-doc-wrapper {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-column-gap: 25px;
}

.menu {
    background: white;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px 10px;
    box-shadow: 0 4px 15px rgba(102, 124, 131, 0.06);
    z-index: 2;

    &__toggle-wrapper {
        display: none;
    }

    &__links-wrapper {
        padding-left: 10px;
    }

    &__root-link-wrapper {
        &--active {
            background-color: #FFB400;
            border-radius: 10px;
        }

        a.nav-link {
            margin: 0;
            padding: 3px 5px;
        }
    }

    &__link-wrapper {
        box-sizing: border-box;

        a.nav-link {
            margin: 0;
            padding: 3px 5px;
        }

        &--active {
            background-color: #e3e3e3;
            box-shadow: inset 20px 20px 60px #d9d9d9,
            inset -20px -20px 60px #ffffff;
            border-radius: 5px;
        }
    }

    &__list-sub-items {
        list-style: none;
        padding-left: 25px;
        border-radius: 50px;

        li {
            font-family: Cabin, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
            margin: 8px 0;

            a {
                color: #242424;
                line-height: 150%;
            }

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    &__item-title {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        display: flex;
        align-items: center;
        color: #242424;
        cursor: pointer;
        margin: 10px 0;
        width: 100%;

        &:hover {
            text-decoration: underline;
        }
    }
}

.md {
    .markdown {
        margin-bottom: 100px;

        &__h3-title {
            font-family: Raleway, sans-serif;
        }

        &__download-link {
            color: #037BFE;

            &:hover {
                text-decoration: underline;
                color: #0152ac;
                cursor: pointer;
            }
        }

        &__image {
            width: 100%;
            margin: 20px 0;
        }

        &__fit-link {
            word-break: break-word;
        }

        li.nav-item {
            display: inline-block;
            margin: 0;

            a.nav-link {
                font-size: 16px !important;
                margin: 0;
                padding: 0;
            }
        }
    }

    &__link-wrapper {
        display: flex;
        align-items: center;

        a.nav-link {
            margin: 0;
        }

        &:hover .md__anchor {
            visibility: visible;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__anchor {
        font-size: 30px;
        color: #242424;
        padding-right: 10px;
        visibility: hidden;
    }

    h2 {
        position: relative;
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 150%;
        color: #242424;
        margin-bottom: 20px;

        &:hover {
            text-decoration: underline;
        }

        &:hover:before {
            content: "#";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 0;
            left: -23px;
        }
    }

    h3 {
        margin: 20px 0;
        position: relative;

        a {
            color: #242424;
            font-size: 26px;
            font-weight: bold;
            font-family: Raleway, serif;

            &:hover:before {
                content: "#";
                position: absolute;
                width: 20px;
                height: 20px;
                top: 2px;
                left: -20px;
            }
        }
    }
}

@media (max-width: 766px) {
    .api {
        padding: 0 20px;

        &__title {
            font-size: 36px;
            word-break: break-word;
        }
    }

    .api-doc-wrapper {
        display: grid;
        grid-template-columns: 100%;
        grid-column-gap: 20px;
        position: relative;
    }

    .menu {
        position: fixed;
        top: 71px;
        left: 0;
        overflow: auto;
        max-width: 300px;
        width: 100%;
        height: 88%;
        transform: translateX(-285px);
        transition: 0.3s;

        &__toggle-wrapper {
            display: block;
            position: absolute;
            top: 58px;
            right: 0;
            background-color: #FFB400;
            width: 20px;
            height: 50px;
        }

        &__show-menu {
            transform: translateX(0);
            animation: api__menu;
            animation-duration: 0.7s;
        }

        &__root-link-wrapper {
            max-width: 220px;
        }

        &__link-wrapper {
            max-width: 210px;
        }

        &__item-title {
            max-width: 210px;

            &:hover {
                text-decoration: underline;
            }
        }

        &__list-sub-items {
            list-style: none;
            padding-left: 25px;
            border-radius: 50px;

            li {
                max-width: 200px;
            }
        }
    }
}

@keyframes api__menu {
    0% {
        transform: translateX(-285px);
    }

    50% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}
</style>
