import router from "@/router";
import Vue from 'vue'

const VUE_APP_ZENDESK_URI = process.env.VUE_APP_ZENDESK_URI

export const routeUserToPageByTypeAfterLogin = async (userType, isGetStartedCompleted, redirect) => {
    if (redirect) {
        await router.push({path: redirect})
    } else if (userType && userType === 'company') {
        /**
         * If the user is a company user, we will redirect them to the integrations page if they have completed the get started process
         * Otherwise, we will redirect them to the get started page
         * Note: Logic disabled for now
         */
        // if (isGetStartedCompleted) {
        //     await router.push({name: 'Integrations'})
        // } else {
        //     await router.push({name: 'GetStarted'})
        // }
        await router.push({name: 'Integrations'})
    } else {
        await router.push({name: 'Scans'})
    }
}

export const redirectUserToZendeskAfterLogin = async () => {
    const {data} = await Vue.axios.get('/private/ui/zendesk/payload')
    if (data && data.payload) {
        document.location.href = `${VUE_APP_ZENDESK_URI}/access/jwt?jwt=${data.payload}`
    } else {
        await router.push({name: 'Root'})
    }
}
