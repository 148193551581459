<template>
    <div class="back">
        <div
            v-if="loaded"
            :key="keyTime"
        >
            <div class="my-scans__header">
                <div class="my-scans-title__sign-wrapper">
                    <h2
                        v-if="userType"
                        class="my-scans-title"
                    >
                        Single Scans
                    </h2>
                    <Tooltip
                        position="right"
                        mobile-position="bottom"
                        :tooltip="tooltipSingleScansText"
                    >
                        <img
                            class="my-scans-title__sign-icon"
                            src="../../assets/images/integration-settings-toggle-icon.svg"
                            alt=""
                        >
                    </Tooltip>
                </div>
                <div class="my-scans__header-btn-wrapper">
                    <b-nav-item
                        v-if="userType === 'company'"
                        class="my-scans__header-new-scan-btn"
                        :to="{ path: '/scan/create'}"
                    >
                        New Scan
                    </b-nav-item>
                </div>
            </div>
            <div
                class="my-scans-title__in-progress"
                :style="{display: inProgressJobs.length !== 0 ? 'block' : 'none'}"
            >
                <div
                    id="progress"
                    class="block-title"
                >
                    <span>In progress</span>&nbsp;<span v-text="`(${inProgressJobs.length})`" />
                </div>
                <div class="scan-list-wrapper">
                    <div class="scan-list-block">
                        <b-table
                            id="in-progress-table-my-scans"
                            hover
                            :items="inProgressJobs"
                            :fields="inProgressJobsFields"
                            :per-page="inProgressPerPage"
                            :current-page="inProgressCurrentPage"
                            :thead-class="`table-header__hidden`"
                            class="table__wrapper table__wrapper-in-progress-my-scans"
                            :class="inProgressJobs.length <= inProgressPerPage ? 'pagination-disabled' : ''"
                        >
                            <template #cell(index)="data">
                                {{ data.index + 1 + ((inProgressPerPage * inProgressCurrentPage) - inProgressPerPage) }}
                            </template>
                            <template #cell(file_name)="data">
                                {{ data.item.fileName }}
                            </template>
                            <template #cell(progress)="data">
                                <div
                                    v-if="isScanning(data.item.status)"
                                    :key="progressSpinner"
                                >
                                    <div class="dot-spin" />
                                </div>
                            </template>
                            <template #cell(status)="data">
                                <div
                                    v-if="isCreated(data.item.status)"
                                    class="status-text in_queue"
                                >
                                    Created
                                </div>
                                <div
                                    v-if="isScanning(data.item.status)"
                                    class="status-text scanning"
                                >
                                    Scanning
                                </div>
                                <div
                                    v-if="isFailed(data.item.status)"
                                    class="status-text failed"
                                >
                                    Failed
                                </div>
                                <div
                                    v-if="isInQueue(data.item.status)"
                                    class="status-text in_queue"
                                >
                                    In queue
                                </div>
                            </template>
                            <template #cell(actions)="data">
                                <div class="button__delete">
                                    <Tooltip
                                        position="top"
                                        :tooltip="'Delete'"
                                    >
                                        <button id="delete-report-btn">
                                            <img
                                                class="icon-action btn"
                                                src="../../assets/images/newScan/trash-icon.svg"
                                                alt="icon_action_delete"
                                                @click.prevent="deleteJobFromProgressTable(data.item.id)"
                                            >
                                        </button>
                                    </Tooltip>
                                </div>
                            </template>
                        </b-table>
                        <div
                            v-if="isReportDeletionNowFromProgressTable"
                            class="spinner__my-scans-wrapper"
                        >
                            <div class="spinner__my-scans">
                                <Spinner />
                            </div>
                        </div>
                        <Pagination
                            v-if="inProgressJobs.length > inProgressPerPage"
                            class="pagination-general finished-versions-pagination"
                            :count="inProgressJobs.length"
                            :limit="inProgressPerPage"
                            :show-spinner="isShowSpinner"
                            :current-page="inProgressCurrentPage"
                            @pageChanged="updateInProgressPage"
                        />
                    </div>
                </div>
            </div>

            <div
                v-if="!inProgressJobs.length || finishedJobs.length"
                class="my-scans-title__finished"
            >
                <div
                    id="finish"
                    class="my-scans__finished-wrapper"
                >
                    <div class="my-scans__finished-title">
                        Finished
                    </div>&nbsp;
                    <div
                        class="my-scans__finished-amount"
                        v-text="`(${countJobs})`"
                    />
                </div>
                <div class="scan-list-wrapper">
                    <div
                        v-if="finishedJobs.length > 0"
                        class="scan-list-block"
                    >
                        <b-table
                            id="finished-table-my-scans"
                            hover
                            :items="finishedJobs"
                            :fields="finishedJobsFields"
                            :current-page="finishedCurrentPage"
                            :thead-class="`table-header`"
                            :tbody-class="`table-body`"
                            class="table__wrapper"
                            :class="countJobs <= finishedPerPage ? 'pagination-disabled' : ''"
                        >
                            <template #cell(icon)>
                                <img
                                    class="icon"
                                    src="../../assets/images/myScans/icon_android.svg"
                                    alt="icon"
                                >
                            </template>
                            <template #cell(file_name)="data">
                                <b-nav-item
                                    :disabled="isFailed(data.item.status)"
                                    :style="isFailed(data.item.status) ? 'cursor: default' : 'cursor: pointer'"
                                    :to="{ path: `/scan/info/${data.item.id}`}"
                                    class="btn"
                                >
                                    {{ data.item.fileName }}
                                </b-nav-item>
                            </template>
                            <template
                                id="finished-table__package"
                                #cell(appId)="data"
                            >
                                {{ data.item.appId }}
                            </template>
                            <template #cell(scan_time)="data">
                                <span
                                    :style="isLessThenOneMinuteTime(data.item.completionTime)? 'color: #61D4B3;' : ''"
                                >
                                    {{ createDateView(data.item.completionTime) }}
                                </span>
                            </template>
                            <template #cell(vulnerability)="data">
                                <div v-if="data.item.status==='FINISH'">
                                    <StatsBar
                                        :high-width-percent="calcSeverityPercentage(data.item.allVulnerabilitiesFolder, 'high')"
                                        :medium-width-percent="calcSeverityPercentage(data.item.allVulnerabilitiesFolder, 'medium')"
                                        :low-width-percent="calcSeverityPercentage(data.item.allVulnerabilitiesFolder, 'low')"
                                    />
                                </div>
                                <div v-else>
                                    <div
                                        v-if="isFailed(data.item.status)"
                                        class="status-text failed"
                                    >
                                        Failed
                                    </div>
                                </div>
                            </template>
                            <template #cell(actions)="data">
                                <div class="button__actions">
                                    <Tooltip
                                        position="top"
                                        mobile-position="left"
                                        :tooltip="tooltipMessage(data.item)"
                                    >
                                        <button
                                            v-if="data.item.status==='FINISH'"
                                            id="download-pdf-btn"
                                            class="icon-action btn"
                                            :disabled="isAllowDownloadPDF(data.item)"
                                            @click.prevent="downloadJobPDFReport(data.item.id)"
                                        >
                                            <DownloadIcon
                                                v-if="data.item.status==='FINISH'"
                                                :disabled="isAllowDownloadPDF(data.item)"
                                                alt="icon_action_download"
                                            />
                                        </button>
                                    </Tooltip>
                                    <Tooltip
                                        position="top"
                                        mobile-position="left"
                                        :tooltip="tooltipCopyText"
                                    >
                                        <button
                                            v-if="!isFailed(data.item.status)"
                                            id="copy-link-btn"
                                            class="button_copy_link"
                                            @click.stop="copyLinkSharedJobReportPage(data.item.id)"
                                        >
                                            <img
                                                class="icon-action btn"
                                                src="../../assets/images/myScans/copy-link.svg"
                                                alt="icon_action_share"
                                            >
                                        </button>
                                    </Tooltip>
                                    <Tooltip
                                        position="top"
                                        mobile-position="left"
                                        :tooltip="tooltipDeleteText"
                                    >
                                        <button
                                            id="delete-report-btn"
                                            class="finished-table__delete-btn"
                                            @click="deleteReport( data.item )"
                                        >
                                            <img
                                                class="icon-action btn"
                                                src="../../assets/images/newScan/trash-icon.svg"
                                                alt="icon_action_delete"
                                            >
                                        </button>
                                    </Tooltip>
                                </div>
                                <!-- delete popup -->
                                <GeneralPopup
                                    :title-text="''"
                                    :popup-id="'DeleteReport-' + data.item.id"
                                    :hide-footer="true"
                                    :hide-header="true"
                                    :size="'md'"
                                    @onHidden="closeModal('general-popupDeleteReport-' + data.item.id)"
                                >
                                    <div class="report__delete-modal">
                                        <img
                                            src="../../assets/images/closeModal_new.svg"
                                            alt="close modal"
                                            class="modal-close"
                                            @click="closeModal('general-popupDeleteReport-' + data.item.id)"
                                        >
                                        <div class="modal-title">
                                            You`re about to permanently delete the report for {{ data.item.fileName }}
                                        </div>
                                        <div class="modal-description">
                                            This action cannot be undone. To confirm, please type in the amount of vulnerabilities found in this report:
                                        </div>
                                        <div class="modal-number">
                                            {{ calculateTotalStatisticsCount(data.item.allVulnerabilitiesFolder) }}
                                        </div>
                                    </div>
                                    <form
                                        class="report__modal-form"
                                        @submit.prevent="handleDeleteModal"
                                    >
                                        <div class="report__form-input-wrapper">
                                            <input
                                                id="DeleteReport"
                                                v-model.trim="deleteReportInput"
                                                class="input"
                                                type="text"
                                                name="DeleteReport"
                                                :class="{ errorInput: isDeleteReportInputInvalid(data.item.allVulnerabilitiesFolder) && deleteReportInput.length > 0}"
                                            >
                                            <img
                                                v-if="deleteReportInput.length > 0"
                                                class="cross-icon"
                                                src="../../assets/images/closeModal_new.svg"
                                                alt="cross icon"
                                                @click="deleteReportInput = ''"
                                            >
                                            <label
                                                for="DeleteReport"
                                                class="label"
                                                :class="{ activeInput: deleteReportInput }"
                                            >
                                                Amount of vulnerabilities
                                            </label>
                                            <span
                                                v-if="(calculateTotalStatisticsCount(data.item.allVulnerabilitiesFolder) !== parsedDeleteReportInput) && deleteReportInput.length > 0"
                                                id="DeleteReportError"
                                                class="report__error"
                                            >
                                                <span class="report__error-text">Please enter a valid vulnerability count</span>
                                            </span>
                                            <span
                                                id="DeleteReportStatusError"
                                                class="report__error"
                                            >
                                                <span
                                                    v-if="jobError"
                                                    class="report__error-text"
                                                >
                                                    {{ jobError.deleteReportStatusError ? jobError.deleteReportStatusError : 'Something went wrong' }}
                                                </span>
                                            </span>
                                        </div>
                                    </form>

                                    <div class="delete-report__btns">
                                        <ConfigurablePopupButton
                                            :button-text="'Cancel'"
                                            :button-type="'cancel'"
                                            :icon="false"
                                            :disabled="false"
                                            @buttonClick="closeModal('general-popupDeleteReport-' + data.item.id)"
                                        />

                                        <ConfigurablePopupButton
                                            :button-text="'Delete'"
                                            :button-type="'delete'"
                                            :icon="true"
                                            :disabled="calculateTotalStatisticsCount(data.item.allVulnerabilitiesFolder) !== parsedDeleteReportInput || !isDeleteReportInputValid"
                                            @buttonClick="deleteJob(data.item)"
                                        />
                                    </div>
                                    <div
                                        v-if="isShowSpinner"
                                        class="spinner__vp-wrapper"
                                    >
                                        <div class="spinner__wp">
                                            <Spinner />
                                        </div>
                                    </div>
                                </GeneralPopup>
                            </template>
                        </b-table>
                        <div
                            v-if="isReportLoadingNow"
                            class="spinner__my-scans-wrapper"
                        >
                            <div class="spinner__my-scans">
                                <Spinner />
                            </div>
                        </div>
                        <Pagination
                            v-if="countJobs > finishedPerPage"
                            class="pagination-general finished-versions-pagination"
                            :count="countJobs"
                            :limit="finishedPerPage"
                            :show-spinner="isShowSpinner"
                            :current-page="finishedCurrentPage"
                            @pageChanged="updateFinishedPage"
                        />
                    </div>
                    <div
                        v-if="emptyFinishedList"
                        class="empty-list__wrapper"
                    >
                        <h3 class="empty-list__title">
                            No completed scans, please start the first scan
                        </h3>
                        <b-nav-item
                            :to="{ path: '/scan/create'}"
                            class="empty-list__button"
                        >
                            <p class="empty-list__button-text">
                                New Scan
                            </p>
                        </b-nav-item>
                    </div>
                </div>
            </div>
        </div>
        <Spinner v-if="!loaded" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { checkAndUpdatePlatformText, createDateView, isLessThenOneMinuteTime, calculateTotalStatisticsCount } from '../../services/functions';
import { FolderNameEnum, JobStatusEnum } from '../../utils/constants/common';
import { successNotification } from '@/services/notificationService/notificationService';

import GeneralPopup from '../../components/popups/GeneralPopup';
import ConfigurablePopupButton from '../../components/popups/ConfigurablePopupButton';
import Tooltip from '../../components/report/components/Tooltip.vue';
import Pagination from '../../components/Pagination.vue';
import StatsBar from '../../components/templates/StatsBar';
import Spinner from '../../components/Spinner';
import DownloadIcon from '../../assets/iconTemplates/DownloadIcon.vue';


export default {
    components: {
    GeneralPopup,
    ConfigurablePopupButton,
    Tooltip,
    Pagination,
    StatsBar,
    Spinner,
    DownloadIcon,
},
    data() {
        return {
            interval: undefined,
            loaded: false,
            inProgressPerPage: 20,
            emptyFinishedList: false,
            inProgressCurrentPage: 1,
            finishedPerPage: 20,
            finishedCurrentPage: 1,
            isShowSpinner: false,
            keyTime: null,
            progressSpinner: 1,
            tooltipSingleScansText: 'Single Scan lets you receive scan reports for select apps at a fixed price. This option provides a report only for the uploaded file. Use Integration to get continuous app security monitoring and use CI/CD features.',
            tooltipDownloadPDFText: 'Download PDF',
            tooltipTrialMessage:`Please contact our team
             to unlock the download option`,
            tooltipCopyText: 'Copy link',
            tooltipDeleteText: 'Delete report',
            deleteReportInput: '',
            inProgressJobsFields: [
                {
                    key: 'index',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-5', 'center', 'table__small-icon'],
                },
                {
                    key: 'file_name',
                    tdClass: ['table-cell', 'table-column-text-name', 'table-column-width-30', 'left', 'table__small-name'],
                },
                {
                    key: 'progress',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-30', 'left', 'table__small-package'],
                },
                {
                    key: 'status',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-5', 'center', 'table__small-version'],
                },
                {
                    key: 'actions',
                    label: '',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-20', 'center', 'table__small-actions'],
                },
            ],
            finishedJobsFields: [
                {
                    key: 'platform',
                    label: '',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-8', 'center', 'table__platform', 'table__small-platform '],
                    formatter: checkAndUpdatePlatformText,
                },
                {
                    key: 'file_name',
                    label: 'Original File Name',
                    tdClass: ['table-cell', 'table-column-text-name', 'table-column-width-30', 'left', 'table__small-name'],
                },
                {
                    key: 'appId',
                    label: 'App ID',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-10', 'center', 'table__small-package'],
                },
                {
                    key: 'version',
                    label: 'Version',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-5', 'center', 'table__small-version'],
                },
                {
                    key: 'scan_time',
                    label: 'Scan Completion Time',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-15', 'center', 'table__small-scan-time'],
                },
                {
                    key: 'vulnerability',
                    label: 'Vulnerability',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-15', 'center', 'table__small-vulnerability'],
                },
                {
                    key: 'actions',
                    label: '',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-20', 'center', 'table__small-actions'],
                },
            ],
            isReportLoadingNow: false,
            isReportDeletionNowFromProgressTable: false,
        };
    },
    computed: {
        ...mapGetters({
            jobs: 'jobs/jobs',
            countJobs: 'jobs/countJobs',
            userType: 'user/userType',
            needToFetchJobs: 'jobs/needToFetchJobs',
            showDeleteReportNotification: 'user/showDeleteReportNotification',
            jobError: 'jobs/errors',
        }),
        inProgressJobs: {
            cache: false,
            get() {
                return this.jobs
                    .filter(job => job.status === JobStatusEnum.CREATED || job.status === JobStatusEnum.IN_QUEUE || job.status === JobStatusEnum.SCANNING)
                    .sort((a, b) => b.status.localeCompare(a.status));
            },
        },
        finishedJobs: {
            cache: false,
            get() {
                return this.jobs
                    .filter(job => job.status === JobStatusEnum.FINISHED || job.status === JobStatusEnum.ERROR)
                    .sort((a, b) => {
                        if (b.completionTime && a.completionTime) {
                            return Date.parse(b.completionTime) - Date.parse(a.completionTime);
                        } else {
                            return Date.parse(b.updatedAt) - Date.parse(a.updatedAt);
                        }
                    });
            },
        },
        isDeleteReportInputValid() {
            return /^\d+$/.test(this.deleteReportInput);
        },

        parsedDeleteReportInput() {
            return this.isDeleteReportInputValid ? parseInt(this.deleteReportInput) : NaN;
        },
    },
    watch: {
        jobs() {
            this.$store.commit('user/changeStatusAuthenticating', false);
            this.progressSpinner++;
            this.isReportLoadingNow = false;
            this.isReportDeletionNowFromProgressTable = false;
        },
        finishedCurrentPage() {
            this.$store.dispatch('jobs/fetchJobs', this.finishedCurrentPage);
            this.isReportLoadingNow = true;
            setTimeout(function () {
                document.getElementById('finish').scrollIntoView();
            }, 0);
        },
        inProgressCurrentPage() {
            setTimeout(function () {
                document.getElementById('progress').scrollIntoView();
            }, 0);
        },
        keyTime() {
            setTimeout(this.renderPage, 60 * 1000);
        },
        async finishedJobs() {
            this.emptyFinishedList = this.finishedJobs.length <= 0;

            if (this.finishedJobs.length === 0 && this.finishedCurrentPage - 1 > 0) {
                this.loaded = false;
                await this.$store.dispatch('jobs/fetchJobs', this.finishedCurrentPage - 1);
                this.loaded = true;
            }
        },
    },
    async beforeMount() {
        await this.fetchJobs();
        if (this.showDeleteReportNotification) {
            this.successNotification('reportDeleted');
            this.$store.commit('user/setShowDeleteReportNotification', false);
        }
        this.loaded = true;
        this.interval = setInterval(this.fetchJobsIfNeed, 5000);

    },
    mounted() {
        this.$store.commit('jobs/clearCurrentJob');
        this.keyTime = Date.now();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = undefined;
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        checkAndUpdatePlatformText,
        createDateView,
        isLessThenOneMinuteTime,
        successNotification,
        calculateTotalStatisticsCount,
        isAllowDownloadPDF(item){
            return item.type === 'TRIAL';
        },
        tooltipMessage(item){
            return this.isAllowDownloadPDF(item) ? this.tooltipTrialMessage
                : this.tooltipDownloadPDFText;
        },
        async fetchJobs() {
            await this.$store.dispatch('jobs/fetchJobs', this.finishedCurrentPage);
        },
        async fetchJobsIfNeed() {
            if (this.needToFetchJobs) {
                await this.$store.dispatch('jobs/fetchJobs', this.finishedCurrentPage);
            }
        },
        renderPage() {
            this.keyTime = Date.now();
        },
        isDeleteReportInputInvalid(issue) {
            return !this.isDeleteReportInputValid || this.calculateTotalStatisticsCount(issue) !== this.parsedDeleteReportInput;
        },
        deleteReport(item){
            const vulnerabilities = calculateTotalStatisticsCount(item.allVulnerabilitiesFolder);
            if (this.isFailed(item.status) || vulnerabilities === 0) {
                this.deleteJob(item);
            } else {
                this.openModal('general-popupDeleteReport-' + item.id);
            }
        },
        openModal(modalId) {
            this.$bvModal.show(modalId);
        },
        closeModal(modalId) {
            this.$bvModal.hide(modalId);
            this.deleteReportInput = '';
        },
        updateFinishedPage(page) {
            this.finishedCurrentPage = page;
        },
        updateInProgressPage(page) {
            this.inProgressCurrentPage = page;
        },
        calcSeverityPercentage(folderData, severity) {
            let total = this.calculateTotalStatisticsCount(folderData);

            let severityCount = folderData.statistics.find(item => item.severity === severity)?.count || 0;

            return (severityCount / total) * 100;
        },
        downloadJobPDFReport(id) {
            const folderName = this.$route.query.folderName || FolderNameEnum.ALL_VULNERABILITIES;

            this.isReportLoadingNow = true;
            let job = this.jobs.find(job => job.id === id);
            let url = `/private/ui/pdf_report/${job.id}`;
            this.axios
                .post(url, {
                    filters: { vulnerabilityCategoriesIds: [] },
                    folderName,
                })
                .then(
                    response => {
                        window.location.href = response.data.url;
                        this.isReportLoadingNow = false;
                    },
                    error => {
                        this.isReportLoadingNow = false;
                        console.log(error);
                    },
                );
        },
        copyLinkSharedJobReportPage(id) {
            let link = window.location.origin + `/scan/info/${id}`;
            const el = document.createElement('textarea');
            el.value = link;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.tooltipCopyText = 'Link Copied!';
            setTimeout(() => this.tooltipCopyText = 'Copy link', 2000);
        },
        async deleteJob(item) {
            this.isReportLoadingNow = true;
            this.isShowSpinner = true;
            try {
                let res = await this.$store.dispatch('jobs/deleteJob', { id: item.id, page: this.finishedCurrentPage });
                if (res && res.status === 200) {
                    this.successNotification('reportDeleted');
                }
                this.closeModal('general-popupDeleteReport-' + item.id);
                this.isShowSpinner = false;
            } catch (error) {
                console.log(error);
                this.isReportLoadingNow = false;
            }
        },
        async deleteJobFromProgressTable(id) {
            this.isReportDeletionNowFromProgressTable = true;
            await this.$store.dispatch('jobs/deleteJob', { id: id, page: this.finishedCurrentPage });
            const currentPage = Math.ceil((this.inProgressJobs.length - 1) / this.inProgressPerPage);
            this.updateInProgressPage(currentPage);
        },
        isScanning(status) {
            return status === JobStatusEnum.SCANNING;
        },
        isCreated(status) {
            return status === JobStatusEnum.CREATED;
        },
        isFailed(status) {
            return status === JobStatusEnum.ERROR;
        },
        isInQueue(status) {
            return status === JobStatusEnum.IN_QUEUE;
        },
    },
};
</script>

<style lang="scss">
@import "../../assets/css/dotFlashing/dots";
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

.spinner__vp-wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 3000;
    background-color: rgba(255, 255, 255, 0.7);
}

.spinner__wp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#finished-table-my-scans {
    margin: 0 auto;
    li.nav-item.btn {
        padding-left: 0;
        padding-right: 0;
    }

    .nav-link {
        color: black;

        &:hover {
            color: black;
        }
    }
}

button {
    border: #ffffff;
    background: #FFFFFF;
    padding: 0;
}

button:focus {
    outline: grey;
}

.button_copy_link {
    margin-left: 10%;
    margin-right: 10%;
    position: relative;
}

.my-scans__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 7.5%;
}

.my-scans__header-btn-wrapper {
    display: flex;
    align-items: center;
}

.my-scans__header-buy-packs-btn,
.my-scans__header-new-scan-btn {
    a.nav-link {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        background: #FFB400;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        transition: 0.3s;
        padding: 10px 40px;
        margin: 0 0 30px 30px;

        &:hover {
            opacity: 0.7;
        }

        &:active {
            opacity: 1;
        }
    }
}

.my-scans__header-buy-packs-btn {
    a.nav-link {
        color: #FFB400;
        background: transparent;
        border: none;
        padding: 0;

        &:hover {
            opacity: 0.5;
        }
    }
}

.shared_report__buttons-link-message {
    @include Aspekta-font(400, 14px, 140%, white);
    position: absolute;
    top: -32px;
    left: -37px;
    width: 90px;
    text-align: center;
    height: 23px;
    background-color: black;
    border-radius: 3px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shared_report__buttons-link-message::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
    border: 6px solid transparent;
    border-top: 3px solid black;
    z-index: 2;
}

#in-progress-table-my-scans {
    margin: 0 auto;
}

#in-progress-table-my-scans tbody tr:first-child {
    border-radius: 20px !important;
}

#in-progress-table-my-scans tbody tr:first-child td:nth-child(1) {
    border-top-left-radius: 20px !important;
}

#in-progress-table-my-scans tbody tr:first-child td:last-child {
    border-top-right-radius: 20px !important;
}

#in-progress-table-my-scans tbody tr td {
    border-top: none !important;
}

#finished-table-my-scans tbody tr,
#in-progress-table-my-scans tbody tr {
    border-bottom: 1px solid #f0f0f0;
    border-top: none !important;
}

#finished-table-my-scans tbody tr:last-child,
#in-progress-table-my-scans tbody tr:last-child {
    border-bottom: none !important;
}

#in-progress-table-my-scans tbody tr td:nth-child(4) {
    width: 10%;
    border-top: 0;
}

#in-progress-table-my-scans tbody tr td:nth-child(5) {
    .button__delete {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

.spinner__my-scans-wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.7);
    overflow-y: hidden;
}

.spinner__my-scans {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.my-scans__finished-wrapper {
    display: flex;
    margin: 20px 7.5%;
}

.my-scans__finished-title {
    @include Aspekta-font(600, 18px, 26px, #242424);
    margin-right: 10px;
}

.my-scans__finished-amount {
    @include Aspekta-font(500, 18px, 26px, #949DB8);
}

.empty-list__wrapper {
    @include Aspekta-font(400, 18px, 140%, #242424);
    width: 100%;
    height: 336px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 20px;
    margin: 40px 0 70px;

    .empty-list__title {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #706969;
    }

    .empty-list__button {
        list-style: none;
        background-color: #FFB400;
        border: 2px solid #FFB400;
        border-radius: 8px;
        margin-top: 18px;
        transition: 0.3s;
        padding: 0 12px;
        height: 40px;
        display: flex;
        align-items: center;

        &:hover {
            opacity: 0.5;
        }
    }

    .empty-list__button-text {
        margin: 0;
        font-size: 18px;
        color: #FFFFFF;
    }
}

.my-scans__finished {
    color: #B2B9C4;
    margin-left: 10px;
}

.back {
    background: #FAFBFC;
    padding-bottom: 100px;
}

.my-scans-title {
    @include Aspekta-font(600, 30px, 150%, #242424);
    margin-left: 0;

    &__sign-icon {
        cursor: pointer;
        margin-left: 5px;
    }

    &__sign-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 30px;
        max-width: 240px;
        width: 100%;
        padding: 40px 0 30px 0;

        &--small {
            position: relative;
            display: flex;
            max-width: 160px;
            padding: 40px 0 30px 0;
        }
    }

    &__finished {
        position: relative;
    }

    &__in-progress, &__finished {
        overflow: auto;
        font-size: 18px;
        line-height: 150%;

        .block-title {
            margin: 20px 7.5%;

            span:nth-child(1) {
                @include Aspekta-font(600, 18px, 26px, #242424);
                margin-right: 10px;
            }

            span:nth-child(2) {
                @include Aspekta-font(500, 18px, 26px, #949DB8);
            }
        }
    }
}

#finished-table-my-scans thead tr th {
    border: 0;
    padding: 0;
    line-height: 80px;
}

#finished-table-my-scans tbody tr td {
    border: 0;
    padding: 20px 0;
}

#finished-table-my-scans tbody tr td:nth-child(3) {
    word-break: break-word;
}

#finished-table-my-scans tbody tr td:nth-child(2) {
    word-break: break-word;
    padding-right: 25px;

    a.nav-link {
        &:hover {
            text-decoration: underline;
        }
    }

}

#finished-table-my-scans tbody tr td:nth-child(4) {
    padding: 0 10px;
}

#finished-table-my-scans thead tr th:nth-child(2) {
    text-align: left;
}

#finished-table-my-scans tbody tr td:nth-child(7) {
    .button__actions {
        display: flex;
        flex-direction: row;
        gap: 12.5px;
        justify-content: center;
        align-items: center;
    }
}

.scan-list-block {
    position: relative;
    background: #FFFFFF;
    border-radius: 20px;
    width: 85%;
    margin: 0 auto;

    .status-text {
        font-size: 14px;
        line-height: 140%;

        &.scanning {
            color: #61D4B3;
        }

        &.failed {
            color: #FB8D62;
        }

        &.in_queue {
            color: #B2B9C4;
        }
    }

    #finished-table-my-scans thead {
        @include Aspekta-font(400, 14px, 140%, #B2B9C4);
        align-items: center;
        text-align: center;

        th {
            font-weight: normal;
        }

        &__hidden {
            display: none;
        }
    }
}

table.table-hover tbody tr:hover {
    background-color: white;
    box-shadow: 0 4px 15px rgba(56, 70, 77, 0.04);
    border-radius: 5px;

    &:hover img.icon-action {
        visibility: visible;
    }
    &:hover button.icon-action {
        visibility: visible;
    }
}

td.table-cell {
    vertical-align: middle !important;
    border-top: 1px solid#F0F0F0 !important;

    &.center {
        text-align: center;
    }

    &.left, &.left .btn {
        text-align: left;
    }

    &.table-column-text-platform {
        @include Aspekta-font(400, 14px, 140%, #3180F6);
    }

    &.table-column-text-name, &.table-column-text-name .btn {
        @include Aspekta-font(400, 18px, 140%, #242424);
        word-break: break-all;
    }

    &.table-column-text {
        @include Aspekta-font(400, 14px, 140%, #706969);
    }

    &.table-column-package-name {
        @include Aspekta-font(400, 14px, 140%, #706969);
        word-break: break-all
    }

    &.table-column-width-50 {
        width: 50%;
    }

    &.table-column-width-30 {
        width: 30%;
    }

    &.table-column-width-25 {
        width: 25%;
    }

    &.table-column-width-20 {
        width: 20%;
    }

    &.table-column-width-15 {
        width: 17%;
    }

    &.table-column-width-10 {
        width: 10%;
    }

    &.table-column-width-8 {
        width: 8%;
    }

    &.table-column-width-5 {
        width: 5%;
    }

    &.table-column-width-actions {
        width: 180px;
    }

    &.table__platform {
        font-weight: 500;
        font-size: 12px;
        line-height: 1.2;
        color: #3180F6;
    }

    .icon-action {
        padding-left: 0;
        padding-right: 0;
        visibility: hidden;
        border: none;

        &.non-clickable {
            opacity: 0.4;
            cursor: default;
        }
    }

    .icon-action:nth-child(2) {
        padding-left: 10%;
        padding-right: 10%;
    }
}

.delete-report__btns {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
}

@media (max-width: 1120px) {
    .scan-list-block {
        width: 1120px;
    }
}

</style>
