<template>
    <label  
        :for="`payment-${card.id}`"
        class="payment"
    >
        <img class="payment-img" :src="bankIcon" alt="icon" />
        <div class="payment-number">
            XXXX XXXX XXXX {{card.last4}}
            <input 
                ref="inputCard"
                type="radio"
                :id="`payment-${card.id}`"
                :name="`payment`"
                :value="card.id"
                @click="toggle"
            >
            <span class="checkmark"></span>
        </div>
    </label>
</template>

<script>
import {getBankIcon} from '@/utils/helper-functions.js'

export default {
    name: 'PaymentCard',
    props: ['card', 'selected'],
    mounted() {
        if (this.selected) {
            this.$refs.inputCard.checked = true
        }
    },
    computed: {
        bankIcon() {
            return getBankIcon(this.card.brand)
        },
    },
    methods: {
        toggle({target}) {
            this.$emit('selectCard', target.value)
        },
    }
}
</script>

<style lang="scss">
@import "@/assets/css/variables";

.payment {
    min-width: 100%;
    border: 2px solid $ghost-border;
    border-radius: 20px;
    padding: 24px 40px;
    user-select: none;
    transition: all .3s;
    display: flex;
    gap: 10px;
    &-img {
        width: 30px;
        height: 30px;
    }
    & input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }
    &:hover, &:has(input:checked) {
        cursor: pointer;
        border: 2px solid $main-blue-color;
        box-shadow: 0px 12px 16px rgba(51, 128, 244, 0.12);
        transition: all .3s;
        .checkmark {
            border: 2px solid $main-blue-color;
            box-shadow: 0px 12px 16px rgba(51, 128, 244, 0.12);
            transition: all .3s;
        }
        .payment-number {
            color: $main-text;
        }
    }
    &-number {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        color: $ghost-text;
        white-space: nowrap;
        .checkmark {
            position: relative;
            height: 16px;
            width: 16px;
            border: 2px solid transparent;
            border-radius: 50%;
            &:after {
                content: "";
                display: none;
                position: absolute;
                top: 1px;
                left: 1px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $main-blue-color;
            }
        }
        input:checked ~ .checkmark {
            border: 2px solid $main-blue-color;
        }
        input:checked ~ .checkmark:after {
            display: block;
            opacity: 1;
        }
    }
}

@media (max-width: 1500px) {
    .payment {
        padding: 18px 25px;
        &-number {
            font-size: 14px;
        }
    }
}
@media (max-width: 900px) {
    .payment {
        width: 100%;
    }
}
</style>
