import { JobStatusEnum, JobTypeEnum } from '@/utils/constants/common';

const CryptoJS = require('crypto-js');

export const makeAnchor = (str) => {
    return encodeURIComponent(str)
        .replace(/%20/g, '_')
        .replace(/%2F/, '/');
};

export const makeLinkToAnchorSettings = (str) => {
    return `/settings/${str}`;
};

export const makeLinkToAnchorIntegrationSettings = (str, id) => {
    return `/integration/${id}/settings/${str}`;
};

export const makeLinkToAnchor = (str) => {
    return `/docs/api/${str}`;
};

export const makeLinkToAnchorCICD = (str) => {
    return `/docs/ci-cd/${str}`;
};

export const makeLinkToAnchorScanConfigurations = (str) => {
    return `/docs/configurations/${str}`;
};

export const makeSubLinkToAnchorScanConfigurations = (str, path) => {
    return `/docs/${makeAnchor(path)}#${makeAnchor(str)}`;
};

export const makeLinkToAnchorQuickStart = () => {
    return `/docs/quick-start`;
};

export const makeLinkToAnchorWebhooks = () => {
    return `/docs/webhooks`;
};

export const makeSubLinkToAnchor = (str, path) => {
    return `/docs/api/${makeAnchor(path)}#${makeAnchor(str)}`;
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"+]+(\.[^<>()[\]\\.,;:\s@"+]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};
export const validateOtp = (otp) => {
    const re = /^\d{6}$/;
    return re.test(otp);
};

export const toggleShowPassword = () => {
    const password = document.getElementById('password');
    const labelShow = document.getElementById('passwordShow');
    const labelHide = document.getElementById('passwordHide');

    if (password.type === 'password') {
        labelShow.style.display = 'none';
        labelHide.style.display = 'block';
        password.type = 'text';
    } else {
        labelShow.style.display = 'block';
        labelHide.style.display = 'none';
        password.type = 'password';
    }
};

export const convertErrorMessageForUI = (errorMessage) => {
    if (errorMessage === 'User exists with same username') {
        return { key: 'email', body: 'User with this email already exists' };
    } else if (errorMessage === 'User name is missing') {
        return { key: 'email', body: 'Required field' };
    } else if (errorMessage === 'Unauthorized') {
        return { key: 'credentials', body: 'Wrong credentials, please try again' };
    } else if (errorMessage === 'Something went wrong') {
        return { key: 'credentials', body: 'Something went wrong' };
    } else if (errorMessage === 'Failed to send execute actions email') {
        return { key: 'email', body: 'Email is not valid' };
    } else if (errorMessage === 'Redirect to confirm') {
        return { key: 'link', body: 'Redirect to confirm' };
    } else if (errorMessage === 'Email already verified') {
        return { key: 'verifyEmail', body: 'Email already verified' };
    } else if (errorMessage === 'Please use a different email') {
        return { key: 'email', body: 'Please use a different email' };
    } else if (errorMessage === 'Please use your corporate email') {
        return { key: 'email', body: 'Please use your corporate email' };
    } else if (errorMessage === 'OTP is not valid.') {
        return { key: 'otp', body: 'OTP is not valid.' };
    } else if (errorMessage === 'Too many requests') {
        return { key: 'timeout', body: 'Too many requests' };
    } else if (errorMessage === 'Account blocked') {
        return {
            key: 'credentials', body: `Unfortunately, your account is out of the trial version or was blocked.
         Please buy a subscription or contact sales team to renew it` };
    } else if (errorMessage === 'Server error') {
        return { key: 'dbConnecting', body: 'Something went wrong, please contact us for details' };
    } else if (errorMessage === 'Temporarily blocked') {
        return { key: 'credentials', body: 'Your account has been blocked for 5 hour' };
    } else if (errorMessage === 'The user has already been invited') {
        return { key: 'inviteEmailStatusError', body: 'The user has already been invited' };
    } else if (errorMessage === 'The user is already a member') {
        return { key: 'inviteEmailStatusError', body: 'The user is already a member' };
    } else if (errorMessage === 'User not found') {
        return { key: 'inviteEmailStatusError', body: 'User not found' };
    } else if (errorMessage === 'Can\'t invite users with the hacker account type') {
        return { key: 'inviteEmailStatusError', body: 'Can\'t invite users with the hacker account type' };
    } else if (errorMessage === 'Please use corporate email') {
        return { key: 'inviteEmailStatusError', body: 'Access denied. Please use corporate email to continue' };
    } else if (errorMessage === 'You cannot invite yourself to the Integration') {
        return { key: 'inviteEmailStatusError', body: 'You cannot invite yourself to the Integration' };
    } else if (errorMessage === 'Url is not valid (use https only)') {
        return { key: 'commonWebhookError', body: 'Url is not valid (use https only)' };
    } else if (errorMessage === 'IP/domains from local network are not allowed') {
        return { key: 'commonWebhookError', body: 'IP/domains from local network are not allowed' };
    } else if (errorMessage === 'HTTP redirects are not allowed') {
        return { key: 'commonWebhookError', body: 'HTTP redirects are not allowed' };
    } else if (errorMessage === 'Please write a valid number') {
        return { key: 'deleteReportStatusError', body: 'Please write a valid number' };
    } else {
        return { key: 'default', body: 'Something went wrong' };
    }
};

export const converFolderErrorMessagesForUI = (errorMessage) => {
    if (errorMessage === 'Can\'t update folder with empty name') {
        return { key: 'folderName', body: 'Can\'t update folder with empty name' };
    } else if (errorMessage === 'Can\'t create folder with empty name') {
        return { key: 'folderName', body: 'Can\'t create folder with empty name' };
    } else if (errorMessage === 'Can\'t create with Default folder name') {
        return { key: 'folderName', body: 'Can\'t create with Default folder name' };
    } else if (errorMessage === 'Folder with this name already exists') {
        return { key: 'folderName', body: 'Folder with this name already exists' };
    } else if (errorMessage === 'Can\'t update to Default folder name') {
        return { key: 'folderName', body: 'Can\'t update to Default folder name' };
    } else {
        return { key: 'folderName', body: 'Something went wrong' };
    }
};

export const setItemsToLocalStorage = (items) => {
    items.forEach(item => {
        const [key, value] = item;

        localStorage.setItem(key, value);
    });
};

export const deleteItemsFromLocalStorage = (items) => {
    items.forEach(item => {
        localStorage.removeItem(item);
    });
};

export const checkFormInputsForErrors = (fields, inputErrorClass) => {
    Object.keys(fields).forEach(field => {
        if (!fields[field]) {
            raiseError(field, `${field}Error`, inputErrorClass);
        }
    });
};

export const checkFieldForError = (field, fieldValue, inputErrorClass) => {
    if (!fieldValue) {
        raiseError(field, `${field}Error`, inputErrorClass);
    }
};

export const checkFormInputsLength = (fields, inputErrorClass) => {
    Object.keys(fields).forEach(field => {
        if (field && field.length > 1000)
            raiseError(field, `${field}LengthError`, inputErrorClass);
    });
};

export const validatePassword = (password) => password.length >= 0 && password.length >= 12;

export const checkPasswordLength = (fields, inputErrorClass) => {
    Object.keys(fields).forEach(field => {
        if (!validatePassword(fields[field])) {
            raiseError(field, `${field}LengthError`, inputErrorClass);
        }
    });
};

export const checkEmailValidation = (emailField, inputErrorClass) => {
    Object.keys(emailField).forEach(field => {
        if (!validateEmail(emailField[field])) {
            raiseError(field, `${field}ValidationError`, inputErrorClass);
        }
    });
};

export const isEmpty = (object) => {
    for (const property in object) {
        return false;
    }
    return true;
};

export const checkFormInputsForCompleteness = (fields) => {
    return Object.keys(fields).map(field => {
        return !!fields[field] && fields[field].length < 1000;
    }).every(el => el);
};

export const checkFieldCompleteness = (value, maxLength = 1000) => {
    return value.length < maxLength;
};

export const raiseError = (inputId, errorId, inputErrorClass) => {
    const element = document.getElementById(inputId);
    if (!element) return;
    element.classList.add(inputErrorClass);
    document.getElementById(errorId).style.display = 'block';
    document.getElementById(inputId).style.border = '1px solid #FB8D62';
    element.addEventListener('click', () => {
        element.classList.remove(inputErrorClass);
        document.getElementById(errorId).style.display = 'none';
        document.getElementById(inputId).style.border = '1px solid #F0F0F0';

    });
};
// todo This code is decoding if data was previously encoded
// export const decodeFromBase64 = (str) => {
//     const decoded = CryptoJS.enc.Base64.parse(str);
//     return CryptoJS.enc.Utf8.stringify(decoded);
// }

// export const handleDecodeSignatureToObj = (signature) => {
//     return JSON.parse(decodeFromBase64(signature))
// }

export const isNewDevLocation = () => window.location.host === 'new.oversecured.com';
export const isNewProdLocation = () => window.location.host === 'hasdgkjwewer.oversecured.com';
export const isProdLocation = () => window.location.host === 'oversecured.com';

export const validateString = (value) => {
    if (value && typeof value === 'string') {
        return value;
    } else {
        return '';
    }
};

export const calculateDateForSettings = (date) => {
    const versionDate = Date.parse(date);
    const currentDate = Date.now();
    const oneDayInUnix = 24 * 60 * 60 * 1000;
    if ((versionDate + oneDayInUnix) > currentDate) {
        const timeAfterVersionFinished = currentDate - versionDate;
        const timeInHours = timeAfterVersionFinished / 1000 / 60 / 60;
        if (timeInHours >= 1) {
            return `${Math.floor(timeInHours)} h ago`;
        } else {
            const timeInMinutes = timeInHours * 60;
            return `${Math.floor(timeInMinutes)} min ago`;
        }
    } else {
        const changeFormatDate = new Date(date);
        const currentFormatDate = changeFormatDate.toDateString().split(' ');
        return `${currentFormatDate[1]}  ${currentFormatDate[2]}, ${currentFormatDate[3]}`;
    }
};

export const isArrayNotEmpty = arr => {
    return Array.isArray(arr) && arr.length > 0;
};

export const checkFilesAndUpdatePrice = (files, id) => {
    return files && files[id] ? files[id].price : 0;
};

export const convertCentsToDollars = (cents) => {
    if (typeof cents === 'number') {
        return cents / 100;
    } else {
        return 0;
    }
};

export const validateUuid = (id) => {
    return validateString(id) && /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id);
};

export const compareFn = (a, b) => (a.position < b.position) ? 1 : ((b.position < a.position) ? -1 : 0);

export const convertCategoryPositionToNumber = (categoryList) => {
    if (categoryList.length) {
        return categoryList.map(category => {
            category.position = Number.parseInt(category.position);
            return category;
        });
    } else {
        return [];
    }
};
export const checkAndUpdatePlatformText = (platform) => {
    if (platform === 'android') {
        return 'Android';
    } else if (platform === 'ios') {
        return 'iOS';
    } else {
        return '-';
    }
};

export const hideErrors = (errorIdsArr) => {
    if (isArrayNotEmpty(errorIdsArr)) {
        errorIdsArr.forEach(error => {
            const errorElement = document.getElementById(error);
            if (errorElement) {
                errorElement.style.display = 'none';
            }
        });
    }
};

export const checkInputForErrors = (errorId) => {
    return document.getElementById(errorId) && document.getElementById(errorId).style.display === 'none';
};

export const isNotEmptyString = (string) => {
    return string && typeof string === 'string' && string.trim().length > 0;
};

export const isUrlValid = (url) => {
    let path;
    try {
        path = new URL(url);
    } catch (_) {
        return false;
    }
    return isNotEmptyString(url) && path.origin !== 'null';
};

export const getVersionStatus = (status) => {
    switch (status) {
        case JobStatusEnum.CREATED:
            return 'Created';
        case JobStatusEnum.IN_QUEUE:
            return 'In queue';
        case JobStatusEnum.PROCESSING:
            return 'Processing';
        case JobStatusEnum.SCANNING:
            return 'Scanning...';
        case JobStatusEnum.ERROR:
            return 'Failed';
        case JobStatusEnum.FINISHED:
            return 'Finished';
        default:
            return '-';
    }
};

export const sortByField = (field) => {
    return (a, b) => a[field] < b[field] ? 1 : -1;
};

export const createDateView = (date) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    if (isLessThenOneMinuteTime(date)) return 'new';
    if (isLessThenOneHourTime(date)) {
        // '8 min ago'
        let milliseconds = Date.now() - Date.parse(date);
        return `${(milliseconds / (60 * 1000)).toFixed()} min ago`;
    }
    if (isLessThenOneDayTime(date)) {
        // '23 h ago'
        let milliseconds = Date.now() - Date.parse(date);
        return `${(milliseconds / (60 * 60 * 1000)).toFixed()} h ago`;
    }
    // '24 Jan, 2020'
    let parsedDate = new Date(Date.parse(date));
    return `${months[parsedDate.getMonth()]} ${parsedDate.getDate()}, ${parsedDate.getFullYear()}`;
};

export const isLessThenOneMinuteTime = (date) => {
    return date ? Date.now() - Date.parse(date) < 60 * 1000 : true;
};

const isLessThenOneHourTime = (date) => {
    return Date.now() - Date.parse(date) < 60 * 60 * 1000;
};

const isLessThenOneDayTime = (date) => {
    return Date.now() - Date.parse(date) < 24 * 60 * 60 * 1000;
};

export const isDisplayScanBtn = (isJobOwner, jobType) => {
    return jobType === JobTypeEnum.INTEGRATION || jobType === JobTypeEnum.VERSION_SAMPLE || isJobOwner;
};

export const shouldDisplay = (isJobOwner, jobType) => {
    return jobType === JobTypeEnum.INTEGRATION || jobType === JobTypeEnum.VERSION_SAMPLE || isJobOwner;
};


// isHighSeverityExist and isMediumSeverityExist for ReportStatistic outdated and supposed to be removed isSeverityExist - new
export const isHighSeverityExist = (statistics) => {
    return statistics.percentageBySeverity.high > 0 ? true : false;
};

export const isMediumSeverityExist = (statistics) => {
    return statistics.percentageBySeverity.medium > 0 ? true : false;
};

export const isSeverityExist = (value) => {
    return value > 0 ? true : false;
};

export const checkIfMobile = (window) => {
    // This is a simple regex for mobile detection, it checks for the most common mobile device indicators in the user agent string.
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return mobileRegex.test(window.navigator.userAgent);
};

/**
 * Set multiple CSS styles to a DOM element.
 *
 * @param {Element} element - The DOM element to apply styles to.
 * @param {Object} styles - An object containing CSS properties and their values.
 */
const setStyle = (element, styles) => {
    if (!element) return;
    Object.assign(element.style, styles);
};

/**
 * Adjusts the width of various elements based on the provided width value.
 * Also handles transition effects for smoother visual adjustments.
 *
 * @param {number} width - The width value (typically the window's width).
 * @param {string} page - The name of the current page.
 */
export const adjustWidths = (width, page) => {
    if (!width) return;

    const container = document.querySelector('.page-content');
    const mainReport = document.querySelector('.main-report-container');
    const reportPage = document.querySelector('.report-page');
    const codeWrapper = document.querySelector('.code-wrapper');

    /**
     * Sets transition styles to key elements.
     */
    const setTransition = () => {
        const transitionStyle = '0.2s ease-in-out';
        setStyle(container, { transition: `max-width ${transitionStyle}` });
        setStyle(mainReport, { transition: `width ${transitionStyle}` });
        setStyle(reportPage, { transition: `all ${transitionStyle}` });
    };

    /**
     * Adjusts the width styles of key elements based on the current screen width.
     */
    const setWidthStyles = () => {
        if (page !== 'ScanInfo' && page !== 'Version scan') return;

        if (width <= 576) {
            setStyle(container, { 'max-width': `${width - 30}px` });
            setStyle(mainReport, { 'width': `${width - 30}px` });
            setStyle(reportPage, { 'width': `${width - 30}px` });
            setStyle(codeWrapper, { 'width': `${width - 30}px` });
        } else if (width <= 834) {
            setStyle(container, { 'max-width': `${width - 20}px` });
            setStyle(mainReport, { 'width': `${width - 60}px` });
            setStyle(reportPage, { 'width': `${width - 232}px` });
            setStyle(codeWrapper, { 'width': `${width - 232}px` });
        } else {
            setStyle(container, { 'max-width': `${width}px` });
            setStyle(mainReport, { 'width': `${width}px` });
            setStyle(reportPage, { 'width': `${width - 232}px` });
            setStyle(codeWrapper, { 'width': `${width - 232}px` });
        }
    };

    setTransition();
    setWidthStyles();
};

/**
 * Adjusts the widths of header and footer elements based on the provided width value.
 * Also handles transition effects for smoother visual adjustments.
 *
 * @param {number} width - The width value (typically the window's width).
 * @param {string} page - The name of the current page.
 */
export const adjustHeaderAndFooterWidths = (width, page) => {
    if (!width) return;

    const header = document.querySelector('.header');
    const footer = document.querySelector('.footer');

    /**
     * Sets basic styles to header and footer elements.
     */
    const setBasicStyles = () => {
        if (width <= 576) {
            setStyle(header, { 'min-width': 'unset', 'max-width': `${width}px`, 'transition': 'max-width 0.2s ease-in-out' });
            setStyle(footer, { 'min-width': 'unset', 'width': `${width - 40}px`, 'transition': 'max-width 0.2s ease-in-out' });
        } else {
            setStyle(header, { 'min-width': 'unset', 'max-width': `${width - (width * 0.15)}px`, 'transition': 'max-width 0.2s ease-in-out' });
            setStyle(footer, { 'min-width': 'unset', 'width': `${width - (width * 0.15)}px`, 'transition': 'max-width 0.2s ease-in-out' });
        }
    };

    /**
     * Adjusts the widths of header and footer elements based on the type of page and screen width.
     */
    const adjustForPageType = () => {
        if (page !== 'ScanInfo' && page !== 'Version scan') return;

        if (width <= 576) {
            setStyle(header, { 'max-width': `${width}px` });
            setStyle(footer, { 'width': `${width - 40}px` });
        } else if (width <= 834) {
            setStyle(footer, { 'width': `${width - 232}px` });
        } else {
            setStyle(footer, { 'width': `${width - 232}px` });
        }
    };

    setBasicStyles();
    adjustForPageType();
};

export const calculateTotalStatisticsCount = (folder) => {
    if (folder && folder.statistics) {
        return folder.statistics.reduce((total, stat) =>
            total + stat.count, 0,
        );
    }
    return 0;
};

export const transformVulnerabilitiesIds = (originalData) => {
    const transformed = {};
    Object.keys(originalData).forEach(key => {
        transformed[key] = originalData[key].map(obj => obj.categoryId);
    });
    return transformed;
};
