<template>
    <div class="tooltip-container">
        <slot/>
<!--        <transition name="fade">-->
            <div
                v-if="show"
                :class="['tooltip-content', chosenPosition]"
            >
                <slot name="tooltip">
                    {{ tooltip }}
                </slot>
            </div>
<!--        </transition>-->
    </div>
</template>
<script>
export default {
    props: {
        tooltip: {
            type: String,
            required: false,
            default: '',
        },
        position: {
            type: String,
            required: false,
            default: 'top',
        },
        mobilePosition: {
            type: String,
            required: false,
            default: null,
        },
        show: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            // show: false,
            timer: null,
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        chosenPosition() {
            return this.windowWidth < 768
                ? this.mobilePosition
                    ? this.mobilePosition
                    : this.position
                : this.position;
        },
        // todo - check touch device, but it should be only for tablets and phones (but currently also applies
        //  to laptops with touch screen)
        // isTouchDevice() {
        //     return 'ontouchstart' in window || navigator.maxTouchPoints;
        // },
    },
    created() {
        window.addEventListener('resize', this.updateWindowWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWindowWidth);
    },
    methods: {
        showTooltip() {
            // todo - check touch device, but only when it's ready
            // // Prevent tooltip from showing on touch devices
            // if (this.isTouchDevice) return;

            clearTimeout(this.timer);
            this.show = true;
        },
        hideTooltip() {
            this.timer = setTimeout(() => {
                this.show = false;
            }, 0); // delay in milliseconds
        },
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';


@mixin tooltip-arrow($direction, $width: 14px, $height: 14px, $background-color: #FFFFFF, $border-color: #E3E6ED, $border-width: 1px) {
    content: "";
    display: flex;
    position: absolute;
    background-color: $background-color;
    width: $width;
    height: $height;
    border-width: $border-width;
    border-style: solid;
    border-color: transparent $border-color $border-color transparent;
    border-radius: 0 0 50% 0;

    @if $direction == 'top' {
        transform: rotate(45deg);
        left: calc(50% - #{calc($width / 2)});
        bottom: -#{calc($height / 2)};
    } @else if $direction == 'right' {
        transform: rotate(135deg);
        top: calc(50% - #{calc($height / 2)});
        left: -#{calc($width / 2)};
    } @else if $direction == 'bottom' {
        transform: rotate(225deg);
        top: -#{calc($height / 2)};
        left: calc(50% - #{calc($width / 2)});
    } @else if $direction == 'left' {
        transform: rotate(315deg);
        top: calc(50% - #{calc($height / 2)});
        right: -#{calc($width / 2)};
    }
}

.tooltip-container {
    position: relative;
    display: flex;
    justify-content: center;
}

.tooltip-content {
    @include Aspekta-font(400, 14px, 20px, #021631);
    position: absolute;
    padding: 12px 16px;
    background-color: #FFFFFF;
    border: 1px solid #E3E6ED;
    box-shadow: 0 12px 16px rgba(36, 36, 36, 0.12);
    border-radius: 6px;
    white-space: normal;
    max-width: 292px;
    width: max-content;
    z-index: 1039;
    word-wrap: break-word;
    text-align: left;

    &:hover {
        opacity: 1;
    }

    &.top {
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);

        &::before {
            @include tooltip-arrow('top');
        }
    }

    &.right {
        top: 50%;
        left: calc(100% + 10px);
        transform: translateY(-50%);

        &::before {
            @include tooltip-arrow('right');
        }
    }

    &.bottom {
        top: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);

        &::before {
            @include tooltip-arrow('bottom');
        }
    }

    &.left {
        top: 50%;
        right: calc(100% + 15px);
        transform: translateY(-50%);

        &::before {
            @include tooltip-arrow('left');
        }
    }
}

</style>
