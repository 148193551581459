<template>
    <div class="compare-container">
        <b-container class="main-report-container">
            <div
                v-if="isLoading"
                class="spinner"
            >
                <Spinner />
            </div>
            <div v-else-if="!isLoading">
                <CompareHeader
                    :integration-id="integrationId"
                    :screen-width="screenWidth"
                    :integration="integration"
                    :current-job="currentJob"
                    :integration-name="integrationName"
                    :current-id="currentId"
                    :branch-name="branchName"
                    @downloadComparePDFReport="downloadComparePDFReport"
                />
                <div class="compare-versions-row">
                    <p class="main_text_content">
                        Versions comparison
                    </p>
                    <router-link
                        class="compare-versions-row__close-report"
                        :to="{path: `/integration/${integrationId}/branch/${branchName}`}"
                    >
                        Close comparison
                    </router-link>
                </div>
                <div class="form-compare-block">
                    <CompareDropdown
                        :id="'currentVersion'"
                        class="dropdownCurrent"
                        :selected-current-version="selectedCurrentVersion"
                        :selected-current-date="selectedCurrentDate"
                        :selected-current-id="selectedCurrentId"
                        :selected-compare-id="selectedCompareId"
                        :options="allVersions"
                        @change-version="onChangeVersion"
                    />
                    <div class="repeat-icon">
                        <img
                            id="repeat-icon"
                            alt="repeat"
                            src="../../../assets/images/repeat.svg"
                            @click="swapVersions"
                        >
                    </div>
                    <CompareDropdown
                        :id="'compareVersion'"
                        class="dropdownCompare"
                        :selected-compare-version="selectedCompareVersion"
                        :selected-compare-date="selectedCompareDate"
                        :selected-compare-id="selectedCompareId"
                        :selected-current-id="selectedCurrentId"
                        :options="allVersions"
                        @change-version="onChangeVersion"
                    />
                </div>

                <p
                    v-if="compareByCategories.length > 0"
                    class="main_text_content"
                >
                    List of vulnerabilities
                </p>
                <NoChangesBlock
                    v-if="!compareByCategories.length"
                />
                <CategoriesCompare
                    v-if="compareByCategories.length > 0"
                    class="categoriesCompare"
                    :is-trial-restriction="currentJob.data.type === 'INTEGRATION' ? integration.trial : currentJob.data.type === 'TRIAL'"
                    :compare-by-categories="compareByCategories"
                    :refresh-loading="refreshLoading"
                    :screen-width="screenWidth"
                    @scroll-to-category="scrollToChosenCategory"
                />

                <div
                    v-if="allVulInCode.length > 0"
                    class="compare-versions__vuln-in-code"
                >
                    <h2
                        id="pagination-top"
                        class="vulnerability__sign"
                    >
                        Vulnerabilities in the code
                    </h2>
                    <div
                        v-if="screenWidth > 770"
                        class="icons-amount"
                    >
                        <div class="resolved">
                            <img
                                src="../../../assets/images/vulnerabilities/check-circle.svg"
                                class="icons-amount__resolved"
                                alt="check circle"
                            >
                            <div>Resolved : {{ resolvedVulsCount }}</div>
                        </div>
                        <div class="new-bug">
                            <img
                                src="../../../assets/images/vulnerabilities/Bug.svg"
                                class="icons-amount__new"
                                alt="bug grey"
                            >
                            <div>New : {{ newVulsCount }}</div>
                        </div>
                    </div>
                </div>
                <Pagination
                    v-if="allVulInCode.length > 0"
                    class="compare-versions__pagination"
                    :count="totalCount"
                    :limit="perPage"
                    :current-page="currentPage"
                    :show-spinner="showSpinner || isLoading"
                    @pageChanged="onPageChange"
                />
                <div v-if="allVulInCode.length && !showSpinner">
                    <VulnerabilityInCode
                        :is-trial-restriction="currentJob.data.type === 'INTEGRATION' ? integration.trial : currentJob.data.type === 'TRIAL'"
                        :vulnerabilities="allVulInCode.slice(indexOfFirstVul, indexOfLastVul)"
                        :allow-false-positive="true"
                        :current-id="currentId"
                        :owner="true"
                        :selected-category="selectedCategory"
                        :integration-id="integrationId"
                        :branch-name="branchName"
                        :version="selectedCurrentVersion"
                        :compare="true"
                        :screen-width="screenWidth"
                    />
                    <div
                        v-if="isLoading"
                        class="spinner"
                    >
                        <Spinner />
                    </div>
                    <Pagination
                        v-if="allVulInCode.length > 0"
                        class="compare-versions__pagination"
                        style="padding-bottom: 20px;"
                        :count="totalCount"
                        :limit="perPage"
                        :current-page="currentPage"
                        :show-spinner="showSpinner || isLoading"
                        @pageChanged="onPageChange"
                    />
                </div>
            </div>

            <a
                v-if="toggleDisplayBtnToTop"
                class="btn-to-top"
                @click="toTop"
            >
                <svg
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        width="56"
                        height="56"
                        rx="28"
                        fill="#F5F7FA"
                    />
                    <path
                        d="M28 25.4142L20.7071 32.7071C20.3166 33.0976 19.6834 33.0976 19.2929 32.7071C18.9024 32.3166 18.9024 31.6834 19.2929 31.2929L27.2929 23.2929C27.6834 22.9024 28.3166 22.9024 28.7071 23.2929L36.7071 31.2929C37.0976 31.6834 37.0976 32.3166 36.7071 32.7071C36.3166 33.0976 35.6834 33.0976 35.2929 32.7071L28 25.4142Z"
                        fill="#949DB8"
                    />
                </svg>
            </a>
        </b-container>
        <div
            v-if="showSpinner"
            class="compare__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import VulnerabilityInCode from '../../../components/scans/VulnerabilityInCode';
import Spinner from '../../../components/Spinner';
import CompareHeader from '../../../components/compare/compareHeader';
import CategoriesCompare from '../../../components/compare/categoriesCompare';
import CompareDropdown from '../../../components/compare/dropdown/compareDropdown';
import { FolderNameEnum } from '../../../utils/constants/common';
import Vue from 'vue';
import Pagination from '../../../components/Pagination';
import NoChangesBlock from './NoChangesBlock.vue';

export default {
    name: 'VersionsCompare',
    components: {
        VulnerabilityInCode,
        Spinner,
        CompareHeader,
        CategoriesCompare,
        CompareDropdown,
        Pagination,
        NoChangesBlock,
    },
    data() {
        return {
            currentId: '',
            integrationId: '',
            integrationName: '',
            branchName: '',
            jobName: '',
            isLoading: false,
            count: 0,
            selectedCurrentVersion: '',
            selectedCompareVersion: '',
            selectedCurrentDate: '',
            selectedCompareDate: '',
            selectedCurrentId: '',
            selectedCompareId: '',
            allVersions: [],
            filterCompareVersions: [],
            filterCurrentVersions: [],
            screenWidth: 0,
            currentPage: 1,
            totalCount: 0,
            indexOfLastVul: null,
            indexOfFirstVul: null,
            perPage: 20,
            isCopied: false,
            chosenCategoryId: null,
            refreshLoading: false,
            toggleDisplayBtnToTop: false,
            showSpinner: false,
            isTrialRestriction: false,
        };
    },
    computed: {
        ...mapGetters({
            currentJob: 'jobs/currentJob',
            integration: 'integrations/integrationById',
            vulnerabilityPage: 'jobs/vulnerabilityPage',
            selectedCategory: 'jobs/selectedCategory',
            allVulInCode: 'compare/allVulInCode',
            newVulsCount: 'compare/newVulsCount',
            resolvedVulsCount: 'compare/resolvedVulsCount',
            compareByCategories: 'compare/compareByCategories',
            allCategories: 'compare/allCategories',
            finishedVersions: 'integrations/allFinishedVersionsByBranch',
        }),
    },
    watch: {
        currentJob() {
            if (this.currentJob && this.currentJob.data) {
                this.count = this.currentJob.data.statistics.percentageBySeverity.commonAmountVulnerabilities;
                this.selectedCurrentId = this.$route.query.new;
                this.selectedCompareId = this.$route.query.old;
            }
        },
        screenWidth() {},
        integration() {
            this.isTrialRestriction = this.integration && this.integration.trial === true;
        },
        refreshLoading() {},
        // watch selectedCurrentVersion and update document title
        selectedCurrentVersion() {
            this.updateDocumentTitle();
        },
        // watch selectedCompareVersion and update document title
        selectedCompareVersion() {
            this.updateDocumentTitle();
        },
    },
    created() {
        this.onResize();
        window.addEventListener('scroll', this.handleScroll);
    },
    async beforeMount() {
        this.isLoading = true;
        this.currentId = this.$route.query.old;
        this.integrationId = this.$route.params.integrationId;
        this.branchName = this.$route.params.branchName;
        this.$store.commit('jobs/setCurrentJob', null);
        await this.loadIntegrationVersion();
        await this.loadIntegrationById();
        await this.$store.dispatch('integrations/loadAllVersionsForBranch', {
            integrationId: this.integrationId,
            branchName: this.branchName,
        });
        this.setSelectedVersions();
        if (this.integrationId) {
            const currentVersionId = this.$route.query.new;
            const compareVersionId = this.$route.query.old;
            const response = await this.$store.dispatch('integrations/compareIntegrationsVersions', {
                integrationId: this.integrationId,
                branchName: this.branchName,
                versionId: this.currentId,
                currentVersionId,
                compareVersionId,
            });
            await this.$store.dispatch('integrations/loadIntegrationById', {
                id: this.integrationId,
                branchName: this.branchName,
                page: 1,
                limit: 100,
            });

            await this.getAllVulnerabilitiesCategories();
            await this.getChangesCompare(response.category_change);
            await this.getVulnerabilitiesPerPage();
            this.checkForVId();

        } else {
            await this.$store.dispatch('jobs/fetchJobById', {
                jobId: this.currentId,
                folderName: FolderNameEnum.MAIN_REPORT,
            });
        }

        this.getAllVersions();
        this.isLoading = false;
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        async onPageChange(pageNumber) {
            await this.getPage(pageNumber);
            if (!this.chosenCategoryId) this.toTopVulInCode();
            this.chosenCategoryId = null;
        },
        async checkForVId() {
            let vId = this.$route.query.vId ? this.$route.query.vId : null;
            if (vId) {
                const vulnerability = this.allVulInCode.find(vul => vul.id === vId);
                if (vulnerability) {
                    const page = Math.floor(this.allVulInCode.findIndex(vul => vul.id === vId) / 20) + 1;
                    await this.getPage(page);
                    setTimeout(() => document.getElementById(vulnerability.id).scrollIntoView(), 0);
                }
            }
        },
        async getPage(page = 1) {
            this.showSpinner = true;
            this.currentPage = page;
            this.indexOfLastVul = this.currentPage * this.perPage;
            this.indexOfFirstVul = this.indexOfLastVul - this.perPage;
            await this.getVulnerabilitiesPerPage();
            this.showSpinner = false;
        },
        async downloadComparePDFReport() {
            this.showSpinner = true;
            let url = `/private/ui/pdf_report/integrations_compare/${this.integration.id}/branches/${this.branchName}/versions/compare?old=${this.$route.query.old}&new=${this.$route.query.new}`;
            this.axios.post(url).then(
                response => {
                    this.showSpinner = false;
                    window.open(response.data.url);
                },
                error => {
                    this.showSpinner = false;
                    console.log(error);
                },
            );
        },
        async loadNewCompareReport(params) {
            const { currentVersionId, compareVersionId } = params;
            this.$router.replace({ query: { old: `${compareVersionId}`, new: `${currentVersionId}` } });
            this.isLoading = true;
            if (this.integrationId) {
                const response = await this.$store.dispatch('integrations/compareIntegrationsVersions', {
                    integrationId: this.integrationId,
                    branchName: this.branchName,
                    versionId: this.currentId,
                    currentVersionId,
                    compareVersionId,
                });
                await this.$store.dispatch('integrations/loadIntegrationById', {
                    id: this.integrationId,
                    branchName: this.branchName,
                    page: 1,
                    limit: 100,
                });

                await this.getAllVulnerabilitiesCategories();
                await this.getChangesCompare(response.category_change);
                await this.getVulnerabilitiesPerPage();
                this.checkForVId();
            } else {
                await this.$store.dispatch('jobs/fetchJobById', {
                    jobId: this.currentId,
                    folderName: FolderNameEnum.MAIN_REPORT,
                });
            }
            this.getAllVersions();
            this.isLoading = false;
        },
        async loadIntegrationVersion() {
            if (this.integrationId) {
                await this.$store.dispatch('integrations/loadIntegrationVersion', {
                    integrationId: this.integrationId,
                    versionId: this.currentId,
                    branchName: this.branchName,
                    folderName: FolderNameEnum.MAIN_REPORT,
                });
            } else {
                await this.$store.dispatch('jobs/fetchJobById', {
                    jobId: this.currentId,
                    folderName: FolderNameEnum.MAIN_REPORT,
                });
            }
        },
        async loadIntegrationById() {
            if (this.integrationId && this.integration) {
                this.integrationName = this.integration.integrationName;
            } else if (this.integrationId) {
                await this.$store.dispatch('integrations/loadIntegrationById', {
                    id: this.integrationId,
                    branchName: this.branchName,
                    page: 1,
                    limit: 10,
                });
                this.integrationName = this.integration.integrationName;
            }
        },
        async getVulnerabilitiesPerPage() {
            const vulnerabilities = this.allVulInCode.slice(this.indexOfFirstVul, this.indexOfLastVul).map(vul => ({
                id: vul.id,
                jobId: vul.jobId,
                categoryId: vul.categoryId,
            }));
            const versionIds = [this.$route.query.old, this.$route.query.new];
            if (!vulnerabilities.length) return;
            await this.$store.dispatch('compare/getVulnerabilitiesForCompare', {
                integrationId: this.integrationId,
                branchName: this.branchName,
                vulnerabilities,
                versionIds,
            });
        },
        /**
         * Search engines typically display about the first 55–60 characters of a page title.
         * All static parts including spaces: "❯ - | Oversecured`" are 17 characters.
         * currentVersion and compareVersion: from 7 to 10 (including ...) characters. So total 14-20
         * integrationName: from 20 to 23 (including ...) characters.
         */
        updateDocumentTitle() {
            if (this.currentJob && this.currentJob.data) {
                let currentVersion = this.selectedCurrentVersion;
                let compareVersion = this.selectedCompareVersion;
                let name = this.integrationName;
                document.title = `${currentVersion.length > 7 ? currentVersion.substring(0, 7) + '...' : this.selectedCurrentVersion}
                ❯
                ${compareVersion.length > 7 ? compareVersion.substring(0, 7) + '...' : this.selectedCompareVersion}
                -
                ${name.length > 20 ? name.substring(0, 20) + '...' : this.integrationName} | Oversecured`;
            } else {
                if (this.currentJob.isJobFound) {
                    let isAuthenticated = this.$auth.isAuthenticated;

                    if (!isAuthenticated) {
                        this.$auth.loginWithRedirect({
                            appState: { targetUrl: `scan/info/${this.currentId}` },
                        });
                    }
                }
                document.title = 'Page not found | Oversecured';
                this.$router.push('/not-found');
            }
        },
        async getAllVulnerabilitiesCategories() {
            await this.$store.dispatch('compare/getAllVulnerabilitiesCategories');
        },
        async getChangesCompare(newReport) {
            await this.$store.dispatch('compare/getChangesCompare', {
                newReport,
                currentId: this.currentId,
                integration: this.integration,
                currentJob: this.currentJob,
            });
            await this.$store.dispatch('compare/setVulnerabilitiesInCode', this.integration.trial);

            this.totalCount = this.allVulInCode.length;
            this.indexOfLastVul = this.currentPage * this.perPage;
            this.indexOfFirstVul = this.indexOfLastVul - this.perPage;
        },
        getAllVersions() {
            if (this.finishedVersions) {
                this.allVersions = this.finishedVersions.map(
                    (item) => {
                        return {
                            value: null,
                            version: item.version,
                            time: item.completionTime,
                            id: item.id,
                        };
                    },
                );
            }
        },
        setSelectedVersions() {
            if (this.finishedVersions) {
                const versionsStatusFinish = this.finishedVersions;

                let { id: currentId, version: currentVersion, completionTime: currentDate } = versionsStatusFinish.find(version => version.id === this.$route.query.new);
                let { id: compareId, version: compareVersion, completionTime: compareDate } = versionsStatusFinish.find(version => version.id === this.$route.query.old);

                this.selectedCurrentVersion = currentVersion;
                this.selectedCompareVersion = compareVersion;
                this.selectedCurrentDate = currentDate;
                this.selectedCompareDate = compareDate;
                this.selectedCurrentId =  currentId;
                this.selectedCompareId = compareId;
            }
        },
        async onChangeVersion(e) {
            const { id, value, date, dropDownType } = e;
            if (dropDownType === 'currentVersion') {
                this.selectedCurrentVersion = value;
                this.selectedCurrentDate = date;
                this.selectedCurrentId = id;
            } else if (dropDownType === 'compareVersion') {
                this.selectedCompareVersion = value;
                this.selectedCompareDate = date;
                this.selectedCompareId = id;
            }
            await this.sendNewCompareHandler();
            await this.getPage();
        },

        async swapVersions() {
            // Swap versions
            [this.selectedCurrentVersion, this.selectedCompareVersion] = [this.selectedCompareVersion, this.selectedCurrentVersion];

            // Swap dates
            [this.selectedCurrentDate, this.selectedCompareDate] = [this.selectedCompareDate, this.selectedCurrentDate];

            // Swap ids
            [this.selectedCurrentId, this.selectedCompareId] = [this.selectedCompareId, this.selectedCurrentId];

            await this.sendNewCompareHandler();
            await this.getPage();
        },
        async sendNewCompareHandler() {
            await this.loadNewCompareReport({
                currentVersionId: this.selectedCurrentId,
                compareVersionId: this.selectedCompareId,
            });
        },
        onResize() {
            this.screenWidth = document.documentElement.clientWidth;
        },
        async scrollToChosenCategory(type) {
            let element = this.allVulInCode.find(el => el.type === type);
            const page = Math.floor(this.allVulInCode.findIndex(item => item.type === type) / 20) + 1;
            if (this.currentPage !== page) await this.getPage(page);
            if (element) {
                Vue.nextTick(() => {
                    this.chosenCategoryId = element.id;
                    const vulnerabilityItem = document.getElementById(this.chosenCategoryId);
                    setTimeout(() => vulnerabilityItem.scrollIntoView({ behavior: 'smooth' }), 0);
                });
            }
        },
        toTop() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        handleScroll() {
            this.toggleDisplayBtnToTop = window.scrollY > 500;
        },
        toTopVulInCode() {
            let paginationTop = document.getElementById('pagination-top');
            if (paginationTop) {
                setTimeout(() => {
                    paginationTop.scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../../assets/css/fonts';
@import "../../../assets/css/variables";

.compare-container {
    padding: 0;
    margin: 0;
    background: rgba(248, 250, 252, 1);
}

.main-report-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
}

img {
    height: 22px;
}

.compare-versions {

    &-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 40px;

        &__close-report {
            all: unset;
            @include Aspekta-font(400, 14px, 20px, $main-blue-color);
            margin-left: 24px;
            margin-bottom: -3px;
            cursor: pointer;
        }
    }

    &__vuln-in-code {
        display: flex;
        align-items: center;
        margin: 40px 0 10px;
        gap: 25px;
    }

    &__pagination {
        justify-content: flex-end;
    }


}
.repeat-icon {
    margin: 20px 10px;
    cursor: pointer;

    & img:hover {
        transform: scale(0.9);
        opacity: 0.7;
    }
}

.form-compare-block {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    z-index: 90;
    margin: 20px 0 40px;
}

.vulnerability__sign {
    @include Aspekta-font(650, 22px, normal, #021631);
    letter-spacing: 0.66px;
    margin: 0;
}

.spinner {
    position: fixed;
    background: rgba(256, 256, 256, 0.5);
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
}

.icons-amount {
    display: flex;
    height: 30px;
    align-items: center;
    gap: 25px;

    .resolved, .new-bug {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }

    .resolved div {
        @include Aspekta-font(400, 14px, normal, #4CE074);
    }

    .new-bug div {
        @include Aspekta-font(400, 14px, normal, #FB7462);
    }
}

.main_text_content {
    @include Aspekta-font(650, 22px, normal, #021631);
    letter-spacing: 0.66px;
    margin: 0;
}

.btn-to-top {
    position: fixed;
    bottom: 50px;
    border: 4px solid transparent;
    right: 24px;
    border-radius: 50%;
    transition: 0.3s;
    z-index: 9;

    &:hover {
        cursor: pointer;
        border-radius: 50%;
        border: 4px solid #E3E6ED;
    }
}

.compare__spinner {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100
}

@media (max-width: 992px) {

    .form-compare-block {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .compare-versions {

        &__pagination {
            justify-content: space-between;
        }
    }
}

</style>
