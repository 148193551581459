<template>
    <a href='#' class="dropdown-content" @click="changeVersion(id , version, date, dropDownType, disabled, chosenVersion)" >
        <slot class="dropdown-content_slot" />
        <div class="dropdown-content_date">{{ formatDateData(date) }}</div>
        <div v-if="chosenVersion" class="dropdown-content_active">✔︎</div>
    </a>
</template>

<script>
import { formatDate } from '../../../utils/helper-functions';
export default {
    name: 'AppDropdownItem',
    props: ['version','id','date', 'disabled', 'chosenVersion','dropDownType'],
    data() {
        return {};
    },
    methods: {
        formatDateData(time) {
            return formatDate(time);
        },
        changeVersion( id, version, date, dropDownType, disabled, chosenVersion ) {
            if (disabled) return;
            if (chosenVersion) return;
            this.$emit('change-version', { id , value : version, date: date, dropDownType } );
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/css/fonts.scss';

.dropdown-content {
display: flex;
height: 68px;
width: 100%;
padding: 22px 22px 22px 45px;
border-bottom: 1px solid #F0F0F0;
@include Aspekta-font(bold, 15px, normal, #242424);
align-items: center;
border-radius: 0;
z-index:99;
position : relative;
justify-content: space-between;
&:hover{
  color:black;
  text-decoration: none;
  opacity : 0.5;
}

&_date {
  position: relative;
  left : 20px;
  @include Aspekta-font(normal, 14px, normal, #B2B9C3);
  margin: 4px 50px 0 0;
}

&_active {
  color: #61D4B3;
  position: absolute;
  right: 29px;
}

&:first-child{
  border-radius: 20px 20px 0 0 ;
}
&:last-child{
  border : none;
}
}
@media (max-width: 420px) {
    .dropdown-content {
      padding-left: 10px;
    }
    .dropdown-content_active{
      right: 10px;
    }
    .dropdown-content_date{
      margin-right: 34px;
    }

}
</style>

