<template>
    <div class="inner-integrations__wrapper">
        <div
            v-if="viewerPage && integrationById.showPage"
            class="inner-integrations"
        >
            <InnerIntegrationsHeader
                :is-expired="isExpired"
                :integration="integrationById"
            />
            <InnerIntegrationsStatistics_new />
            <InnerIntegrationsInProgress />
            <InnerIntegrationsFinished />
        </div>
        <PageNotFound
            v-if="viewerPage && !integrationById.showPage"
        />
        <div
            v-if="showSpinner"
            class="inner-integrations__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import InnerIntegrationsHeader from '../../../components/innerIntegration/InnerIntegrationsHeader';
import InnerIntegrationsStatistics_new from '../../../components/innerIntegration/InnerIntegrationStatistics_new';
import InnerIntegrationsFinished from '../../../components/innerIntegration/InnerIntegrationsFinished';
import InnerIntegrationsInProgress from '../../../components/innerIntegration/InnerIntegrationsInProgress';
import PageNotFound from '../../../components/pageNotFound/PageNotFound';
import {mapGetters} from 'vuex';
import {IntegrationStatusEnum} from '@/utils/constants/common';
import Spinner from '../../../components/Spinner';
import {VersionStatusEnum} from '../../../utils/constants/common';

export default {
    components: {
        InnerIntegrationsHeader,
        InnerIntegrationsStatistics_new,
        InnerIntegrationsFinished,
        InnerIntegrationsInProgress,
        PageNotFound,
        Spinner,
    },
    data: () => {
        return {
            viewerPage: false,
            isExpired: false,
            showSpinner: false,
            branchName: null,
            integrationId: null,
        };
    },
    computed: {
        ...mapGetters({
            integrationById: 'integrations/integrationById',
        }),
    },
    watch: {
        integrationById() {
            if (new Date(this.integrationById.dateExpiredIntegration) < Date.now() &&
                this.integrationById.status !== IntegrationStatusEnum.SAMPLE) {
                this.isExpired = true;
            }
        },
        '$route.params.branchName': {
            handler () {
                this.$store.commit('integrations/clearIntegrationBranchData');
                this.$store.dispatch('integrations/loadIntegrationById', {id: this.integrationId, branchName: this.branchName});
            },
            deep: true,
        },
    },
    async beforeMount() {
        this.showSpinner = true;

        await this.loadIntegrationData();

        if (this.integrationById.showPage) {
            document.title = `${this.integrationById.integrationName} — Integration | Oversecured`;
        } else {
            document.title = 'Page not found | Oversecured';
        }

        this.showSpinner = false;
        this.viewerPage = true;
    },
    beforeDestroy() {
        this.$store.commit('integrations/clearIntegrationData');
    },
    methods: {
        areScanningVersions(listVersion) {
            return listVersion.filter(el => el.status !== VersionStatusEnum.FINISHED || el.status === VersionStatusEnum.ERROR);
        },
        areFinishedVersions(listVersion) {
            return listVersion.filter(el => el.status === VersionStatusEnum.FINISHED || VersionStatusEnum.ERROR);
        },
        lastVersion(listVersion) {
            const list = listVersion.filter(el => el.status === VersionStatusEnum.FINISHED);
            return list[0] || false;
        },
        async loadIntegrationData() {
            this.integrationId = this.$route.params.id;
            this.branchName = this.$route.params.branchName;
            await this.$store.dispatch('integrations/loadIntegrationById', {id: this.integrationId, branchName: this.branchName});
        },
    },
};
</script>
<style lang="scss">
.inner-integrations {
    padding: 40px 0 70px;
    background-color: #F8FAFC;
    min-height: 70vh;

    &__wrapper {
        position: relative;
    }

    &__spinner {
        position: fixed;
        top: 15%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 100;
    }
}
</style>
