<template>
    <div class="question">
        <div class="question-about">
            <span class="question-about__text">{{ question }}</span>
            <div
                class="toggle-button"
                @click="toggleButton"
            >
                <div
                    class="line"
                />
                <div
                    class="line"
                    :class="{ 'line_2': !isOpen, }"
                />
            </div>
        </div>
        <span
            :class="{ answerText: isOpen, answerTextHidden: !isOpen }"
            v-html="processedAnswer"
        />
        <div
            class="question__line"
        />
    </div>
</template>
<script>

import DOMPurify from 'dompurify';


export default {
    props:{
        question:{
            type: String,
            default: '',
        },
        answer:{
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isOpen: false,

        };
    },
    computed: {
        processedAnswer() {
            return this.answer.replace(/\[([^\]]+)\]/g, (match, p1) => {
                return  DOMPurify.sanitize(`<a id="link-text" style="color: #3180F6;
                    cursor: pointer; text-decoration: underline;">${p1}</a>`);
            });
        },
    },
    mounted() {
        this.$nextTick(function () {
            let link = document.getElementById('link-text');
            if(link) {
                link.addEventListener('click', this.routerToContactUs);
            }
        });
    },
    beforeDestroy() {
        let link = document.getElementById('link-text');
        if(link) {
            link.addEventListener('click', this.routerToContactUs);
        }
    },
    methods: {
        async toggleButton() {
            this.isOpen = !this.isOpen;
        },
        routerToContactUs() {
            this.$router.push('/contact-us');
        },
    },

};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/variables/variablesForFAQs.scss";
@import "../../../assets/css/variables.scss";
@import "../../../assets/css/fonts";



.question {
    display: flex;
    flex-direction: column;

    &-about {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__text {
            @include Aspekta-font(700, $dynamic-question-about-text-font-size, normal, $general-black);
            width: 90%;
        }
    }

    &__line {
        width: 100%;
        height: 2px;
        opacity: 0.2;
        background: var(--ghost-text, #797E86);
        margin-top: 40px;
    }
}
.answerText {
    @include Aspekta-font(400, $dynamic-answer-text-font-size, normal, #797E86);
    text-align: justify;
    margin-top: 20px;
    opacity: 1;
    animation-name: text-open;
    animation-duration: 0.5s;
}
.answerTextHidden {
    @include Aspekta-font(400, $dynamic-answer-text-font-size, normal, #797E86);
    text-align: justify;
    margin-top: 0;
    opacity: 0;
    height: 0;
    animation-duration: 0.5s;
    animation-name: text-close;
}
@keyframes text-open {
    0% {
        margin-top: 0;
        opacity: 0;
        height: 0;
    }

    100% {
        margin-top: 20px;
        opacity: 1;
        height: 100%;
    }
}
@keyframes text-close {
    0% {
        margin-top: 30px;
        opacity: 1;
        height: 100%;
    }

    100% {
        margin-top: 0;
        opacity: 0;
        height: 0;
    }
}

.toggle-button {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  top: 10px;
}

.line {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: black;
  transition: transform 0.4s ease;
}



.line_2 {
  bottom: 18px;
  transform: rotate(-90deg);
}

@media (max-width: 768px) {
    .question-about__text {
        font-size: 18px;
    }
    .answerText {
        font-size: 14px;
    }
}


</style>
