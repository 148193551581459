<!-- Pagination.vue -->
<template>
    <b-pagination
        v-model="innerCurrentPage"
        :total-rows="count"
        :per-page="limit"
        :limit="paginationLimit"
        :disabled="showSpinner"
        class="pagination-general"
        aria-controls="my-table"
        first-number
        last-number
    >
        <!-- <template #first-text>
            <UnifiedPaginationPageIcon iconType="first" :state="iconState('first')" size="14" class="first-text"/>
        </template> -->
        <template #prev-text>
            <UnifiedPaginationPageIcon
                icon-type="prev"
                :state="iconState('prev')"
                size="14"
                class="prev-text"
            />
        </template>
        <template #next-text>
            <UnifiedPaginationPageIcon
                icon-type="next"
                :state="iconState('next')"
                size="14"
                class="next-text"
            />
        </template>
        <!-- <template #last-text>
            <UnifiedPaginationPageIcon iconType="last" :state="iconState('last')" size="14"/>
        </template> -->
        <template #ellipsis-text>
            ...
        </template>
    </b-pagination>
</template>

<script>
import UnifiedPaginationPageIcon from '../assets/iconTemplates/UnifiedPaginationPageIcon.vue';

export default {
    name: 'Pagination',
    components: {
        UnifiedPaginationPageIcon,
    },
    props: {
        count: {
            type: Number,
            required: true,
            validator(value) {
                return value >= 0;
            },
        },
        limit: {
            type: Number,
            required: true,
            validator(value) {
                return value >= 0;
            },
        },
        currentPage: {
            type: Number,
            required: true,
            validator(value) {
                return value > 0;
            },
        },
        showSpinner: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            innerCurrentPage: this.currentPage,
            screenWidth: window.innerWidth,
        };
    },
    computed: {
        lastPage() {
            return Math.ceil(this.count / this.limit);
        },
        paginationLimit() {
            return this.screenWidth <= 768 ? 4 : 5;
        },
    },
    watch: {
        innerCurrentPage(page, oldPage) {
            const calculatedPageLimit = Math.ceil(this.count / this.limit);

            if (page > calculatedPageLimit) {
                this.innerCurrentPage = 1;
            } else if (page === calculatedPageLimit || oldPage > calculatedPageLimit) {
                this.innerCurrentPage = calculatedPageLimit;
            } else {
                this.innerCurrentPage = page;
            }

            this.$emit('pageChanged', this.innerCurrentPage);
        },
        currentPage(page) {
            this.innerCurrentPage = page;
        },
    },
    mounted() {
        window.addEventListener('resize', this.updateScreenWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenWidth);
    },
    methods: {
        iconState(iconType) {
            if ((iconType === 'first' || iconType === 'prev') && this.currentPage === 1) {
                return 'disabled';
            } else if ((iconType === 'last' || iconType === 'next') && this.currentPage === this.lastPage) {
                return 'disabled';
            } else {
                return 'active';
            }
        },
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        },
    },

};
</script>
<style lang="scss">
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

.pagination-general {
    @include Aspekta-font(500, 14px, 20px, #3180F6);
    border: none;
    display: flex;
    align-items: center;
    margin: 0;

    .page-link {
        display: flex;
        @include Aspekta-font(500, 14px, 20px, #949DB8);
        border: none !important;
        padding: 10px !important;
        width:32px;
        height:32px;
        border-radius: 8px;
        background: none !important;

        &:focus {
            outline: 5px solid white !important;
            box-shadow: 0 0 0 0.2rem rgba(49, 128, 246, 0) !important;
            // border: none !important;
        }
    }

    .page-item {
        margin: 0 8px;

        .page-link {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #949DB8;
        }

        &:not(:nth-child(1)):not(:nth-last-child(1)):hover {
            border-radius: 8px;
            background: #F5F7FA;
        }

    }

    .page-item.active {
        background: #EBF2FD;
        border-radius: 8px;

        .page-link {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #3180F6 !important;

            &:hover {
                opacity: 0.63;
            }
        }

        &:hover {
            border-radius: 8px;
            background: #F5F7FA;
        }

    }

    .page-item.disabled {
        background: none;
        .page-link {
            color: #949DB8 !important;
        }
    }

}

.pagination-general .page-item.disabled .page-link {
    cursor: not-allowed;
}
</style>
