<template>
    <svg class="success" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10"/>
        <path d="M16.9863 8.52888C16.2407 7.78334 15.032 7.78334 14.2864 8.52888L10.9899 11.8254L9.71356 10.5491C8.96802 9.80354 7.75925 9.80354 7.0137 10.5491C6.26815 11.2946 6.26815 12.5034 7.0137 13.2489L9.63996 15.8752C10.3855 16.6208 11.5943 16.6208 12.3398 15.8752L16.9863 11.2287C17.7318 10.4832 17.7318 9.27443 16.9863 8.52888ZM23 12C23 5.92537 18.0746 1 12 1C5.92537 1 1 5.92537 1 12C1 18.0746 5.92537 23 12 23C18.0746 23 23 18.0746 23 12ZM4.81818 12C4.81818 8.03309 8.03309 4.81818 12 4.81818C15.9669 4.81818 19.1818 8.03309 19.1818 12C19.1818 15.9669 15.9669 19.1818 12 19.1818C8.03309 19.1818 4.81818 15.9669 4.81818 12Z" stroke-width="2"/>
    </svg>
</template>

<script>
export default {
    name: 'SuccessIcon'
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

svg.success {
    path {
        fill: $main-green-color;
        stroke: $background-green-color;
    }
    circle {
        fill: $background-green-color;
    }
}

</style>
</style>