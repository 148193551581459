<template>
    <b-modal
        :id="`general-popup${popupId}`"
        dialog-class="popup__dialog"
        content-class="popup__content"
        header-class="popup__header"
        title-class="popup__title"
        body-class="popup__body"
        footer-class="popup__footer"
        :size="size"
        :title="titleText"
        :hide-footer="loading || hideFooter"
        :hide-header="hideHeader"
        ok-only
        scrollable
        centered
        no-fade
        no-stacking
        lazy
        @show="onShow"
        @hidden="onHidden"
    >
        <Spinner v-if="loading" class="popup__body-spinner" />
        <slot v-else class="popup__body-slot" />
        <template #modal-footer>
            <slot name="ok-button" class="popup__footer-button" />
        </template>
    </b-modal>
</template>

<script>
import Spinner from '@/components/Spinner';
export default {
    components: { Spinner },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        titleText: {
            type: String,
            default: ''
        },
        popupId: {
            type: String,
            default: ''
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'sm'
        }
    },
    data() {
        return {

        };
    },
    methods: {
        submitClick() {
            this.$emit('submitClick');
        },
        onHidden() {
            this.$emit('onHidden');
        },
        onShow() {
            this.$emit('onShow');
        }
    }
};
</script>

<style lang='scss'>
.popup {
    &__content {
        border-radius: 16px;
        border: none;
    }
    &__header {
        border-bottom: none;
        padding-top: .5rem;
        .close {
            font-family: sans-serif;
            font-weight: 200;
            opacity: 1;
            margin: -1rem;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 18px;
        margin: 20px auto 0 auto;
    }
    &__body {
        &-spinner {
            margin-bottom: 25px;
        }
    }
    &__footer {
        align-self: center;
        border-top: none;
        margin-bottom: 20px;
    }
}
//remove padding-right on windows os
.modal-open {
    padding-right: 0 !important;
}



@media (min-width: 576px) {
    .modal-lg {
        max-width: 72% !important;
    }
}

@media (min-width: 900px) {
    .modal-lg {
        max-width: 748px !important;
    }
}
</style>
