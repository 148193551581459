<template>
    <div style="background-color: #F8FAFC;">
        <div class="new-integrations">
            <div
                v-if="userType === 'company'"
                class="new-integrations__sign-wrapper-company"
            >
                <h1 class="new-integrations__sign">
                    Integration Request
                </h1>
            </div>
            <form
                class="form__new-integrations"
                @submit.prevent="checkForm"
            >
                <div
                    v-if="userType === 'company' && !requestSent"
                    class="form__new-integrations-wrapper"
                >
                    <div class="form__new-integrations-input-wrapper">
                        <label class="form__new-integrations-label">
                            Integration Name
                            <input
                                v-if="userType === 'company' && !requestSent"
                                id="integrationName"
                                v-model.trim="integrationInfo.integrationName"
                                class="form__new-integrations-input"
                                type="text"
                                placeholder="E.g., My App"
                                @input="handleInput"
                                @blur="activeInputName = null"
                                @change="handleChange"
                            >
                            <img
                                v-if="this.integrationInfo.integrationName.length > 0"
                                class="form__new-integrations-input-check"
                                src="../../assets/images/cross_grey.svg"
                                alt="cross"
                                @click="clearInputAndErrors('integrationName')"
                            >
                            <div
                                id="integrationNameError"
                                class="form__new-integrations-error"
                            >
                                <img
                                    class="form__new-integrations-error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                    @click="clearInputAndErrors('integrationName')"
                                >
                                <p class="form__new-integrations-error-text">Required field</p>
                            </div>
                            <div
                                id="integrationNameLengthError"
                                class="form__new-integrations-error"
                            >
                                <img
                                    class="form__new-integrations-error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                    @click="clearInputAndErrors('integrationName')"
                                >
                                <p class="form__new-integrations-error-text">Max input length 1000</p>
                            </div>
                        </label>
                    </div>
                    <div class="form__new-integrations-input-wrapper">
                        <label class="form__new-integrations-label">
                            Application ID
                            <img
                                class="form__new-integrations--sign-icon"
                                src="../../assets/images/integration-settings-toggle-icon.svg"
                                alt=""
                            >
                            <p class="form__new-integrations--sign-icon-text">Package name for Android or Bundle Identifier for iOS</p>
                            <input
                                id="appId"
                                v-model.trim="integrationInfo.appId"
                                class="form__new-integrations-input"
                                type="text"
                                :placeholder="integrationInfo.platform === 'ios' ? 'E.g., com.apple.myapp' : 'E.g., com.android.myapp'"
                                @input="handleInput"
                                @blur="activeInputName = null"
                                @change="handleChange"
                            >
                            <img
                                v-if="integrationInfo.appId.length > 0"
                                class="form__new-integrations-input-check"
                                src="../../assets/images/cross_grey.svg"
                                alt="cross"
                                @click="clearInputAndErrors('appId')"
                            >
                            <div
                                id="appIdError"
                                class="form__new-integrations-error"
                            >
                                <img
                                    class="form__new-integrations-error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                    @click="clearInputAndError('appId')"
                                >
                                <p class="form__new-integrations-error-text">Required field</p>
                            </div>
                            <div
                                id="appIdLengthError"
                                class="form__new-integrations-error"
                            >
                                <img
                                    class="form__new-integrations-error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                    @click="clearInputAndErrors('appId')"
                                >
                                <p class="form__new-integrations-error-text">Max input length 1000</p>
                            </div>
                        </label>
                    </div>
                </div>
                <div
                    v-if="userType === 'company' && !requestSent"
                    class="form__new-integrations-platform-radio"
                >
                    <p class="form__new-integrations-platform-radio-title">
                        Platform
                    </p>
                    <div
                        id="platform"
                        class="form__new-integrations-platform-radio-btn-list"
                    >
                        <label class="form__new-integrations-platform-radio-label">
                            <input
                                id="radio-android"
                                v-model="integrationInfo.platform"
                                type="radio"
                                class="form__new-integrations-platform-radio-input"
                                name="platform"
                                value="android"
                            >
                            <span>Android</span>
                        </label>
                        <label class="form__new-integrations-platform-radio-label">
                            <input
                                id="radio-ios"
                                v-model="integrationInfo.platform"
                                type="radio"
                                class="form__new-integrations-platform-radio-input"
                                name="platform"
                                value="ios"
                            >
                            <span>iOS</span>
                        </label>
                    </div>
                    <div
                        id="platformError"
                        class="form__new-integrations-platform-error"
                    >
                        <p class="form__new-integrations-platform-error-text">
                            You need to choose platform
                        </p>
                    </div>
                </div>
                <div
                    v-if="userType === 'company' && !requestSent"
                    class="trial_request"
                >
                    <label
                        for="trialRequest"
                        class="trial_request_label"
                    >
                        <span class="checkmark" />
                        Request 2-week trial access
                        <input
                            id="trialRequest"
                            v-model="isTrialAccess"
                            type="checkbox"
                            name="trialRequest"
                        >
                    </label>
                </div>
                <button
                    v-if="userType === 'company' && !requestSent"
                    class="form__new-integrations-btn"
                    type="submit"
                >
                    Submit Request
                </button>
                <div
                    v-if="requestSent"
                    class="form__new-integrations-sent-mail-wrapper"
                >
                    <p class="form__new-integrations-sent-mail-text">
                        Thank you for your request! We will contact you by email about the next steps
                    </p>
                    <div style="display: flex; gap: 15px;">
                        <router-link :to="{path: '/integrations'}">
                            <button
                                class="form__new-integrations-btn"
                                :class="{ghost: isFromGetStated}"
                                type="button"
                                @click="loadIntegration"
                            >
                                Go To All
                                Integrations
                            </button>
                        </router-link>
                        <router-link
                            v-if="isFromGetStated"
                            :to="{path: '/get-started'}"
                        >
                            <button
                                class="form__new-integrations-btn"
                                type="button"
                                @click="$store.commit('user/setToGetStartedFrom', GetStartedStepsEnum.CREATED_INTEGRATION)"
                            >
                                Continue Setting Up
                            </button>
                        </router-link>
                    </div>
                </div>
                <div
                    v-if="userType === 'hacker'"
                    class="form__new-integrations-sent-mail-wrapper"
                >
                    <p class="form__new-integrations-sent-mail-text">
                        To use this feature, please
                        <a
                            :href="'https://support.oversecured.com/hc/en-us/requests/new'"
                            class="form__new-integrations-sent-mail-link"
                        >
                            contact us
                        </a>
                        to upgrade to company profile
                    </p>
                </div>
                <div
                    v-if="isRequestSendingNow"
                    class="form__new-integrations-spinner"
                >
                    <Spinner />
                </div>
            </form>
            <HowItWorksIntegration />
        </div>
    </div>
</template>
<script>
import Spinner from '../Spinner';
import HowItWorksIntegration from './how-it-works-integrations';
import { mapGetters } from 'vuex';
import { store } from '@/store';
import {
    checkFormInputsForCompleteness,
    checkFormInputsForErrors,
    checkFormInputsLength,
    hideErrors,
} from '@/services/functions';
import { GetStartedStepsEnum } from '../../utils/constants/common';

export default {
    components: {
        Spinner,
        HowItWorksIntegration,
    },
    data() {
        return {
            integrationInfo: {
                integrationName: '',
                appId: '',
                platform: 'android',
            },
            requestSent: false,
            isTrialAccess: false,
            isEmptyInput: '',
            activeInputName: null,
            GetStartedStepsEnum,
        };
    },
    computed: {
        ...mapGetters({
            isRequestSendingNow: 'integrations/isRequestSendingNow',
            userType: 'user/userType',
            isFromGetStated: 'user/isFromGetStated',
        }),
    },
    watch: {
        userType() {
            if (this.userType === 'company') {
                document.title = 'Integration Request | Oversecured';
            } else {
                document.title = 'Integrations | Oversecured';
            }
        },
    },
    beforeDestroy() {
        this.$store.commit('user/setIsFromGetStated', false);
    },
    methods: {
        clearInputAndErrors(inputField) {
            if (inputField === 'integrationName') {
                this.integrationInfo.integrationName = '';
                hideErrors(['integrationNameError', 'integrationNameLengthError']);
            } else if (inputField === 'appId') {
                this.integrationInfo.appId = '';
                hideErrors(['appIdError', 'appIdLengthError']);
            }
            document.getElementById(inputField).style.border = '1px solid #F0F0F0';
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id;
        },
        handleInput(e) {
            this.checkKeyEvent(e);
            this.handleKeyUp(e);
        },
        handleKeyUp(event) {
            const element = document.getElementById(`${event.target.id}Error`);
            element.style.display = 'none';
        },
        handleChange(e){
            if (e.target.value.trim()) {
                const el = document.getElementById(`${e.target.id}`);
                const label = document.getElementById(`${e.target.id}Error`);
                el.classList.remove('form__new-integrations-input--error');
                label.style.display = 'none';
            }
        },
        checkRadioCompleteness() {
            if (this.integrationInfo.platform === '') {
                const platformErrorText = document.getElementById('platformError');
                platformErrorText.style.display = 'block';
                const platformArea = document.getElementById('platform');
                platformArea.addEventListener('click', () => {
                    platformErrorText.style.display = 'none';
                });
            }
        },
        async checkForm() {
            hideErrors(['integrationNameError', 'integrationNameLengthError', 'appIdError', 'appIdLengthError', 'platformError']);

            checkFormInputsForErrors({ integrationName: this.integrationInfo.integrationName, appId: this.integrationInfo.appId }, 'form__new-integrations-input--error');
            checkFormInputsLength({ integrationName: this.integrationInfo.integrationName, appId: this.integrationInfo.appId }, 'form__new-integrations-input--length-error');
            this.checkRadioCompleteness();

            if (checkFormInputsForCompleteness(this.integrationInfo)) {
                await this.createJob(this.integrationInfo.integrationName, this.integrationInfo.appId, this.isTrialAccess.toString(), this.integrationInfo.platform);
            }
        },
        async createJob(integrationName, appId, trialAccess, platform) {
            store.commit('integrations/toggleIisRequestSending', true);
            await this.$store.dispatch('integrations/createIntegration', {
                integrationName,
                appId,
                platform,
                trialAccess,
            });
            gtag('event', 'integration_request_send', { event_category: 'engagement', event_label: 'custom' });
            this.requestSent = true;
        },
        loadIntegration() {
            this.$store.commit('user/setIsFromGetStated', false);
            if (this.$route.fullPath === '/integrations') {
                this.$store.dispatch('integrations/loadListAllIntegrationByUserId');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/variables";

.trial_request {
    width : 100%;
    position: relative;
    &_label{
        display: flex;
        gap: 10px;
        align-items: center;
        font-family: Aspekta, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #706969;
        box-sizing: border-box;
        margin-left: 145px;
        cursor: pointer;
    }
    &:hover {
        .checkmark {
            @include checkbox-square-hover("blue");
        }
        &:has(#trialRequest:checked) .checkmark {
            @include checkbox-checkmark-checked-hover("blue");
        }
    }
    #trialRequest {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .checkmark {
        @include checkbox-check("blue");
        display: inline-block;
    }
    &:has(#trialRequest:checked) .checkmark {
        @include checkbox-checkmark-checked("blue");
    }
    &:has(#trialRequest:checked) .checkmark::after,
    &:has(#trialRequest:checked) .checkmark::before {
        display: block;
    }
}
.new-integrations {
    padding: 40px 0 70px;
    width: 100% !important;

    &__sign {
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 150%;
        color: #242424;
        margin: 0;
    }

    &__sign-icon {
        cursor: pointer;
        margin-left: 5px;
    }

    &__sign-wrapper-company {
        margin-bottom: 30px;
        margin-left: 7.5%;
    }

    &__sign-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 190px;
        width: 100%;
        margin-bottom: 30px;
        padding-left: 20px;
    }

    &__sign-icon-text {
        @extend .btn-title;
        transform: translate(-24.4%);
        top: -60px;
        width: 400px;
    }


    .form__new-integrations {
        position: relative;
        margin: 0 7.5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        box-sizing: border-box;
        box-shadow: 0 4px 15px rgba(102, 124, 131, 0.06);
        border-radius: 20px;
        padding: 60px 0;
        min-height: 315px;
        max-width: 97% !important;

        &-input-check {
            position: absolute;
            bottom: 45px;
            right: 20px;
            cursor: pointer;
        }

        &-error {
            width: 100%;
            max-width: 831px;
            position: relative;
        }

        &-error-cross {
            position: absolute;
            top: -56px;
            right: 20px;
            cursor: pointer;
        }

        &-error-text {
            position: absolute;
            top: -29px;
            left: 0;
            font-family: Aspekta, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 150%;
            color: #FB8D62;
            margin: 0;
        }

        &--sign-icon-text {
            @extend .btn-title;
            transform: translate(-29.5%);
            top: -22px;
            left: 16px;
            width: 350px;
        }

        &--sign-icon {
            width: 13px;
            height: 13px;
            margin-left: 3px;
            cursor: pointer;
        }

        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .form__new-integrations-input-wrapper {
                position: relative;
                width: 100%;
                max-width: 405px;
                margin: 0 10px;
            }
        }

        &-btn {
            padding: 10px 40px;
            margin-top: 40px;
            background-color: #FF7A08;
            border: 2px solid #FF7A08;
            box-sizing: border-box;
            border-radius: 8px;
            font-family: Aspekta, serif;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: normal;
            letter-spacing: 0.54px;
            color: #FFFFFF;
            transition: 0.3s;
            &:hover {
                background-color: #fff;
                color: #FF7A08;
                box-shadow: 0px 8px 16px 0px rgba(255, 180, 0, 0.24);
            }
            &:active {
                opacity: 1;
                background-color: #FFB400;
            }

            &.ghost {
                color: #949DB8;
                background-color: #fff;
                border-color: #E3E6ED;
                &:hover {
                    box-shadow: 0px 12px 16px 0px rgba(178, 185, 195, 0.12);
                }
            }
        }

        &-sent-mail-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            animation-name: success;
            animation-duration: 1s;
        }

        &-sent-mail-text {
            font-family: Aspekta, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 140%;
            color: #706969;
            margin: 0;
            text-align: center;
            max-width: 440px;
        }

        &-sent-mail-link {
            color: #3180F6;
        }

        &-spinner {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.8);
            z-index: 1;
        }

        &-label {
            width: 100%;
            font-family: Aspekta;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            color: #B2B9C4;
            margin-bottom: 0;
        }

        &-input {
            background: #FFFFFF;
            border: 1px solid #F0F0F0;
            box-sizing: border-box;
            border-radius: 5px;
            font-family: Aspekta, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 140%;
            color: #242424;
            min-height: 40px;
            padding: 10px 35px 10px 17px;
            max-width: 476px;
            width: 100%;
            margin-bottom: 30px;
            margin-top: 2px;

            &--error {
                border: 1px solid #FB8D62;
                border-radius: 5px;
            }
        }

        &-platform-radio {
            display: flex;
            flex-direction: column;
            margin-left: 145px;
            margin-right: auto;
            margin-bottom: 14px;
        }

        &-platform-radio-btn-list {
            display: flex;
            flex-direction: row;

            & :first-child {
                margin-right: 40px;
            }
        }

        &-platform-radio-title {
            font-family: Aspekta;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #706969;
        }

        &-platform-radio-label>input {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        &-platform-radio-label>span {
            display: inline-flex;
            align-items: center;
            user-select: none;
            cursor: pointer;
            font-family: Aspekta;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            color: #706969;
        }

        &-platform-radio-label>span::before {
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            flex-shrink: 0;
            flex-grow: 0;
            background-color: white;
            border: 1px solid $border-blue-color;
            border-radius: 50%;
            margin-right: 0.5em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
        }

        &-platform-radio-label:hover>span::before {
            outline: 2px solid $background-blue-color;
        }
        &-platform-radio-label:has(input:checked):hover>span::before {
            outline: 2px solid $border-blue-color;
        }

        &-platform-radio-label>input:checked+span::before {
            background-color: $main-blue-color;
            border: 2.8px solid #ffffff;
            box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
        }

        &-platform-radio-label>input:disabled+span::before {
            background-color: #e9ecef;
            border: 2.8px solid #ffffff;
            box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
        }

        &-platform-error {
            width: 100%;
            max-width: 831px;
            position: relative;
            display: none;
        }

        &-platform-error-text {
            position: absolute;
            top: -9px;
            left: 0;
            font-family: Aspekta, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 150%;
            color: #FB8D62;
            margin: 0;
            padding-left: 5px;
            width: 100%;
            border-top: 1px solid #fb8d62;
        }
    }
}

.form__new-integrations--sign-icon:hover + .form__new-integrations--sign-icon-text {
    visibility: visible !important;
}

.new-integrations__sign-icon:hover + .new-integrations__sign-icon-text {
    visibility: visible !important;
}

#appIdError,
#integrationNameError,
#emailError,
#emailValidError {
    display: none;
}

#appIdError,
#integrationNameError,
#emailError,
#emailValidError,
#appIdLengthError,
#integrationNameLengthError,
#emailLengthError {
    display: none;
}
@media (max-width : 1200px) {
    .trial_request_label{
        margin-left : 50px;
    }

    .form__new-integrations-platform-radio {
        margin-left: 50px !important;
    }
}

@media (max-width: 1000px) {
    .new-integrations {
        padding: 20px 0 70px;

        &__sign-icon-text {
            transform: translate(-29.5%);
            top: -80px;
            width: 300px !important;

            &:after {
                display: none;
            }
        }

        &__sign-icon {
            margin-left: 5px;
        }

        &__sign {
            font-family: Raleway, serif;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 150%;
            color: #242424;
        }

        .form__new-integrations {
            padding: 40px 14px;
            margin: 0 20px;
            min-height: 335px;

            &-wrapper {
                flex-direction: column;

                .form__new-integrations-input-wrapper {
                    max-width: 100%;
                    padding: 0 25px;
                }
            }

            &-input {
                max-width: 100%;
            }

            &-btn-create {
                margin-top: 30px;
            }
            &-input-check {
                right: 43px;
            }
        }
    }

    .form__new-integrations--sign-icon-text {
        top: -40px !important;
        left: 64px !important;
        width: 250px !important;
    }

    .form__new-integrations-platform-radio {
        margin-left: 30px!important;
    }

    .trial_request{
        &_label{
            margin-left: 30px;
        }
    }
}

@media (max-width: 460px) {
    .new-integrations {

        .form__new-integrations {

            &-btn {
                padding: 10px 15px;
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 450px) {
    .form__new-integrations {
        &-platform-radio-title {
            max-width: 185px;
        }
    }
}

@keyframes success {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
