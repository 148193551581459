<template>
    <div
        id="integrationHistory"
        class="integration-history"
    >
        <div
            v-if="historyScans && historyScans.count"
            class="integration-history__container"
        >
            <div
                v-if="windowWidth > mediaWidthForCheckScreen"
                class="integration-history__block-first"
            >
                <div class="integration-history__header">
                    <div class="integration-history__list-item--first" />
                    <div class="integration-history__list-item--second">
                        <p>Integration</p>
                    </div>
                </div>
                <div
                    v-for="(item, index) in historyScans.rows"
                    :key="index"
                    class="integration-history__list"
                >
                    <div
                        v-if="checkStatusIntegration(item.status)"
                        class="integration-history__list--wrapper"
                        :class="{'integration-history__list--wrapper-active': isActiveItemId === item.id}"
                        @click="showIntegrationVersions(item.id)"
                    >
                        <div class="integration-history__list-item integration-history__list-item--first">
                            <img
                                :id="item.id"
                                alt=""
                                :src="item.signedUrl ? item.signedUrl : errorLoaded"
                                @error="errorLoaded"
                            >
                            <p>{{ checkAndUpdatePlatformText(item.platform) }}</p>
                        </div>
                        <div
                            class="integration-history__list-item integration-history__list-item--second-small"
                            @click="showIntegrationVersions(item.id)"
                        >
                            <p>{{ item.integrationName }}</p>
                        </div>
                        <div class="integration-history__list-item integration-history__list-item--second">
                            <p :title="item.integrationName">
                                {{ item.integrationName }}
                            </p>
                            <div class="integration-history__list-item--second-block-info">
                                <p
                                    v-if="item.appId"
                                    class="integration-history__list-item--second-block-info-title"
                                    :title="item.appId"
                                >
                                    {{ item.appId }}
                                </p>
                                <p
                                    v-else
                                    class="integration-history__list-item--second-block-info-title"
                                >
                                    -
                                </p>
                                <p
                                    class="integration-history__integ-status"
                                    :class="{
                                        'integration-history__deleted-status': getIntegrationStatus(item.status) === 'Deleted',
                                        'integration-history__expired-status': getIntegrationStatus(item.status) === 'Expired',
                                    }"
                                >
                                    {{ getIntegrationStatus(item.status) }}
                                </p>
                                <img
                                    :class="{'integration-history__list-item--arrow-active': isActiveItemId === item.id}"
                                    class="integration-history__list-item--arrow"
                                    src="../../assets/images/vulnerability-show-version.svg"
                                    alt=""
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        class="integration-history__list--wrapper-disabled"
                        :class="{'integration-history__list--wrapper-active': isActiveItemId === item.id}"
                    >
                        <div class="integration-history__list-item integration-history__list-item--first">
                            <img
                                :id="item.id"
                                :src="item.signedUrl ? item.signedUrl : errorLoaded"
                                alt=""
                                @error="errorLoaded"
                            >
                            <p>{{ checkAndUpdatePlatformText(item.platform) }}</p>
                        </div>
                        <div
                            class="integration-history__list-item integration-history__list-item--second-small"
                            @click="showIntegrationVersions(item.id)"
                        >
                            <p>{{ item.integrationName }}</p>
                        </div>
                        <div class="integration-history__list-item integration-history__list-item--second">
                            <p :title="item.integrationName">
                                {{ item.integrationName }}
                            </p>
                            <div class="integration-history__list-item--second-block-info">
                                <p
                                    v-if="item.appId"
                                    class="integration-history__list-item--second-block-info-title"
                                    :title="item.appId"
                                >
                                    {{ item.appId }}
                                </p>
                                <p v-else>
                                    -
                                </p>
                                <p
                                    class="integration-history__integ-status"
                                    :class="{
                                        'integration-history__deleted-status': getIntegrationStatus(item.status) === 'Deleted',
                                        'integration-history__expired-status': getIntegrationStatus(item.status) === 'Expired',
                                    }"
                                >
                                    {{ getIntegrationStatus(item.status) }}
                                </p>
                                <img
                                    :class="{'integration-history__list-item--arrow-active': isActiveItemId === item.id}"
                                    class="integration-history__list-item--arrow"
                                    src="../../assets/images/vulnerability-show-version.svg"
                                    alt=""
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination
                    class="integration-history__pagination"
                    :count="historyScans.count"
                    :limit="limit"
                    :current-page="currentPage"
                    :show-spinner="showSpinner"
                    @pageChanged="updatePage"
                />
            </div>
            <div
                v-if="windowWidth < mediaWidthForCheckScreen"
                class="integration-history__block-first-mobile"
            >
                <div class="integration-history__header">
                    <div class="integration-history__list-item--first" />
                    <div class="integration-history__list-item--second">
                        <p>App name</p>
                    </div>
                    <div class="integration-history__list-item--third">
                        <p>Status</p>
                    </div>
                </div>
                <div
                    v-for="(item, index) in historyScans.rows"
                    :key="index"
                    class="integration-history__list"
                >
                    <router-link
                        v-if="checkStatusIntegration(item.status)"
                        class="integration-history__mobilel-version-link"
                        :to="{ path: `${makeLinkToAnchorSettings(`integration/${item.id}`)}`}"
                    >
                        <div class="integration-history__list--wrapper">
                            <div class="integration-history__list-item integration-history__list-item--first">
                                <img
                                    :id="item.id"
                                    :src="item.signedUrl ? item.signedUrl : errorLoaded"
                                    alt=""
                                    @error="errorLoaded"
                                >
                                <p>{{ checkAndUpdatePlatformText(item.platform) }}</p>
                            </div>
                            <div class="integration-history__list-item integration-history__list-item--second-small">
                                <p>{{ item.integrationName }}</p>
                            </div>
                            <p
                                class="integration-history__list-item integration-history__list-item--third"
                                :class="{
                                    'integration-history__deleted-status': getIntegrationStatus(item.status) === 'Deleted',
                                    'integration-history__expired-status': getIntegrationStatus(item.status) === 'Expired',
                                }"
                            >
                                {{ getIntegrationStatus(item.status) }}
                            </p>
                        </div>
                    </router-link>
                    <div
                        v-else
                        class="integration-history__list--wrapper integration-history__mobilel-version-link-disabled"
                    >
                        <div class="integration-history__list-item integration-history__list-item--first">
                            <img
                                :id="item.id"
                                :src="item.signedUrl ? item.signedUrl : errorLoaded"
                                alt=""
                                @error="errorLoaded"
                            >
                        </div>
                        <div class="integration-history__list-item integration-history__list-item--second-small">
                            <p>{{ item.integrationName }}</p>
                        </div>
                        <p
                            class="integration-history__list-item integration-history__list-item--third"
                            :class="{
                                'integration-history__deleted-status': getIntegrationStatus(item.status) === 'Deleted',
                                'integration-history__expired-status': getIntegrationStatus(item.status) === 'Expired',
                            }"
                        >
                            {{ getIntegrationStatus(item.status) }}
                        </p>
                    </div>
                </div>
                <Pagination
                    class="integration-history__pagination"
                    :count="historyScans.count"
                    :limit="limit"
                    :current-page="currentPage"
                    :show-spinner="showSpinner"
                    @pageChanged="updatePage"
                />
            </div>
            <div
                v-if="windowWidth > mediaWidthForCheckScreen && checkStatusIntegration(activeIntegrationStatus)"
                class="integration-history__block-second"
            >
                <div class="integration-history__header">
                    <div class=" integration-history__list-item--versions-block">
                        <div class="integration-history__list-item--third">
                            <p>Version</p>
                        </div>
                        <div class="integration-history__list-item--fourth">
                            <p>Scan Completion Time</p>
                        </div>
                        <div class="integration-history__list-item--fifth">
                            <p>Version Status</p>
                        </div>
                    </div>
                </div>
                <div v-if="!checkStatusIntegration(activeIntegrationStatus)" />
                <p
                    v-else-if="checkStatusIntegration(activeIntegrationStatus) && !integrationVersionsList.length"
                    class="empty-versions-message"
                >
                    No version has been uploaded yet
                </p>
                <div
                    v-else
                    id="versionsList"
                    class="integration-history__versions-list--wrapper"
                >
                    <div class="integration-history__versions-list--wrapper-scroll">
                        <div
                            v-for="(version, index) in integrationVersionsList"
                            :key="index"
                            class="integration-history__versions-list"
                        >
                            <div class="integration-history__versions-list--first">
                                <p v-if="version.version">
                                    {{ version.version }}
                                </p>
                                <p v-else>
                                    -
                                </p>
                            </div>
                            <div class="integration-history__versions-list--second">
                                <p
                                    v-if="!isVersionInProgress(version.status) || version.isDeleted"
                                    :style="isLessThenOneMinuteTime(version.completionTime)? 'color: #61D4B3;' : ''"
                                >
                                    {{ createDateView(version.completionTime) }}</p>
                                <p v-else>
                                    -
                                </p>
                            </div>
                            <div class="integration-history__versions-list--third">
                                <p
                                    v-if="integrationVersionsList.length > 0"
                                    :class="{
                                        'integration-history__deleted-status': getVersionStatus(version.status, version.isDeleted) === 'Deleted',
                                        'integration-history__expired-status': getVersionStatus(version.status, version.isDeleted) === 'Failed',
                                    }"
                                >
                                    {{ getVersionStatus(version.status, version.isDeleted) }}
                                </p>
                                <p v-else>
                                    -
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="showSpinner"
            class="integration-history__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { IntegrationStatusEnum, VersionStatusEnum } from '../../utils/constants/common';
import { createDateView, makeLinkToAnchorSettings, checkAndUpdatePlatformText, isLessThenOneMinuteTime } from '../../services/functions';
import Spinner from '../Spinner';
import DefaultLogo from '../../assets/images/default-integration-icon.png';
import Pagination from '../Pagination.vue';

export default {
    components: {
        Spinner,
        Pagination,
    },
    data() {
        return {
            DefaultLogo,
            showAllList: false,
            showSpinner: false,
            currentPage: 1,
            integrationVersionsList: [],
            limit: 5,
            isActiveItemId: null,
            windowWidth: 0,
            mediaWidthForCheckScreen: 768,
            integrationItem: null,
            activeIntegrationStatus: null,
        };
    },
    computed: {
        ...mapGetters('integrations', {
            historyScans: 'historyScans',
        }),
    },
    watch: {
        async currentPage() {
            this.showSpinner = true;
            await this.$store.dispatch('integrations/loadIntegrationHistoryScans', {
                limit: this.limit,
                page: this.currentPage,
            });
            this.showIntegrationVersions(this.historyScans.rows[0].id);
            this.showSpinner = false;
            setTimeout(() => document.getElementById('integrationHistory').scrollIntoView(), 0);
        },
    },
    async beforeMount() {
        this.showSpinner = true;
        await this.$store.dispatch('integrations/loadIntegrationHistoryScans', {
            limit: this.limit,
            page: this.currentPage,
        });
        this.showIntegrationVersions(this.historyScans.rows[0].id);
        this.windowWidth = window.innerWidth;
        this.showSpinner = false;
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        createDateView,
        isLessThenOneMinuteTime,
        makeLinkToAnchorSettings,
        checkAndUpdatePlatformText,
        errorLoaded(event) {
            let img = document.getElementById(`${event.target.id}`);
            img.src = this.DefaultLogo;
        },
        updatePage(page) {
            this.currentPage = page;
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        showIntegrationVersions(integrationId) {
            const versionsArray = this.historyScans.rows.filter(item => item.id === integrationId)[0].versions;
            const statusOfActiveIntegration = this.historyScans.rows.filter(item => item.id === integrationId)[0].status;
            this.activeIntegrationStatus = statusOfActiveIntegration;
            this.integrationVersionsList = versionsArray;
            if (this.checkStatusIntegration(this.activeIntegrationStatus)) {
                this.isActiveItemId = integrationId;
            }
        },
        setIntegration(integration) {
            this.integrationItem = integration;
        },
        checkStatusIntegration(status) {
            return status === 'ACTIVE' || status === 'EXPIRED' || status === 'SAMPLE' || status === 'RENEWAL_REQUESTED';
        },
        getVersionStatus(status, isDeleted) {
            if (isDeleted) {
                return 'Deleted';
            }
            switch (status) {
                case VersionStatusEnum.IN_QUEUE:
                    return 'In queue';
                case VersionStatusEnum.CREATED:
                    return 'Created';
                case VersionStatusEnum.SCANNING:
                    return 'Scanning';
                case VersionStatusEnum.FINISHED:
                    return 'Finished';
                case VersionStatusEnum.ERROR:
                    return 'Failed';
                default:
                    return '';
            }
        },
        isVersionInProgress(status) {
            return status === VersionStatusEnum.CREATED || status === VersionStatusEnum.IN_QUEUE || status === VersionStatusEnum.SCANNING;
        } ,
        getIntegrationStatus(status) {
            switch (status) {
                case IntegrationStatusEnum.ACTIVE:
                    return 'Active';
                case IntegrationStatusEnum.CREATED:
                    return 'Created';
                case IntegrationStatusEnum.EXPIRED:
                    return 'Expired';
                case IntegrationStatusEnum.REJECTED:
                    return 'Rejected';
                case IntegrationStatusEnum.UNDER_REVIEW:
                    return 'Under Review';
                case IntegrationStatusEnum.RENEWAL_REQUESTED:
                    return 'Renewal Requested';
                case IntegrationStatusEnum.DELETED:
                    return 'Deleted';
                case IntegrationStatusEnum.SAMPLE:
                    return 'Sample';
                case IntegrationStatusEnum.TRIAL_REQUESTED:
                    return 'Trial Requested';
                default:
                    return '';
            }
        },
    },
};
</script>
<style lang="scss">
.integration-history {
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 20px;

    &__header {
        height: 82px;
    }

    &__wrapper {
        display: flex;
    }

    &__sign {
        font-size: 18px;
        line-height: 150%;
    }

    &__count {
        font-size: 18px;
        line-height: 150%;
        margin-left: 10px;
        color: #B2B9C4;
    }

    &__wrapper-background {
        position: relative;
    }

    &__container {
        display: flex;
        margin: 0 -15px;
    }

    &__header {
        display: flex;
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom: 1px solid #F0F0F0;

        div {
            border-top: none;

            p {
                color: #B2B9C4;
                font-size: 14px;
            }
        }
    }

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: rgba(255,255,255,0.7);
        z-index: 1000;
    }

    &__integ-status {
        padding: 0;
        margin: 0;
        width: 100px;
        white-space: nowrap !important;
        text-align: center;
        font-size: 14px;
        line-height: 1.4;
    }

    &__deleted-status {
        padding: 0;
        margin: 0;
        color: #B2B9C4;
    }

    &__expired-status {
        color: #FB8D62;
    }

    &__block-first {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 52%;
        min-height: 650px;
        height: 100%;
        border-radius: 20px;
        box-shadow: 4px 0px 15px rgba(56, 70, 77, 0.04);
        z-index: 20;
    }

    &__block-second {
        width: 100%;
        max-width: 48%;
        height: 100%;
        background-color: white;
    }

    &__list {
        width: 100%;
        background-color: white;

        &--wrapper {
            display: flex;
            width: 100%;

            &:hover {
                cursor: pointer;
                box-shadow: 0px 4px 15px rgba(56, 70, 77, 0.2);
                .integration-history__list-item--arrow {
                    opacity: 1;
                }
            }
        }

        &--wrapper-disabled {
            display: flex;
            width: 100%;
        }

        &--wrapper-active {
            z-index: 25;
            box-shadow: 0px 4px 15px rgba(56, 70, 77, 0.2);
        }
    }

    &__versions-list {
        display: flex;

        &--second {
            color: #B2B9C4;
        }

        &--first,
        &--second,
        &--third {
            font-size: 14px;
            line-height: 140%;
            width: 100%;
            padding: 18px 0;
            border-bottom: 1px solid #F0F0F0;

            p {
                word-break: break-word;
                margin: 0;
                text-align: center;
            }
        }
    }

    &__versions-list--wrapper {
        position: relative;
        width: 100%;
        height: 555px;
        padding: 0 18px;
        overflow: hidden;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: 5px 5px 5px -5px rgba(126, 126, 126, 0.685) inset;
            background-color: #f9f9fd;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #b2b9c48a;
            background-image: -webkit-gradient(linear, 0 0, 0 100%,
                                color-stop(.5, rgba(255, 255, 255, 0.185)),
                                color-stop(.5, transparent), to(transparent));
        }
    }

    &__versions-list--wrapper-scroll {
        width: 92%;
        position: absolute;
    }

    &__list-item {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid #F0F0F0;

        &--versions-block {
            display: flex;
            width: 100%;
            padding: 0 18px;
        }

        &--first {
            max-width: 78px;
            width: 100%;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {

                width: 25px;
                height: 25px;
                border-radius: 5px;
            }

            p {
                font-weight: 500;
                font-size: 10px;
                line-height: 140%;
                color: #3180F6;
                margin-top: 9px;
                margin-bottom: 0;
            }
        }

        &--second {
            width: 100%;
            justify-content: flex-start;
            flex-direction: column;
            padding: 20px 20px 20px 0;
            box-sizing: border-box;
            box-shadow: 0px 4px 15px rgba(56, 70, 77, 0.04);

            p {
                word-break: break-word;
                margin: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 300px;
            }
        }

        &--second-block-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
        }

        &--second-block-info-title {
            width: 160px;
            font-size: 14px;
            line-height: 1.4;
            color: #706969;
        }

        &--second-small {
            display: none;
            padding: 20px 0;
        }

        &--third,
        &--fourth,
        &--fifth {
            padding: 20px 0;
            width: 100%;

            p {
                word-break: break-word;
                margin: 0;
                text-align: center;
            }
        }

        &--arrow {

            height: 15px;
            width: 15px;
            margin-top: -30px;
            opacity: 0;
        }

        &--arrow-active {
            opacity: 1;
        }
    }

    &__pagination {
        padding: 20px 0 20px 10px;
    }
}

.empty-versions-message {
    text-align: center;
    margin-top: 15px;
}

@media (max-width: 1200px) {
    .integration-history {
        width: 750px;

        &__list-item {

            &--fourth {
                min-width: 140px;
            }
        }
    }
}

@media (max-width: 768px) {
    .integration-history {
        width: 100%;
        font-size: 18px;
        line-height: 140%;

        &__header {
            height: 60px;
        }

        &__wrapper {
            display: flex;
        }

        &__sign {
            font-size: 18px;
            line-height: 150%;
            margin-left: 20px;
        }

        &__container {
            margin: 0;
        }

        &__count {
            font-size: 18px;
            line-height: 150%;
            margin-left: 10px;
            color: #B2B9C4;
        }

        &__block-first-mobile {
            width: 100%;
        }

            &__mobilel-version-link {
            text-decoration: none;
            cursor: pointer;
            color: unset;
            width: 100%;
            height: 100%;

            &:hover {
                color: initial;
                text-decoration: none;
            }
        }

        &__mobilel-version-link-disabled {
            color: #B2B9C4;
        }

        &__header {
            div {
                box-shadow: unset;
                p {
                    color: #242424;
                    font-size: 18px;
                }
            }

            & :last-child {
                p {
                    justify-content: flex-end;
                }
            }
        }

        &__list-item {
            display: flex;
            justify-content: center;
            padding: 20px 0;

            &--first {
                text-align: center;
                padding: 8px 0;

                p {
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 140%;
                    margin-top: 9px;
                    margin-bottom: 0;
                }
            }

            &--second-small {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                padding-left: 20px;
                padding-right: 5px;
                box-sizing: border-box;
                box-shadow: unset;

                p {
                    word-break: break-word;
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 320px;
                }
            }

            &--third {
                width: 100%;
                max-width: 110px;
                font-size: 14px;
                text-align: center;
                word-break: break-word;
                margin: 0;
                padding-right: 20px;
            }

            &--second {
                padding-left: 20px;
            }
        }

        &__pagination {
            padding: 20px 0;
            justify-content: space-between;
        }
    }
}

@media (max-width: 530px) {
    .integration-history__list-item--second-small{
        p {
            max-width: 230px;
        }
    }
}


@media (max-width: 430px) {
    .integration-history__list-item--second-small{
        p {
            max-width: 160px;
        }
    }

    .integration-history__list-item--third {
        padding-right: 0;
    }
}
</style>
