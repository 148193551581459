<template>
    <div class="options__wrapper">
        <div v-if="prepaidAmount" class="options__prepaid-scans">
            Available Scans
            <label
                class="options__prepaid-scans-check"
                for="prepaid"
            >
                <div class="options__prepaid-scans-check-left">
                    <div>{{prepaidAmount}} left</div>
                    <input
                        type="checkbox"
                        id="prepaid"
                        name="prepaid"
                        v-model="usePrepaid"
                    >
                    <span class="checkmark"></span>
                </div>
                <div class="options__prepaid-scans-check-text">
                    Use Prepaid Scans
                </div>
            </label>
        </div>
        <div class="options__scan-packs">
            Buy Scan Packs
            <scan-pack-cards
                :packs="scanPacks"
                :selectedPack="selectedPack"
                @pack="getPackSelected"
            />
        </div>
        <div class="options__footer">
            Scan Packs offer a discounted rate. Scans from Scan Pack will be immediately used and any remaining Scans will be credited to your account as Prepaid Scans. If the total amount of Scans (including Trial, Prepaid Scans and Scans bought with Scan Pack) is insufficient, individual Scan Packs will be purchased at the regular price to complete the transaction.
        </div>
    </div>
</template>

<script>
import ScanPackCards from './ScanPackCards.vue';

export default {
    name: 'ScanOptions',
    components: { ScanPackCards },
    props: ['prepaidAmount', 'prepaidSelect', 'scanPacks', 'selectedPack'],
    data() {
        return {
            usePrepaid: this.prepaidSelect,
        }
    },
    watch: {
        usePrepaid(newValue) {
            this.$emit('use-prepaid', newValue)
        },
        prepaidSelect(newValue) {
            this.usePrepaid = newValue
        },
    },
    methods: {
        getPackSelected(pack) {
            this.$emit('pack-selected', pack)
        },
    }
}
</script>

<style lang="scss">
@import "@/assets/css/variables";

.options {
    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;
        font-size: 22px;
        font-weight: 650;
        color: $main-text;
    }
    &__prepaid-scans {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-check {
            border: 2px solid $border-orange-color;
            padding: 20px 30px 20px 40px;
            border-radius: 20px;
            font-weight: 650;
            font-size: 22px;
            transition: all .3s;
            width: 320px;
            input {
                position: absolute;
                opacity: 0;
            }
            &-left {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 34px;
                margin-bottom: 20px;
                .checkmark {
                    @include checkbox-square("orange");
                }
            }
            &:has(input:checked) {
                border-color: $new-orange-color;
                box-shadow: $box-shadow-orange;
                &:hover {
                    border-color: $border-orange-color;
                }
            }
            &:hover {
                cursor: pointer;
                border: 2px solid $new-orange-color;
                box-shadow: $box-shadow-orange;
                .checkmark {
                    @include checkbox-square-hover("orange");
                }
                & input:checked ~ .checkmark {
                    @include checkbox-square-checked-hover("orange");
                }
            }

            & input:checked ~ .checkmark:after {
                display: block;
            }
        }
    }
    &__footer {
        color: $secondary-text;
        font-size: 14px;
        font-weight: 400;
    }
}

@media (max-width: 1500px) {
    .options {
        &__wrapper {
            gap: 20px;
        }
    }
}
@media (max-width: 505px) {
    .options {
        &__prepaid-scans {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            &-check {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                font-size: 17px;
                width: 100%;
                position: relative;
                &-text {
                    margin-left: 30px;
                }
                &-left {
                    position: static;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    font-size: 22px;
                    padding: 0;
                    margin: 0;
                    .checkmark {
                        position: absolute;
                        left: 20px;
                        height: 20px;
                        width: 20px;
                        border-radius: 5px;
                    }
                }
                & .checkmark:after {
                    height: 14px;
                    width: 14px;
                    border-radius: 3px;
                }
            }
        }
    }
}
</style>
