import Vue from 'vue'

export const state = () => ({
    cards: null
})

export const getters = {
    cards: s => s.cards
}

export const mutations = {
    setCards: (state, cards) => {
        state.cards = cards
    }
}

export const actions = {
    async fetchCards({commit}) {
        let cards = []
        try {
            const {data} = await Vue.axios.get('/private/ui/stripe/cards')
            cards = data.cards
        } catch (e) {
            console.error(e)
        }
        commit('setCards', cards)
    },
    async saveCard({commit, dispatch}, newCard) {
        try {
            const res = await Vue.axios.post('/private/ui/stripe/cards', newCard)
            if (res.status === 200) {
                await dispatch('fetchCards')
                return res
            }
        } catch (err) {
            console.log(err)
            return err.response
        }
    },
    async deleteCard({commit, dispatch}, {id}) {
        const res = await Vue.axios.delete('/private/ui/stripe/delete', {
            params: {id}
        })
        if (res.status === 200) {
            await dispatch('fetchCards')
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
