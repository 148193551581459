<template>
    <div class="partner-success-stories">
        <div class="partner-success-stories__content">
            <div
                class="title-block"
            >
                <div class="text-block">
                    <span class="text-block__main">{{ mainText }}</span>
                    <span class="text-block__second">{{ secondText }}</span>
                </div>
            </div>
            <div class="partner-carousel">
                <carousel
                    ref="partnerCarousel"
                    :per-page="perPageValue"
                    :mouse-drag="true"
                    :navigation-enabled="!isMobile"
                    :pagination-enabled="isMobile"
                    :pagination-color="'#727D8D80'"
                >
                    <slide
                        v-for="(card, index) in cards"
                        :key="index"
                        class="slides"
                        :data-index="index"
                    >
                        <StoryCard
                            :author="card.author"
                            :title="card.title"
                            :text="card.text"
                        />
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>
<script>

import StoryCard from './StoryCard.vue';
export default {
    components: {
        StoryCard,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            isNavButtonClicked: false,
            mainText: 'Partner Success Stories',
            secondText: 'Hear What Our Partners Have to Say',
            cards:[
                {
                    author: 'Amara Patel',
                    title: ' Their support is exceptional',
                    text: `Partnering with Oversecured has been
                    a game-changer for us. Their support is exceptional, and we look forward to a long-lasting partnership`,
                },
                {
                    author: 'Alexandre Dubois',
                    title: ' It\'s been a pleasure working together',
                    text: `Collaborating on co-marketing campaigns with Oversecured
                    has expanded our market reach, and it's been a pleasure working together`,

                },
                {
                    author: 'Yusuf Khan',
                    title: ' Loyalty of our existing customers',
                    text: `Partnering with Oversecured has not only brought us new clients
                    but has also enhanced the loyalty of our existing customers`,
                },
                {
                    author: 'Sofia Hernandez',
                    title: ' Cybersecurity expertise is unparalleled',
                    text: 'The cybersecurity expertise offered by Oversecured is unparalleled',
                },
            ],
        };
    },
    computed: {
        isMobile() {
            return this.windowWidth < 768;
        },
        perPageValue() {
            return this.windowWidth >= 1335
                ? 2
                : this.windowWidth < 1335 && this.windowWidth > 768
                    ? 1.35
                    : 1;
        },

    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.$refs.partnerCarousel.$el
            .querySelector('.VueCarousel-inner')
            .addEventListener('transitionend', this.handleTransitionEnd);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
};

</script>
<style lang="scss" >
@import "../../../assets/css/variables.scss";
@import "../../../assets/css/variables/variablesForPartnerSuccessStories.scss";
@import "../../../assets/css/fonts";

.partner-success-stories {
    width: 100vw;
    background-color: var(--bg-color-2, #F6F6F9);
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;

    &__content {
        display: flex;
        flex-direction: column;
        margin: 8% 0;
    }
}


.title-block {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 $dynamic-title-block-margin-l-r;
}

.text-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 80px;

    &__main {
        @include Aspekta-font(700, $dynamic-text-block-main-font-size, normal, $general-black);
        line-height: 120%; /* 72px */
    }

    &__second {
        @include Aspekta-font(400, $dynamic-text-block-second-font-size, normal, #797E86);
    }
}


.partner-carousel {
    margin: 0 $dynamic-title-block-margin-l-r;
    width: 70%;

    .VueCarousel-slide {
        flex-basis: auto;
        width: $dynamic-VueCarousel-width;
    }
    .VueCarousel-inner {
        width: $dynamic-VueCarousel-width;
    }

    .VueCarousel-navigation {
        position: absolute;
        top: 40%;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .VueCarousel-navigation-prev, .VueCarousel-navigation-next {
        background-size: contain;
        background-repeat: no-repeat;
        text-indent: -9999px;
        height: 64px;
        width: 64px;
        transform: none;
    }

    .VueCarousel-navigation-prev:hover, .VueCarousel-navigation-next:hover {
        opacity: 0.7;
    }

    .VueCarousel-navigation-prev {
        position: relative;
        background-image: url('../../../assets/images/main/main-slider-arrow-left.svg');
        left: -10% !important;
    }

    .VueCarousel-navigation-prev.VueCarousel-navigation--disabled {
        background-image: url('../../../assets/images/main/main-slider-arrow-left-disable.svg');
        opacity: 1;
    }
    .VueCarousel-navigation-next.VueCarousel-navigation--disabled {
      background-image: url('../../../assets/images/main/main-slider-arrow-right-disable.svg');
      opacity: 1;
    }

    .VueCarousel-navigation-next {
        position: relative;
        background-image: url('../../../assets/images/main/main-slider-arrow-right.svg');
        right: -10% !important;
    }
}

@media (max-width: 1335px ) {

    .partner-carousel {
        .VueCarousel-slide {
            flex-basis: auto;
            width: $dynamic-middle-VueCarousel-width;
        }
        .VueCarousel-inner {
            width: $dynamic-middle-VueCarousel-width;
        }
        .VueCarousel-navigation-next {
            right: -5% !important;
        }
        .VueCarousel-navigation-prev {
            left: -5% !important;
        }
    }

}

@media (max-width: 768px ) {

    .partner-carousel {
        .VueCarousel-slide {
            width: $dynamic-mobile-VueCarousel-width;
        }
        .VueCarousel-inner {
            width: $dynamic-mobile-VueCarousel-width;
        }
    }

    .title-block {
        margin: 0 20px;
    }

    .text-block {

        &__main {
            line-height: 130%; /* 72px */
            font-size: 32px;
        }

        &__second {
            font-size: 14px;
        }
    }
 }

</style>
