<template>
    <svg
        width="190"
        height="11"
        viewBox="0 0 237 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 1.50391L235.517 4.9942L26.0342 9.39619"
            stroke="#FF7A08"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
<script>
export default {
    name: 'OrangeLine',
    props: {
        height: {
            type: Number,
            require: true,
            default: 190,
        },
        width: {
            type: Number,
            require: true,
            default: 11,
        },
    },
};
</script>