<template>
    <div
        :class="{
            menu_with_border: this.$route.name === `Version scan` || this.$route.name === `ScanInfo`,
            'new-hover-fixed': isRedesignedPage, headerLinkIsOpen: isOpenMenu,
        }"
    >
        <MfaNotification
            v-if="isAuthenticated && isZeroMfaDevicesAssigned"
            :is-user-auth="isAuthenticated"
            @toggleNotification="toggleMfaNotification"
        />
        <div class="header">
            <router-link
                to="/"
                class="header-brand"
            >
                <img
                    class="header-brand-logo"
                    src="../assets/images/logo_brand_text.svg"
                    alt="brand"
                >
            </router-link>

            <div class="header__nav">
                <router-link
                    v-if="isAuthPage"
                    class="link-back"
                    to="/"
                >
                    Return to Homepage
                </router-link>
                <div v-else>
                    <NewOuterHeader
                        v-if="!isAuthenticated"
                        :is-authenticated="isAuthenticated"
                    />
                    <InnerHeader v-if="isAuthenticated" />
                </div>
            </div>
            <div
                id="menu"
                :class="{
                    header__linksOpen: isOpenMenu,
                    header__linksOpen__main_page: isOpenMenu && isRedesignedPage,
                    header__linksClose: !isOpenMenu,
                    header__linksOpen__with_notification: isMfaNotification,
                }"
            >
                <b-nav-item
                    v-if="isAuthPage"
                    class="link-back"
                    to="/"
                >
                    Return to Homepage
                </b-nav-item>
                <div v-else>
                    <NewOuterHeader
                        v-if="!isAuthenticated"
                        :is-authenticated="isAuthenticated"
                    />
                    <InnerHeader v-if="isAuthenticated" />
                </div>
            </div>
            <div
                class="header__burger-menu"
                @click="toggleMenu()"
            >
                <div :class="{ header__burgerItem: !isOpenMenu, header__burgerItemOpen: isOpenMenu }" />
                <div :class="{ header__burgerItem: !isOpenMenu, header__burgerItemOpen: isOpenMenu }" />
                <div :class="{ header__burgerItem: !isOpenMenu, header__burgerItemOpen: isOpenMenu }" />
            </div>
        </div>
    </div>
</template>
<script>
import InnerHeader from './headers/InnerHeader';
import NewOuterHeader from './headers/NewOuterHeader.vue';
import { adjustHeaderAndFooterWidths, adjustWidths } from '../services/functions';
import MfaNotification from './MfaNotification';
import { mapGetters } from 'vuex';
import { store } from '@/store';
export default {
    components: {
        InnerHeader,
        MfaNotification,
        NewOuterHeader,
    },
    data() {
        return {
            get showMessageIfNoIntegrations() {
                return +localStorage.getItem('integrationsCount') === 0;
            },
            isOpenMenu: false,
            route: document.location.pathname,
            authPages: [
                /\/sign-up/,
                /\/sign-in/,
                /\/forgot-password/,
                /\/reset-password/,
                /\/confirm-email/,
            ],
            isAuthPage: false,
            debouncedWidth: 0,
            widthTimeout: null,
        };
    },
    computed: {
        isAuthenticated: {
            get() {
                return this.$auth.isAuthenticated;
            },
        },
        isRedesignedPage() {
            return this.$route.path === '/' ||
                this.$route.path === '/partner' ||
                this.$route.path === '/request-demo';
        },
        ...mapGetters({
            trial: 'user/getTrial',
            isTrialNotification: 'user/isTrialNotification',
            isMfaNotification: 'user/isMfaNotification',
            isZeroMfaDevicesAssigned: 'mfaDevices/isZeroMfaDevicesAssigned',
        }),
        width: {
            get() {
                return  this.debouncedWidth;
            },
            set(val) {
                if (this.widthTimeout) clearTimeout(this.widthTimeout);
                this.widthTimeout =  setTimeout(()  =>  {
                    this.debouncedWidth = val;
                },  0);
            },
        },
    },
    watch: {
        $route() {
            this.isOpenMenu = false;
            document.body.style.overflow = 'visible';
            this.isAuthPage = this.defineAuthPages();
            this.adjustHeaderAndFooterWidths(this.width, this.$route.name);
            this.adjustWidths(this.width, this.$route.name);
        },
        isAuthenticated() {
            if (this.isAuthenticated) {
                this.$store.dispatch('mfaDevices/fetchMfaDevices');
                this.$store.dispatch('user/fetchUserInfo');
                store.commit('user/setTrialNotification', false);
            } else {
                store.commit('user/setTrialNotification', true);
            }
        },
        // watch the innerWidth change and ajust
        width() {
            this.width = window.innerWidth;
            this.adjustHeaderAndFooterWidths(this.width, this.$route.name);
        },
    },
    async beforeMount() {
        if (window.innerWidth) {
            this.width = window.innerWidth;
        }
    },
    created() {
        window.addEventListener('resize', this.resizeHandler);
    },
    destroyed() {
        window.removeEventListener('resize', this.resizeHandler);
    },
    methods: {
        async toggleMenu() {
            const menu = document.getElementById('menu');
            if (this.isOpenMenu === false) {
                menu.style.animation = 'menu-open 0.5s forwards';
                document.body.style.overflow = 'hidden';
            } else {
                menu.style.animation = 'menu-close 0.5s forwards';
                await new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 400);
                });
                document.body.style.overflow = 'visible';
            }
            this.isOpenMenu = !this.isOpenMenu;
        },
        defineAuthPages() {
            const path = document.location.pathname;
            const isAuthPage = this.authPages.find(el => el.test(path));

            return !!isAuthPage;
        },
        toggleTrialNotification(value) {
            store.commit('user/setTrialNotification', value);
        },
        toggleMfaNotification(value) {
            store.commit('user/setMfaNotification', value);
        },
        resizeHandler() {
            this.width = window.innerWidth;
        },
        adjustHeaderAndFooterWidths,
        adjustWidths,
    },
};
</script>
<style lang="scss" >
@import "../assets/css/fonts";
@import "../assets/css/variables";

.new-hover-fixed {
    background-color: rgba(246, 246, 249, 0.80);
    backdrop-filter: blur(20px);
    z-index: 1040;
    position: fixed;
    width: 100%;
    padding: 13px 0;

}
.headerLinkIsOpen {
    background-color: rgba(246, 246, 249, 1);
}

.menu_with_border {
    border-bottom: 1px solid #F0F1F3;
}

.header {
    height: #{$header_height};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    .header-brand {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &-logo {
            width: $dynamic-header-brand-width;
        }
    }

    .header-menu {
        display: flex;
        align-items: center;
        width: 100%;
        column-gap: 20px;
        margin: auto;

        .menu-button {

            &--login,
            .nav-link {
                font-size: 14px;
                line-height: 17px;
                color: #242424 !important;
                margin: 0 !important;
                cursor: pointer;
            }
        }

        button {
            &.login-button {
                @include Aspekta-font(600, 17px, normal, #FF7A08);
                display: flex;
                justify-content: center;
                width: 160px;
                border: 2px solid #FFD2AB;
                border-radius: 8px;
                margin-left: 10px;
                padding: 16px 20px;
                outline: none;

                &:hover {
                    box-shadow: 0px 8px 16px 0px rgba(255, 180, 0, 0.24);
                }
            }
        }

        &-btn {
            @include Aspekta-font(600, 14px, normal, #0C0D0E);
        }
    }

    .card-chip {
        height: 20px;
    }

}

.link-back {
    a {
        font-family: Cabin, serif;
        font-weight: normal;
        font-size: 14px;
        color: #242424;

        &:hover {
            color: #242424;
            text-decoration: underline;
        }
    }
}

.header__nav {
    display: block;

    a.link-back {
        @include Aspekta-font(500, 14px, normal, #3180F6);

        &:hover {
            color: #FF7A08;
        }
    }
}

.header__burger-menu {
    display: none;
}

.header__linksClose,
.header__linksOpen {
   display: none;
}


@media (max-width: 834px) {

    .new-hover-fixed {
        padding: 0;
    }

    .header-brand-logo {
        width: #{$max-header-brand-width};
    }
    .header-brand {
        li.nav-item {
            a.nav-link {
                margin: 0;
            }
        }
    }

    .header {

        .header-menu {
            flex-direction: column;
            .menu-button {

                &--login,
                .nav-link {
                    font-size: 24px;
                    line-height: 17px;
                    color: #242424 !important;
                    margin-bottom: 25px !important;
                    cursor: pointer;
                }
            }
        }
    }

    .header__linksOpen {
        position: absolute;
        top: #{$header_height};
        left: 0;
        backdrop-filter: blur(50px);
        background: rgba(246, 246, 249, 1);
         backdrop-filter: blur(50px);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1044;

        &__main_page {
           position: fixed;
           height: 640px;

        }
        /**
        *   If we have Trial Notification on Top of the Header, we should
        *   add the height of the notification to the height of the header
        *    and adjust the top position of the menu
        */
        &__with_notification {
            top: 118.59px;
        }
    }


    .header__nav {
        display: none;
    }

    .header__burger-menu {
        display: block;
        width: 34px;
        height: 34px;
        cursor: pointer;
        margin-top: 10px;
    }

    .header__burgerItem,
    .header__burgerItemOpen {
        height: 2px;
        width: 100%;
        background-color: black;
        transition: 0.3s;
    }

    .header__burgerItem:nth-child(2),
    .header__burgerItem:nth-child(3) {
        margin-top: 10px;
    }

    .header__burgerItemOpen:nth-child(1) {
        transform-origin: center;
        transform: rotate(-45deg);
        position: relative;
        top: 2px;
        margin-top: 10px;
    }

    .header__burgerItemOpen:nth-child(3) {
        transform-origin: center;
        transform: rotate(45deg);
    }

    .header__burgerItemOpen:nth-child(2) {
        display: none;
    }
}

@keyframes menu-open {
    0% {
        height: 0;
        opacity: 0;
    }

    100% {
        opacity: 1;
        height: 100vh;//640px;
    }
}
@keyframes menu-close {
    0% {
        opacity: 1;
        height: 100vh;//640px;
    }

    100% {
        opacity: 0;
        height: 0;
    }
}


@media (max-width: 576px) {

    .header {
        padding: 0 20px;
    }
}

</style>
