<template>
    <div class="markdown">
        <p >To start using Integration quickly or testing it on Oversecured, complete the
            following steps:</p>
        <ol>
            <li>
                <b-nav-item :to="{ path: `/sign-up/company`}" class="md__link-wrapper">
                    Create
                </b-nav-item>
                a company account.
            </li>
            <li>
                <b-nav-item :to="{ path: `/integrations/create`}" class="md__link-wrapper">
                    Submit an application
                </b-nav-item>
                , and check <code>Request 2-week trial access</code> to Integration.
            </li>
            <li>Shortly after, we'll activate a two-week Integration demo.</li>
        </ol>
    </div>
</template>

<script>
import {makeAnchor, makeLinkToAnchor, makeLinkToAnchorCICD} from "../../../services/functions";
import {makeSubLinkToAnchor} from "@/services/functions";

export default {
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor,
        makeLinkToAnchorCICD,
    }
}
</script>

<style scoped>

</style>
