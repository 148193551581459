<template>
    <div class="activation-buttons">
        <button
            class="activation-cancel"
            @click.prevent="cancel"
        >
            Cancel
        </button>
        <button
            class="activation-confirm"
            :disabled="disableConfirmButton"
            @click.prevent="confirm"
        >
            Confirm
        </button>
    </div>
</template>

<script>
export default {
    name: 'ActivationCodeButtons',
    props: {
        disableConfirmButton: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        confirm() {
            this.$emit('confirm');
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/css/variables";

.activation {
    &-buttons {
        display: flex;
        gap: 10px;
        margin-top: 30px;
    }
    &-cancel, &-confirm {
        width: 100%;
        border-radius: 8px;
        font-size: 22px;
        font-weight: 600;
        padding: 18px 28px;
        transition: all .3s ease-in;
    }
    &-confirm {
        background-color: $main-text;
        color: #fff;
        border: 1px solid $main-text;
        &:hover {
            border: 1px solid $main-text;
            background-color: #fff;
            color: $main-text;
            box-shadow: 0px 12px 16px rgba(36, 36, 36, 0.12);
        }
        &:disabled {
            background-color: $background-disabled;
            color: $disabled-text;
            border: none;
            box-shadow: none;
        }
    }
    &-cancel {
        background-color: $background-ghost;
        color: $ghost-text;
        border: 1px solid $background-ghost;
        &:hover {
            border: 1px solid $ghost-border;
            background-color: #fff;
            box-shadow: 0px 12px 16px rgba(178, 185, 196, 0.12);
        }
    }
}

@media (max-width: 1500px) {
    .activation {
        &-buttons {
            display: flex;
            gap: 10px;
            margin-top: 30px;
        }
        &-cancel, &-confirm {
            border-radius: 8px;
            font-size: 18px;
            font-weight: 600;
            padding: 16px 28px;
        }
    }
}
</style>
