<template>
    <div
        v-click-outside="hide"
        class="dropdown-element"
        :class="{ _open : sharedState.active}"
        @click="toggle"
    >
        <slot name="toggler">
            <button>Toggle</button>
        </slot>
        <slot />
    </div>
</template>

<script>

import ClickOutside from 'vue-click-outside';
export default {
    name: 'AppDropdown',
    directives: {
        ClickOutside,
    },
    provide() {
        return {
            sharedState: this.sharedState,
        };
    },
    data() {
        return {
            sharedState: {
                active: false,
            },
        };
    },
    methods: {
        toggle() {
            this.sharedState.active = !this.sharedState.active;
            this.$emit('toggle',this.sharedState.active);
        },
        hide () {
            this.sharedState.active  = false;
        },
    },
};
</script>
<style lang="scss">

.dropdown-element{
    width : 50%;
    height : 68px;
    border-radius: 20px ;
    position: relative;
    border : 1px solid #E3E6ED;
}

@media (max-width: 992px) {
    .dropdown-element{
        width : 100%;
    }
}
</style>
