<template>
    <div
        class="point-card"
        :class="cardClass"
    >
        <div class="card-icon">
            <img
                :src="iconSrc"
                :alt="iconAlt"
            >
        </div>
        <div class="card-text">
            <p class="card-title">
                {{ title }}
            </p>
            <p class="card-description">
                {{ description }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        iconSrc: String,
        iconAlt: String,
        title: String,
        description: String,
        cardClass: String,
    },
    
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/variables";
@import "../../../assets/css/fonts";

.point-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}

.card-text {
    margin: 10% 10% 10% 0%;
    height: 100%;
}

.card-description {
    @include Aspekta-font(400, $dynamic-main-top-sub-title-font-size, normal, #797E86);
    letter-spacing: 0em;
    text-align: justify;
}

.card-title {
    @include Aspekta-font(700, $dynamic-truly-card-title-font-size, normal, #242424);
    letter-spacing: 0em;
}

.partner-card .card-text {
    margin: 3rem 0rem 0rem 0rem;
}

@media (max-width: 768px) {
    .card-text {
        margin: 0%;
        display: flex;
        flex-direction: column;
    }

    .card-title {
        margin-top: 1.5rem;
        margin-bottom: 1.3rem;
    }

    .card-description {
        text-align: justify;
    }
}
</style>
