<template>
    <div class="markdown">
        <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('jenkins')}`}" class="md__link-wrapper">
            <h2 class="md__item-title">Oversecured plugin for Jenkins</h2>
        </b-nav-item>
        <p>These are the steps you need to follow to add Oversecured to Jenkins:</p>
        <ol>
            <li>Open the Jenkins control panel</li>
            <li>Add the Oversecured Scanner plugin by going to <strong>Manage Jenkins</strong> - <strong>Manage
                Plugins</strong> - <strong>Available</strong> (or by visiting <code>http://ip:port/pluginManager/available</code>)
                and search for <code>Oversecured</code></li>
            <li>Select <strong>Install</strong> and restart your Jenkins server</li>
            <li>Add the Oversecured plugin to the pipeline
                <img
                    class="markdown__image"
                    src="../../../assets/images/docs/jenkins_add_step.png"
                    alt="Pipeline">
            </li>
            <li>Enter your <strong>Integration ID</strong>, <strong>Branch name</strong> and <strong>App file</strong> location (APK/AAB file for Android and zipped sources for iOS)
                <img
                    class="markdown__image"
                    src="../../../assets/images/docs/jenkins_step_config.png"
                    alt="Config">
            </li>
            <li>Add the <strong>Oversecured API Key</strong> to Jenkins Credentials
                <img
                    class="markdown__image"
                    src="../../../assets/images/docs/jenkins_credentials.png" alt="Jenkins Credentials">
            </li>
            <li>In the Build Environment tab in project settings, create a secret key value and bind it to the
                variable <code>oversecuredApiKey</code>
                <img
                    class="markdown__image"
                    src="../../../assets/images/docs/jenkins_secret_key_binding.png"
                    alt="Secret key binding">
            </li>
            <li>The workflow is now ready to go!
                <img
                    class="markdown__image"
                    src="../../../assets/images/docs/jenkins_build_output.png" alt="Output">
            </li>
        </ol>
        <p><a href="https://github.com/jenkinsci/oversecured-plugin">Plugin source code</a>.</p>
    </div>
</template>

<script>
import {makeAnchor, makeLinkToAnchor, makeLinkToAnchorCICD} from "../../../services/functions";
import {makeSubLinkToAnchor} from "@/services/functions";

export default {
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor,
        makeLinkToAnchorCICD,
    }
}
</script>

<style scoped>

</style>
