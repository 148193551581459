<template>
    <div>
        <Breadcrumb
            style="padding-top: 25px;"
            :integration-id="integrationId"
            :integration-name="integrationName"
            :current-job="currentJob"
            :branch-name="branchName"
        />

        <div class="report-filename__wrapper">
            <img
                id="integration_logo"
                class="report-filename__icon"
                alt=""
                :src="integration.signedUrl ? integration.signedUrl : DefaultLogo"
                @error="errorLoaded"
            >
            <div class="title-and-btn_block">
                <div class="report-filename__header">
                    {{ integrationName }}
                </div>
                <div class="compare-versions">
                    <Tooltip
                        v-if="isAllowDownloadPDF"
                        position="top"
                        mobile-position="bottom"
                        :tooltip="tooltipTrialMessage"
                    >
                        <button
                            class="compare-versions__download-btn"
                            :disabled="isAllowDownloadPDF"
                            @click.prevent="downloadComparePDFReport"
                        >
                            Download PDF Report
                        </button>
                    </Tooltip>
                    <button
                        v-else
                        class="compare-versions__download-btn"
                        :disabled="isAllowDownloadPDF"
                        @click.prevent="downloadComparePDFReport"
                    >
                        Download PDF Report
                    </button>
                    <Tooltip
                        position="top"
                        mobile-position="left"
                        :tooltip="tooltipCopyText"
                    >
                        <b-nav-item @click="copyLinkSharedJobReportPage(currentId)">
                            <div class="button-action">
                                <img
                                    src="../../assets/images/myScans/copy-link.svg"
                                    alt="icon_action_share"
                                >
                            </div>
                        </b-nav-item>
                    </Tooltip>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '../report/components/Breadcrumb.vue';
import Tooltip from '../report/components/Tooltip.vue';
import DefaultLogo from '../../assets/images/default-integration-icon.png';

export default {
    components: {
        Breadcrumb,
        Tooltip,
    },
    props: ['integrationId', 'screenWidth', 'integration', 'currentJob', 'integrationName', 'currentId', 'branchName'],
    data() {
        return {
            DefaultLogo,
            tooltipCopyText: 'Copy link',
            tooltipTrialMessage:'Please contact our team to unlock the download option',
        };
    },
    computed:{
        isAllowDownloadPDF(){
            return this.integration.trial;
              
        },
        tooltipMessage(){
            return this.isAllowDownloadPDF() ? this.tooltipTrialMessage
                : this.tooltipDownloadText;
        },
    },
    methods: {
        errorLoaded() {
            document.getElementById('integration_logo').src = this.DefaultLogo;
        },
        async downloadComparePDFReport() {
            this.$emit('downloadComparePDFReport', null);
        },
        async copyToClipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
                this.tooltipCopyText = 'Link Copied!';
                setTimeout(() => {
                    this.tooltipCopyText = 'Copy link';
                }, 2000);
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        },
        copyLinkSharedJobReportPage() {
            const link = window.location.href;
            this.copyToClipboard(link);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';


.report-filename {
    &__wrapper {
        display: flex;
        flex-direction: row;
        margin-top: 40px;
    }

    &__icon {
        width: 30px;
        height: 30px;
        margin-top: 8px;
    }

    &__header {
        @include Aspekta-font(700, 30px, 54px, #021631);
        letter-spacing: 1.44px;
        word-break: break-all;
        margin-left: 20px;
        width: 56%;
    }
}

.nav-link:hover {
    .copy-link-btn-title {
        width: 50px;
        visibility: visible;
        white-space: nowrap;
        color: black;
    }
}
.nav-link{
    margin : 0 ;
}

.title-and-btn_block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.compare-versions {
    display: flex;
    margin-left: auto;

    &__download-btn {
        @include Aspekta-font(600, 17px, 24px, $main-blue-color);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: $background-blue-color;
        border: 1px solid $background-blue-color;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px 40px;
        outline: none;
        height: 53px;
        transition: 0.3s;
        margin-right: 20px;

        &:not(:disabled):hover {
            background: $main-blue-color;
            border: 1px solid $main-blue-color;
            box-shadow: 0px 12px 16px rgba(51, 128, 244, 0.12);
            color: $background-blue-color;
        }
        &:disabled{
            background-color: #fff;
            color: $ghost-text;
            border: none;
            box-shadow: none;
        }
    }

    .button-action {
        width: 53px;
        height: 53px;
        background: #fff;
        border: 1px solid #E3E6ED;
        box-sizing: border-box;
        border-radius: 8px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;

        img {
            width: 28px;
            height: 28px;
        }

        &:hover {
            background: #FFFFFF;
            border: 1px solid #E3E6ED;
            box-shadow: 0px 12px 16px rgba(178, 185, 196, 0.12);
        }
    }
}


@media (max-width: 1000px) {
    .report-filename {
        font-size: 20px;
        width: 240px;
        margin-top: 10px;

        &__header {
            width: 90%;
            margin-bottom: 20px;
        }
    }

    .main-breadcrumb__back-point {
        display: block;
        cursor: pointer;
        font-size :14px;
    }

    .title-and-btn_block {
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .compare-versions {
        justify-content: center;
        margin: 0;

        &__download-btn {
            width: 100%;
            padding: 5px 13px;
            margin: 0 20px 0 -30px;
            z-index: 10;
        }
    }
}
</style>
