<template>
    <div class="payment-history">
        <div class="payment-history__links-wrapper">
            <router-link
                active-class="payment-history__link--active"
                class="payment-history__link"
                :to="{ path: `/settings/payment-history/single-scans`}"
            >
                Single Scans
            </router-link>
            <router-link
                active-class="payment-history__link--active"
                class="payment-history__link"
                :to="{ path: `/settings/payment-history/scan-packs`}"
            >
                Scan Packs
            </router-link>
        </div>
        <router-view />
    </div>
</template>
<script>
export default {
    beforeMount() {
        if (this.$route.params && this.$route.params.to === 'single scans') {
            this.$router.push('/settings/payment-history/single-scans')
        }
    },
};
</script>
<style lang="scss">
.payment-history {
    width: 100%;

    &__links-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__link {
        display: inline-block;
        text-align: center;
        width: 50%;
        box-sizing: border-box;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 29px;
        color: #FFB400;
        padding: 10px;
        border-bottom: 2px solid transparent;
        transition: 0.3s;

        &--active {
            text-decoration: none;
            border-bottom: 2px solid #A1DFEE;
            color: #A1DFEE
        }

        &:hover {
            text-decoration: none;
            border-bottom: 2px solid #FFB400;
            color: #FFB400;
        }
    }
}

@media (max-width: 575px) {
    .payment-history {

        &__links-wrapper {
            overflow: auto;
        }

        &__link {
            min-width: 150px;

            &:hover {
                border-bottom: 2px solid #A1DFEE;
                color: #A1DFEE
            }
        }
    }
}
</style>
