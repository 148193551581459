<template>
    <div class="header-menu">
        <b-nav class="header-menu header-menu__links">
            <b-nav-item
                v-if="userType === 'company'"
                :to="{ path: '/get-started'}"
                class="menu-button"
            >
                <div
                    class="header-menu-btn"
                >
                    Get Started
                </div>
            </b-nav-item>
            <b-nav-item
                :to="{ path: '/integrations'}"
                class="menu-button"
            >
                <div class="header-menu-btn">
                    Integrations
                </div>
            </b-nav-item>
            <b-nav-item
                v-if="userType === 'hacker'"
                :to="{ path: '/scans'}"
                class="menu-button"
            >
                <div class="header-menu-btn">
                    Single Scans
                </div>
            </b-nav-item>
            <b-nav-item
                :to="{ path: `${userType && userType === 'company' ? '/settings/profile-info' : '/settings/scan-history'}`}"
                class="menu-button"
            >
                <div class="header-menu-btn">
                    Settings
                </div>
            </b-nav-item>
        </b-nav>
        <div v-if="userType === 'hacker'">
            <b-nav-item :to="{ path: '/scan/create'}">
                <button class="login-button">
                    New Scan
                </button>
            </b-nav-item>
        </div>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            userType: 'user/userType',
        }),
    },
};
</script>
<style lang="scss">
@import "../../assets/css/fonts";

.header-menu-btn {
    &:hover {
        text-decoration: underline;
    }
}

.header-menu {
    display: flex;

    &__links {
        margin-top: -15px;
    }

    .nav-link {
        @include Aspekta-font(600, 14px, normal, #0C0D0E);
        letter-spacing: 0.42px;
    }
}

.nav-item {
    list-style: none;
    user-select: none;
}

.login-button {
    @include Aspekta-font(600, 17px, normal, #FF7A08);
    display: flex;
    justify-content: center;
    width: 160px;
    border: 2px solid #FFD2AB;
    border-radius: 8px;
    margin-left: 10px;
    padding: 16px 20px;
    outline: none;

}

.login-button a.nav-link {
    color: #FF7A08;
}

div.header-menu-btn {
    margin-bottom: 0 !important;
}

@media (max-width: 768px) {
    .header-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center !important;

        .nav-link {
            @include Aspekta-font(600, 24px, normal, #0C0D0E);
            margin-bottom: 26px;
        }
    }

    .header-menu-btn {
        margin-bottom: 40px !important;
        color: #242424;
    }

    .login-button {
        margin: 0 !important;
    }


}
</style>
