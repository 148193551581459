<template>
    <div>
        <PageForbidden/>
    </div>
</template>
<script>
    import PageForbidden from '../components/forbiddenPage/PageForbidden'
    export default {
        components: {
            PageForbidden
        }
    }
</script>

<style></style>
