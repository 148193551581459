<template>
    <div>
        <AboutHeader />
        <div class="line-wrapper">
            <hr class="line"/>
        </div>
        <AboutMain v-bind:isAuthenticated="isAuthenticated"/>
        <div class="line-wrapper">
            <hr class="line"/>
        </div>
        <AboutFooter />
    </div>
</template>
<script>
    import AboutHeader from '../components/about/AboutHeader'
    import AboutMain from "../components/about/AboutMain";
    import AboutFooter from "../components/about/AboutFooter";
    import {mapGetters} from "vuex";
    export default {
        components: {
            AboutHeader,
            AboutMain,
            AboutFooter
        },
        computed: {
            isAuthenticated: {
                get(){
                    return this.$auth.isAuthenticated
                }
            }
        },
    }
</script>
<style scoped>
    .line-wrapper {
        display: flex;
        justify-content: center;
        margin: 95px 0;
        width: 100%;
    }

    .line {
        width: 90%;
        background-color: black;
    }

    @media (max-width: 840px) {
        .line {
            width: 90%;
        }

        .line-wrapper {
            display: flex;
            justify-content: center;
            margin: 50px 0;
            width: 100%;
        }
    }
</style>
