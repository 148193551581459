var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isDesktop)?_c('div',{class:{
            desktopCard:_vm.isDesktop,
            desktopCardWidth: _vm.isDesktop,
            expertTeam: _vm.card.title === 'Expert Team',
            codeControl: _vm.card.title === 'Code Control',
            earlyChecks: _vm.card.title === 'Early Checks',
            diverseAnalysis: _vm.card.title === 'Diverse Analysis',
            expansiveRules: _vm.card.title === 'Expansive Rules'
        },on:{"mouseover":_vm.handleHover,"mouseleave":_vm.handleLeave}},[_c('div',{staticClass:"content"},[_c('span',{staticClass:"desktopCard__title"},[_vm._v(" "+_vm._s(_vm.card.title)+" ")]),_c('span',{class:{ descriptionText: _vm.isHovered, blockClosed: !_vm.isHovered }},[_vm._v(" "+_vm._s(_vm.card.description)+" ")]),_c('button',{staticClass:"get-started-btn",class:{ activeButton: _vm.isClicked, passiveButton: !_vm.isClicked },on:{"mousedown":_vm.click,"mouseup":_vm.click,"click":_vm.requestDemo}},[_vm._v(" "+_vm._s(_vm.buttonText)+" "),_c('TopArrowIcon',{staticClass:"arrow-icon",attrs:{"clicked":!_vm.isClicked}})],1)])]):_c('div',{class:{
            mobileCard: _vm.isMobile,
            desktopCard:_vm.isMiddleDesktopSize,
            middleCardWidth: _vm.isMiddleDesktopSize,
            expertTeam: _vm.card.title === 'Expert Team',
            codeControl: _vm.card.title === 'Code Control',
            earlyChecks: _vm.card.title === 'Early Checks',
            diverseAnalysis: _vm.card.title === 'Diverse Analysis',
            expansiveRules: _vm.card.title === 'Expansive Rules'
        }},[_c('div',{staticClass:"content"},[_c('span',{class:{ mobileCard__title: _vm.isMobile, desktopCard__title: _vm.isMiddleDesktopSize }},[_vm._v(" "+_vm._s(_vm.card.title)+" ")]),_c('span',{class:{ descriptionText: _vm.isMiddleDesktopSize || _vm.isMobile }},[_vm._v(" "+_vm._s(_vm.card.description)+" ")]),_c('button',{staticClass:"get-started-btn",class:{ activeButton: _vm.isMiddleDesktopSize || _vm.isMobile, passiveButton: _vm.isClicked },on:{"mousedown":_vm.click,"mouseup":_vm.click,"click":_vm.requestDemo}},[_vm._v(" "+_vm._s(_vm.buttonText)+" "),_c('TopArrowIcon',{staticClass:"arrow-icon",attrs:{"clicked":_vm.isClicked}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }