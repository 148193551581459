<template>
    <div class="hover-block">
        <img
            class="image-left"
            src="../../../assets/images/main/animation-images/scan-report-1.svg"
            alt="card"
        >
        <div class="gradient-block" />
        <img
            class="small-image-left"
            src="../../../assets/images/main/animation-images/high-severity-statistics.svg"
            alt="card"
        >
        <img
            class="image-bottom"
            src="../../../assets/images/main/animation-images/bottom-img.svg"
            alt="card"
        >
    </div>
</template>

<script>
export default {
    name: 'InteractiveSVG',
    props: {
        disabled: {
            type: Boolean,
            require: true,
        },
    },
    computed: {
        arrowColor() {
            return this.disabled ? '#D9D9D9' : '#949DB8';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables/variablesForMainTopAnimation.scss';

.hover-block{
  position: absolute;
  //width: -webkit-fill-available;
  width: 100%;
  height: $dynamic-hover-block-height;
  overflow: hidden;
}

.small-image-left {
    position: absolute;
    top: $dynamic-small-image-left-top;
    left: $dynamic-small-image-left-l;
    width: $dynamic-vulnerability-width;
    height: $dynamic-vulnerability-height;
    z-index: 4;
    border-radius: 20px;
    transition: transform 1.1s ease;

}
.gradient-block{
    position: absolute;
    top: 252px;
    z-index: 3;
    filter: blur(50px);
    width: 1583.967px;
    height: 457.261px;
    background: linear-gradient(180deg, rgba(246, 246, 249, 0.00) 0%, rgba(246, 246, 249, 0.17) 33.33%, rgba(246, 246, 249, 0.75) 66.67%, #F6F6F9 100%);

}

.image-left {
    position: absolute;
    top: 20px;
    left: 82px;
    width: $dynamic-scan-width;
    height: $dynamic-scan-height;
    z-index: 2;
    border-radius: 40px;
    transition: transform 1.1s ease;


}
.image-bottom {
    position: absolute;
    top:120px;
    left: $dynamic-image-bottom-left;
    width: $dynamic-blured-image-width;
    height: $dynamic-blured-image-height;
    z-index: 1;
    border-radius: 30px;
    opacity: 0.6;
    transition: transform 1.1s ease;
}

.hover-block:hover .small-image-left{
  transform: translateX(-7%) translateY(-6%) scale(1.08, 1.08);
}
.hover-block:hover .image-left{
  transform: translateX(-3%) translateY(-20px) ;
}
.hover-block:hover .image-bottom{
  transform: translateY(25%);
}

</style>

