<template>
    <div
        class="step__wrapper"
        :class="[isOpen ? 'step-open' : 'step-close', `step-${stepNumber}`]"
    >
        <div class="step__header">
            <div class="step__header-number">
                {{ stepNumber }}
            </div>
            <div
                class="step__header-number-mobile"
                :style="{background: stepCircleStyle}"
            >
                {{ stepNumber }} of 3
            </div>
            <div class="step__header-text">
                <div>
                    <slot name="headerText" />
                </div>
                <div class="step__header-text-next">
                    {{ nextStep }}
                </div>
            </div>
            <slot
                v-if="isOpen"
                name="headerButton"
            />
        </div>
        <div class="step__body">
            <slot v-if="isOpen" />
        </div>
        <div class="step__buttons">
            <slot name="stepButtons" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'StepContainer',
    props: ['stepNumber', 'headerText', 'isOpen'],
    computed: {
        stepCircleStyle() {
            return `radial-gradient(closest-side, #fff 87%, transparent 90%), conic-gradient(#3180F6 ${this.stepNumber/3*100}%, #ADCCFB 0)`;
        },
        nextStep() {
            switch(this.stepNumber) {
                case 1:
                    return 'Next: Scan Option';
                case 2:
                    return 'Next: Payment Methods';
                case 3:
                    return 'You\'re almost there!';
                default:
                    return '';
            }
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/css/variables";

.step__header {
    display: flex;
    align-items: center;
    gap: 20px;
    &-text {
        font-size: 22px;
        font-weight: 650;
        &-next {
            display: none;
        }
    }
}
.step__header-number {
    width: 32px;
    height: 32px;
    font-size: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-mobile {
        display: none;
    }
}

.step__wrapper {
    width: 55vw;
    padding: 40px 40px;
    border-radius: 20px;
    background-color: #fff;
    &.step-close {
        .step__header {
            color: $main-text;
            &-number {
                background-color: $background-ghost;
            }
        }
    }
    &.step-open {
        .step__header {
            color: $main-blue-color;
            margin-bottom: 30px;
            &-number {
                background-color: $background-blue-color;
            }
        }
    }
}
.step__body {
    padding-left: 45px;
}

@media (max-width: 1500px) {
    .step__wrapper {
        padding: 20px 30px;
    }

}
@media (max-width: 1200px) {
    .step__body {
        padding-left: 0;
    }

}
@media (max-width: 900px) {
    .step__header-number {
        display: none;
        &-mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            flex-shrink: 0;
        }
    }
    .step__wrapper {
        width: 80vw;
        &.step-close {
            display: none;
        }
    }
    .step__header {
    &-text {
        &-next {
            display: block;
            color: $main-text;
            font-size: 14px;
            font-weight: 400;
        }
    }
}
}
@media (max-width: 505px) {
    .step__wrapper {
        width: 100vw;
        background-color: $background-grey;
        border-radius: 0;
        &.step-open.step-1 {
            padding-left: 0;
            padding-right: 0;
            .step__header {
                padding-left: 30px;
            }
        }
        &.step-open.step-3 {
            background-color: #fff;
        }
        &.step-close {
            display: none;
        }
    }
}
</style>
