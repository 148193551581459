<template>
    <div
        v-if="!versionsInProgress.length"
        class="first-integrations"
    >
        <form
            v-if="!isExpired"
            class="form__first-integrations"
            @submit.prevent=""
        >
            <h3>No version has been uploaded yet.</h3>
            <button
                v-if="integrationStatus !== 'SAMPLE' &&
                    !(integration.status === 'ACTIVE' && countVersionsFinish === 0 && integration.trial && !integration.isAllowAddVersion) &&
                    (!integration.limited || integration.limitedCount - integration.usedVersionsCount > 0)"
                class="form__first-integrations-btn form__first-integrations-btn-active"
                type="submit"
                @click="redirectNewVersion"
            >
                Upload first version
            </button>
            <div
                v-else
                style="position: relative;"
            >
                <div
                    v-if="integration.status === 'ACTIVE' && integration.trial && !integration.isAllowAddVersion"
                >
                    <Tooltip
                        position="top"
                        mobile-position="top"
                        :tooltip="tooltipText"
                    >
                        <button class="form__first-integrations-btn form__first-integrations-btn-disabled">
                            Upload first version
                        </button>
                    </Tooltip>
                </div>
                <div
                    v-else
                >
                    <button class="form__first-integrations-btn form__first-integrations-btn-disabled">
                        Upload first version
                    </button>
                </div>
            </div>
        </form>
        <form
            v-else
            class="form__first-integrations"
        >
            <h3>No version has been uploaded yet.</h3>
        </form>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Tooltip from '../report/components/Tooltip.vue';
export default {
    name: "FirstVersionIntegration",
    components: { 
        Tooltip,
    },
    props: {
        isExpired: Boolean,
        renewalIntegrationRequest: Function,
        renewalRequest: Boolean,
        integrationStatus: String,
        integration: Object,
        branchName: String
    },
    data() {
        return {
            tooltipText: 'The number of trial scans is exceeded',
        };
    },
    computed: {
        ...mapGetters({
            versionsInProgress: 'integrations/versionsInProgress',
            finishedVersions: 'integrations/finishedVersions',
            countVersionsFinish: 'integrations/countVersionsFinish',
        }),
    },
    methods: {
        redirectNewVersion() {
            this.$router.push(`/integration/${this.$route.params.id}/branch/${this.$route.params.branchName}/new-version`);
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/fonts";
.first-integrations {
    padding: 40px 0 33px;
    margin: 0 7.5%;

    .form__first-integrations {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        box-sizing: border-box;
        box-shadow: 0 4px 15px rgba(102, 124, 131, 0.06);
        border-radius: 20px;
        padding: 60px 0;
        min-height: 315px;
        width: 100%;

        &-btn {
            padding: 10px 40px;
            box-sizing: border-box;
            border-radius: 8px;
            transition: 0.3s;

            &-active {
                @include Aspekta-font(600, 18px, normal, #FF7A08);
                background: #FFFFFF;
                border: 2px solid #FFD2AB;

                &:hover {
                    box-shadow: 0px 8px 16px 0px rgba(255, 180, 0, 0.24);
                }
            }

            &-disabled {
                @include Aspekta-font(600, 18px, normal, #FFFFFF);
                background-color: #BEBEBE;
                border: 2px solid #BEBEBE;
                box-sizing: border-box;
                transition: 0.3s;
                cursor: default;

                &:hover {
                    opacity: 0.7;
                }
                &:active {
                    opacity: 1;
                }
        }

    }

        h3 {
            @include Aspekta-font(600, 20px, 20px, #242424);
            margin-bottom: 1rem;
        }

    }
}

@media (max-width: 576px) {
    .first-integrations {
        margin: 0 20px;
    }
}

</style>
