<template>
    <b-container class="confirm-email__container">
        <div v-if="IsConfirmationEmailNow" class="confirm-email__spinner">
            <Spinner/>
        </div>
        <div v-else class="confirm-email">
            <h1 class="confirm-email__title">Email Confirmed!</h1>
            <div class="confirm-email__message">
                <h6 class="confirm-email__text confirm-email__text--center">Your email has been confirmed.
                    <br> If you haven't been redirected to the sign-in page after a few seconds, click the button below
</h6>
                <b-nav-item to="/sign-in" @click="stopTimeout()" class="confirm-email__back-link">Redirect</b-nav-item>
            </div>
        </div>
    </b-container>
</template>

<script>
import Spinner from "@/components/Spinner";

export default {
    components: {
        Spinner
    },
    data() {
        return {
            IsConfirmationEmailNow: true,
            showContent: false,
            timeout: null,
        }
    },
    created() {
        this.signature = this.$route.params.signature;
        if (this.signature) {
            this.$auth.confirmEmail({id: this.signature})
            this.IsConfirmationEmailNow = false
            this.redirectAfterConfirmEmail()
        } else {
            this.IsConfirmationEmailNow = false
        }
    },
    methods: {
        redirectAfterConfirmEmail() {
            this.timeout = setTimeout(() => {
                this.$router.push('/sign-in')
            }, 6000);
        },
        stopTimeout() {
            clearTimeout(this.timeout);
        }
    }
}
</script>

<style lang="scss">
.confirm-email {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
        font-family: Raleway, serif;
        font-weight: bold;
        font-size: 72px;
        color: #242424;
        margin: 60px 0;
    }

    &__container {
        position: relative;
    }

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 595px;
        margin-bottom: 131px;
    }

    &__message {
        width: 100%;
    }

    &__error {
        position: absolute;
        top: -27px;
        left: 0;
        font-family: Cabin, serif;
        font-weight: normal;
        font-size: 12px;
        color: #FB8D62;
    }

    &__inputs-wrapper {
        margin: 36px 0;
        width: 100%;
        position: relative;
        max-width: 260px;

        .react-code-input-container {
            width: 100% !important;
        }

        div.react-code-input {
            display: flex;
            justify-content: center;
            width: 100% !important;
        }

        input {
            background: #FFFFFF;
            border: 1px solid #F0F0F0 !important;
            box-sizing: border-box;
            border-radius: 5px !important;
            font-family: Cabin, serif !important;
            font-weight: normal;
            font-size: 14px !important;
            color: #B2B9C4;
            width: 35px !important;
            height: 35px !important;

            &:not(:last-child) {
                margin-right: 10px;
            }

            &:focus {
                outline: none !important;
                border: 2px solid #025FCC !important;
            }
        }
    }

    &__text {
        margin: 0;
        font-family: Cabin, serif;
        font-weight: 500;
        font-size: 18px;
        color: #706969;
        line-height: 150%;

        &--center {
            text-align: center;
        }
    }

    &__btn-submit {
        background: #FFB400;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Cabin, serif;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        padding: 10px 40px;
        margin: 0 0 28px 0;
        transition: 0.3s;

        &:focus {
            border: 2px solid #FFB400;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &__back-link {
        max-width: 195px;
        margin: 0 auto;

        a.nav-link {
            display: flex;
            justify-content: center;
            background: #FFB400;
            border: 2px solid #FFB400;
            box-sizing: border-box;
            border-radius: 8px;
            font-family: Cabin, serif;
            font-weight: bold;
            font-size: 18px;
            color: #FFFFFF;
            margin-top: 30px;
            padding: 10px 40px;
            max-width: 195px;
            transition: 0.3s;

            &:focus {
                border: 2px solid #FFB400;
            }

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

@media (max-width: 768px) {
    .confirm-email {
        padding: 0 20px;

        &__title {
            font-size: 40px;
            margin: 30px 0;
            text-align: center;
        }

        &__form {
            margin-bottom: 30px;
        }

        &__btn-submit {
            padding: 10px 0;
            width: 100%;
            margin: 15px 0 28px 0;
        }

        &__back-link {
            width: 100% !important;
            max-width: 100%;

            a.nav-link {
                max-width: 100%;
                width: 100% !important;
                font-size: 18px !important;
            }
        }
    }
}
</style>
