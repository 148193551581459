<template>
    <div class="api-keys">
        <div class="api-keys__header">
            <button v-b-modal.general-popup class="api-keys__generate-btn">Generate Token</button>
        </div>
        <table class="api-keys__table" id="api-keys__table">
            <thead v-if="apiTokensList && apiTokensList.length > 0">
            <tr>
                <th>Token Name</th>
                <th>Last Used</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="token in apiTokensList" :key="token.id">
                <td>{{ token.name }}</td>
                <td v-if="token.last_used_on">{{calculateDateForSettings(token.last_used_on)}}</td>
                <td v-else>-</td>
                <td>
                    <button class="api-keys__table-delete-btn"
                        @click="deleteToken(token.id)"
                        @mouseenter="showLabel(token.id)"
                        @mouseleave="showHintDeleteLabel = null"
                    >
                        <p v-show="showHintDeleteLabel !== null && showHintDeleteLabel === token.id" class="delete-token-btn-title">
                            Delete token
                        </p>
                        <img class="api-keys__btn-delete"
                             src="../../assets/images/myScans/icon_action_delete.svg"
                             alt="icon_action_delete">
                    </button>
                </td>
            </tr>
            <tr v-if="apiTokensList && apiTokensList.length === 0">
                <td>
                    <h6 class="api-keys__table-empty">No tokens have been created yet</h6>
                </td>
            </tr>
            </tbody>
        </table>
        <div v-if="isShowSpinner" class="api-keys__spinner">
            <Spinner/>
        </div>
        <GeneralPopup
            :titleText="showGeneratedToken ? responseTokenName : 'Generate new token'"
            :loading="loading"
            size="'lg'"
            @onHidden="hiddenModal"
        >
            <div v-if="showGeneratedToken">
                <div class="api-keys__generate-modal-success">
                    <h6 class="api-keys__generate-modal-success-title">The token has been successfully generated! </h6>
                    <label>
                        <input class="api-keys__generate-modal-success-input"
                            v-on:focus="$event.target.select()"
                            ref="responseToken"
                            readonly
                            :value="responseToken"
                            type="text"
                        >
                    </label>
                </div>
            </div>
            <form
                v-else
                @submit.stop.prevent="handleTokenModal"
                class="api-keys__generate-modal-form"
            >
            <label class="api-keys__generate-modal-label">
                <input
                    id="tokenName"
                    v-model.trim="requiredData.tokenName"
                    name="tokenName"
                    class="api-keys__generate-modal-input"
                    type="text"
                    ref="tokenInput"
                    @input="checkKeyEvent"
                    @focus="activeInputName = 'tokenName'"
                    @blur="activeInputName = null"
                >
                <label for="tokenName" class="label" :class="{ activeInput: requiredData.tokenName !== isEmptyInput || activeInputName === 'tokenName'}">Token Name</label>
                <img v-if="requiredData.tokenName.length"
                    @click="clearInputAndErrors('tokenName')"
                    class="api-keys-input-check"
                    src="../../assets/images/cross_grey.svg"
                    alt="cross"
                >
                <div id="tokenNameError" class="api-keys-error">
                    <img
                        @click="clearInputAndErrors('tokenName')"
                        class="api-keys-error-cross"
                        src="../../assets/images/cross.svg"
                        alt="cross"
                    >
                    <p class="api-keys-error-text">Enter token name</p>
                </div>
                <div id="tokenNameLengthError" class="api-keys-error">
                    <img
                        @click="clearInputAndErrors('tokenName')"
                        class="api-keys-error-cross"
                        src="../../assets/images/cross.svg"
                        alt="cross"
                    >
                    <p class="api-keys-error-text">Max input length 1000</p>
                </div>
            </label>
            </form>
            <template v-slot:ok-button>
                <button
                    v-if="!showGeneratedToken"
                    style="padding: 12px 40px"
                    class="api-keys__generate-btn"
                    @click="handleTokenModal"
                >
                    Generate
                </button>
                <div v-else style="display: flex; gap: 15px;">
                    <button
                        @click="copyToken"
                        style="position: relative;"
                        class="api-keys__generate-btn"
                        :class="{ghost: isFromGetStated}"
                    >
                        Copy To Clipboard
                        <span class="api-keys__generate-modal-success-input-copy-link" v-if="isCopiedId"
                            v-text="'Token Copied!'"></span>
                    </button>
                    <router-link v-if="isFromGetStated" to="/get-started">
                        <button
                            style="white-space: nowrap;"
                            class="api-keys__generate-btn"
                            @click="$store.commit('user/setToGetStartedFrom', GetStartedStepsEnum.CREATED_API_KEY)"
                        >
                            Continue Setting Up
                        </button>
                    </router-link>
                </div>
            </template>
        </GeneralPopup>
    </div>
</template>
<script>
import Spinner from "@/components/Spinner";
import {mapGetters} from "vuex";
import {
    checkFormInputsForCompleteness,
    checkFormInputsForErrors,
    checkFormInputsLength,
    calculateDateForSettings,
    hideErrors
} from '@/services/functions'
import closeModal from '../../assets/images/closeModal.svg'
import GeneralPopup from '@/components/popups/GeneralPopup.vue';
import {tokenNotification} from '@/services/notificationService/notificationService';
import { GetStartedStepsEnum } from '../../utils/constants/common';

export default {
    components: {
        Spinner,
        GeneralPopup,
    },
    data() {
        return {
            showHintDeleteLabel: null,
            loading: false,
            isShowSpinner: false,
            showGeneratedToken: false,
            responseToken: null,
            responseTokenName: null,
            isCopiedId: false,
            requiredData: {
                tokenName: '',
            },
            isEmptyInput: '',
            activeInputName: null,
            closeModal,
            GetStartedStepsEnum,
        }
    },
    async beforeMount() {
        try {
            this.isShowSpinner = true
            await this.$store.dispatch('apiKeys/getApiTokens')
            this.isShowSpinner = false
        } catch (error) {
            this.isShowSpinner = false
            console.log(error)
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    watch: {
        async responseAfterCreatedToken() {
            if (this.responseAfterCreatedToken && this.responseAfterCreatedToken.access_key) {
                this.responseToken = this.responseAfterCreatedToken.access_key
                this.showGeneratedToken = true
                this.responseTokenName = this.responseAfterCreatedToken.name
                try {
                    await this.$store.dispatch('apiKeys/getApiTokens')
                } catch (error) {
                    console.log(error)
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            responseAfterCreatedToken: 'apiKeys/responseAfterCreatedToken',
            apiTokensList: 'apiKeys/apiTokensList',
            isFromGetStated: 'user/isFromGetStated',
        }),
    },
    beforeDestroy() {
        this.$store.commit('user/setIsFromGetStated', false);
    },
    methods: {
        /**
         * This method is called when the user scrolls the page. It hides the hint labels.
         */
        handleScroll() {
            this.showHintDeleteLabel = null
        },
        /**
         * This method is called when the user hovers on the "Delete" button. It shows the hint label.
         * @param {string} id - id of the token.
         */
        showLabel(id) {
            this.showHintDeleteLabel = id
        },
        clearInputAndErrors(inputField) {
            this.requiredData.tokenName = '';
            hideErrors(['tokenNameError', 'tokenNameLengthError'])
            document.getElementById(inputField).style.border = '1px solid #F0F0F0'
        },
        hiddenModal() {
            this.responseToken = null
            this.showGeneratedToken = false
            this.requiredData.tokenName = ''
            this.$store.commit('user/setIsFromGetStated', false);
        },
        handleTokenModal() {
            checkFormInputsForErrors(this.requiredData, 'api-keys__generate-modal-input--error')
            checkFormInputsLength(this.requiredData, 'api-keys__generate-modal-input--error')

            if (checkFormInputsForCompleteness(this.requiredData)) {
                this.createToken()
            }
        },
        async createToken() {
            this.loading = true
            try {
                await this.$store.dispatch('apiKeys/createNewToken', {
                    name: this.requiredData.tokenName
                })
                this.loading = false

            } catch (error) {
                console.log(error)
                this.loading = false
            }
        },
        async deleteToken(id) {
            try {
                this.isShowSpinner = true
                await this.$store.dispatch('apiKeys/deleteToken', id)
                await this.$store.dispatch('apiKeys/getApiTokens')
                this.isShowSpinner = false
                this.tokenNotification('removed')
            } catch (error) {
                console.log(error)
            }
        },
        copyToken() {
            this.$refs.responseToken.focus();
            document.execCommand('copy');
            this.isCopiedId = true;
            setTimeout(() => this.isCopiedId = false, 3000)
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id
        },
        tokenNotification,
        calculateDateForSettings
    }
}
</script>
<style lang="scss">
@import "../../assets/css/variables";

.api-keys {
    position: relative;
    min-height: 100px;
    width: 100%;

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;

        div.spinner-wrapper {
            width: 100%;
            height: 100%;
            padding: 0 !important;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    &__title {
        font-family: Aspekta, serif;
        font-weight: bold;
        font-size: 18px;
        color: #242424;
    }

    &__generate-btn {
        background: #FF7A08;
        border: 2px solid #FF7A08;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Aspekta, serif;
        font-style: normal;
        letter-spacing: 0.54px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        margin-left: auto;
        max-width: 200px;
        width: 100%;
        padding: 12px;
        transition: all .3s;
        &:hover {
            background-color: #fff;
            color: #FF7A08;
            box-shadow: 0px 8px 16px 0px rgba(255, 180, 0, 0.24);
        }
        &.ghost {
            color: #949DB8;
            background-color: #fff;
            border-color: #E3E6ED;
            &:hover {
                box-shadow: 0px 12px 16px 0px rgba(178, 185, 195, 0.12);
            }
        }

    }

    &__generate-modal-form {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__generate-modal-title {
        text-align: center;
        font-family: Aspekta, serif;
        font-weight: bold;
        font-size: 18px;
        color: #242424;
        margin: 12px 0 30px 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
    }

    &__generate-modal-label {
        position: relative;
        margin: 0;
        width: 100%;
    }

    &__generate-modal-input {
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Aspekta, serif;
        font-weight: normal;
        font-size: 14px;
        color: #242424;
        padding: 10px 30px 10px 10px;
        min-height: 40px;
        width: 100%;

        &--error {
            border: 1px solid #FB8D62;
            border-radius: 5px;
        }
    }

    &-input-check {
        position: absolute;
        bottom: 16px;
        right: 20px;
        cursor: pointer;
    }

    &-error {
        width: 100%;
        max-width: 831px;
        position: relative;
    }

    &-error-cross {
        position: absolute;
        top: -27px;
        right: 20px;
    }

    &-error-text {
        position: absolute;
        top: 0;
        left: 0;
        font-family: Aspekta, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 150%;
        color: #FB8D62;
        margin: 0;
    }

    &__generate-modal-success {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__generate-modal-success-title {
        display: flex;
        justify-content: center;
        font-family: Aspekta, serif;
        font-weight: normal;
        font-size: 14px;
        color: #706969;
        max-width: 300px;
        width: 100%;
    }

    &__generate-modal-success-input {
        min-width: 300px;
        border: none;
        text-align: left;
        margin-top: 16px;
        font-family: Aspekta, serif;
        font-weight: normal;
        font-size: 14px;
        color: #706969;
    }

    &__generate-modal-success-btn {
        background: #FFB400;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Aspekta, serif;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        max-width: 253px;
        padding: 10px;
        width: 100%;
        position: relative;

        &:focus {
            border: 2px solid #FFB400;
        }
    }

    &__table-empty {
        text-align: center;
        font-family: Aspekta, serif;
        font-weight: bold;
        font-size: 20px;
        color: #242424;
    }

    &__generate-modal-success-input-copy-link {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate(-50%);
        width: 90px;
        text-align: center;
        height: 23px;
        background-color: black;
        border-radius: 3px;
        color: white;
        font-family: Aspekta, serif;
        font-size: 12px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__generate-modal-success-input-copy-link::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0%);
        border: 6px solid transparent;
        border-top: 3px solid black;
    }

    #api-keys__table {
        background-color: #FFFFFF;
        width: 100%;
        border-radius: 20px;
    }

    #api-keys__table thead tr {
        height: 60px;
        font-family: Aspekta, serif;
        font-weight: normal;
        font-size: 14px;
        color: #B2B9C4;
        border-bottom: rgba(178,185,196, .2) solid 1px;
    }

    #api-keys__table tbody tr {
        height: 85px;
        font-family: Aspekta, serif;
        font-weight: normal;
        font-size: 18px;
        color: #242424;
        border-bottom: rgba(178,185,196, .2) solid .5px;
        &:last-child {
            border-bottom: none;
        }
        &:hover {
            box-shadow: 0 4px 15px rgba(56, 70, 77, 0.04);
        }
    }

    #api-keys__table thead tr th:nth-child(1),
    #api-keys__table tbody tr td:nth-child(1) {
        padding-left: 56px;
        box-sizing: border-box;
        width: 76%;
        word-break: break-word;
    }

    #api-keys__table thead tr th:nth-child(2),
    #api-keys__table thead tr th:nth-child(3),
    #api-keys__table tbody tr td:nth-child(2),
    #api-keys__table tbody tr td:nth-child(3) {
        width: 12%;
        text-align: center;
    }

    .api-keys__btn-delete {
        visibility: hidden;
    }

    #api-keys__table tbody tr {
        &:hover .api-keys__btn-delete {
            visibility: visible;
        }
    }
}

#api-keys__table tbody tr td:nth-child(3) {
    button:nth-child(1) {
        position: relative;

        .delete-token-btn-title {
            @extend .btn-title;
            visibility: visible;
            width: 95px !important;
            left: 42%;
        }
    }
}

#tokenNameError,
#tokenNameLengthError {
    display: none;
}

@media (max-width: 576px) {
    .api-keys {
        &__header {
            margin: 12px 20px;
        }

        &__generate-btn {
            margin-right: auto;
            border: 0;

            &:focus {
                border: 0;
            }
        }

        #api-keys__table thead tr {
            height: 60px;
        }

        #api-keys__table tbody tr {
            height: 60px;
        }

        #api-keys__table thead tr th:nth-child(1),
        #api-keys__table tbody tr td:nth-child(1) {
            width: 50%;
            padding-left: 20px;
        }

        #api-keys__table thead tr th:nth-child(2),
        #api-keys__table thead tr th:nth-child(3),
        #api-keys__table tbody tr td:nth-child(2),
        #api-keys__table tbody tr td:nth-child(3) {
            width: 25%;
        }
    }
}
</style>
