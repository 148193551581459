<template>
    <div class="integration-settings-container">
        <IntegrationSettingsPage />
    </div>
</template>
<script>
    import IntegrationSettingsPage from "../../../components/innerIntegration/IntegrationSettingsPage";

    export default {
        components: {
            IntegrationSettingsPage,
        }  
    }
</script>
<style lang="scss">
    .integration-settings-container {
        background-color: #FAFBFC;
        padding: 40px 0 70px 0;
    }
</style>
