export const state = () => ({
    errors: {},
})

export const mutations = {
    setErrors: (state, response) => {
        state.errors = {
            [response.key]: response.body
        }
    },
    clearErrors: (state) => {
        state.errors = {}
    },
}

export const actions = {}

export const getters = {
    errors: s => s.errors,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
