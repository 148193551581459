<template>
    <div>
        <b-container class="paddings">
            <b-row>
                <b-col>
                    <h1 class="sign">Terms of Use</h1>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="last-update">Last updated: November 29, 2023</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h2 class="terms-subtitle">IMPORTANT NOTICE: THESE TERMS AND CONDITIONS CONTAIN A BINDING ARBITRATION
                        PROVISION AND WAIVER OF JURY TRIALS AND CLASS ACTIONS GOVERNING DISPUTES ARISING FROM USE OF THE
                        OVERSECURED SERVICES. IT AFFECTS YOUR LEGAL RIGHTS AS DETAILED IN THE MANDATORY ARBITRATION AND
                        WAIVER OF CLASS ACTION SECTION BELOW. PLEASE READ CAREFULLY.
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h2 class="content">You represent and warrant that you are: (a) of legal age (18 years of age or
                        older or otherwise of legal age in your resident jurisdiction) and competent to agree to these
                        Terms; and (b) you are not and will not when using the Services be located in, under the control
                        of, or a national or resident of a U.S. embargoed country or territory and are not a prohibited
                        end user under export control laws. You acknowledge that you are not permitted to use the
                        Services if you cannot make these representations. If Oversecured has previously prohibited you
                        from accessing the Services, you are not permitted to access the Services. If you are agreeing
                        to these Terms on behalf of an organization or entity, you represent and warrant that you are
                        authorized to agree to these Terms on that organization or entity’s behalf and bind them to
                        these Terms (in which case, the references to "you" and "your" in these Terms, except for in
                        this sentence, refer to that organization or entity).
                        You may use the Platform in accordance with the following Terms. The Terms affect your statutory
                        rights, so you should make yourself aware of them carefully. By continuing to use the platform
                        you agree unconditionally to these Terms. If you do not agree to these Terms, do not use the
                        Platform.
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h2 class="content">If you have an independent agreement with Oversecured on the subject matter
                        hereof, the terms of such agreement may supersede these Terms.
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">ABOUT THE OVERSECURED PLATFORM</p>
                    <p class="content">The Oversecured platform is designed for researchers and companies who would like
                        to improve the safety and security of mobile apps. The Platform offers the ability to scan
                        publicly distributed mobile apps in order to: <br>
                        - take part in bug bounties or vulnerability disclosure programs;<br>
                        - pentest own projects or authorized third party projects;<br>
                        - gather vulnerability statistics;<br>
                        - conduct research aimed at improving the security of mobile apps.<br>
                        The users can use the service to obtain information about vulnerabilities in their mobile
                        apps.<br>
                        The scan report will be divided into categories as determined by the Oversecured team.<br>
                        Researchers can study and check the security of any app, without being the owner of it, but with
                        a proper authorization from the app owner. Researchers should inform the app owners of any
                        vulnerabilities they discover in such apps in good faith. If an individual researcher is acting
                        on behalf of a company, he is obliged to mention in his report the fact that the vulnerabilities
                        were discovered using the Oversecured Service.<br>
                        Companies that own apps may purchase a subscription and may only scan the app for which they
                        have a subscription, with a view to integrating the Oversecured Service into the development and
                        release process for new versions.</p>
                    <p class="title">NO UNLAWFUL OR PROHIBITED USE</p>
                    <p class="content">As an obligatory condition of your usage of the website and the services, you
                        warrant to Oversecured that you will not use the website or the services for any purpose that is
                        unlawful or prohibited by these terms. <br>
                        You may not use the website and the services in any manner that could damage, disable,
                        overburden, or impair the website or the services, or interfere with any other party’s use and
                        enjoyment of the website or the services. You may not obtain or attempt to obtain any materials
                        or information through any means not intentionally made available or provided for through the
                        website or the services. <br>
                        You agree to comply with all applicable domestic and international laws, statutes, ordinances,
                        and regulations regarding your use of the website and services. Oversecured reserves the right
                        to investigate complaints or reported violations of our Terms of Use and to take any action we
                        deem appropriate, including but not limited to canceling your account, reporting any suspected
                        unlawful activity to law enforcement officials, regulators, or other third parties and
                        disclosing any information necessary or appropriate to such persons or entities relating to your
                        profile, email addresses, usage history, posted materials, IP addresses and traffic information,
                        as allowed under our
                        <b-nav-item class="terms__link" :to="{ path: '/privacy'}">Privacy Policy</b-nav-item>.</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">DEFINITIONS</p>
                    <p class="content">DEFINITIONS
                        The following capitalized terms shall have the meaning specified within the Definitions section
                        below. <br>
                        * “Researcher” means a security researcher or anyone who is willing to help companies and other
                        organizations find bugs and vulnerabilities in their mobile applications or computer systems.
                        Researcher means an individual or entity using the Oversecured Platform to scan mobile apps they
                        do not own, for research purposes. <br>
                        * “Integrated Company (Company)” means a company that automates its mobile app security
                        improvement processes using Oversecured Services via Integration. Organizations obtain the
                        ability to improve their apps' protection, speed up the process of discovering vulnerabilities,
                        and prevent their release to production. <br>
                        * “Confidential Information" means any confidential or proprietary business or technical
                        information about a party related to the Services. Confidential Information does not include any
                        information that (i) was publicly known and made generally available in the public domain prior
                        to the time of disclosure by the disclosing party; (ii) becomes publicly known and made
                        generally available after disclosure by the disclosing party to the receiving party; (iii) is
                        already in the possession of the receiving party at the time of disclosure by the disclosing
                        party; or (iv) is obtained by the receiving party from a third party without a breach of such
                        third party's obligations of confidentiality. <br>
                        * “Oversecured” means Oversecured Inc., a Delaware corporation. <br>
                        * “Oversecured Platform" means the Oversecured Platform vulnerability coordination
                        software-as-a-service offered by Oversecured. <br>
                        * “Oversecured Site" means Oversecured's website located at oversecured.com and related domains
                        and subdomains. <br>
                        * “Services" means the Oversecured Platform and any related service made available by or through
                        Oversecured. <br>
                        * “Scan Report" means a report on the scan of a mobile app generated using the Oversecured
                        service, in text, graphics, image, software, works of authorship of any kind, and information or
                        other material that was provided or otherwise become available through the Oversecured Platform.
                        <br>
                        * “Registered User (User)” means any user of the site who possesses an account. It may refer
                        either to a Researcher or a Company. <br>
                        * “Single Scan” means a scan of each APK file for a fixed fee, where the User receives a report
                        on vulnerabilities found for each app submitted for scanning. <br>
                        * “Integration” means a subscription charged for a period of time, where the User may perform an
                        unlimited number of scans of the integrated app, with additional features not available in
                        Single Scan. </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">CHANGES TO THE TERMS</p>
                    <p class="content">Oversecured may modify the Terms at any time upon notice to Registered Users. If
                        the Registered User continues to use the Services after Oversecured has modified the Terms, the
                        Registered User will be deemed to have agreed to be bound by the modified Terms.</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">PAYMENTS</p>
                    <p class="content">The Services are provided for a fee specified on the purchase form for each
                        specific product like Single Scan or Integration. We may also send you an invoice in your
                        account or by email with a specific product and price. All prices are set in US dollars, and the
                        Users solely bear currency exchange and banking fees. <br>
                        Oversecured does not represent or warrant that a particular price will be offered indefinitely
                        and reserves the right to change the prices for or alter the features and options without prior
                        notice. <br>
                        If the Services provide functionality of payment with a banking card, you may agree to automatic
                        and/or recurrent withdrawal of funds from your account.<br>
                        If you use a third party payment provider you should review the agreement of such service
                        provider before making payment.<br>
                        USER’S PAYMENTS ARE NON-REFUNDABLE EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS. Charges for
                        Single Scans are billed and should be paid prior to scanning. Charges for Integration are billed
                        and paid in advance of each subscription period via wire transfer.<br>
                        You must provide accurate and complete information for a valid payment method that you are
                        authorized to use.<br>
                        You must promptly notify us of any change in your invoicing address and must update your account
                        with any changes related to its payment method.<br>
                        If Oversecured does not receive payment from User’s payment method, User agrees to pay all
                        amounts due upon demand. Any amount not paid when due will be subject to finance charges equal
                        to 1.5% of the unpaid balance per month or the highest rate permitted by applicable law,
                        whichever is less, determined and compounded daily from the date due until the date paid. User
                        will reimburse any costs or expenses (including, but not limited to, reasonable attorneys’ fees)
                        incurred by Oversecured to collect any amount that is not paid when due. Oversecured may accept
                        payment in any amount without prejudice to Oversecured’s right to recover the balance of the
                        amount due or to pursue any other right or remedy. Amounts due to Oversecured may not be
                        withheld or offset by the User for any reason against amounts due or asserted to be due from
                        Oversecured.<br>
                        All payments required by these Terms are stated exclusive of all taxes, duties, levies, imposts,
                        fines, or similar governmental assessments, including sales and use taxes, value-added taxes
                        (“VAT”), goods and services taxes (“GST”), excise, business, service, and similar transactional
                        taxes imposed by any jurisdiction and the interest and penalties thereon, excluding taxes based
                        on Oversecured’s net income (collectively, “Taxes”). User shall be responsible for and bear
                        Taxes associated with its purchase of, payment for, access to or use of the Services. Where the
                        responsibility to remit Taxes falls upon Oversecured, the Taxes will be added to the payment and
                        payable to Oversecured at the same time as the payment. Taxes shall not be deducted from the
                        payments to Oversecured, except as required by law, in which case User shall increase the amount
                        payable as necessary so that after making all required deductions and withholdings, Oversecured
                        receives and retains (free from any Tax liability) an amount equal to the amount it would have
                        received had no such deductions or withholdings been made. User hereby confirms that Oversecured
                        can rely on the name and address set forth in its account as being the place of supply for Tax
                        purposes. Oversecured’s and User’s obligations under this Section shall survive the termination
                        or expiration of these Terms.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">INDEPENDENT TRANSACTIONS</p>
                    <p class="content">Any contract or other interaction between Registered Users will be between the
                        Registered Users. Oversecured is not a party to such contracts and disclaims all liability
                        arising from or related to such contracts.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">TERMINATION</p>
                    <p class="content">Oversecured may terminate any Registered User’s access to and use of the
                        Oversecured Platform, at Oversecured’s sole discretion, at any time and without notice to the
                        User. A Registered User may cancel a Registered User's account at any time by sending an email
                        to <a class="terms__link--blue"
                              href="mailto:support@oversecured.com">support@oversecured.com</a>. Upon any termination,
                        discontinuation or cancellation of the
                        Services, the Oversecured Platform or Registered User’s account, the following provisions of the
                        Terms will survive: No Endorsement, Independent Transactions, Ownership, Warranty Disclaimers,
                        Limitation of Liability, and Dispute Resolution.</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">COPYRIGHT POLICY</p>
                    <p class="content">Oversecured respects copyright law in all jurisdictions in which it does business
                        and expects its users to do the same. It is Oversecured's policy to terminate in appropriate
                        circumstances Registered Users which repeatedly infringe or are believed to be repeatedly
                        infringing the rights of copyright holders. Persons who have reason to believe that their
                        intellectual property rights have been infringed on the website may contact Oversecured and
                        request the deletion of or blocking of access to the material that violates their copyright by
                        sending an email to <a class="terms__link--blue" href="mailto:support@oversecured.com">support@oversecured.com</a>.
                        Oversecured will consider such complaints. Once
                        Oversecured is satisfied of the fact of a violation it will take appropriate action.</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">LINKS TO WEBSITES OR RESOURCES OF THIRD PARTIES</p>
                    <p class="content">The Services may contain links to third-party websites or resources. Oversecured
                        provides these links only as a convenience and is not responsible for the content, products or
                        services on or available from those websites or resources or links displayed on such websites.
                        The Registered User acknowledges sole responsibility for and assumes all risk arising from the
                        Registered User’s use of any third-party websites or resources.</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">CONFIDENTIAL INFORMATION</p>
                    <p class="content">Oversecured understands that it may receive Confidential Information belonging to
                        the Registered User, and the Registered User understands that it may receive Confidential
                        Information belonging to Oversecured. The receiving party undertakes not to divulge to third
                        parties any Confidential Information belonging to the other party and not to use any Confidential
                        Information belonging to the other party for any purposes not provided for in the Terms, on
                        condition that Registered Users agree that Platform usage statistics gathered by Oversecured are
                        not Confidential Information.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">NO ENDORSEMENT</p>
                    <p class="content">Oversecured does not endorse any User. Oversecured is not responsible for any
                        damage or harm resulting from any User's communications or interactions with any other User,
                        either through the Services or otherwise. Any reputation ranking or description of any User as
                        part of the Services is not intended by Oversecured as an endorsement of any type. Any selection
                        or use of any User is at the User's own risk. <br>
                        Any use of or reliance on Scan Reports that the User receives is at the User's own risk.
                        Oversecured does not endorse, represent or guarantee the completeness, truthfulness, accuracy,
                        or reliability of any Scan Report. Under no circumstances will Oversecured be liable in any way
                        for any Scan Report, including, but not limited to, any errors or omissions in any Scan Report,
                        or any loss or damage of any kind incurred as a result of the use of any Scan Report. Users are
                        not employees, contractors or agents of Oversecured, but are independent third parties who
                        receive scan reports through the Services. The User agrees that any legal remedy that the User
                        seeks to obtain for actions or omissions of another User or other third parties regarding the
                        User, including Scan Reports, will be limited to a claim against the particular User or other
                        third parties who caused harm to the User, and the User agrees not to attempt to impose
                        liability on Oversecured or seek any legal remedy from Oversecured with respect to such actions
                        or omissions. <br>
                        Oversecured bears no responsibility for actions or omissions of Users who receive Scan Reports.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">OWNERSHIP AND LICENSES</p>
                    <p class="content">Oversecured does not claim any ownership rights in any User’s Material, and
                        nothing in the Terms will be deemed to restrict any rights that the User may have to use and
                        exploit the User's Material. The User acknowledges and agrees that Oversecured may collect
                        statistical and other information, which will not identify a particular User, and use such
                        information internally at Oversecured. Subject to the User's rights in any Material, Oversecured
                        and its licensors exclusively own all right, title and interest in and to the Services, Scan
                        Reports and content contained thereon, including all associated intellectual property rights.
                        The User acknowledges that the Services and Oversecured content are protected by copyright,
                        trademark, and other laws of the United States and foreign countries. <br>
                        Subject to the User's compliance with the Terms, Oversecured hereby grants to the User a
                        non-exclusive, non-transferable, non-sublicenseable, worldwide, royalty-free license to access
                        and view the content that Oversecured makes available on the Services solely in connection with
                        the User's permitted use of the Services. <br>
                        Subject to the User's compliance with the Terms, Oversecured hereby grants to the User a
                        non-exclusive, non-transferable, non-sublicenseable, worldwide, royalty-free license to access
                        and view the Scan Reports that Oversecured makes available on the Services. <br>
                        The User hereby grants Oversecured a non-exclusive, non-revocable, perpetual, freely
                        transferable, freely sublicenseable, worldwide, royalty-free license to access, view and analyze
                        the User Materials for the purpose of vulnerability detection. User Materials related to Single
                        Scans and Integrations will be deleted by Oversecured after the scanning.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">WARRANTY DISCLAIMERS</p>
                    <p class="content">The Services are provided by Oversecured "as is," without warranty of any kind.
                        Without limiting the foregoing, Oversecured explicitly disclaims any warranties of
                        merchantability, fitness for a particular purpose or non-infringement, and any warranties
                        arising out of the course of dealing or usage of trade. Oversecured makes no warranty that the
                        Services will meet the User's requirements, as applicable, or be available on an uninterrupted,
                        secure or error-free basis.</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">USER’S WARRANTIES</p>
                    <p class="content">The User warrants that he is the lawful owner of the materials processed by the
                        User with the Services (“User Materials”), that such User Materials have been lawfully developed
                        or acquired by User, and that use of such User Materials will not infringe on any third party
                        intellectual property. The User agrees to indemnify and hold Oversecured harmless from any and
                        all losses, claims, damages, expenses or liabilities (including reasonable attorneys’ fees)
                        which Oversecured may incur based on User Materials. <br>
                        By using the Services, you specifically agree not to engage in any activity or transmit any
                        information that, in our sole discretion: <br>
                        Is illegal, or violates any federal, state, or local law or regulation; <br>
                        Advocates illegal activity or discusses illegal activities with the intent to commit them; <br>
                        Violates any third-party right, including, but not limited to, right of privacy, right of
                        publicity, copyright, trademark, patent, trade secret, or any other intellectual property or
                        proprietary rights; <br>
                        Is harmful, threatening, abusive, harassing, tortious, indecent, defamatory, sexually explicit
                        or pornographic, discriminatory, vulgar, profane, obscene, libelous, hate speech, violent or
                        inciting violence, inflammatory, or otherwise objectionable; <br>
                        Interferes with any other party’s use and enjoyment of the Services; <br>
                        Attempts to impersonate another person or entity; <br>
                        Is commercial in a way that violates these Terms, including but not limited to, using the
                        Services for spam, surveys, contests, pyramid schemes, or other advertising materials; <br>
                        Falsely states, misrepresents, or conceals your affiliation with another person or entity; <br>
                        Accesses or uses the account of another user without permission; <br>
                        Distributes computer viruses or other code, files, or programs that interrupt, destroy, or limit
                        the functionality of any computer software or hardware or electronic communications equipment;
                        <br>
                        Interferes with, disrupts, disables, overburdens, or destroys the functionality or use of any
                        features of the Services, or the servers or networks connected to the Services; <br>
                        “Hacks” or accesses without permission our proprietary or confidential records, those of another
                        user, or those of anyone else; <br>
                        Improperly solicits personal or sensitive information from other users including without
                        limitation address, credit card or financial account information, or passwords; <br>
                        Decompiles, reverse engineers, disassembles, or otherwise attempts to derive source code from
                        the Site, except as expressly permitted in these Terms or by law, unless and then only to the
                        extent permitted by applicable law without consent; <br>
                        Removes, circumvents, disables, damages, or otherwise interferes with security-related features,
                        or features that enforce limitations on use of the Services; <br>
                        Uses automated or manual means to violate the restrictions in any robot exclusion headers on the
                        Services, if any, or bypasses or circumvents other measures employed to prevent or limit access,
                        for example by engaging in practices such as “screen scraping,” “database scraping,” or any
                        other activity with the purpose of obtaining lists of users or other information; <br>
                        Modifies, copies, scrapes or crawls, displays, distributes, publishes, licenses, sells, rents,
                        leases, lends, transfers, or otherwise commercializes any materials or content on the Services;
                        <br>
                        Uses the Services for benchmarking, or to compile information for a product or service; <br>
                        Downloads (other than through page caching necessary for personal use, or as otherwise expressly
                        permitted by these Terms), distributes, posts, transmits, performs, reproduces, broadcasts,
                        duplicates, uploads, licenses, creates derivative works from, or offers for sale any content or
                        other information contained on or obtained from or through the Services, by any means except as
                        provided for in these Terms or with the prior written consent of Oversecured; or <br>
                        Attempts to do any of the foregoing. <br>
                        In addition, Users shall not, and shall not permit others to, do the following with respect to
                        the Services: <br>
                        Use the Services or allow access to them in a manner that circumvents contractual usage
                        restrictions or that exceeds authorized use or usage metrics set forth in these Terms; <br>
                        License, sub-license, sell, re-sell, rent, lease, transfer, distribute or time share or
                        otherwise make any portion of the Services available for access by third parties except as
                        otherwise expressly provided in these Terms; or <br>
                        Access or use the Services for the purpose of: (i) developing or operating products or services
                        intended to be offered to third parties in competition with the Services or (ii) allowing access
                        to your account by a direct competitor of Oversecured. <br>
                        You acknowledge that we have no obligation to monitor your – or anyone else’s – access to or use
                        of the Services for violations of these Terms, or to review or edit any content. However, we
                        have the right to do so for the purpose of operating and improving the Services (including
                        without limitation for fraud prevention, risk assessment, investigation and customer support
                        purposes, analytics, and advertising), to ensure your compliance with these Terms and to comply
                        with applicable law or the order or requirement of a court, consent decree, administrative
                        agency or other governmental body.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">LIMITATION OF LIABILITY</p>
                    <p class="content">No party to the Terms will be liable for any incidental, special, exemplary or
                        consequential damages, including lost profits, loss of data or goodwill, service interruption,
                        computer damage or system failure or the cost of substitute services arising out of or in
                        connection with the terms or from the use of or inability to use the Services, whether based on
                        warranty, contract, tort (including negligence), or any other legal theory, and whether or not
                        such party has been informed of the possibility of such damage. <br>
                        OUR TOTAL LIABILITY TO YOU FOR ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THESE TERMS OR
                        TO YOUR USE OF THE SERVICES (INCLUDING WITHOUT LIMITATION WARRANTY CLAIMS), REGARDLESS OF THE
                        FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT, TORT (INCLUDING
                        NEGLIGENCE), OR ANY OTHER LEGAL OR EQUITABLE THEORY, WILL NOT EXCEED THE TOTAL AMOUNT PAID BY
                        YOU TO OVERSECURED FOR THE SERVICE(S) GIVING RISE TO THE CLAIM UNDER THESE TERMS DURING THE
                        TWELVE (12) MONTH PERIOD PRECEDING THE DATE OF THE FIRST EVENT GIVING RISE TO LIABILITY, OR
                        $100, WHICHEVER IS GREATER. THE EXISTENCE OF MORE THAN ONE CLAIM SHALL NOT ENLARGE THIS LIMIT.
                        <br>
                        Some jurisdictions do not allow the exclusion or limitation of liability for consequential or
                        incidental damages, so the above limitation may not apply.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">DISPUTE RESOLUTION</p>
                    <p class="content">The Terms and any action related thereto will be governed by the laws of
                        California without regard to its conflict of laws provisions. Any and all disputes arising out
                        of or concerning the Terms shall be brought exclusively within the Superior Court for the County
                        of San Francisco or the United States District Court for the Northern District of California.
                        <br>
                        The User hereby submits to the personal jurisdiction of such courts and waives any and all
                        objections to the exercise of jurisdiction, venue or inconvenient forum in such courts. <br>
                        The parties understand that, absent this mandatory arbitration section, they would have the
                        right to sue in court and have a jury trial. They further understand that, in some instances,
                        the costs of arbitration could exceed the costs of litigation and the right to discovery may be
                        more limited in arbitration than in court. The parties further agree that the arbitration shall
                        be conducted in the party’s respective individual capacities only and not as a class action or
                        other representative action, and the parties expressly waive their right to file a class action
                        or seek relief on a class basis. YOU AND OVERSECURED AGREE THAT EACH MAY BRING CLAIMS AGAINST
                        THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                        PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If any court or arbitrator determines that the
                        class action waiver set forth in this paragraph is void or unenforceable for any reason or that
                        an arbitration can proceed on a class basis, then the arbitration provisions set forth above
                        shall be deemed null and void in their entirety and the parties shall be deemed to have not
                        agreed to arbitrate disputes.
                    </p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.terms__link {
    a.nav-link {
        &:hover {
            text-decoration: underline;
            user-select: auto;
        }
    }
}

.terms__link--blue {
    color: #1FA8C7;
}

.sign {
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: #242424;
    margin: 0;
}

.last-update,
.terms-subtitle,
.title,
.content {
    font-family: Cabin, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #706969;
    margin-top: 30px;
}

.terms-subtitle {
    line-height: 140%;
    color: #242424;
    margin-top: 85px;
}

.nav-item,
.nav-link {
    display: inline-block;
    list-style: none;
    padding: 0;
    color: #1FA8C7;

    &:hover {
        color: #1FA8C7;
    }
}

.title {
    color: #242424;
    line-height: 140%;
    margin: 40px 0 17px 0;
    font-weight: bold;
}

.content {
    line-height: 140%;
    color: #242424;
}

.paddings {
    padding: 100px 100px 78px;
}

@media (max-width: 991px) {
    .paddings {
        padding: 45px 50px 78px;
    }
}

@media (max-width: 376px) {
    .paddings {
        padding: 45px 20px 78px;
    }

    .sign {
        font-size: 30px;
    }

    .last-update,
    .terms-subtitle,
    .title,
    .content {
        font-size: 14px;
    }
}
</style>
