<template>
    <div class="markdown">
        <b-nav-item :to="{ path: `${makeLinkToAnchor('single-scans')}`}" class="md__link-wrapper">
            <!--            <p class="md__anchor">#</p>-->
            <h2 class="md__item-title">Single Scans</h2>
        </b-nav-item>
        <p>Single Scans are available to users with any account type. To use them, you need to add a
            card in your
            <b-nav-item class="link" :to="{ path: `/settings`}" href="https://oversecured.com/settings">profile
            </b-nav-item>. The price for scanning a single app is set out on the
            <b-nav-item class="link" :to="{ path: `/pricing`}" href="https://oversecured.com/settings">Pricing
            </b-nav-item>
            page.
        </p>
        <p>Users can only work via the API for paid scans. Trial scans can only be
            <b-nav-item class="link" :to="{ path: `/scan/create`}" href="https://oversecured.com/settings">carried out
            </b-nav-item>
            and
            <b-nav-item class="link" :to="{ path: `/scans`}" href="https://oversecured.com/settings">studied
            </b-nav-item>
            via the platform.
        </p>
        <h3 :id="makeAnchor('Getting a list of apps')"><a
            :href="makeSubLinkToAnchor('Getting a list of apps', 'single-scans')">Getting a list of apps</a></h3>
        <p><strong>Endpoint</strong></p>
        <ul>
            <li><code>GET /v1/single_scans/scans?limit={limit}&amp;page={page}</code></li>
        </ul>
        <p><strong>Input parameters</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>limit</td>
                <td>integer</td>
                <td>number of scans on one page</td>
            </tr>
            <tr>
                <td>page</td>
                <td>integer</td>
                <td>page number</td>
            </tr>
            </tbody>
        </table>
        <p><strong>JSON response</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>count</td>
                <td>integer</td>
                <td>total number of scans</td>
            </tr>
            <tr>
                <td>rows</td>
                <td>array</td>
                <td>array containing data about each scan on the current page</td>
            </tr>
            </tbody>
        </table>
        <p><strong>Scan data</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>id</td>
                <td>string(uuid)</td>
                <td>scan ID</td>
            </tr>
            <tr>
                <td>file_name</td>
                <td>string</td>
                <td>original name of the app file passed by the user</td>
            </tr>
            <tr>
                <td>platform</td>
                <td>string</td>
                <td>mobile platform name</td>
            </tr>
            <tr>
                <td>app_id</td>
                <td>string</td>
                <td>app ID, for Android it will be a package value</td>
            </tr>
            <tr>
                <td>version_id</td>
                <td>string</td>
                <td>name of the app version</td>
            </tr>
            <tr>
                <td>status</td>
                <td>string</td>
                <td>scan status</td>
            </tr>
            <tr>
                <td>price</td>
                <td>object</td>
                <td>price charged for scanning one app</td>
            </tr>
            <tr>
                <td>shared</td>
                <td>boolean</td>
                <td>value defining whether or not the report is publicly accessible</td>
            </tr>
            <tr>
                <td>created_at</td>
                <td>string</td>
                <td>date and time when the file was submitted for scanning</td>
            </tr>
            </tbody>
        </table>
        <p><strong>Using curl</strong></p>
        <pre class="  language-metadata"><code class="  language-metadata">curl 'https://api.oversecured.com/v1/single_scans/scans?limit=10&amp;page=1' \
  -H 'Authorization: {api_token}'
</code></pre>
        <p><strong>Example response</strong></p>
        <pre class="  language-json"><code class="  language-json"><span
            class="token punctuation">{</span>
  <span class="token property">"count"</span><span class="token operator">:</span> <span
                class="token number">1</span><span class="token punctuation">,</span>
  <span class="token property">"rows"</span><span class="token operator">:</span> <span
                class="token punctuation">[</span><span class="token punctuation">{</span>
    <span class="token property">"id"</span><span class="token operator">:</span> <span class="token string">"0da00f1b-f2db-4e42-8787-6fdd357bbf62"</span><span
                class="token punctuation">,</span>
    <span class="token property">"file_name"</span><span class="token operator">:</span> <span class="token string">"ovaa_1.0.apk"</span><span
                class="token punctuation">,</span>
    <span class="token property">"platform"</span><span class="token operator">:</span> <span class="token string">"android"</span><span
                class="token punctuation">,</span>
    <span class="token property">"app_id"</span><span class="token operator">:</span> <span class="token string">"oversecured.ovaa"</span><span
                class="token punctuation">,</span>
    <span class="token property">"version_id"</span><span class="token operator">:</span> <span class="token string">"1.0"</span><span
                class="token punctuation">,</span>
    <span class="token property">"status"</span><span class="token operator">:</span> <span class="token string">"FINISH"</span><span
                class="token punctuation">,</span>
    <span class="token property">"price"</span><span class="token operator">:</span> <span
                class="token punctuation">{</span>
      <span class="token property">"currency"</span><span class="token operator">:</span> <span class="token string">"USD"</span><span
                class="token punctuation">,</span>
      <span class="token property">"amount"</span><span class="token operator">:</span> <span
                class="token number">249</span>
    <span class="token punctuation">}</span><span class="token punctuation">,</span>
    <span class="token property">"shared"</span><span class="token operator">:</span> <span
                class="token boolean">true</span><span class="token punctuation">,</span>
    <span class="token property">"created_at"</span><span class="token operator">:</span> <span class="token string">"2020-11-03T01:24:48.520Z"</span>
  <span class="token punctuation">}</span><span class="token punctuation">]</span>
<span class="token punctuation">}</span>
</code></pre>
        <h3 :id="makeAnchor('Deleting a scan report')"><a
            :href="makeSubLinkToAnchor('Deleting a scan report', 'single-scans')">Deleting a scan report</a></h3>
        <p><strong>Endpoint</strong></p>
        <ul>
            <li><code>DELETE /v1/single_scans/{scan_id}</code></li>
        </ul>
        <p><strong>Input parameters</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>scan_id</td>
                <td>string(uuid)</td>
                <td>scan ID</td>
            </tr>
            </tbody>
        </table>
        <p><strong>Using curl</strong></p>
        <pre class="  language-metadata"><code class="  language-metadata">curl https://api.oversecured.com/v1/single_scans/{scan_id} \
  -X DELETE -H 'Authorization: {api_token}'
</code></pre>
        <h3 :id="makeAnchor('Making a report public')"><a
            :href="makeSubLinkToAnchor('Making a report public', 'single-scans')">Making a report public</a></h3>
        <p>This endpoint is used to set whether or not any user, including unauthenticated users, can
            obtain read-only access to the report. A third-party user can obtain access if the user
            shares the report ID or a link with them.</p>
        <p><strong>Endpoint</strong></p>
        <ul>
            <li><code>POST /v1/single_scans/{scan_id}/shared/set</code></li>
        </ul>
        <p><strong>Input parameters</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>value</td>
                <td>boolean</td>
                <td>value defining whether or not the report is public</td>
            </tr>
            </tbody>
        </table>
        <p><strong>Using curl</strong></p>
        <pre class="  language-metadata"><code class="  language-metadata">curl https://api.oversecured.com/v1/single_scans/{scan_id}/shared/set \
  -d '{"value": true}' \
  -X POST -H 'Authorization: {api_token}'
</code></pre>
        <h3 :id="makeAnchor('Submitting apps to be scanned using a card')"><a
            :href="makeSubLinkToAnchor('Submitting apps to be scanned using a card', 'single-scans')">Submitting apps to be
            scanned using a card</a>
        </h3>
        <p>Use Single Scans and pay with a card attached to your account. The price will be calculated using the rates provided on the
            <b-nav-item class="link" :to="{ path: `/pricing`}" href="https://oversecured.com/settings">pricing</b-nav-item>
            page.
        </p>
        <p><strong>Endpoint</strong></p>
        <ul>
            <li><code>POST /v1/single_scans/new_scan</code></li>
        </ul>
        <p><strong>Input parameters</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>files</td>
                <td>array</td>
                <td>array containing data on each file submitted to be scanned</td>
            </tr>
            <tr>
                <td>card_id</td>
                <td>string</td>
                <td>ID of the card used for payment.
                    <b-nav-item
                        :to="{ path: `${makeLinkToAnchor('profile-and-categories')}#${makeAnchor('Obtaining a list of cards')}`}">
                        See more
                    </b-nav-item>
                </td>
            </tr>
            <tr>
                <td>notify_when_done</td>
                <td>boolean</td>
                <td>value defining whether or not to notify the user when each scan is complete</td>
            </tr>
            </tbody>
        </table>
        <p><strong>Using curl</strong></p>
        <pre class="  language-metadata"><code class="  language-metadata">curl 'https://api.oversecured.com/v1/single_scans/new_scan' \
  -d '
    {
      "files": [{
          "platform": "android",
          "file_name": "ovaa_1.0.apk",
          "bucket_key": "f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/0395ed14-d5ea-4c9e-944d-5d0af425c658/9024d2e3-430e-4126-8445-f2ee0f36b507.apk"
        }],
      "card_id": "card_1HuIasAfmNl9bAedIWiBxKK3",
      "notify_when_done": true
    }
  ' \
  -H "Content-Type: application/json" -X POST \
  -v -H 'Authorization: {api_token}'
</code></pre>
        <p><strong>Response codes</strong></p>
        <ul>
            <li><code>200</code> if the scans of all files were successfully initiated</li>
            <li><code>500</code> if payment was declined</li>
        </ul>
        <p><strong>Upload steps</strong></p>
        <ol>
            <li>Select an app to scan</li>
            <li>Select a card to use for payment</li>
            <li>
                <b-nav-item class="link" :to="{ path: `${makeLinkToAnchor('uploading')}#${makeAnchor('Uploading apps')}`}">Submit a request
                </b-nav-item>
                to obtain a signed link and bucket key for each app
            </li>
            <li><a href="https://docs.aws.amazon.com/AmazonS3/latest/dev/PresignedUrlUploadObject.html">Upload
                the files to the URL you receive</a></li>
            <li>Pass the <code>bucket_key</code> values in this request</li>
        </ol>

        <h3 :id="makeAnchor('Submitting apps to be scanned when you have available scans')"><a
            :href="makeSubLinkToAnchor('Submitting apps to be scanned when you have available scans', 'single-scans')">Submitting apps to be scanned when you have available scans</a>
        </h3>
        <p>If you have activated a code or
            <b-nav-item class="link" :to="{ path: `/settings/scan-packs`}" href="https://oversecured.com/settings">purchased</b-nav-item>
            a scan pack previously, you should prefer this option.
        </p>
        <p><strong>Endpoint</strong></p>
        <ul>
            <li><code>POST /v1/single_scans/new_scan_via_pack</code></li>
        </ul>
        <p><strong>Input parameters</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>files</td>
                <td>array</td>
                <td>array containing data on each file submitted to be scanned</td>
            </tr>
            <tr>
                <td>notify_when_done</td>
                <td>boolean</td>
                <td>value defining whether or not to notify the user when each scan is complete</td>
            </tr>
            </tbody>
        </table>
        <p><strong>Using curl</strong></p>
        <pre class="  language-metadata"><code class="  language-metadata">curl 'https://api.oversecured.com/v1/single_scans/new_scan_via_pack' \
  -d '
    {
      "files": [{
          "platform": "android",
          "file_name": "ovaa_1.0.apk",
          "bucket_key": "f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/0395ed14-d5ea-4c9e-944d-5d0af425c658/9024d2e3-430e-4126-8445-f2ee0f36b507.apk"
        }],
      "notify_when_done": true
    }
  ' \
  -H "Content-Type: application/json" -X POST \
  -v -H 'Authorization: {api_token}'
</code></pre>
        <p><strong>Response codes</strong></p>
        <ul>
            <li><code>200</code> if the scans of all files were successfully initiated</li>
            <li><code>500</code> if your account does not have enough available scans to process the request</li>
        </ul>
        <p><strong>Upload steps</strong></p>
        <ol>
            <li>Select an app to scan</li>
            <li>  <b-nav-item class="link" :to="{ path: `/settings/scan-packs`}" href="https://oversecured.com/settings">Make sure</b-nav-item>
                your account has enough available scans</li>
            <li>
                <b-nav-item class="link" :to="{ path: `${makeLinkToAnchor('uploading')}#${makeAnchor('Uploading apps')}`}">Submit a request
                </b-nav-item>
                to obtain a signed link and bucket key for each app
            </li>
            <li><a href="https://docs.aws.amazon.com/AmazonS3/latest/dev/PresignedUrlUploadObject.html">Upload
                the files to the URL you receive</a></li>
            <li>Pass the <code>bucket_key</code> values in this request</li>
        </ol>

        <h3 :id="makeAnchor('Reading the vulnerability report')"><a
            :href="makeSubLinkToAnchor('Reading the vulnerability report', 'single-scans')">Reading the vulnerability
            report</a></h3>
        <p>Operations to work with the scan report are in the
            <b-nav-item class="link" :to="{ path: `${makeLinkToAnchor('vulnerabilities')}`}">corresponding section
            </b-nav-item>
            and include:
        </p>
        <ol>
            <li>
                <b-nav-item
                    :to="{ path: `${makeLinkToAnchor('vulnerabilities')}#${makeAnchor('General report statistics')}`}">
                    Reading the overall statistics from the report
                </b-nav-item>
            </li>
            <li>
                <b-nav-item
                    :to="{ path: `${makeLinkToAnchor('vulnerabilities')}#${makeAnchor('Listing of vulnerabilities')}`}">
                    Reading the vulnerability listing
                </b-nav-item>
            </li>
            <li>
                <b-nav-item
                    :to="{ path: `${makeLinkToAnchor('vulnerabilities')}#${makeAnchor('Moving vulnerabilities between Folders')}`}">
                    Moving vulnerabilities between Folders
                </b-nav-item>
            </li>
        </ol>
    </div>

</template>

<script>
import {makeAnchor, makeLinkToAnchor, makeSubLinkToAnchor} from "@/services/functions";

export default {
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor,
    }
}
</script>

<style scoped>

</style>
