<template>
    <div class="dropzone-page__scans-amount">
        <label
            v-if="prepaid"
            class="dropzone-page__scans-amount-prepaid"
            for="use-prepaid"
        >
            <div class="dropzone-page__scans-amount-prepaid-text">
                Use Prepaid Scans
            </div>
            <div class="dropzone-page__scans-amount-prepaid-left">
                <div>{{ prepaid }} left</div>
                <input
                    id="use-prepaid"
                    v-model="onUsePrepaid"
                    type="checkbox"
                    name="use-prepaid"
                >
                <span class="checkmark" />
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: 'PrepaidBlock',
    props: {
        prepaid: {
            type: Number,
            default: 0,
        },
        usePrepaid: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            onUsePrepaid: this.usePrepaid,
        };
    },
    watch: {
        onUsePrepaid(newValue) {
            this.$emit('selected', newValue);
        },
        usePrepaid(newVal) {
            this.onUsePrepaid = newVal;
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/css/variables";

.dropzone-page__scans-amount {
    display: flex;
    gap: 20px;
    & label {
        margin: 0;
    }
    &-prepaid {
        display: flex;
        align-items: center;
        width: 50%;
        height: 75px;
        justify-content: space-between;
        padding: 0 30px;
        border-radius: 20px;
        background-color: #fff;
    }
    &-prepaid {
        border: 2px solid $border-orange-color;
        font-weight: 650;
        font-size: 22px;
        transition: all .3s;
        input {
            position: absolute;
            opacity: 0;
        }
        &-left {
            position: relative;
            display: flex;
            gap: 15px;
            align-items: center;
            font-size: 36px;
            white-space: nowrap;
            .checkmark {
                @include checkbox-square("orange");
            }
        }
        &:has(input:checked) {
            border-color: $new-orange-color;
            box-shadow: $box-shadow-orange;
            &:hover {
                border-color: $border-orange-color;
            }
        }
        &:hover {
            cursor: pointer;
            border: 2px solid $new-orange-color;
            box-shadow: $box-shadow-orange;
            .checkmark {
                @include checkbox-square-hover("orange");
            }
            & input:checked ~ .checkmark {
                @include checkbox-square-checked-hover("orange");
            }
        }

        & input:checked ~ .checkmark:after {
            display: block;
        }
    }
}
@media (max-width: 1500px) {
    .dropzone-page__scans-amount {
        &-prepaid {
            font-size: 18px;
            &-left {
                font-size: 22px;
            }
        }
    }
}
@media (max-width: 900px) {
    .dropzone-page__scans-amount {
        &-prepaid {
            font-size: 18px;
            &-left {
                font-size: 22px;
            }
        }
    }
}
@media (max-width: 800px) {
    .dropzone-page__scans-amount {
        flex-direction: column-reverse;
        &-prepaid {
            width: 100%;
            height: 75px;
        }
    }
}
</style>
