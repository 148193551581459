<template>
    <div
        v-if="compareByCategories"
        class="table"
    >
        <div
            v-if="refreshLoading"
            class="spinner"
        >
            <Spinner />
        </div>

        <div class="table-headers">
            <div class="table-headers__count" />
            <div class="table-headers__category">
                Category
            </div>
            <div class="table-headers__level">
                {{ screenWidth > 500 ? 'Level' : "" }}
            </div>
            <div class="table-headers__changes">
                Changes
            </div>
        </div>
        <CategoriesCompareItem
            v-for="(item, index) in compareByCategories"
            :key="index"
            :index="index"
            :item="item"
            :screen-width="screenWidth"
            :clickable="isCategoryClickable(item.type, item.severity)"
            @scroll-to-category="scrollToCategory"
        />
    </div>
</template>
<script>
import Spinner from '../../components/Spinner';
import CategoriesCompareItem from './categoriesCompareItem';

export default {
    components: {
        Spinner,
        CategoriesCompareItem,
    },
    props: ['currentJob', 'screenWidth', 'compareByCategories', 'refreshLoading', 'isTrialRestriction'],
    data() {
        return {
            isShowAddInfoToggle: false,
        };
    },
    methods: {
        determineDotColorBySeverityLvl: (lvl) => {
            if (lvl === 'low') {
                return 'green-dot';
            } else if (lvl === 'medium') {
                return 'yellow-dot';
            } else {
                return 'red-dot';
            }
        },
        setSeverityLvl: (lvl) => {
            if (lvl === 'low') {
                return 'Low';
            } else if (lvl === 'medium') {
                return 'Medium';
            } else {
                return 'High';
            }
        },
        toggleInfo() {
            this.isShowAddInfoToggle = !this.isShowAddInfoToggle;
        },
        scrollToCategory(type){
            // if Trial we don't have access to high severity
            if (this.isTrialRestriction) {
                let typeFilter = this.compareByCategories.filter(item => item.severity !== 'high' && item.severity !== 'medium');
                let typeFilterArray = typeFilter.map(item => item.type);

                // so if the chosen category is not included in our filter we don't scroll
                if (!typeFilterArray.includes(type)) {
                    return false;
                // if it is included we scroll to it
                } else {
                    this.$emit('scroll-to-category', type);
                }
            // if not Trial we just scroll to any chosen category
            } else {
                this.$emit('scroll-to-category', type);
            }

        },
        isCategoryClickable(type, severity){
            // here we check all available types for click if it has Trial Restriction
            if (this.isTrialRestriction) {
                let severityFilter = ['medium', 'low'];
                let typeFilter = this.compareByCategories.filter(item => item.severity !== 'high' && item.severity !== 'medium');

                let typeFilterArray = typeFilter.map(item => item.type);

                return typeFilterArray.includes(type)
                    && severityFilter.includes(severity);
            } else {
                return this.compareByCategories.map(item => item.type.includes(type))
                    && this.compareByCategories.map(item => item.severity.includes(severity));
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

.table {
    position: relative;
    background: #ffffff;
    border: 1px solid #E3E6ED;
    box-sizing: border-box;
    box-shadow: 0 4px 15px rgba(102, 124, 131, 0.06);
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 20px;


    &-headers {
        @include Aspekta-font(500, 14px, normal, #949DB8);
        letter-spacing: 0.42px;
        display: flex;
        align-items: center;
        height: 86px;

        border-bottom: 1px solid #f0f0f0;;
        &__count {
            float: left;
            width: 8%;
        }

        &__category {
            float: left;
            width: 54%;
        }

        &__level {
            float: left;
            width: 19%;
            padding-left: 20px;
        }

        &__changes {
            float: left;
            width: 18%;
            padding-left: 10px;
        }

    }
}

.spinner {
    position: absolute;
    background: rgba(256, 256, 256, 0.5);
    width: 100%;
    height: 100%;
    z-index: 10;
    border-radius: 20px;
}

@media(max-width: 515px) {

    .table-headers{

        &__changes{
            width : 25%;
        }
    }
}

</style>
