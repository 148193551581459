<template>
    <b-container>
        <div class="sign-in">
            <h1 class="sign-in__title">
                Sign In
            </h1>
            <form
                novalidate
                class="sign-in__form"
                @submit.prevent="handleForm"
            >
                <div class="sign-in__input-wrapper">
                    <img
                        v-if="isEmailValid && Object.keys(authErrors).length === 0
                            && !isLoading && inputData.email.length > 0"
                        class="sign-in__email-check"
                        src="../../assets/images/greenCheck.svg"
                        alt=""
                    >
                    <img
                        v-if="isEmailValid === false
                            && inputData.email.length > 0"
                        class="sign-in__email-check"
                        src="../../assets/images/cross.svg"
                        alt=""
                        @click="clearInputAndErrors(['email'])"
                    >
                    <input
                        id="email"
                        v-model.trim="inputData.email"
                        class="input"
                        name="email"
                        type="email"
                        @input="handleInput"
                        @change="handleInput"
                        @keyup.delete="handleInput"
                        @blur="activeInputName = null"
                    >
                    <label
                        for="email"
                        class="label"
                        :class="{ activeInput: inputData.email !== isEmptyInput || activeInputName === 'email'}"
                    >
                        Email
                    </label>
                    <span
                        id="emailError"
                        class="sign-in__error"
                    >
                        <img
                            class="sign-in__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors(['email'])"
                        >
                        <span class="sign-in__error-text">Required field</span>
                    </span>
                </div>
                <div class="sign-in__input-wrapper">
                    <img
                        id="passwordShow"
                        class="sign-in__toggle-show-password icon"
                        src="../../assets/images/myScans/icon_action_hide.svg"
                        alt=""
                        @click="toggleShowPassword"
                    >
                    <img
                        id="passwordHide"
                        class="sign-in__toggle-show-password sign-in__toggle-show-password--bigger icon"
                        src="../../assets/images/myScans/icon_action_show.svg"
                        alt=""
                        @click="toggleShowPassword"
                    >
                    <span
                        id="authErrors"
                        class="sign-in__error"
                    >
                        <img
                            class="sign-in__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors(['email','password'])"
                        >
                        <span class="sign-in__input-error">
                            {{ authErrors.credentials || authErrors.dbConnecting }}
                        </span>
                    </span>
                    <input
                        id="password"
                        v-model.trim="inputData.password"
                        class="input"
                        name="password"
                        type="password"
                        @input="checkKeyEvent"
                        @blur="activeInputName = null"
                    >
                    <label
                        for="password"
                        class="label"
                        :class="{ activeInput: inputData.password !== isEmptyInput || activeInputName === 'password'}"
                    >
                        Password
                    </label>
                    <span
                        id="passwordError"
                        class="sign-in__error"
                    >
                        <span class="sign-in__error-text">Required field</span>
                    </span>
                </div>
                <div v-if="isOtpEnabled" class="sign-in__otp-warning">Please wait for your authentication app to
                    generate a verification code,
                    and then enter the code to continue.
                </div>
                <div v-if="isOtpEnabled" class="sign-in__input-wrapper">
                    <img
                        v-if="isOtpValid && Object.keys(authErrors).length === 0
                            && !isLoading && inputData.otp.length > 0"
                        class="sign-in__otp-check"
                        src="../../assets/images/greenCheck.svg"
                        alt=""
                    >
                    <img
                        v-if="isOtpValid === false && inputData.otp.length > 0"
                        class="sign-in__otp-check"
                        src="../../assets/images/cross.svg"
                        alt="cross"
                        @click="clearInputAndErrors(['otp'])"
                    >
                    <span
                        id="authOtpErrors"
                        class="sign-in__error"
                    >
                        <img
                            class="sign-in__error-cross-otp"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors(['otp'])"
                        >
                        <span class="sign-in__input-error">
                            {{ authErrors.otp }}
                        </span>
                    </span>
                    <input
                        id="otp"
                        v-model.trim="inputData.otp"
                        class="input"
                        name="otp"
                        type="text"
                        @input="handleInput"
                        @change="handleInput"
                        @keyup.delete="handleInput"
                        @blur="activeInputName = null"
                    >
                    <label
                        for="otp"
                        class="label"
                        :class="{ activeInput: inputData.otp !== isEmptyInput || activeInputName === 'otp'}"
                    >
                        Secure Verification Code
                    </label>
                    <span
                        id="otpError"
                        class="sign-in__error"
                    >
                        <img
                            class="sign-in__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors(['otp'])"
                        >
                        <span class="sign-in__error-text">Required field</span>
                    </span>
                </div>
                <div class="sign-in__forgot-password-wrapper">
                    <b-nav-item
                        to="/forgot-password"
                        class="sign-in__text--link"
                    >
                        Forgot Password?
                    </b-nav-item>
                </div>
                <div class="sign-in__toggle-wrapper">
                    <b-form-checkbox
                        id="rememberForTwoWeeks"
                        v-model="rememberForTwoWeeks"
                        name="rememberForTwoWeeks"
                        value="true"
                        unchecked-value="false"
                    >
                        Remember me for 2 weeks
                    </b-form-checkbox>

                    <button class="sign-in__btn-submit">
                        Sign In
                    </button>
                </div>
                <div class="sign-in__link-to-login-wrapper">
                    <b-nav-item
                        to="/sign-up/company"
                        class="sign-in__link "
                    >
                        Create an Oversecured Account
                    </b-nav-item>
                </div>
            </form>
            <div
                v-if="isLoading"
                class="sign-in__spinner"
            >
                <Spinner/>
            </div>
        </div>
    </b-container>
</template>
<script>
import {
    checkFormInputsForCompleteness,
    checkFormInputsForErrors,
    setItemsToLocalStorage,
    toggleShowPassword,
    validateEmail,
    hideErrors, validateOtp,
} from '@/services/functions';
import Spinner from '@/components/Spinner';
import {mapGetters} from 'vuex';
import {store} from '@/store';
import {routeUserToPageByTypeAfterLogin, redirectUserToZendeskAfterLogin} from '@/services/authorization/routeUser';
import {nextTick} from 'process';

const errorIds = ['emailError', 'emailValidationError', 'authErrors', 'authOtpErrors', 'passwordError', 'otpError'];
export default {
    components: {
        Spinner,
    },
    data() {
        return {
            inputData: {
                email: '',
                password: '',
                otp: ''
            },
            rememberForTwoWeeks: false,
            isEmailValid: null,
            isOtpValid: null,
            isLoading: false,
            isOtpEnabled: false,
            zendeskData: null,
            isEmptyInput: '',
            activeInputName: null,
            border: '1px solid #F0F0F0',
        };
    },
    computed: {
        ...mapGetters({
            authErrors: 'authentication/errors',
            userType: 'user/userType',
            isGetStartedCompleted: 'user/isGetStartedCompleted',
        }),
    },
    mounted() {
        if (this.$router.currentRoute.query) {
            this.zendeskData = this.$router.currentRoute.query;
        }
    },
    methods: {
        clearErrors() {
            hideErrors(errorIds);
            store.commit('authentication/clearErrors');
            document.getElementById('password').style.border = this.border;
            document.getElementById('email').style.border = this.border;
            let otpElem = document.getElementById('otp');
            if (otpElem) {
                otpElem.style.border = this.border;
            }
        },
        clearInputAndErrors(dataIds) {
            if (Array.isArray(dataIds) && dataIds.length > 0) {
                dataIds.forEach((id) => {
                    this.inputData[ id ] = '';
                });
            }
            this.clearErrors();
        },
        async handleForm() {
            this.clearErrors();

            const loginData = {
                email: this.inputData.email,
                password: this.inputData.password,
                otp: this.inputData.otp,
                remember_for_two_weeks: JSON.parse(this.rememberForTwoWeeks),
            };
            checkFormInputsForErrors(this.inputData, 'sign-in__input--error');

            if (this.isOtpEnabled) {
                if (checkFormInputsForCompleteness(this.inputData)) {
                    await this.sendAuthRequest(loginData);
                }
            } else {
                if (checkFormInputsForCompleteness({email: this.inputData.email})) {
                    await this.sendOtpCheckRequest(loginData);
                }
            }
        },
        isAuthError() {
            return this.authErrors && Object.keys(this.authErrors).length !== 0;
        },
        async sendOtpCheckRequest(loginData) {
            this.isLoading = true;
            const otpCheckResponse = await this.$auth.optCheck(loginData.email);
            if (otpCheckResponse && 'otp' in otpCheckResponse.data) {
                if (otpCheckResponse.data.otp === true) {
                    this.isOtpEnabled = true;
                } else if (otpCheckResponse.data.otp === false) {
                    await this.sendAuthRequest(loginData);
                } else {
                    console.log('OTP status is not boolean');
                }
            } else {
                console.log('Fail to get OTP status');
            }
            this.isLoading = false;
        },
        async sendAuthRequest(loginData) {
            this.isLoading = true;
            await this.$auth.login(loginData);
            if (!this.isAuthError()) {
                gtag('event', 'login', {method: 'oversecured'});
                // if (typeof otag === 'function') otag({event: 'LOGIN', email: loginData.email});
                otag({event: 'LOGIN', email: loginData.email});
                await this.$store.dispatch('user/fetchUserInfo');
                setItemsToLocalStorage([['userType', this.userType]]);
                if (this.canRedirectToZendesk()) {
                    await redirectUserToZendeskAfterLogin();
                } else {
                    await routeUserToPageByTypeAfterLogin(this.userType, this.isGetStartedCompleted, this.$route.query.redirect);
                }
            } else if (this.isAuthError() && this.authErrors.link) {
                await this.$router.push({
                    name: 'ConfirmEmailLink',
                    params: {email: this.inputData.email},
                });
            }
            this.isLoading = false;
        },
        canRedirectToZendesk() {
            return this.zendeskData && this.zendeskData.brand_id;
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id;
        },
        handleInput(e) {
            this.checkKeyEvent(e);
            this.isEmailValid = validateEmail(this.inputData.email);
            this.isOtpValid = validateOtp(this.inputData.otp);

            this.clearErrors();
        },
        toggleShowPassword,
    },
};
</script>

<style lang="scss">
@import "../../assets/css/fonts";

.sign-in {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: calc(120vh - 71px - 192px);
    min-height: 550px;

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    &__title {
        @include Aspekta-font(700, 60px, normal, #242424);
        font-size: calc(31px + (60 - 31) * calc((100vw - 320px) / (1920 - 320)));
        letter-spacing: 0em;
        margin: 36px 0;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 395px;
        margin-bottom: 76px;
    }

    &__label {
        width: 100%;
        margin-bottom: 15px;
        position: relative;
        font-family: Cabin;
        font-style: normal;
        font-size: 12px;
        line-height: 150%;
        color: #B2B9C4;

        &:nth-child(2) {
            margin-bottom: 10px;
        }
    }

    &__email-valid {
        position: absolute;
        top: 45px;
        right: 20px;
    }

    &__toggle-show-password {
        position: absolute;
        right: 11px;
        top: 6px;
        width: 30px;
        cursor: pointer;
    }

    &__otp-valid {
        position: absolute;
        top: 45px;
        right: 20px;
    }

    &__input {
        &--error {
            border: 1px solid #FB8D62;
            border-radius: 5px;
        }
    }

    &__input-wrapper {
        position: relative;
        width: 100%;

        .icon {
            margin: 4px;
            width: 22px;
            height: 22px;
        }
    }

    &__otp-warning {
        margin-bottom: 25px;
        text-align: center;
    }

    &__input-error {
        position: absolute;
        padding-top: 13%;
        right: 0;
        font-family: Cabin, serif;
        font-weight: normal;
        font-size: 12px;
        color: #FB8D62;
    }

    &__email-check {
        position: absolute;
        top: 16px;
        right: 20px;
    }

    &__otp-check {
        position: absolute;
        top: 16px;
        right: 20px;
    }

    &__toggle-wrapper {
        @include Aspekta-font(500, 14px, normal, #706969);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0 63px;

        label.custom-control-label {
            padding-top: 2px;
        }

        .custom-control-label::after {
            background-color: #EBF2FD;
            border-radius: 4px;
            border: 1px solid #3180F6;
        }

        .custom-control-input:checked ~ .custom-control-label::after {
            background-color: #3180F6;
        }
    }

    &__link {
        display: inline-block;
        @include Aspekta-font(700, 18px, normal, transparent);
        font-weight: normal;
        align-items: center;

        a {
            color: #FFB400;
            font-size: 18px !important;

            &:hover {
                text-decoration: underline;
                color: #FF7A08;
            }
        }
    }

    &__link-to-login-wrapper {
        display: flex;
        align-items: center;
    }

    &__forgot-password-wrapper {
        margin-top: 6%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__text {
        font-weight: 500;
        font-size: 18px;
        color: #706969;

        &--link {
            a {
                @include Aspekta-font(500, 14px, normal, transparent);
                font-size: 14px !important;
                margin: 0 !important;

                &:hover {
                    color: #FF7A08;
                    text-decoration: underline
                }
            }
        }
    }

    &__btn-submit {
        @include Aspekta-font(600, 18px, normal, #FFFFFF);
        background: #FF7A08;
        border: 2px solid #FF7A08;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 18px;
        color: #FFFFFF;
        padding: 10px 40px;
        transition: 0.3s;

        &:focus {
            border: 2px solid #FF7A08;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &__error {
        width: 100%;
        max-width: 831px;
        position: relative;
    }

    &__error-cross {
        position: absolute;
        top: -56px;
        right: 20px;
    }

    &__error-cross-otp {
        position: absolute;
        top: 16px;
        right: 20px;
    }

    &__error-text {
        @include Aspekta-font(500, 10px, 150%, #FB8D62);
        position: absolute;
        top: -30px;
        left: 3px;
        margin: 0;
    }

    &__email-check {
        cursor: pointer;
        top: 18px;
        right: 20px;
    }
}

#passwordError,
#emailError,
#otpError,
#authErrors,
#authOtpErrors {
    display: none;
}

@media (max-width: 1440px) {
    .sign-in {
        height: calc(100vh - 71px - 251px);
    }
}

@media (max-width: 768px) {
    .sign-in {
        padding: 0 20px;

        &__title {
            margin: 30px 0;
        }

        &__form {
            margin-bottom: 3px;
        }

        &__link--orange {
            a {
                font-size: 16px !important;
            }
        }

        &__toggle-wrapper {
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 23px;
        }

        &__btn-submit {
            padding: 10px 0;
            width: 100%;
            margin: 15px 0 28px 0;
        }
    }
}

@media (max-width: 594px) {
    .sign-in {
        height: calc(100vh - 71px - 331px);
    }
}
</style>
