<template>
    <div class="back page-content padding_cont">
        <div class="settings-header">
            <div class="settings-header__wrapper">
                <div id="history">
                    <div class="settings-header__title raleway raleway-bold">
                        Settings
                    </div>
                </div>
            </div>
            <div
                class="link"
                @click="logout"
            >
                Logout
            </div>
        </div>
        <div class="main-contant-block">
            <ul class="nav-side-bar hacker-settings">
                <li
                    v-if="integrationsHistory && displayBtnIfNotOnlySampleOrDeleted()"
                    class="nav-side-bar__link"
                >
                    <router-link
                        class="nav-side-bar__link"
                        active-class="nav-side-bar__link--active"
                        :to="{ path: `${makeLinkToAnchorSettings('integration-history')}` }"
                    >
                        Integration History
                    </router-link>
                </li>
                <li class="nav-side-bar__link">
                    <router-link
                        active-class="nav-side-bar__link--active"
                        class="nav-side-bar__link"
                        :to="{ path: `${makeLinkToAnchorSettings('scan-history')}` }"
                    >
                        Scan History
                    </router-link>
                </li>
                <li class="nav-side-bar__link">
                    <router-link
                        active-class="nav-side-bar__link--active"
                        class="nav-side-bar__link"
                        :to="{ path: `${makeLinkToAnchorSettings('scan-packs')}` }"
                    >
                        Scan Pack History
                    </router-link>
                </li>
                <li class="nav-side-bar__link">
                    <router-link
                        active-class="nav-side-bar__link--active"
                        class="nav-side-bar__link"
                        :to="{ path: `${makeLinkToAnchorSettings('sessions')}` }"
                    >
                        Sessions
                    </router-link>
                </li>
                <li class="nav-side-bar__link">
                    <router-link
                        active-class="nav-side-bar__link--active"
                        class="nav-side-bar__link"
                        :to="{ path: `${makeLinkToAnchorSettings('cards')}` }"
                    >
                        Cards
                    </router-link>
                </li>
                <li class="nav-side-bar__link">
                    <router-link
                        active-class="nav-side-bar__link--active"
                        class="nav-side-bar__link"
                        :to="{ name: 'Payment History' , params: { to: 'single scans' }}"
                    >
                        Payment History
                    </router-link>
                </li>
                <li class="nav-side-bar__link">
                    <router-link
                        active-class="nav-side-bar__link--active"
                        class="nav-side-bar__link"
                        :to="{ path: `${makeLinkToAnchorSettings('api-keys')}`}"
                    >
                        API
                    </router-link>
                </li>
                <li class="nav-side-bar__link">
                    <router-link
                        active-class="nav-side-bar__link--active"
                        class="nav-side-bar__link"
                        :to="{ path: `${makeLinkToAnchorSettings('security')}` }"
                    >
                        Security
                    </router-link>
                </li>
            </ul>
            <router-view />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { makeLinkToAnchorSettings } from '../../services/functions';
const STRIPE_PUBLIC_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
export default {
    name: 'Settings',
    data() {
        return {
            loaded: false,
            isNewCard: false,
            elements: null,
            isActiveBlock: {
                isScansHistory: true,
                isSessions: false,
                isCardsBlock: false,
                isAPIKeys: false,
            },
        };
    },
    computed: {
        ...mapGetters({
            jobsHistory: 'jobs/jobsHistory',
            cards: 'stripe/cards',
            countJobsHistory: 'jobs/countJobsHistory',
            userType: 'user/userType',
            integrationsHistory: 'integrations/historyScans',
        }),
    },
    async beforeMount() {
        this.stripe = Stripe(STRIPE_PUBLIC_KEY);
        this.elements = this.stripe.elements();
        await this.$store.dispatch('integrations/loadIntegrationHistoryScans', { limit: 5, page: 1 });
        await this.$store.dispatch('jobs/loadJobsHistory');
        await this.$store.dispatch('stripe/fetchCards');
    },
    methods: {
        makeLinkToAnchorSettings,
        displayBtnIfNotOnlySampleOrDeleted() {
            const statusToDisplayButton = [
                'ACTIVE',
                'RENEWAL_REQUESTED',
                'TRIAL_REQUESTED',
                'UNDER_REVIEW',
                'EXPIRED',
                'REJECTED',
            ];
            const checkIfNotOnlySampleOrDeleted = this.integrationsHistory.rows.filter(
                integration => statusToDisplayButton.includes(integration.status),
            );
            return checkIfNotOnlySampleOrDeleted.length > 0 ? true : false;
        },
        logout() {
            this.$auth.logout();
            localStorage.removeItem('userType');
        },
    },
};
</script>
<style lang="scss">
@import "../../assets/css/variables";

.settings-header {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    margin: 0 7.5%;
    &__wrapper {
        display: flex;
        align-items: flex-start;
    }
    &__title {
        font-size: 30px;
        line-height: 150%;
        color: #242424;
    }
    &__text {
        font-size: 18px;
        line-height: 150%;
        color: #242424;
        margin-top: 40px;
    }
    .switch-settings-page {
        margin-top: 10px;
    }
}

.main-contant-block{
    display: flex;
    margin: 40px 7.5%;
}
.nav-side-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    min-width: 146px;
    margin-right: 40px;
    margin-bottom: 0;
    & li {
        list-style: none;
    }

    &__link {
        text-decoration: none;
        font-family: Cabin;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.5;
        color: #B2B9C3;
        background-color: unset;
        &:not(:last-child) {
            margin-bottom: 30px;
        }
        &:hover {
            color: initial;
            text-decoration: none;
        }

        &--active {
            color: #242424;
        }
    }
}

.settings-link {
    margin: 16px 0 0 40px;
    max-width: 240px;
    width: 100%;
    height: 24px;
    a.nav-link {
        color: #3180F6;
        font-size: 18px;
        &:hover {
            text-decoration: underline;
        }
    }
}
.spinner__history-wrapper {
    width: 100%;
    height: 125%;
    top: 25%;
    left: 0;
    position: absolute;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.7);
}
.spinner__history {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.back {
    background-color: #FAFBFC;
}
.StripeElement--webkit-autofill {
    background-color: transparent !important;
}
.svg__card-wrapper {
    max-width: 28px;
}
.svg__card {
    width: 130%;
    height: 130%;
}
.svg__card-icon {
    max-width: 35px;
    margin-right: -7px;
    width: 35px;
}
.title_position {
    padding-top: 50px;
    margin-bottom: 20px;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    color: #242424;
    width: 100%;
    height: 30%;
    & .title {
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 150%;
    }
    & .history {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
    }
}
.link {
    font-family: Cabin, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #3180F6;
    cursor: pointer;
    margin-top: 10px;
    &:hover {
        text-decoration: underline
    }
}
.padding_cont {
    padding-bottom: 30px;
}
.stripe_card {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
}
.text_title {
    font-family: Cabin, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    margin: 0 0 10px 0;
}
.list-card {
    width: 500px;
    margin-right: 85px;
    margin-top: 50px;
    &--block {
        width: 100%;
        display: flex;
        align-items: center;
        height: 82px;
        margin-bottom: 2px;
        background: #FFFFFF;
        box-shadow: 0 4px 15px rgba(56, 70, 77, 0.04);
        &:first-child {
            border-radius: 20px 20px 0 0;
        }
        &:last-child {
            border-radius: 0 0 20px 20px;
        }
        &:only-child {
            border-radius: 20px;
        }
    }
    &__button-delete {
        visibility: hidden;
    }
    &--block:hover #delete-card-btn {
        visibility: visible;
    }
    &__image {
        margin: 30px;
    }
    &__code_card {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        margin: 10px;
        color: #242424;
        width: 62%;
    }
    &__button-delete {
        position: relative;
        .delete-report-btn-title {
            @extend .btn-title;
            visibility: visible;
        }
    }
}
.text_title {
    border-radius: 20px;
}
.new-card {
    width: 325px;
    &__title {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        color: #242424;
        margin-bottom: 22px;
    }
    &--block {
        width: 325px;
        margin: auto auto 20px;
        .card {
            width: 350px;
        }
    }
    &--add-card {
        background: #242424;
        border: 2px solid #242424;
        box-sizing: border-box;
        border-radius: 8px;
        align-items: center;
        text-align: center;
        height: 46px;
        width: 100%;
        &__button {
            font-family: Cabin, serif;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 40px;
            color: #FFFFFF;
            cursor: pointer;
            background: #242424;
            border: 2px solid #242424;
            box-sizing: border-box;
            border-radius: 8px;
            align-items: center;
            text-align: center;
            width: 100%;
        }
        &:hover {
            box-shadow: 0 0 20px grey;
        }
        &:active {
            background: grey;
            transition: 0.2s;
        }
    }
}
.spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: rgba(256, 256, 256, .3);
    z-index: 2000;
}
.hide-loader {
    position: absolute;
    visibility: hidden;
}
.add-card-message {
    font-family: Cabin, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: right;
    color: #FB8D62;
}
button:focus {
    border: 0
}
#scan-history-table thead tr th{
    border: none !important;
}
#scan-history-table tbody tr td:nth-child(1) {
    font-size: 14px;
    line-height: 140%;
    color: #3180F6;
}

#scan-history-table thead tr th:nth-child(6),
#scan-history-table tbody tr td:nth-child(6),
#scan-history-table thead tr th:nth-child(7),
#scan-history-table tbody tr td:nth-child(8) {
    width: 60px !important;
}
#scan-history-table thead tr th:nth-child(2) {
    display: flex;
    padding: 12px 0;
}
#scan-history-table tbody tr td:nth-child(2) {
    padding-right: 10px !important;
    padding-left: 0;
}
@media (max-width: 1200px) {
    .list-card {
        width: 350px;
        margin-right: 40px;
    }
    .table thead th {
        vertical-align: top;
    }
}
@media (max-width: 990px) {
    .settings-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px 20px 0 20px;
        &__wrapper {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
        &__title {
            font-size: 30px;
            line-height: 150%;
            color: #242424
        }
        &__text {
            font-size: 18px;
            line-height: 150%;
            color: #242424;
            margin-top: 20px;
        }
        .switch-settings-page {
            margin-top: 10px;
        }
    }
    .main-contant-block{
        flex-direction: column;
        margin-top: 35px;
        & ul {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .nav-side-bar {
        flex-direction: row;
        justify-content: center;
        white-space: pre;
        overflow-x: scroll;
        width: 91%;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 20px;
        padding-bottom: 8px;
        &__link {
            &:not(:last-child) {
                margin-right: 30px;
                margin-bottom: 0;
            }
        }
    }

    #scan-history-table thead tr th:nth-child(2),
    #scan-history-table thead tr th:nth-child(3),
    #scan-history-table thead tr th:nth-child(4),
    #scan-history-table thead tr th:nth-child(5),
    #scan-history-table thead tr th:nth-child(7),
    #scan-history-table thead tr th:nth-child(8) {
        display: none;
    }
    #scan-history-table thead tr th:nth-child(6) {
        text-align: left;
        padding-left: 20px;
    }
    #scan-history-table tbody tr td:nth-child(2) {
        padding-left: 20px !important;
    }
    #scan-history-table thead tr th:nth-child(2) {
        padding-left: 20px !important;
        text-align: left;
    }

    #scan-history-table thead tr th:nth-child(6),
    #scan-history-table thead tr th:nth-child(2) {
        display: table-cell;
        border: 0 !important;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: #B2B9C4;
    }

    #scan-history-table thead tr  {
        height: 60px;
        padding-right: 25px;
        width: 100% !important;
    }

    .history__table {
        .small-permission__package,
        .small-permission__version,
        .small-permission__updatedAt,
        .small-permission__price,
        .small-permission__shared {
            display: none !important;
        }
        #scan-history-table tbody tr {
            min-height: 100px !important;
            height: 50px !important;
        }
    }
    .status-text {
        text-align: right;
        padding-right: 20px;
    }
    .stripe_card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #FAFBFC;
        margin-top: 0;
        padding: 0;
        position: relative;
    }
    .card {
        max-width: 325px;
        width: 100%;
        margin: 30px 0 0 0;
    }
    .container {
        padding: 0;
    }
    .title_position {
        justify-content: center;
        flex-direction: column;
        padding: 20px 20px 0;
    }
    .settings-link {
        margin: 16px 0 0 0;
        max-width: 210px;
        width: 100%;
        height: 24px;
        a.nav-link {
            font-size: 16px !important;
            color: #3180F6;
        }
    }
    .history {
        margin-top: 20px;
    }
    .list-card {
        width: 100%;
        margin-top: 0;
        margin-right: 0;
        .text_title {
            margin: 0 0 10px 35px;
        }
        &--block {
            position: relative;
            box-shadow: 0 0 0;
            display: flex;
            justify-content: space-between;
            width: 100% !important;
            border-radius: 0 !important;
            padding: 0 35px;
        }
        &__image {
            margin: 0;
        }
        &__button-delete {
            visibility: visible;
        }
    }
    .new-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 315px !important;
        margin-left: 0;
        &--block {
            width: 325px;
            .card {
                width: 325px;
            }
        }
        &__title {
            display: none;
        }
        &--add-card {
            margin-top: 20px;
        }
    }
}
@media (max-width: 768px) {
    .main-contant-block ul {
        margin-left: auto;
        margin-right: auto;
    }
    .nav-side-bar {
        justify-content: unset;
        &__btn {
            &:not(:last-child) {
                margin-right: 25px;
            }
        }
    }
    .hacker-settings {
        justify-content: center;
    }
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #FFB400;
    background-color: #FFB400;
}
</style>
