<template>
    <div>
        <!-- in viewBox 3rd argument is width 4th argument is height  -->
        <svg :fill="fill" :height="height" viewBox="0 0 22 16" :width="width" xmlns="http://www.w3.org/2000/svg">
            <g clip-rule="evenodd" :fill="fill" fill-rule="evenodd">
                <path d="m15.5 9c1.5856 0 2.9249 1.0543 3.3551 2.5002l2.1449-.0002c.5523 0 1 .4477 1 1s-.4477 1-1 1l-2.1452.0008c-.4305 1.4454-1.7696 2.4992-3.3548 2.4992s-2.9243-1.0538-3.3548-2.4992l-11.1452-.0008c-.552285 0-1-.4477-1-1s.447715-1 1-1l11.1449.0002c.4302-1.4459 1.7695-2.5002 3.3551-2.5002zm0 1.75c-.9665 0-1.75.7835-1.75 1.75s.7835 1.75 1.75 1.75 1.75-.7835 1.75-1.75-.7835-1.75-1.75-1.75zm-8-10.75c1.58557 0 2.9249 1.05433 3.3551 2.50016l10.1449-.00016c.5523 0 1 .44772 1 1s-.4477 1-1 1l-10.1452.00084c-.4305 1.44531-1.7696 2.49916-3.3548 2.49916s-2.92427-1.05385-3.35481-2.49916l-3.14519-.00084c-.552285 0-1-.44772-1-1s.447715-1 1-1l3.14489.00016c.43022-1.44583 1.76954-2.50016 3.35511-2.50016zm0 1.75c-.9665 0-1.75.7835-1.75 1.75s.7835 1.75 1.75 1.75 1.75-.7835 1.75-1.75-.7835-1.75-1.75-1.75z"/>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    name: "FolderSettingsIcon",
    props: ["svgColor", "svgHeight", "svgWidth"],
    data() {
        return {
            fill: this.svgColor,
            width: this.svgWidth,
            height: this.svgHeight
        }
    },
    methods: {
        setFill(value) {
            this.fill = value;
        }
    }
};
</script>