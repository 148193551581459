<template>
    <div class="integration-members">
        <div
            v-if="integrationMembersList.length"
            class="integration-members__table__wrapper"
        >
            <div class="integration-members__list-block">
                <button
                    v-if="integrationUserType === 'owner' && !isShowSpinner"
                    v-b-modal.general-popupInvite
                    :disabled="isInviteButtonDisabled()"
                    :class="`integration-settings__invite-btn${isInviteButtonDisabled() ? '-disable' : ''} cabin cabin-bold`"
                >
                    Invite Member
                </button>
                <span
                    v-if="integrationUserType === 'owner' && !isShowSpinner"
                    class="integration-settings__invite-btn-tooltip"
                >
                    Seats left: {{ invitesLeft() }}
                </span>

                <!-- Desktop table -->
                <b-table
                    id="integration-members-table"
                    hover
                    :items="integrationMembersList"
                    :fields="memberFields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :thead-class="`table-header table-header__settings`"
                >
                    <template #cell(index)="data">
                        {{ (data.index + 1) + ((currentPage - 1) * perPage) }}
                    </template>
                    <template #cell(full_name)="data">
                        <span>{{ data.item.userName }}</span>
                    </template>
                    <template #cell(email)="data">
                        {{ data.item.email }}
                    </template>
                    <template #cell(status)="data">
                        <div :class="`integration-members__style-${data.item.status.toLowerCase()}`">
                            {{ userStatus(data.item.status) }}
                        </div>
                    </template>
                    <template #cell(delete_btn)="data">
                        <div v-if="data.item.status !== 'OWNER' && integrationUserType === 'owner'">
                            <button
                                class="integration-members__remove-btn cabin"
                                @click="openRemoveModal(data.item)"
                            >
                                Remove
                            </button>
                        </div>
                        <div v-if="data.item.id === integrationInvitedId">
                            <button
                                class="integration-members__remove-btn cabin"
                                @click="openRemoveModal(data.item)"
                            >
                                Leave
                            </button>
                        </div>
                    </template>
                </b-table>

                <!-- Mobile table -->
                <b-table
                    id="integration-members-table-adaptive"
                    hover
                    :items="integrationMembersList"
                    :fields="memberFields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :thead-class="'table-header table-header__settings'"
                >
                    <template #cell(full_name)="data">
                        <div :class="`${data.item.status}-StatusColorPointer`" />
                        <div class="num-index_adaptive">
                            {{ (data.index + 1) + ((currentPage - 1) * perPage) }}
                        </div>
                        <div>{{ data.item.userName }}</div>
                        <button v-b-toggle="'details' + data.item.id">
                            <img
                                src="../../assets/images/open_accardion.svg"
                                alt="open btn"
                            >
                        </button>
                    </template>
                    <template #cell(status)="data">
                        <div :class="`integration-members__style-${data.item.status.toLowerCase()}`">
                            <div id="status">
                                {{ userStatus(data.item.status) }}
                            </div>
                        </div>
                    </template>
                    <template #cell(email)="data">
                        <b-collapse
                            :id="'details' + data.item.id"
                            accordion="my-group"
                            aria-hidden="true"
                        >
                            <div id="email">
                                {{ data.item.email }}
                            </div>
                        </b-collapse>
                    </template>
                    <template #cell(delete_btn)="data">
                        <b-collapse
                            :id="'details' + data.item.id"
                            accordion="my-group"
                        >
                            <div
                                v-if="data.item.status !== 'OWNER' && integrationUserType !== 'member'"
                                id="remove-btn"
                            >
                                <button
                                    class="integration-members__remove-btn cabin"
                                    @click="openRemoveModal(data.item)"
                                >
                                    Remove
                                </button>
                            </div>
                            <div
                                v-if="data.item.id === integrationInvitedId"
                                id="leave-btn"
                            >
                                <button
                                    class="integration-members__remove-btn cabin"
                                    @click="openRemoveModal(data.item)"
                                >
                                    Leave
                                </button>
                            </div>
                        </b-collapse>
                    </template>
                </b-table>
                <Pagination
                    class="integration-members__pagination"
                    :count="integrationMembersCount"
                    :limit="perPage"
                    :current-page="currentPage"
                    :show-spinner="isShowSpinner"
                    @pageChanged="updatePage"
                />
            </div>
        </div>

        <GeneralPopup
            :popup-id="'Invite'"
            :title-text="'Invite new member'"
            :loading="isShowSpinnerModal"
            @onHidden="closeModal"
        >
            <form
                class="integration-members__modal-form"
                @submit.prevent="handleInviteMemberModal"
            >
                <div class="integration-members__form-input-wrapper">
                    <input
                        id="inviteEmail"
                        v-model.trim="inviteEmail"
                        class="input"
                        type="email"
                        name="email"
                        @blur="activeInputName = null"
                        @input="handleInput"
                        @change="handleInput"
                        @keyup.delete="handleInput"
                    >
                    <img
                        v-if="isEmailValid && Object.keys(error).length === 0 && inviteEmail.length > 0
                            && checkInputForErrors('inviteEmailError')
                            && checkInputForErrors('inviteEmailValidationError')
                        "
                        class="integration-members__email-check"
                        src="../../assets/images/greenCheck.svg"
                        alt=""
                    >
                    <img
                        v-if="!isEmailValid && inviteEmail.length > 0"
                        class="integration-members__email-check"
                        src="../../assets/images/cross.svg"
                        alt=""
                        @click.stop="clearInputAndErrors('inviteEmail')"
                    >
                    <label
                        for="inviteEmail"
                        class="label"
                        :class="{ activeInput: inviteEmail !== isEmptyInput || activeInputName === 'Email'}"
                    >
                        Email
                    </label>
                    <span
                        id="inviteEmailError"
                        class="integration-members__error"
                    >
                        <img
                            class="integration-members__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('inviteEmail')"
                        >
                        <span class="integration-members__error-text">Required field</span>
                    </span>
                    <span
                        id="inviteEmailValidationError"
                        class="integration-members__error"
                    >
                        <img
                            class="integration-members__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('inviteEmail')"
                        >
                        <span class="integration-members__error-text">Email is not valid</span>
                    </span>
                    <span
                        id="inviteEmailStatusError"
                        class="integration-members__error"
                    >
                        <img
                            class="integration-members__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('inviteEmail')"
                        >
                        <span class="integration-members__error-text">{{ error.inviteEmailStatusError ? error.inviteEmailStatusError : 'Something went wrong' }}</span>
                    </span>
                </div>
            </form>
            <template v-slot:ok-button>
                <popup-button
                    :button-text="'Send Invite'"
                    @buttonClick="handleInviteMemberModal"
                />
            </template>
        </GeneralPopup>

        <GeneralPopup
            :title-text="integrationUserType !== 'member' ? 'Remove member from Integration?' : 'Leave the Integration?'"
            :loading="isShowSpinnerModal"
            :popup-id="'Remove'"
            @onHidden="closeModal"
        >
            <div class="integration-members__generate-modal-remove">
                <div
                    v-if="integrationUserType !== 'member'"
                    class="integration-members__modal-text"
                >
                    Member <span class="integration-members__modal-text-black shorten-315px">{{ removalUserData.userName }}</span>
                    will be removed from the Integration. You can add them back anytime.
                </div>
            </div>
            <template v-slot:ok-button>
                <popup-button
                    :button-text="integrationUserType !== 'member' ? 'Remove Member' : 'Leave'"
                    @buttonClick="deleteMember(removalUserData)"
                />
            </template>
        </GeneralPopup>
        <div
            v-if="isShowSpinner"
            class="integration-members-table__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import Spinner from '../Spinner';
import ClickOutside from 'vue-click-outside';
import { IntegrationInvitesStatusEnum } from '../../utils/constants/common';
import GeneralPopup from '../popups/GeneralPopup.vue';
import PopupButton from '../popups/PopupButton.vue';
import { mapGetters } from 'vuex';
import { store } from '@/store';
import {
    validateEmail,
    validateString,
    checkInputForErrors,
    raiseError,
    hideErrors,
} from '@/services/functions';
import { memberNotification, successNotification } from '@/services/notificationService/notificationService';
import Pagination from '../Pagination.vue';

export default {
    name: 'IntegrationMembers',
    components: {
        GeneralPopup,
        Spinner,
        PopupButton,
        Pagination,
    },
    directives: {
        ClickOutside,
    },
    data() {
        return {
            isShowSpinner: false,
            isShowSpinnerModal: false,
            isBtnDisabled: false,
            isEmailValid: null,
            isEmptyInput: '',
            activeInputName: null,
            removalUserData: {
                id: '',
                userName: '',
                status: '',
            },
            inviteEmail: '',
            memberFields: [
                {
                    key: 'index',
                    label: '',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-5', 'left'],
                    formatter: 'checkAndUpdatePlatformText',
                },
                {
                    key: 'full_name',
                    label: 'Full Name',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-20', 'left'],
                },
                {
                    key: 'status',
                    label: 'Role',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-15', 'left'],
                },
                {
                    key: 'email',
                    label: 'Email',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-25', 'left'],
                },
                {
                    key: 'delete_btn',
                    label: '',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-25', 'center'],
                },
            ],
            currentPage: 1,
            perPage: 10,
        };
    },
    computed: {
        ...mapGetters({
            integration: 'integrations/integration',
            integrationMembersList: 'integrations/integrationMembersList',
            integrationMembersCount: 'integrations/integrationMembersCount',
            integrationUserType: 'integrations/integrationUserType',
            integrationInvitedId: 'integrations/integrationInvitedId',
            error: 'integrations/errors',
        }),
    },
    async beforeMount() {
        this.isShowSpinner = true;
        await this.$store.dispatch('integrations/getIntegration', { id: this.$route.params.id });
        await this.$store.dispatch('integrations/loadIntegrationMembers', { id: this.$route.params.id });
        this.isShowSpinner = false;
    },
    methods: {
        checkInputForErrors,
        clearInputAndErrors(inputField) {
            this.inviteEmail = '';
            this.isEmailValid = null;
            store.commit('integrations/clearErrors');
            hideErrors(['inviteEmailError', 'inviteEmailValidationError', 'inviteEmailStatusError']);
        },
        invitesLeft(){
            // +1 is owner
            // seats number is the number of available seats only for members
            let invitesNumber = (this.integration.seats + 1) - this.integrationMembersCount;
            return invitesNumber > 0 ? invitesNumber : 0;
        },
        updatePage(page) {
            this.currentPage = page;
        },
        isInviteButtonDisabled() {
            return this.isBtnDisabled || this.isShowSpinner || this.invitesLeft() <= 0;
        },
        openRemoveModal(data) {
            this.isBtnDisabled = true;
            this.$bvModal.show('general-popupRemove');
            this.removalUserData.id = data.id;
            this.removalUserData.userName = data.userName ? data.userName : data.email;
            this.removalUserData.status = data.status;
        },
        closeModal() {
            this.$bvModal.hide('general-popupInvite');
            this.$bvModal.hide('general-popupRemove');
            this.isBtnDisabled = false;
            this.removalUserData.id = '';
            this.removalUserData.userName = '';
            this.removalUserData.status = '';
            this.inviteEmail = '';
        },
        userStatus(status) {
            switch (status) {
                case IntegrationInvitesStatusEnum.ACCEPTED:
                    return 'Member';
                case IntegrationInvitesStatusEnum.INVITE_SENT:
                    return 'Invited';
                case IntegrationInvitesStatusEnum.DECLINED:
                    return 'Declined';
                default:
                    return 'Owner';
            }
        },
        async handleInviteMemberModal() {
            hideErrors(['inviteEmailError', 'inviteEmailValidationError', 'inviteEmailStatusError']);
            if(!validateString(this.inviteEmail)) {
                return raiseError('inviteEmail', 'inviteEmailError', 'integration-members__generate-modal-input__error');
            }
            if (!validateEmail(this.inviteEmail)) {
                return raiseError('inviteEmail', 'inviteEmailValidationError', 'integration-members__generate-modal-input__error');
            }

            this.$store.commit('integrations/clearErrors');
            await this.sendInviteEmail(this.inviteEmail);
        },
        async sendInviteEmail(email) {
            this.isShowSpinnerModal = true;

            const res = await this.$store.dispatch('integrations/sendInvite', {
                email,
                integrationId: this.$route.params.id,
            });

            if (res && res.status === 200) {
                await this.$store.dispatch('integrations/loadIntegrationMembers', { id: this.$route.params.id });
                this.closeModal();
                this.successNotification('memberSend');
            } else {
                //todo remove setTimeout and fix showing errors
                setTimeout(() => {
                    raiseError('inviteEmail', 'inviteEmailStatusError', 'integration-members__generate-modal-input__error');
                }, 0);
            }
            this.isShowSpinnerModal = false;
        },

        async deleteMember(data) {
            this.isShowSpinnerModal = true;
            const result = await this.$store.dispatch('integrations/deleteIntegrationMember', {
                id: data.id,
                integrationId: this.$route.params.id,
            });
            if (result.status === 204) {
                if (this.integrationUserType === 'member') {
                    this.$store.commit('integrations/setIsLeftIntegration', true);
                    this.$router.push('/integrations');
                } else {
                    await this.$store.dispatch('integrations/loadIntegrationMembers', { id: this.$route.params.id });
                    const { userName } = data;
                    this.closeModal();
                    this.memberNotification('leftUser', userName);
                }
            }
            this.isShowSpinnerModal = false;
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id;
        },
        handleInput(event) {
            this.checkKeyEvent(event);
            this.isEmailValid = validateEmail(this.inviteEmail);

            let inputField = event.target.id;
            hideErrors([`${inputField}Error`, `${inputField}ValidationError`]);
            store.commit('integrations/clearErrors');
        },
        memberNotification,
        successNotification,
        raiseError,
    },
};
</script>
<style lang="scss">

.alert-message {
    position: absolute;
}

[role=row] {
    &:hover .integration-members__remove-btn {
        opacity: 1;
        transition: 0.6s;
    }
}

@mixin modalForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;
}

@mixin button {
    background: #FFB400;
    right: 0;
    border: 2px solid #FFB400;
    box-sizing: border-box;
    border-radius: 8px;
    color: #FFFFFF;
    transition: 0.3s;
    padding: 10px 40px;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    &:active {
        opacity: 1;
    }

    &:focus {
        border: 2px solid #FFB400;
    }
}

@mixin spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,0.7);
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 10;
}

.integration-members {
    position: relative;
    min-height: 200px;
    width: 100%;

    &__style {
        &-accepted {
            color: #706969;
        }
        &-owner {
            color: #1FA8C7;
        }
        &-invite_sent {
            color: #FFB400;
        }
        &-declined {
            color: #FB8D62;
        }
    }

    &__form {
        display: flex;
        position: relative;
        width: 100%;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 40px;
    }

    &__form-input-wrapper {
        position: relative;
        width: 100%;
        .input {
            margin-bottom: 0px;
        }
    }

    &__list-block {
        position: relative;
        background: #FFFFFF;
        border-radius: 20px;
    }

    &__generate-modal-remove {
        @include modalForm;
        position: relative;
    }
    &__generate-modal-remove-success {
        @include modalForm;
    }

    &__modal-form {
        display: flex;
        justify-content: center;
    }

    &__modal-title {
        font-family: Cabin;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        color: #242424;
        margin: 10px 0 0 0;

        &-input {
            font-size: 14px;
            color: #B2B9C4;
            margin-top: 12px;
        }
    }
    &__modal-text {
        max-width: 400px;
        font-family: Cabin;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #706969;
        text-align: center;

        &-black {
            color: #242424;
        }
    }

    &__email-check {
        position: absolute;
        bottom: 15px;
        right: 20px;
        cursor: pointer;
        height: 11px;
    }

    &__input-error {
        position: absolute;
        bottom: 0;
        left: 0;
        font-family: Cabin, serif;
        font-weight: normal;
        font-size: 12px;
        color: #FB8D62;
    }

    &__invite-btn {
        @include button;
        margin: auto;
        line-height: 22px;
    }
    &__remove-btn {
        @include button;
        background-color: white;
        color: #FFB400;
        padding: 7px 27.5px;
        font-size: 18px;
        margin: auto;
        line-height: 22px;
        opacity: 0;
        &:hover {
            background-color: #FFB400;;
            color: white;
            opacity: 1;
        }

        &:active {
            opacity: 0.7;
        }
    }

    &__error {
        width: 100%;
        max-width: 832px;
        position: relative;
    }

    &__error-cross {
        position: absolute;
        top: -25px;
        right: 20px;
        cursor: pointer;
    }

    &__error-text {
        position: absolute;
        left: 10px;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 150%;
        color: #FB8D62;
    }

    &__invite_circle_img {
        display: flex;
        justify-content: center;
        height: 57px;
        margin-top: 10px;
    }

    &__pagination {
        padding: 0 0 12px 12px;
    }
}

.integration-settings__invite-btn {
    @include button;
    position: absolute;
    top: -90px;
    line-height: 22px;

    &-disable {
        @include button;
        position: absolute;
        top: -90px;
        line-height: 22px;
        background: #B2B9C3;
        border: 2px solid #B2B9C3;
        opacity: 0.6;
        cursor: default;
    }
}
.integration-settings__invite-btn-tooltip {
    position: absolute;
    right: 0;
    top: -40px;
    color: #706969
}

.integration-members-table__spinner {
    @include spinner;
    border-radius: 20px;
}

.integration-members-table__spinner-modal {
    @include spinner;
    position: absolute;
    align-content: center;
    border-radius: 10px;
    max-width: 382px;
    z-index: 100;
}
#integration-members-table thead tr th {
    border: none;
}
#integration-members-table th {
    text-align: left;
    vertical-align: middle;
}
#integration-members-table tr {
    height: 60px;
    width: 26%;
}
#integration-members-table tbody tr td:nth-child(1) {
    width: 6%;
    font-size: 17px;
    line-height: 140%;
    color: #B2B9C3;
    text-align: center;
}
#integration-members-table tbody tr td:nth-child(2) {
    font-size: 17px;
    color: #242424;
}

#integration-members-table thead tr th:nth-child(3) {
    width: 60px;
}
#integration-members-table tbody tr td:nth-child(4) {
    width: 14%;
}

#integration-members-table-adaptive,
#inviteEmailError,
#inviteEmailValidationError,
#inviteEmailStatusError {
    display: none;
}


@media (max-width: 990px) {

    @mixin rectangleStatusKeyProperties {
        position: absolute;
        height: 72%;
        left: 0;
        top: 18px;
    }

    .integration-settings__invite-btn {
        top: -80px;
        right: 50%;
        transform: translate(50%, 0);

        &-disable {
            top: -80px;
            right: 50%;
            transform: translate(50%, 0);
            cursor: default;
        }
    }

    .collapsed {
        display: flex;
        position: absolute;
        right: 32px;
        transition: all 0.5s;
    }

    .not-collapsed {
        display: flex;
        position: absolute;
        right: 32px;
        transition: all 0.5s;
        transform: rotate(0.5turn);
    }

    .num-index_adaptive {
        display: block;
        padding: 0 23px 0 29px;
        font-family: Cabin;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #706969;
    }

    .integration-members__table__wrapper {
        width: 100%;
        margin: 90px 0 0 0;

    }

    #integration-members-table,
    #integration-members-table-adaptive > thead,
    #integration-members-table-adaptive > tbody > tr > td:nth-child(1) {
        display: none;
    }

    #integration-members-table-adaptive {
        display: flex;

        & tbody {
            display: flex;
            flex-direction: column;
            width: inherit;

            & tr {
                border-bottom: 1px solid #F0F0F0;
            }

            & tr:hover {
                border-bottom: 1px solid #FFB400;
            }
        }

        & tr {
            border-bottom: 1px solid #f0f0f0;
            position: relative;

            & .OWNER-StatusColorPointer {
                @include rectangleStatusKeyProperties;
                border-left: 6px solid #A0E0EE;
            }
            & .ACCEPTED-StatusColorPointer {
                @include rectangleStatusKeyProperties;
                border-left: 6px solid #A0E0EE;
            }
        }

        & tr:hover {
            & .INVITE_SENT-StatusColorPointer {
                @include rectangleStatusKeyProperties;
                border-left: 6px solid #FFB400;
            }
            & .DECLINED-StatusColorPointer {
                @include rectangleStatusKeyProperties;
                border-left: 6px solid #FB8D62;
            }
        }

        & td.table-cell {
            padding: 0;
        }

        &.table-hover tbody tr:hover {
            border-radius: 20px 20px 0 0;
        }
    }

    #integration-members-table-adaptive tbody tr td:nth-child(2) {
        width: inherit;
        max-width: 80%;
        font-size: 18px;
        margin: 24px 0 6px 0;
        font-weight: bold;
        display: flex;
        flex-direction: row;
    }

    #integration-members-table-adaptive tbody tr td:nth-child(3),
    #integration-members-table-adaptive tbody tr td:nth-child(4),
    #integration-members-table-adaptive tbody tr td:nth-child(5) {
        width: inherit;
        max-width: 70%;
        margin-left: 56px;
        display: flex;
        flex-direction: column;
    }

    #status {
        margin: 6px 0 12px 0;
    }

    #email {
        margin: 0 0 30px 0;
    }

    #remove-btn, #leave-btn {
        margin: 0 0 36px 0;
    }
    .integration-members__remove-btn.cabin {
        margin: 0;
        display: flex;
    }
}
@media (max-width: 575px) {
    .integration-settings {
        &__invite-btn {
            padding: 10px 20px;

            &-disable {
                padding: 10px 20px;
                cursor: default;
            }

            &-tooltip {
                right: 15px;
                top: -30px;
            }
        }
    }

    .integration-members {
        &__pagination {
            justify-content: space-between;
            padding: 0 0 12px 0;
        }
    }

    @mixin shortenName {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .shorten-315px {
        @include shortenName;
        width: 315px;
    }
    .shorten-270px {
        @include shortenName;
        width: 270px;
    }
}

</style>
