<template>
    <div class="buttons__wrapper">
        <button 
            class="add-more-files"
            @click="onAddMore"
        >
            Add More Files
        </button>
        <button
            class="edit"
            @click="onEdit"
        >
            {{ isEditing ? 'Cancel' : 'Edit' }}
        </button>
    </div>
</template>

<script>
export default {
    name: 'AddMoreFilesButtons',
    data() {
        return {
            isEditing: false,
        };
    },
    methods: {
        onAddMore() {
            this.$emit('add-more');
        },
        onEdit() {
            this.isEditing = !this.isEditing;
            this.$emit('edit', this.isEditing);
        },
    },  
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables";

.buttons__wrapper {
    display: flex;
    width: 100%;
    gap: 20px;
    .add-more-files {
        color: $main-blue-color;
        width: 100%;
        border: 2px solid $border-blue-color;
        padding: 10px 20px;
        border-radius: 8px;
        font-size: 17px;
        font-weight: 600;
        transition: all .3s;
        &:hover {
            border-color: $main-blue-color;
            box-shadow: $box-shadow-blue;
        }
    }
    .edit {
        width: 120px;
        color: $main-blue-color;
        background-color: transparent;
    }
}

@media(max-width: 505px) {
    .buttons__wrapper{
        padding-left: 30px;
    }
}
</style>
