<template>
    <div class="companies-container">
        <div class="svg-image">
            <img 
                src="../images/companies/companies.svg"
                alt="card"
            >
        </div>
    </div>
</template>

<script>

export default {
    name: 'Companies',
    props: {
        disabled: {
            type: Boolean,
            require: true,
        },
    },
        
};
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.companies-container {
    display: flex;
    overflow: hidden; 
    white-space: nowrap;
}

.svg-image {
    display: inline-block;
    animation: scroll 500s linear infinite;
    }

/*.scrolling-content {
    width: fit-content;
    animation: scrollContainer 200s linear infinite;
}*/

@keyframes scroll {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-85%); 
    }
}


</style>


