<template>
    <div class="main-matters">
        <div class="main-matters__container">
            <div class="main-matters__content">
                <div class="main-matters__text">
                    <div class="main-matters__title">
                        <p>What Truly Matters to Our Clients</p>
                    </div>
                    <div class="main-matters__sub-title">
                        <p>Decoding <span class="orange-word">customers perspective</span></p>
                    </div>
                </div>
                <div
                    v-if="screenWidth > mobileVersionWidth"
                    class="main-matters__cards"
                >
                    <div
                        v-for="columnIndex in 3"
                        :key="columnIndex"
                        class="column"
                    >
                        <div
                            v-for="pointIndex in 2"
                            :key="pointIndex"
                            class="point-card"
                        >
                            <!--Calculation of the card index considering its position in 3 columns with 2 cards each-->
                            <TrulyMatterCard
                                :icon-src="getIconSrc(points[(columnIndex - 1) * 2 + pointIndex - 1].title)"
                                :icon-alt="points[(columnIndex - 1) * 2 + pointIndex - 1].title"
                                :title="points[(columnIndex - 1) * 2 + pointIndex - 1].title"
                                :description="points[(columnIndex - 1) * 2 + pointIndex - 1].description"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="main-matters__cards"
                >
                    <carousel
                        :per-page="1"
                        :mouse-drag="true"
                        :pagination-color="'rgba(114, 125, 141, 0.5)'"
                    >
                        <slide
                            v-for="point in points"
                            :key="point.title"
                        >
                            <TrulyMatterCard
                                :icon-src="getIconSrc(point.title)"
                                :icon-alt="point.title"
                                :title="point.title"
                                :description="point.description"
                            />
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TrulyMatterCard from './TrulyMatterCard.vue';
import bugIcon from '../../../assets/images/main/what-truly-matters/truly-bug-icon.svg';
import puzzleIcon from '../../../assets/images/main/what-truly-matters/truly-puzzle-icon.svg';
import timeIcon from '../../../assets/images/main/what-truly-matters/truly-time-icon.svg';
import chatIcon from '../../../assets/images/main/what-truly-matters/truly-chat-icon.svg';
import helpIcon from '../../../assets/images/main/what-truly-matters/truly-help-icon.svg';
import securityIcon from '../../../assets/images/main/what-truly-matters/truly-security-icon.svg';


export default {
    components: {
        TrulyMatterCard,
    },
    data() {
        return {
            screenWidth: window.innerWidth,
            mobileVersionWidth: 768,
            points: [
                {
                    title: 'Find Bugs Fast and Effectively',
                    description: 'Customers want a solution that can reliably and quickly find vulnerabilities before they can be exploited',
                },
                {
                    title: 'Easy to Use and Works with Their Systems',
                    description: 'The solution should be easy to get the hang of and play nice with everything else they\'re using',
                },
                {
                    title: 'Frequent Updates and True Alerts',
                    description: 'They need a solution that stays up-to-date with the latest threats and doesn\'t cry wolf with false alarms',
                },
                {
                    title: 'Solid Support and Someone to Answer for Issues',
                    description: 'They want help when needed, and someone who\'ll step up and take responsibility when things go wrong',
                },
                {
                    title: 'Help with Fixing Problems',
                    description: 'Once a problem is spotted, they appreciate advice on how to fix it',
                },
                {
                    title: 'A Bang for Their Buck',
                    description: 'Ultimately, they want something that works just well',
                },
            ],
            icons: {
                'Find Bugs Fast and Effectively': bugIcon,
                'Easy to Use and Works with Their Systems': puzzleIcon,
                'Frequent Updates and True Alerts': timeIcon,
                'Solid Support and Someone to Answer for Issues': chatIcon,
                'Help with Fixing Problems': helpIcon,
                'A Bang for Their Buck' : securityIcon,
            },
        };
    },

    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        handleResize() {
            this.screenWidth = window.innerWidth;
        },
        getIconSrc(title) {
            return this.icons[title] || null;
        },
    },
};


</script>

<style lang="scss" scoped>
@import "../../../assets/css/variables";
@import "../../../assets/css/variables/variablesForSuccessStories.scss";
@import "../../../assets/css/fonts";

    .main-matters {
        background-color: #FFF;
        display: flex;
        flex-direction: column;

        &__container {
            display: flex;
            flex-direction: column;
        }

        &__content {
            display: flex;
            flex-direction: column;
            margin: 7% 10% 1% 10%;;
        }

        &__text {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__cards {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 10%;
            gap: 8.8rem;
        }

        .column {
            display: flex;
            flex-direction: column;
            flex: 1 1 30%;
            gap: 4rem;
        }

        .point-card {
            height: calc(50% - 4.4rem);
        }

        &__title {
            position: relative;
            @include Aspekta-font(700, $dynamic-main-risks-title-font-size, normal, #242424);
            letter-spacing: 0em;
        }

        &__sub-title {
            @include Aspekta-font(400, $dynamic-truly-sub-title-font-size, normal, #797E86);
        }

        .orange-word {
            @include Aspekta-font(600, $dynamic-truly-sub-title-font-size, normal, $new-orange-color);
        }
    }

    @media (max-width: 1500px) and (min-width: 768px) {
        .main-matters {
            &__text {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    @media (max-width: 1100px) and (min-width: 768px) {
        .main-matters {
            &__cards {
                gap: 4rem;
            }
        }
    }

    @media (max-width: 768px) {
        .main-matters {
            &__content {
                margin: 5rem 0rem 5rem 1.25rem;;
            }

            &__title {
                line-height: 130%;
                margin-right: 1rem;
            }

            &__sub-title, .orange-word {
                letter-spacing: 0em;
            }

            &__cards {
                flex-direction: column;
                gap: 15px;
                margin-top: 3.3rem;
            }

            .point-card {
                height: 100%;
                border-radius: 24px;
                border: 1px solid #E8E7EF;
                padding: 11% 14% 7% 14%;
                margin-right: 15px;
            }

            .card-icon {
                height: 60px;
                width: 60px;
            }


            &__text {
                flex-direction: column;
                align-items: flex-start;
            }

            .column {
                gap: 15px;
            }
        }
    }    

    @media (max-width: 530px) and (min-width: 320px) {
        .main-matters {
            .point-card {
                padding: 11% 14% 5% 14%;
            }
        }
    }

</style>
