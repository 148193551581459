<template>
    <div 
        ref="listbox"
        class="listbox" 
        :value="value"
    >
        <span
            :id="`${name}-label`"
            :class="{ 'listbox__label--visually-hidden': !showLabel }"
            class="listbox__label"
        >
            {{ label }}
        </span>
        <div 
            v-click-outside="hideListbox"
            class="listbox__dropdown"
        >
            <button
                :id="`${name}-button-label`"
                ref="listboxButton"
                :aria-labelledby="`${name}-label ${name}-button-label`"
                type="button"
                aria-haspopup="listbox"
                class="listbox__button"
                @click="toggleListbox"
                @keydown="checkShow"
            >
                <img
                    class="branch-icon"
                    src="../../../assets/images/branch-icon-active-grey.svg"
                >
                {{ value ? selectedOptionLabel : emptyOptionLabel }}
                <img
                    class="dropdown__button-arrow"
                    src="../../../assets/images/folders/dropdown-arrow.png"
                >
            </button>
            <ul
                v-show="!listboxHidden"
                ref="listboxNode"
                :aria-labelledby="`${name}-label`"
                :aria-activedescendant="value"
                tabindex="-1"
                role="listbox"
                class="listbox__list"
                @keydown="checkKeyDown"
                @click="checkClickItem"
            >
                <li
                    v-for="(option, index) in options"
                    ref="listboxOptions"
                    :key="`${name}-option-${index}`"
                    :aria-selected="option.value === value"
                    :data-value="option.value"
                    class="listbox__option"
                    role="option"
                >
                    {{ option.label }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { KeyCodesEnum } from '../../../utils/constants/common';

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // if the click event was triggered for an element outside the target element
            if (!(el === event.target || el.contains(event.target))) {
                // trigger the event handler with event as an argument
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});

export default {
    name: 'DropDown',
    props: {
        value: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        emptyOptionLabel: {
            type: String,
            default: 'Select branch',
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            keyClear: null,
            keysSoFar: '',
            listboxHidden: true,
            searchIndex: null,
        };
    },
    computed: {
        selectedOptionLabel() {
            const selectedOption = this.options.find(option => option.value === this.value);
            return selectedOption && selectedOption.label;
        },
    },
    methods: {
        // Toggles Listbox based on this.listboxHidden
        toggleListbox() {
            this.listboxHidden ? this.showListbox() : this.hideListbox();
        },
        // Shows the ListBox list and puts its on focus
        showListbox() {
            this.listboxHidden = false;
            this.$refs.listboxButton.setAttribute('aria-expanded', 'true');
            this.$refs.listboxNode.focus();
        },
        // Hides the ListBox list
        hideListbox() {
            this.listboxHidden = true;
            this.$refs.listboxButton.setAttribute('aria-expanded', 'false');
        },
        /**
         * Keypress handler for the listbox button.
         * It shows the listbox list on up/down key press.
         */
        checkShow(event) {
            const key = event.code;
            switch (key) {
                case KeyCodesEnum.SPACE:
                case KeyCodesEnum.ENTER:
                    event.preventDefault();
                    this.showListbox();
                    this.checkKeyDown(event);
                    break;
                default:
                    break;
            }
        },
        /**
         * Handles various keyboard controls; UP/DOWN/HOME/END/PAGE_UP/PAGE_DOWN
         *
         * @param {Event} event The keydown event object
         */
        checkKeyDown(event) {
            const key = event.code;

            switch (key) {
                case KeyCodesEnum.UP:
                case KeyCodesEnum.DOWN: {
                    event.preventDefault();
                    const selectedItemIndex = this.options.findIndex(option => option.value === this.value);
                    let nextItem = selectedItemIndex ? this.$refs.listboxOptions[selectedItemIndex] : this.$refs.listboxOptions[0];

                    if (key === KeyCodesEnum.UP) {
                        // If there's an option above the selected one
                        if (selectedItemIndex - 1 >= 0) {
                            // Assign the previous option to nextItem
                            nextItem = this.$refs.listboxOptions[selectedItemIndex - 1];
                        }
                    } else {
                        // If there's an option below the selected one
                        if (selectedItemIndex + 1 <= this.options.length) {
                            nextItem = this.$refs.listboxOptions[selectedItemIndex + 1];
                        }
                    }

                    if (nextItem) {
                        this.focusItem(nextItem);
                    }

                    break;
                }
                case KeyCodesEnum.HOME:
                case KeyCodesEnum.PAGE_UP:
                    event.preventDefault();
                    this.focusFirstItem();
                    break;
                case KeyCodesEnum.END:
                case KeyCodesEnum.PAGE_DOWN:
                    event.preventDefault();
                    this.focusLastItem();
                    break;
                case KeyCodesEnum.RETURN:
                case KeyCodesEnum.ESC:
                    event.preventDefault();
                    this.hideListbox();
                    this.$refs.listboxButton.focus();
                    break;
                default: {
                    // If the user typed a set of characters,
                    // focus the option that matches those characters
                    const itemToFocus = this.findItemToFocus(key);
                    if (itemToFocus) {
                        this.focusItem(itemToFocus);
                    }
                    break;
                }
            }
        },
        /**
         *  Focus on the first option
         */
        focusFirstItem() {
            this.focusItem(this.$refs.listboxOptions[0]);
        },
        /**
         *  Focus on the last option
         */
        focusLastItem() {
            const lastListboxOption = this.$refs.listboxOptions[this.options.length - 1];
            this.focusItem(lastListboxOption);
        },
        /**
         * Select the option passed as the parameter.
         *
         * @param {Element} element - the option to select
         */
        focusItem(element) {
            // Defocus active element
            if (this.value) {
                const index = this.options.findIndex(option => option.value === this.value);
                const listboxOption = this.$refs.listboxOptions[index];
                this.defocusItem(listboxOption);
            }
            element.setAttribute('aria-selected', 'true');
            this.$refs.listboxNode.setAttribute(
                'aria-activedescendant',
                element.getAttribute('data-value'),
            );

            // Trigger the v-model "changeBranch" event with value equal to element.id
            this.$emit('changeBranch', element.getAttribute('data-value'));

            // Scroll up/down to show the listbox within the viewport
            if (this.$refs.listboxNode.scrollHeight > this.$refs.listboxNode.clientHeight) {
                const scrollBottom = this.$refs.listboxNode.clientHeight + this.$refs.listboxNode.scrollTop;
                const elementBottom = element.offsetTop + element.offsetHeight;

                if (elementBottom > scrollBottom) {
                    this.$refs.listboxNode.scrollTop = elementBottom - this.$refs.listboxNode.clientHeight;
                } else if (element.offsetTop < this.$refs.listboxNode.scrollTop) {
                    this.$refs.listboxNode.scrollTop = element.offsetTop;
                }
            }
        },
        /**
         * defocus on the element passed as a parameter.
         *
         * @param {Element} element
         */
        defocusItem(element) {
            if (!element) {
                return;
            }
            element.removeAttribute('aria-selected');
        },
        /**
         * Returns an option that its label matches the key or
         * null if none of the match the key entered.
         *
         * @param {String} key typed characters to check whether they match an option
         */
        findItemToFocus(key) {
            const character = String.fromCharCode(key);

            // If it's the first time the user is typing to find an option
            // set the search index to the active option
            if (!this.keysSoFar) {
                this.searchIndex = this.options.findIndex(option => option.value === this.value);
            }

            this.keysSoFar += character;
            this.clearKeysSoFarAfterDelay();

            // Find the next matching element starting from the search index
            // until the end of all the options
            let nextMatch = this.findMatchInOptions(
                this.searchIndex + 1,
                this.options.length,
            );

            // If there wasn't a match search for a match from the start of
            // all the options until the search index
            if (!nextMatch) {
                nextMatch = this.findMatchInOptions(0, this.searchIndex);
            }

            return nextMatch;
        },
        // Resets the keysSoFar after 500ms
        clearKeysSoFarAfterDelay() {
            if (this.keyClear) {
                clearTimeout(this.keyClear);
                this.keyClear = null;
            }
            this.keyClear = setTimeout(() => {
                this.keysSoFar = '';
                this.keyClear = null;
            }, 500);
        },
        /**
         * Returns an element that its label starts with keysSoFar
         * or null if none is found in the options.
         *
         * @param {String} keysSoFar
         */
        findMatchInOptions(startIndex, endIndex) {
            for (let i = startIndex; i < endIndex; i++) {
                if (
                    this.options[i].label &&
                    this.options[i].label
                        .toUpperCase()
                        .indexOf(this.keysSoFar) === 0 ) {
                    return this.$refs.listboxOptions[i];
                }
            }
            return null;
        },
        /**
         * On option click it focuses on the option.
         *
         * @param {Event} event
         */
        checkClickItem(event) {
            if (event.target.getAttribute('role') === 'option') {
                this.focusItem(event.target);
                this.hideListbox();
                this.$refs.listboxButton.focus();
            }
        },
    }
}
</script>

<style lang="scss">
@import "../../../assets/css/fonts.scss";

@mixin LongTextShorten {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.listbox {
    z-index: 100;
    &__label {
        font-size: 14px;
        font-family: Cabin, sans-serif;
        color: #706969;
    }

    &__dropdown {
        width: 252px;
    }

    &__list {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

[role="listbox"] {
    min-height: fit-content;
    padding: 0;
    background: white;
    border: 1px solid #E3E6ED;
}

.listbox__button[aria-selected="false"] {
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
}

[role="option"] {
    display: block;
    padding: 0px 0px 10px 0px;
    border-bottom: 1px solid #F0F1F3;
    margin: 12px 18px;
    @include Aspekta-font(400, 14px, normal, #5C6A7A);
    position: relative;
    @include LongTextShorten;

    &:hover {
        background-color: transparent;
        opacity: .5;
    }
}

[role="option"]:last-child {
    border-bottom: none;
    padding: 0;
}

[role="option"][aria-selected="true"]::before {
    content: "✔︎";
    color: #61D4B3;
    position: absolute;
    left: 14.5em;
}

button {
    font-size: 16px;

    &[aria-disabled="true"] {
        opacity: 0.5;
    }
}

.listbox__button {
    @include Aspekta-font(400, 14px, normal, #949DB8);
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left;
    border: 1px solid #E3E6ED;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px 18px;
    width: 252px;
    position: relative;
    @include LongTextShorten;

    .dropdown__button-arrow {
            margin-left: auto;
            width: 12px;
            height: 7px;
            transition: rotate .3s ease-in-out;
        }

    .branch-icon {
        margin-right: 8px;
    }

    &:focus {
        border-top: 1px solid #E3E6ED;
        border-right: 1px solid #E3E6ED;
        border-left: 1px solid #E3E6ED;
        border-bottom: 1px solid #E3E6ED;
        border-radius: 8px;

    }

    &[aria-expanded="false"]:focus {
        border-bottom: 1px solid #E3E6ED;
        border-radius: 8px;
    }

    &[aria-expanded="true"]:focus {
        .dropdown__button-arrow {
                rotate: 180deg;
                transition: rotate .3s ease-in-out;
            }
    }

    &[aria-expanded="true"]::after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 0;
        border-bottom: 4px solid #E3E6ED;

    }
}

.listbox__list {
    max-height: 20rem;
    overflow-y: auto;
    position: absolute;
    margin: 0;
    width: 252px;
    margin-top: 10px;
    border-radius: 8px;
}

.listbox__label {
    @include Aspekta-font(400, 12px, 150%, #949DB8);
    letter-spacing: 0.6px;
    padding-bottom: 3px;
    display: inline-block;
}

.listbox__label--visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
    background: black;
}
</style>
