<template>
    <div>
        <div
            class="table-row"
            :class="{info_open_row : isShowAddInfoToggle}"
        >
            <div
                v-if="screenWidth>500"
                class="table-row__index"
            >
                {{ index + 1 }}
            </div>
            <div
                v-else
                class="table-row__index"
            >
                <span
                    class="dot dot-for-large-permissions level_severity"
                    :class="determineDotColorBySeverityLvl(item.severity)"
                />
            </div>

            <div class="table-row__category">
                <span
                    :style=" clickable ? 'cursor: pointer;' : null"
                    @click="$emit('scroll-to-category', item.type)"
                >
                    {{ item.type }}
                </span>

                <div
                    class="info__btn"
                    :class="{ info__btn__show : isShowAddInfoToggle}"
                    @click.stop="toggleInfo()"
                >
                    <img
                        class="table__link"
                        :src="circleInfo"
                        alt=""
                    >
                    <img
                        class="table__link__arrow"
                        alt=""
                        :src="arrowInfo"
                    >
                </div>
            </div>
            <div
                v-if="screenWidth>500"
                class="table-row__level"
            >
                <span
                    class="dot dot-for-large-permissions level_severity"
                    :class="determineDotColorBySeverityLvl(item.severity)"
                />
                {{ setSeverityLvl(item.severity) }}
            </div>

            <div
                v-if="item.changes"
                class="table-row__changes"
            >
                <div class="table-vulnerability__positive">
                    <span v-if="item.changes.fixed ">{{ '-' + item.changes.fixed.length }}</span>
                    <span v-else>-0</span>
                </div>
                |
                <div class="table-vulnerability__negative">
                    <span v-if="item.changes.created ">{{ item.changes.created.length + '+' }}</span>
                    <span v-else>0+</span>
                </div>
            </div>
        </div>
        <div
            v-if="isShowAddInfoToggle"
            class="table-row__add-info"
            :class="{info_open_block : isShowAddInfoToggle}"
        >
            <div class="table-row__add-info-wrapper">
                <div class="table-row__add-info-description">
                    <h6 class="table-row__add-info-title">
                        Vulnerability description
                    </h6>
                    <p class="table-row__add-info-text">
                        {{ item.description }}
                    </p>
                </div>
                <div class="table-row__add-info-box">
                    <h6 class="table-row__add-info-title">
                        Remediation
                    </h6>
                    <p class="table-row__add-info-text">
                        {{ item.remediation }}
                    </p>
                </div>
            </div>
            <div class=" table-row__add-info-title--links">
                <h6
                    v-if="item.links.length"
                    class="table-row__add-info-title"
                >
                    Links
                </h6>
            </div>
            <div
                v-for="link in item.links"
                :key="link"
            >
                <p
                    class="table-row__text-link"
                    :title="link"
                    @click.stop="routeToLink(link)"
                >
                    {{ link }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import circleInfo from '../../assets/images/vulnerabilities/circleInfo.svg';
import arrowInfo from '../../assets/images/vulnerabilities/arrowInfo.svg';

export default {
    props: ['item', 'screenWidth', 'index', 'clickable'],
    data() {
        return {
            isShowAddInfoToggle: false,
            circleInfo,
            arrowInfo,
        };
    },
    methods: {
        toggleInfo() {
            this.isShowAddInfoToggle = !this.isShowAddInfoToggle;
        },
        determineDotColorBySeverityLvl: (lvl) => {
            if (lvl === 'low') {
                return 'green-dot';
            } else if (lvl === 'medium') {
                return 'yellow-dot';
            } else {
                return 'red-dot';
            }
        },
        setSeverityLvl: (lvl) => {
            if (lvl === 'low') {
                return 'Low';
            } else if (lvl === 'medium') {
                return 'Medium';
            } else {
                return 'High';
            }
        },
        routeToLink(link) {
            window.open(link);
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

.table-row {
    display: flex;
    align-items: center;
    min-height: 70px;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    @include Aspekta-font(400, 18px, 140%, #E3E6ED);

    &__index {
        @include Aspekta-font(400, 14px, 140%, #949DB8);
        float: left;
        width: 8%;
        text-align: center;
    }

    &__category {
        display: flex;
        float: left;
        width: 54%;
        min-height: 30px;
        align-items: center;
        word-break: break-word;
    }

    &__category > span {
        @include Aspekta-font(600, 18px, normal, #021631);
        letter-spacing: 0.54px;
        padding: 5px 0;
    }

    &__level {
        @include Aspekta-font(400, 14px, 140%, #949DB8);
        letter-spacing: 0.28px;
        float: left;
        width: 19%;
        align-items: center;
    }

    &__changes {
        float: left;
        width: 18%;
    }


    &__add-info {
        width: 100%;
        padding: 0 8%;
        background: #FFFFFF;
        border: none;
        overflow: hidden;


        &-wrapper {
            width: 100%;
            display: flex;
            overflow: hidden;
            gap: 45px;
        }

        &-description {
            float: left;
            width: 40%;
        }

        &-title {
            @include Aspekta-font(600, 18px, normal, #021631);
            letter-spacing: 0.54px;
            margin-bottom: 20px;

            &--links {
                width: 100%;
            }

            &--link {
                @include Aspekta-font(500, 14px, normal, #3180F6);
                letter-spacing: 0.42px;
                cursor: pointer;
                word-break: break-all;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-box {
            float: left;
            width: 40%;
        }

        &-text {
            @include Aspekta-font(400, 14px, 140%, #5C6A7A);
            align-items: center;

        }

    }

    &__text-link {
        @include Aspekta-font(500, 14px, normal, #3180F6);
        letter-spacing: 0.42px;
        margin-bottom: 10px;
        cursor: pointer;
        width: 100%;
        word-break: break-all;

        &:hover {
            text-decoration: underline;
        }
    }
}

.table-row:hover .table-row__link {
    transform: scale(1.2);
    opacity: 1;
    cursor: pointer;
}

.table-vulnerability__positive,
.table-vulnerability__negative {
    @include Aspekta-font(400, 14px, 140%, #4CE074);
    letter-spacing: 0.28px;
    display: inline-block;
    text-align: center;
}

.table-vulnerability__negative {
    color: #FB7462;
}

.red-dot {
    background-color: #FB7462;
}

.yellow-dot {
    background-color: #F7C602;
}

.green-dot {
    background-color: #4CE074;
}

.level_severity {
    margin-right: 15px;
}

.info_open {
    &_row {
        border-bottom: none;
    }

    &_block {
        border-bottom: 1px solid #f0f0f0;
    }
}

.table__link {
    min-width: 17px;
    transition: 0.3s;
    max-width: 26px;
    max-height: 26px;
    opacity: 0;

    &__arrow {
        opacity: 0;
        transition: 0.3s;
        position: absolute;
        width: 12px;
        left: 7px;
        top: 12px;
    }
}

.table-row:hover .table__link {
    transform: scale(1.2);
    opacity: 1;
    cursor: pointer;
}

.table-row:hover .table__link__arrow {
    transform: scale(1.2);
    opacity: 1;
    cursor: pointer;
}

.info__btn {
    box-sizing: border-box;
    display: flex;
    position: relative;
    max-width: 40px;
    margin: 0 15px;
    transition: all 0.5s;

    &__show {
        transform: rotate(0.5turn);
    }
}

@media(max-width: 992px) {
    .table-row__add-info {
        &-description {
            width: 60%;

        }

        &-box {
            width: 40%;
            padding: 45px 0;
        }
    }
}

@media (max-width: 768px) {

    .table-row__add-info {

        &-description {
            padding: 25px 0;
            float: none;
            width: 100%;
        }

        &-box {
            padding: 0;
            float: none;
            width: 100%;
        }

        &-wrapper {
            display: block;
        }

        &-title--links {
            margin-top: 20px;
            padding: 0;
            width: 100%;
        }
    }
    .table-row__text-link {
        margin-left: 0;
    }
}

@media(max-width: 500px) {
    .table-row__category {
        width: 75%;
      /*  margin-right: 10%;*/
    }
    .table-row__changes {
        width: 25%;
    }
    .table-headers__level {
        width: 15%;
    }

    .level_severity {
        margin-left: 10px;
    }

}

</style>
