<template>
    <b-container>
        <div class="sign-up-researcher">
            <h1 class="sign-up-researcher__title">
                Sign Up
            </h1>
            <form
                novalidate
                class="sign-up-researcher__form"
                @submit.prevent="handleForm"
            >
                <div class="sign-up-company__input-wrapper">
                    <input
                        id="firstName"
                        v-model.trim="inputData.firstName"
                        class="input"
                        name="firstName"
                        type="text"
                        @blur="activeInputName = null"
                        @input="handleInput"
                    >
                    <img
                        v-if="inputData.firstName.length > 0"
                        class="sign-up-researcher__email-check"
                        src="../../assets/images/cross_grey.svg"
                        alt="cross"
                        @click="clearInputAndErrors('firstName')"
                    >
                    <label
                        for="firstName"
                        class="label"
                        :class="{ activeInput: inputData.firstName !== isEmptyInput || activeInputName === 'firstName'}"
                    >First Name</label>
                    <span
                        id="firstNameError"
                        class="sign-up-researcher__error"
                    >
                        <img
                            class="sign-up-researcher__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('firstName')"
                        >
                        <span class="sign-up-researcher__error-text">Required field</span>
                    </span>
                </div>
                <div class="sign-up-company__input-wrapper">
                    <input
                        id="lastName"
                        v-model.trim="inputData.lastName"
                        class="input"
                        name="lastName"
                        type="text"
                        @blur="activeInputName = null"
                        @input="handleInput"
                    >
                    <img
                        v-if="inputData.lastName.length > 0"
                        class="sign-up-researcher__email-check"
                        src="../../assets/images/cross_grey.svg"
                        alt="cross"
                        @click="clearInputAndErrors('lastName')"
                    >
                    <label
                        for="lastName"
                        class="label"
                        :class="{ activeInput: inputData.lastName !== isEmptyInput || activeInputName === 'lastName'}"
                    >Last Name</label>
                    <span
                        id="lastNameError"
                        class="sign-up-researcher__error"
                    >
                        <img
                            class="sign-up-researcher__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('lastName')"
                        >
                        <span class="sign-up-researcher__error-text">Required field</span>
                    </span>
                </div>
                <div class="sign-up-company__input-wrapper">
                    <img
                        v-if="isEmailValid && Object.keys(errors).length === 0 && !isLoading && inputData.email.length > 0"
                        class="sign-up-researcher__email-check"
                        src="../../assets/images/greenCheck.svg"
                        alt=""
                    >
                    <img
                        v-if="isEmailValid === false && inputData.email.length > 0"
                        class="sign-up-researcher__email-check"
                        src="../../assets/images/cross.svg"
                        alt=""
                        @click="clearInputAndErrors('email')"
                    >
                    <span
                        v-if="errors.email"
                        class="sign-up-researcher__input-error"
                    >{{ errors.email }}</span>
                    <input
                        id="email"
                        v-model.trim="inputData.email"
                        class="input"
                        name="email"
                        type="email"
                        @blur="activeInputName = null"
                        @input="handleInput"
                        @change="handleInput"
                        @keyup.delete="handleInput"
                    >
                    <label
                        for="email"
                        class="label"
                        :class="{ activeInput: inputData.email !== isEmptyInput || activeInputName === 'email'}"
                    >Email</label>
                    <span
                        id="emailError"
                        class="sign-up-researcher__error"
                    >
                        <img
                            class="sign-up-researcher__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('email')"
                        >
                        <span class="sign-up-researcher__error-text">Required field</span>
                    </span>
                    <span
                        id="emailValidationError"
                        class="sign-up-researcher__error"
                    >
                        <img
                            v-if="isEmailValid === false && inputData.email.length > 0"
                            class="sign-up-researcher__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('email')"
                        >
                        <span class="sign-up-researcher__error-text">Please enter a valid email address</span>
                    </span>
                </div>
                <div class="sign-up-company__input-wrapper">
                    <img
                        id="passwordShow"
                        class="sign-up-researcher__toggle-show-password icon"
                        src="../../assets/images/myScans/icon_action_hide.svg"
                        alt=""
                        @click="toggleShowPassword"
                    >
                    <img
                        id="passwordHide"
                        class="sign-up-researcher__toggle-show-password sign-up-researcher__toggle-show-password--bigger icon"
                        src="../../assets/images/myScans/icon_action_show.svg"
                        alt=""
                        @click="toggleShowPassword"
                    >
                    <input
                        id="password"
                        v-model.trim="inputData.password"
                        class="input"
                        name="password"
                        type="password"
                        @blur="activeInputName = null"
                        @input="handleInput"
                        @keyup.tab="handleKeyUp"
                    >
                    <label
                        for="password"
                        class="label"
                        :class="{ activeInput: inputData.password !== isEmptyInput || activeInputName === 'password'}"
                    >Password</label>
                    <span
                        id="passwordError"
                        class="sign-up-researcher__error sign-up-researcher__error-length"
                    >
                        <span class="sign-up-researcher__error-text">Required field</span>
                    </span>
                    <span
                        id="passwordLengthError"
                        class="sign-up-researcher__error sign-up-researcher__error-length"
                    >
                        <span class="new-password__error-text">
                            Minimum 12 characters
                        </span>
                    </span>
                </div>
                <div class="sign-up-researcher__toggle-wrapper">
                    <b-form-checkbox
                        id="acceptTermsAndPolicy"
                        v-model="acceptTermsOfUseAndPrivacyPolicy"
                        name="acceptTermsAndPolicy"
                        value="true"
                        unchecked-value="false"
                    >
                        By signing up, you agree to our <br>
                        <b-nav-item
                            to="/terms"
                            class="sign-up-researcher__link"
                        >
                            Terms of Service
                        </b-nav-item>
                        and
                        <b-nav-item
                            to="/privacy"
                            class="sign-up-researcher__link"
                        >
                            Privacy Policy
                        </b-nav-item>
                    </b-form-checkbox>
                </div>
                <button
                    v-if="JSON.parse(acceptTermsOfUseAndPrivacyPolicy)"
                    class="sign-up-researcher__btn-submit sign-up-researcher__btn-submit--animation"
                >
                    Create Account
                </button>
                <button
                    v-else
                    disabled
                    class="sign-up-researcher__btn-submit sign-up-researcher__btn-submit--disabled"
                >
                    Create Account
                </button>
                <div class="sign-up-researcher__link-to-login-wrapper">
                    <p class="sign-up-researcher__text">
                        Already have an account? Sign In
                    </p>
                    <b-nav-item
                        to="/sign-in"
                        class="sign-up-researcher__link sign-up-researcher__link--orange"
                    >
                        here
                    </b-nav-item>
                    <p class="sign-up-researcher__text">
                        .
                    </p>
                </div>
            </form>
            <div
                v-if="isLoading"
                class="sign-up-researcher__spinner"
            >
                <Spinner />
            </div>
        </div>
    </b-container>
</template>

<script>
import {
    checkFormInputsForCompleteness,
    checkFormInputsForErrors,
    checkEmailValidation,
    checkPasswordLength,
    toggleShowPassword,
    validatePassword,
    validateEmail,
    hideErrors,
} from '@/services/functions';
import Spinner from '../../components/Spinner';
import { mapGetters } from 'vuex';
import { store } from '@/store';

export default {
    components: {
        Spinner,
    },
    data() {
        return {
            inputData: {
                email: '',
                password: '',
                firstName: '',
                lastName: '',
            },
            acceptTermsOfUseAndPrivacyPolicy: 'false',
            isEmailValid: null,
            emailError: false,
            isLoading: false,
            isEmptyInput: '',
            activeInputName: null,
        };
    },
    computed: {
        ...mapGetters({
            errors: 'authentication/errors',
        }),
    },
    watch: {
        errors() {
            if (this.errors.email) {
                this.emailError = true;
                this.isEmailValid = false;
            }
        },
    },
    methods: {
        clearInputAndErrors(inputField) {
            if (inputField === 'email') {
                this.inputData.email = '';
                hideErrors(['emailError', 'emailValidationError']);
            } else if (inputField === 'firstName') {
                this.inputData.firstName = '';
                hideErrors(['firstNameError']);
            } else if (inputField === 'lastName') {
                this.inputData.lastName = '';
                hideErrors(['lastNameError']);
            }
            store.commit('authentication/clearErrors');
            document.getElementById(inputField).style.border = '1px solid #F0F0F0';
        },
        async handleForm() {
            store.commit('authentication/clearErrors');

            const errorIds = ['emailError', 'emailValidationError', 'firstNameError', 'lastNameError', 'passwordError', 'passwordLengthError'];
            hideErrors(errorIds);

            const newUserData = {
                email: this.inputData.email,
                password: this.inputData.password,
                first_name: this.inputData.firstName,
                last_name: this.inputData.lastName,
                type: 'hacker',
                accept_terms_of_use_and_privacy_policy: JSON.parse(this.acceptTermsOfUseAndPrivacyPolicy),
            };

            checkFormInputsForErrors(this.inputData, 'sign-up-researcher__input--error');

            if (this.inputData.password) {
                checkPasswordLength(
                    { password: this.inputData.password },
                    'sign-up-researcher__input--length-error',
                );
            }

            if (this.inputData.email) {
                checkEmailValidation(
                    { email: this.inputData.email },
                    'sign-up-researcher__input--validation-error',
                );
            }

            if (checkFormInputsForCompleteness(this.inputData) &&
                validateEmail(this.inputData.email) &&
                validatePassword(this.inputData.password)) {
                await this.sendRequest(newUserData);
            }
        },
        async sendRequest(newUserData) {
            try {
                this.isLoading = true;
                await this.$auth.signUpResearcher(newUserData);
                this.isLoading = false;
                if (!Object.keys(this.errors).length) {
                    await this.$router.push({
                        name: 'ConfirmEmailLink',
                        params: { email: this.inputData.email },
                    });
                }
            } catch (error) {
                this.isLoading = false;
                console.log(error);
            }
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id;
        },
        handleInput(e) {
            this.checkKeyEvent(e);
            this.isEmailValid = validateEmail(this.inputData.email);

            hideErrors(['passwordError', 'passwordLengthError', 'emailError', 'emailValidationError']);
            document.getElementById('email').style.border = '1px solid #F0F0F0';
            document.getElementById('password').style.border = '1px solid #F0F0F0';
            store.commit('authentication/clearErrors');
        },
        handleKeyUp(event) {
            const element = document.getElementById(`${event.target.id}Error`);
            element.style.display = 'none';
            if (event.target.id === 'email') {
                const elementEmail = document.getElementById(`${event.target.id}ValidationError`);
                elementEmail.style.display = 'none';
            }
            if (event.target.id === 'password') {
                const elementPassword = document.getElementById(`${event.target.id}LengthError`);
                elementPassword.style.display = 'none';
            }
        },
        toggleShowPassword,
    },
};
</script>

<style lang="scss">
@import "../../assets/css/fonts";

.sign-up-researcher {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 71px - 192px);
    min-height: 665px;

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        @include Aspekta-font(700, 60px, normal, #242424);
        font-size: calc(31px + (60 - 31) * calc((100vw - 320px) / (1920 - 320)));
        letter-spacing: 0em;
        margin: 36px 0;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 395px;
        margin-bottom: 131px;
    }

    &__email-check {
        position: absolute;
        bottom: 48px;
        right: 20px;
        cursor: pointer;
    }

    &__toggle-show-password {
        position: absolute;
        right: 11px;
        top: 5px;
        width: 30px;
        cursor: pointer;
    }

    &__input-error {
        position: absolute;
        bottom: 14px;
        left: 3px;
        font-family: Cabin, serif;
        font-weight: normal;
        font-size: 12px;
        color: #FB8D62;
    }

    &__input {

        &--error,
        &--validation-error,
        &--length-error {
            border: 1px solid #FB8D62;
            border-radius: 5px;
        }
    }

    &__toggle-wrapper {
        width: 100%;
        @include Aspekta-font(500, 14px, normal, #706969);

        label.custom-control-label {
            padding-top: 2px;
        }

        .custom-control-label::after {
            background-color: #EBF2FD;
            border-radius: 4px;
            border: 1px solid #3180F6;
        }

        .custom-control-input:checked ~ .custom-control-label::after {
            background-color: #3180F6;
        }

        .icon {
            margin: 4px;
            width: 22px;
            height: 22px;
        }
    }

    &__link {
        display: inline-block;
        font-family: Cabin, serif;
        font-weight: normal;
        align-items: center;

        a {
            @include Aspekta-font(500, 14px, normal, transparent);
            color: #1FA8C7;
            font-size: 14px !important;

            &:hover {
                color: #FF7A08;
                text-decoration: underline
            }
        }
    }

    &__link-to-login-wrapper {
        display: flex;
        align-items: center;
    }

    &__text {
        margin: 0 5px 0 0;
        @include Aspekta-font(500, 18px, 150%, #706969);
    }

    &__link--orange {
        font-family: Cabin, serif;

        .nav-link {
            margin: 0;
        }

        a {
            @include Aspekta-font(500, 18px, normal, transparent);
            font-size: 18px !important;
            color: #FFB400;

            &:hover {
                color: #FF7A08;
                text-decoration: underline
            }
        }
    }

    &__btn-submit {
        @include Aspekta-font(600, 18px, normal, #FFFFFF);
        background: #FF7A08;
        border: 2px solid #FF7A08;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px 40px;
        margin: 45px 0 28px 0;
        transition: 0.3s;

        &--disabled {
            background: #B2B9C4;
            border: 2px solid #B2B9C4;
        }

        &:focus {
            border: 2px solid #FFB400;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &__error {
        width: 100%;
        max-width: 831px;
        position: relative;
    }

    &__error-cross {
        position: absolute;
        top: -56px;
        right: 20px;
        cursor: pointer;
    }

    &__error-text {
        @include Aspekta-font(500, 10px, 150%, #FB8D62);
        position: absolute;
        top: -30px;
        left: 3px;
        margin: 0;
    }
}

#firstNameError,
#lastNameError,
#passwordError,
#emailError,
#emailValidationError,
#passwordLengthError {
    display: none;
}

@media (max-width: 1440px) {
    .sign-up-researcher {
        height: calc(100vh - 71px - 251px);
    }
}

@media (max-width: 768px) {
    .sign-up-researcher {
        padding: 0 20px;

        &__title {
            margin: 30px 0;
        }

        &__link--orange {
            a {
                font-size: 16px !important;
            }
        }

        &__btn-submit {
            padding: 10px 0;
            width: 100%;
            margin: 15px 0 28px 0;
        }
    }
}

@media (max-width: 594px) {
    .sign-up-researcher {
        height: calc(100vh - 71px - 331px);
    }
}


</style>
