<template>
    <b-container>
        <div class="sign-up-company">
            <h1 class="sign-up-company__title">
                Sign Up
            </h1>
            <form
                novalidate
                class="sign-up-company__form"
                @submit.prevent="handleForm"
            >
                <p class="sign-up-company__step">
                    Step {{ step }} of 2
                </p>
                <div
                    v-if="step === 1"
                    class="sign-up-company__step"
                >
                    <div class="sign-up-company__input-wrapper">
                        <input
                            id="companyName"
                            v-model.trim="inputProfileData.companyName"
                            class="input"
                            name="companyName"
                            type="text"
                            @input="checkKeyEvent"
                            @blur="activeInputName = null"
                            @change="handleChange"
                        >
                        <img
                            v-if="inputProfileData.companyName.length > 0"
                            class="sign-up-researcher__email-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                            @click="clearInputAndErrors('companyName')"
                        >
                        <label
                            for="companyName"
                            class="label"
                            :class="{ activeInput: inputProfileData.companyName !== isEmptyInput || activeInputName === 'companyName'}"
                        >Company Name</label>
                        <span
                            id="companyNameError"
                            class="sign-up-company__error"
                        >
                            <img
                                class="sign-up-company__error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                                @click="clearInputAndErrors('companyName')"
                            >
                            <span class="sign-up-company__error-text">Required field</span>
                        </span>
                    </div>
                    <div class="sign-up-company__input-wrapper">
                        <input
                            id="website"
                            v-model.trim="inputProfileData.website"
                            class="input"
                            name="website"
                            type="text"
                            @input="checkKeyEvent"
                            @blur="activeInputName = null"
                            @change="handleChange"
                        >
                        <img
                            v-if="inputProfileData.website.length > 0"
                            class="sign-up-researcher__email-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                            @click="clearInputAndErrors('website')"
                        >
                        <label
                            for="website"
                            class="label"
                            :class="{ activeInput: inputProfileData.website !== isEmptyInput || activeInputName === 'website'}"
                        >Website</label>
                        <span
                            id="websiteError"
                            class="sign-up-company__error"
                        >
                            <img
                                class="sign-up-company__error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                                @click="clearInputAndErrors('website')"
                            >
                            <span class="sign-up-company__error-text">Required field</span>
                        </span>
                    </div>
                    <div class="sign-up-company__input-wrapper">
                        <input
                            id="address1"
                            v-model.trim="inputProfileData.address1"
                            class="input"
                            name="address1"
                            type="text"
                            @input="checkKeyEvent"
                            @blur="activeInputName = null"
                            @change="handleChange"
                        >
                        <img
                            v-if="inputProfileData.address1.length > 0"
                            class="sign-up-researcher__email-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                            @click="clearInputAndErrors('address1')"
                        >
                        <label
                            for="address1"
                            class="label"
                            :class="{ activeInput: inputProfileData.address1 !== isEmptyInput || activeInputName === 'address1'}"
                        >Address</label>
                        <div
                            id="address1Error"
                            class="sign-up-company__error"
                        >
                            <img
                                class="sign-up-company__error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                                @click="clearInputAndErrors('address1')"
                            >
                            <p class="sign-up-company__error-text">
                                Required field
                            </p>
                        </div>
                    </div>
                    <div class="sign-up-company__input-wrapper">
                        <input
                            id="country"
                            v-model.trim="inputProfileData.country"
                            class="input"
                            name="country"
                            type="text"
                            @input="checkKeyEvent"
                            @blur="activeInputName = null"
                            @change="handleChange"
                        >
                        <img
                            v-if="inputProfileData.country.length > 0"
                            class="sign-up-researcher__email-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                            @click="clearInputAndErrors('country')"
                        >
                        <label
                            for="country"
                            class="label"
                            :class="{ activeInput: inputProfileData.country !== isEmptyInput || activeInputName === 'country'}"
                        >Country</label>
                        <span
                            id="countryError"
                            class="sign-up-company__error"
                        >
                            <img
                                class="sign-up-company__error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                                @click="clearInputAndErrors('country')"
                            >
                            <span class="sign-up-company__error-text">Required field</span>
                        </span>
                    </div>
                    <div class="sign-up-company__label-wrapper">
                        <div class="sign-up-company__input-wrapper sign-up-company__input-wrapper--small sign-up-company__input-wrapper--margin">
                            <input
                                id="state"
                                v-model.trim="inputProfileData.state"
                                class="input"
                                name="state"
                                type="text"
                                @input="checkKeyEvent"
                                @blur="activeInputName = null"
                                @change="handleChange"
                            >
                            <img
                                v-if="inputProfileData.state.length > 0"
                                class="sign-up-researcher__email-check"
                                src="../../assets/images/cross_grey.svg"
                                alt="cross"
                                @click="clearInputAndErrors('state')"
                            >
                            <label
                                for="state"
                                class="label"
                                :class="{ activeInput: inputProfileData.state !== isEmptyInput || activeInputName === 'state'}"
                            >State</label>
                            <span
                                id="stateError"
                                class="sign-up-company__error"
                            >
                                <img
                                    class="sign-up-company__error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                    @click="clearInputAndErrors('state')"
                                >
                                <span class="sign-up-company__error-text">Required field</span>
                            </span>
                        </div>
                        <div class="sign-up-company__input-wrapper sign-up-company__input-wrapper--small">
                            <input
                                id="city"
                                v-model.trim="inputProfileData.city"
                                class="input"
                                name="city"
                                type="text"
                                @input="checkKeyEvent"
                                @blur="activeInputName = null"
                                @change="handleChange"
                            >
                            <img
                                v-if="inputProfileData.city.length > 0"
                                class="sign-up-researcher__email-check"
                                src="../../assets/images/cross_grey.svg"
                                alt="cross"
                                @click="clearInputAndErrors('city')"
                            >
                            <label
                                for="city"
                                class="label"
                                :class="{ activeInput: inputProfileData.city !== isEmptyInput || activeInputName === 'city'}"
                            >City</label>
                            <span
                                id="cityError"
                                class="sign-up-company__error"
                            >
                                <img
                                    class="sign-up-company__error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                    @click="clearInputAndErrors('city')"
                                >
                                <span class="sign-up-company__error-text">Required field</span>
                            </span>
                        </div>
                    </div>
                    <div class="sign-up-company__input-wrapper sign-up-company__input-wrapper--small">
                        <input
                            id="zipCode"
                            v-model.trim="inputProfileData.zipCode"
                            class="input"
                            name="zipCode"
                            type="text"
                            @input="checkKeyEvent"
                            @blur="activeInputName = null"
                            @change="handleChange"
                        >
                        <img
                            v-if="inputProfileData.zipCode.length > 0"
                            class="sign-up-researcher__email-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                            @click="clearInputAndErrors('zipCode')"
                        >
                        <label
                            for="zipCode"
                            class="label"
                            :class="{ activeInput: inputProfileData.zipCode !== isEmptyInput || activeInputName === 'zipCode'}"
                        >ZIP Code</label>
                        <span
                            id="zipCodeError"
                            class="sign-up-company__error"
                        >
                            <img
                                class="sign-up-company__error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                                @click="clearInputAndErrors('zipCode')"
                            >
                            <span class="sign-up-company__error-text">Required field</span>
                        </span>
                    </div>
                    <div
                        class="sign-up-company__btn"
                        @click="handleGoToNextStep"
                    >
                        Next Step
                    </div>
                </div>
                <div
                    v-if="step === 2"
                    class="sign-up-company__step"
                >
                    <div class="sign-up-company__input-wrapper">
                        <input
                            id="firstName"
                            v-model.trim="inputData.firstName"
                            class="input"
                            name="firstName"
                            type="text"
                            @blur="activeInputName = null"
                            @input="handleInput"
                            @change="handleChange"
                        >
                        <img
                            v-if="inputData.firstName.length > 0"
                            class="sign-up-researcher__email-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                            @click="clearInputAndErrors('firstName')"
                        >
                        <label
                            for="firstName"
                            class="label"
                            :class="{ activeInput: inputData.firstName !== isEmptyInput || activeInputName === 'firstName'}"
                        >First Name</label>
                        <span
                            id="firstNameError"
                            class="sign-up-company__error"
                        >
                            <img
                                class="sign-up-company__error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                                @click="clearInputAndErrors('firstName')"
                            >
                            <span class="sign-up-company__error-text">Required field</span>
                        </span>
                    </div>
                    <div class="sign-up-company__input-wrapper">
                        <input
                            id="lastName"
                            v-model.trim="inputData.lastName"
                            class="input"
                            name="lastName"
                            type="text"
                            @blur="activeInputName = null"
                            @input="handleInput"
                            @change="handleChange"
                        >
                        <img
                            v-if="inputData.lastName.length > 0"
                            class="sign-up-researcher__email-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                            @click="clearInputAndErrors('lastName')"
                        >
                        <label
                            for="lastName"
                            class="label"
                            :class="{ activeInput: inputData.lastName !== isEmptyInput || activeInputName === 'lastName'}"
                        >Last Name</label>
                        <span
                            id="lastNameError"
                            class="sign-up-company__error"
                        >
                            <img
                                class="sign-up-company__error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                                @click="clearInputAndErrors('lastName')"
                            >
                            <span class="sign-up-company__error-text">Required field</span>
                        </span>
                    </div>
                    <div class="sign-up-company__input-wrapper">
                        <img
                            v-if="isEmailValid && Object.keys(errors).length === 0 && !isLoading && inputData.email.length > 0"
                            class="sign-up-company__email-check"
                            src="../../assets/images/greenCheck.svg"
                            alt=""
                        >
                        <img
                            v-if="isEmailValid === false && inputData.email.length > 0"
                            class="sign-up-company__email-check"
                            src="../../assets/images/cross.svg"
                            alt=""
                            @click="clearInputAndErrors('email')"
                        >
                        <span
                            v-if="errors.email"
                            class="sign-up-company__input-error"
                        >{{ errors.email }}</span>
                        <input
                            id="email"
                            v-model.trim="inputData.email"
                            class="input"
                            name="email"
                            type="email"
                            @blur="activeInputName = null"
                            @input="handleInput"
                            @change="handleInput"
                            @keyup.delete="handleInput"
                        >
                        <label
                            for="email"
                            class="label"
                            :class="{ activeInput: inputData.email !== isEmptyInput || activeInputName === 'email'}"
                        >Corporate Email</label>
                        <span
                            id="emailValidationError"
                            class="sign-up-researcher__error"
                        >
                            <img
                                class="sign-up-researcher__error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                                @click="clearInputAndErrors('email')"
                            >
                            <span class="sign-up-researcher__error-text">Please enter a valid email address</span>
                        </span>
                        <span
                            id="emailError"
                            class="sign-up-company__error"
                        >
                            <img
                                class="sign-up-company__error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                                @click="clearInputAndErrors('email')"
                            >
                            <span class="sign-up-company__error-text">Required field</span>
                        </span>
                    </div>
                    <div class="sign-up-company__input-wrapper">
                        <img
                            id="passwordShow"
                            class="sign-up-company__toggle-show-password icon"
                            src="../../assets/images/myScans/icon_action_hide.svg"
                            alt=""
                            @click="toggleShowPassword"
                        >
                        <img
                            id="passwordHide"
                            class="sign-up-company__toggle-show-password sign-up-company__toggle-show-password--bigger icon"
                            src="../../assets/images/myScans/icon_action_show.svg"
                            alt=""
                            @click="toggleShowPassword"
                        >
                        <input
                            id="password"
                            v-model.trim="inputData.password"
                            class="input"
                            name="password"
                            type="password"
                            @blur="activeInputName = null"
                            @input="handleInput"
                        >
                        <label
                            for="password"
                            class="label"
                            :class="{ activeInput: inputData.password !== isEmptyInput || activeInputName === 'password'}"
                        >Password</label>
                        <span
                            id="passwordLengthError"
                            class="sign-up-company__error sign-up-company__error-length"
                        >
                            <span class="new-password__error-text">
                                Minimum 12 characters
                            </span>
                        </span>
                        <span
                            id="passwordError"
                            class="sign-up-company__error sign-up-company__error-length"
                        >
                            <span class="new-password__error-text">
                                Required field
                            </span>
                        </span>
                    </div>
                    <div class="sign-up-company__toggle-wrapper">
                        <b-form-checkbox
                            id="acceptTermsAndPolicy"
                            v-model="acceptTermsOfUseAndPrivacyPolicy"
                            name="acceptTermsAndPolicy"
                            value="true"
                            unchecked-value="false"
                        >
                            By signing up, you agree to our <br>
                            <b-nav-item
                                to="/terms"
                                class="sign-up-company__link"
                            >
                                Terms of Service
                            </b-nav-item>
                            and
                            <b-nav-item
                                to="/privacy"
                                class="sign-up-company__link"
                            >
                                Privacy Policy
                            </b-nav-item>
                        </b-form-checkbox>
                    </div>
                    <button
                        v-if="JSON.parse(acceptTermsOfUseAndPrivacyPolicy)"
                        class="sign-up-company__btn"
                    >
                        Create Account
                    </button>
                    <button
                        v-else
                        disabled
                        class="sign-up-company__btn sign-up-company__btn--disabled"
                    >
                        Create Account
                    </button>
                </div>
                <div class="sign-up-company__link-to-login-wrapper">
                    <p class="sign-up-company__text">
                        Already have an account? Sign In
                    </p>
                    <b-nav-item
                        to="/sign-in"
                        class="sign-up-company__link sign-up-company__link--orange"
                    >
                        here
                    </b-nav-item>
                    <p class="sign-up-company__text">
                        .
                    </p>
                </div>
            </form>
            <div
                v-if="isLoading"
                class="sign-up-company__spinner"
            >
                <Spinner />
            </div>
        </div>
    </b-container>
</template>

<script>
import {
    checkFormInputsForCompleteness,
    checkFormInputsForErrors,
    checkEmailValidation,
    checkPasswordLength,
    toggleShowPassword,
    validatePassword,
    validateEmail,
    hideErrors,
} from '@/services/functions';
import Spinner from '../../components/Spinner';
import { mapGetters } from 'vuex';
import { store } from '@/store';

export default {
    name: 'SignUpCompany',
    components: {
        Spinner,
    },
    data() {
        return {
            inputProfileData: {
                companyName: '',
                website: '',
                address1: '',
                country: '',
                state: '',
                city: '',
                zipCode: '',
            },
            inputData: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
            },
            acceptTermsOfUseAndPrivacyPolicy: 'false',
            step: 1,
            isLoading: false,
            isEmailValid: null,
            emailError: false,
            isEmptyInput: '',
            activeInputName: null,
        };
    },
    computed: {
        ...mapGetters({
            errors: 'authentication/errors',
        }),
    },
    watch: {
        errors() {
            if (this.errors.email) {
                this.emailError = true;
                this.isEmailValid = false;
            }
        },
    },
    methods: {
        clearInputAndErrors(inputField) {
            // inputProfileData
            if (inputField === 'companyName') {
                this.inputProfileData.companyName = '';
                hideErrors(['companyNameError']);
            } else if (inputField === 'website') {
                this.inputProfileData.website = '';
                hideErrors(['websiteError']);
            } else if (inputField === 'address1') {
                this.inputProfileData.address1 = '';
                hideErrors(['address1Error']);
            } else if (inputField === 'country') {
                this.inputProfileData.country = '';
                hideErrors(['countryError']);
            } else if (inputField === 'state') {
                this.inputProfileData.state = '';
                hideErrors(['stateError']);
            } else if (inputField === 'city') {
                this.inputProfileData.city = '';
                hideErrors(['cityError']);
            } else if (inputField === 'zipCode') {
                this.inputProfileData.zipCode = '';
                hideErrors(['zipCodeError']);
            }
            // inputData
            if (inputField === 'email') {
                this.inputData.email = '';
                hideErrors(['emailError', 'emailValidationError']);
            } else if (inputField === 'firstName') {
                this.inputData.firstName = '';
                hideErrors(['firstNameError']);
            } else if (inputField === 'lastName') {
                this.inputData.lastName = '';
                hideErrors(['lastNameError']);
            }
            store.commit('authentication/clearErrors');
            document.getElementById(inputField).style.border = '1px solid #F0F0F0';
        },
        async handleForm() {
            hideErrors([
                'passwordError', 'passwordLengthError', 'emailError', 'emailValidationError', 'firstNameError', 'lastNameError',
                'companyNameError', 'websiteError', 'address1Error', 'countryError', 'stateError', 'cityError', 'zipCodeError',
            ]);
            store.commit('authentication/clearErrors');

            const newUserData = {
                company_name: this.inputProfileData.companyName,
                website: this.inputProfileData.website,
                address1: this.inputProfileData.address1,
                country: this.inputProfileData.country,
                state: this.inputProfileData.state,
                city: this.inputProfileData.city,
                zip_code: this.inputProfileData.zipCode,
                email: this.inputData.email,
                password: this.inputData.password,
                first_name: this.inputData.firstName,
                last_name: this.inputData.lastName,
                type: 'company',
                accept_terms_of_use_and_privacy_policy: JSON.parse(this.acceptTermsOfUseAndPrivacyPolicy),
            };

            if (this.inputData.email) {
                checkEmailValidation(
                    { email: this.inputData.email },
                    'sign-up-company__input--validation-error',
                );
            }

            if (this.inputData.password) {
                checkPasswordLength(
                    { password: this.inputData.password },
                    'sign-up-company__input--length-error',
                );
            }

            checkFormInputsForErrors(this.inputData, 'sign-up-company__input--error');
            if (checkFormInputsForCompleteness(this.inputData) &&
                validatePassword(this.inputData.password) &&
                validateEmail(this.inputData.email)) {
                await this.sendRequest(newUserData);
            }
        },
        async sendRequest(newUserData) {
            try {
                this.isLoading = true;
                await this.$auth.signUpCompany(newUserData);
                this.isLoading = false;
                if (!Object.keys(this.errors).length) {
                    gtag('event', 'sign_up', { method: 'oversecured', type: 'company' });
                    await this.$router.push({
                        name: 'ConfirmEmailLink',
                        params: { email: this.inputData.email },
                    });
                    otag({ event: 'SIGN_UP', email: this.inputData.email });
                }
            } catch (error) {
                this.isLoading = false;
                console.log(error);
            }
        },
        handleInput(e) {
            this.checkKeyEvent(e);
            this.isEmailValid = validateEmail(this.inputData.email);

            hideErrors(['passwordError', 'passwordLengthError', 'emailError', 'emailValidationError']);
            document.getElementById('email').style.border = '1px solid #F0F0F0';
            document.getElementById('password').style.border = '1px solid #F0F0F0';
            store.commit('authentication/clearErrors');
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id;
        },
        scroll: function () {
            return window.scrollTo(0, 0);
        },
        handleGoToNextStep() {
            checkFormInputsForErrors(this.inputProfileData, 'sign-up-company__input--error');
            if (checkFormInputsForCompleteness(this.inputProfileData)) {
                this.step = 2;
                this.scroll();
            }
        },
        handleChange(e) {
            if (e.target.value.trim()) {
                const el = document.getElementById(`${e.target.id}`);
                const label = document.getElementById(`${e.target.id}Error`);
                el.classList.remove('sign-up-company__input--error');
                label.style.display = 'none';
            }
        },
        toggleShowPassword,
    },
};
</script>

<style lang="scss">
@import "../../assets/css/fonts";

.sign-up-company {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 71px - 160px);
    min-height: 770px;

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__step {
        @include Aspekta-font(600, 18px, 150%, #706969);
    }

    &__title {
        @include Aspekta-font(700, 60px, normal, #242424);
        font-size: calc(31px + (60 - 31) * calc((100vw - 320px) / (1920 - 320)));
        letter-spacing: 0em;
        margin: 36px 0;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 395px;
        margin-bottom: 131px;
    }

    &__step {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    &__label-wrapper {
        width: 100%;
        display: flex;
    }

    &__email-check {
        position: absolute;
        top: 15px;
        right: 20px;
    }

    &__toggle-show-password {
        position: absolute;
        right: 11px;
        top: 5px;
        width: 30px;
        cursor: pointer;
    }

    &__input-wrapper {
        position: relative;
        width: 100%;

        &--small {
            max-width: 187px;
            margin-right: auto;
        }

        &--margin {
            margin-right: 20px;
        }

        .icon {
            margin: 4px;
            width: 22px;
            height: 22px;
        }

    }

    &__input--error,
    &__input--length-error,
    &__input--validation-error {
            border: 1px solid #FB8D62;
            border-radius: 5px;
    }

    &__toggle-wrapper {
        @include Aspekta-font(500, 14px, normal, #706969);
        margin-right: auto;

        .custom-control-label::after {
            background-color: #EBF2FD;
            border-radius: 4px;
            border: 1px solid #3180F6;
        }

        .custom-control-input:checked ~ .custom-control-label::after {
            background-color: #3180F6;
        }
    }

    &__link {
        display: inline-block;
        font-family: Cabin, serif;
        font-weight: normal;
        align-items: center;

        a {
            @include Aspekta-font(500, 14px, normal, transparent);
            color: #1FA8C7;
            font-size: 14px !important;

            &:hover {
                color: #FF7A08;
                text-decoration: underline
            }
        }
    }

    &__link-to-login-wrapper {
        display: flex;
        align-items: center;
    }

    &__input-error {
        position: absolute;
        bottom: 10px;
        left: 3px;
        font-family: Cabin, serif;
        font-weight: normal;
        font-size: 12px;
        color: #FB8D62;
    }

    &__text {
        @include Aspekta-font(500, 18px, 150%, #706969);
        margin: 0 5px 0 0;
    }

    &__link--orange {
        font-family: Cabin, serif;

        .nav-link {
            margin: 0;
        }

        a {
            @include Aspekta-font(500, 18px, normal, transparent);
            font-weight: normal;
            font-size: 18px !important;
            color: #FFB400;

            &:hover {
                color: #FF7A08;
                text-decoration: underline
            }
        }
    }

    &__btn {
        @include Aspekta-font(600, 18px, normal, #FFFFFF);
        background: #FF7A08;
        border: 2px solid #FF7A08;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px 40px;
        margin: 28px 0;
        transition: 0.3s;
        cursor: pointer;
        display: flex;
        justify-content: center;

        &--disabled {
            background: #B2B9C4;
            border: 2px solid #B2B9C4;
        }

        &:focus {
            border: 2px solid #FFB400;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &__error {
        width: 100%;
        max-width: 831px;
        position: relative;
    }

    &__error-cross {
        position: absolute;
        top: -58px;
        right: 20px;
        cursor: pointer;
    }

    &__email-check {
        cursor: pointer;
    }

    &__error-text {
        @include Aspekta-font(500, 10px, 150%, #FB8D62);
        position: absolute;
        top: -30px;
        left: 3px;
        margin: 0;
    }
}

#companyNameError,
#websiteError,
#address1Error,
#countryError,
#stateError,
#cityError,
#zipCodeError,
#firstNameError,
#lastNameError,
#passwordError,
#emailError,
#emailValidationError,
#passwordLengthError {
    display: none;
}

@media (max-width: 1100px) {
    .sign-up-company {
        height: calc(100vh - 71px - 185px);
    }
}

@media (max-width: 768px) {
    .sign-up-company {
        padding: 0 20px;

        &__title {
            margin: 30px 0;
        }

        &__link--orange {
            a {
                font-size: 16px !important;
            }
        }

        &__label-wrapper {
            flex-direction: column;
        }

        &__label {
            width: 100%;
            margin-bottom: 36px;
            position: relative;

            &--small {
                max-width: 100%;
            }

            &--margin {
                margin-right: 20px;
            }
        }

        &__btn {
            padding: 10px 0;
            width: 100%;
            margin: 15px 0 28px 0;
        }
    }
}
</style>
