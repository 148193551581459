<template>
    <svg
        width="28"
        height="28" 
        viewBox="0 0 28 28" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            :fill="iconColor"
            d="M10.6917 1.5C8.24388 1.5 7.16477 1.71562 6.06946 2.3014C5.09426 2.82294 4.32294 3.59426 3.8014 4.56946C3.21562 5.66477 3 6.74388 3 9.19166V17.8083C3 20.2561 3.21562 21.3352 3.8014 22.4305C4.32294 23.4057 5.09426 24.1771 6.06946 24.6986C7.16477 25.2844 8.24388 25.5 10.6917 25.5H12.3083C12.5604 25.5 12.7975 25.4977 13.0212 25.4929C13.5733 25.4813 14.0115 25.0242 13.9998 24.472C13.9881 23.9198 13.531 23.4817 12.9788 23.4934C12.7712 23.4978 12.5483 23.5 12.3083 23.5H10.6917C8.35671 23.5 7.67339 23.2883 7.01266 22.935C6.386 22.5998 5.90017 22.114 5.56502 21.4873C5.21166 20.8266 5 20.1433 5 17.8083V9.19166C5 6.85671 5.21166 6.17339 5.56502 5.51266C5.90017 4.886 6.386 4.40017 7.01266 4.06502C7.67339 3.71166 8.35671 3.5 10.6917 3.5L12.8261 3.50054C12.8891 3.50094 12.9473 3.50157 13.0015 3.50245L13 9C13 10.3807 14.1193 11.5 15.5 11.5L20.9976 11.4996L21 11.5V12.033C21 12.5852 21.4477 13.033 22 13.033C22.5523 13.033 23 12.5852 23 12.033V11.5C23 11.4694 22.9986 11.4392 22.9959 11.4094C22.9854 10.909 22.9524 10.6176 22.878 10.2947C22.7846 9.88946 22.6329 9.52339 22.4125 9.17084C22.1899 8.81494 21.9561 8.54186 21.3927 7.97851L16.5215 3.10727C15.9581 2.54393 15.6851 2.31012 15.3292 2.08754C14.9766 1.86706 14.6105 1.71543 14.2053 1.62204C13.8824 1.54762 13.591 1.51464 13.0907 1.50405C13.0608 1.50137 13.0306 1.5 13 1.5H10.6917ZM15 4.415L15.1073 4.52149L19.9785 9.39273C20.0169 9.43111 20.0531 9.4675 20.0873 9.50206L15.5 9.5L15.4101 9.49194C15.1769 9.44961 15 9.24546 15 9V4.415ZM18.9974 15.5C18.9974 14.9477 19.4451 14.5 19.9974 14.5C20.5497 14.5 20.9974 14.9477 20.9974 15.5L20.9974 23.0858L23.2927 20.7905C23.6832 20.4 24.3164 20.4 24.7069 20.7906C25.0974 21.1811 25.0974 21.8142 24.7069 22.2048L20.7045 26.2071C20.314 26.5976 19.6808 26.5976 19.2903 26.2071L15.2929 22.2097C14.9024 21.8192 14.9024 21.186 15.2929 20.7955C15.6834 20.4049 16.3166 20.4049 16.7071 20.7955L18.9974 23.0858L18.9974 15.5Z" 
        />
    </svg>
</template>

<script>

export default {
    name: 'DownloadIcon',
    props: {
        disabled: {
            type: Boolean,
            require: true,
        },
    },
    computed: {
        iconColor() {
            return this.disabled ? '#D9D9D9' : '#949DB8';
        },
    },
};
</script>


