<template>
    <div class="no-changes">
        <div class="no-changes__container">
            <div class="no-changes__title">
                {{ title }}
            </div>
            <div
                class="no-changes__description"
            >
                <span
                    class="no-changes__description-text"
                >
                    {{ description }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'NoChangesBlock',

    data(){
        return{
            title:'No changes found',
            description:'No differences were found between the compared versions.',
        };
    },

};
</script>
<style lang="scss" scoped>
@import '../../../assets/css/fonts';

.no-changes {
    width: 100%;
    height: 100%;
    padding: 30px;
    margin-bottom: 40px;
    background: white;
    border-radius: 20px;
    border: 1px #E3E6ED solid;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    display: inline-flex;

	&__container {
        flex: 1 1 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        display: inline-flex;
	}

	&__title {
		align-self: stretch;
        @include Aspekta-font(700, 36px, 54px, #242424);
        letter-spacing: 1.44px;
        word-wrap: break-word;
	}

	&__description {
		align-self: stretch;
	}

    &__description-text{
        @include Aspekta-font(400, 14px, 19.60px, #5C6A7A);
        letter-spacing: 0.28px;
        word-wrap: break-word;
    }
}

@media (max-width: 576px) {

.no-changes__description-text {
   @include Aspekta-font(450, 14px, 25.50px, #5C6A7A);
   letter-spacing: 0.17px;
}
.no-changes__title{
       @include Aspekta-font(650, 21px, normal, #242424);
       letter-spacing: 0.63px;
}
}

</style>
