<template>
    <b-container class="contact-us__container">
        <div class="contact-us">
            <div class="contact-us__wrapper">
                <h1 class="contact-us__title">Submit a request</h1>
                <form class="contact-us__form" @submit.prevent="handleForm">
                    <div class="contact-us__input-wrapper">
                        <div class="contact-us__label-wrapper contact-us__label-wrapper--margin">
                            <input id="first_name" v-model.trim="requireData.first_name" class="input input-contact-us"
                                type="text"
                                @blur="activeInputName = null"
                                @input="handleInput"
                                @change="handleInput"
                                @keyup.delete="handleInput"
                            >
                            <img v-if="requireData.first_name.length > 0"
                                @click="clearInputAndErrors('first_name')"
                                class="contact-us__email-check"
                                src="../assets/images/cross_grey.svg"
                                alt="cross"
                            >
                            <label for="first_name" class="label" v-bind:class="{ activeInput: requireData.first_name !== isEmptyInput || activeInputName === 'first_name'}">First Name</label>
                            <div id="first_nameError" class="contact-us__error">
                                <img
                                    @click="clearInputAndErrors('first_name')"
                                    class="contact-us__error-cross"
                                    src="../assets/images/cross.svg"
                                    alt="cross"
                                >
                                <p class="contact-us__error-text">Required field</p>
                            </div>
                            <div id="first_nameLengthError" class="contact-us__error">
                                <img
                                    @click="clearInputAndErrors('first_name')"
                                    class="contact-us__error-cross"
                                    src="../assets/images/cross.svg"
                                    alt="cross"
                                >
                                <p class="contact-us__error-text">Max input length 1000</p>
                            </div>
                        </div>
                        <div class="contact-us__label-wrapper">
                            <input id="last_name" v-model.trim="requireData.last_name" class="input input-contact-us" type="text"
                                @blur="activeInputName = null"
                                @input="handleInput"
                                @change="handleInput"
                                @keyup.delete="handleInput"
                            >
                            <img v-if="requireData.last_name.length > 0"
                                @click="clearInputAndErrors('last_name')"
                                class="contact-us__email-check"
                                src="../assets/images/cross_grey.svg"
                                alt="cross"
                            >
                            <label for="last_name" class="label" v-bind:class="{ activeInput: requireData.last_name !== isEmptyInput || activeInputName === 'last_name'}">Last Name</label>
                            <div id="last_nameError" class="contact-us__error">
                                <img
                                    @click="clearInputAndErrors('last_name')"
                                    class="contact-us__error-cross"
                                    src="../assets/images/cross.svg"
                                    alt="cross"
                                >
                                <p class="contact-us__error-text">Required field</p>
                            </div>
                            <div id="last_nameLengthError" class="contact-us__error">
                                <img
                                    @click="clearInputAndErrors('last_name')"
                                    class="contact-us__error-cross"
                                    src="../assets/images/cross.svg"
                                    alt="cross"
                                >
                                <p class="contact-us__error-text">Max input length 1000</p>
                            </div>
                        </div>
                    </div>
                    <div class="contact-us__label-wrapper">
                        <input id="corporate_email" v-model.trim="requireData.corporate_email" class="input input-contact-us"
                            type="email"
                            @blur="activeInputName = null"
                            @input="handleInput"
                            @change="handleInput"
                            @keyup.delete="handleInput"
                        >
                        <img v-if="isEmailValid
                        && requireData.corporate_email.length > 0
                        && checkInputForErrors('corporate_emailError')
                        && checkInputForErrors('corporate_emailValidationError')
                        && checkInputForErrors('corporate_emailLengthError')"
                            class="sign-up-researcher__email-check"
                            src="../assets/images/greenCheck.svg"
                            alt=""
                        >
                        <img v-if="isEmailValid === false && requireData.corporate_email.length > 0"
                            @click="clearInputAndErrors('corporate_email')"
                            class="contact-us__email-check"
                            src="../assets/images/cross.svg"
                            alt="cross"
                        >
                        <label for="corporate_email" class="label" v-bind:class="{ activeInput: requireData.corporate_email !== isEmptyInput || activeInputName === 'corporate_email'}">Corporate Email</label>
                        <div id="corporate_emailError" class="contact-us__error">
                            <img
                                @click="clearInputAndErrors('corporate_email')"
                                class="contact-us__error-cross"
                                src="../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="contact-us__error-text">Required field</p>
                        </div>
                        <div id="corporate_emailLengthError" class="contact-us__error">
                            <img
                                @click="clearInputAndErrors('corporate_email')"
                                class="contact-us__error-cross"
                                src="../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="contact-us__error-text">Max input length 1000</p>
                        </div>
                        <div id="corporate_emailValidationError" class="contact-us__error">
                            <img
                                @click="clearInputAndErrors('corporate_email')"
                                class="contact-us__error-cross"
                                src="../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="contact-us__error-text">Please enter a valid email address</p>
                        </div>
                    </div>
                    <div class="contact-us__label-wrapper">
                        <input
                            id="company" v-model.trim="requireData.company"
                            class="input input-contact-us"
                            type="text"
                            @blur="activeInputName = null"
                            @input="handleInput"
                            @change="handleInput"
                            @keyup.delete="handleInput"
                        >
                        <img v-if="requireData.company.length > 0"
                            @click="clearInputAndErrors('company')"
                            class="contact-us__email-check"
                            src="../assets/images/cross_grey.svg"
                            alt="cross"
                        >
                        <label for="company" class="label" v-bind:class="{ activeInput: requireData.company !== isEmptyInput || activeInputName === 'company'}">Company</label>
                        <div id="companyError" class="contact-us__error">
                            <img
                                @click="clearInputAndErrors('company')"
                                class="contact-us__error-cross"
                                src="../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="contact-us__error-text">Required field</p>
                        </div>
                        <div id="companyLengthError" class="contact-us__error">
                            <img
                                @click="clearInputAndErrors('company')"
                                class="contact-us__error-cross"
                                src="../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="contact-us__error-text">Max input length 1000</p>
                        </div>
                    </div>
                    <div class="contact-us__input-wrapper contact-us__input-wrapper--margin">
                        <h6 class="contact-us__input-wrapper-title">REASON FOR CONTACT:</h6>
                        <label class="contact-us__label-select">
                            <select v-model="restData.reason_for_contact" class="contact-us__select" name="" id="">
                                <option value="I would like to talk to sales">I would like to talk to sales</option>
                                <option value="I would like a product demo">I would like a product demo</option>
                                <option value="I would like to partner with Oversecured">I would like to become a partner</option>
                                <option value="I am an existing customer">I am an existing customer</option>
                            </select>
                        </label>
                    </div>
                    <button class="contact-us__btn-submit" type="submit">Send</button>
                </form>
                <div v-if="loading" class="contact-us__spinner">
                    <Spinner/>
                </div>
            </div>
            <div class="contact-us__or-contact">
                <h6 class="contact-us__or-contact-title">OR CONTACT:</h6>
                <a class="contact-us__or-contact-link" href="https://support.oversecured.com/hc/en-us/requests/new"
                   target="_blank" rel="noopener">Support</a>
            </div>
        </div>
    </b-container>
</template>
<script>
import Spinner from "@/components/Spinner";

import {
    checkFormInputsForCompleteness,
    checkFormInputsForErrors,
    checkFormInputsLength,
    checkEmailValidation,
    checkInputForErrors,
    validateEmail,
    hideErrors
} from "@/services/functions";
import {successNotification, failNotification} from '@/services/notificationService/notificationService';

export default {
    components: {
        Spinner
    },
    data() {
        return {
            requireData: {
                first_name: "",
                last_name: "",
                corporate_email: "",
                company: ""
            },
            restData: {
                reason_for_contact: "I would like to talk to sales",
            },
            loading: false,
            isEmptyInput: "",
            isEmailValid: null,
            activeInputName: null,
        }
    },
    methods: {
        checkInputForErrors,
        clearInputAndErrors(inputField) {
            if (inputField === 'corporate_email') {
                this.requireData.corporate_email = ''
                hideErrors(['emailError', 'emailValidationError'])
            } else if (inputField === 'first_name') {
                this.requireData.first_name = ''
                hideErrors(['first_nameError', 'first_nameLengthError'])
            } else if (inputField === 'last_name') {
                this.requireData.last_name = ''
                hideErrors(['last_nameError', 'last_nameLengthError'])
            } else if (inputField === 'company') {
                this.requireData.company = ''
                hideErrors(['companyError', 'companyLengthError'])
            }
        },
        async handleForm() {
            checkFormInputsForErrors(this.requireData, 'contact-us__input--error')
            checkFormInputsLength(this.requireData, 'contact-us__input--error')

            if (this.requireData.corporate_email) {
                checkEmailValidation(
                    {corporate_email: this.requireData.corporate_email},
                    'contact-us__input--error'
                )
            }

            if (checkFormInputsForCompleteness(this.requireData) && validateEmail(this.requireData.corporate_email)) {
                await this.sendData()
            }
        },
        async sendData() {
            this.loading = true
            try {
                const result = await this.$store.dispatch(
                    'user/sendBusinessContact',
                    {
                        ...this.requireData,
                        ...this.restData,
                        source: 'Portal'
                    }
                )

                if (result.status === 204 || result.status === 200) {
                    this.requireData.first_name = ""
                    this.requireData.last_name = ""
                    this.requireData.corporate_email = ""
                    this.requireData.company = ""
                    this.restData.reason_for_contact = "I would like to talk to sales"

                    hideErrors(['emailError', 'emailValidationError', 'first_nameError', 'first_nameLengthError', 'last_nameError', 'last_nameLengthError', 'companyError', 'companyLengthError'])
                }

                this.loading = false
                this.successNotification('contactUsSend')
            } catch (error) {
                this.loading = false
                console.log(error)
                this.failNotification()
            }
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id
        },
        handleInput(event) {
            this.checkKeyEvent(event)
            this.isEmailValid = validateEmail(this.requireData.corporate_email)

            let inputField = event.target.id
            if (inputField === 'corporate_email') {
                hideErrors([`${inputField}Error`, `${inputField}ValidationError`, `${inputField}LengthError`])
                document.getElementById(`${inputField}`).style.border = '1px solid #F0F0F0'
            } else {
                hideErrors([`${inputField}Error`, `${inputField}LengthError`])
                document.getElementById(`${inputField}`).style.border = '1px solid #F0F0F0'
            }
        },
        successNotification,
        failNotification
    }
}
</script>
<style lang="scss">
#phone_label {
    margin-bottom: 30px;
}

::placeholder {
  color: #B2B9C4 !important;
}

.country-selector__label, .input-tel__label {
    color: #B2B9C4 !important;
    font-size: 12px !important;
    font-family: Cabin !important;
    top: -21px !important;
}

.country-selector__input, .input-tel__input {
    border-color:#F0F0F0 !important;
    color: #242424 !important;
    padding-top: 0px !important;
    font-size: 14px !important;
}

.input-tel.has-value .input-tel__input {
    padding-right: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input-contact-us {
    overflow: hidden;
    text-overflow: ellipsis;
}

.contact-us {
    width: 100%;
    max-width: 700px;
    display: flex;
    margin: 60px 0;

    &__spinner {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.7);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        div.spinner-wrapper {
            height: 100%;
        }
    }

    &__alert-message {
        position: absolute;
    }

    &__container {
        display: flex;
        justify-content: center;
    }

    &__wrapper {
        width: 100%;
        position: relative;
    }

    &__title {
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 69px;
        line-height: 121.5%;
        color: #242424;
        margin-bottom: 60px;
    }

    &__or-contact {
        margin: auto 0 auto 10px;
        max-width: 120px;
        width: 100%;
        background-color: #FAFBFC;
        padding: 10px;
        box-sizing: border-box;
    }

    &__or-contact-title {
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 121.5%;
        color: #242424;
    }

    &__or-contact-link {
        font-family: Cabin, serif;
        font-style: normal;
        font-size: 18px;
        color: #1FA8C7;
        margin: 0;

        &:hover {
            color: #1FA8C7;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__input-wrapper {
        width: 100%;
        display: flex;
        align-items: center;

        &--margin {
            margin-bottom: 30px;
        }
    }

    &__input {

        &--error {
            border: 1px solid #FB8D62;
            border-radius: 5px;
        }
    }

    &__input-wrapper-title {
        width: 35%;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
    }

    &__label-select {
        width: 65%;
    }

    &__label-wrapper {
        position: relative;
        width: 100%;

        &--margin{
            margin-right: 20px;
        }
    }

    &__select {
        border: 1px solid #F0F0F0;
        box-sizing: border-box;
        border-radius: 5px;
        min-height: 40px;
        font-family: Cabin, serif;
        font-style: normal;
        font-size: 18px;
        width: 100%;
        padding-left: 14px;
    }

    &__btn-submit {
        background: #242424;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: #FFFFFF;
        max-width: 215px;
        padding: 12px 0;
        transition: 0.3s;
        border: 2px solid #242424;

        &:hover {
            color: black;
            background-color: white;
        }

        &:active {
            border: 2px solid #242424;
        }
    }

    &__email-check {
        position: absolute;
        bottom: 45px;
        right: 20px;
        cursor: pointer;
    }

    &__tel-check {
        position: absolute;
        bottom: 14px;
        right: 20px;
        cursor: pointer;
        z-index: 10;
    }

    &__error {
        width: 100%;
        max-width: 831px;
        position: relative;
    }

    &__error-cross {
        position: absolute;
        top: -56px;
        right: 20px;
        cursor: pointer;
    }

    &__error-text {
        position: absolute;
        top: -30px;
        left: 3px;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 150%;
        color: #FB8D62;
        margin: 0;
    }
}

#first_nameError,
#first_nameLengthError,
#last_nameError,
#last_nameLengthError,
#corporate_emailError,
#corporate_emailLengthError,
#corporate_emailValidationError,
#companyError,
#companyLengthError,
#job_titleError,
#job_titleLengthError {
    display: none;
}

@media (max-width: 768px) {
    .contact-us {
        &__input-wrapper {
            align-items: flex-start;
            flex-direction: column;
        }

        &__input-wrapper-title {
            width: 100%;
        }

        &__label-select {
            width: 100%;
        }

        &__select {
            width: 100%;
        }
    }
}

@media (max-width: 575px) {
    .contact-us {
        margin: 40px 0;
        flex-direction: column;
        padding: 0 20px;

        &__container {
            display: flex;
            justify-content: center;
        }

        &__wrapper {
            width: 100%;
        }

        &__title {
            font-size: 40px;
            margin-bottom: 30px;
        }

        &__or-contact {
            margin: 30px 0 0 0;

            max-width: 100%;
            width: 100%;
            background-color: #FAFBFC;
            padding: 10px;
            box-sizing: border-box;
        }

        &__or-contact-title {
            font-size: 18px;
        }

        &__or-contact-link {
            font-size: 16px;
        }

        &__input-wrapper {
            width: 100%;
            display: flex;
            align-items: center;

            &--margin {
                margin-bottom: 30px;
            }
        }

        &__label {
            &--margin {
                margin-right: 0;
            }
        }

        &__label-wrapper {
            &--margin {
                margin-right: 0;
            }
        }

        &__label-select {
            width: 100%;
        }

        &__btn-submit {
            font-size: 20px;
            max-width: 150px;
            padding: 10px 0;
        }
    }
}
</style>
