<template>
    <div class="markdown">
        <b-nav-item :to="{ path: `${makeLinkToAnchor('vulnerabilities')}`}" class="md__link-wrapper">
<!--            <p class="md__anchor">#</p>-->
            <h2 class="md__item-title">Working with vulnerabilities</h2>
        </b-nav-item>
        <h3 :id="makeAnchor('Possible scan statuses')"><a
            :href="makeSubLinkToAnchor('Possible scan statuses', 'vulnerabilities')">Possible scan statuses</a></h3>
        <table class="table">
            <thead>
            <tr>
                <th>Status</th>
                <th>Comments</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CREATED</td>
                <td>app has only just been created, and will soon be added to the queue for scanning
                </td>
            </tr>
            <tr>
                <td>IN_QUEUE</td>
                <td>task is in the queue</td>
            </tr>
            <tr>
                <td>SCANNING</td>
                <td>the scanning kernel is currently searching for vulnerabilities in the app</td>
            </tr>
            <tr>
                <td>FINISH</td>
                <td>scanning is finished and the report can be studied</td>
            </tr>
            <tr>
                <td>ERROR</td>
                <td>an error occurred for some reason. This happens very rarely, but it does happen. We
                    study
                    the causes and fix the scanning kernel on a daily basis.
                </td>
            </tr>
            </tbody>
        </table>
        <h3 :id="makeAnchor('General report statistics')"><a
            :href="makeSubLinkToAnchor('General report statistics', 'vulnerabilities')">General report statistics</a></h3>
        <p>The general statistics include app data such as the application ID, version number, and
            number of
            vulnerabilities by severity and category.</p>
        <p><strong>Endpoints</strong></p>
        <ul>
            <li><code>GET /v1/integrations/{integration_id}/branches/{branch_name}/versions/{version_id}</code></li>
            <li><code>GET /v1/single_scans/{scan_id}</code></li>
        </ul>
        <p><strong>Input parameters</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>integration_id</td>
                <td>string(uuid)</td>
                <td>Integration ID, generated automatically when the Integration is created</td>
            </tr>
            <tr>
                <td>branch_name</td>
                <td>string</td>
                <td>Branch name, created by the user. <code>main</code> is the default</td>
            </tr>
            <tr>
                <td>version_id</td>
                <td>string(uuid)</td>
                <td>Version ID, generated automatically when the new version is uploaded</td>
            </tr>
            <tr>
                <td>scan_id</td>
                <td>string(uuid)</td>
                <td>scan ID</td>
            </tr>
            </tbody>
        </table>
        <p><strong>JSON response</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>file_name</td>
                <td>string</td>
                <td>original name of the app file passed by the user</td>
            </tr>
            <tr>
                <td>label</td>
                <td>string</td>
                <td>app label</td>
            </tr>
            <tr>
                <td>platform</td>
                <td>string</td>
                <td>mobile platform name</td>
            </tr>
            <tr>
                <td>app_id</td>
                <td>string</td>
                <td>app ID, for Android it will be a package value</td>
            </tr>
            <tr>
                <td>version_id</td>
                <td>string</td>
                <td>name of the app version</td>
            </tr>
            <tr>
                <td>status</td>
                <td>string</td>
                <td>scan status</td>
            </tr>
            <tr>
                <td>is_owner</td>
                <td>boolean</td>
                <td>flag showing whether the current user is the owner of the report</td>
            </tr>
            <tr>
                <td>shared</td>
                <td>boolean</td>
                <td>flag showing whether the report is public. For Integrations reports always
                    <code>false</code></td>
            </tr>
            <tr>
                <td>price</td>
                <td>object</td>
                <td>price, for Integrations always 0</td>
            </tr>
            <tr>
                <td>scan_completed_at</td>
                <td>string</td>
                <td>date and time when the scan was completed</td>
            </tr>
            <tr>
                <td>created_at</td>
                <td>string</td>
                <td>date and time when the scan was created on the platform</td>
            </tr>
            <tr>
                <td>statistics</td>
                <td>array</td>
                <td>severity statistics, containing the number of vulnerabilities in each group</td>
            </tr>
            <tr>
                <td>categories</td>
                <td>array</td>
                <td>statistics by category, containing a
                    <b-nav-item
                        :to="{ path: `${makeLinkToAnchor('profile-and-categories')}#${makeAnchor('List of categories')}`}">
                        description of the category
                    </b-nav-item> and a <code>count</code> field indicating the number of
                    vulnerabilities
                    in that category
                </td>
            </tr>
            </tbody>
        </table>
        <p><strong>Using curl</strong></p>
        <div class="code-toolbar"><pre class="  language-metadata"><code class="  language-metadata">curl https://api.oversecured.com/v1/integrations/{integration_id}/branches/{branch_name}/versions/{version_id} \
  -H 'Authorization: {api_token}'
</code></pre>

        </div>
        <p><strong>Example response</strong></p>
        <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
            class="token punctuation">{</span>
  <span class="token property">"file_name"</span><span class="token operator">:</span> <span class="token string">"ovaa_1.1.apk"</span><span
                class="token punctuation">,</span>
  <span class="token property">"label"</span><span class="token operator">:</span> <span class="token string">"Oversecured OVAA"</span><span
                class="token punctuation">,</span>
  <span class="token property">"platform"</span><span class="token operator">:</span> <span class="token string">"android"</span><span
                class="token punctuation">,</span>
  <span class="token property">"app_id"</span><span class="token operator">:</span> <span class="token string">"oversecured.ovaa"</span><span
                class="token punctuation">,</span>
  <span class="token property">"version_id"</span><span class="token operator">:</span> <span
                class="token string">"1.0"</span><span class="token punctuation">,</span>
  <span class="token property">"status"</span><span class="token operator">:</span> <span
                class="token string">"FINISH"</span><span class="token punctuation">,</span>
  <span class="token property">"is_owner"</span><span class="token operator">:</span> <span
                class="token boolean">true</span><span class="token punctuation">,</span>
  <span class="token property">"shared"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
  <span class="token property">"price"</span><span class="token operator">:</span> <span
                class="token punctuation">{</span>
    <span class="token property">"currency"</span><span class="token operator">:</span> <span
                class="token string">"USD"</span><span class="token punctuation">,</span>
    <span class="token property">"amount"</span><span class="token operator">:</span> <span
                class="token number">0</span>
  <span class="token punctuation">}</span><span class="token punctuation">,</span>
  <span class="token property">"scan_completed_at"</span><span class="token operator">:</span> <span
                class="token string">"2020-11-03T11:39:58.849Z"</span><span
                class="token punctuation">,</span>
  <span class="token property">"created_at"</span><span class="token operator">:</span> <span class="token string">"2020-11-03T01:24:48.520Z"</span><span
                class="token punctuation">,</span>
  <span class="token property">"statistics"</span><span class="token operator">:</span> <span class="token punctuation">[</span><span
                class="token punctuation">{</span>
    <span class="token property">"severity"</span><span class="token operator">:</span> <span class="token string">"high"</span><span
                class="token punctuation">,</span>
    <span class="token property">"count"</span><span class="token operator">:</span> <span class="token number">1</span>
    <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
    <span class="token property">"severity"</span><span class="token operator">:</span> <span class="token string">"medium"</span><span
                class="token punctuation">,</span>
    <span class="token property">"count"</span><span class="token operator">:</span> <span class="token number">0</span>
    <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
    <span class="token property">"severity"</span><span class="token operator">:</span> <span
                class="token string">"low"</span><span class="token punctuation">,</span>
    <span class="token property">"count"</span><span class="token operator">:</span> <span class="token number">0</span>
    <span class="token punctuation">}</span>
  <span class="token punctuation">]</span><span class="token punctuation">,</span>
  <span class="token property">"categories"</span><span class="token operator">:</span> <span class="token punctuation">[</span><span
                class="token punctuation">{</span>
    <span class="token property">"id"</span><span class="token operator">:</span> <span class="token string">"cd85663f-49fd-4838-ba72-80c73aa161ca"</span><span
                class="token punctuation">,</span>
    <span class="token property">"position"</span><span class="token operator">:</span> <span
                class="token number">1000</span><span class="token punctuation">,</span>
    <span class="token property">"severity"</span><span class="token operator">:</span> <span class="token string">"high"</span><span
                class="token punctuation">,</span>
    <span class="token property">"title"</span><span class="token operator">:</span> <span class="token string">"Arbitrary code execution"</span><span
                class="token punctuation">,</span>
    <span class="token property">"description"</span><span class="token operator">:</span> <span class="token string">"Arbitrary code execution gives an attacker unrestricted capabilities and the ability to perform any actions in the context of an attacked application. The attacker thus gains access to all the application's functions and to any sensitive information to which the application has access."</span><span
                class="token punctuation">,</span>
    <span class="token property">"remediation"</span><span class="token operator">:</span> <span class="token string">"To avoid arbitrary code execution, the application should sanitize all data received or change its architecture to prevent unintended access to sensitive components."</span><span
                class="token punctuation">,</span>
    <span class="token property">"links"</span><span class="token operator">:</span> <span
                class="token punctuation">[</span>
    <span class="token string">"https://blog.oversecured.com/Android-arbitrary-code-execution-via-third-party-package-contexts/"</span>
    <span class="token punctuation">]</span><span class="token punctuation">,</span>
    <span class="token property">"count"</span><span class="token operator">:</span> <span class="token number">1</span>
  <span class="token punctuation">}</span><span class="token punctuation">]</span>
<span class="token punctuation">}</span>
</code></pre>

        </div>
        <h3 :id="makeAnchor('Listing of vulnerabilities')"><a
            :href="makeSubLinkToAnchor('Listing of vulnerabilities', 'vulnerabilities')">Listing of vulnerabilities</a></h3>
        <p>Vulnerability listings are returned sorted by category in decreasing order of severity (the
            category’s <code>position</code> field).</p>
        <p><strong>Endpoints</strong></p>
        <ul>
            <li><code>GET
                /v1/integrations/{integration_id}/branches/{branch_name}/versions/{version_id}/vulnerabilities?folder_name={folder_name}&amp;limit={limit}&amp;page={page}&amp;severity_filter={severity_filter}&amp;category_filter={category_filter}</code>
            </li>
            <li><code>GET /v1/single_scans/{scan_id}/vulnerabilities?folder_name={folder_name}&amp;limit={limit}&amp;page={page}&amp;severity_filter={severity_filter}&amp;category_filter={category_filter}</code>
            </li>
        </ul>
        <p><strong>Input parameters</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>integration_id</td>
                <td>string(uuid)</td>
                <td>Integration ID, generated automatically when the Integration is created</td>
            </tr>
            <tr>
                <td>branch_name</td>
                <td>string</td>
                <td>Branch name, created by the user. <code>main</code> is the default</td>
            </tr>
            <tr>
                <td>version_id</td>
                <td>string(uuid)</td>
                <td>Version ID, generated automatically when the new version is uploaded</td>
            </tr>
            <tr>
                <td>scan_id</td>
                <td>string(uuid)</td>
                <td>scan ID</td>
            </tr>
            <tr>
              <td>folder_name (optional)</td>
              <td>string</td>
              <td>Folder name. If not specified, the default <code>All Vulnerabilities</code> will be used, which means all vulnerabilities from all folders except <code>False Positives</code></td>
            </tr>
            <tr>
                <td>severity_filter (optional)</td>
                <td>string</td>
                <td>comma-separated severity names</td>
            </tr>
            <tr>
                <td>category_filter (optional)</td>
                <td>string</td>
                <td>comma-separated category names</td>
            </tr>
            </tbody>
        </table>
        <p>The OR operator is applied between the elements of <code>severity_filter</code> and
            <code>category_filter</code>,
            and the AND operator between the two filters. Thus, if for example
            <code>severity_filter=medium,low</code> and
            <code>category_filter=cd85663f-49fd-4838-ba72-80c73aa161ca</code>
            (category <code>Arbitrary code execution</code> with <code>severity=high</code>), then an
            empty
            list
            will be returned. These fields are optional. If they are not used, no filter is applied.</p>
        <p><strong>JSON response</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>count</td>
                <td>integer</td>
                <td>total number of vulnerabilities</td>
            </tr>
            <tr>
                <td>rows</td>
                <td>array</td>
                <td>array of vulnerabilities on the given page</td>
            </tr>
            </tbody>
        </table>
        <p><strong>JSON object for the vulnerability</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>id</td>
                <td>string(uuid)</td>
                <td>ID of the vulnerability</td>
            </tr>
            <tr>
                <td>category_id</td>
                <td>string(uuid)</td>
                <td>ID of the category to which it belongs</td>
            </tr>
            <tr>
                <td>hash</td>
                <td>string(sha256)</td>
                <td>unique vulnerability hash. You can use it to track the vulnerability across multiple reports/versions as the Oversecured platform does</td>
            </tr>
            <tr>
                <td>blocks</td>
                <td>array</td>
                <td>array of code blocks</td>
            </tr>
            </tbody>
        </table>
        <p>Each vulnerability can have either no code blocks at all (if, for example, the scan is not of
            the
            file’s contents but of the file itself, e.g. Android key store), one code block, or more
            than
            one
            (when what is shown is a trace of data from several files or from several places in one
            file).</p>
        <p><strong>JSON object for the code block</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>file</td>
                <td>string</td>
                <td>relative path to the file within the app</td>
            </tr>
            <tr>
                <td>source_type</td>
                <td>string</td>
                <td>type of the file, e.g. <code>java</code> or <code>xml</code>. In the case of an
                    unknown
                    type
                    the value is <code>unknown</code></td>
            </tr>
            <tr>
                <td>lines</td>
                <td>array</td>
                <td>array of lines of code</td>
            </tr>
            </tbody>
        </table>
        <p>All lines are in order within each block. The <code>is_matched</code> field indicates whether
            the
            given line contains a listing or trace of vulnerable code or whether it is only used as an
            environment.</p>
        <p><strong>Example response</strong></p>
        <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
            class="token punctuation">{</span>
  <span class="token property">"count"</span><span class="token operator">:</span> <span
                class="token number">1</span><span class="token punctuation">,</span>
  <span class="token property">"rows"</span><span class="token operator">:</span> <span
                class="token punctuation">[</span><span class="token punctuation">{</span>
    <span class="token property">"id"</span><span class="token operator">:</span> <span class="token string">"fb3b9867-1590-4a5b-96c2-c91830ac152d"</span><span
                class="token punctuation">,</span>
    <span class="token property">"category_id"</span><span class="token operator">:</span> <span class="token string">"cd85663f-49fd-4838-ba72-80c73aa161ca"</span><span
                class="token punctuation">,</span>
    <span class="token property">"hash"</span><span class="token operator">:</span> <span class="token string">"b1ffe008b0a4e2e2eb7fae08c9eeafa737ef54fc1a1c69eaea8e0c6ce3212c64"</span><span
                class="token punctuation">,</span>
    <span class="token property">"blocks"</span><span class="token operator">:</span> <span
                class="token punctuation">[</span><span class="token punctuation">{</span>
      <span class="token property">"file"</span><span class="token operator">:</span> <span class="token string">"oversecured/ovaa/OversecuredApplication.java"</span><span
                class="token punctuation">,</span>
      <span class="token property">"source_type"</span><span class="token operator">:</span> <span class="token string">"java"</span><span
                class="token punctuation">,</span>
      <span class="token property">"lines"</span><span class="token operator">:</span> <span
                class="token punctuation">[</span><span class="token punctuation">{</span>
        <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
        <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">12</span><span class="token punctuation">,</span>
        <span class="token property">"content"</span><span class="token operator">:</span> <span class="token string">"  }"</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
        <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
        <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">13</span><span class="token punctuation">,</span>
        <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">""</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
        <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
        <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">14</span><span class="token punctuation">,</span>
        <span class="token property">"content"</span><span class="token operator">:</span> <span class="token string">"  private void invokePlugins() {"</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
        <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">true</span><span class="token punctuation">,</span>
        <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">15</span><span class="token punctuation">,</span>
        <span class="token property">"content"</span><span class="token operator">:</span> <span class="token string">"    for (PackageInfo info : getPackageManager().getInstalledPackages(128)) {"</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
        <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">true</span><span class="token punctuation">,</span>
        <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">16</span><span class="token punctuation">,</span>
        <span class="token property">"content"</span><span class="token operator">:</span> <span class="token string">"      String packageName = info.packageName;"</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
        <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
        <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">17</span><span class="token punctuation">,</span>
        <span class="token property">"content"</span><span class="token operator">:</span> <span class="token string">"      Bundle meta = info.applicationInfo.metaData;"</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
        <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
        <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">18</span><span class="token punctuation">,</span>
        <span class="token property">"content"</span><span class="token operator">:</span> <span class="token string">"      if (packageName.startsWith(\"oversecured.plugin.\") &amp;&amp; meta.getInt(\"version\", -1) &gt;= 10) {"</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
        <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
        <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">19</span><span class="token punctuation">,</span>
        <span class="token property">"content"</span><span class="token operator">:</span> <span class="token string">"        try {"</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
        <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">true</span><span class="token punctuation">,</span>
        <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">20</span><span class="token punctuation">,</span>
        <span class="token property">"content"</span><span class="token operator">:</span> <span class="token string">"          createPackageContext(packageName, 3).getClassLoader().loadClass(\"oversecured.plugin.Loader\").getMethod(\"loadMetadata\", Context.class).invoke(null, this);"</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
        <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
        <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">21</span><span class="token punctuation">,</span>
        <span class="token property">"content"</span><span class="token operator">:</span> <span class="token string">"        } catch (Exception e) {"</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
        <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
        <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">22</span><span class="token punctuation">,</span>
        <span class="token property">"content"</span><span class="token operator">:</span> <span class="token string">"          throw new RuntimeException(e);"</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                class="token punctuation">{</span>
        <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
        <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">23</span><span class="token punctuation">,</span>
        <span class="token property">"content"</span><span class="token operator">:</span> <span class="token string">"        }"</span>
      <span class="token punctuation">}</span><span class="token punctuation">]</span>
    <span class="token punctuation">}</span><span class="token punctuation">]</span>
  <span class="token punctuation">}</span><span class="token punctuation">]</span>
<span class="token punctuation">}</span>
</code></pre>

        </div>
        <p><strong>Using curl</strong> <br> With filters</p>
        <div class="code-toolbar"><pre class="  language-metadata"><code class="  language-metadata">curl 'https://api.oversecured.com/v1/integrations/{integration_id}/branches/{branch_name}/versions/{version_id}/vulnerabilities?folder_name={folder_name}&amp;limit={limit}&amp;page={page}&amp;severity_filter={severity_filter}&amp;category_filter={category_filter}' \
  -H 'Authorization: {api_token}'
</code></pre>

        </div>
        <p>Without filters</p>
        <div class="code-toolbar"><pre class="  language-metadata"><code class="  language-metadata">curl 'https://api.oversecured.com/v1/integrations/{integration_id}/branches/{branch_name}/versions/{version_id}/vulnerabilities?folder_name={folder_name}&amp;limit={limit}&amp;page={page}' \
  -H 'Authorization: {api_token}'
</code></pre>

        </div>
        <h3 :id="makeAnchor('Moving vulnerabilities between Folders')"><a
            :href="makeSubLinkToAnchor('Moving vulnerabilities between Folders', 'vulnerabilities')">Moving vulnerabilities between Folders</a></h3>
        <p>Moving a vulnerability to another folder in Single Scans or Integrations. It's important that if you move a vulnerability in an Integration, it will also be moved to the specified folder for all other versions of the Integration.</p>
        <p>When moved to the reserved <code>False Positives</code> folder, the vulnerability will no longer be included in any statistics and will only become available when the user moves it back to the list of valid vulnerabilities.</p>
        <p><strong>Endpoints</strong></p>
        <ul>
            <li><code>PUT /v1/single_scans/{scan_id}/vulnerabilities/{vulnerability_id}?folder_name={folder_name}</code></li>
            <li><code>PUT /v1/integrations/{integration_id}/branches/{branch_name}/versions/{version_id}/vulnerabilities/{vulnerability_id}?folder_name={folder_name}</code></li>
        </ul>
        <p><strong>Input parameters</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>scan_id</td>
                <td>string(uuid)</td>
                <td>Single Scan ID</td>
            </tr>
            <tr>
                <td>integration_id</td>
                <td>string(uuid)</td>
                <td>Integration ID, generated automatically when the Integration is created</td>
            </tr>
            <tr>
              <td>branch_name</td>
              <td>string(uuid)</td>
              <td>Branch name, created by the user. <code>main</code> is the default</td>
            </tr>
            <tr>
              <td>version_id</td>
              <td>string(uuid)</td>
              <td>ID of version</td>
            </tr>
            <tr>
                <td>vulnerability_id</td>
                <td>string(uuid)</td>
                <td>ID of the vulnerability</td>
            </tr>
            <tr>
                <td>folder_name</td>
                <td>string</td>
                <td>Folder name</td>
            </tr>
            </tbody>
        </table>

        <p><strong>Using curl</strong></p>
        <div class="code-toolbar"><pre class="  language-metadata"><code class="  language-metadata">curl https://api.oversecured.com/v1/single_scans/{scan_id}/vulnerabilities/{vulnerability_id}?folder_name={folder_name} \
  -X PUT \
  -H 'Authorization: {api_token}'
</code></pre>

        </div>
        <h3 :id="makeAnchor('Downloading a PDF report')"><a
            :href="makeSubLinkToAnchor('Downloading a PDF report', 'vulnerabilities')">Downloading a PDF report</a></h3>
        <p>The <code>severity_filter</code> and <code>category_filter</code> filters can also be applied
            to the PDF report, using the same rules as described above.</p>
        <p><strong>Endpoints</strong></p>
        <ul>
            <li><code>GET
                /v1/integrations/{integration_id}/branches/{branch_name}/versions/{version_id}/download/pdf?folder_name={folder_name}&amp;severity_filter={severity_filter}&amp;category_filter={category_filter}</code>
            </li>
            <li><code>GET /v1/single_scans/{scan_id}/download/pdf?folder_name={folder_name}&amp;severity_filter={severity_filter}&amp;category_filter={category_filter}</code>
            </li>
        </ul>
        <p><strong>Input parameters</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
                <tr>
                    <td>integration_id</td>
                    <td>string(uuid)</td>
                    <td>Integration ID, generated automatically when the Integration is created</td>
                </tr>
                <tr>
                    <td>branch_name</td>
                    <td>string</td>
                    <td>Branch name, created by the user. <code>main</code> is the default</td>
                </tr>
                <tr>
                <td>version_id</td>
                <td>string(uuid)</td>
                <td>Version ID, generated automatically when the new version is uploaded</td>
            </tr>
            <tr>
                <td>scan_id</td>
                <td>string(uuid)</td>
                <td>scan ID</td>
            </tr>
            <tr>
              <td>folder_name (optional)</td>
              <td>string</td>
              <td>Folder name. If not specified, the default <code>All Vulnerabilities</code> will be used, which means all vulnerabilities from all folders except <code>False Positives</code></td>
            </tr>
            <tr>
                <td>severity_filter (optional)</td>
                <td>string</td>
                <td>comma-separated severity names</td>
            </tr>
            <tr>
                <td>category_filter (optional)</td>
                <td>string</td>
                <td>comma-separated category names</td>
            </tr>
            </tbody>
        </table>
        <p><strong>Example response</strong>: The response is in <code>application/pdf</code> format.</p>
        <p><strong>Using curl</strong> <br> With filters</p>
        <div class="code-toolbar"><pre class="  language-metadata"><code class="  language-metadata">curl 'https://api.oversecured.com/v1/integrations/{integration_id}/branches/{branch_name}/versions/{version_id}/download/pdf?folder_name={folder_name}&amp;severity_filter={severity_filter}&amp;category_filter={category_filter}' \
  -H 'Authorization: {api_token}' -H 'Accept: application/pdf' \
  --output oversecured_report.pdf
</code></pre>

        </div>
        <p>Without filters</p>
        <div class="code-toolbar"><pre class="  language-metadata"><code class="  language-metadata">curl https://api.oversecured.com/v1/integrations/{integration_id}/branches/{branch_name}/versions/{version_id}/download/pdf?folder_name={folder_name} \
  -H 'Authorization: {api_token}' -H 'Accept: application/pdf' \
  --output oversecured_report.pdf
</code></pre>

        </div>
    </div>
</template>

<script>
import {makeAnchor, makeLinkToAnchor, makeSubLinkToAnchor} from "@/services/functions";

export default {
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor,
    }
}
</script>

<style scoped>

</style>
