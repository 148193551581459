<template>
<div>
    <div class="current-integration">
        <div class="current-integration__page-header" ref="header">
            <a class="current-integration__back-link" @click="$router.go(-1)">Back</a>
            <h3 class="current-integration__title">Integration History</h3>
        </div>
        <div v-if="integration && allVersions" class="current-integration__main-block">
            <div class="current-integration__main-block--header">
                <div class="current-integration__header-item--first">
                    <img id="integration_logo" alt="" @error="errorLoaded" @load="ShowSpinner = false"
                         :src="integration.signedUrl ? integration.signedUrl : DefaultLogo"
                    >
                </div>
                <div class="current-integration__header-item--second">
                    <p>{{ integration.integrationName }}</p>
                </div>
            </div>
            <div class="current-integration__main-block--header">
                <div class="current-integration__main-block--header-first">
                    <p>Version</p>
                </div>
                <div class="current-integration__main-block--header-second">
                    <p>Scan Completion Time</p>
                </div>
                <div class="current-integration__main-block--header-third">
                    <p>Version status</p>
                </div>
            </div>
            <p v-if="!allVersions.rows.length" class="empty-integration-versions-message">No version has been uploaded yet</p>
            <div v-else id="versionsList" class="current-integration__main-block--versions-list">
                <div class="current-integration__list-item" v-for="(version, index) in allVersions.rows" v-bind:key="index">
                    <div class="current-integration__list-item--first">
                        <p v-if="allVersions.rows.length > 0">{{ version.version }}</p>
                        <p v-else>-</p>
                    </div>
                    <div class="current-integration__list-item--second">
                        <p v-if="version.completionTime">{{ calculateDateForSettings(version.completionTime) }}</p>
                        <p v-else>-</p>
                    </div>
                    <div class="current-integration__list-item--third">
                        <p v-if="allVersions && allVersions.rows.length > 0"
                                :class="{
                                    'integration-history__deleted-status': getVersionStatus(version.status, version.isDeleted) === 'Deleted',
                                    'integration-history__expired-status': getVersionStatus(version.status, version.isDeleted) === 'Failed',
                                }">
                                    {{ getVersionStatus(version.status, version.isDeleted) }}
                        </p>
                        <p v-else>-</p>
                    </div>
                </div>
            </div>
            <b-pagination
                    v-model="currentPage"
                    v-if="allVersions.rows.length"
                    :total-rows="allVersions.count"
                    :per-page="limit"
                    class="pagination-general"
                    aria-controls="versionsList"
            >
                <template v-slot:ellipsis-text>___</template>
            </b-pagination>
        </div>
    </div>
    <div class="current-integration__spinner" v-if="showSpinner">
        <Spinner/>
    </div>
</div>
</template>
<script>
    import {mapGetters} from "vuex";
    import {VersionStatusEnum} from "../../utils/constants/common";
    import Spinner from '../Spinner'
    import { calculateDateForSettings } from "../../services/functions"
    import DefaultLogo from "../../assets/images/default-integration-icon.png";

    export default {
        components: {
            Spinner,
        },
        data() {
            return{
                DefaultLogo,
                showSpinner: false,
                currentPage: 1,
                limit: 10,
                integrationId: null,
            }
        },
        async beforeMount() {
            this.showSpinner = true
            if(window.innerWidth > 768){
                this.$router.push({ path: `/settings/integration-history` })
            }
            this.integrationId = this.$route.params.id
            await this.$store.dispatch('integrations/getIntegration', {
                id: this.integrationId
            })
            await this.$store.dispatch('integrations/loadAllIntegrationVersions', {
                integrationId: this.integrationId,
                limit: this.limit,
                page: this.currentPage
            })
            this.showSpinner = false
        },
        beforeDestroy() {
            this.$store.commit('integrations/setIntegration', null)
            this.$store.commit('integrations/setAllIntegrationVersions', null)
        },
        watch: {
            async currentPage() {
                this.showSpinner = true
                this.$store.commit('integrations/setAllIntegrationVersions', null)
                await this.$store.dispatch('integrations/loadAllIntegrationVersions', {
                    integrationId: this.integrationId,
                    limit: this.limit,
                    page: this.currentPage
                })
                this.$refs.header.scrollIntoView()
                this.showSpinner = false
            },
        },
        computed: {
            ...mapGetters('integrations', {
                allVersions: 'allIntegrationVersions',
                integration: 'integration'
            }),
        },
        methods: {
            calculateDateForSettings,
            errorLoaded() {
                this.ShowSpinner = false
                document.getElementById("integration_logo").src = this.DefaultLogo
            },
            getVersionStatus(status, isDeleted) {
                if (isDeleted) {
                    return 'Deleted'
                }
                switch (status) {
                    case VersionStatusEnum.IN_QUEUE:
                        return 'In queue'
                    case VersionStatusEnum.CREATED:
                        return 'Created'
                    case VersionStatusEnum.SCANNING:
                        return 'Scanning'
                    case VersionStatusEnum.FINISHED:
                        return 'Finished'
                    case VersionStatusEnum.ERROR:
                        return 'Failed'
                    default:
                        return ''
                }
            },
        }
    }
</script>
<style lang="scss">
    .current-integration{
        width: 100%;
        background-color: white;
        position: relative;
        padding-bottom: 20px;
        margin-top: -50px;
        transform: translateY(-4px);

        &__page-header {
            background-color: #FAFBFC;
            border-bottom: 1px solid #F0F0F0;
            font-size: 18px;
            line-height: 1.4;
        }

        &__back-link {
            display: inline;
            width: 30px;
            padding: 20px 0 10px 44px;
            color: #706969;
            position: relative;

            &::before {
                position: absolute;
                background-image: url('../../assets/images/current-integration_back-btn.svg');
                background-repeat: no-repeat;
                content: " ";
                width: 18px;
                height: 18px;
                color: orange;
                top: 25px;
                left: 20px
            }
        }

        &__title {
            font-weight: 700;
            line-height: 1.5;
            font-size: 18px;
            padding: 20px 0 20px 20px;
            margin: 0;
        }

        &__main-block {
            width: 100%;

            &--header{
                display: flex;
                padding: 20px 0;
                border-bottom: 1px solid #F0F0F0;
                text-align: center;
            }


            &--header-first {
                width: 100%;

                p {
                    margin: 0;
                    color: #B2B9C4;
                    font-size: 14px;
                }
            }

            &--header-second {
                min-width: 140px;
                width: 100%;
                margin: 0;
                color: #B2B9C4;
                font-size: 14px;

                p {
                    margin: 0;
                    color: #B2B9C4;
                    font-size: 14px;
                }
            }

            &--header-third {
                width: 100%;
                margin: 0;
                color: #B2B9C4;
                font-size: 14px;

                p {
                    margin: 0;
                    color: #B2B9C4;
                    font-size: 14px;
                }
            }

        }

        &__header-item--first {
                max-width: 80px;
                width: 100%;
                    img {
                        width: 25px;
                        height: 25px;
                        border-radius: 5px;
                    }
        }

        &__header-item--second {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            text-align: start;
            color: #242424;

            p {
                word-break: break-word;
                font-size: 18px;
                line-height: 1.4;
                margin: 0;
                max-width: 400px;
            }
        }

        &__integ-status {
            padding: 0;
            margin: 0;
        }

        &__spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            background-color: rgba(255,255,255,0.7);
            z-index: 1000;
        }

        &__list-item {
            display: flex;
            padding: 20px 0;
            font-size: 13px;
            line-height: 1.4;
            text-align: center;
            border-bottom: 1px solid #F0F0F0;

            div {
                p {
                    margin: 0;
                }
            }

            &--first {
                width: 100%;
                color: #242424;
            }

            &--second {
                min-width: 140px;
                width: 100%;
                display: flex;
                justify-content: center;
                color: #706969;

                p {
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 130px;
                }
            }

            &--third {
                width: 100%;
                color: #242424;
            }
        }
    }

.empty-integration-versions-message {
    text-align: center;
    padding: 40px 0px 100px;
}

</style>
