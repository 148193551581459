<template>
    <div class="markdown">
        <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('bitrise')}`}" class="md__link-wrapper">
            <h2 class="md__item-title">Oversecured Step for Bitrise</h2>
        </b-nav-item>
        <p>These are the steps you need to follow to add Oversecured to Bitrise:</p>
        <ol>
            <li>Open your project Workflow on Bitrise</li>
            <li>Click the <strong>+</strong> icon to add a new Step</li>
            <li>Enter Oversecured or drag and drop the Step, by going to
                <a class="markdown__fit-link" href="https://www.bitrise.io/integrations/steps/oversecured">
                    https://www.bitrise.io/integrations/steps/oversecured
                </a>
            </li>
            <li>Fill out the <strong>API key</strong>, <strong>Integration ID</strong> and <strong>Branch</strong> fields
            </li>
            <li>Fill out the <strong>App File</strong> field. For Android, you may use <strong>$BITRISE_APK_PATH</strong> or specify a custom path to the APK/AAB file if the path isn't standard. For iOS, specify the path to zipped sources.
            </li>
        </ol>
        <img class="markdown__image" src="../../../assets/images/docs/bitrise_workflow.png" alt="Bitrise Workflow">
        <p><a href="https://github.com/oversecured/oversecured-bitrise-step">Plugin source code</a>.</p>
    </div>
</template>

<script>
import {makeAnchor, makeLinkToAnchor, makeLinkToAnchorCICD} from "../../../services/functions";
import {makeSubLinkToAnchor} from "@/services/functions";

export default {
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor,
        makeLinkToAnchorCICD,
    }
}
</script>

<style scoped>

</style>
