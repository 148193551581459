<template>
    <div class="main-demo">
        <div class="main-demo__container">
            <div
                class="main-demo__content"
                :class="step === 3 ? 'main-demo__content-calendly' : ''"
            >
                <div class="main-demo__title">
                    <p>Request a Demo</p>
                </div>
                <div class="main-demo__sub-title">
                    <p>Follow the simple 3 steps to complete your request. It will take you a few minutes</p>
                </div>
                <div class="main-demo__request">
                    <div
                        v-if="screenWidth <= mobileWidth"
                        class="indicators"
                        :class="{ 'indicators-mobile': screenWidth <= mobileWidth }"
                    >
                        <Indicator
                            :state="step === 1 ? 'active' : 'passed'"
                            :is-mobile="screenWidth < mobileWidth"
                        />
                        <Indicator
                            :state="step === 1 ? 'default' : (step === 2 ? 'active' : 'passed')"
                            :is-mobile="screenWidth < mobileWidth"
                        />
                        <Indicator
                            :state="step === 3 ? 'active' : 'default'"
                            :is-mobile="screenWidth < mobileWidth"
                        />
                    </div>
                    <div class="main-demo__request-steps">
                        <div class="step">
                            <div class="step__text">
                                <p class="step__title">
                                    Contact Person
                                </p>
                                <p class="step__sub-title">
                                    Share Your Details
                                </p>
                            </div>
                            <div class="step__img">
                                <ContactPerson :state="step === 1 ? 'default' : 'passed'" />
                            </div>
                        </div>
                        <div class="step">
                            <div class="step__text">
                                <p class="step__title">
                                    Company Details
                                </p>
                                <p class="step__sub-title">
                                    Provide Business Info
                                </p>
                            </div>
                            <div class="step__img">
                                <CompanyDetails :state="step === 1 ? 'default' : (step === 2 ? 'active' : 'passed')" />
                            </div>
                        </div>
                        <div class="step">
                            <div class="step__text">
                                <p class="step__title">
                                    Preferred Date
                                </p>
                                <p class="step__sub-title">
                                    Choose a Date
                                </p>
                            </div>
                            <div class="step__img">
                                <PreferredDate :state="step === 3 ? 'active' : 'default'" />
                            </div>
                        </div>
                    </div>
                    <div class="main-demo__request-form">
                        <div class="form">
                            <p class="step-number">
                                Step {{ step }} / 3
                            </p>
                            <div class="form-title">
                                <p>{{ getFormTitle(step) }}</p>
                            </div>
                            <div class="main-demo__inputs">
                                <div class="inputs-and-indicators">
                                    <div
                                        v-if="screenWidth > mobileWidth"
                                        class="indicators"
                                        :class="step === 3 ? 'indicators-calendly' : ''"
                                    >
                                        <Indicator
                                            :state="step === 1 ? 'active' : 'passed'"
                                            :is-mobile="screenWidth < mobileWidth"
                                        />
                                        <Indicator
                                            :state="step === 1 ? 'default' : (step === 2 ? 'active' : 'passed')"
                                            :is-mobile="screenWidth < mobileWidth"
                                        />
                                        <Indicator
                                            :state="step === 3 ? 'active' : 'default'"
                                            :is-mobile="screenWidth < mobileWidth"
                                        />
                                    </div>
                                    <Step1
                                        v-if="step === 1"
                                        :step-data="inputData"
                                        @updateInputData="updateInputData"
                                        @updateIsDataComplete="updateIsDataComplete(1, $event)"
                                        @updateIsDataValid="updateIsDataValid(1, $event)"
                                    />
                                    <Step2
                                        v-if="step === 2"
                                        :step-data="inputData"
                                        @updateInputData="updateInputData"
                                        @updateIsDataComplete="updateIsDataComplete(2, $event)"
                                        @updateIsDataValid="updateIsDataValid(2, $event)"
                                    />
                                    <Step3
                                        v-if="step === 3"
                                        :step-data="inputData"
                                    />
                                </div>
                                <div
                                    class="btn__wrapper"
                                    :class="{'btn__wrapper-single': step === 1}"
                                >
                                    <div
                                        v-if="step > 1"
                                        class="back-btn__wrapper"
                                    >
                                        <button
                                            class="back-btn"
                                            @click="handleGoToPreviousStep"
                                        >
                                            <img
                                                v-if="screenWidth > mobileWidth"
                                                src="../../assets/images/request-demo/arrow.svg"
                                                alt="arrow"
                                            >
                                            <img
                                                v-if="screenWidth <= mobileWidth"
                                                src="../../assets/images/request-demo/arrow-mobile.svg"
                                                alt="arrow"
                                            >
                                            Back
                                        </button>
                                    </div>
                                    <button
                                        v-if="!isLastStep"
                                        :class="{'next-btn': stepsData[step - 1].isDataComplete && !isLastStep && stepsData[step - 1].isDataValid, 'next-btn-disabled': !stepsData[step - 1].isDataComplete || isLastStep || !stepsData[step - 1].isDataValid}"

                                        :disabled="!stepsData[step - 1].isDataComplete || !stepsData[step - 1].isDataValid"
                                        @click="handleGoToNextStep"
                                    >
                                        Next Step
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ContactPerson from '../../assets/iconTemplates/ContactPerson.vue';
import CompanyDetails from '../../assets/iconTemplates/CompanyDetails.vue';
import PreferredDate from '../../assets/iconTemplates/PreferredDate.vue';
import Indicator from '../../assets/iconTemplates/Indicator.vue';
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';

export default {
    components: {
        Step1,
        Step2,
        Step3,
        ContactPerson,
        CompanyDetails,
        PreferredDate,
        Indicator,
    },
    data() {
        return {
            step: 1,
            inputData: {
                name: '',
                jobTitle: '',
                email: '',
                companyName: '',
                companySize: '',
                securityChallanges: '',
            },
            stepsData: [
                { isDataComplete: false, isDataValid: false },
                { isDataComplete: false, isDataValid: false },
            ],
            isLastStep: false,
            mobileWidth: 1000,
            screenWidth: window.innerWidth,

        };
    },
    watch: {
        step(newStep) {
            this.isLastStep = newStep === 3;
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleGoToNextStep() {
            this.step++;
        },
        handleGoToPreviousStep() {
            this.step--;
        },
        getFormTitle(step) {
            switch (step) {
                case 1:
                    return 'Let\'s start with your personal info';
                case 2:
                    return 'Please fill out some details about your company';
                case 3:
                    return ' ';
            }
        },
        handleResize() {
            this.screenWidth = window.innerWidth;
        },
        updateIsDataComplete(step, value) {
            this.stepsData[step - 1].isDataComplete = value;
        },
        updateIsDataValid(step, value) {
            this.stepsData[step - 1].isDataValid = value;
        },
        updateInputData(newData) {
            this.inputData = { ...this.inputData, ...newData };
        },
    },

};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables/variablesForRequestDemo";
@import "../../assets/css/fonts";

    .main-demo {
        background-color:#F6F6F9;
        padding-top: 3.75rem;
        overflow: hidden;

        &__container {
            display: flex;
            flex-direction: column;
        }

        &__content {
            display: flex;
            flex-direction: column;
            margin: 3.75rem 9rem 3.75rem 18.75rem;

            &-calendly {
                margin-right: 0rem;
            }
        }

        &__sub-title {
            @include Aspekta-font(400, $dynamic-demo-sub-title-font-size, normal, #797E86);
            letter-spacing: 0;
        }

        &__title, .form-title {
            @include Aspekta-font(650, $dynamic-demo-title-font-size, 130%, #242424);
            letter-spacing: 1.2px;
        }

        &__inputs {
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            width: 86%;
            margin-top: 2rem;
        }

        &__request {
            border-top: 2px solid #E8E7EF;
            margin-top: 4rem;
            display: flex;
            padding-left: 1.5rem;

            &-form {
                padding-top: 4rem;
                display: flex;
                flex-direction: column;
                flex: 4;
                border-left: 2px solid #E8E7EF;
            }

            &-steps {
                display: flex;
                flex: 1;
                flex-direction: column;
                gap: 5rem;
                padding-right: 3rem;
                padding-top: 4rem;
            }
        }

        .indicators {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 1%;
            gap: 141px;
            top: -89px;
            left: -75px;

            &-calendly {
                top: -30px;
            }
        }

        .form {
            padding-left: 4rem;
        }

        .inputs-and-indicators {
            display: flex;
        }

        .step {
            display: flex;
            justify-content: space-around;

            &__text {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            &__title {
                @include Aspekta-font(700, 18px, normal, #0C0D0E);
                letter-spacing: 0em;
            }

            &__sub-title {
                @include Aspekta-font(400, 14px, normal, #797E86);
                letter-spacing: 0em;
            }
        }

        .step-number {
            @include Aspekta-font(400, 14px, normal, #797E86);
            margin-bottom: 1rem;
        }

        .btn__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-single {
                justify-content: flex-end;
            }
        }

        .back-btn {
            @include Aspekta-font(700, $dynamic-demo-button-font-size, normal, #797E86);
            background: transparent;
            padding: 24px 30px 24px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
        }

        .next-btn {
            @include Aspekta-font(700, $dynamic-demo-button-font-size, normal, #FFF);
            padding: 18px 30px;
            gap: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-radius: 30px;
            background: #FF7A08;
            border: 2px solid #FF7A08;

            &-disabled {
                @include Aspekta-font(700, $dynamic-demo-button-font-size, normal, #D9D9D9);
                padding: 18px 30px;
                gap: 10px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                border-radius: 30px;
                background: #F9F9F9;
                border: 2px solid transparent;
            }

            &:hover {
                background: linear-gradient(0deg, rgba(255, 122, 8, 0.70) 0%, rgba(255, 122, 8, 0.70) 100%), var(--white, #FFF);
                border: 2px solid transparent;
            }

            &:active {
                color: #FF7A08;
                background: #FFF;
                border: 2px solid rgba(255, 122, 8, 0.30);
            }
        }

        .optional {
            @include Aspekta-font(300, 14px, 140%, rgba(121, 126, 134, 0.50));
        }

        .spinner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.7);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
        }

    }

    @media (max-width: 1000px) {
        .main-demo {
            &__content {
                margin: 4rem 1.25rem 6rem 1.25rem;
            }

            &__title {
                letter-spacing: 0.96px;
            }

            &__request {
                flex-direction: column;
                border-top: 1px solid #E8E7EF;
                margin-top: 3rem;
                padding-left: 0rem;

                &-steps {
                    display: none;
                }

                &-form {
                    border-left: none;
                    padding-top: 2rem;
                }
            }

            &__inputs {
                width: 100%;
                gap: 1.5rem;
                margin-top: 3rem;
            }

            .step-number, .form-title {
                text-align: center;
            }

            .form-title {
                letter-spacing: 0.8px;
            }

            .indicators {
                flex-direction: row;
                width: 100%;
                height: 1%;
                gap: 77px;
                top: -17px;
                left: 43px;

                &-mobile {
                    left: $dynamic-demo-indicators-left-position;
                }
            }

            .inputs-and-indicators {
                flex-direction: column;
            }

            .form {
                padding-left: 0rem;
            }

            .next-btn {
                font-weight: 650;
                padding: 15px 30px;

                &-disabled {
                    padding: 15px 30px;

                }
            }

            .back-btn {
                font-weight: 650;
                padding: 0px 40px 0px 1px;
            }
        }
    }

</style>
