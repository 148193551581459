<template>
    <div class="post__wrapper">
        <div class="post">
            <div class="post__header">
                <img :src="card.cover" alt="article-image">
            </div>
            <div class="post__article">
                <h2 class="post__title">{{ card.title }}</h2>
                <div class="post__meta">
                    <div
                        class="post__tags"
                    >
                        <img src="../../../assets/images/main/main-stay-informed-with-blog/circle.svg" alt="circle">
                        <span>{{ card.tags.length > 1 ? card.tags[1] : card.tags[0] }}</span>
                        <span class="separator">|</span>
                    </div>
                    <div class="post__minutes">
                        {{ card.minutesRead + ' min read' }}
                    </div>
                </div>
                <div class="post__description">
                    <p> {{ card.description }}</p>
                </div>
                <div class="post__btn">
                    <a
                        :href="card.url"
                        target="_blank"
                    >
                        <OrangeArrowButton
                            :title="'Read More'"
                            :display-icon="false"
                            :is-blog-card="true"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import OrangeArrowButton from '../OrangeArrowButton.vue';

export default {

    components: {
        OrangeArrowButton,
    },
    props: {
        card: Object,
    },
};

</script>

<style lang="scss" scoped>
@import "../../../assets/css/variables";
@import "../../../assets/css/fonts";

.post {

    height: 480px;
    width: 410px;
    transition: transform .6s;

    &__wrapper {
        margin: 0 16px;
        
        overflow: hidden;
        position: relative;
    }

    &__article {
        display: flex;
        flex-direction: column;
        height: 480px;
        justify-content: space-between;
    }

    &__title {
        flex-grow: 1;
        max-height: 150px;
        @include Aspekta-font(700, $dynamic-informed-card-title-font-size, 130%, #0C0D0E);
        letter-spacing: 0em;
        transition: flex-grow .6s;
    }

    &__wrapper:hover .post {
       transform: translateY(-276px);
    }

    &__wrapper:hover &__title {
        flex-grow: 0;
    }

    &__wrapper:hover &__description {
        opacity: 1;
    }

    &__meta {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    &__header {
        margin-bottom: 34px;
    }

    &__header img {
        width: 100%;
        height: 238px;
        border-radius: 24px;
    }

    &__tags, &__minutes, &__description {
        @include Aspekta-font(400, $dynamic-informed-tag-title-font-size, 150%, #797E86);
        letter-spacing: 0em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    &__description {
        display: block;
        min-height: 160px;
        opacity: 0;
        transition: opacity .6s;
    }

    &__btn a {
        text-decoration: none;
    }
}

@media (max-width: 1900px) {
    .post {
        width: 365px;

        &__header img {
            height: 212px;
        }

        &__title {
            max-height: 162px;
        }

        &__wrapper:hover .post {
            transform: translateY(-246px);
        }
    }
}

@media (max-width: 1705px) {
    .post {
        width: 340px;

        &__header img {
            height: 198px;
        }

        &__title {
            max-height: 200px;
        }

        &__wrapper:hover .post {
            transform: translateY(-232px);
        }
    }
}

@media (max-width: 1600px) {
    .post {
        &__wrapper {
            margin: 0 10px;
        }
    }
}

@media (max-width: 1555px) {
    .post {
        &__wrapper {
            margin: 0 5px;
        }
    }
}

@media (max-width: 1515px) {
    .post {
        width: 320px;

        &__header img {
            height: 186px;
        }

        &__title {
            max-height: 220px;
        }

        &__wrapper:hover .post {
            transform: translateY(-220px);
        }

    }
}

@media (max-width: 1425px) {
    .post {
        width: 300px;

        &__header img {
            height: 174px;
        }

        &__title {
            max-height: 230px;
        }

        &__wrapper:hover .post {
            transform: translateY(-209px);
        }
    }
}

@media (max-width: 1340px) {
    .post {
        width: 280px;

        &__header img {
            height: 163px;
        }

        &__wrapper:hover .post {
            transform: translateY(-197px);
        }

        &__description {
            min-height: 190px;
        }
    }
}

@media (max-width: 1255px) {
    .post {
        width: 260px;
        height: 500px;

        &__header img {
            height: 151px;
        }

        &__article {
            height: 500px;
        }

        &__wrapper:hover .post {
            transform: translateY(-185px);
        }

        &__description {
            min-height: 212px;
        }
    }
}

@media (max-width: 1170px) {
    .post {
        width: 240px;
        height: 520px;

        &__header img {
            height: 139px;
        }

        &__article {
            height: 520px;
        }

        &__wrapper:hover .post {
            transform: translateY(-174px);
        }

        &__description {
            min-height: 234px;
        }
    }
}

@media (max-width: 1100px) {
    .post {
        width: 220px;
        height: 560px;

        &__header img {
            height: 128px;
        }

        &__article {
            height: 560px;
        }

        &__wrapper:hover .post {
            transform: translateY(-162px);
        }
    }
}

@media (max-width: 890px) {
    .post {
        width: 258px;

        &__header img {
            height: 150px;
        }

        &__title {
            max-height: 260px;
        }

        &__wrapper:hover .post {
            transform: translateY(-184px);
        }
    }
}

@media (max-width: 850px) {
    .post {
        width: 225px;

        &__header img {
            height: 131px;
        }

        &__title {
            max-height: 260px;
        }

        &__wrapper:hover .post {
            transform: translateY(-165px);
        }
    }
}

@media (max-width: 685px) {
    .post {
        width: 435px;
        height: 416px;

        &__header img {
            height: 253px;
        }

        &__description {
            min-height: 110px;
        }

        &__title {
            max-height: 72px;
        }

        &__wrapper:hover .post {
            transform: translateY(-287px);
        }

        &__wrapper {
            display: flex;
            justify-content: center;
        }

        &__article {
            height: 340px;
        }
    }
}

@media (max-width: 655px) {
    .post {
        width: 400px;

        &__header img {
            height: 233px;
        }

        &__wrapper:hover .post {
            transform: translateY(-267px);
        }

    }
}

@media (max-width: 610px) {
    .post {
        width: 350px;

        &__header img {
            height: 204px;
        }

        &__wrapper:hover .post {
            transform: translateY(-236px);
        }

        &__wrapper {
            margin: 0 0px;
        }

        &__title {
            max-height: 100px;
        }

    }
}

@media (max-width: 535px) {
    .post {
        width: 300px;
        height: 350px;

        &__header img {
            height: 174px;
        }

        &__description {
            min-height: 120px;
        }

        &__article {
            height: 320px;
        }


        &__wrapper:hover .post {
            transform: translateY(-209px);
        }

        &__wrapper {
            margin: 0 0px;
        }

        &__title {
            max-height: 220px;
        }

    }
}

@media (max-width: 465px) {
    .post {
        width: 244px;
        height: 380px;

        &__header img {
            height: 142px;
        }

        &__description {
            min-height: 164px;
        }
        
        &__article {
            height: 380px;
        }

        &__wrapper:hover .post {
            transform: translateY(-176px);
        }

        &__wrapper {
            margin: 0 0px;
        }

        &__title {
            max-height: 220px;
        }

    }
}

@media (max-width: 380px) {
    .post {
        width: 230px;

        &__header img {
            height: 134px;
        }
    }
}

@media (max-width: 355px) {
    .post {
        width: 200px;

        &__header img {
            height: 116px;
        }
    }
}
</style>
