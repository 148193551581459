import Vue from 'vue';
import Vuex from 'vuex';

import jobs from './jobs';
import stripe from './stripe';
import user from './user';
import categories from './categories';
import integrations from './integrations';
import apiKeys from './apiKeys';
import authentication from '@/store/authentication';
import compare from './compare';
import scanPacks from './scanPacks';
import scanPacksBF from './scanPacksBF';
import coupons from './coupons';
import folders from './folders';
import filters from './filters';
import blogPosts from './blogPosts';
import mfaDevices from './mfaDevices';

Vue.use(Vuex);
export const store = new Vuex.Store({
    modules: {
        jobs,
        user,
        stripe,
        categories,
        integrations,
        apiKeys,
        authentication,
        compare,
        scanPacks,
        scanPacksBF,
        coupons,
        folders,
        filters,
        blogPosts,
        mfaDevices
    },
});

export default {
    store,
    install (Vue, options) {
        Vue.prototype.$store = store;
    },
};
