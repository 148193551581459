<template>
    <div>
        <div class="invite-container">
            <div class="invite-container__header">Accept invite to the Integration?</div>
            <p class="invite-container__title">Invite from:</p>
            <div class="invite-container__block-from">
                <span class="invite-container__sender-name">{{acceptedInviteInfo.userName}}</span>
                <span class="invite-container__sender-email">{{acceptedInviteInfo.email}}</span>
            </div>

            <div class="invite-container__title">To the Integration:</div>
            <div  class="invite-container__block-to">
                <img :src="acceptedInviteInfo.signedUrl ? acceptedInviteInfo.signedUrl : DefaultLogo" alt="integration logo">
                <span class="invite-container__integration-name">{{acceptedInviteInfo.integrationName}}</span>
            </div>
            <button @click="acceptInvite()" class="invite-container__accept-btn cabin cabin-bold">
                Accept Invite
            </button>

            <Spinner v-if="showSpinner" class="spinner"/>
        </div>
        <div v-if="showErrorPopup">
            {{showAlert('Cannot accept invite, something went wrong', 5000, 'error')}}
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Spinner from '../../../components/Spinner'
import {validateUuid} from '../../../services/functions'
import DefaultLogo from "../../../assets/images/default-integration-icon.png";

export default {
    name: 'IntegrationInviteAccept',
    data() {
        return {
            DefaultLogo,
            showSpinner: false,
            showErrorPopup: false,
            invitedId: this.$route.params.invitedId
        }
    },
    components: {
        Spinner,
    },
    async created() {
        this.showSpinner = true
        if (!validateUuid(this.invitedId)) return this.$router.push('/')
        try {
            await this.$store.dispatch('integrations/loadIntegrationInvite', {invitedId: this.invitedId})
        } catch (err) {
            if (err === 403) {
                this.$router.push('/403')
            } else {
                this.$router.push('/')
            }
        }
        this.showSpinner = false
    },
    computed: {
        ...mapGetters({
            acceptedInviteInfo: 'integrations/acceptedInviteInfo'
        }),
    },
    methods: {
        async acceptInvite() {
            this.showSpinner = true
            const res = await this.$store.dispatch('integrations/acceptIntegrationInvite', {
                invitedId: this.invitedId
            })
            if (res && res.status === 200) {
                this.$router.push('/integrations')
            } else {
                this.showErrorPopup = true
                setTimeout(() => {
                    this.showErrorPopup = false
                }, 5000)
            }
            this.showSpinner = false
        },
        showAlert(message, timer, status) {
            this.$swal({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                icon: status,
                title: message,
                timer: timer,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
}

@mixin Cabin {
    font-family: Cabin, serif;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    font-size: 14px;
    color: #242424;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;

    div.spinner-wrapper {
        width: 100%;
        height: 100%;
        padding: 0 !important;
    }
}

img {
    width: 30px;
    height: 30px;
}

.page-content {
    display: flex;
    justify-content: center;
    background-color: #FAFBFC;
}

.invite-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 115px 265px 0 265px;

    &__header {
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 150%;
        color: #000;
        text-align: center;
    }

    &__block-from {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 6px;
    }

    &__block-to {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 6px;
    }

    &__title {
        @include Cabin;
        font-size: 14px;
        color: #706969;
        margin-top: 24px;
    }

    &__modal-title {
        @include Cabin;
        font-size: 18px;
        text-align: center;
        color: #242424;
    }

    &__sender-name, &__integration-name {
        @include Cabin;
        font-size: 18px;
    }

    &__sender-email {
        @include Cabin;
        margin-top: 6px;
    }

    &__integration-name {
        margin-left: 12px;
    }

    &__accept-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFB400;
        right: 0;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        color: #FFFFFF;
        transition: 0.3s;
        padding: 10px 40px;
        font-size: 18px;
        cursor: pointer;
        line-height: 22px;
        margin: 36px 0;

        &:hover {
            opacity: 0.7;
        }

        &:active {
            opacity: 1;
        }

        &:focus {
            border: 2px solid #FFB400;
        }
    }
}

@media (max-width: 1080px) {
    .invite-container {
        margin: 130px 0 10px 0;
        text-align: center;

        &__modal-title {
            font-size: 14px;
        }

        &__sender-name {
            overflow-wrap: break-word;
            word-break: break-word;
            max-width: 80%;
        }

        &__block-to {
            max-width: 60%;
        }
    }
}

@media (max-width: 500px) {

    .invite-container__accept-btn {
        padding: 12px 80px;
        width: 335px;
        height: 46px;
    }
}

</style>
