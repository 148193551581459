<template>
    <div class="main-top">
        <div class="main-top__wrapper">
            <div class="main-top__content">
                <div class="main-top__content-text">
                    <div class="title-and-subtitle">
                        <div class="main-top__sub-title">
                            Elevate Your Business with Oversecured Partner Opportunities
                        </div>
                        <div class="main-top__title">
                            <p>
                                <span class="main-top__title--line">
                                    Explore
                                    <OrangeLine
                                        class="orange-line"
                                    />
                                </span> the Benefits<br>
                                of Our Partner Program<br>
                            </p>
                        </div>
                    </div>
                    <div class="main-top__buttons">
                        <OrangeArrowButton :title="'Become a Partner'" />
                    </div>
                    <div class="main-top__statistics">
                        <div class="main-top__percentage-card">
                            <p class="card-title">
                                Global Successful Partnerships
                            </p>
                            <p class="card-numbers">
                                17+
                            </p>
                        </div>
                        <div class="main-top__percentage-card">
                            <p class="card-title">
                                Partners Satisfaction Rate
                            </p>
                            <p class="card-numbers">
                                100%
                            </p>
                        </div>
                        <div class="main-top__percentage-card">
                            <p class="card-title">
                                Partners Annual Growth Rate
                            </p>
                            <p class="card-numbers">
                                50%+
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img 
            class="main-top__image"
            src="../../assets/images/partner/main-background.png" 
            alt="background-image" 
            @load="loadPage()"
        >
    </div>
</template>
<script>
import OrangeLine from '../../assets/iconTemplates/OrangeLine.vue';
import OrangeArrowButton from '../main/OrangeArrowButton.vue';

export default {
    components: {
        OrangeLine,
        OrangeArrowButton,
    },
    data() {
        return {
            isClicked: false,
            isLoaded: false,
        };
    },
    methods: {
        loadPage(){
            this.isLoaded = true;
            this.$emit('hideSpinner');
        },

    },
   
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables/variablesForPartnerPage";
@import "../../assets/css/variables";
@import "../../assets/css/fonts";

    

    .main-top {
        background-color:#F6F6F9;
        height: 983px;
        overflow: hidden;

        &__image {
            position: absolute;
            left: $dynamic-background-position-left;
            top: 0;
        }

        &__wrapper {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__content {
            display: flex;
            padding-top: 13%;

            &-text {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                margin-left: 7.5%;
                z-index: 10;
            }
        }

        &__sub-title {
            position: relative;
            @include Aspekta-font(400, $dynamic-main-top-sub-title-font-size, normal, #797E86);
            letter-spacing: 0em;
        }

        &__title {
            position: relative;
            @include Aspekta-font(700, $dynamic-partner-top-title-font-size, 130%, #242424);
            letter-spacing: 0em;
            z-index: 2;
            display: flex;
            width: 100%;

            &--line {
                position: relative;
                display: inline-block;
            }
        }

        .title-and-subtitle {
            display: flex;
            flex-direction: column;
            gap: 1.7rem;
        }

        &__buttons {
            display: flex;
            align-items: flex-start;
        }

        &__statistics {
            display: flex;
            gap: $dynamic-statistic-gap;
            margin-top: 6rem;
        }

        .card-title {
            @include Aspekta-font(400, $dynamic-partner-top-card-title-font-size, normal, #797E86);
            letter-spacing: 0em;
        }

        .card-numbers {
            @include Aspekta-font(700, $dynamic-partner-top-card-numbers-font-size, normal, #0C0D0E);
            letter-spacing: 0em;
        }

        .orange-line {
            position: absolute;
            bottom: -0.1rem;
            right: 0px;
            width: 100%;
        }
    }

@media (max-width: 1700px) and (min-width: 768px) {
    .main-top__statistics {
        flex-direction: column;
        margin-top: 2rem;
        gap: 1rem;
    }
}
@media (max-width: 1400px) {
    .main-top__image {
        width: 70%;
        top: $dynamic-background-position-top;
        left: $dynamic-background-position-middle-left;
    }
    
}

@media (max-width: 950px) {
    .main-top__content {
        padding-top: 12%;
    }
    
}

@media (max-width: 768px) {
    .main-top {
        background-position: 100% 100%;
        background-size: 100%;
        height: 1039px;

        &__content {
            padding: 6rem 1.25rem 0rem 1.25rem;

            &-text {
                margin-left: 0%;
                gap: 2rem;
            }
        }

        &__image {
            width: 90%;
            top: calc($dynamic-background-position-mobile-top + 20px);
            left: $dynamic-background-position-mobile-left;
        }

        .title-and-subtitle {
            flex-direction: column-reverse;
            gap: 0.5rem;
        }

        &__statistics {
            flex-direction: column;
            margin-top: 0.5rem;
            gap: 1rem;
        }

        .card-title {
            margin-bottom: 0rem;
        }
    }

        
}

@media (max-width: 500px) {
    .main-top {

        &__image {
            width: 100%;
            top: $dynamic-background-position-mobile-top;
            left: 0;
        }
        &__statistics {
            text-align: center;
        }
        
    }
    
}

</style>
