<template>
    <div class="main-business">
        <div class="main-business__container">
            <div class="main-business__content">
                <div class="main-business__text">
                    <div class="main-business__title">
                        <p>Protect Your Business with Oversecured</p>
                    </div>

                    <div class="main-business__sub-title">
                        <p>Take action now and fortify your business with our automated scanning solution.</p>
                        <p>Enhance your team's efficiency and protect your brand reputation</p>
                    </div>
                    <div class="main-business__btn">
                        <OrangeArrowButton
                            :title="'Start Protecting Now'"
                            class="orange-button"
                        />
                    </div>
                </div>
                <div class="main-business__animation-wrapper">
                    <Animation
                        class="animation-desktop"
                    />
                    <img
                        src="../../../assets/images/main/protect-business-with-us/animation-image.svg"
                        alt="report-animation"
                        class="animation-mobile"
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import OrangeArrowButton from '../OrangeArrowButton.vue';
import Animation from './Animation.vue';

export default {
    components: {
        Animation,
        OrangeArrowButton,
    },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/variables";
@import "../../../assets/css/fonts";

    .main-business {
        background-color: #021631;
        background-image: url('../../../assets/images/main/protect-business-with-us/curved-arrow.svg');;
        display: flex;
        flex-direction: column;

        &__container {
            display: flex;
            flex-direction: column;
        }

        &__content {
            display: flex;
            margin: 7% 0% 7% 10%;
        }

        &__text {
            display: flex;
            flex-direction: column;
            width: 45%;
            height: 75%;
            gap: 4.5rem;
            margin-top: 5%;
        }

        &__animation-wrapper {
            display: flex;
            flex-direction: column;
            width: 55%;
            height: 75%;
        }

        &__sub-title {
            @include Aspekta-font(400, $dynamic-main-top-sub-title-font-size, normal, #FFFFFF);
            letter-spacing: 0em;
            opacity: 0.5;
            display: flex;
            flex-direction: column;
            margin-right: 49%;
            gap: 10px;
        }

        &__title {
            @include Aspekta-font(700, $dynamic-main-top-title-font-size, 130%, #FFFFFF);
            letter-spacing: 0em;
        }

        .animation-mobile {
            display: none;
        }

        .orange-button {
            font-weight: 700;
        }

    }
        @media (min-width: 768px) and (max-width: 1280px) {
            .main-business {
                &__text {
                    gap: 2.5rem;
                    width: 50%;
                    margin-top: 7%;
                }

                &__sub-title {
                    margin-right: 41%;
                }

                &__animation-wrapper {
                    width: 50%;
                }

            }

        }

        @media (min-width: 768px) and (max-width: 1051px) {
            .main-business {
                &__text {
                    width: 51%;
                    margin-top: 9%;
                }

                &__animation-wrapper {
                    width: 49%;
                }

            }

        }

        @media (min-width: 768px) and (max-width: 985px) {
            .main-business {
                &__text {
                    width: 54%;
                    margin-top: 9%;
                }

                &__animation-wrapper {
                    width: 46%;
                }

            }

        }

        @media (min-width: 768px) and (max-width: 895px) {
            .main-business {
                &__text {
                    width: 58%;
                    margin-top: 11%;
                }

                &__animation-wrapper {
                    width: 42%;
                }

                &__sub-title {
                    margin-right: 37%;
                }

            }

        }

        @media (min-width: 768px) and (max-width: 800px) {
            .main-business {
                &__text {
                    width: 60%;
                    margin-top: 13%;
                }

                &__animation-wrapper {
                    width: 40%;
                }

                &__sub-title {
                    margin-right: 35%;
                }

            }

        }

        @media (max-width: 768px) {
            .main-business {
                &__content {
                    flex-direction: column;
                    margin: 6rem 0rem 6rem 1.25rem;
                    gap: 4rem;
                }

                &__text {
                    width: 100%;
                    gap: 0.8rem;
                }

                &__title {
                    margin-right: 1.25rem;
                    line-height: 130%;
                }

                &__sub-title {
                    margin-right: 0%;
                }

                &__animation-wrapper {
                    width: 100%;
                }

                &__btn {
                    margin-top: 1.8rem;
                }

                .animation-mobile {
                    display: block;
                }

                .animation-desktop {
                    display: none;
                }

            }

        }

</style>
