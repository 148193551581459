<template>
    <b-container style="display: flex;">
        <div v-if="currentJob && currentJob.data && currentJob.isJobFound && isValidType && !isLoading">
            <FolderMenu_v2
                :all-vulnerabilities-folder="allVulnerabilitiesFolder"
                :folder-list="folderList"
                :false-positives-folder="falsePositivesFolder"
                :integration-id="integrationId"
                :job-id="currentJob.data.id"
                :is-job-owner="currentJob.data.isOwner"
                :version-id="currentId"
                :branch-name="branchName"
                @toggleFolderSettingsPopUp="toggleFolderSettingsPopUp"
            />
            <FolderSettingsPopUp
                v-show="isFolderSettingsPopUp"
                ref="folderSettingsPopUp"
                :custom-folders="customFolders"
                :job-id="currentJob.data.id"
                :integration-id="integrationId"
                :version-id="currentId"
                :folders-for-deleting="foldersForDeleting"
                :processing-folder="processingFolder"
                :processing-folder-index="processingFolderIndex"
                :folder-deletion-accepted="folderDeletionAccepted"
                :edited-folder-name="editedFolderName"
                @toggleFolderSettingsPopUp="toggleFolderSettingsPopUp"
                @deleting="deleteNotEmptyFolder"
                @deleted="deletedFolderHandler"
                @reload-data="reloadData"
                @before-delete="openConfirmationPopup"
                @open-edit-popup="openEditFolderPopUp"
            />
            <FolderDeletePopUp
                ref="folderDeletePopUp"
                :folders="foldersForDeletingNotEmpty"
                @on-hidden="onDeleteFolderHidden"
                @on-delete="onDeleteNotEmptyFolder"
            />
            <ConfirmationPopUp
                ref="confirmationPopUp"
                :folder="processingFolder"
                :index="processingFolderIndex"
                @toggleFolderSettingsPopUp="toggleFolderSettingsPopUp"
                @is-confirm="isConfirmedDeleteFolder"
            />
            <EditFolderNamePopUp
                ref="editFolderNamePopUp"
                :folder="processingFolder"
                @toggleFolderSettingsPopUp="toggleFolderSettingsPopUp"
                @confirm-edit-folder="onConfirmEditFolder"
            />
        </div>
        <div class="main-report-container">
            <div v-if="isLoading">
                <Spinner />
            </div>
            <div v-else>
                <!-- <ReportUnlockTrialPopUp
                    v-if="isUnlockTrialPopUp"
                    @closeUnlockTrialPopUp="toggleUnlockTrialPopUp"
                    :jobId="currentJob.data.id"
                    :type="currentJob.data.type"
                    :page="vulnerabilityPage.page"
                    @notify="UnlockTrialNotification"
                /> -->
                <Breadcrumb
                    v-if="integrationId"
                    style="margin-top: 25px;"
                    :integration-id="integrationId"
                    :integration-name="integrationName"
                    :current-job="currentJob"
                    :branch-name="branchName"
                />
                <div
                    v-if="currentJob.data && currentJob.isJobFound && isValidType"
                    class="report-page"
                    :class="{ sidebar: isSidebarVisible }"
                >
                    <div
                        id="main-vulnerability"
                        class="main"
                    >
                        <ReportHeader
                            :is-report-loading-now="IsReportLoadingNow"
                            :change-downloading-report-status="changeDownloadingReportStatus"
                            :job="currentJob.data"
                            :count="currentJob.data.statistics.percentageBySeverity.commonAmountVulnerabilities"
                            :platform="integration && integrationId ? integration.platform : currentJob.data.platform"
                            :is-trial-integration-restriction="currentJob.data.type === 'INTEGRATION' ? integration.trial : false"
                            :is-false-positive="folderName === 'False Positives'"
                            :all-folders-without-selected="allFoldersWithoutSelected"
                            :selected-folder="selectedFolder"
                            :folder-name="folderName"
                            :integration-id="integrationId"
                            :is-job-owner="currentJob.data.isOwner"
                            :job-type="currentJob.data.type"
                            :all-vulnerabilities-count="allVulnerabilitiesCount"
                            @toggleFolderSettingsPopUp="toggleFolderSettingsPopUp"
                            @loadSpinner="loadSpinner"
                        />
                        <NewReportStatistic
                            v-if="currentJob.data.statistics"
                            :all-vulnerabilities-folder="allVulnerabilitiesFolder"
                            :all-vulnerabilities-count="currentJob.data.statistics.percentageBySeverity.commonAmountVulnerabilities"
                            :job="currentJob.data"
                            :integration-id="integrationId"
                            :is-trial-integration-restriction="currentJob.data.type === 'INTEGRATION' ? integration.trial : false"
                            @loadSpinner="loadSpinner"
                        />
                    </div>
                    <CategoriesSideBar
                        v-show="calculateTotalStatisticsCount(selectedFolder[0]) > 0 && !isEmpty(filters)"
                        :all-vulnerabilities-folder="allVulnerabilitiesFolder"
                        :job="currentJob.data"
                        :vulnerability-categories-list="currentJob.data.statistics.percentageByType"
                        :refresh-loading="refreshLoading"
                        :integration-id="integrationId"
                        :is-trial-restriction="currentJob.data.type === 'INTEGRATION' ? integration.trial : false"
                        :width="width"
                        :branch-name="branchName"
                        :vulnerability-category-id="vulnerabilityCategoryId"
                        :is-mouse-leave="isMouseLeave"
                    />
                    <VulnerabilityInCode
                        v-if="currentJob.data.statistics.percentageBySeverity.commonAmountVulnerabilities > 0 && !allSeveritiesUnchecked"
                        ref="vulnerabilityInCode"
                        :is-trial-restriction="currentJob.data.type === 'INTEGRATION' ? integration.trial : false"
                        :vulnerabilities="vulnerabilityPage.vulnerabilities"
                        :is-member="integration && integrationId ? integration.isIntegrationMember : false"
                        :is-owner="currentJob.data.isOwner"
                        :version-id="currentId"
                        :count="vulnerabilityPage.count"
                        :allow-false-positive="true"
                        :current-id="currentId"
                        :page="vulnerabilityPage.page"
                        :selected-category="selectedCategory"
                        :refresh-loading="refreshLoading"
                        :integration-id="integrationId"
                        :job-id="currentJob.data.id"
                        :is-router-query-used="isRouterQueryUsed"
                        @mouse-enter="handleMouseEnter"
                        @is-mouse-leave="handleMouseLeave"
                    />
                    <a
                        v-if="toggleDisplayBtnToTop"
                        class="btn-to-top"
                        @click="toTop"
                    >
                        <svg
                            width="56"
                            height="56"
                            viewBox="0 0 56 56"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                width="56"
                                height="56"
                                rx="28"
                                fill="#F5F7FA"
                            />
                            <path
                                d="M28 25.4142L20.7071 32.7071C20.3166 33.0976 19.6834 33.0976 19.2929 32.7071C18.9024 32.3166 18.9024 31.6834 19.2929 31.2929L27.2929 23.2929C27.6834 22.9024 28.3166 22.9024 28.7071 23.2929L36.7071 31.2929C37.0976 31.6834 37.0976 32.3166 36.7071 32.7071C36.3166 33.0976 35.6834 33.0976 35.2929 32.7071L28 25.4142Z"
                                fill="#949DB8"
                            />
                        </svg>
                    </a>
                    <NoVulnerabilitiesBlock
                        v-if="(!currentJob.data.statistics.percentageBySeverity.commonAmountVulnerabilities && currentJob.isJobFound) || allSeveritiesUnchecked"
                        :integration-id="integrationId"
                        :job-id="currentJob.data.id"
                        :version-id="currentId"
                        :branch-name="branchName"
                    />
                    <div
                        v-if="isVulnerabilityPageLoading"
                        class="spinner__vp-wrapper"
                    >
                        <div class="spinner__wp">
                            <Spinner />
                        </div>
                    </div>
                </div>
                <PageNotFound v-else />
            </div>
        </div>
    </b-container>
</template>

<script>
// Functions and constants
import { mapGetters } from 'vuex';
import { reportTime } from '../../../utils/time';
import { FolderNameEnum } from '../../../utils/constants/common';
import { adjustWidths, calculateTotalStatisticsCount, isEmpty } from '../../../services/functions';
import { successNotification, failNotification } from '@/services/notificationService/notificationService';
// Components
import Breadcrumb from '../../../components/report/components/Breadcrumb.vue';
import ReportHeader from '../../../components/report/ReportHeader';
import FolderMenu_v2 from '../../../components/report/FolderMenu_v2';
import NewReportStatistic from '../../../components/report/NewReportStatistic';
import CategoriesSideBar from '../../../components/report/CategoriesSideBar.vue';
import VulnerabilityInCode from '../../../components/scans/VulnerabilityInCode';
import NoVulnerabilitiesBlock from '../../../components/report/components/NoVulnerabilitiesBlock';

// PopUps
import FolderSettingsPopUp from '../../../components/report/FolderSettingsPopUp.vue';
import FolderDeletePopUp from '../../../components/report/FolderDeletePopUp.vue';
// Common
import Spinner from '../../../components/Spinner';
import PageNotFound from '../../../components/pageNotFound/PageNotFound';
import ConfirmationPopUp from '../../../components/report/ConfirmationPopUp.vue';
import EditFolderNamePopUp from '../../../components/report/EditFolderNamePopUp.vue';

export default {
    name: 'ScanInfo',
    components: {
        Breadcrumb,
        ReportHeader,
        FolderMenu_v2,
        NewReportStatistic,
        CategoriesSideBar,
        VulnerabilityInCode,
        NoVulnerabilitiesBlock,
        FolderSettingsPopUp,
        FolderDeletePopUp,
        Spinner,
        PageNotFound,
        ConfirmationPopUp,
        EditFolderNamePopUp,
    },
    metaInfo: {
        title: 'Vue App',
    },
    data() {
        return {
            debouncedWidth:  0,
            currentId: null,
            toggleDisplayBtnToTop: false,
            IsReportLoadingNow: false,
            count: 0,
            isLoading: false,
            refreshLoading: false,
            isVulnerabilityPageLoading: false,
            integrationId: null,
            branchName: null,
            isValidType: true,
            isFolderSettingsPopUp: false,
            isRouterQueryUsed: false,
            integrationName: '',
            folderName: '',
            foldersForDeletingNotEmpty: [],
            foldersForDeleting: {},
            vId: null,
            vulnerabilityCategoryId: null,
            isMouseLeave: false,
            processingFolder: null,
            processingFolderIndex: null,
            folderDeletionAccepted: false,
            editedFolderName: '',
        };
    },
    computed: {
        ...mapGetters({
            folders: 'jobs/folders',
            currentJob: 'jobs/currentJob',
            isSidebarVisible: 'filters/isSidebarVisible',
            selectedCategory: 'jobs/selectedCategory',
            vulnerabilityPage: 'jobs/vulnerabilityPage',
            integration: 'integrations/integrationById',
            integrationFolders: 'integrations/integrationFolders',
            allSeveritiesUnchecked: 'filters/allSeveritiesUnchecked',
            filters: 'filters/filters',
        }),
        allFoldersWithoutSelected() {
            let folderName = this.folderName ? this.folderName : FolderNameEnum.ALL_VULNERABILITIES;
            return this.integrationId
                ? (this.integrationFolders || []).filter(folder => folder && folder.name !== folderName)
                : (this.folders || []).filter(folder => folder && folder.name !== folderName);
        },
        selectedFolder() {
            let folderName = this.folderName ? this.folderName : FolderNameEnum.ALL_VULNERABILITIES;
            return this.integrationId
                ? (this.integrationFolders || []).filter(folder => folder && folder.name === folderName)
                : (this.folders || []).filter(folder => folder && folder.name === folderName);
        },
        folderList() {
            return this.integrationId
                ? (this.integrationFolders || []).filter(folder => folder && folder.name !== FolderNameEnum.FALSE_POSITIVES && folder.name !== FolderNameEnum.ALL_VULNERABILITIES)
                : (this.folders || []).filter(folder => folder && folder.name !== FolderNameEnum.FALSE_POSITIVES && folder.name !== FolderNameEnum.ALL_VULNERABILITIES);
        },
        allVulnerabilitiesFolder() {
            return this.integrationId
                ? (this.integrationFolders || []).filter(folder => folder && folder.name === FolderNameEnum.ALL_VULNERABILITIES)
                : (this.folders || []).filter(folder => folder && folder.name === FolderNameEnum.ALL_VULNERABILITIES);
        },
        falsePositivesFolder() {
            return this.integrationId
                ? (this.integrationFolders || []).filter(folder => folder && folder.name === FolderNameEnum.FALSE_POSITIVES)
                : (this.folders || []).filter(folder => folder && folder.name === FolderNameEnum.FALSE_POSITIVES);
        },
        allVulnerabilitiesCount() {
            return this.calculateTotalStatisticsCount((this.allVulnerabilitiesFolder[0] || {}));
        },
        customFolders() {
            return this.integrationId
                ? (this.integrationFolders || []).filter(folder => folder && folder.name !== FolderNameEnum.FALSE_POSITIVES && folder.name !== FolderNameEnum.MAIN_REPORT && folder.name !== FolderNameEnum.ALL_VULNERABILITIES)
                : (this.folders || []).filter(folder => folder && folder.name !== FolderNameEnum.FALSE_POSITIVES && folder.name !== FolderNameEnum.MAIN_REPORT && folder.name !== FolderNameEnum.ALL_VULNERABILITIES);
        },
        width:  {
            get()  {
                return this.debouncedWidth;
            },
            async set(val)  {
                await this.$nextTick();
                this.debouncedWidth = val;
            },
        },
    },
    watch: {
        currentJob() {
            if (this.currentJob && this.currentJob.isJobFound) {
                if (this.currentJob.data && this.currentJob.data.type === 'INTEGRATION' && !this.integrationId) {
                    this.currentJob.isJobFound = false;
                    this.isValidType = false;
                } else {
                    if (this.currentJob.data && this.currentJob.data.statistics) {
                        this.count = this.currentJob.data.statistics.percentageBySeverity.commonAmountVulnerabilities;
                        if (!this.currentJob.data.statistics.percentageByType.length) {
                            document.body.style.overflow = 'visible';
                        }
                    }
                }
            }
        },
        vulnerabilityPage() {
            document.body.style.overflow = 'visible';
        },
        '$route.query': {
            deep: true,
            async handler() {
                if(!this.$route.query?.categories && !this.$route.query?.page) {
                    this.isVulnerabilityPageLoading = true;
                    this.isRouterQueryUsed = true;
                    this.folderName = this.$route.query?.folderName || FolderNameEnum.ALL_VULNERABILITIES;
                    this.$store.commit('jobs/deleteFilterVulnerability', { id: this.$route.params.id });
                    await this.loadPageInfo();
                    // sidebar only on desktop and if filters is not empty
                    this.width >= 900 && !isEmpty(this.filters) ? this.onSidebarShown() : this.onSidebarHidden();
                    this.$store.commit('filters/updateAllSeveritiesUnchecked');

                    this.isRouterQueryUsed = false;

                    this.isVulnerabilityPageLoading = false;
                    // Call adjustWidths after everything has been rendered
                    this.adjustWidthsAfterRender();
                    // set prevent page load duplication to false. It's needed when we move last vulnerability to another folder
                    this.$store.commit('jobs/setPreventPageLoadDuplication', false);
                }
            },
        },
        selectedCategory() {
            if (this.selectedCategory) {
                this.toVulnerabilityScroll('SELECTED_SCROLL', this.selectedCategory);
            }
        },
        // watch the innerWidth change and adjust
        width() {
            this.adjustWidthsAfterRender();
        },
    },
    async beforeMount() {
        this.isLoading = true;
        this.currentId = this.$route.params.id;
        this.integrationId = this.$route.params.integrationId;
        this.branchName = this.$route.params.branchName;
        this.folderName = this.$route.query.folderName;
        this.vId = this.$route.query.vId;

        this.$store.commit('jobs/setCurrentJob', null);
        await this.loadPageInfo();

        if (this.integration) this.integrationName = this.integration.integrationName;

        if (this.currentJob && this.currentJob.data) {
            document.title = `${this.currentJob.data.fileName} — Report | Oversecured`;
        } else {
            document.title = 'Page not found | Oversecured';
        }

        this.isLoading = false;

        if (window.innerWidth) {
            this.width = window.innerWidth;
        }

        if (this.$route.query.hasOwnProperty('categories') && this.$route.query.categories === '') {
            this.$store.commit('filters/updateAllSeveritiesUnchecked');
        }
    },
    mounted() {
        window.innerWidth >= 900 && !isEmpty(this.filters)
            ? this.onSidebarShown()
            : this.onSidebarHidden();
    },
    beforeRouteLeave(to, from, next) {
        this.onSidebarHidden();
        this.$store.commit('jobs/deleteFilterVulnerability', { id: this.$route.params.id }); // clear filters vulnerabilityIds after leave page
        this.$store.dispatch('filters/clearFilters'); // clear filters after leave page
        next();
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        // add event listener for resize event
        window.addEventListener('resize', this.resizeHandler);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.resizeHandler);
    },
    methods: {
        handleMouseEnter(id) {
            this.vulnerabilityCategoryId = id;
            this.isMouseLeave = false;
        },
        handleMouseLeave() {
            this.isMouseLeave = true;
        },
        async adjustWidthsAfterRender() {
            await this.$nextTick();  // wait for the next DOM update cycle
            this.adjustWidths(this.width, this.$route.name);
        },
        canLoadPage(foldersArray, folderNameQuery) {
            const currentFolder = foldersArray.find(folder => folder.name === folderNameQuery);
            // Return true if currentFolder exists and is not empty, false otherwise
            return !(currentFolder?.isEmptyFolder);
        },
        async reloadData() {
            this.refreshLoading = true;
            await this.$refs.vulnerabilityInCode.loadPage(true);
            this.refreshLoading = false;
        },
        async loadPageInfo() {
            if (this.integrationId) {
                await this.$store.dispatch('integrations/loadIntegrationById', {
                    id: this.integrationId,
                    branchName: this.branchName,
                    page: 1,
                    limit: 10,
                });
                await this.$store.dispatch('integrations/loadIntegrationVersion', {
                    integrationId: this.integrationId,
                    versionId: this.currentId,
                    branchName: this.branchName,
                    folderName: this.$route.query?.folderName || FolderNameEnum.ALL_VULNERABILITIES,
                });
                await this.$store.dispatch('integrations/getFolders', {
                    integrationId: this.integrationId,
                    versionId: this.currentId,
                });
            } else {
                await this.$store.dispatch('jobs/fetchJobById', {jobId: this.currentId, folderName: this.$route.query?.folderName || FolderNameEnum.ALL_VULNERABILITIES});
                await this.$store.dispatch('jobs/getFolders', {jobId: this.currentId});
            }

            // if after getFolders current folder in the route query isEmptyFolder, we don't need to load page
            const folderNameQuery = this.$route.query?.folderName || FolderNameEnum.ALL_VULNERABILITIES;
            const foldersArray = this.integrationId ? this.integrationFolders : this.folders;

            if (this.canLoadPage(foldersArray, folderNameQuery)) {
                //define categories from query for page request
                let categoriesArray = [];
                const originalData = {};

                if (this.$route.query.hasOwnProperty('categories')) {
                    categoriesArray = [];

                    if (this.$route.query?.categories) {
                        const idArray = this.$route.query?.categories.split(',');
                        categoriesArray = idArray.map(id => ({ categoryId: id }));
                    }
                }

                const key = this.$route.params.id;
                originalData[key] = categoriesArray;

                await this.sendPageRequest(1, originalData);

                const queryPage = Number(this.$route.query?.page);
                let page = 1;
                if(queryPage && queryPage !== 1) {
                    page = queryPage;

                    if(!this.isPageValid(queryPage, this.vulnerabilityPage.count)) {
                        page = 1;

                        if(this.$route.query?.page !== page) {
                            this.$router.replace({
                                query: {
                                    ...this.$route.query,
                                    page: page,
                                },
                            });
                        }
                    }

                    await this.sendPageRequest(page, originalData);
                }
            } else {
                this.$store.commit('jobs/setVulnerabilityPage', { count: 0, page: 0, vulnerabilities: [] });
            }

            // we should scroll to vulnerability if we have vId in query
            if (this.$route.query.vId) this.toVulnerabilityScroll('QUERY_SCROLL', this.$route.query.vId);
        },
        isPageValid(page, count) {
            const maxPages = Math.ceil(count / 20);
            return page >= 1 && page <= maxPages;
        },
        toVulnerabilityScroll(scrollType, vulnerbilityId) {
            // Here we should scroll to vulnerability by id
            if (scrollType === 'QUERY_SCROLL') {
                setTimeout(() => {
                    document.getElementById(vulnerbilityId).scrollIntoView({behavior: 'smooth'});
                    this.vId = null;
                    this.$store.commit('jobs/clearSelectedCategory'); // clear selected category after scroll
                }, 0);
            // Here we should scroll to vulnerability by categoryType
            } else if (scrollType === 'SELECTED_SCROLL') {
                setTimeout(() => {
                    let vulnerability = this.vulnerabilityPage.vulnerabilities.find(vulnerability => vulnerability['vulnerability-category.descriptionTitle'] === this.selectedCategory);
                    if (vulnerability) {
                        document.getElementById(vulnerability.id).scrollIntoView({behavior: 'smooth'});
                        this.$store.commit('jobs/clearSelectedCategory'); // clear selected category after scroll
                    }
                }, 500);
            }
        },
        toggleFolderSettingsPopUp(value) {
            this.isFolderSettingsPopUp = value;
        },
        deleteNotEmptyFolder(deletedFolder) {
            if (this.integrationId) {
                this.foldersForDeletingNotEmpty = this.integrationFolders.filter(folder => folder.name !== deletedFolder.name && folder.name !== FolderNameEnum.ALL_VULNERABILITIES);
            } else {
                this.foldersForDeletingNotEmpty = this.folders.filter(folder => folder.name !== deletedFolder.name && folder.name !== FolderNameEnum.ALL_VULNERABILITIES);
            }
            this.isFolderSettingsPopUp = false;
            this.$refs.folderDeletePopUp.deletedFolder = deletedFolder;
            this.$refs.folderDeletePopUp.isShowModal = true;
        },
        //open popup to confirm deletion folder
        openConfirmationPopup(index, folder) {
            this.processingFolder = folder;
            this.processingFolderIndex = index;
            this.folderDeletionAccepted  = false;
            this.isFolderSettingsPopUp = false;
            this.$refs.confirmationPopUp.isShowModal = true;
        },
        // confirmed deletion and start spinner
        isConfirmedDeleteFolder(index, folder) {
            this.folderDeletionAccepted  = true;
            this.isFolderSettingsPopUp = true;
            this.$refs.folderSettingsPopUp.showSpinner = true;
        },
        //open edit folder popup
        openEditFolderPopUp(index, folder) {
            this.processingFolderIndex = index;
            this.processingFolder = folder;
            this.editedFolderName = '';
            this.isFolderSettingsPopUp = false;
            this.$refs.editFolderNamePopUp.showModal();
        },
        //confirmed edit
        onConfirmEditFolder(editedFolderName) {
            this.processingFolderIndex = null;
            this.editedFolderName = editedFolderName;
            this.isFolderSettingsPopUp = true;
            this.$refs.folderSettingsPopUp.showSpinner = true;
        },
        onDeleteFolderHidden() {
            this.isFolderSettingsPopUp = true;
        },
        onDeleteNotEmptyFolder({ selectedFolder, deletedFolder }) {
            this.$refs.folderSettingsPopUp.moveAndDeleteFolder(selectedFolder, deletedFolder);
        },
        async deletedFolderHandler({ selectedFolder, deletedFolder }) {
            if (deletedFolder?.name === this.folderName) {
                const path = this.integrationId ? `/integration/${this.integrationId}/branch/${this.branchName}/version/${this.currentId}` : `/scan/info/${this.currentId}`;
                this.$router.push(path);
            } else if (selectedFolder?.name === this.folderName) {
                this.refreshLoading = true;
                await this.loadPageInfo();
                this.refreshLoading = false;
            }
        },
        async sendPageRequest(page, originalData) {
            await this.$store.dispatch('jobs/chooseAppropriatePageAction', {
                id: this.currentId,
                page: page,
                limit: 20,
                shared: this.currentJob?.data.shared,
                vulnerabilityId: this.$route.query.vId,
                integrationId: this.integrationId,
                branchName: this.branchName,
                folderName: this.$route.query?.folderName || FolderNameEnum.ALL_VULNERABILITIES,
                reqType: !this.vId ? 'GET_PAGE' : 'FIND_PAGE_BY_VULNERABILITY_ID',
                categories: originalData,
            });
        },
        reportTime(time) {
            return reportTime(time);
        },
        handleScroll() {
            this.toggleDisplayBtnToTop = window.scrollY > 500;
            if (this.toggleDisplayBtnToTop) {
                let btnToTop = document.querySelector('.btn-to-top');
                if (btnToTop) btnToTop.style.right = this.isSidebarVisible ? '380px' : '24px';
            }
        },
        resizeHandler() {
            this.width = window.innerWidth;
        },
        changeDownloadingReportStatus(status) {
            this.refreshLoading = status;
        },
        toTop() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
        loadSpinner(value) {
            this.refreshLoading = value;
        },
        loadRefreshSpinner(isLoadSpinner) {
            this.refreshLoading = isLoadSpinner && !this.isVulnerabilityPageLoading;
        },
        UnlockTrialNotification(type) {
            if (type === 'success') this.successNotification('reportUnlocked');
            else if (type === 'fail') this.failNotification();
        },
        onSidebarHidden() {
            this.$store.commit('filters/setSidebarVisiblity', false);
        },
        onSidebarShown() {
            this.$store.commit('filters/setSidebarVisiblity', true);
        },
        adjustWidths,
        successNotification,
        failNotification,
        calculateTotalStatisticsCount,
        isEmpty,
    },
};
</script>
<style lang="scss" scoped>
.main-report-container {
    display: flex;
    justify-content: center;
    padding: 0;
}

.sidebar {
    padding-right: 264px;
}

.page-content {
    background-color: transparent;
    justify-content: center;
}

.spinner__vp-wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 3000;
    background-color: rgba(255, 255, 255, 0.7);
}

.spinner__wp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.spinner__my-scans-wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.7);
}

.spinner__my-scans {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.report__title {
    margin: 90px 0 27px 20px;
    font-family: Cabin, serif;
    font-weight: bold;
    font-size: 24px;
    color: #242424;
}

.component-for-small-permissions {
    display: none;
}

.main {
    display: flex;
    flex-direction: column;

    &__no-vulnerabilities-wrapper {
        min-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__no-vulnerabilities {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 150%;
        color: #242424;
    }
}

.vulnerability-statistics-title {
    margin-left: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.report-time {
    font-size: 18px;
}

.statistics {
    font-size: 24px;
}

.btn-to-top {
    position: fixed;
    bottom: 50px;
    border: 4px solid transparent;
    right: 24px;
    border-radius: 50%;
    transition: 0.3s;
    z-index: 9;
}

.btn-to-top:hover {
    cursor: pointer;
    border-radius: 50%;
    border: 4px solid #E3E6ED;
}

.report-page {
        width: calc(100vw - 232px);
    }

@media (max-width: 900px) {
    .report-page {
        padding-right: 0px;
    }
}

@media (max-width: 576px) {
    .report-page {
        width: 92vw;
    }

    .main-report-container {
        max-width: 100%;
    }
}


</style>
