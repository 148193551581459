<template>
    <div class="markdown">
        <b-nav-item :to="{ path: `${makeLinkToAnchorScanConfigurations('ios')}`}" class="md__link-wrapper">
            <h2 class="md__item-title">Configuration for iOS</h2>
        </b-nav-item>
        <p> 
            Oversecured scans the source code of Xcode projects for iOS apps. These can contain code that will 
            never be included in release versions, such as tests or mock data. By default Oversecured scans all 
            the files, which may lead to the scanner being triggered when it's not wanted. To restrict your scan,
            you can create an <code>Oversecured.config</code> file in the project root (the root of the <code>.zip</code> archive uploaded 
            to be scanned) and prevent specific modules, directories or files being analyzed.
        </p>
        <h3 :id="makeAnchor('The Oversecured.config file')">
            <a :href="makeSubLinkToAnchorScanConfigurations('The Oversecured.config file', 'configurations/ios')">The <a id="title_pink">Oversecured.config</a> file</a>
        </h3>
        <p>
            This is an XML file that makes it possible to exclude files you don't want to be included in the security analysis of an iOS app.
        </p>
        <p>
            Name the root element <code>OversecuredConfig</code>. Within it, create an element called <code>ScanScope</code> and use it to list the elements to 
            include and to exclude. By default, all files are included in the analysis. The user needs to list the elements that should be 
            excluded. It is important to note that <strong>priority on the list goes from bottom to top</strong>, meaning that elements listed lower can 
            cancel or correct previous settings.
        </p>
        <p><strong>Possible elements</strong></p>
        <table class="table">
            <thead>
                <tr>
                    <th>Tag</th>
                    <th>Comment</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><code>Exclude</code></td>
                    <td>excluded element</td>
                </tr>
                <tr>
                    <td><code>Include</code></td>
                    <td>included element</td>
                </tr>
            </tbody>
        </table>
        <p><strong>Possible types of element</strong></p>
        <table class="table">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><code>module</code></td>
                    <td>
                        refers to an Xcode module (Target). If a particular file is a member 
                        of more than one module, and only one of them is excluded, then the file 
                        is included in the analysis. If the file is only a member of one module, 
                        and that module is excluded, then it will be excluded from all types of analysis
                    </td>
                </tr>
                <tr>
                    <td><code>subpath</code></td>
                    <td>
                        refers to a subpath (prefix) from the root which is to be included/excluded; 
                        it must correspond to specific files or whole directories
                    </td>
                </tr>
            </tbody>
        </table>
        <p>The <strong>element text</strong> can be either a module name or a prefix value.</p>
        <h3 :id="makeAnchor('Configuration Example')">
            <a :href="makeSubLinkToAnchorScanConfigurations('Configuration Example', 'configurations/ios')">Example</a>
        </h3>
        <p><strong>Content of the file:</strong></p>
        <div class="code-toolbar"><pre class="language-xml"><code class="language-xml"><span class="token prolog">&lt;?xml version="1.0" encoding="UTF-8"?&gt;</span>
<span class="token tag"><span class="token punctuation">&lt;</span>OversecuredConfig</span><span class="token punctuation">&gt;</span>
    <span class="token tag"><span class="token punctuation">&lt;</span>ScanScope</span><span class="token punctuation">&gt;</span>
        <span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>Exclude</span> <span class="token attr-name">type</span><span class="token attr-value"><span class="token punctuation attr-equals">=</span><span class="token punctuation">"</span>module<span class="token punctuation">"</span></span><span class="token punctuation">&gt;</span></span>UnitTests<span class="token tag"><span class="token tag"><span class="token punctuation">&lt;/</span>Exclude</span><span class="token punctuation">&gt;</span></span>
        <span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>Exclude</span> <span class="token attr-name">type</span><span class="token attr-value"><span class="token punctuation attr-equals">=</span><span class="token punctuation">"</span>subpath<span class="token punctuation">"</span></span><span class="token punctuation">&gt;</span></span>MyApp-Tests<span class="token tag"><span class="token tag"><span class="token punctuation">&lt;/</span>Exclude</span><span class="token punctuation">&gt;</span></span>
        <span class="token tag"><span class="token tag"><span class="token punctuation">&lt;</span>Include</span> <span class="token attr-name">type</span><span class="token attr-value"><span class="token punctuation attr-equals">=</span><span class="token punctuation">"</span>subpath<span class="token punctuation">"</span></span><span class="token punctuation">&gt;</span></span>MyApp-Tests/DefaultConfig.json<span class="token tag"><span class="token tag"><span class="token punctuation">&lt;/</span>Include</span><span class="token punctuation">&gt;</span></span>
    <span class="token tag"><span class="token punctuation">&lt;/</span>ScanScope</span><span class="token punctuation">&gt;</span>
<span class="token tag"><span class="token punctuation">&lt;/</span>OversecuredConfig</span><span class="token punctuation">&gt;</span>
</code></pre></div>
        <p>
            The module <code>UnitTests</code> (in any project) is here excluded, as are all files in the 
            <code>MyApp-Tests</code> directory except <code>DefaultConfig.json</code>.
        </p>
        <h3 :id="makeAnchor('How to set up the configuration correctly')">
            <a :href="makeSubLinkToAnchorScanConfigurations('How to set up the configuration correctly', 'configurations/ios')">How to set up the configuration correctly</a>
        </h3>
        <p>
            Oversecured recommends you initially analyze your code without creating a config file, and obtain 
            a list of the vulnerabilities found. If anything in your debug code triggers the scanner, we recommend 
            setting up the narrowest possible rules so as not to affect production code now or in the future.
        </p>
        <p>
            In addition, we do not recommend excluding build or configuration files from the analysis. It's important 
            to remember that code repositories should not contain private keys, certificates or passwords of any kind. 
            We recommend using these as environment variables. If Oversecured finds hardcoded data, this should not be 
            treated as a false positive.
        </p>
        <p>
            If you get an unexpected result after using your configuration, 
            <b-nav-item class="link" href="https://support.oversecured.com/hc/en-us/requests/new" target="_blank">
                    get in touch with us
            </b-nav-item>.
            We will help you correct any mistakes and set up your filters correctly.
        </p>
    </div>
</template>

<script>
import {makeLinkToAnchorScanConfigurations, makeAnchor, makeSubLinkToAnchorScanConfigurations} from "../../../services/functions";

export default {
    methods: {
        makeLinkToAnchorScanConfigurations,
        makeAnchor, 
        makeSubLinkToAnchorScanConfigurations,
    }
}
</script>

<style scoped>
a#title_pink {
    color: #E83E8C;  
    text-decoration-color: #242424;
}

a:hover:before {
    color: #242424;
}

</style>
