<template>
    <div
        v-if="integrationData.length"
        class="in-progress"
    >
        <div class="in-progress__header-wrapper">
            <h2 class="in-progress__sign">
                In progress
            </h2>
            <p class="in-progress__files">
                ({{ integrationData.length }})
            </p>
        </div>
        <div class="in-progress__table-wrapper">
            <div class="in-progress-integrations-block">
                <b-table
                    id="integration-in-progress-table"
                    hover
                    :items="currentPageIntegrations"
                    :fields="uploadedFilesFields"
                    :thead-class="`table-header__hidden`"
                    :tbody-class="`table-body`"
                    class="table-inprogress"
                    :class="integrationData.length <= inProgressPerPage ? 'pagination-disabled' : ''"
                >
                    <template #cell(index)="data">
                        {{ (data.index) + 1 }}
                    </template>
                    <template #cell(file_name)="data">
                        <b-nav-item :to="{ path: `/integration/${data.item.id}/branch/${data.item.defaultBranchName}`}">
                            {{ data.item.integrationName }}
                        </b-nav-item>
                    </template>
                    <template #cell(email)="data">
                        {{ data.item.email }}
                    </template>
                    <template #cell(appId)="data">
                        {{ data.item.appId }}
                    </template>
                    <template #cell(spinner)="data">
                        <div v-if="isScanning(data.item.integrationStatus)">
                            <div class="dot-spin" />
                        </div>
                    </template>
                    <template #cell(status)="data">
                        <div
                            v-if="isCreated(data.item.integrationStatus)"
                            class="status-text in_queue"
                        >
                            Created
                        </div>
                        <div
                            v-if="isScanning(data.item.integrationStatus)"
                            class="status-text scanning"
                        >
                            Scanning
                        </div>
                        <div
                            v-if="isFailed(data.item.integrationStatus)"
                            class="status-text failed"
                        >
                            Failed
                        </div>
                        <div
                            v-if="isInQueue(data.item.integrationStatus)"
                            class="status-text in_queue"
                        >
                            In queue
                        </div>
                    </template>
                    <template #cell(action)="data">
                        <div class="tooltips">
                            <Tooltip
                                position="top"
                                mobile-position="top"
                                :tooltip="tooltipCopyText"
                            >
                                <button
                                    class="in-progress__copy-link-btn"
                                    @click.stop="copyIntegrationLink(data.item)"
                                >
                                    <img
                                        class="table__btn-icon"
                                        src="../../assets/images/myScans/copy-link.svg"
                                        alt="icon_action_share"
                                    >
                                </button>
                            </Tooltip>
                        </div>
                    </template>
                </b-table>
                <Pagination
                    v-if="integrationData.length > inProgressPerPage"
                    class="pagination-general in-progress-versions-pagination"
                    :count="integrationData.length"
                    :limit="inProgressPerPage"
                    :current-page="inProgressCurrentPage"
                    @pageChanged="updateinProgressPage"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { JobStatusEnum } from '@/utils/constants/common';
import Tooltip from '../report/components/Tooltip.vue';
import Pagination from '../Pagination.vue';

export default {
    components: {
        Tooltip,
        Pagination,
    },
    props: {
        integrationData: Array,
    },
    data() {
        return {
            inProgressPerPage: 20,
            inProgressCurrentPage: 1,
            currentPageIntegrations: [],
            tooltipCopyText: 'Copy link',
            uploadedFilesFields: [
                {
                    key: 'index',
                },
                {
                    key: 'file_name',
                },
                {
                    key: 'email',
                },
                {
                    key: 'appId',
                },
                {
                    key: 'spinner',
                },
                {
                    key: 'status',
                },
                {
                    key: 'action',
                },
            ],
        };
    },
    watch: {
        inProgressCurrentPage() {
            this.currentPageIntegrations = this.integrationData
                .slice((this.inProgressCurrentPage - 1) * this.inProgressPerPage, this.inProgressCurrentPage * this.inProgressPerPage);
        },
    },
    mounted() {
        this.currentPageIntegrations = this.integrationData.slice(0, this.inProgressPerPage);
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        async copyToClipboard(item) {
            try {
                await navigator.clipboard.writeText(item);
                this.tooltipCopyText = 'Link Copied!';
                setTimeout(() => {
                    this.tooltipCopyText = 'Copy link';
                }, 2000);
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        },
        copyIntegrationLink(item) {
            let link = window.location.origin + `/integration/${item.id}`;
            this.copyToClipboard(link);
        },
        isScanning(status) {
            return status === JobStatusEnum.SCANNING;
        },
        isCreated(status) {
            return status === JobStatusEnum.CREATED;
        },
        isFailed(status) {
            return status === JobStatusEnum.ERROR;
        },
        isInQueue(status) {
            return status === JobStatusEnum.IN_QUEUE;
        },
        updateinProgressPage(page) {
            this.inProgressCurrentPage = page;
        },
    },
};
</script>
<style lang="scss">
@import "../../assets/css/variables";
@import "../../assets/css/fonts";

.in-progress-integrations-block {
    position: relative;
    background: #FFFFFF;
    border-radius: 20px;
    width: 85%;
    margin: 0 auto;
}

.table-inprogress td {
    border-top: none;
}
.in-progress {
    margin-bottom: 50px;
    overflow: auto;

    &__btn-show-all {
        font-size: 14px;
        color: #1FA8C7;
        background-color: transparent;
        transition: 0.3s;

        &:hover {
            border-bottom: 1px solid #1FA8C7;
        }
    }

    &__header-wrapper {
        display: flex;
        align-items: center;
        @include Aspekta-font(500, 18px, normal, #242424);
        margin: 20px 7.5% 20px 7.5%;

    }

    &__sign {
        margin: 0;
        @include Aspekta-font(600, 18px, normal, #242424);
    }

    &__files {
        margin: 0 40px 0 10px;
        color: #B2B9C4;
    }

    &__btn-show-all {
        font-size: 14px;
        color: #1FA8C7;
        background-color: transparent;
        transition: 0.3s;

        &:hover {
            border-bottom: 1px solid #1FA8C7;
        }
    }
}

/* Styles will be applied when pagination is disabled */
.pagination-disabled tbody tr:last-child td:nth-child(1) {
  border-bottom-left-radius: 20px !important;
}

.pagination-disabled tbody tr:last-child td:last-child {
  border-bottom-right-radius: 20px !important;
}

.pagination-disabled tr {
  border-radius: 20px !important;
}

#integration-in-progress-table {
    margin: 0 auto;
}

#integration-in-progress-table tr{
  border-bottom: 1px solid #f0f0f0;
  border-top: none !important;
}

#integration-in-progress-table tbody tr:first-child {
  border-radius: 20px !important;
}

#integration-in-progress-table tbody tr:first-child td:nth-child(1) {
  border-top-left-radius: 20px !important;
}

#integration-in-progress-table tbody tr:first-child td:last-child {
  border-top-right-radius: 20px !important;
}

#integration-in-progress-table tr:last-child {
  background-color: white;
  border-bottom: none !important;
}

#integration-in-progress-table tbody tr td:nth-child(1) {
    width: 90px;
    text-align: center;
    @include Aspekta-font(600, 14px, 140%, #B2B9C4);
}

#integration-in-progress-table tbody tr td:nth-child(7) {
    :first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
}

#integration-in-progress-table tbody tr td:nth-child(2) {
    padding-left: 15px !important;
    width: 350px;
    border-bottom-right-radius: 0px !important;

    a.nav-link {
        @include Aspekta-font(400, 18px, 140%, #242424);
        word-break: break-word;

        &:hover {
            text-decoration: underline;
        }
    }
}

#integration-in-progress-table tbody tr td:nth-child(4) {
    max-width: 210px;
    word-wrap: break-word;
}

#integration-in-progress-table tbody tr td:nth-child(5) {
    width: 265px;
    padding-top: 34px !important;
}

#integration-in-progress-table tbody tr td:nth-child(6) {
    width: 140px;
    text-align: center;
}

#integration-in-progress-table tbody tr td:nth-child(7) {
    width: 100px;
    text-align: center;
    position: relative;
}

#integration-in-progress-table tbody tr td {
    padding: 30px 0 !important;
    @include Aspekta-font(400, 16px, normal, #242424);
    background-color: white;
}

.status-text {
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 0;

    &.scanning {
        color: #61D4B3;
    }

    &.failed {
        color: #FB8D62;
    }

    &.in_queue {
        color: #B2B9C4;
    }
}


.inner-integration-progress__copy-link {
    position: absolute;
    top: 0;
    left: -38px;
    width: 90px;
    text-align: center;
    height: 20px;
    background-color: black;
    border-radius: 3px;
    @include Aspekta-font(400, 14px, 140%, white);
}

.in-progress-versions-pagination {
    width: 100%;
    background-color: white;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 10px 0 20px 84px !important;
    margin: 0 auto;
}

@media (max-width: 1120px) {
    .in-progress-integrations-block {
        width: 1120px;
    }
}

</style>
