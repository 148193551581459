<template>
    <div class="finished">
        <div
            v-if="integrationData.length>0"
            class="finished__header-wrapper"
        >
            <h2 class="finished__sign">
                Finished
            </h2>
            <p class="finished__files">
                ({{ integrationData.length }})
            </p>
        </div>
        <div class="table-finished-integrations-wrapper">
            <div class="finished-integrations-block">
                <b-table
                    v-if="integrationData.length > 0 "
                    id="finished"
                    hover
                    :items="currentPageIntegrations"
                    :fields="uploadedFilesFields"
                    :thead-class="`table-header`"
                    :tbody-class="`table-body`"
                    class="table-finished"
                    :class="integrationData.length <= finishedPerPage ? 'pagination-disabled' : ''"
                >
                    <template #cell(icon)="data">
                        <img
                            :id="data.item.id"
                            class="table__icon-image"
                            alt=""
                            :src="data.item.signedUrl ? data.item.signedUrl : DefaultLogo"
                            @load="showSpinner = false"
                            @error="errorLoaded(data.item.id)"
                        >
                    </template>
                    <template #cell(file_name)="data">
                        <b-nav-item
                            v-if="permissionToOpenIntegration(data.item.status)"
                            exact
                            :to="{ path: `/integration/${data.item.id}/branch/${data.item.defaultBranchName}`}"
                        >
                            {{ data.item.integrationName }}
                        </b-nav-item>
                        <h3
                            v-else
                            class="forbidden-to-open-integration"
                        >
                            {{ data.item.integrationName }}
                        </h3>
                    </template>
                    <template #cell(status)="data">
                        <div
                            v-if="isCreated(data.item.status)"
                            class="table__status-finished created"
                        >
                            Created
                        </div>
                        <div
                            v-if="isUnderReview(data.item.status)"
                            class="table__status-finished under_review"
                        >
                            Under Review
                        </div>
                        <div
                            v-if="isActive(data.item.status)"
                            class="table__status-finished active"
                        >
                            Active
                        </div>
                        <div
                            v-if="isExpired(data.item.status)"
                            class="table__status-finished expired"
                        >
                            Expired
                        </div>
                        <div
                            v-if="isRejected(data.item.status)"
                            class="table__status-finished under_review"
                        >
                            Rejected
                        </div>
                        <div
                            v-if="isRenewalRequested(data.item.status)"
                            class="table__status-finished renewal_requested"
                        >
                            Renewal Requested
                        </div>
                        <div
                            v-if="isSampleIntegration(data.item.status)"
                            class="table__status-finished renewal_requested"
                        >
                            Sample
                        </div>
                        <div
                            v-if="isTrialRequested(data.item.status)"
                            class="table__status-finished renewal_requested"
                        >
                            Trial Requested
                        </div>
                    </template>
                    <template #cell(lastVersion)="data">
                        <p v-if="data.item.lastVersion">
                            {{ data.item.lastVersion.version }}
                        </p>
                        <p v-else>
                            -
                        </p>
                    </template>
                    <template #cell(appId)="data">
                        {{ data.item.appId }}
                    </template>
                    <template #cell(platform)="data">
                        {{ checkAndUpdatePlatformText(data.item.platform) }}
                    </template>
                    <template #cell(actions)="data">
                        <div class="tooltips">
                            <Tooltip
                                :position="tooltipPosition"
                                mobile-position="left"
                                :tooltip="tooltipMessage(data.item)"
                            >
                                <button
                                    id="download-pdf-btn"
                                    class="table__btn-icon"
                                    :disabled="data.item.trial"
                                    @click="downloadJobPDFReport(data.item.lastVersion.integrationId, data.item.lastVersion.id, data.item.defaultBranchName)" 
                                >
                                    <DownloadIcon
                                        v-if="showIntegrationIcon(data.item.status) && data.item.lastVersion" 
                                        :disabled="data.item.trial"                   
                                        alt="icon_action_download"
                                    />
                                </button>
                            </Tooltip>
                            <Tooltip
                                :position="tooltipPosition"
                                mobile-position="left"
                                :tooltip="tooltipCopyText"
                            >
                                <button
                                    id="copy-link-btn"
                                    class="button_copy_link"
                                    @click.stop="copyIntegrationLink(data.item)"
                                >
                                    <img
                                        v-if="showIntegrationIcon(data.item.status)"
                                        class="table__btn-icon"
                                        src="../../assets/images/myScans/copy-link.svg"
                                        alt="icon_action_share"
                                    >
                                </button>
                            </Tooltip>
                            <Tooltip
                                :position="tooltipPosition"
                                mobile-position="left"
                                :tooltip="isUserHacker ? tooltipHackerText: tooltipDeleteText"
                            >
                                <button
                                    v-if="!isActive(data.item.status) && !isExpired(data.item.status) && !isRenewalRequested(data.item.status)"
                                    id="delete-integration-btn"
                                    :disabled="isUserHacker"
                                    @click.prevent="deleteIntegration(data.item.id)"
                                >
                                    <img
                                        class="table__btn-icon"
                                        src="../../assets/images/newScan/trash-icon.svg"
                                        alt="icon_action_delete"
                                    >
                                </button>
                            </Tooltip>
                        </div>
                    </template>
                </b-table>
                <div
                    v-if="showSpinner"
                    class="integration-finished__spinner"
                >
                    <Spinner />
                </div>
                <Pagination
                    v-if="integrationData.length > finishedPerPage"
                    class="pagination-general finished-versions-pagination"
                    :count="integrationData.length"
                    :limit="finishedPerPage"
                    :show-spinner="showSpinner"
                    :current-page="finishedCurrentPage"
                    @pageChanged="updateFinishedPage"
                />
            </div>
        </div>
        <div v-if="integrationData.length === 0 && integrationInProgress. length === 0 && userType!=='hacker'">
            <div class="integration-empty-list">
                <p class="integration-empty-list__sign">
                    No integrations. To get started, create a new integration.
                </p>
                <b-nav-item
                    :to="{ path: '/integrations/create'}"
                    class="integration-empty-list__btn"
                >
                    Request Integration
                </b-nav-item>
            </div>
            <HowItWorksIntegration />
        </div>
    </div>
</template>
<script>
import { IntegrationStatusEnum } from '@/utils/constants/common';
import Spinner from '../Spinner';
import HowItWorksIntegration from '../newIntegration/how-it-works-integrations';
import { addImportanceToIntegrations, filterAndSortIntegrationByImportance } from '@/utils/functions-integrations';
import DefaultLogo from '../../assets/images/default-integration-icon.png';
import { checkAndUpdatePlatformText } from '@/services/functions';
import { FolderNameEnum } from '../../utils/constants/common';
import Tooltip from '../report/components/Tooltip.vue';
import Pagination from '../Pagination.vue';
import DownloadIcon from '../../assets/iconTemplates/DownloadIcon.vue';

export default {
    components: {
    Spinner,
    HowItWorksIntegration,
    Tooltip,
    Pagination,
    DownloadIcon,
},
    props: {
        integrationData: Array,
        integrationInProgress: Array,
        userType: String,
    },
    data() {
        return {
            DefaultLogo,
            showSpinner: false,
            // Tooltips
            tooltipCopyText: 'Copy link',
            tooltipDeleteText: 'Delete Integration',
            tooltipDownloadText: 'Download PDF',
            tooltipTrialMessage:'Please contact our team to unlock the download option',
            tooltipHackerText: 'Users with the "Hacker" account type are barred from this action',
            finishedPerPage: 20,
            finishedCurrentPage: 1,
            windowWidth: window.innerWidth,
            currentPageIntegrations: [],
            uploadedFilesFields: [
                {
                    key: 'icon',
                    tdClass: [''],
                },
                {
                    key: 'file_name',
                    label: 'Integration Name',
                    tdClass: ['table__file-name-finished'],
                },
                {
                    key: 'status',
                    label: 'Status',
                    tdClass: ['table__status-finished'],
                },
                {
                    key: 'appId',
                    label: 'App ID',
                    tdClass: ['table__packageName'],
                },
                {
                    key: 'lastVersion',
                    label: 'Last Version',
                    tdClass: ['table__lastVersion'],
                },
                {
                    key: 'platform',
                    label: 'Platform',
                    tdClass: ['table__platform'],
                    formatter: 'checkAndUpdatePlatformText',
                },
                {
                    key: 'actions',
                    tdClass: [''],
                },
            ],
        };
    },
    computed: {
        isUserHacker() {
            return this.userType === 'hacker';
        },
        tooltipPosition() {
            return this.windowWidth < 1245 && this.isUserHacker ? 'left' : 'top';
        },
    },
    watch: {
        finishedCurrentPage: 'updateCurrentPageIntegrations',
        integrationData: 'updateCurrentPageIntegrations',
    },
    mounted() {
        this.currentPageIntegrations = this.sortedIntegrations(this.integrationData).slice(0, this.finishedPerPage);
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    created() {
        window.addEventListener('resize', this.updateWindowWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateWindowWidth);
    },
    methods: {
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
        updateCurrentPageIntegrations() {
            this.currentPageIntegrations = this.sortedIntegrations(this.integrationData)
                .slice((this.finishedCurrentPage - 1) * this.finishedPerPage, this.finishedCurrentPage * this.finishedPerPage);
        },
        checkAndUpdatePlatformText,
        tooltipMessage(item){
            return item.trial ? this.tooltipTrialMessage
                : this.tooltipDownloadText;
        },
        errorLoaded(id) {
            this.showSpinner = false;
            document.getElementById(id).src = this.DefaultLogo;
        },
        permissionToOpenIntegration(status) {
            return this.isActive(status) || this.isExpired(status) || this.isRenewalRequested(status) || this.isSampleIntegration(status);
        },
        async copyToClipboard(item) {
            try {
                await navigator.clipboard.writeText(item);
                this.tooltipCopyText = 'Link Copied!';
                setTimeout(() => {
                    this.tooltipCopyText = 'Copy link';
                }, 2000);
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        },
        copyIntegrationLink(item) {
            let link = window.location.origin + `/integration/${item.id}`;
            this.copyToClipboard(link);
        },
        downloadJobPDFReport(integrationId, versionId, defaultBranchName) {
            const folderName = this.$route.query.folderName || FolderNameEnum.ALL_VULNERABILITIES;

            this.showSpinner = true;
            let url = `/private/ui/pdf_report/integrations/${integrationId}/branches/${defaultBranchName}/versions/${versionId}`;
            this.axios
                .post(url, {
                    filters: { vulnerabilityCategoriesIds: [] },
                    folderName,
                })
                .then(
                    response => {
                        window.location.href = response.data.url;
                        this.showSpinner = false;
                    },
                    error => {
                        this.showSpinner = false;
                        console.log(error);
                    },
                );
        },
        isCreated(status) {
            return IntegrationStatusEnum.CREATED === status;
        },
        isUnderReview(status) {
            return IntegrationStatusEnum.UNDER_REVIEW === status;
        },
        isActive(status) {
            return IntegrationStatusEnum.ACTIVE === status;
        },
        isExpired(status) {
            return IntegrationStatusEnum.EXPIRED === status;
        },
        isRejected(status) {
            return IntegrationStatusEnum.REJECTED === status;
        },
        isRenewalRequested(status) {
            return IntegrationStatusEnum.RENEWAL_REQUESTED === status;
        },
        isSampleIntegration(status) {
            return IntegrationStatusEnum.SAMPLE === status;
        },
        isTrialRequested(status) {
            return IntegrationStatusEnum.TRIAL_REQUESTED === status;
        },
        showIntegrationIcon(status) {
            return !this.isRejected(status) && !this.isUnderReview(status) && !this.isTrialRequested(status);
        },
        updateFinishedPage(page) {
            this.finishedCurrentPage = page;
        },
        async deleteIntegration(id) {
            this.showSpinner = true;
            await this.$store.dispatch('integrations/deleteIntegrationById', { id: id });
            await this.$store.dispatch('integrations/loadListAllIntegrationByUserId');
            this.showSpinner = false;
        },
        addImportanceToIntegrations,
        filterAndSortIntegrationByImportance,
        sortedIntegrations(integrations) {
            const markedIntegrations = this.addImportanceToIntegrations(integrations);
            const integrationsFirstImportance = this.filterAndSortIntegrationByImportance(markedIntegrations, 1);
            const integrationsSecondImportance = this.filterAndSortIntegrationByImportance(markedIntegrations, 2);
            const integrationsThirdImportance = this.filterAndSortIntegrationByImportance(markedIntegrations, 3);
            const integrationsFourthImportance = this.filterAndSortIntegrationByImportance(markedIntegrations, 4);
            const integrationsFifthImportance = this.filterAndSortIntegrationByImportance(markedIntegrations, 5);
            const integrationsSixthImportance = this.filterAndSortIntegrationByImportance(markedIntegrations, 6);
            const integrationsSeventhImportance = this.filterAndSortIntegrationByImportance(markedIntegrations, 7);
            const integrationsEightImportance = this.filterAndSortIntegrationByImportance(markedIntegrations, 8);
            return [
                ...integrationsFirstImportance,
                ...integrationsSecondImportance,
                ...integrationsThirdImportance,
                ...integrationsFourthImportance,
                ...integrationsFifthImportance,
                ...integrationsSixthImportance,
                ...integrationsSeventhImportance,
                ...integrationsEightImportance,
            ];
        },
    },
};
</script>
<style lang="scss">
@import "../../assets/css/variables";
@import "../../assets/css/fonts";

#finished {
    margin: 0 auto;
}
.finished {
    overflow: auto;
    &__header-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        margin: 20px 7.5% 0 7.5%;
    }

    &__sign {
        margin: 0;
        @include Aspekta-font(600, 18px, normal, #242424);
    }

    &__files {
        margin: 0 40px 0 10px;
        @include Aspekta-font(500, 18px, normal, #B2B9C4);
    }
}

.forbidden-to-open-integration {
    @include Aspekta-font(500, 18px, normal, #242424);
    opacity: 0.6;
    cursor: default;
    word-break: break-word;
}

.integration-empty-list {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 30px;
    border-radius: 20px;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0 4px 15px rgba(102, 124, 131, 0.06);
    margin: 0 7.5%;
    min-height: 315px;
    max-width: 97%;

    &__sign {
        margin: 0 0 34px 0;
        @include Aspekta-font(400, $dynamic-integration-description-font-size, normal, #706969);
        letter-spacing: $dynamic-integration-description-letter-spacing;
    }

  &__btn {
    background: #FFFFFF;
    border: 2px solid #FFD2AB;
    box-sizing: border-box;
    border-radius: 8px;
    transition: 0.3s;
    padding: $dynamic-button-horizontal-padding $dynamic-button-vertical-padding;

    &:hover {
        box-shadow: 0px 8px 16px 0px rgba(255, 180, 0, 0.24);
    }

    &:active {
        opacity: 1;
    }

    a.nav-link {
        @include Aspekta-font(600, $dynamic-button-font-size, normal, #FF7A08);
        letter-spacing: $dynamic-button-letter-spacing;
    }
  }
}

.table-finished {
    background-color: white;
    margin: 0 auto;
    border-radius: 20px;
}

.finished-integrations-block {
    position: relative;
    background: #FFFFFF;
    border-radius: 20px;
    width: 85%;
    margin: 0 auto;
}

.table-header {
    @include Aspekta-font(100, 14px, 140%, #B2B9C4);
    align-items: center;
    text-align: center;
}

#finished tbody tr td:nth-child(7) {
    :first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
}

#finished tbody tr{
    border-bottom: 1px solid #f0f0f0;
    border-top: none !important;
}

#finished tbody tr:last-child {
    background-color: white;
    border-bottom: none !important;
}

#finished thead tr th {
    border: none;
    padding: 20px 0;
}

#finished tbody tr td {
    padding: 30px 0;
}

#finished tbody tr td:nth-child(1) {
    width: 8%;
}

#finished tbody tr td:nth-child(2) {
    width: 26%;
}

#finished tbody tr td:nth-child(3) {
    width: 10%;
}

#finished tbody tr td:nth-child(4) {
    width: 12%;
}

#finished tbody tr td:nth-child(5) {
    width: 12%;
}

#finished tbody tr td:nth-child(6) {
    width: 12%;
}

#finished tbody tr td:nth-child(7) {
    width: 20%;
}

/* Styles will be applied when pagination is disabled */
.pagination-disabled tbody tr:last-child td:nth-child(1) {
    border-bottom-left-radius: 20px !important;
}

.pagination-disabled tbody tr:last-child td:last-child {
    border-bottom-right-radius: 20px !important;
}

.pagination-disabled tr {
    border-radius: 20px !important;
}

.finished-versions-pagination {
    width: 100%;
    background-color: white;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 10px 0 20px 84px !important;
    margin: 0 auto;
}

#finished tbody tr td:nth-child(3),
#finished tbody tr td:nth-child(4),
#finished tbody tr td:nth-child(5),
#finished tbody tr td:nth-child(6) {
    @include Aspekta-font(400, 14px, 140%, #706969);
    text-align: center;
}

#finished tbody tr td:nth-child(7) {
    button {
        img {
            margin: 0 5px 0 0;
        }
    }
}

#finished thead tr th:nth-child(1),
#finished thead tr th:nth-child(7) {
    visibility: hidden;
}

#finished thead tr th:nth-child(2) {
    text-align: left;
    padding-left: 0;
}

#finished tbody tr td:nth-child(5) {
    word-break: break-all;
}

#finished tbody tr td:nth-child(2) a.nav-link {
    @include Aspekta-font(400, 18px, 140%, #242424);
    word-break: break-word;

    &:hover {
        text-decoration: underline;
    }
}

th {
    font-weight: normal;
}

td.table__file-name-finished {
    word-wrap: break-word;
    width: 271px;
}

td.table__status-finished {
    @include Aspekta-font(400, 14px, 140%, #242424);
    text-align: center;
    width: 150px;
}

td.table__lastVersion {
    @include Aspekta-font(400, 14px, 140%, #706969);
    width: 150px;
    text-align: center;

    p {
        margin: 0;
    }
}

td.table__packageName {
    width: 180px;
    max-width: 220px;
    font-size: 14px;
    color: #706969;
    text-align: center;
}

td.table__platform {
    width: 100px;
    font-size: 14px;
    color: #3180F6 !important;
    text-align: center;
}

.table-header__hidden {
    display: none;
}

td.table__icon {
    width: 90px;
    margin: 0 !important;
}

.table__icon-image {
    height: 20px;
    width: 20px;
    margin-left: 33px;
    border-radius: 5px;
}

td.table__actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

table.table-hover tbody tr:hover {
    box-shadow: 0 4px 15px rgba(56, 70, 77, 0.04);
    border-radius: 5px;

    &:hover .table__btn-icon {
        visibility: visible;
    }
}

.table__btn-icon {
    visibility: hidden;
}

.created {
    color: #00b253;
}

.under_review {
    color: #B2B9C4;
}

.active,
.renewal_requested {
    color: #706969;
}

.expired {
    color: #FB8D62;
}

.integration-finished__spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    overflow-y: hidden;
}

.table__packageName {
    word-wrap: break-word;
}

@media (max-width: 1120px) {
    .finished-integrations-block {
        width: 1120px;
    }

}

@media (max-width: 576px) {
    .finished {
        &__header-wrapper {
            margin: 20px 20px 0 20px;
        }
    }
}

</style>
