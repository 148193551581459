<template>
    <div ref="topTable">
        <b-table
            :items="files"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :table-class="['new-scan__table', isEditing && 'editing', tablet && 'tablet', mobile && 'mobile']"
            thead-class="hide-header"
            tbody-class="table-body"
        >
            <template #cell(index)="data">
                <span>
                    {{ data.index + 1 + ((currentPage-1)*perPage) }}
                </span>
                <button
                    v-if="tablet || mobile"
                    class="delete"
                    @click.prevent="$emit('delete', data.item)"
                >
                    <img
                        class="delete-icon"
                        src="@/assets/images/newScan/trash-icon.svg"
                        alt="delete"
                    >
                </button>
            </template>
            <template #cell(name)="data">
                <div
                    :title="data.item.name"
                    class="file-name"
                >
                    {{ data.item.name }}
                </div>
                <div class="status-wrapper">
                    <div
                        v-if="tablet || mobile"
                        class="platform-mobile"
                    >
                        {{ platformNameFormatter(data.item.platform) }}
                    </div>
                    <div class="dot-wrapper">
                        <div class="dot-spin new-scan" />
                    </div>
                    <div
                        class="status"
                        :class="returnStatusClass(calculateStatus(data.item))"
                    >
                        {{ calculateStatus(data.item) }}
                    </div>
                    <button
                        v-if="!tablet && !mobile"
                        class="delete"
                        @click.prevent="$emit('delete', data.item)"
                    >
                        <img
                            class="delete-icon"
                            src="@/assets/images/newScan/trash-icon.svg"
                            alt="delete"
                        >
                    </button>
                </div>
            </template>
        </b-table>
        <Pagination
            v-show="files.length > perPage"
            class="table-pagination"
            :count="files.length"
            :limit="perPage"
            :current-page="currentPage"
            @pageChanged="onPageChanged"
        />
    </div>
</template>

<script>
import Pagination from '../Pagination.vue';

export default {
    name: 'NewScanTable',
    components: {
        Pagination,
    },
    props: {
        files: {
            type: Array,
            default() {
                return [];
            },
            required: true,
        },
        isEditing: Boolean,
        tablet: Boolean,
        mobile: Boolean,
    },
    data() {
        const me = this;
        return {
            currentPage: 1,
            perPage: 10,
            fields: [
                {
                    key: 'index',
                    class: 'ghost-text',
                },
                {
                    key: 'platform',
                    class: 'ghost-text platform',
                    formatter(platform) {
                        return me.platformNameFormatter(platform);
                    },
                },
                {
                    key: 'name',
                    class: 'file-status-wrapper',
                },
            ],
        };
    },
    watch: {
        currentPage() {
            this.$refs.topTable.scrollIntoView({ behavior: 'smooth' });
        },
    },
    methods: {
        calculateStatus({ status, price, errorMessage, jobType, isScanPack }) {
            switch (status) {
                case 'error':
                    return `Error.(${errorMessage})`;
                case 'added':
                case 'queued':
                    return 'Waiting to Upload';
                case 'uploading':
                    return 'Uploading';
                case 'success':
                    return this.calculatePrice(jobType, price, isScanPack);
            }
        },
        calculatePrice(jobType, price, isScanPack) {
            switch(jobType) {
                case 'TRIAL':
                case 'PREPAID':
                    return isScanPack ? '0 $' : jobType;
                case 'PAID':
                    return `${price/100} $`;
                default:
                    return '';
            }
        },
        returnStatusClass(type) {
            switch (type) {
                case type.includes('Error') && type:
                    return 'error';
                case 'Waiting to Upload':
                    return 'waiting';
                case 'Uploading':
                    return 'uploading';
                case 'TRIAL':
                    return 'trial';
                case 'PREPAID':
                    return 'prepaid';
                default:
                    return 'price';
            }
        },
        onPageChanged(page) {
            this.currentPage = page;
        },
        platformNameFormatter(platform) {
            switch(platform) {
                case 'android':
                    return 'Android';
                case 'ios':
                    return 'iOS';
                default:
                    return '';
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';
@mixin line-clamp($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.hide-header {
    display: none;
}
.new-scan__table tr {
    td {
        border-top: none;
        &:first-child {
            padding-left: 0;
        }
    }
    &:first-child {
        border-top: none;
    }
}
.new-scan__table tr {
    border-top: 1px solid $ghost-border;
    td {
        padding: 30px 20px;
    }
    .ghost-text {
        color: $ghost-text;
        text-align: center;
        width: 10px;
    }
    .file-status-wrapper {
        display: flex;
        align-items: center;
        gap: 40px;
        justify-content: space-between;
        .file-name {
            @include line-clamp(2);
            flex-shrink: 2;
            word-break: break-all;
            color: $main-text;
            min-width: 170px;
        }
        .status-wrapper {
            display: flex;
            gap: 20px;
            .delete {
                display: none;
            }
            .status {
                @include line-clamp(2);
                white-space: nowrap;
                &.error {
                    color: $error-red-color;
                    white-space: normal;
                    text-align: right;
                }
                &.waiting {
                    color: $ghost-text;
                }
                &.uploading {
                    color: $uploading-yellow;
                    font-weight: 600;
                }
                &.trial {
                    color: $ghost-text;
                    font-weight: 600;
                }
                &.prepaid {
                    color: $new-orange-color;
                    font-weight: 600;
                }
                &.price {
                    color: $main-text;
                    font-weight: 600;
                }
            }
        }
    }
}
.new-scan__table {
    &.mobile, &.tablet {
        background-color: #fff;
        margin-top: 15px;
        padding: 0 10px;
        .platform {
            display: none;
        }
    }
    &.mobile tr, &.tablet tr {
        .ghost-text {
            width: 40px;
        }
        td {
            padding: 10px;
        }
        .file-status-wrapper {
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
            .status-wrapper {
                .status {
                    font-size: 14px;
                }
                .platform-mobile {
                    display: flex;
                    align-items: center;
                    color: $ghost-text;
                    font-size: 14px;
                    gap: 10px;
                    &::after {
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background-color: $ghost-text;
                    }
                }
            }
        }
    }
}
.new-scan__table tr {
    .ghost-text {
        .delete {
            display: none;
        }
    }
}
.new-scan__table.editing tr {
    .ghost-text {
        span {
            display: none;
        }
        .delete {
            display: block;
        }
    }
}
.table-pagination {
    display: flex;
    justify-content: flex-end;
}
.dot-wrapper {
    display: none;
    &:has(+ div.status.uploading) {
        width: 105px;
        height: 10px;
        display: block;
        .dot-spin.new-scan {
            transform: scale(.5);
            margin-top: 5px;
        }
    }
}

@media (min-width: 901px) {
    .new-scan__table tr:hover {
    .file-status-wrapper {
        .status-wrapper {
            .delete {
                display: block;
            }
        }
    }
}
}

</style>
