<template>
    <b-container>
        <div class="forgot-password">
            <h2
                v-if="errors.timeout"
                class="confirm-email__alert"
            >
                {{ showAlert(errors.timeout, 3000, 'error') }}
            </h2>
            <h1 class="forgot-password__title">
                Reset Password
            </h1>
            <form
                v-if="!isRequestSend"
                class="forgot-password__form"
                novalidate
                @submit.prevent="handleForm"
            >
                <input
                    id="email"
                    v-model.trim="inputData.email"
                    class="input"
                    name="email"
                    type="email"
                    @blur="activeInputName = null"
                    @input="handleInput"
                    @change="handleInput"
                    @keyup.delete="handleInput"
                >
                <img
                    v-if="isEmailValid && inputData.email.length > 0 && checkInputForErrors('emailError') && checkInputForErrors('emailValidationError')"
                    class="sign-up-company__email-check"
                    src="../../assets/images/greenCheck.svg"
                    alt=""
                >
                <img
                    v-if="isEmailValid === false && inputData.email.length > 0"
                    class="sign-up-company__email-check"
                    src="../../assets/images/cross.svg"
                    alt=""
                    @click="clearInputAndErrors('email')"
                >
                <label
                    for="email"
                    class="label"
                    :class="{ activeInput: inputData.email !== isEmptyInput || activeInputName === 'email'}"
                >
                    Email
                </label>
                <span
                    id="emailError"
                    class="forgot-password__error"
                >
                    <img
                        class="forgot-password__error-cross"
                        src="../../assets/images/cross.svg"
                        alt="cross"
                        @click="clearInputAndErrors('email')"
                    >
                    <span class="forgot-password__error-text">Required field</span>
                </span>
                <span
                    id="emailValidationError"
                    class="sign-up-researcher__error"
                >
                    <img
                        class="forgot-password__error-cross"
                        src="../../assets/images/cross.svg"
                        alt="cross"
                        @click="clearInputAndErrors('email')"
                    >
                    <span class="forgot-password__error-text">Please enter a valid email address</span>
                </span>
                <button class="forgot-password__btn-submit">
                    Reset Password
                </button>
                <div class="forgot-password__link-to-login-wrapper">
                    <p class="forgot-password__text forgot-password__text--margin">
                        Back to the
                    </p>
                    <b-nav-item
                        to="/sign-in"
                        class="forgot-password__link--orange forgot-password__text--margin"
                    >
                        Sign In
                    </b-nav-item>
                    <p class="forgot-password__text">
                        page
                    </p>
                </div>
            </form>
            <div
                v-else
                class="forgot-password__message"
            >
                <h6 class="forgot-password__text forgot-password__text--center">If specified email is registered, you’ll
                    <br> receive an email with password reset</h6>
                <b-nav-item
                    to="/sign-in"
                    class="forgot-password__back-link"
                >
                    Back to the Sign In page
                </b-nav-item>
            </div>
            <div
                v-if="isLoading"
                class="forgot-password__spinner"
            >
                <Spinner />
            </div>
        </div>
    </b-container>
</template>

<script>
import Spinner from '@/components/Spinner';
import { store } from '@/store';
import {
    checkFormInputsForCompleteness,
    checkFormInputsForErrors,
    checkEmailValidation,
    checkInputForErrors,
    validateEmail,
    hideErrors,
} from '@/services/functions';
import { mapGetters } from 'vuex';

export default {
    components: {
        Spinner,
    },
    data() {
        return {
            isRequestSend: false,
            inputData: {
                email: '',
            },
            isLoading: false,
            isEmailValid: null,
            isEmptyInput: '',
            activeInputName: null,
        };
    },
    computed: {
        ...mapGetters({
            errors: 'authentication/errors',
        }),
    },
    methods: {
        checkInputForErrors,
        clearInputAndErrors(inputField) {
            this.inputData.email = '';
            hideErrors(['emailError', 'emailValidationError']);
            document.getElementById(inputField).style.border = '1px solid #F0F0F0';
        },
        async handleForm() {
            store.commit('authentication/clearErrors');

            const errorIds = ['emailError', 'emailValidationError'];
            hideErrors(errorIds);

            if (this.inputData.email) {
                checkEmailValidation(
                    this.inputData,
                    'forgot-password__input--validation-error',
                );
            }

            checkFormInputsForErrors(this.inputData, 'forgot-password__input--error');

            if (checkFormInputsForCompleteness(this.inputData) && validateEmail(this.inputData.email)) {
                await this.sendRequest({ email: this.inputData.email });
            }
        },
        async sendRequest(payload) {
            try {
                this.isLoading = true;
                await this.$auth.sendForgotPassword(payload);
                this.isLoading = false;
                if (!Object.keys(this.errors).length) this.isRequestSend = true;
            } catch (error) {
                this.isLoading = false;
                console.log(error);
            }
        },
        handleInput(e) {
            this.checkKeyEvent(e);
            this.isEmailValid = validateEmail(this.inputData.email);

            hideErrors(['emailError', 'emailValidationError']);
            document.getElementById('email').style.border = '1px solid #F0F0F0';
            store.commit('authentication/clearErrors');
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id;
        },
        showAlert(message, timer, status) {
            this.$swal({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                icon: status,
                title: message,
                timer: timer,
                timerProgressBar: true,
            });
        },
    },
};
</script>

<style lang="scss">
@import "../../assets/css/fonts";

.forgot-password {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 71px - 192px);
    min-height: 390px;

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        @include Aspekta-font(700, 60px, normal, #242424);
        font-size: calc(31px + (60 - 31) * calc((100vw - 320px) / (1920 - 320)));
        letter-spacing: 0em;
        margin: 36px 0;
    }

    &__form {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 395px;
    }

    &__message {
        width: 100%;
    }

    &__input {

        &--error,
        &--validation-error {
            border: 1px solid #FB8D62;
            border-radius: 5px;
        }
    }

    &__text {
        margin: 0;
        @include Aspekta-font(500, 18px, 150%, #706969);

        &--margin {
            margin-right: 5px;
        }

        &--center {
            text-align: center;
        }
    }

    &__link--orange {
        font-family: Cabin, serif;

        .nav-link {
            margin: 0;
        }

        a {
            @include Aspekta-font(500, 18px, normal, transparent);
            font-size: 18px !important;
            color: #FFB400;

            &:hover {
                color: #FF7A08;
                text-decoration: underline
            }
        }
    }

    &__link-to-login-wrapper {
        display: flex;
        align-items: center;
    }


    &__btn-submit {
        @include Aspekta-font(600, 18px, normal, #FFFFFF);
        background: #FF7A08;
        border: 2px solid #FF7A08;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px 40px;
        margin: 0 0 28px 0;
        transition: 0.3s;

        &:focus {
            border: 2px solid #FFB400;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &__back-link {
        max-width: 240px;
        margin: 0 auto;

        a.nav-link {
            @include Aspekta-font(600, 18px, normal, #FFFFFF);
            background: #FF7A08;
            border: 2px solid #FF7A08;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            border-radius: 8px;
            color: #FFFFFF;
            margin-top: 30px;
            padding: 10px 0;
            max-width: 240px;
            transition: 0.3s;

            &:focus {
                border: 2px solid #FFB400;
            }

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__error {
        width: 100%;
        max-width: 831px;
        position: relative;
    }

    &__error-cross {
        position: absolute;
        top: -57px;
        right: 20px;
        cursor: pointer;
    }

    &__error-text {
        @include Aspekta-font(500, 10px, 150%, #FB8D62);
        position: absolute;
        top: -30px;
        left: 3px;
        margin: 0;
    }
}

#emailError, #passwordError {
    display: none;
}

.sign-up-company__email-check {
    cursor: pointer;
}

@media (max-width: 1440px) {
    .forgot-password {
        height: calc(100vh - 71px - 251px);
    }
}

@media (max-width: 768px) {
    .forgot-password {
        padding: 0 20px;

        &__title {
            margin: 30px 0;
            text-align: center;
        }

        &__form {
            margin-bottom: 30px;
        }

        &__link--orange {
            a {
                font-size: 16px !important;
            }
        }

        &__btn-submit {
            padding: 10px 0;
            width: 100%;
            margin: 15px 0 28px 0;
        }

        &__back-link {
            width: 100% !important;
            max-width: 100%;

            a.nav-link {
                max-width: 100%;
                width: 100% !important;
                font-size: 18px !important;
            }
        }
    }
}

@media (max-width: 594px) {
    .forgot-password {
        height: calc(100vh - 71px - 331px);
    }
}
</style>
