import {getInstance} from "./auth";
import {store} from '../src/store/index'
import router from './router'
import {IntegrationStatusEnum} from "./utils/constants/common";
import {getAccessTokenHandler} from "./services/authorization/getAccessToken";
import {routeUserToPageByTypeAfterLogin, redirectUserToZendeskAfterLogin} from "./services/authorization/routeUser";

export const authGuard = async (to, from, next) => {
    const authService = getInstance();

    const fn = async () => {
        // If the user is authenticated, continue with the route
        if (authService.isAuthenticated) {
            return next();
        }

        // Otherwise, log in
        authService.loginWithRedirect({appState: {targetUrl: to.fullPath}});
    };
    if (!authService.loading) {
        return fn();
    }
    authService.$watch("loading", loading => {
        if (loading === false) {
            return fn();
        }
    });
};

export const authGuardGuest = async (to, from, next) => {
    const authService = getInstance();

    const fn = async () => {
        return next();
    };

    if (!authService.loading) {
        return fn();
    }
    authService.$watch("loading", loading => {
        if (loading === false) {
            return fn();
        }
    });
};

export const isTypeCompany = async (to, from, next) => {
    await store.dispatch('user/fetchUserInfo')
    if (store.getters["user/userType"] === 'company') {
        await router.push('/settings')
    }
    next()
};

export const companyGuard = async (to, from, next) => {
    await store.dispatch('user/fetchUserInfo')
    if (store.getters["user/userType"] === 'company') {
        next('/403');
    } else {
        next()
    }
};

export const checkAccessToken = async (to, from, next) => {
    await getAccessTokenHandler()
    next()
};

export const checkStatusIntegration = async (to, from, next) => {
    let integrationStatus;
    if (store.getters["integrations/integrationById"]) {
        integrationStatus = await store.getters["integrations/integrationById"].status
    } else {
        await store.dispatch('integrations/loadIntegrationById', {id: to.params.id, branchName: to.params.branchName, page: 1, limit: 10})
        integrationStatus = await store.getters["integrations/integrationById"].status
    }
    if (integrationStatus === IntegrationStatusEnum.EXPIRED || integrationStatus === IntegrationStatusEnum.RENEWAL_REQUESTED) {
        await router.push('/integrations')
    } else {
        next()
    }
};

export const signOut = async (to, from, next) => {
    await getInstance().logout()
};

export const beforeEnterAuthPage = async (to, from, next) => {
    const authService = getInstance();
    store.commit('authentication/clearErrors')

    const fn = async () => {
        let brand_id_query = to.query.brand_id
        let brand_id_params = to.params.brand_id
        if (authService.isAuthenticated) {
            // redirect to the next page (or zendesk)
            if (brand_id_query || brand_id_params) {
                await redirectUserToZendeskAfterLogin()
            } else {
                const userType = localStorage.getItem('userType');
                await routeUserToPageByTypeAfterLogin(userType)
            }
        } else {
            // go to the sign in page for auth
            if (brand_id_query) {
                next({params: to.query})
            } else {
                next()
            }
        }
    };
    if (!authService.loading) {
        return fn();
    }
    authService.$watch("loading", loading => {
        if (loading === false) {
            return fn();
        }
    });
};

export const beforeForbiddenPage = (to, from, next) => {
    if (from.name === null && to.query.redirect) {
        router.push({path: to.query.redirect});
    } else {
        next();
    }
};

export const hackerGuard = async (to, from, next) => {
    if (!store.getters["user/userType"]) await store.dispatch('user/fetchUserInfo');
    if (store.getters["user/userType"] === 'hacker') {
        next('/403');
    } else {
        next();
    }
};
