<template>
    <div class="story-card">
        <div class="customer">
            <div class="customer-top">
                <img
                    v-if="!isMobile"
                    class="quote"
                    src="../../../assets/images/success-stories/quote.svg"
                    alt="quote"
                >
                <span class="customer-top__text">
                    <span
                        v-text="firstText"
                    />
                    <button
                        v-if="showExpandButton"
                        class="expanding-btn"
                        @click="expandText"
                    >...read more</button>
                    <span v-if="showCollapsedBlock && isMobile">
                        {{ secondText }}
                        <button
                            class="expanding-btn"
                            @click="collapseBlock"
                        >hide</button>
                    </span>
                </span>
            </div>
            <div class="customer-description">
                <img
                    class="customer-description__photo"
                    :src="photoUrl"
                    alt="photo"
                >
                <div class="customer-description-about">
                    <span class="customer-description-about__name">{{ customerName }}</span>
                    <span class="customer-description-about__position">{{ customerPosition }}</span>
                </div>
            </div>
        </div>
        <img
            class="line"
            src="../../../assets/images/success-stories/line.svg"
            alt="line"
        >
        <div class="company">
            <div class="company-icon">
                <img
                    :src="companyIconUrl"
                    alt="company-icon"
                >
            </div>

            <div class="company-text">
                <span class="company-text__large">{{ companyTextLarge }}<span class="company-text__small">{{ companyTextSmall }}</span></span>
                <span class="company-text__description">{{ companyTextDescription }}</span>
            </div>
        </div>
    </div>
</template>
<script>


export default {

    props:{
        customerText:{
            type: String,
            default: '',
        },
        customerName:{
            type: String,
            default: '',
        },
        customerPosition:{
            type: String,
            default: '',
        },
        companyTextLarge:{
            type: String,
            default: '',
        },
        companyTextSmall:{
            type: String,
            default: '',
        },
        companyTextDescription:{
            type: String,
            default: '',
        },
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            mobileLimit: 170, //quantity of symbols
            desktopLimit:372,
            expanded: false,
            showCollapsedBlock:false,

        };
    },
    computed: {

        isMobile() {
            return this.windowWidth < 768;
        },

        photoUrl() {
            switch (this.customerName) {
                case 'Jane Williams':
                    return require('../../../assets/images/success-stories/jane_williams_photo.svg');
                case 'Robert Taylor':
                    return require('../../../assets/images/success-stories/robert_taylor_photo.svg');
                case 'Andrew Smith':
                    return require('../../../assets/images/success-stories/andrew_smith_photo.svg');
                case 'Kristoffer Blasiak':
                    return require('../../../assets/images/success-stories/kristopher_blasiak_photo.svg');
                case 'Gaurang Bhatnagar':
                    return require('../../../assets/images/success-stories/gaurang_bhatnagar_photo.svg');
                default:
                    return '';
            }
        },
        companyIconUrl() {
            switch (this.customerName) {
                case 'Jane Williams':
                    return require('../../../assets/images/success-stories/amazon_logo.svg');
                case 'Robert Taylor':
                    return require('../../../assets/images/success-stories/netflix_logo.svg');
                case 'Andrew Smith':
                    return require('../../../assets/images/success-stories/dropbox_logo.svg');
                case 'Kristoffer Blasiak':
                    return require('../../../assets/images/success-stories/google_logo.svg');
                case 'Gaurang Bhatnagar':
                    return require('../../../assets/images/success-stories/vmware_logo.svg');
                default:
                    return '';
            }
        },
        currentLimit() {
            return this.isMobile? this.mobileLimit: this.desktopLimit;
        },

        showExpandButton() {
            return !this.expanded && this.customerText.length > this.currentLimit;
        },
        firstText() {
            return this.customerText.substring(
                0, this.findSpaceIndexAfterLimit(this.customerText, this.currentLimit));
        },
        secondText() {
            return this.customerText.substring(
                this.findSpaceIndexAfterLimit(this.customerText, this.currentLimit));
        },

    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        expandText() {
            this.expanded = true;
            this.showCollapsedBlock = true;
        },
        collapseBlock() {
            this.expanded = false;
            this.showCollapsedBlock = false;
        },
        findSpaceIndexAfterLimit(text, currentLimit) {
            let newLimit = currentLimit;
            while(newLimit < text.length && text[newLimit] !== ' ') {
                newLimit++;
            }
            return newLimit;
        },
    },

};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/variables.scss";
@import "../../../assets/css/variables/variablesForSuccessStories.scss";
@import "../../../assets/css/fonts";



.story-card {
    display: flex;
    width: $dynamic-story-card-width;
    padding: $dynamic-story-card-padding;//60px; 4rem
    align-items: center;
    gap: $dynamic-story-card-gap; //60px; 4rem
    border-radius: 40px;
    border: 2px solid var(--stroke, #E8E7EF);
    background: var(--white, #FFF);
    height: 100%;

}
.customer {
    display: flex;
    width: 66rem; //94%
    height: 100%; //283px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;

    &-top {
        display: flex;
        gap: 30px;

        &__text {
            @include Aspekta-font(400, $dynamic-customer-top-text-font-size, normal, $general-black);
            text-align: left;
        }

    }
}
.customer-description {
    display: flex;
    gap: 30px;

    &-about {
        display: flex;
        flex-direction: column;
        gap: 2px;
        text-align: left;
        &__name {
            @include Aspekta-font(500, 18px, normal, $general-black);
        }
        &__position {
            @include Aspekta-font(400, 18px, normal,#797E86);
        }
    }
    &__photo {
         width: 60px;
    }
}

.quote {
    width: 50px;
    height: 36px;
}

.line {
    width: 2px;
    height: 100%;
    border-radius: 10px;
    background: var(--stroke, #E8E7EF);
}

.company {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 24rem; //269px;
    gap: 7rem; //144px;
    justify-content: space-between;

    &-text {
        display: flex;
        flex-direction: column;
        text-align: left;

        &__large {
            @include Aspekta-font(700, $dynamic-company-text-large-font-size, normal, $general-black);
        }
        &__small {
            margin-left: 10px;
            @include Aspekta-font(700, $dynamic-company-text-small-font-size, normal, $general-black);
        }
        &__description {
            @include Aspekta-font(400, $dynamic-company-text-description-font-size, normal, #797E86);
        }
    }
}
.expanding-btn {
    @include Aspekta-font(400, $dynamic-customer-top-text-font-size, normal, #FF7A08);
    text-decoration-line: underline;
}



@media (max-width: 1280px ) {

   .customer-description{

        &-about {

            &__name {
                font-size: 16px
            }

            &__position {
            font-size: 14px;
            }
        }

        &__photo {
            width: 50px;
        }
    }

}
@media (max-width: 768px) {

    .story-card {
        width: 100%;
        padding: 40px 2.5rem; // 40px 35px;
        gap: 35px;
        flex-direction: column-reverse;
    }

    .line {
        width: 100%;
        height: 2px;
    }

    .customer {
        width: 100%;
        height: min-content; //164px;
        gap: 4rem;  //50px

        &-top__text {
            height: min-content;
            overflow: hidden;
        }
    }

    .customer-description {

        &-about {

            &__name {
                font-size: 14px
            }

            &__position {
            font-size: 12px;
            }
        }

        &__photo {
            width: 44px;
        }
    }

    .company {
       width:100%;
       gap: 3rem;

        &-text {

            &__large {
                font-size: 32px;
            }

            &__small {
            font-size: 18px;
            }

            &__description {
                font-size: 14px;
            }
        }
    }
}

</style>
