<template>
    <ScanPacksBlackFriday />
</template>
<script>
import ScanPacksBlackFriday from '../components/scanPacks/ScanPacksBlackFriday';
export default {
    components: {
        ScanPacksBlackFriday,
    },
};
</script>
<style>

</style>
