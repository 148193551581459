import Vue from "vue";

const VUE_APP_AUTH_BASE_URL = process.env.VUE_APP_AUTH_BASE_URL

export const resendConfirmEmailRequest = (payload) => {
    return Vue.axios.post(
        `${VUE_APP_AUTH_BASE_URL}/authorization/resend-confirm-email`,
        payload
    )
}
