<template>
    <b-container>
        <div class="new-password">
            <h1 v-if="!isRequestSend" class="new-password__title">New Password</h1>
            <h1 v-else class="new-password__title">Success!</h1>
            <form v-if="!isRequestSend" @submit.prevent="handleForm" class="new-password__form">
                <img class="new-password__toggle-show-password icon"
                     src="../../assets/images/myScans/icon_action_hide.svg"
                     id="passwordShow"
                     @click="toggleShowPassword"
                     alt=""
                >
                <img
                    class="new-password__toggle-show-password new-password__toggle-show-password--bigger icon"
                    src="../../assets/images/myScans/icon_action_show.svg"
                    id="passwordHide"
                    @click="toggleShowPassword"
                    alt=""
                >
                <input class="input new-password_input"
                       name="password"
                       id="password"
                       type="password"
                       @blur="activeInputName = null"
                       @input="checkKeyEvent"
                       v-model.trim="inputData.password"
                >
                <label for="password" class="label" v-bind:class="{ activeInput: inputData.password !== isEmptyInput || activeInputName === 'password'}">Password</label>
                <span id="passwordError" class="new-password__error new-password__error-length">
                    <span class="new-password__error-text">
                        Required field
                    </span>
                </span>
                <span id="passwordLengthError" class="new-password__error new-password__error-length">
                    <span class="new-password__error-text">
                        Minimum 12 characters
                    </span>
                </span>
                <button class="new-password__btn-submit">Confirm New Password</button>
            </form>
            <div v-else class="new-password__message">
                <h6 class="new-password__text new-password__text--center">You have set a new password. <br> Login to
                    start</h6>
                <b-nav-item to="/sign-in" class="new-password__back-link">Back to Login</b-nav-item>
            </div>
            <div v-if="isPasswordChangingNow" class="new-password__spinner">
                <Spinner/>
            </div>
        </div>
    </b-container>
</template>

<script>
import {
    checkFormInputsForCompleteness,
    checkFormInputsForErrors, checkPasswordLength,
    toggleShowPassword, validatePassword,
    raiseError, hideErrors
} from "@/services/functions";
import Spinner from "@/components/Spinner";

export default {
    components: {
        Spinner
    },
    data() {
        return {
            isPasswordChangingNow: false,
            isRequestSend: false,
            inputData: {
                password: ''
            },
            isEmptyInput: "",
            activeInputName: null,
        }
    },
    created() {
        this.signature = this.$route.params.signature;
    },
    methods: {
        async handleForm() {
            hideErrors(['passwordError', 'passwordLengthError'])
            if (!this.inputData.password) {
                return raiseError('password', 'passwordError', 'new-password__input--length-error')
            }

            this.checkPasswordLength(
                this.inputData,
                'new-password__input--length-error'
            )

            this.checkFormInputsForErrors(this.inputData, 'new-password__input--error')
            if (this.checkFormInputsForCompleteness(this.inputData) && validatePassword(this.inputData.password)) {
                await this.sendRequest()
            }
        },
        async sendRequest() {
            try {
                this.isPasswordChangingNow = true
                await this.$auth.changePassword({id: this.signature, password: this.inputData.password})
                this.isPasswordChangingNow = false
                this.isRequestSend = true
            } catch (error) {
                this.isPasswordChangingNow = false
                console.log(error)
            }
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id
        },
        toggleShowPassword,
        checkFormInputsForErrors,
        checkFormInputsForCompleteness,
        checkPasswordLength,
        validatePassword,
    }
}
</script>

<style lang="scss">
.new-password {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        font-family: Raleway, serif;
        font-weight: bold;
        font-size: 72px;
        color: #242424;
        margin: 60px 0;
    }

    &__form {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 395px;
        margin-bottom: 131px;

        .icon {
            margin: 4px;
            width: 22px;
            height: 22px;
        }
    }

    &__message {
        width: 100%;
    }

    &__toggle-show-password {
        position: absolute;
        right: 11px;
        top: 5px;
        width: 30px;
        z-index: 5;
        cursor: pointer;
    }

    &__input {

        &--error,
        &--length-error {
            border: 1px solid #FB8D62;
            border-radius: 5px;
        }
    }

    &__text {
        margin: 0;
        font-family: Cabin, serif;
        font-weight: 500;
        font-size: 18px;
        color: #706969;
        line-height: 150%;

        &--center {
            text-align: center;
        }
    }

    &__link--orange {
        font-family: Cabin, serif;

        .nav-link {
            margin: 0;
        }

        a {
            font-weight: normal;
            font-size: 18px !important;
            color: #FFB400;
        }
    }

    &__link-to-login-wrapper {
        display: flex;
        align-items: center;
    }


    &__btn-submit {
        background: #FFB400;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Cabin, serif;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        padding: 10px 40px;
        margin: 0 0 28px 0;
        transition: 0.3s;

        &:focus {
            border: 2px solid #FFB400;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &__back-link {
        max-width: 195px;
        margin: 0 auto;

        a.nav-link {
            display: flex;
            justify-content: center;
            background: #FFB400;
            border: 2px solid #FFB400;
            box-sizing: border-box;
            border-radius: 8px;
            font-family: Cabin, serif;
            font-weight: bold;
            font-size: 18px;
            color: #FFFFFF;
            margin-top: 30px;
            padding: 10px 0;
            max-width: 195px;
            transition: 0.3s;

            &:focus {
                border: 2px solid #FFB400;
            }

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__error {
        width: 100%;
        max-width: 831px;
        position: relative;
    }


    &__error-text {
        position: absolute;
        top: -30px;
        left: 3px;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 150%;
        color: #FB8D62;
        margin: 0;
    }
}

#passwordError,
#passwordLengthError {
    display: none;
}

@media (max-width: 768px) {
    .new-password {
        padding: 0 20px;

        &__title {
            font-size: 40px;
            margin: 30px 0;
            text-align: center;
        }

        &__form {
            margin-bottom: 30px;
        }

        &__link--orange {
            a {
                font-size: 16px !important;
            }
        }

        &__btn-submit {
            padding: 10px 0;
            width: 100%;
            margin: 15px 0 28px 0;
        }

        &__back-link {
            width: 100% !important;
            max-width: 100%;

            a.nav-link {
                max-width: 100%;
                width: 100% !important;
                font-size: 18px !important;
            }
        }
    }
}
</style>
