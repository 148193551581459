<template>
    <div>
        <!-- in viewBox 3rd argument is width 4th argument is height  -->
        <svg :width="width" :height="height" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" :fill="fill" clip-rule="evenodd" d="M11.2583 4.47902L2.22118 13.5161C2.01489 13.7224 1.899 14.0022 1.899 14.294L1.899 16.0011C1.899 16.0563 1.94377 16.1011 1.999 16.1011H3.7061C3.99784 16.1011 4.27763 15.9852 4.48392 15.7789L13.521 6.74176L11.2583 4.47902ZM12.5311 3.20623L14.7938 5.46897L15.9252 4.3376C16.1595 4.10328 16.1595 3.72338 15.9252 3.48907L14.511 2.07486C14.2767 1.84054 13.8968 1.84054 13.6624 2.07486L12.5311 3.20623ZM0.948387 12.2434L12.3896 0.802065C13.3269 -0.135194 14.8465 -0.135193 15.7838 0.802065L17.198 2.21628C18.1352 3.15354 18.1352 4.67313 17.198 5.61039L5.75671 17.0517C5.21286 17.5955 4.47523 17.9011 3.7061 17.9011L1.999 17.9011C0.94966 17.9011 0.0989995 17.0504 0.0989993 16.0011L0.098999 14.294C0.0989989 13.5248 0.404533 12.7872 0.948387 12.2434Z"/>
        </svg>
    </div>
</template>
<script>
export default {
    name: "EditIcon",
    props: ["svgColor", "svgHeight", "svgWidth"],
    data() {
        return {
            fill: this.svgColor,
            width: this.svgWidth,
            height: this.svgHeight
        }
    },
    methods: {
        setFill(value) {
            this.fill = value;
        }
    }
};
</script>
