<template>
    <div class="settings-business">
        <div class="settings-business__wrapper">
            <div class="settings-business__box">
                <h1 class="settings-business__sign raleway raleway-bold">
                    Settings
                </h1>
            </div>
            <div
                class="link link-logout"
                @click="logout"
            >
                Logout
            </div>
        </div>
        <div class="main-contant-block">
            <ul class="nav-side-bar scroll">
                <li class="nav-side-bar__link">
                    <router-link
                        active-class="nav-side-bar__link--active"
                        class="nav-side-bar__link"
                        :to="{ path: `${makeLinkToAnchorSettings('profile-info')}` }"
                    >
                        Company Info
                    </router-link>
                </li>
                <li class="nav-side-bar__link">
                    <router-link
                        class="nav-side-bar__link"
                        active-class="nav-side-bar__link--active"
                        :to="{ path: `${makeLinkToAnchorSettings('integration-history')}` }"
                    >
                        Integration History
                    </router-link>
                </li>
                <li class="nav-side-bar__link">
                    <router-link
                        active-class="nav-side-bar__link--active"
                        class="nav-side-bar__link"
                        :to="{ path: `${makeLinkToAnchorSettings('sessions')}` }"
                    >
                        Sessions
                    </router-link>
                </li>
                <li class="nav-side-bar__link">
                    <router-link
                        active-class="nav-side-bar__link--active"
                        class="nav-side-bar__link"
                        :to="{ path: `${makeLinkToAnchorSettings('api-keys')}` }"
                    >
                        API
                    </router-link>
                </li>
                <li class="nav-side-bar__link">
                    <router-link
                        active-class="nav-side-bar__link--active"
                        class="nav-side-bar__link"
                        :to="{ path: `${makeLinkToAnchorSettings('security')}` }"
                    >
                        Security
                    </router-link>
                </li>
            </ul>
            <router-view/>
        </div>
    </div>
</template>
<script>
import {makeLinkToAnchorSettings} from '../../services/functions';
import {mapGetters} from 'vuex';

export default {
    data() {
        return {
            loaded: false,
        }
    },
    computed: {
        ...mapGetters({
            jobsHistory: 'jobs/jobsHistory',
            cards: 'stripe/cards',
            countJobsHistory: 'jobs/countJobsHistory',
        })
    },
    methods: {
        makeLinkToAnchorSettings,
        logout() {
            this.$auth.logout();
        },
    },
};
</script>
<style lang="scss">
.settings-business {
    background-color: #FAFBFC;
    padding: 50px 0 60px;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        margin: 0 7.5%;

        &--left {
            justify-content: flex-start;
        }
    }

    &__sign {
        font-size: 30px;
        line-height: 150%;
        color: #242424;
    }
}

@media (max-width: 1000px) {
    .link-logout {
        margin-right: 20px;
        color: #3180F6;
    }
    .scroll {
        overflow-x: auto !important;
        justify-content: normal !important;
        padding-bottom: 10px !important;
    }
}
</style>
