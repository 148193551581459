<template>
    <div class="delete-modal" v-click-outside="closeModalOutside">
        <div @click.stop="closeModal" class="delete-modal__close-btn">
            <img src ="../../../assets/images/closeModal.svg" alt="close btn"/>
        </div>
        <div class="delete-modal__title">{{title}}</div>
        <div class="delete-modal__text">
            {{text}}
        </div>
        <button @click.stop="deleteItem" class="delete-modal__btn cabin cabin-bold">
            Delete
        </button>
        <div v-if="showSpinnerModal" class="delete-modal__spinner">
            <Spinner/>
        </div>
    </div>
</template>

<script>
import Spinner from '../../Spinner'
import ClickOutside from 'vue-click-outside'
export default {
    name: 'DeleteModal',
    components: {
        Spinner
    },
    directives: {
        ClickOutside
    },
    props: {
        showSpinnerModal: Boolean,
        title: String,
        text: String
    },
    methods: {
        closeModal() {
            this.$emit('closeModal')
        },
        deleteItem() {
            this.$emit('deleteItem')
        },
        closeModalOutside() {
            if (this.showSpinnerModal) return
            this.closeModal()
        }
    } 
}
</script>

<style lang="scss">
@mixin modalForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    max-width: 382px;
    padding-bottom: 20px;
}
@mixin button {
    background: #FFB400;
    right: 0;
    border: 2px solid #FFB400;
    box-sizing: border-box;
    border-radius: 8px;
    color: #FFFFFF;
    transition: 0.3s;
    padding: 10px 40px;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    &:active {
        opacity: 1;
    }

    &:focus {
        border: 2px solid #FFB400;
    }
}
@mixin spinner {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,0.7);
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 100;
}
.delete-modal {
      @include modalForm;
      position: relative;
      padding-bottom: 25px;

      &__close-btn {
          align-self: end;
          margin: 15px 15px 0 0;
          cursor: pointer;
          position: relative;
      }

      &__title {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          word-break: break-all;
          letter-spacing: .025em;
          margin-top: 5px;
          max-width: 370px;
          line-height: 150%;
          text-align: center;
          color: #242424;
      }

      &__text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          margin: 24px 0;
          color: #706969;
          text-align: center;
      }
      &__btn {
          @include button;
      }
      &__spinner {
          @include spinner;
          position: absolute;
          align-content: center;
          border-radius: 10px;
          max-width: 382px;
      }
}
.text-wrap {
    max-width: 360px;
    text-overflow: ellipsis;
    overflow: hidden;
}
.btn-close {
    margin-top: 15px;
}
</style>
