<template>
    <div class="markdown">
        <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('gradle')}`}" class="md__link-wrapper">
            <h2 class="md__item-title">Oversecured plugin for Gradle</h2>
        </b-nav-item>
        <p>Add the dependency to the project-level <code>build.gradle</code> file:</p>
        <pre class="language-yml"><code class="language-yml"><span class="token key atrule">buildscript</span><span class="token punctuation"> {</span>
    <span class="token key atrule">repositories</span><span class="token punctuation"> {</span>
        <span class="token key atrule">maven</span><span class="token punctuation"> {</span>
            url 'https://plugins.gradle.org/m2/'
        <span class="token punctuation">}</span>
    <span class="token punctuation">}</span>
    <span class="token key atrule">dependencies</span><span class="token punctuation"> {</span>
        classpath 'com.oversecured:gradle-android:1.2'
    <span class="token punctuation">}</span>
<span class="token punctuation">}</span>
</code></pre>

        <p>In the module-level <code>build.gradle</code> file, apply the plugin:</p>
        <pre class="language-yml"><code class="language-yml"><span class="token key atrule">apply plugin</span>: 'com.oversecured'
</code></pre>
        <p>and set the settings like this:</p>
        <pre class="language-yml"><code class="language-yml"><span class="token key atrule">oversecured</span><span class="token punctuation"> {</span>
    buildTypes 'beta', 'release'
    integrationId '5e7096e2-7fed-43f0-9290-22d03c64cfbf'
    branchName 'main'
    accessToken System.env['OVERSECURED_ACCESS_TOKEN']
<span class="token punctuation">}</span>
</code></pre>
        <p>where <code>buildTypes</code> are types of builds that will be automatically sent for scanning.</p>
        <p>After building each of the specified build types, the plugin will automatically send it to the
            specified Integration as a new version of the app. The user can also omit <code>buildTypes</code>
            and call <code>oversecuredTask.execute(apkFilePath)</code> when desired.</p>
        <p><a href="https://plugins.gradle.org/plugin/com.oversecured">Plugin page</a>.<br>
            <a href="https://github.com/oversecured/oversecured-android-gradle">Plugin source code</a>.</p>
    </div>
</template>

<script>
import {makeAnchor, makeLinkToAnchor, makeLinkToAnchorCICD} from "../../../services/functions";
import {makeSubLinkToAnchor} from "@/services/functions";

export default {
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor,
        makeLinkToAnchorCICD,
    }
}
</script>

<style scoped>

</style>