var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"markdown"},[_c('b-nav-item',{staticClass:"md__link-wrapper",attrs:{"to":{ path: `${_vm.makeLinkToAnchorCICD('gradle')}`}}},[_c('h2',{staticClass:"md__item-title"},[_vm._v("Oversecured plugin for Gradle")])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('p',[_vm._v("and set the settings like this:")]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Add the dependency to the project-level "),_c('code',[_vm._v("build.gradle")]),_vm._v(" file:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',{staticClass:"language-yml"},[_c('code',{staticClass:"language-yml"},[_c('span',{staticClass:"token key atrule"},[_vm._v("buildscript")]),_c('span',{staticClass:"token punctuation"},[_vm._v(" {")]),_vm._v("\n    "),_c('span',{staticClass:"token key atrule"},[_vm._v("repositories")]),_c('span',{staticClass:"token punctuation"},[_vm._v(" {")]),_vm._v("\n        "),_c('span',{staticClass:"token key atrule"},[_vm._v("maven")]),_c('span',{staticClass:"token punctuation"},[_vm._v(" {")]),_vm._v("\n            url 'https://plugins.gradle.org/m2/'\n        "),_c('span',{staticClass:"token punctuation"},[_vm._v("}")]),_vm._v("\n    "),_c('span',{staticClass:"token punctuation"},[_vm._v("}")]),_vm._v("\n    "),_c('span',{staticClass:"token key atrule"},[_vm._v("dependencies")]),_c('span',{staticClass:"token punctuation"},[_vm._v(" {")]),_vm._v("\n        classpath 'com.oversecured:gradle-android:1.2'\n    "),_c('span',{staticClass:"token punctuation"},[_vm._v("}")]),_vm._v("\n"),_c('span',{staticClass:"token punctuation"},[_vm._v("}")]),_vm._v("\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("In the module-level "),_c('code',[_vm._v("build.gradle")]),_vm._v(" file, apply the plugin:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',{staticClass:"language-yml"},[_c('code',{staticClass:"language-yml"},[_c('span',{staticClass:"token key atrule"},[_vm._v("apply plugin")]),_vm._v(": 'com.oversecured'\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',{staticClass:"language-yml"},[_c('code',{staticClass:"language-yml"},[_c('span',{staticClass:"token key atrule"},[_vm._v("oversecured")]),_c('span',{staticClass:"token punctuation"},[_vm._v(" {")]),_vm._v("\n    buildTypes 'beta', 'release'\n    integrationId '5e7096e2-7fed-43f0-9290-22d03c64cfbf'\n    branchName 'main'\n    accessToken System.env['OVERSECURED_ACCESS_TOKEN']\n"),_c('span',{staticClass:"token punctuation"},[_vm._v("}")]),_vm._v("\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("where "),_c('code',[_vm._v("buildTypes")]),_vm._v(" are types of builds that will be automatically sent for scanning.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("After building each of the specified build types, the plugin will automatically send it to the specified Integration as a new version of the app. The user can also omit "),_c('code',[_vm._v("buildTypes")]),_vm._v(" and call "),_c('code',[_vm._v("oversecuredTask.execute(apkFilePath)")]),_vm._v(" when desired.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://plugins.gradle.org/plugin/com.oversecured"}},[_vm._v("Plugin page")]),_vm._v("."),_c('br'),_c('a',{attrs:{"href":"https://github.com/oversecured/oversecured-android-gradle"}},[_vm._v("Plugin source code")]),_vm._v(".")])
}]

export { render, staticRenderFns }