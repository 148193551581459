import {IntegrationStatusEnum} from "@/utils/constants/common";

export const addImportanceToIntegrations = (integrations) => {
    return integrations.map(integration => {
        switch (integration.status) {

            case IntegrationStatusEnum.ACTIVE:
                return {...integration, importance: 1}
            case IntegrationStatusEnum.RENEWAL_REQUESTED:
                return {...integration, importance: 2}
            case IntegrationStatusEnum.TRIAL_REQUESTED:
                return {...integration, importance: 3}
            case IntegrationStatusEnum.UNDER_REVIEW:
                return {...integration, importance: 4}
            case IntegrationStatusEnum.EXPIRED:
                return {...integration, importance: 5}
            case IntegrationStatusEnum.REJECTED:
                return {...integration, importance: 6}
            case IntegrationStatusEnum.SAMPLE:
                return {...integration, importance: 7}
            default:
                return {}
        }
    })
}

export const filterAndSortIntegrationByImportance = (integrations, importance) => {
    return integrations
        .filter(integration => integration.importance === importance)
        .sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt))
}
