<template>
    <div class="settings-business">
        <div class="settings-business__wrapper">
            <div class="settings-business__box">
                <h1 class="settings-business__sign raleway raleway-bold">Integration Settings</h1>
            </div>
        </div>
        <div class="main-contant-block">
            <ul class="nav-side-bar nav-bar-scroll">
                <li class="nav-side-bar__link">
                    <router-link
                        active-class="nav-side-bar__link--active"
                        class="nav-side-bar__link"
                        :to="{ path: `${makeLinkToAnchorIntegrationSettings('general', this.$route.params.id)}`}"
                    >
                        General Settings
                    </router-link>
                </li>
                <li class="nav-side-bar__link" v-if="integration && allowedAccessStatuses.includes(integration.status)">
                    <router-link
                        class="nav-side-bar__link"
                        active-class="nav-side-bar__link--active"
                        :to="{ path: `${makeLinkToAnchorIntegrationSettings('members', this.$route.params.id)}`}"
                    >
                        Members
                    </router-link>
                </li>
                <li class="nav-side-bar__link" v-if="integration && allowedAccessStatuses.includes(integration.status)">
                    <router-link
                        class="nav-side-bar__link"
                        active-class="nav-side-bar__link--active"
                        :to="{ path: `${makeLinkToAnchorIntegrationSettings('webhooks', this.$route.params.id)}`}"
                    >
                        Webhooks
                    </router-link>
                </li>
            </ul>
            <router-view v-if="!showSpinner"/>
            <Spinner v-if="showSpinner" />
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
import Spinner from '../Spinner'
import {makeLinkToAnchorIntegrationSettings} from '../../services/functions'
import {IntegrationStatusEnum} from '../../utils/constants/common'

export default {
    components: {
      Spinner
    },
    data() {
        return {
            loaded: false,
            ENV: process.env.NODE_ENV,
            showSpinner: false,
            integrationId: null,
            allowedAccessStatuses: [IntegrationStatusEnum.ACTIVE, IntegrationStatusEnum.EXPIRED, IntegrationStatusEnum.TRIAL_REQUESTED, IntegrationStatusEnum.SAMPLE]
        }
    },
    async beforeMount() {
        this.showSpinner = true
        this.integrationId = this.$route.params.id
        await this.$store.dispatch('integrations/getIntegration', {id: this.integrationId})
        this.showSpinner = false
    },
    methods: {
        makeLinkToAnchorIntegrationSettings,
    },
    computed: {
        ...mapGetters({
            integration: 'integrations/integration'
        })
    }
}
</script>
<style lang="scss">
.settings-business {
    background-color: #FAFBFC;
    padding: 50px 0 60px;

    &__wrapper,
    &__box {
        display: flex;
        justify-content: space-between;
    }

    &__wrapper {
        &--left {
            justify-content: flex-start;
        }
    }

    &__sign {
        font-size: 30px;
        line-height: 150%;
        color: #242424;
    }
}

@media (max-width: 1000px) {

    .link-logout {
        margin-right: 20px;
    }

    .nav-side-bar {
        font-size: 18px;
        width: 100%;
    }
    .nav-bar-scroll {
        overflow: auto !important;
        padding-bottom: 10px !important;
    }
}
</style>
