<template>
    <div
        v-if="job"
        class="report"
    >
        <div class="report__section">
            <div class="report__box">
                <h2 class="report__title">
                    {{ isFalsePositive ? 'False Positive Report' : 'Scan Report' }}
                </h2>
            </div>
            <p class="report__completion-time">
                Completion Time: <span class="date">
                    {{ createDateView(job.completionTime) }}
                </span>
            </p>
            <h1
                v-if="job.fileName"
                class="report__file-name"
            >
                {{ job.fileName }}
            </h1>
            <div class="report__file-details-wrapper">
                <div class="section__app-id">
                    <p class="title">
                        App ID
                    </p>
                    <p
                        class="text"
                        :title="job.appId"
                    >
                        {{ job.appId }}
                    </p>
                </div>
                <div class="section__version">
                    <p class="title">
                        Version
                    </p>
                    <p
                        class="text"
                        :title="job.version"
                    >
                        {{ job.version }}
                    </p>
                </div>
                <div class="section__platform">
                    <p class="title">
                        Platform
                    </p>
                    <p
                        class="text"
                        :title="checkAndUpdatePlatformText(platform)"
                    >
                        {{ checkAndUpdatePlatformText(platform) }}
                    </p>
                </div>
            </div>
            <div
                id="floatingAncor"
                ref="floatingAncor"
            />
            <div
                v-if="!isFolderSettingsOpen"
                ref="folderWrapper"
                class="folder_menu mobile"
            >
                <FolderMenuMobile
                    v-if="allFoldersWithoutSelected.length"
                    :items="allFoldersWithoutSelected"
                    :integration-id="integrationId"
                    :is-mobile="true"
                    @item-click="switchFolder"
                    @show="(data) => dropDownToggle(data)"
                    @hide="(data) => dropDownToggle(data)"
                >
                    <template #button-icon>
                        <div v-if="selectedFolder[0].name !== 'False Positives'">
                            <FolderIcon
                                :svg-color="checkVulnerabilitySeverityNumber(selectedFolder[0].statistics, 'all') === 0 ? Empty : Default"
                                :svg-width="'24px'"
                                :svg-height="'21px'"
                                alt="folder icon"
                                class="folder-icon"
                            />
                        </div>
                        <div v-else>
                            <FalsePositiveIcon
                                :svg-color="checkVulnerabilitySeverityNumber(selectedFolder[0].statistics, 'all') === 0 ? Empty : Default"
                                :svg-width="'20px'"
                                :svg-height="'20px'"
                                alt="folder icon"
                                class="folder-icon"
                            />
                        </div>
                    </template>
                    <template #folder-name>
                        <div
                            class="chosen_folder_name"
                            :style="{ color: checkVulnerabilitySeverityNumber(selectedFolder[0].statistics, 'all') === 0 ? EmptyText : DefaultText}"
                        >
                            {{ folderName ? folderName : 'All Vulnerabilities' }}
                        </div>
                    </template>
                    <template #folder-statistics>
                        <div class="chosen_folder_count">
                            <div class="chosen_folder_count_high">
                                {{ checkVulnerabilitySeverityNumber(selectedFolder[0].statistics, 'high') }}
                            </div>
                            <span class="chosen_folder_count_slash">/</span>
                            <div class="chosen_folder_count_medium">
                                {{ checkVulnerabilitySeverityNumber(selectedFolder[0].statistics, 'medium') }}
                            </div>
                            <span class="chosen_folder_count_slash">/</span>
                            <div class="chosen_folder_count_low">
                                {{ checkVulnerabilitySeverityNumber(selectedFolder[0].statistics, 'low') }}
                            </div>
                        </div>
                    </template>
                </FolderMenuMobile>
                <div
                    v-if="integrationId || isJobOwner"
                    v-show="!isDropdownOpen"
                    id="folder"
                    class="folder_menu_settings"
                    @click.prevent="openFolderSettings()"
                >
                    <FolderSettingsIcon
                        :svg-color="Default"
                        :svg-width="'22px'"
                        :svg-height="'16px'"
                        alt="folder settings icon"
                        class="folder-settings-icon"
                    />
                </div>
            </div>

            <div
                v-if="!isFalsePositive && count > 0"
                class="report__button-wrapper"
            >
                <div style="position: relative;">
                    <Tooltip
                        v-if="isJobTypeTrial"
                        position="top"
                        :tooltip="tooltipTrialMessage"
                    >
                        <button
                            v-if="count > 0"
                            class="report__button-download"
                            :disabled="isJobTypeTrial"
                            @click.prevent="downloadJobPDFReport"
                        >
                            Download PDF Report
                        </button>
                    </Tooltip>
                    <div v-else>
                        <button
                            v-if="count > 0"
                            class="report__button-download"
                            :disabled="isJobTypeTrial"
                            @click.prevent="downloadJobPDFReport"
                        >
                            Download PDF Report
                        </button>
                    </div>
                    <div v-if="count > 0">
                        <p
                            v-if="!isFalsePositive"
                            class="report__download-link"
                        >
                            Report will download with preserving filters
                        </p>
                    </div>
                </div>

                <div
                    ref="buttonsAction"
                    class="report__buttons-action"
                    :class="defineNumberOfButtons()"
                >
                    <Tooltip
                        position="top"
                        :mobile-position="!isJobOwner ? 'top' : 'right'"
                        :tooltip="tooltipCopyText"
                    >
                        <b-nav-item @click="copyLinkSharedJobReportPage(job.id)">
                            <div class="button_action">
                                <img
                                    src="../../assets/images/myScans/copy-link.svg"
                                    alt="icon_action_share"
                                >
                            </div>
                        </b-nav-item>
                    </Tooltip>
                    <Tooltip
                        v-if="job.isOwner && !integrationId"
                        position="top"
                        :tooltip="tooltipShareText"
                    >
                        <button
                            id="share-report-btn"
                            class="button_action button-action-share-report"
                            @click="sharedReport(job.id)"
                        >
                            <img
                                v-if="job.shared"
                                src="../../assets/images/myScans/icon_action_show.svg"
                                alt="icon_action_show"
                            >
                            <img
                                v-else
                                src="../../assets/images/myScans/icon_action_hide.svg"
                                alt="icon_action_show"
                            >
                        </button>
                    </Tooltip>
                    <Tooltip
                        v-if="isJobOwner || (!isJobOwner && jobType === 'INTEGRATION')"
                        position="top"
                        :mobile-position="!isJobOwner ? 'top' : 'left'"
                        :tooltip="isUserHacker && isSampleReport ? tooltipHackerText: tooltipDeleteText"
                    >
                        <button
                            id="delete-report-btn"
                            v-b-modal.general-popupDeleteReport
                            class="button_action button-action-delete-report"
                            :disabled="isUserHacker && isSampleReport"
                        >
                            <img
                                src="../../assets/images/myScans/delete_icon.svg"
                                alt="delete icon"
                            >
                        </button>
                    </Tooltip>
                    <GeneralPopup
                        :title-text="''"
                        :popup-id="'DeleteReport'"
                        :hide-footer="true"
                        :hide-header="true"
                        :size="'md'"
                        @onHidden="closeModal"
                    >
                        <div class="report__delete-modal">
                            <img
                                src="../../assets/images/closeModal_new.svg"
                                alt="close modal"
                                class="modal-close"
                                @click="closeModal"
                            >
                            <div class="modal-title">
                                You`re about to permanently delete the report for {{ job.fileName }}
                            </div>
                            <div class="modal-description">
                                This action cannot be undone. To confirm, please type in the amount of vulnerabilities found in this report:
                            </div>
                            <div class="modal-number">
                                {{ allVulnerabilitiesCount }}
                            </div>
                        </div>
                        <form
                            class="report__modal-form"
                            @submit.prevent="handleDeleteModal"
                        >
                            <div class="report__form-input-wrapper">
                                <input
                                    id="DeleteReport"
                                    v-model.trim="deleteReportInput"
                                    class="input"
                                    type="text"
                                    name="DeleteReport"
                                    :class="{ errorInput: isDeleteReportInputInvalid && deleteReportInput.length > 0}"
                                >
                                <img
                                    v-if="deleteReportInput.length > 0"
                                    class="cross-icon"
                                    src="../../assets/images/closeModal_new.svg"
                                    alt="cross icon"
                                    @click="deleteReportInput = ''"
                                >
                                <label
                                    for="DeleteReport"
                                    class="label"
                                    :class="{ activeInput: deleteReportInput }"
                                >
                                    Amount of vulnerabilities
                                </label>
                                <span
                                    v-if="(allVulnerabilitiesCount !== parsedDeleteReportInput) && deleteReportInput.length > 0"
                                    id="DeleteReportError"
                                    class="report__error"
                                >
                                    <span class="report__error-text">Please enter a valid vulnerability count</span>
                                </span>
                                <span
                                    id="DeleteReportStatusError"
                                    class="report__error"
                                >
                                    <span
                                        v-if="jobError"
                                        class="report__error-text"
                                    >
                                        {{ jobError.deleteReportStatusError ? jobError.deleteReportStatusError : 'Something went wrong' }}
                                    </span>
                                    <span
                                        v-if="versionError"
                                        class="report__error-text"
                                    >
                                        {{ versionError.deleteReportStatusError ? versionError.deleteReportStatusError : 'Something went wrong' }}
                                    </span>
                                </span>
                            </div>
                        </form>

                        <div class="delete-report__btns">
                            <ConfigurablePopupButton
                                :button-text="'Cancel'"
                                :button-type="'cancel'"
                                :icon="false"
                                :disabled="false"
                                @buttonClick="closeModal"
                            />

                            <ConfigurablePopupButton
                                :button-text="'Delete'"
                                :button-type="'delete'"
                                :icon="true"
                                :disabled="allVulnerabilitiesCount !== parsedDeleteReportInput || !isDeleteReportInputValid"
                                @buttonClick="deleteReport"
                            />
                        </div>
                        <div
                            v-if="isShowSpinner"
                            class="spinner__vp-wrapper"
                        >
                            <div class="spinner__wp">
                                <Spinner />
                            </div>
                        </div>
                    </GeneralPopup>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FolderNameEnum, JobStatusEnum } from '../../utils/constants/common';
import { isVulnerabilityAmountPlural } from '../../utils/helper-functions';
import { mapGetters } from 'vuex';
import { checkAndUpdatePlatformText, createDateView, raiseError, hideErrors, isEmpty, transformVulnerabilitiesIds } from '../../services/functions';
import { setNotFoundJobData, checkVulnerabilitySeverityNumberFunction } from '../../utils/helper-functions';
import { successNotification } from '@/services/notificationService/notificationService';
import FolderMenuMobile from '../../components/scans/dropdown/FolderMenuMobile';
import FalsePositiveIcon from '../../assets/iconTemplates/FalsePositiveIcon';
import FolderIcon from '../../assets/iconTemplates/FolderIcon';
import FolderSettingsIcon from '../../assets/iconTemplates/FolderSettingsIcon';
import GeneralPopup from '../popups/GeneralPopup';
import ConfigurablePopupButton from '../popups/ConfigurablePopupButton';
import Tooltip from './components/Tooltip.vue';
import Spinner from '../../components/Spinner';

export default {
    name: 'ReportHeader',
    components: {
        FolderMenuMobile,
        FalsePositiveIcon,
        FolderIcon,
        FolderSettingsIcon,
        GeneralPopup,
        ConfigurablePopupButton,
        Tooltip,
        Spinner,
    },
    props: {
        job: Object,
        isFalsePositive: Boolean,
        IsReportLoadingNow: Boolean,
        changeDownloadingReportStatus: Function,
        loadSpinner: Function,
        count: Number,
        allVulnerabilitiesCount: Number,
        platform: String,
        isTrialIntegrationRestriction: Boolean,
        isJobOwner: Boolean,
        jobType: String,
        allFoldersWithoutSelected: {
            type: Array
        },
        selectedFolder: {
            type: Array
        },
        folderName: {
            type: String
        },
        integrationId: {
            type: String
        }
    },
    data() {
        return {
            // Color rules:
            DefaultText: 'rgba(112, 105, 105, 1)',
            Default: '#B2B9C3',
            EmptyText: 'rgba(178, 185, 195, 1)',
            Empty: '#F0F1F3',
            BGWhite: '#FFF',
            BGGrey: '#FAFBFC',
            // Data
            isCopied: false,
            isDropdownOpen: false,
            deleteReportInput: '',
            isErrorPresent: false,
            isShowSpinner: false,
            // Tooltips
            tooltipCopyText: 'Copy link',
            tooltipShareText: 'Share report — Anyone on the internet with this link can view',
            tooltipDeleteText: 'Delete report',
            tooltipHackerText: 'Users with the "Hacker" account type are barred from this action',
            tooltipTrialMessage:'Please contact our team to unlock the download option',
        };
    },
    computed: {
        jobIsFinished: {
            cache: false,
            get() {
                return this.job && this.job.status === JobStatusEnum.FINISHED;
            },

        },
        ...mapGetters({
            selectedVulnerabilitiesIds: 'jobs/selectedVulnerabilitiesIds',
            isFolderSettingsOpen: 'folders/isFolderSettingsOpen',
            jobError: 'jobs/errors',
            versionError: 'integrations/errors',
            userType: 'user/userType',
        }),

        isJobTypeTrial(){
            return this.isTrialIntegrationRestriction;
        },
        isDeleteReportInputValid() {
            return /^\d+$/.test(this.deleteReportInput);
        },
        isDeleteReportInputInvalid() {
            return !this.isDeleteReportInputValid || this.allVulnerabilitiesCount !== this.parsedDeleteReportInput;
        },
        parsedDeleteReportInput() {
            return this.isDeleteReportInputValid ? parseInt(this.deleteReportInput) : NaN;
        },
        isUserHacker() {
            return this.userType === 'hacker';
        },
        isSampleReport() {
            return this.jobType === 'VERSION_SAMPLE';
        },
    },
    watch: {
        versionError() {
            if (this.versionError) {
                return raiseError('DeleteReport', 'DeleteReportStatusError', 'branch-modal-form__input--error');
            }
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        createDateView,
        checkAndUpdatePlatformText,
        isVulnerabilityAmountPlural,
        successNotification,
        isEmpty,
        transformVulnerabilitiesIds,
        makeAnchor(str) {
            return encodeURIComponent(str).replace(/%20/g, '_');
        },
        makeLinkToAnchor(str) {
            return window.location.origin + '/scan/info' + '/' + this.makeAnchor(str);
        },
        handleScroll() {
            this.makeMobileFolderMenuFixed();
        },
        makeMobileFolderMenuFixed() {
            const folderWrapper = this.$refs.folderWrapper;
            const floatingAncor = this.$refs.floatingAncor;
            // fakeElement is needed to fill the heigh after folderWrapper becomes fixed
            // to prevent the page from jumping
            let fakeElement = document.getElementById('floatingAncor');
            // select folder_menu_settings class to change background color
            let folderSettings = document.getElementsByClassName('folder_menu_settings')[0];

            if (folderWrapper) {
                const rect = folderWrapper.getBoundingClientRect();
                const floatingAncorRect = floatingAncor.getBoundingClientRect();
                if (rect.top <= 10 && !folderWrapper.classList.contains('fixed')) {
                    fakeElement.style.height = '62px';
                    if (folderSettings) folderSettings.style.backgroundColor = this.BGWhite;
                    folderWrapper.classList.add('fixed');
                } else if (floatingAncorRect.top >= 10) {
                    fakeElement.style.height = '0px';
                    if (folderSettings) folderSettings.style.backgroundColor = this.BGGrey;
                    folderWrapper.classList.remove('fixed');
                }
            }
        },
        openJobFalsePositivePage() {
            const url = `/scan/info/${this.job.id}/falsepositive`;
            this.$router.push(url);
        },
        downloadJobPDFReport() {
            const folderName = this.$route.query.folderName || FolderNameEnum.ALL_VULNERABILITIES;

            let url;
            if (window.location.pathname === `/scan/info/${this.job.id}`) {
                url = `/private/ui/pdf_report/${this.job.id}`;
            } else {
                url = `/private/ui/pdf_report/integrations/${this.$route.params.integrationId}/branches/${this.$route.params.branchName}/versions/${this.$route.params.id}`;
            }
            this.changeDownloadingReportStatus(true);
            let preparedFilters = this.isEmpty(this.selectedVulnerabilitiesIds) ? [] : this.transformVulnerabilitiesIds(this.selectedVulnerabilitiesIds)[this.$route.params.id];
            this.axios
                .post(url, { filters: { vulnerabilityCategoriesIds: preparedFilters }, folderName })
                .then(
                    response => {
                        window.location.href = response.data.url;
                        this.changeDownloadingReportStatus(false);
                    },
                    error => {
                        this.changeDownloadingReportStatus(false);
                        console.log(error);
                        let job = setNotFoundJobData(error);
                        this.$store.commit('jobs/setCurrentJob', job);
                    },
                );
        },
        async copyToClipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
                this.tooltipCopyText = 'Link Copied!';
                setTimeout(() => {
                    this.tooltipCopyText = 'Copy link';
                }, 2000);
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        },

        copyLinkSharedJobReportPage() {
            const link = window.location.href;
            this.copyToClipboard(link);
        },
        async sharedReport(jobId) {
            this.changeDownloadingReportStatus(true);
            try {
                const folderName = this.$route.query.folderName || FolderNameEnum.ALL_VULNERABILITIES;
                await this.$store.dispatch('jobs/changeSharedReport', { id: jobId, shared: !this.job.shared, folderName });
                this.changeDownloadingReportStatus(false);
            } catch (err) {
                this.changeDownloadingReportStatus(false);
            }
        },
        dropDownToggle(isDropdownOpen) {
            this.isDropdownOpen = isDropdownOpen;
            this.$refs.folderWrapper.style.zIndex = this.isDropdownOpen ? '1053' : '49';
        },
        /**
         * switchFolder
         * is a method that takes a folder name as input and changes the current folder
         * @param {String} folderName - The name of the folder to switch to
         * @return {void}
         */
        switchFolder({ name: folderName }) {
            this.$router.push({ path: `${this.$route.path}`, query: { folderName: `${folderName}` } });
        },
        checkVulnerabilitySeverityNumber(statistics, severity) {
            return checkVulnerabilitySeverityNumberFunction(statistics, severity);
        },
        /**
         * Folder Settings
         */
        openFolderSettings() {
            this.$store.commit('folders/setFolderSettings', true);
            this.$emit('toggleFolderSettingsPopUp', true);
        },

        closeModal() {
            this.$bvModal.hide('general-popupDeleteReport');
            this.deleteReportInput = '';
        },
        handleInput(event) {
            this.$store.commit('jobs/clearListJobs');
        },
        async deleteReport() {
            this.isShowSpinner = true;
            this.$emit('loadSpinner', true);
            this.$store.commit('jobs/clearListJobs');
            if (this.$route.name === 'ScanInfo') {
                let res = await this.$store.dispatch('jobs/deleteJob', {
                    id: this.job.id,
                    deleteVerificationNumber: this.deleteReportInput,
                });
                if (res && res.status === 200) {
                    this.$store.commit('user/setShowDeleteReportNotification', true);
                }
                this.closeModal();
                this.isShowSpinner = false;
                this.$emit('loadSpinner', false);
                await this.$router.push('/scans');
            } else {
                let res = await this.$store.dispatch('integrations/deleteVersion', {
                    id: this.integrationId,
                    branchName: this.$route.params.branchName,
                    versionId: this.$route.params.id,
                    deleteVerificationNumber: this.deleteReportInput,
                    page: 1,
                });
                if (res && res.status === 200) {
                    this.$store.commit('user/setShowDeleteReportNotification', true);
                }
                this.closeModal();
                this.isShowSpinner = false;
                this.$emit('loadSpinner', false);
                await this.$router.push(`/integration/${this.integrationId}/branch/${this.$route.params.branchName}`);
            }
        },
        defineNumberOfButtons() {
            this.$nextTick(() => {
                let buttonsAction = this.$refs?.buttonsAction;
                let buttonsWithTooltips = buttonsAction.querySelectorAll('.tooltip-container');

                buttonsWithTooltips.length === 1
                    ? buttonsAction.classList.add('expand-one-button')
                    : buttonsWithTooltips.length === 2
                        ? buttonsAction.classList.add('expand-two-buttons')
                        : '';
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../assets/css/fonts';
@import '../../assets/css/variables';

@mixin vulnDot {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: 0;
}

@mixin LongTextShorten {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.spinner__vp-wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 3000;
    background-color: rgba(255, 255, 255, 0.7);
}

.spinner__wp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.folder-icon {
    display: flex;
}

.chosen_folder {
    &_name {
        font-size: 17px;
        line-height: 26px;
        width: 140px;
        @include LongTextShorten;
    }

    &_count {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        &_high,
        &_medium,
        &_low {
            position: relative;
            @include Aspekta-font(700, 14px, 21px, #B2B9C3);
        }

        &_high::after {
            @include vulnDot;
            background-color: #FB8D62;
        }
        &_medium::after {
            @include vulnDot;
            background-color: #FDD365;
        }
        &_low::after {
            @include vulnDot;
            background-color: #61D4B3;
        }

        &_slash {
            @include Aspekta-font(400, 22px, 21px, #F0F1F3);
            margin: 0 8px;
        }

    }

}

.folder_menu {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .folder_menu_settings {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
        gap: 70px;
        cursor: pointer;
        width: 62px;
        height: 62px;
        background: #FAFBFC;
        border: 1px solid #FAFBFC;
        border-radius: 8px;
        transition: 0.3s;

        &:hover {
            background: #FFFFFF;
            border: 1px solid #F0F1F3;
            box-shadow: 0px 12px 16px rgba(178, 185, 196, 0.12);
        }
    }
}

.fixed {
    position: fixed;
    padding: 10px 10px 10px 10px;
    top: 0;
    left: 0;
    background: #FAFBFC;
    z-index: 1049;
}

.mobile {
    display: none;
}

#floatingAncor {
    display: none;
}

.report {
    margin-top: 40px;

    &__box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title {
        @include Aspekta-font(650, 22px, 31px, #021631);
        margin: 0;
    }

    &__completion-time {
         @include Aspekta-font(400, 14px, 20px, #949DB8);
         letter-spacing: 0.02em;
         margin: 20px 0 0;

         .date {
            font-weight: 500;
            letter-spacing: 0.03em;
         }
    }

    &__switch-link {
        user-select: none;
        margin: 0 0 0 48px;
        color: #1FA8C7;

        &:hover {
            text-decoration: underline;
        }
    }

    &__file-name {
        @include Aspekta-font(700, 36px, 54px, #021631);
        margin-bottom: 20px;
        width: 100%;
        word-break: break-all;
    }

    &__file-details-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 3px 0 0 0;
        justify-content: space-between;

        .section {
            &__app-id {
                width: 40%;
            }

            &__version {
                width: 30%;
            }

            &__platform {
                width: 30%
            }


            &__app-id, &__version, &__platform {
                display: flex;
                flex-direction: column;
            }

            &__app-id, &__version {
                .title {
                    @include Aspekta-font(500, 14px, 20px, #949DB8);
                    margin-bottom: 4px;
                    white-space: nowrap;
                }

                .text {
                    @include Aspekta-font(400, 14px, 20px, #5C6A7A);
                    @include LongTextShorten;
                    margin-bottom: 0px;
                }
            }

            &__platform {
                .title {
                    @include Aspekta-font(500, 14px, 20px, #949DB8);
                    margin-bottom: 4px;
                }

                .text {
                    @include Aspekta-font(400, 14px, 20px, #5C6A7A);
                    margin-bottom: 0px;
                }
            }
        }

    }

    &__version {
        color: #706969;
    }

    &__button-wrapper {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        gap: 20px;

        .report__buttons-action {
            display: flex;
            flex-direction: row;
            gap: 20px;
            height: 53px;
        }
    }

    &__button-download {
        @include Aspekta-font(600, 17px, 24px, $main-blue-color);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: $background-blue-color;
        border: 1px solid $background-blue-color;
        margin-right: 20px;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px 40px;
        outline: none;
        height: 53px;
        transition: 0.3s;

        &:not(:disabled):hover {
            background: $main-blue-color;
            border: 1px solid $main-blue-color;
            box-shadow: 0px 12px 16px rgba(51, 128, 244, 0.12);
            color: $background-blue-color;
        }
        &:disabled{
            background-color: $background-disabled;
            color: $ghost-text;
            border: none;
            box-shadow: none;
        }
    }

    &__button-link {
        position: relative;
        margin-right: 30px;
        display: flex;
        align-items: center;
        background: #FAFBFC;
        border: 1px solid #F0F0F0;
        box-sizing: border-box;
        border-radius: 8px;

        &:hover {
            opacity: 0.5;
            cursor: pointer;
        }
    }

    &__button-link-message {
        position: absolute;
        top: -30px;
        right: -19px;
        width: 90px;
        text-align: center;
        height: 20px;
        background-color: black;
        border-radius: 3px;
        color: white;
        font-family: Cabin, serif;
        font-size: 14px;

        &::before {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, 0%);
            border: 6px solid transparent;
            border-top: 3px solid black;
        }
    }

    &__button-show {
        @extend .report__button-link;
    }

    &__download-link {
        @include Aspekta-font(400, 14px, 20px, #949DB8);
        margin: 8px 0 0;
        position: absolute;
        width: 300px;
    }

    // Delete Popup section
    &__delete-modal {
        position: relative;

        .modal-close {
            position: absolute;
            top: 0px;
            right: 0px;
            cursor: pointer;
        }

        img.modal-close {
            cursor: pointer;
        }

        .modal-title {
            @include Aspekta-font(600, 18px, 26px, #021631);
            padding-top: 20px;
            // word-break: break-all;
            word-wrap: break-word; // break long words onto the next line
            // overflow-wrap: break-word; // break long words onto the next line
            display: -webkit-box;
            -webkit-line-clamp: 4; // limit text to 4 lines
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-align: center;
        }

        .modal-description {
            @include Aspekta-font(400, 14px, 20px, #706969);
            letter-spacing: 0.02em;
            margin-top: 20px;
        }

        .modal-number {
            @include Aspekta-font(700, 36px, 54px, #021631);
            letter-spacing: 0.04em;
            text-align: center;
            margin-bottom: 20px;

        }
    }

    &__form-input-wrapper {
        position: relative;

        .cross-icon {
            position: absolute;
            top: 20px;
            right: 30px;
            cursor: pointer;
            width: 24px;
            height: 24px;
        }

        input {
            @include Aspekta-font(450, 18px, 150%, #021631);
            letter-spacing: 0.02em;
            padding: 18px 52px 18px 24px;

            &:focus {
                border: 1px solid $main-blue-color;
                outline: none;

                + .label {
                    transform: translate(0px, 0px);
                }
            }

            &.errorInput {
                border: 1px solid $error-color;
            }
        }


        input:focus ~ label,
        .activeInput {
            top: -18px;
            left: 10px;
            font-size: 12px;
        }

        label {
            @include Aspekta-font(400, 18px, 150%, $default-text-color);
            letter-spacing: 0.02em;
            top: 18px;
            left: 24px;
        }

    }

    &__error {
        width: 100%;
        max-width: 380px;
    }

    &__error-cross {
        position: absolute;
        top: -25px;
        right: 20px;
        cursor: pointer;
    }

    &__error-text {
        @include Aspekta-font(400, 12px, 18px, $error-color);
        position: absolute;
        left: 0px;
        bottom: 10px;
    }
}

.delete-report__btns {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
}

#DeleteReportStatusError {
    display: none;
}




.button_action {
    width: 53px;
    height: 53px;
    background: #F8FAFC;
    border: 1px solid #F8FAFC;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    img {
        width: 28px;
        height: 28px;
    }

    &:not(:disabled):hover{
        background: #FFFFFF;
        border: 1px solid #E3E6ED;
        box-shadow: 0px 12px 16px rgba(178, 185, 196, 0.12);
    }

}


@media (max-width: 834px) {
    .fixed {
        padding: 10px;
    }

    .report {
        &__file-details-wrapper {
            .section {
                &__app-id, &__version, &__platform {
                    .title, .text {
                        font-size: 14px;
                    }
                }
            }
        }

        &__button-wrapper {
            justify-content: space-between;
        }
    }
}

@media (max-width: 768px) {
    .report {

        &__button-wrapper {
            flex-direction: column;
            gap: 36px;

            .report__buttons-action {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 36px;

                .nav-item {
                    width: 100%;
                }

                &.expand-two-buttons {
                    grid-template-columns: 1fr 1fr;
                }

                &.expand-one-button {
                    grid-template-columns: 1fr;
                }

                .button_action {
                    width: 100%;
                }
            }
        }
    }

    .delete-report__btns {
        flex-direction: column-reverse;
    }

}

@media (max-width: 576px) {
    .mobile {
        display: flex;
        width: 100%;
    }
    .fixed {
        padding: 10px 15px;
    }

    .report {
        &__file-details-wrapper {
            margin: 3px 0 20px 0;
        }
    }

    #floatingAncor {
        display: block;
    }
}

</style>
