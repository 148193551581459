<template>
    <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="35"
            cy="35"
            r="35"
            :fill="fillColor"
        />
        <path
            d="M35.0001 19.9583C36.8622 19.9577 38.6755 20.5541 40.1738 21.6599C41.6721 22.7656 42.7765 24.3226 43.3249 26.1022C43.8733 27.8817 43.8368 29.7902 43.2207 31.5475C42.6047 33.3049 41.4416 34.8184 39.9021 35.8661C42.5742 36.8461 44.8918 38.6035 46.5567 40.912C48.2215 43.2205 49.1574 45.9744 49.2437 48.8193C49.2518 48.9772 49.2283 49.1352 49.1745 49.2838C49.1207 49.4325 49.0378 49.569 48.9305 49.6852C48.8233 49.8014 48.694 49.895 48.5501 49.9606C48.4062 50.0261 48.2507 50.0623 48.0926 50.0669C47.9346 50.0716 47.7772 50.0446 47.6297 49.9876C47.4822 49.9306 47.3476 49.8447 47.2337 49.735C47.1199 49.6253 47.0291 49.4939 46.9667 49.3487C46.9043 49.2034 46.8715 49.0471 46.8703 48.889C46.776 45.8038 45.4842 42.8765 43.2685 40.7275C41.0528 38.5786 38.0875 37.3767 35.0009 37.3767C31.9142 37.3767 28.9489 38.5786 26.7332 40.7275C24.5175 42.8765 23.2257 45.8038 23.1314 48.889C23.1219 49.2039 22.9878 49.5022 22.7584 49.7182C22.529 49.9343 22.2232 50.0503 21.9083 50.0409C21.5933 50.0314 21.295 49.8972 21.079 49.6679C20.863 49.4385 20.7469 49.1327 20.7564 48.8177C20.8431 45.9731 21.7791 43.2195 23.4439 40.9114C25.1087 38.6032 27.4262 36.846 30.0981 35.8661C28.5586 34.8184 27.3954 33.3049 26.7794 31.5475C26.1634 29.7902 26.1268 27.8817 26.6752 26.1022C27.2236 24.3226 28.328 22.7656 29.8263 21.6599C31.3246 20.5541 33.1379 19.9577 35.0001 19.9583ZM28.6667 28.6666C28.6667 30.3464 29.334 31.9573 30.5217 33.145C31.7094 34.3327 33.3204 35 35.0001 35C36.6798 35 38.2907 34.3327 39.4784 33.145C40.6661 31.9573 41.3334 30.3464 41.3334 28.6666C41.3334 26.9869 40.6661 25.376 39.4784 24.1883C38.2907 23.0006 36.6798 22.3333 35.0001 22.3333C33.3204 22.3333 31.7094 23.0006 30.5217 24.1883C29.334 25.376 28.6667 26.9869 28.6667 28.6666Z"
            fill="white"
            stroke="white"
        />
    </svg>
</template>
<script>
export default {
    props: {
        state: {
            type: String,
            require: true,
        },
    },
    computed: {
        fillColor() {
            if(this.state === 'active') {
                return '#FF7A08';
            }
            else if(this.state === 'passed') {
                return '#FFD7B5';
            }

            return '#FF7A08';
            
        },
    },
};
</script>
