<template>
    <div
        v-if="!(isTrialRestriction && vulnerability['vulnerability-category.severity'] === 'high' && vulnerability['vulnerability-category.severity'] === 'medium')"
        :id="vulnerability.id"
        class="vulnerability"
        :class="{ vulnerability__active: showReadMore, moveto__folder: isMovingToFolder }"
        @mouseenter="handleMouseEnter(vulnerability.id)"
        @mouseleave="handleMouseLeave"
    >
        <div
            class="vulnerability__description"
        >
            <div
                v-if="compare && category"
                class="vulnerability__header"
            >
                <Tooltip
                    position="top"
                    mobile-position="right"
                    :tooltip="compare === 'fixed' ? 'Resolved' : 'New'"
                >
                    <img
                        v-if="compare==='created'"
                        src="../../assets/images/vulnerabilities/Bug.svg"
                        class="vulnerability__icon"
                    >
                    <img
                        v-else-if="compare==='fixed'"
                        src="../../assets/images/vulnerabilities/check-circle.svg"
                        class="vulnerability__icon"
                    >
                </Tooltip>
                <div class="vulnerability__header__version">
                    {{ version }}
                </div>
                <div class="vulnerability__header__category">
                    {{ category }}
                </div>
            </div>
            <div
                v-if="!compare && !category"
                class="vulnerability__description-title"
            >
                <div
                    class="dot"
                    :class="colorOfVulnerability(vulnerability['vulnerability-category.severity'])"
                />
                <div class="vulnerability__title">
                    {{ vulnerability['vulnerability-category.descriptionTitle'] }}
                </div>
            </div>
        </div>
        <div
            v-show="showButtons"
            class="vulnerability__buttons"
        >
            <div
                ref="buttonsWrapper"
                class="vulnerability__buttons-wrapper"
                :class="[isDropdownOpen && 'open-dropdown', { 'vulnerability__buttons-wrapper_special': comparePage || !isOwner && !isMember }]"
            >
                <div
                    v-if="!comparePage && (isOwner || isMember)"
                    class="dropdown-with-folders"
                    @mouseenter="handleFolderMouseEnter"
                    @mouseleave="handleFolderMouseLeave"
                >
                    <div
                        v-if="popup.isShow && popup.type === 'folder'"
                        class="folder-popup move-to-folder"
                    >
                        <template>
                            <img
                                :src="moveToIcon"
                                class="folder-popup__icon move"
                            >
                            <div class="folder-popup__folder-title">
                                Vulnerability moved to&nbsp;<span
                                    class="folder-popup__folder-name"
                                    :title="folderNamePopUp"
                                >
                                    “{{ folderNamePopUp }}”
                                </span>
                            </div>
                            <button
                                class="folder-popup__button"
                                @click.prevent="undoMoveTo"
                            >
                                <img
                                    :src="undoIcon"
                                    class="folder-popup__icon"
                                    alt=""
                                >
                                Undo
                            </button>
                        </template>
                    </div>
                    <grey-drop-down
                        v-if="folders.length"
                        :items="setFolders"
                        :component-id="vulnerability.id"
                        :integration-id="integrationId"
                        is-interactive-icons
                        is-mobile
                        @item-click="prepareMoveToFolder"
                        @show="(data) => dropDownToggle(data)"
                        @hide="(data) => dropDownToggle(data)"
                        @create-folder="createFolder"
                    >
                        <template v-slot:button-icon>
                            <img
                                :src="currentUrl"
                                class="dropdown__icon"
                            >
                        </template>
                        <template v-slot:button-text>
                            {{ !isMouseOnDropDown ? dropDownButtonText : 'Move To' }}
                        </template>
                    </grey-drop-down>
                </div>
                <Tooltip
                    :ref="`copyTooltip${vulnerability.id}`"
                    position="top"
                    mobile-position="left"
                    :tooltip="'Copy link'"
                >
                    <b-nav-item @click.prevent="copyLinkSharedJobReportPage(vulnerability.id)">
                        <div
                            v-if="popup.isShow && popup.type === 'copy'"
                            class="folder-popup copy-link"
                        >
                            <template>
                                <img
                                    :src="copiedIcon"
                                    class="folder-popup__icon copy"
                                >
                                Link copied to clipboard
                            </template>
                        </div>
                        <div class="button_action button-action-copy-vulnerability-link">
                            <img
                                id="copy-link-btn"
                                :ref="`copyBtn${vulnerability.id}`"
                                src="../../assets/images/myScans/copy-link.svg"
                                alt="icon_action_share"
                                @mouseenter="onMouseCopyIcon = true"
                                @mouseleave="onMouseCopyIcon = false"
                            >
                        </div>
                    </b-nav-item>
                </Tooltip>
                <Tooltip
                    :ref="`downloadTooltip${vulnerability.id}`"
                    position="top"
                    mobile-position="left"
                    :tooltip="tooltipMessage"
                >
                    <b-nav-item
                        :disabled="isTrialRestriction"
                        @click.prevent="downloadVulnerabilityImage()"
                    >
                        <div
                            v-if="popup.isShow && popup.type === 'download'"
                            class="folder-popup download-image"
                        >
                            <template>
                                <img
                                    :src="downloadImgIcon"
                                    class="folder-popup__icon download"
                                >
                                Your download will begin shortly
                            </template>
                        </div>
                        <div class="button_action button-action-download-vulnerability-image">
                            <DownloadImageIcon
                                id="download-image-btn"
                                :ref="`downloadBtn${vulnerability.id}`"
                                :disabled="isTrialRestriction"
                                alt="icon_action_download"
                                @mouseenter="onMouseDownloadIcon = true"
                                @mouseleave="onMouseDownloadIcon = false"
                            />
                        </div>
                    </b-nav-item>
                </Tooltip>
            </div>
            <div class="vulnerability__show-code">
                <button
                    class="vulnerability__show-code-btn"
                    :class="{ vulnerability__showvulnerabilitiesdisable: vulnerability.blocks.length < 2}"
                    :aria-expanded="showReadMore"
                    :disabled="vulnerability.blocks.length < 2"
                    @click="changeShowReadMoreFlag()"
                >
                    <img
                        src="../../assets/images/folders/expand-arrow.svg"
                        class="vulnerability__show-code-btn-arrow"
                        alt=""
                    >
                    <span class="vulnerability__show-code-btn-text">
                        {{ showReadMore ? 'Collapse' : 'Expand' }}
                    </span>
                </button>
            </div>
        </div>
        <div
            v-for="(block, index) in currentVulnerability.blocks"
            :key="index"
            :class="addReadMoreBlocks(index)"
        >
            <div class="vulnerability__code-wrapper-child">
                <div class="vulnerability__info">
                    <div class="vulnerability_level">
                        Found in file
                        <b class="vulnerability__file-name">{{ block.file }}</b>
                    </div>
                </div>
                <div class="vulnerability__code">
                    <div
                        v-if="block.lines.length > 0"
                        class="vulnerability__line"
                        :class="colorOfVulnerabilityForLine(vulnerability['vulnerability-category.severity'] || vulnerability['severity'])"
                    />
                    <table
                        class="block vulnerability__code-wrapper-child"
                    >
                        <tbody :class="{ vulnerability__delete: isFalsePositiveToggle}">
                            <tr
                                v-for="(line, lineIndex) in block.lines"
                                :key="lineIndex"
                                :class="isHighlightedLine(line , vulnerability['format'])"
                            >
                                <td class="vulnerability__code-line-number">
                                    {{ line.line_number }}
                                </td>
                                <td>
                <pre v-highlightjs="minAllowedSpacesBeforeCode+line.content.substring(block.minSpaces)" class="hljs"><code
                    :class="getHighlightLanguageBySourceType(block.source_type)"></code></pre>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br>
        </div>
    </div>
</template>

<script>

import { setNotFoundJobData } from '../../utils/helper-functions';
import undoIcon from '../../assets/images/folders/undo.svg';
import downloadImgIcon from '../../assets/images/folders/download-img.svg';
import moveToIcon from '../../assets/images/folders/move_to_icon.svg';
import copiedIcon from '../../assets/images/folders/resolved.svg';
import { FolderNameEnum } from '../../utils/constants/common';
import { mapGetters } from 'vuex';
import greyDropDown from './dropdown/greyDropDown.vue';
import Tooltip from '../report/components/Tooltip.vue';
import DownloadImageIcon from '../../assets/iconTemplates/DownloadImageIcon.vue';

export default {
    components: {
        greyDropDown,
        Tooltip,
        DownloadImageIcon,
    },
    props: {
        vulnerability: {
            type: Object,
            default: null,
        },
        isTrialRestriction: {
            type: Boolean,
            default: false,
        },
        allowFalsePositive: {
            type: Boolean,
            default: false,
        },
        showButtons: {
            type: Boolean,
            default: true,
        },
        loadPage: {
            type: Function,
            default: () => ({}),
        },
        modal: {
            type: Boolean,
            default: false,
        },
        currentId: {
            type: String,
            default: '',
        },
        integrationId: {
            type: String,
            default: '',
        },
        isMember: {
            type: Boolean,
            default: false,
        },
        isOwner: {
            type: Boolean,
            default: false,
        },
        compare: {
            type: String,
            default: null,
        },
        comparePage: {
            type: Boolean ,
            default: null,
        },
        category: {
            type: String,
            default: null,
        },
        version: {
            type: String,
            default: null,
        },
        currentJob: {
            type: Object,
            default: () => ({}),
        },
        screenWidth : {
            type : Number,
            default : 0,
        },
        folders: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            showReadMore: false,
            isFalsePositiveToggle: false,
            timeout: null,
            isCopied: false,
            isImageDownload: false,
            showHintIcon: false,
            branchName: null,
            resetTimeout: null,
            popup: {
                isShow: false,
                type: '',
            },
            folderNamePopUp: '',
            isMovingToFolder: false,
            isButtonDisable: false,
            isDropdownOpen: false,
            onMouseCopyIcon: false,
            onMouseDownloadIcon: false,
            undoIcon,
            downloadImgIcon,
            moveToIcon,
            copiedIcon,
            tooltipTrialMessage:'Please contact our team to unlock the download option',
            tooltipDownloadMessage:'Download image',
            dropDownButtonText: '',
            isMouseOnDropDown: false,
            currentVulnerability: {},
            minAllowedSpacesBeforeCode: '   ', 
        };
    },
    computed: {
        ...mapGetters({
            selectedVulnerabilityFilters: 'jobs/selectedVulnerabilityFilters',
        }),
        tooltipMessage(){
            return this.isTrialRestriction?this.tooltipTrialMessage
                : this.tooltipDownloadMessage;
        },
        setFolders() {
            return this.folders.filter(folder => folder.name !== this.vulnerability.folderName);
        },  
        currentUrl() {
            return this.isMouseOnDropDown ? require('../../assets/images/folders/move-to-icon_new.svg')
                : require('../../assets/images/folders/folder-icon-grey.svg');
        },
    },
    beforeMount() {
        this.removeLeftSpacesInBlock();
        this.branchName = this.$route.params.branchName;
        if (this.$route.query.vId === this.vulnerability.id && this.vulnerability.blocks.length > 1) {
            this.changeShowReadMoreFlag();
        }
    },
    mounted() {
        this.dropDownButtonText = this.vulnerability.folderName;
        this.unhighlightAllElements();
        this.$emit('vulnerabilityMounted', this.vulnerability.id);
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    updated() {
        this.dropDownButtonText = this.vulnerability.folderName;
        this.unhighlightAllElements();
    },
    methods: {
        removeLeftSpacesInBlock() {
            this.currentVulnerability = this.vulnerability;
            this.currentVulnerability.blocks.forEach(block => {
                const minSpaces = block.lines.reduce((min,line) => {
                    const leadingSpaces = line.content.search(/\S/);
                    const currentValue = leadingSpaces !== -1 ? leadingSpaces : min;
                    return Math.min(min,currentValue);
                }, 100);
                block.minSpaces = minSpaces;
            });
        },
        handleFolderMouseEnter() {
            if (!this.isDropdownOpen) {
                this.isMouseOnDropDown = true;
            }
        },
        handleFolderMouseLeave() {
            this.isMouseOnDropDown = false;     
        },

        handleMouseEnter() {
            this.$emit('mouse-enter', this.vulnerability.categoryId);
        },
        handleMouseLeave() {
            this.$emit('is-mouse-leave', true);
        },
        /**
         * This method is called when the user scrolls the page. It hides the hint labels.
         */
        handleScroll() {
            this.hideLabel();
        },
        hideLabel() {
            this.showHintIcon = false;
            this.showHintCopyLabel = false;
            this.showHintDownloadImageLabel = false;
            // Get all elements with id "copy-link-btn" and set their opacity to 1.
            document.querySelectorAll('#copy-link-btn').forEach((el) => {
                el.style.opacity = 1;
            });
            // Get all elements with id "download-image-btn" and set their opacity to 1.
            document.querySelectorAll('#download-image-btn').forEach((el) => {
                el.style.opacity = 1;
            });
        },
        colorOfVulnerability(severity) {
            if (severity === 'low') {
                return 'green';
            } else if (severity === 'medium') {
                return 'yellow';
            } else if (severity === 'high') {
                return 'red';
            }
        },
        colorOfVulnerabilityForLine(severity) {
            if (severity === 'low') {
                return 'line-green';
            } else if (severity === 'medium') {
                return 'line-yellow';
            } else if (severity === 'high') {
                return 'line-red';
            }
        },
        prepareMoveToFolder({ name: folderName }) {
            if (this.resetTimeout) {
                clearTimeout(this.resetTimeout);
            }
            this.popup.isShow = true;
            this.popup.type = 'folder';
            this.isDropdownOpen = false;
            this.folderNamePopUp = folderName;
            this.resetTimeout = setTimeout(async () => {
                this.popup.isShow = false;
                this.popup.type = '';
                await this.moveVulnerabilityToFolder(folderName);
            }, 3000);
        },
        undoMoveTo() {
            clearTimeout(this.resetTimeout);
            this.popup.isShow = false;
            this.popup.type = '';
        },
        async moveVulnerabilityToFolder(folderName) {
            this.isMovingToFolder = true;
            this.$store.commit('folders/setMovingToFolder', true);
            // set prevent page load duplication when moving to folder
            this.$store.commit('jobs/setPreventPageLoadDuplication', true);
            // Set changing folders
            this.$store.commit('folders/setMovedToFolder', folderName);
            this.$store.commit('folders/setMovedFromFolder', this.$route.query?.folderName || FolderNameEnum.ALL_VULNERABILITIES);

            this.$emit('animated', true);
            let response;
            if (this.integrationId) {
                response = await this.$store.dispatch('folders/moveVulnerabilityToFolder', {
                    holderId: this.integrationId,
                    integrationId: this.integrationId,
                    vulnerabilityId: this.vulnerability.id,
                    versionId: this.currentId,
                    folderName,
                });
                if (response?.status === 200) {
                    // set deductNumber of vulnerabilities
                    this.$store.commit('filters/setDeductNumber', response.data.numberOfMovingVulnerabilities);
                    // Update the filters after moving the vulnerability
                    await this.updateFiltersAfterMoving();
                    await this.loadPage();

                    await this.$store.dispatch('integrations/loadIntegrationVersion', {
                        integrationId: this.integrationId,
                        versionId: this.$route.params.id,
                        branchName: this.$route.params.branchName,
                        folderName: this.$route.query?.folderName || FolderNameEnum.ALL_VULNERABILITIES,
                    });

                    // Load folders
                    await this.$store.dispatch('integrations/getFolders', {
                        integrationId: this.integrationId,
                        versionId: this.currentId,
                    });
                    // Clear changing folders
                    this.$store.commit('folders/setMovedToFolder', null);
                    this.$store.commit('folders/setMovedFromFolder', null);
                }
            } else if (this.currentJob?.id) {
                response = await this.$store.dispatch('folders/moveVulnerabilityToFolder', {
                    holderId: this.currentJob.id,
                    jobId: this.currentJob.id,
                    vulnerabilityId: this.vulnerability.id,
                    folderName,
                });

                if (response?.status === 200) {
                    // set deductNumber of vulnerabilities
                    this.$store.commit('filters/setDeductNumber', response.data.numberOfMovingVulnerabilities);
                    // Update the filters after moving the vulnerability
                    await this.updateFiltersAfterMoving();
                    await this.loadPage();

                    await this.$store.dispatch('jobs/fetchJobById', { jobId: this.$route.params.id, folderName: this.$route.query?.folderName || FolderNameEnum.ALL_VULNERABILITIES });

                    // Load folders
                    await this.$store.dispatch('jobs/getFolders', { jobId: this.currentJob.id });
                    // Clear changing folders
                    this.$store.commit('folders/setMovedToFolder', null);
                    this.$store.commit('folders/setMovedFromFolder', null);
                }
            }

            this.$store.commit('folders/setMovingToFolder', false);
            this.isMovingToFolder = false;
        },
        async updateFiltersAfterMoving() {
            const payload = {
                id: this.$route.params.id,
                severity: this.vulnerability['vulnerability-category.severity'],
                categoryId: this.vulnerability.categoryId,
            };
            await this.$store.dispatch('filters/updateFilterAfterMovingVulnerability', payload);
        },
        copyLinkSharedJobReportPage(id) {
            if (this.resetTimeout) {
                clearTimeout(this.resetTimeout);
            }
            this.popup.isShow = true;
            this.popup.type = 'copy';
            this.$refs[`copyTooltip${id}`].hideTooltip(); // Hide tooltip while isShow copy popup
            let link = `${window.location.origin}${window.location.pathname}`;
            if (this.$route.name === 'Versions compare') {
                link += `?old=${this.$route.query.old}&new=${this.$route.query.new}&vId=${id}`;
            } else {
                link += `?vId=${id}`;
            }
            if (this.$route.query.folderName && this.$route.query.folderName !== FolderNameEnum.ALL_VULNERABILITIES) {
                link += `&folderName=${this.$route.query.folderName}`;
            }
            const el = document.createElement('textarea');
            el.value = link;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.resetTimeout = setTimeout(() => {
                this.popup.isShow = false;
                this.popup.type = '';
                if (this.onMouseCopyIcon && window.innerWidth >= 900) this.$refs[`copyTooltip${id}`].showTooltip(); // Show tooltip after isShow copy popup only if mouse is on copy icon and is a desktop device
            }, 3000);
        },
        downloadVulnerabilityImage() {
            if (this.resetTimeout) {
                clearTimeout(this.resetTimeout);
            }
            this.isButtonDisable = true;
            this.popup.isShow = true;
            this.popup.type = 'download';
            const { jobId, categoryId, id, format } = this.vulnerability;

            this.$refs[`downloadTooltip${id}`].hideTooltip();
            let url;
            if (this.$route.name === 'ScanInfo') {
                url = `/private/ui/vulnerability_image/${jobId}/report?categoryId=${categoryId}&vulnerabilityId=${id}`;
            } else if (this.$route.name === 'Versions compare') {
                url = `/private/ui/vulnerability_image/integrations/${this.$route.params.integrationId}/branches/${this.$route.params.branchName}/versions/${format === 'fixed' ? this.$route.query.old : this.$route.query.new}/report?categoryId=${categoryId}&vulnerabilityId=${id}`;
            } else {
                url = `/private/ui/vulnerability_image/integrations/${this.$route.params.integrationId}/branches/${this.$route.params.branchName}/versions/${this.$route.params.id}/report?categoryId=${categoryId}&vulnerabilityId=${id}`;
            }
            this.axios
                .post(url, { filters: this.selectedVulnerabilityFilters, format: format })
                .then(
                    response => {
                        window.location.href = response.data.url;
                        this.isButtonDisable = false;
                        this.popup.isShow = false;
                        this.popup.type = '';
                        if (this.onMouseDownloadIcon && window.innerWidth >= 900) this.$refs[`downloadTooltip${id}`].showTooltip(); // Show tooltip after isShow download popup only if mouse is on download icon and is a desktop device
                    },
                    error => {
                        this.isButtonDisable = false;
                        this.popup.isShow = false;
                        this.popup.type = '';
                        if (this.onMouseDownloadIcon && window.innerWidth >= 900) this.$refs[`downloadTooltip${id}`].showTooltip(); // Show tooltip after isShow download popup error and only if mouse is on download icon and is a desktop device
                        console.log(error);
                        let job = setNotFoundJobData(error);
                        this.$store.commit('jobs/setCurrentJob', job);
                    },
                );

        },
        dropDownToggle(isDropdownOpen) {
            this.isDropdownOpen = isDropdownOpen;
            if (isDropdownOpen) {
                this.$refs.buttonsWrapper.style.zIndex = window.innerWidth <= 576 ? '1053' : 'unset';
            } else {
                this.$refs.buttonsWrapper.style.zIndex = 'unset';
            }
        },
        changeShowReadMoreFlag() {
            this.showReadMore = !this.showReadMore;
        },
        addReadMoreBlocks(index) {
            return this.showButtons && !this.showReadMore && index > 0
                ? 'readmore'
                : '';
        },
        isHighlightedLine(line, format) {
            if (format === 'fixed' && line.is_matched){
                return format === 'fixed' && 'highlight_green';
            } else if (format === 'created' && line.is_matched) {
                return format === 'created' && 'highlight_red';
            } else {
                return line.is_matched ? 'highlight' : '';
            }
        },
        getHighlightLanguageBySourceType(sourceType) {
            // TODO may be need to add new languages
            return ['xml', 'java', 'swift'].find(lang => lang === sourceType) || 'plaintext';
        },
        createFolder() {
            this.$emit('create-new-folder', { vulnerabilityTitle: this.vulnerability['vulnerability-category.descriptionTitle'], vulnerabilityId: this.vulnerability.id });
        },
        // unhighlight <pre code> element to highligt on the next step
        unhighlightAllElements() {
            const preElements = document.querySelectorAll('pre code');
            preElements.forEach((element) => {
                if (element.dataset.highlighted) {
                    element.dataset.highlighted = '';
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/fonts';
@import "../../assets/css/variables";

.dot {
    height: 16px;
    width: 16px;
    padding: 8px;
    border-radius: 50%;
}

.nav-item {
    position: relative;
    list-style: none;
}

.vulnerability {
    transition: 0.3s;
    position: relative;
    margin: 0 0 40px 0;
    &.moveto__folder {
        pointer-events: none;
    }
}

.vulnerability__header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;

    &__category{
        @include Aspekta-font(600, 18px, 26px, #242424);
    }

    &__version {
        @include Aspekta-font(500, 18px, 20px, #5C6A7A);
    }

}

.vulnerability__header__hint {

    &__resolved {
        position: absolute;
        top: 7px;
        margin-left: 6px;
        background-color: #242424;
        border-radius: 3px;
        width: 80px;
        height: 20px;

        &__title {
            font-size: 16px;
            color: white;
            position: relative;
            top: -2px;
            left: 10px;
        }

        &__title::before {
            content: "";
            position: absolute;
            top: 90%;
            left: 32.22%;
            transform: translate(-50%, 0%);
            border: 6px solid transparent;
            border-top: 3px solid black;
        }
    }

    &__new {
        position: absolute;
        top: 7px;
        margin-left: 15px;
        background-color: #242424;
        border-radius: 3px;
        width: 50px;
        height: 20px;

        &__title {
            font-size: 16px;
            color: white;
            position: absolute;
            top: -2px;
            left: 10px;
        }

        &__title::before {
            content: "";
            position: absolute;
            top: 90%;
            left: 50%;
            transform: translate(-50%, 0%);
            border: 6px solid transparent;
            border-top: 3px solid black;
        }
    }
}

.vulnerability__icon {
    position: relative;
    width: 25px;
    height: 25px;
}

.vulnerability__info {
    display: flex;
    margin-left: 40px;
    width: fit-content;
}

.vulnerability__file-name {
    @include Aspekta-font(500, 14px, 26px, #021631);
    word-break: break-word;
}

.vulnerability__description {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &_compare {
        margin-bottom: 0;
    }
}

.vulnerability__description-title {
    display: flex;
    align-items: center;
    height: auto;
    gap: 24px;
}

.vulnerability__title {
    @include Aspekta-font(600, 18px, 26px, #242424);
}


.vulnerability__buttons {
    display: flex;
    justify-content: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: center;
    margin: 0px auto 0 40px;


    &-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        min-height: 48px;

        &_special {
            margin: 0 20px 0 auto;
        }

        .tooltip-container {
            width: 28px;
            height: 28px;
            justify-content: center;
            align-items: center;
        }
    }
}



.button_action {
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

}

.button_action:focus {
    outline: 5px;
}

.report-item__link-message {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -32px;
    left: -30px;
    width: 110px;
    height: 23px;
    background-color: black;
    border-radius: 3px;
    color: white;
    font-family: Cabin, serif;
    font-size: 14px;
    z-index: 2;
}

.report-item__link-message::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
    border: 6px solid transparent;
    border-top: 3px solid black;
    z-index: 2;
}

.vulnerability__delete {
    opacity: 0.5;
}

.vulnerability__code-wrapper-child {
    margin-top: 20px;
    overflow: hidden;

}

.vulnerability__code-wrapper-child tbody tr td {
    padding: 0 !important;
}

.vulnerability__code {
    display: flex;
    overflow: auto;
    position: relative;
}

.vulnerability__code::-webkit-scrollbar {
    display: none;
}

.vulnerability__show-code-btn {
    display: flex;
    @include Aspekta-font(400, 14px, 20px, $main-blue-color);
    background-color: #fff;
    border: 2px solid $background-blue-color;
    border-radius: 20px;
    padding: 8px 20px;
    outline: none;


    justify-content: center;
    align-items: center;

    &:hover {
        background-color: $background-blue-color;
        border: 2px solid #ADCCFB;
    }
    &-arrow {
        width: 24px;
        height: 24px;
        rotate: -180deg;
        transition: rotate .3s ease-in-out;
    }
    &-text {
        margin: 0;
        min-width: 60px;
    }
    &[aria-expanded="true"] {
        .vulnerability__show-code-btn-arrow {
            rotate: 0deg;
        }
    }
    &.vulnerability__showvulnerabilitiesdisable {
        filter: grayscale(100%);
        opacity: .5;
    }
}

.vulnerability__line {
    margin: 20px 0 0 40px;
    border: 2px solid transparent;
}

.vulnerability__code-line-number {
    padding-left: 40px !important;
    font-weight: 100;
}

.vulnerability__buttons-cancel {
    width: 100%;
    height: 30px;
}


.vulnerability_description {
    display: flex;
    justify-content: flex-start;
    align-content: center;
}

.vulnerability_level {
    @include Aspekta-font(400, 14px, 20px, #5C6A7A);
    letter-spacing: 0.02em;
    width: 100%;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    float: right;
    margin-right: 10px;
}

.vulnerability__code-wrapper-child tbody tr td:first-child {
    padding-left: 40px !important;
    padding-right: 15px !important;
    width: 20px;
}

table {
    border-collapse: collapse;
    border: 0;
}

table.block {
    width: 100% !important;
    background-color: #F8FAFC !important;
    border: 0;
    transition: 0.3s;
}

.highlight td:nth-child(1) {
    background-color: #FEEAE7;
}

.highlight td:nth-child(2){
    background-color: #FEEAE7;
}
.hljs {
  background-color: transparent!important;
}

.highlight_red {
    background-color: rgba(254, 234, 231, 1);
}

.highlight_green {
    background-color: rgba(76, 224, 116, 0.1);
}

span {
    margin: 5px;
}

.readmore {
    display: none;
}

pre {
    margin: 0;
}

.hljs {
    background-color: inherit;
    border-style: none;
    padding: 0;
    height: 100%;
    color: #242424;
}

code.hljs {
    text-shadow: none;
}

.line-green {
    background-color: $low-vulnerability-color;
}

.line-yellow {
    background-color: $medium-vulnerability-color;
}

.line-red {
    background-color: $high-vulnerability-color;
}

.dropdown__icon {
    width: 24px;
    height: 24px;
}

.dropdown-with-folders {
    width: 260px;
    position: relative;
}
.folder-popup {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 12px 16px 0px rgba(178, 185, 195, 0.12);
    top: 48px;
    width: 240px;
    border-radius: 8px;
    font-size: 14px;
    color: #5C6A7A;
    z-index: 1;

    &__button {
        @include Aspekta-font(500, 14px, normal, #021631);
        letter-spacing: 0.42px;
        background-color: #F5F7FA;
        border-radius: 16px;
        padding: 4px 16px;
        display: flex;
        align-items: center;
        gap: 4px;

        &:hover {
            opacity: 0.5;
        }
        & > img {
            width: 14px;
        }
    }

    &__folder-title {
        @include Aspekta-font(500, 14px, normal, #5C6A7A);
        letter-spacing: 0.42px;
        text-align: center;
    }

    &__folder-name {
        @include Aspekta-font(500, 14px, normal, #5C6A7A);
        letter-spacing: 0.42px;
        display: block;
        width: fit-content;
        max-width: 218px;
        overflow-x: hidden;
        text-align: center;
        text-overflow: ellipsis;
        position: relative;
        margin: 0 auto;

        &::after {
            content: '”';
            width: 6px;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    &__icon {

        &.move {
            height: 24px;
            width: 24px;
        }
        &.copy, &.download {
            height: 28px;
            width: 28px;
        }
    }
}

.folder-popup.move-to-folder {
    cursor: default;
    gap: 10px;
    top: 58px;
    width: 260px;

}
.folder-popup.copy-link {
    cursor: default;
    pointer-events: none;
    right: -46px;
}
.folder-popup.download-image {
    cursor: default;
    pointer-events: none;
    right: 0;
}



@media (max-width: 576px) {
    .dropdown-with-folders {
        max-width: 240px;
    }

    .vulnerability {

        &__buttons {
            margin: 20px auto 0;
        }


        &__line, &__info {
            margin-left: 0;

        }

        &__show-code {
            width: 100%;
            &-btn {
                width: inherit;
                height: 48px;
            }
        }
    }


}

</style>
