<template>
    <div>
        <div>
            <VulnerabilityList />
        </div>
        <MainMarketApps />
    </div>
</template>
<script>
import VulnerabilityList from '../components/vulnerability/VulnerabilityList';
import MainMarketApps from '../components/main/MainMarketApps';

export default {
    components: {
        MainMarketApps,
        VulnerabilityList,
    },
};
</script>

<style></style>
