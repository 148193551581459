<template>
    <svg class="failed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="10"/>
        <path d="M10.4215 7.72165C9.67596 6.9761 8.46719 6.9761 7.72165 7.72165C6.9761 8.46719 6.9761 9.67597 7.72165 10.4215L9.30014 12L7.72165 13.5785C6.9761 14.324 6.9761 15.5328 7.72164 16.2784C8.46719 17.0239 9.67596 17.0239 10.4215 16.2784L12 14.6999L13.5785 16.2784C14.324 17.0239 15.5328 17.0239 16.2784 16.2784C17.0239 15.5328 17.0239 14.324 16.2784 13.5785L14.6999 12L16.2784 10.4215C17.0239 9.67597 17.0239 8.4672 16.2784 7.72165C15.5328 6.9761 14.324 6.9761 13.5785 7.72165L12 9.30014L10.4215 7.72165ZM6.92169 17.0783C4.11701 14.2736 4.11701 9.72636 6.92169 6.92169C9.72636 4.11701 14.2736 4.11701 17.0783 6.92169C19.883 9.72636 19.883 14.2736 17.0783 17.0783C14.2736 19.883 9.72636 19.883 6.92169 17.0783ZM4.22183 4.22183C-0.0739419 8.51759 -0.0739419 15.4824 4.22183 19.7782C8.51759 24.0739 15.4824 24.0739 19.7782 19.7782C24.0739 15.4824 24.0739 8.51759 19.7782 4.22183C15.4824 -0.0739419 8.51759 -0.0739419 4.22183 4.22183Z" stroke-width="2" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default {
    name: 'FailedIcon'
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

svg.failed {
    path {
        fill: $main-orange-color;
        stroke: $background-orange-color;
    }
    circle {
        fill: $background-orange-color;
    }
}

</style>
</style>