<template>
    <div class="sessions">
        <ul class="sessions__list">
            <li
                v-for="session in sessionsList"
                :key="session.id"
                class="sessions__list-item"
            >
                <div class="sessions__list-item-box">
                    <div class="sessions__list-item-img-wrapper">
                        <img
                            v-if="session.deviceType === 'mobile'"
                            src="../../assets/images/modile-logo-orange.svg"
                            alt=""
                        >
                        <img
                            v-else-if="session.deviceType === 'desktop'"
                            src="../../assets/images/computer-logo.svg"
                            alt=""
                        >
                        <div v-else>
                            -
                        </div>
                    </div>
                    <div class="sessions__list-item-text-wrapper">
                        <div :class="{ deactivate: session.deactivatedAt }">
                            <div class="sessions__list-item-text-box">
                                <p class="sessions__list-item-text-label">
                                    IP address
                                </p>
                                <p
                                    v-if="session.ipAddress"
                                    class="sessions__list-item-text"
                                >
                                    {{ session.ipAddress }}
                                </p>
                                <p
                                    v-else
                                    class="sessions__list-item-text"
                                >
                                    -
                                </p>
                            </div>
                            <div class="sessions__list-item-text-box">
                                <p class="sessions__list-item-text-label">
                                    User agent
                                </p>
                                <p
                                    v-if="session.browserName"
                                    class="sessions__list-item-text"
                                >
                                    {{ session.browserName }}
                                    {{ session.browserMajor }} on {{ session.OSName }} {{ session.OSVersion }}
                                </p>
                                <p
                                    v-else
                                    class="sessions__list-item-text"
                                >
                                    -
                                </p>
                            </div>
                            <div class="sessions__list-item-text-box">
                                <p class="sessions__list-item-text-label">
                                    Location
                                </p>
                                <p class="sessions__list-item-text">
                                    {{ locationString([session.city, session.regionName, session.countryName]) }}
                                </p>
                            </div>
                            <div class="sessions__list-item-text-box">
                                <p class="sessions__list-item-text-label">
                                    Time
                                </p>
                                <p
                                    v-if="session.createdAt"
                                    class="sessions__list-item-text"
                                >
                                    {{ getCreatedTime(session.createdAt) }}
                                </p>
                                <p
                                    v-else
                                    class="sessions__list-item-text"
                                >
                                    -
                                </p>
                            </div>
                            <div class="sessions__list-item-text-box">
                                <p class="sessions__list-item-text-label">
                                    Last used at
                                </p>
                                <p
                                    v-if="session.lastUsedAt"
                                    class="sessions__list-item-text"
                                >
                                    {{ getCreatedTime(session.lastUsedAt) }}
                                </p>
                                <p
                                    v-else
                                    class="sessions__list-item-text"
                                >
                                    -
                                </p>
                            </div>
                        </div>
                        <div
                            v-if="session.deactivatedAt"
                            class="sessions__list-item-text-box"
                        >
                            <p class="sessions__list-item-text-label">
                                Deactivated at
                            </p>
                            <p class="sessions__list-item-text">
                                {{ getCreatedTime(session.deactivatedAt) }}
                            </p>
                        </div>
                    </div>
                </div>
                <button
                    v-if="!session.deactivatedAt"
                    class="sessions__list-item-btn"
                    @click="revokeSession(session.sessionId)"
                >
                    Revoke
                </button>
            </li>
        </ul>
        <div>
            <Pagination
                class="session__pagination"
                :count="sessionsCount"
                :limit="limit"
                :current-page="currentPage"
                :show-spinner="showSpinner"
                @pageChanged="updatePage"
            />
        </div>
        <div
            v-if="showSpinner"
            class="session__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import Spinner from '../Spinner';
import { refreshTokenRequest } from '../../services/authorization/refreshToken';
import Pagination from '../Pagination.vue';

export default {
    components: {
        Spinner,
        Pagination,
    },
    data() {
        return {
            showSpinner: false,
            currentPage: 1,
            limit: 10,
        };
    },
    computed: {
        ...mapGetters({
            sessionsList: 'user/sessionsList',
            sessionsCount: 'user/sessionsCount',
        }),
    },
    watch: {
        async currentPage() {
            this.showSpinner = true;
            await this.$store.dispatch('user/getUserSessions', { limit: this.limit, page: this.currentPage });
            this.showSpinner = false;
        },
    },
    async beforeMount() {
        this.showSpinner = true;
        await this.$store.dispatch('user/deactivateAllExpiredSessions');
        await this.$store.dispatch('user/getUserSessions', { limit: this.limit, page: this.currentPage });
        this.showSpinner = false;
    },

    methods: {
        async updatePage(page) {
            this.currentPage = page;
        },
        getCreatedTime(time) {
            return `${moment(Date.parse(time)).format('MMM Do, y')} at ${moment(Date.parse(time)).format('LTS')} (${moment(Date.parse(time)).from()})`;
        },
        async revokeSession(sessionId) {
            this.showSpinner = true;
            await this.$store.dispatch('user/revokeUserSessions', sessionId);
            await this.handleUserSession();
            await this.$store.dispatch('user/getUserSessions', { limit: this.limit, page: this.currentPage });
            this.showSpinner = false;
        },
        async handleUserSession() {
            const refreshToken = localStorage.getItem('refreshToken');
            try {
                await refreshTokenRequest(refreshToken);
            } catch (error) {
                console.error(error);
            }
        },
        locationString(locationArray) {
            const validLocation = locationArray.filter(area => area && area !== 'unknown');
            return validLocation.length ? validLocation.join(', ') : '-';
        },
        refreshTokenRequest,
    },
};
</script>
<style lang="scss">
.deactivate {
    .sessions__list-item-text-label,
    .sessions__list-item-text {
        color: #B2B9C3 !important;
    }
}

.session__spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: rgba(256, 256, 256, .7);
    z-index: 2000;
    border-radius: 20px;
}

.session__pagination {
    background-color: #FFFFFF;
    padding: 0 0 15px 27px;
}

.sessions {
    width: 100% !important;
    position: relative;
    padding: 0;

    &__list {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        padding: 15px 45px 15px 35px;
        background-color: #FFFFFF;
        border-radius: 20px;
        margin: 0;
        min-height: 400px;
    }

    &__list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;

        &:hover .sessions__list-item-btn {
            opacity: 1;
            transition: 0.6s;
        }
    }

    &__list-item-box {
        display: flex;
        align-items: center;
    }

    &__list-item-img-wrapper {
        width: 35px;
        display: flex;
        justify-content: center;
    }

    &__list-item-text-wrapper {
        margin-left: 30px;
    }

    &__list-item-text-box {
        display: flex;
        margin-bottom: 2px;
    }

    &__list-item-text-label {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: #242424;
        margin: 0 24px 0 0;
        width: 90px;
    }

    &__list-item-text {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140%;
        color: #706969;
        margin: 0;
        max-width: 375px;
        word-break: break-all !important;
    }

    &__list-item-btn {
        background: #FFB400;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        width: 137px;
        height: 42px;
        opacity: 0;

        &:hover {
            opacity: 0.6 !important;
        }
    }
}

@media (max-width: 990px) {
    .sessions {
        &__list-item-btn {
            opacity: 1;
        }
    }
}

@media (max-width: 768px) {
    .sessions {
        &__list {
            padding: 15px 20px 15px 20px;
        }

        &__list-item {
            flex-direction: column;
            align-items: flex-start;
        }

        &__list-item-img-wrapper {
            display: none;
        }

        &__list-item-text-wrapper {
            margin-left: 0;
        }

        &__list-item-text {
            max-width: 240px;
        }

        &__list-item-btn {
            margin-top: 20px;
        }
    }

    .session__pagination {
        justify-content: space-between;
        padding: 0 0 15px 0px;
    }
}
</style>
