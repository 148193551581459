<template>
    <AppDropdown @toggle="toggle">
        <template slot="toggler">
            <button class="dropdown-header_button">
                <span
                    v-if="selectedCurrentVersion"
                    class="dropdown-header_title"
                >
                    Last version: {{ slicedVersion(selectedCurrentVersion) }}
                    <span class="dropdown-content_date">{{ formatDateData(selectedCurrentDate) }}</span>
                </span>
                <span
                    v-else-if="selectedCompareVersion"
                    class="dropdown-header_title"
                >
                    Version: {{ slicedVersion(selectedCompareVersion) }}
                    <span class="dropdown-content_date">{{ formatDateData(selectedCompareDate) }}</span>
                </span>
                <span
                    v-else
                    class="dropdown-header_title"
                >
                    Choose version...
                </span>
                <img
                    class="dropdown-header_icon"
                    src="../../../assets/images/vulnerabilities/arrow-down.svg"
                    :style="!options.length && {display: 'none'}"
                    :class="{_active : isActive}"
                >
            </button>
        </template>
        <AppDropdownContent
            v-if="options.length"
            @toggle="toggle"
        >
            <AppDropdownItem
                v-for="(item, index) in options"
                :id="item.id"
                :key="index"
                :version="item.version"
                :drop-down-type="id"
                :date="item.time"
                :class="isVersionDisabled(item.id, id) ? 'disabled' : '' || isVersionChosen(item.id, id) ? 'selected' : ''"
                :disabled="isVersionDisabled(item.id, id)"
                :chosen-version="isVersionChosen(item.id, id)"
                @change-version="changeVersion"
            >
                {{ `Version: ` + dropSlicedVersion(item.version) }}
            </AppDropdownItem>
        </AppDropdownContent>
    </AppDropdown>
</template>

<script>
import AppDropdown from './appDropdown.vue';
import AppDropdownContent from './appDropdownContent.vue';
import AppDropdownItem from './appDropdownItem.vue';
import { formatDate } from '../../../utils/helper-functions';

export default {
    name: 'CompareDropdown',
    components: {
        AppDropdown,
        AppDropdownContent,
        AppDropdownItem,
    },
    props: [
        'selectedCurrentVersion',
        'selectedCompareVersion',
        'selectedCurrentDate',
        'selectedCompareDate',
        'selectedCurrentId',
        'selectedCompareId',
        'options',
        'id'
    ],
    data() {
        return {
            windowWidth: window.innerWidth,
            isActive: false,
            month: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ],
        };
    },
    mounted() {
        window.addEventListener('resize',this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        /**
         * reduces the number of characters depending on the screen size, for current and compare version
         */
        slicedVersion(value) {
            const maxChar = this.windowWidth < 576 ? 5: 15;
            if (value && value.length > maxChar) {
                return `${value.slice(0, maxChar)}...`;
            } else {
                return value;
            }
        },
        /**
         * reduces the number of characters depending on the screen size, for drop down area
         */
        dropSlicedVersion(value) {
            const maxChar =  this.windowWidth < 576 ? 10: 20;
            if (value && value.length > maxChar) {
                return `${value.slice(0, maxChar)}...`;
            } else {
                return value;
            }
        },
        changeVersion(obj) {
            this.$emit('change-version', obj);
        },
        toggle(active) {
            this.isActive = active;
        },
        formatDateData(time) {
            return formatDate(time);
        },
        isVersionDisabled(versionId, dropDownType) {
            if (dropDownType === 'currentVersion') {
                return versionId === this.selectedCompareId;
            } else {
                return versionId === this.selectedCurrentId;
            }
        },
        isVersionChosen(versionId, dropDownType) {
            if (dropDownType === 'currentVersion') {
                return versionId === this.selectedCurrentId;
            } else {
                return versionId === this.selectedCompareId;
            }
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

.dropdown-header {

    &_button {
        position: relative;
        width: 100%;
        height : 100%;
        display: flex;
        border-radius: 20px;
        transition: all 0.5s;
    }

    &_title {
        @include Aspekta-font(600, 18px, normal, #021631);
        letter-spacing: 0.54px;
        float: left;
        padding: 22px 22px 0 0;
        margin-left : 45px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
    }

    &_icon {
        position: absolute;
        right : 50px;
        top : 30px;
        width: 10px;
        height: 6px;
        transition: all 0.5s;
    }
}
._active{
    transition: all 0.5s;
    transform:rotate(180deg);
}

.disabled {
    color: #BDBDBD;
    cursor: not-allowed;
}

.selected {
    cursor: not-allowed;
}

.dropdown-content_date {
    @include Aspekta-font(400, 14px, normal, #949DB8);
    letter-spacing: 0.28px;
    position: relative;
    left : 20px;
}

.dropdown-header_icon {
    right: 32px;
}

@media (max-width: 420px) {
    span.dropdown-header_title {
        margin-left: 10px;
    }
    .dropdown-header_icon {
        right: 15px;
    }
}
</style>
