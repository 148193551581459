export const JobStatusEnum = {
    CREATED: 'CREATED',
    IN_QUEUE: 'IN_QUEUE',
    PROCESSING: 'PROCESSING',
    SCANNING: 'SCANNING',
    FINISHED: 'FINISH',
    ERROR: 'ERROR',
};
export const IntegrationInvitesStatusEnum = {
    INVITE_SENT: 'INVITE_SENT',
    DECLINED: 'DECLINED',
    ACCEPTED: 'ACCEPTED',
};
export const IntegrationStatusEnum = {
    CREATED: 'CREATED',
    UNDER_REVIEW: 'UNDER_REVIEW',
    ACTIVE: 'ACTIVE',
    EXPIRED: 'EXPIRED',
    REJECTED: 'REJECTED',
    DELETED: 'DELETED',
    FINISHED: 'FINISH',
    SCANNING: 'SCANNING',
    RENEWAL_REQUESTED: 'RENEWAL_REQUESTED',
    SAMPLE: 'SAMPLE',
    TRIAL_REQUESTED: 'TRIAL_REQUESTED',
};
export const VersionStatusEnum = {
    CREATED: 'CREATED',
    SCANNING: 'SCANNING',
    IN_QUEUE: 'IN_QUEUE',
    FINISHED: 'FINISH',
    ERROR: 'ERROR',
};

export const JobTypeEnum = {
    TRIAL: 'TRIAL',
    PAID: 'PAID',
    SAMPLE: 'SAMPLE',
    INTEGRATION: 'INTEGRATION',
    VERSION_SAMPLE: 'VERSION_SAMPLE',
};

export const SessionActionEnum = {
    REVOKE: 'REVOKE',
};

export const ActivationCouponCodeEnum = {
    ACTIVATION_CODE: 'ACTIVATION_CODE',
};

export const TEN_SECONDS_IN_MS = 10000;

export const KeyCodesEnum = {
    UP: 'ArrowUp',
    DOWN: 'ArrowDown',
    HOME: 'Home',
    PAGE_UP: 'PageUp',
    PAGE_DOWN: 'PageDown',
    END: 'End',
    RETURN: 'Backspace',
    SPACE: 'Space',
    ENTER: 'Enter',
    ESC: 'Escape',
};

export const FolderNameEnum = {
    MAIN_REPORT: 'Main Report',
    FALSE_POSITIVES: 'False Positives',
    ALL_VULNERABILITIES: 'All Vulnerabilities',
};

export const PopupTypeEnum = {
    FILTERS: 'general-popupFilters',
};

export const GetStartedStepsEnum = {
    CREATED_INTEGRATION: 'createdIntegration',
    CREATED_API_KEY: 'createdApiKey',
    WATCHED_API: 'watchedApi',
    WATCHED_CI_CD: 'watchedCiCd',
};
