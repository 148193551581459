import JSZip from 'jszip';

// Validate APK files by checking for the presence of required files
const validateApk = async (file, { manifestPath, classesDexPath }) => {
	const zip = await loadZipFromFile(file);
	const manifestFile = zip.file(manifestPath);
	const classesDexFile = zip.file(classesDexPath);
	const missingFiles = [];

	if (!manifestFile) missingFiles.push('manifest');
	if (!classesDexFile) missingFiles.push('classes.dex');

	return buildValidationResult(missingFiles, file);
};

// Validate AAB files by checking for the presence of required files
const validateAab = async (file, { manifestPath, classesDexPath }) => {
	const zip = await loadZipFromFile(file);
	const manifestFile = zip.file(manifestPath);
	const classesDexFile = zip.file(classesDexPath);
	const missingFiles = [];

	if (!manifestFile) missingFiles.push('manifest');
	if (!classesDexFile) missingFiles.push('classes.dex');

	return buildValidationResult(missingFiles, file);
};

// Validate ZIP files by checking for the presence of an xcodeproj folder
const validateZip = async (file, { xcodeprojRegex }) => {
	const zip = await loadZipFromFile(file);
	const xcodeprojFolder = Object.keys(zip.files).find((fileName) => xcodeprojRegex.test(fileName));
	const missingFiles = xcodeprojFolder ? [] : ['xcodeproj folder'];

	return buildValidationResult(missingFiles, file);
};

// Map file extensions to their respective validation functions and required properties
const fileValidators = {
	'.apk': { validator: validateApk, manifestPath: 'AndroidManifest.xml', classesDexPath: 'classes.dex' },
	'.aab': { validator: validateAab, manifestPath: 'base/manifest/AndroidManifest.xml', classesDexPath: 'base/dex/classes.dex' },
	'.zip': { validator: validateZip, xcodeprojRegex: /.*\.xcodeproj/ },
};

// Validate a given file based on its extension
export const validateFile = async (file) => {
	const validator = fileValidators[getFileExtension(file.name)];
	return validator ? await validator.validator(file, validator) : false;
};

// Get the file extension from a given filename
const getFileExtension = (filename) => {
	const extIndex = filename.lastIndexOf('.');
	return extIndex !== -1 ? filename.slice(extIndex) : '';
};

// Load a JSZip object from a file
const loadZipFromFile = async (file) => {
	const zip = new JSZip();
	const fileBuffer = await readFileAsArrayBuffer(file);
	await zip.loadAsync(fileBuffer);
	return zip;
};

// Read the contents of a file as an ArrayBuffer
const readFileAsArrayBuffer = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = (event) => resolve(event.target.result);
		reader.onerror = (error) => reject(`Error reading ${file.name}: ${error}`);
		reader.readAsArrayBuffer(file);
	});
};

// Build a validation result object based on the presence of missing files
const buildValidationResult = (missingFiles, file) => {
	if (missingFiles.length === 0) {
		return { valid: true};
	}

	const errorMessage = `Did not find ${missingFiles.join(' and ')} ${missingFiles.length > 1 ? 'files' : 'file'}`;
	// const errorMessage = `Did not find ${missingFiles.join(' and ')} ${missingFiles.length > 1 ? 'files' : 'file'} in ${sanitizeFileName(file.name)}`;
	return { valid: false, errorMessage, missingInnerFiles: missingFiles, fileName: file.name };
};

export const sanitizeFileName = fileName => {
	// Remove control characters (0x00-0x1f and 0x80-0x9f)
	const cleanedFileName = fileName.replace(/[\x00-\x1F\x80-\x9F]/g, '');

	// Escape HTML entities and reserved characters
	const escapedFileName = cleanedFileName
		.replace(/&/g, '&amp;')        // `&` - ampersand
		.replace(/</g, '&lt;')         // `<` - less-than sign
		.replace(/>/g, '&gt;')         // `>` - greater-than sign
		.replace(/"/g, '&quot;')       // `"` - double quotation mark
		.replace(/'/g, '&#039;')       // `'` - single quotation mark (apostrophe)
		.replace(/\//g, '_slash_')     // `/` - forward slash
		.replace(/\\/g, '_backslash_') // `\` - backslash
		.replace(/\?/g, '_question_')  // `?` - question mark
		.replace(/:/g, '_colon_')      // `:` - colon
		.replace(/\*/g, '_asterisk_')  // `*` - asterisk
		.replace(/\|/g, '_pipe_');     // `|` - vertical bar (pipe)
  
	// Remove trailing periods or spaces on Windows
	const windowsSafeFileName = escapedFileName.replace(/[\s.]+$/, '');
  
	return windowsSafeFileName;
}