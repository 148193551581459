<template>
    <div id="app">
        <Header />
        <router-view class="page-content" />
        <Footer />
    </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';

export default {
    name: 'App',
    components: {
        Header,
        Footer,
    },
    watch: {
        $route() {
            this.scroll();
        },
    },
    methods: {
        scroll: function () {
            return window.scrollTo(0, 0);
        },
    },
};
</script>

<style lang="scss">
    @import "assets/css/variables";

    #app {
        font-family: Cabin, sans-serif;
        font-style: normal;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #242424;
        width: 100%;

        .page-content {
            min-height: calc(100vh - #{$header_height} - #{$footer-height});

            & .spinner-wrapper {
                padding-top: 30vh;
                padding-bottom: 30vh;
            }
        }
    }

    #app > *:last-child {
        margin-bottom: 0;
    }

    @media screen and (max-width: 1190px) {
        #app {
            .page-content {
                min-height: calc(100vh - #{$header_height} - 292px);
            }
        }
    }

    @media screen and (max-width: 678px) {
        #app {
            .page-content {
                min-height: calc(100vh - 352px);
            }
        }
    }

    @media only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3) and (orientation: portrait){
        body{
            min-width: 400px;
            max-width: 414px;
            margin: 0;
            padding: 0;
        }
        #app {
            min-width: 400px;
            max-width: 414px;
            margin: 0;
            padding: 0;
        }
        .page-content {
            min-width: 400px;
            max-width: 414px;
            margin: 0;
            padding: 0;

        }
    }
    @media only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3) and (orientation: landscape){
        html, body {
            overflow-x: hidden!important;
        }
        body{
            min-width: 800px!important;
            max-width: 896px!important;
            overflow-x: hidden!important;
            position: relative!important;
            padding: 0!important;
        }
        #app {
            min-width: 800px!important;
            max-width: 896px!important;
            margin: 0!important;
            padding: 0 !important;
            overflow-x:hidden!important
        }
        .page-content {
            min-width: 800px!important;
            max-width: 896px!important;
            margin: 0!important;
            padding: 0 !important;
            overflow-x:hidden!important
        }
    }
</style>
