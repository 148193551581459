<template>
    <div
        id="faq"
        class="faqs-block"
    >
        <div class="faqs-block__content">
            <div class="content-title">
                <span class="content-title__text">FAQs</span>
                <img
                    class="content-title__vector"
                    :src="setVectorUrl"
                    alt="vector"
                >
            </div>
            <div class="content-questions">
                <QuestionBlock
                    v-for="(block, index) in questions"
                    :key="index"
                    :question="block.question"
                    :answer="block.answer"
                />
            </div>
        </div>
    </div>
</template>
<script>
import QuestionBlock from './QuestionBlock.vue';

export default {
    components:{
        QuestionBlock,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            isOpen: false,
            questions:[
                {
                    question: 'What is Oversecured?',
                    answer:`Oversecured is a mobile app security provider offering an automated scanning solution that
                    helps companies detect and fix vulnerabilities in their mobile applications.`,
                },
                {
                    question: 'How does Oversecured work?',
                    answer:`Oversecured automatically scans mobile apps for all known vulnerabilities, such as unauthorized
                    code execution, file theft, and cross-site scripting. It provides detailed reports and recommendations on
                    how to address and resolve these vulnerabilities.`,
                },
                {
                    question: 'How can Oversecured benefit my business?',
                    answer:`Oversecured helps minimize the risks of potential financial and reputational losses due to data
                    breaches. By automating the vulnerability detection and remediation process, it saves time and resources
                    for your security team, allowing them to focus on other priorities. Additionally, it provides valuable
                    insights to improve your team's workflow and prioritize critical vulnerabilities.`,
                },
                {
                    question: 'Is Oversecured suitable for all types of mobile apps?',
                    answer:`Yes, Oversecured is designed to work with all mobile application platforms, including iOS and Android.
                    Whether you have a small business app or a large enterprise application, Oversecured can help identify and mitigate vulnerabilities.`,
                },
                {
                    question: 'How long does it take to integrate Oversecured into my existing workflow?',
                    answer:`Integrating Oversecured into your existing workflow is a straightforward process. Our team will guide you through the
                    integration steps, and the time required will depend on the complexity of your infrastructure. We aim to make the integration
                    process as seamless as possible to ensure minimal disruption to your operations.`,
                },
                {
                    question: 'Is Oversecured suitable for both small businesses and large enterprises?',
                    answer:`Yes, Oversecured caters to the needs of businesses of all sizes. Whether you are a small startup or a large enterprise,
                    our solution can scale to meet your security requirements.`,
                },
                {
                    question: 'What level of support do you provide?',
                    answer:`We provide comprehensive support to our customers. Our team is available to answer any questions, provide technical assistance,
                    and help you make the most of our solution. We offer various support channels, including email, phone, and live chat.`,
                },
                {
                    question: 'How can I get started with Oversecured?',
                    answer:`Getting started with Oversecured is easy. You can [contact our sales team] to discuss your specific requirements.
                    We'll be happy to guide you through the onboarding process and help you start protecting your business.`,
                },
            ],

        };
    },
    computed:{
        isMobile() {
            return this.windowWidth < 768;
        },
        setVectorUrl() {
            return this.isMobile? require('../../../assets/images/FAQs-vector-mobile.svg'):
                require('../../../assets/images/FAQs-vector.svg');
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods:{
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },

};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/variables/variablesForFAQs.scss";
@import "../../../assets/css/variables.scss";
@import "../../../assets/css/fonts";

.faqs-block {
    width: 100vw;
    overflow: hidden;

    &__content {
        margin: 160px $dynamic-faqs-block-content-margin-l-r;
        display: flex;
        justify-content: space-between;
        gap: $dynamic-faqs-block-content-gap;
    }
}
.content-title {
    display: flex;
    flex-direction: column;

    &__text {
        @include Aspekta-font(700, $dynamic-faqs-title-text-font-size, normal, $general-black);
        line-height: 120%;
        margin-bottom: 98px;
    }

    &__vector {
        width: 90%;
        transform: rotate(3.77deg);
    }
}
.content-questions {
    display: flex;
    flex-direction: column;
    width: 70%;  //1007px
    gap: 40px;
}

@media (max-width: 768px) {

    .content-questions {
        width: 100%;
    }

    .faqs-block__content {
        margin: 80px #{$min-faqs-block-content-margin-l-r}px;
        flex-direction: column;
        gap: 20px ;
    }

    .content-title {
        position: relative;

        &__text {
            text-align: center;
            font-size: #{$min-faqs-title-text-font-size}px;
        }
        
        &__vector {
            position: absolute;
            width: 24%;
            transform: rotate(0.77deg);
            top: $dynamic-faqs-vector-mobile-top;
            left: $dynamic-faqs-vector-mobile-left;
        }
    }
}

</style>
