<template>
    <svg :width="size" :height="size" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <path :d="iconPath" :fill="fillColor"></path>
        <path v-if="iconType === 'first' || iconType === 'last'" :d="iconPathSecondary" :fill="fillColor"></path>
    </svg>
</template>

<script>
export default {
    props: ['iconType', 'state', 'size'],
    computed: {
        fillColor() {
            if (this.state === 'active') {
                return '#949DB8';
            } else {
                return '#D9D9D9';
            }
        },
        iconPath() {
            switch (this.iconType) {
                case 'first':
                    return 'M7.1528 1.21738C7.44588 1.51008 7.44618 1.98495 7.15348 2.27804L2.43723 7.00035L7.15342 11.7216C7.44616 12.0146 7.4459 12.4895 7.15285 12.7822C6.8598 13.075 6.38493 13.0747 6.09219 12.7817L0.846584 7.53045C0.554091 7.23765 0.554066 6.76325 0.846527 6.47042L6.09214 1.21806C6.38484 0.924976 6.85971 0.924671 7.1528 1.21738Z';
                case 'last':
                    return 'M6.8472 1.21738C6.55412 1.51008 6.55382 1.98495 6.84652 2.27804L11.5628 7.00035L6.84658 11.7216C6.55384 12.0146 6.5541 12.4895 6.84715 12.7822C7.1402 13.075 7.61507 13.0747 7.90781 12.7817L13.1534 7.53045C13.4459 7.23765 13.4459 6.76325 13.1535 6.47042L7.90786 1.21806C7.61516 0.924976 7.14029 0.924671 6.8472 1.21738Z';
                case 'next':
                    return 'M0.847209 1.21738C0.554127 1.51008 0.553821 1.98495 0.846526 2.27804L5.56277 7.00035L0.846585 11.7216C0.553848 12.0146 0.554102 12.4895 0.847151 12.7822C1.1402 13.075 1.61507 13.0747 1.90781 12.7817L7.15342 7.53045C7.44591 7.23765 7.44594 6.76326 7.15348 6.47042L1.90787 1.21806C1.61516 0.924976 1.14029 0.924671 0.847209 1.21738Z';
                case 'prev':
                    return 'M7.15279 1.21738C7.44587 1.51008 7.44618 1.98495 7.15347 2.27804L2.43723 7.00035L7.15341 11.7216C7.44615 12.0146 7.4459 12.4895 7.15285 12.7822C6.8598 13.075 6.38493 13.0747 6.09219 12.7817L0.84658 7.53045C0.554087 7.23765 0.554062 6.76326 0.846523 6.47042L6.09213 1.21806C6.38484 0.924976 6.85971 0.924671 7.15279 1.21738Z';
                default:
                    return '';
            }
        },
        iconPathSecondary() {
            switch (this.iconType) {
                case 'first':
                    return 'M13.1528 1.21738C13.4459 1.51008 13.4462 1.98495 13.1535 2.27804L8.43723 7.00035L13.1534 11.7216C13.4462 12.0146 13.4459 12.4895 13.1529 12.7822C12.8598 13.075 12.3849 13.0747 12.0922 12.7817L6.84658 7.53045C6.55409 7.23765 6.55407 6.76325 6.84653 6.47042L12.0921 1.21806C12.3848 0.924976 12.8597 0.92467 13.1528 1.21738Z';
                case 'last':
                    return 'M0.847203 1.21738C0.554122 1.51008 0.553817 1.98495 0.846521 2.27804L5.56277 7.00035L0.846579 11.7216C0.553843 12.0146 0.554096 12.4895 0.847146 12.7822C1.1402 13.075 1.61507 13.0747 1.90781 12.7817L7.15342 7.53045C7.44591 7.23765 7.44593 6.76325 7.15347 6.47042L1.90786 1.21806C1.61516 0.924976 1.14028 0.92467 0.847203 1.21738Z';
                default:
                    return '';
            }
        }
    }
};
</script>