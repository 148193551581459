<template>
    <div>
        <b-container
            v-if="loaded && androidCategories && iosCategories"
            class="paddings"
        >
            <div class="header">
                <h2 class="sign">
                    Vulnerabilities
                </h2>
                <div class="platform_selector">
                    <span
                        id="Android"
                        :class="{active : platform==='Android'}"
                        @click="selectPlatform"
                    >
                        Android
                    </span>
                    <span
                        id="iOS"
                        :class="{active : platform==='iOS'}"
                        @click="selectPlatform"
                    >
                        iOS
                    </span>
                </div>
            </div>
            <div
                v-if="androidCategories || iosCategories"
                class="vulnerability-list"
            >
                <VulnerabilityItem
                    v-for="(category, index) in
                        (platform ==='Android' && androidCategories) ||
                        (platform ==='iOS' && iosCategories)"
                    :id="category.descriptionTitle"
                    ref="vulnerabilities_list"
                    :key="index"
                    :item-id="makeAnchor(category.descriptionTitle)"
                    :severity="category.severity"
                    :position="category.position"
                    :title="category.descriptionTitle"
                    :full-description="category.descriptionText"
                    :remediation="category.descriptionRemediation"
                    :links="category.descriptionLinks"
                    :platform="platform"
                />
            </div>
        </b-container>
        <Spinner
            v-if="!loaded"
            class="spinner-block"
        />
    </div>
</template>

<script>
import VulnerabilityItem from './VulnerabilityItem';
import Spinner from '../../components/Spinner';
import {mapGetters} from 'vuex';
export default {
    components: {
        VulnerabilityItem,
        Spinner,
    },
    data() {
        return {
            loaded: false,
            vulnerabilityOpen: this.$route.query.vId,
            platform : 'Android',
        };
    },
    computed: {
        ...mapGetters({
            androidCategories: 'categories/androidCategories',
            iosCategories: 'categories/iosCategories',
        }),
    },
    watch: {
        async $route(to, from) {
            if(this.$route.hash) await this.findAndScrollToCategory(this.$route.hash);
        },
    },
    async beforeMount() {
        this.androidCategories.length === 0 && await this.$store.dispatch('categories/fetchAndroidCategories', categoryListAndroid);
        this.iosCategories.length === 0 && await this.$store.dispatch('categories/fetchIosCategories', categoryListIos);
        this.loaded = true;
    },
    async mounted() {
        if(this.$route.hash) await this.findAndScrollToCategory(this.$route.hash);
    },
    methods: {
        async findAndScrollToCategory(hash) {// Eq #Platform/Category_name
            const hashLink = hash.replace('#', '').split('/'); //Eq ['Platform','Category_name'] || ['Category_name']
            const platform = hashLink.length && hashLink[0]; //Eq 'Platform'

            if (platform === 'iOS' || platform === 'Android') {
                this.platform = platform;
            } else {
                this.platform = 'Android';
                this.$router.push({hash: `Android/${hash.replace('#', '')}`});
            }

            setTimeout(() => {
                const child = this.$refs.vulnerabilities_list;
                child && child.forEach(item => {
                    const id = hashLink.length > 1 ? hashLink.join('/') : this.platform + '/' + hash; //Eq Platform/Category_name
                    if (item.itemId === id) {
                        const el = document.getElementById(`${id}`);
                        el.click();
                        el.scrollIntoView();
                    }
                });
            }, 0);
        },
        makeAnchor(str) {
            return this.platform + '/' + encodeURIComponent(str)
                .replace(/%20/g, '_');
        },
        selectPlatform(event) {
            this.platform = event.target.id;
        },
    },
};

</script>

<style scoped lang="scss">

    .sign {
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 120%;
        color: #242424;
        margin-right: 20px;
    }

    .paddings {
        padding: 100px 100px 78px;
    }

    .vulnerability-list {
        margin-top: 75px;
    }

    .header{
        display: flex;
        align-items: center;
    }

    .platform_selector span:nth-child(1),
    .platform_selector span:nth-child(2)
    {
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: #B2B9C4;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-left: 30px;
        cursor: pointer;
    }

    .active{
        color : #3180F6 !important;
    }

    @media (max-width: 768px) {
        .vulnerability-list{
            padding-top: 50px;
        }
        .header{
           display : block;
        }

        .platform_selector {
            margin-top: 20px;
            span {
                &:nth-child(1){
                    margin-left: 0;
                }
            }
        }
    }
    @media (max-width: 576px) {
        .paddings {
            padding: 0;
        }
        .sign ,
        .platform_selector{
            font-size: 30px;
            margin: 20px 0 0 20px;
        }
    }
</style>
