<template>
    <div>
        <div
            v-if="isDesktop"
            :class=" {
                desktopCard:isDesktop,
                desktopCardWidth: isDesktop,
                expertTeam: card.title === 'Expert Team',
                codeControl: card.title === 'Code Control',
                earlyChecks: card.title === 'Early Checks',
                diverseAnalysis: card.title === 'Diverse Analysis',
                expansiveRules: card.title === 'Expansive Rules'
            }"
            @mouseover="handleHover"
            @mouseleave="handleLeave"
        >
            <div class="content">
                <span class="desktopCard__title">
                    {{ card.title }}
                </span>
                <span
                    :class="{ descriptionText: isHovered, blockClosed: !isHovered }"
                >
                    {{ card.description }}
                </span>
                <button
                    class="get-started-btn"
                    :class="{ activeButton: isClicked, passiveButton: !isClicked }"
                    @mousedown="click"
                    @mouseup="click"
                    @click="requestDemo"
                >
                    {{ buttonText }}
                    <TopArrowIcon
                        :clicked="!isClicked"
                        class="arrow-icon"
                    />
                </button>
            </div>
        </div>
        <div
            v-else
            :class=" {
                mobileCard: isMobile,
                desktopCard:isMiddleDesktopSize,
                middleCardWidth: isMiddleDesktopSize,
                expertTeam: card.title === 'Expert Team',
                codeControl: card.title === 'Code Control',
                earlyChecks: card.title === 'Early Checks',
                diverseAnalysis: card.title === 'Diverse Analysis',
                expansiveRules: card.title === 'Expansive Rules'
            }"
        >
            <div class="content">
                <span
                    :class="{ mobileCard__title: isMobile, desktopCard__title: isMiddleDesktopSize }"
                >
                    {{ card.title }}
                </span>
                <span
                    :class="{ descriptionText: isMiddleDesktopSize || isMobile }"
                >
                    {{ card.description }}
                </span>
                <button
                    class="get-started-btn"
                    :class="{ activeButton: isMiddleDesktopSize || isMobile, passiveButton: isClicked }"
                    @mousedown="click"
                    @mouseup="click"
                    @click="requestDemo"
                >
                    {{ buttonText }}
                    <TopArrowIcon
                        :clicked="isClicked"
                        class="arrow-icon"
                    />
                </button>
            </div>
        </div>
    </div>
</template>
<script>

import TopArrowIcon from '../../../assets/iconTemplates/TopArrowIcon.vue';

export default {
    components: {
        TopArrowIcon,
    },
    props:{
        card:{
            type:Object,
            required:true,
            default:() => ({}),
        },
        currentIndex:{
            type: Number,
            default: NaN,
        },

    },
    data() {
        return {
            windowWidth: window.innerWidth,
            buttonText: 'Get Started',
            isHovered: false,
            isClicked: false,
        };
    },
    computed: {
        isDesktop() {
            return this.windowWidth > 1620;
        },
        isMiddleDesktopSize() {
            return this.windowWidth > 768 && !this.isDesktop;
        },
        isMobile() {
            return this.windowWidth <= 768;
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        handleHover() {
            this.isHovered = true;
            this.sendCardId();
            this.isClicked = true;
        },
        handleLeave() {
            this.isHovered = false;
            this.sendCardId();
            this.isClicked = false;
        },
        sendCardId() {
            this.$emit('start-scroll-by-card', this.currentIndex);
        },
        click() {
            this.isClicked = !this.isClicked;
        },
        requestDemo() {
            this.$router.push('/request-demo');
        },
    },

};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/variables.scss";
@import "../../../assets/css/variables/variablesForMinimizeRisks.scss";
@import "../../../assets/css/fonts";



.desktopCard {
    position: relative;
    height: 404px;
    border-radius: 40px;
    display: flex;
    border: 2px solid var(--stroke, #E8E7EF);
    background-size: cover;
    background-position: -25px 0;
    transition: width 350ms;

    &__title {
        text-align: left;
        margin: 15px 25px 0 0;
        @include Aspekta-font(700, 34px, normal, $general-black);
    }
}
.desktopCardWidth {
    width: 305px;
    &:hover {
        width: 750px;
    }
}
.middleCardWidth {
    margin-left: $dynamic-middle-desctop-card-margin-left;
    width: 750px;
}
.mobileCard {
    width: 285px;
    height: 434px;
    border-radius: 40px;
    display: flex;
    border: 2px solid var(--stroke, #E8E7EF);
    background-size: cover;

    &__title {
        text-align: left;
        @include Aspekta-font(700, 24px, normal, $general-black);
    }
}
.descriptionText {
    @include Aspekta-font(400, 18px, normal, #797E86);
    text-align: left;
    height: 91px;
    width: 292px;
}
.blockClosed {
    display: none;
}
.content {
    display: flex;
    height: 294px;
    flex-direction: column;
    justify-content: space-between;
    margin: 40px 50px 50px;
}


.get-started-btn {
    display: flex;
    margin-top: 66px ;
    @include Aspekta-font(700, 16px, normal, $general-black);
    border-radius: 30px;
    padding: 15px 15px 15px 30px;
    text-decoration: none;
    align-items: center;
    max-width: 197px;
    border: 2px solid #f6f6f900;

    &:hover {
        background: linear-gradient(0deg, rgba(255, 122, 8, 0.70) 0%, rgba(255, 122, 8, 0.70) 100%), var(--white, #FFF);
    }

    &:active {
        border: 2px solid var(--stroke, #E8E7EF);
        background: var(--white, #FFF);
        padding-left: 30px;
    }
}

.activeButton {
    color: #FFFFFF;
    background-color: $new-orange-color;
}

.passiveButton {
    color: $general-black;
    background-color: #FFF;
    padding-left: 0;
}

.arrow-icon {
    width: 30px;
    height: 30px;
    margin-left: 15px;
}


.expertTeam {
    background: url('../../../assets/images/active-cards/expert_team.svg');
}
.expansiveRules {
    background: url('../../../assets/images/active-cards/expansive_rules.svg');
}
.diverseAnalysis {
    background: url('../../../assets/images/active-cards/diverse_analysis.svg');
}
.earlyChecks {
    background: url('../../../assets/images/active-cards/early_checks.svg');
}
.codeControl {
    background: url('../../../assets/images/active-cards/code_control.svg');
}

@media (max-width: 1620px ) {
    .get-started-btn:active {
        color: $general-black;
    }
}

@media (max-width: 769px ) {
    .get-started-btn {
        margin-top: 16px;
        width: 164px;
        height: 52px;
        font-size: 14px;

        &:active {
            color: $general-black;
        }
    }
    .arrow-icon {
        width: 22px;
        height: 22px;
        margin-left: 8px;
    }
    .content {
        margin: 40px 0 40px 40px;
        max-width: 214px;
        height: 220px;
    }
    .descriptionText {
        width: auto;
        font-size: 14px;
    }
    .expertTeam {
        background: url('../../../assets/images/active-cards/expert_team_mob.svg');
    }
    .expansiveRules {
        background: url('../../../assets/images/active-cards/expansive_rules_mob.svg');
    }
    .diverseAnalysis {
        background: url('../../../assets/images/active-cards/diverse_analysis_mob.svg');
    }
    .earlyChecks {
        background: url('../../../assets/images/active-cards/early_checks_mob.svg');
    }
    .codeControl {
        background: url('../../../assets/images/active-cards/code_control_mob.svg');
    }
}

</style>


