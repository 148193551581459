<template>
    <transition name="dropdown-content">
        <div
            v-if="active"
            class="dropdown-content_items"
        >
            <slot />
        </div>
    </transition>
</template>

<script>
export default {
    name: "AppDropdownContent",
    inject: ["sharedState"],
    computed: {
        active() {
            this.$emit("toggle",this.sharedState.active)
            return this.sharedState.active;
        }
    }
};
</script>

<style lang="scss">
.dropdown-content_items {
  display: block;
  background-color: white;
  position : relative;
  border-radius: 20px;
  border : 1px solid #F0F0F0;
  box-shadow: 0px 4px 15px rgba(56, 70, 77, 0.1);
  z-index:99;
  position : relative;
  max-height: 20rem;
  overflow-y: scroll;
  scrollbar-width: none;
}
.dropdown-content_items::-webkit-scrollbar {
  display: none;
}

.dropdown-content-enter-active,
.dropdown-content-leave-active {
  transition: all 0.5s;
}

.dropdown-content-enter,
.dropdown-content-leave-to {
  opacity: 0;
  transform: translateY(-60px);
}
</style>
