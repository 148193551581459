<template>
    <div :class="cardClass">
        <div class="card-content">
            <div class="card-text">
                <div class="card-title">
                    <p>{{ cardData.title }}</p>
                </div>
                <div class="card-description">
                    <p>{{ cardData.description }}</p>
                </div>
            </div>
            <div
                v-if="cardData.isBig"
                class="big-card-img"
            >
                <img
                    :src="cardData.imgSrc"
                    :alt="cardData.altText"
                    :class="cardData.imgClass"
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        cardData: Object,
    },
    computed: {
        cardClass() {
            return [
                'benefit-card',
                this.cardData.isBig ? 'big-card' : '',
                this.cardData.additionalClasses || '',
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/variables";
@import "../../../assets/css/fonts";

    .benefit-card {
        display: flex;
        flex-direction: column;
        flex: 1 0 calc(33.333% - 1.5rem);
        border-radius: 40px;
        background-color: #E0E8F1;
    }

    .big-card {
        flex: 3 0 calc(66.666% - 1.5rem);;
    }

    .big-card-img {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .card-content {
        display: flex;
    }

    .card-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 9rem 3.1rem 6rem 3.1rem;
    }

    .card-title {
        @include Aspekta-font(700, $dynamic-main-protection-card-title-font-size, normal, #0C0D0E);
        margin-bottom: 0rem;
        letter-spacing: 0em;
    }

    .card-description {
        @include Aspekta-font(400, $dynamic-main-protection-card-description-font-size, normal, #797E86);
        letter-spacing: 0em;
        text-align: justify;
    }

    .streamline-money-icon {
        width: $dynamic-main-protection-streamline-card-icon-width;
        height: $dynamic-main-protection-streamline-card-icon-height;
    }

    .arrows-icon {
        width: $dynamic-main-protection-arrows-card-icon-width;
        height: $dynamic-main-protection-arrows-card-icon-height;
    }

    .curved-line-icon {
        // width: $dynamic-main-protection-curved-line-card-icon-width;
        height: $dynamic-main-protection-curved-line-card-icon-height;
    }

    .arrow-card .card-text {
        padding-right: 4.1rem;
        padding-top: 9.3rem;
    }

    .tips-card .card-text {
        padding-right: 1.7rem;
    }

    .save-card .card-text {
        padding-right: 5.8rem;
    }

    @media (max-width: 1600px) and (min-width: 600px) {
        .card-text {
            padding: 3rem 3.5rem 2rem 3.5rem;
        }

        .arrow-card .card-text {
        padding-right: 1.5rem;
        }

        .tips-card .card-text {
            padding-right: 1.5rem;
        }

        .save-card .card-text {
            padding-right: 1.5rem;
        }
    }

    @media (max-width: 1100px) and (min-width: 600px) {

        .benefit-card {
            flex: 1 0 calc(100%);
            overflow: hidden;
        }

    } 

    @media (max-width: 1100px) and (min-width: 320px) {

        .coverage-card {
            order: -1;
        }
                    
        .save-card {
            order: 2;
        }

        .risks-card {
            order: 3;
        }

        .arrow-card {
            order: 4;
        }

        .improve-card {
            order: 5;
        }

        .tips-card {
            order: 6;
        }

    } 

    @media (max-width: 600px) { 

        .arrow-card .card-text {
            padding-right: 2.5rem;
        }

        .tips-card .card-text {
            padding-right: 2.5rem;
        }

        .save-card .card-text {
            padding-right: 2.5rem;
        }

        .benefit-card {
            border-radius: 24px;
        }

        .card-content {
            flex-direction: column;
        }

        .card-text {
            padding: 2.5rem 2.5rem 1.5rem 2.5rem;
        }
        
        .big-card-text {
            padding-bottom: 0rem;
        }

        .big-card-img {
            position: relative;
            align-self: flex-end;
            overflow: hidden;
            border-radius: 24px;
        }

        .save-card .card-text {
            padding-bottom: 0;
        }

        .arrow-card .card-text {
            padding-bottom: 0;
        }

        .tips-card .card-text {
            padding-bottom: 0;
        }

        .streamline-money {
            padding: 0%;
        }

        .streamline-money-icon {
            position: relative;
            top: 16px;
        }

        .curved-line-icon {
            transform: rotate(96deg);
            width: 114%;
        }
    }

</style>
