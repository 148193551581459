<template>
    <div class="mfa">
        <div class="mfa__title">
            Multi-factor authentication (MFA)
        </div>
        <div class="mfa__subtitle">
            To enhance the security of your Oversecured account, use multi-factor authentication (MFA).
            Signing in with MFA requires entering an authentication code from one of your MFA devices.
            Each user can assign up to 3 MFA devices.
        </div>
        <div class="mfa__header">
            <button class="mfa__assign-btn" @click="showDeviceNamePopup">Assign MFA device</button>
        </div>
        <table id="mfa__table" class="mfa__table">
            <thead v-if="mfaDevices && mfaDevices.length > 0">
                <tr>
                    <th>Device Name</th>
                    <th>Assigned</th>
                    <th>Last Used</th>
                    <th/>
                </tr>
            </thead>
            <tbody>
                <tr v-for="mfaDevice in mfaDevices" :key="mfaDevice.id">
                    <td>{{ mfaDevice.name }}</td>
                    <td v-if="mfaDevice.assigned"><b-icon icon="check-circle" class="icon"/></td>
                    <td v-else><b-icon icon="dash-circle" class="icon"/></td>
                    <td v-if="mfaDevice.lastUsedOn">{{ calculateDateForSettings(mfaDevice.lastUsedOn) }}</td>
                    <td v-else><b-icon icon="dash-circle" class="icon"/></td>
                    <td>
                        <button class="mfa__table-delete-btn"
                                @click="deleteMfaDevice(mfaDevice.id)"
                                @mouseenter="showLabel(mfaDevice.id)"
                                @mouseleave="showHintDeleteLabel = null"
                        >
                            <p v-show="showHintDeleteLabel !== null && showHintDeleteLabel === mfaDevice.id"
                               class="delete-token-btn-title"
                            >
                                Delete MFA Device
                            </p>
                            <img class="mfa__btn-delete"
                                 src="../../assets/images/myScans/icon_action_delete.svg"
                                 alt="icon_action_delete"
                            >
                        </button>
                    </td>
                </tr>
                <tr v-if="mfaDevices && mfaDevices.length === 0">
                    <td>
                        <h6 class="mfa__table-empty">No MFA Devices have been assigned yet</h6>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="isShowSpinner" class="mfa__spinner">
            <Spinner/>
        </div>
        <GeneralPopup
            :popup-id="'DeviceNamePopup'"
            title-text="Setup Device"
            :loading="loading"
            size="md"
        >
            <div>
                <div class="mfa__modal-item">
                    <div class="elem">
                        <div class="form-container">
                            <div class="text">Type the name of MFA Device below:</div>
                            <form class="modal-form modal-form-1" @submit.stop.prevent="handleDeviceNameModal">
                                <div class="modal-label">
                                    <input id="deviceName" ref="mfaCodeInput_1" v-model.trim="requiredData.deviceName"
                                           name="deviceName" class="modal-input" type="text"
                                           @input="checkKeyEvent"
                                           @focus="activeInputName = 'deviceName'"
                                           @blur="activeInputName = null"
                                    >
                                    <label for="deviceName" class="label"
                                           :class="{ activeInput: requiredData.deviceName !== '' || activeInputName === 'deviceName'}">
                                        MFA Device Name
                                    </label>
                                    <img v-if="requiredData.deviceName.length"
                                         class="mfa-input-check"
                                         src="../../assets/images/cross_grey.svg"
                                         alt="cross"
                                         @click="clearInputAndErrors('deviceName')"
                                    >
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <template v-slot:ok-button>
                <button style="padding: 12px 40px" class="mfa__assign-btn" @click="handleDeviceNameModal">Next</button>
            </template>
        </GeneralPopup>
        <GeneralPopup
            :popup-id="'AssignPopup'"
            title-text="Setup Device"
            :loading="loading"
            size="lg"
            @onHidden="cleanup"
        >
            <div>
                <div class="mfa__modal-item">
                    <div class="number">
                        <div class="icon-container">
                            <b-icon icon="circle-fill" class="icon"/>
                            <span class="icon-number">1</span>
                        </div>
                    </div>
                    <div class="elem">
                        <span class="text">Install a compatible application such as Google Authenticator, Duo Mobile, or Authy app on your mobile device or computer.</span>
                    </div>
                </div>
                <div class="mfa__modal-item">
                    <div class="number">
                        <div class="icon-container">
                            <b-icon icon="circle-fill" class="icon"/>
                            <span class="icon-number">2</span>
                        </div>
                    </div>
                    <div class="elem">
                        <div class="qrcode-container">
                            <div v-if="isShowQrcode === true && createdDevice && createdDevice.qrcode"
                                 class="qrcode-box">
                                <img :src="createdDevice.qrcode" class="qrcode-img" alt="qrcode">
                            </div>
                            <div v-else class="qrcode-box">
                                <a href="#" @click.prevent="showQrCode">Show QR code</a>
                            </div>
                        </div>
                        <div class="text qrcode-text">
                            Open your authenticator app, choose Show QR code on this page, then use the app to scan the
                            code.
                            Alternatively, you can type a secret key.
                            <TooltipClickable
                                position="bottom"
                                mobile-position="bottom"
                                :tooltip="createdDevice && createdDevice.secret ? createdDevice.secret : ''"
                                :show="isShowSecret"
                            >
                                <a v-if="isShowSecret" href="#" @click.prevent="toggleSecret">
                                    Hide secret key
                                </a>
                                <a v-else href="#" @click.prevent="toggleSecret">
                                    Show secret key
                                </a>
                            </TooltipClickable>
                        </div>
                    </div>
                </div>
                <div class="mfa__modal-item">
                    <div class="number">
                        <div class="icon-container">
                            <b-icon icon="circle-fill" class="icon"/>
                            <span class="icon-number">3</span>
                        </div>
                    </div>
                    <div class="elem">
                        <div class="form-container">
                            <div class="text">Type two consecutive MFA codes below:</div>
                            <form class="modal-form modal-form-2" @submit.stop.prevent="handleAssignModal">
                                <div class="text">1. Enter a code from your virtual app below.</div>
                                <div class="modal-label">
                                    <input id="otp1" ref="mfaCodeInput_1" v-model.trim="requiredData.otp1"
                                           name="otp1" class="modal-input" type="text"
                                           @input="checkKeyEvent"
                                           @focus="activeInputName = 'otp1'"
                                           @blur="activeInputName = null"
                                    >
                                    <label for="otp1" class="label"
                                           :class="{ activeInput: requiredData.otp1 !== '' || activeInputName === 'otp1'}">
                                        MFA Code 1
                                    </label>
                                    <img v-if="requiredData.otp1.length"
                                         class="mfa-input-check"
                                         src="../../assets/images/cross_grey.svg"
                                         alt="cross"
                                         @click="clearInputAndErrors('otp1')"
                                    >
                                </div>
                                <div class="text">2. Wait 30 seconds, and enter a second code entry.</div>
                                <div class="modal-label">
                                    <input id="otp2" ref="mfaCodeInput_1" v-model.trim="requiredData.otp2"
                                           name="otp2" class="modal-input" type="text"
                                           @input="checkKeyEvent"
                                           @focus="activeInputName = 'otp2'"
                                           @blur="activeInputName = null"
                                    >
                                    <label for="otp2" class="label"
                                           :class="{ activeInput: requiredData.otp2 !== '' || activeInputName === 'otp2'}">
                                        MFA Code 2
                                    </label>
                                    <img v-if="requiredData.otp2.length"
                                         class="mfa-input-check"
                                         src="../../assets/images/cross_grey.svg"
                                         alt="cross"
                                         @click="clearInputAndErrors('otp2')"
                                    >
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <template v-slot:ok-button>
                <button style="padding: 12px 40px" class="mfa__assign-btn" @click="handleAssignModal">Assign</button>
            </template>
        </GeneralPopup>
    </div>
</template>
<script>
import Spinner from '@/components/Spinner';
import {mapGetters} from 'vuex';
import {BIcon} from 'bootstrap-vue';
import {
    checkFormInputsForCompleteness,
    checkFormInputsForErrors,
    checkFormInputsLength,
    calculateDateForSettings,
    hideErrors
} from '@/services/functions';
import closeModal from '../../assets/images/closeModal.svg';
import GeneralPopup from '@/components/popups/GeneralPopup.vue';
import {mfaDeviceNotification} from '@/services/notificationService/notificationService';
import {GetStartedStepsEnum} from '../../utils/constants/common';
import TooltipClickable from '@/components/report/components/TooltipClickable.vue';

export default {
    components: {
        TooltipClickable,
        Spinner,
        GeneralPopup,
    },
    data() {
        return {
            showHintDeleteLabel: null,
            loading: false,
            isShowSpinner: false,
            isShowQrcode: false,
            isShowSecret: false,
            requiredData: {
                deviceName: '',
                otp1: '',
                otp2: '',
            },
            activeInputName: null,
            closeModal,
            GetStartedStepsEnum,
        };
    },
    computed: {
        ...mapGetters({
            mfaDevices: 'mfaDevices/getMfaDevices',
            createdDevice: 'mfaDevices/getCreatedDevice'
        }),
    },
    async beforeMount() {
        try {
            this.isShowSpinner = true;
            await this.$store.dispatch('mfaDevices/fetchMfaDevices');
            this.isShowSpinner = false;
        } catch (error) {
            this.isShowSpinner = false;
            console.log(error);
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        async showDeviceNamePopup() {
            this.isShowSpinner = true;
            this.cleanup();
            this.$bvModal.show('general-popupDeviceNamePopup');
            this.isShowSpinner = false;
        },
        async showAssignPopup() {
            this.isShowSpinner = true;
            // await this.createDevice();
            if (this.createdDevice) {
                this.$bvModal.show('general-popupAssignPopup');
            } else {
                //todo show error message
                await this.$store.dispatch('mfaDevices/clearTempData');
                console.log('Failed to create device');
            }
            this.isShowSpinner = false;
        },
        /**
         * This method is called when the user scrolls the page. It hides the hint labels.
         */
        handleScroll() {
            this.showHintDeleteLabel = null;
        },
        /**
         * This method is called when the user hovers on the "Delete" button. It shows the hint label.
         * @param {string} id - id of the token.
         */
        showLabel(id) {
            this.showHintDeleteLabel = id;
        },
        clearInputAndErrors(inputField) {
            this.requiredData.deviceName = '';
            this.requiredData.otp1 = '';
            this.requiredData.otp2 = '';
            hideErrors(['mfaCodeError', 'mfaCodeLengthError']);
            document.getElementById(inputField).style.border = '1px solid #F0F0F0';
        },
        cleanup() {
            this.isShowQrcode = false;
            this.isShowSecret = false;
            this.requiredData.deviceName = '';
            this.requiredData.otp1 = '';
            this.requiredData.otp2 = '';
            this.$store.dispatch('mfaDevices/clearTempData');
        },
        async handleDeviceNameModal() {
            // checkFormInputsForErrors(this.requiredData, 'mfa__modal-input--error');
            // checkFormInputsLength(this.requiredData, 'mfa__modal-input--error');
            //
            // if (checkFormInputsForCompleteness(this.requiredData)) {
            await this.createDevice();
            await this.showAssignPopup();
            // }
        },
        handleAssignModal() {
            // checkFormInputsForErrors(this.requiredData, 'mfa__modal-input--error');
            // checkFormInputsLength(this.requiredData, 'mfa__modal-input--error');
            //
            // if (checkFormInputsForCompleteness(this.requiredData)) {
            this.assignDevice();
            // }
        },
        async createDevice() {
            this.loading = true;
            try {
                await this.$store.dispatch('mfaDevices/createDevice', {
                    name: this.requiredData.deviceName
                });
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        async assignDevice() {
            this.loading = true;
            try {
                const {assigned, error} = await this.$store.dispatch('mfaDevices/assignDevice', {
                    id: this.createdDevice.id,
                    otp1: this.requiredData.otp1,
                    otp2: this.requiredData.otp2
                });
                this.loading = false;
                if (assigned) {
                    this.$bvModal.hide('general-popupAssignPopup');
                    this.mfaDeviceNotification('assigned');
                } else {
                    console.log(error);
                    this.mfaDeviceNotification('assignFail');
                }

            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        async deleteMfaDevice(id) {
            try {
                this.isShowSpinner = true;
                await this.$store.dispatch('mfaDevices/deleteMfaDevice', id);
                this.isShowSpinner = false;
                //todo check if deleted and send fail message if not
                this.mfaDeviceNotification('removed');
            } catch (error) {
                console.log(error);
            }
        },
        showQrCode() {
            this.isShowQrcode = true;
        },
        toggleSecret() {
            this.isShowSecret = !this.isShowSecret;
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id;
        },
        mfaDeviceNotification,
        calculateDateForSettings
    }
};
</script>
<style lang="scss">
@import "../../assets/css/variables";

.mfa {
    position: relative;
    min-height: 100px;
    width: 100%;

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;

        div.spinner-wrapper {
            width: 100%;
            height: 100%;
            padding: 0 !important;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    &__title {
        font-family: Aspekta, serif;
        font-weight: bold;
        font-size: 18px;
        color: $main-text
    }

    &__subtitle {
        font-family: Aspekta, serif;
        font-weight: normal;
        font-size: 14px;
        color: $secondary-text;
        text-align: justify;
    }

    &__assign-btn {
        background: #FF7A08;
        border: 2px solid #FF7A08;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Aspekta, serif;
        font-style: normal;
        letter-spacing: 0.54px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        margin-left: auto;
        max-width: 200px;
        width: 100%;
        padding: 12px;
        transition: all .3s;
        margin-top: 10px;

        &:hover {
            background-color: #fff;
            color: #FF7A08;
            box-shadow: 0 8px 16px 0 rgba(255, 180, 0, 0.24);
        }

        &.ghost {
            color: #949DB8;
            background-color: #fff;
            border-color: #E3E6ED;

            &:hover {
                box-shadow: 0 12px 16px 0 rgba(178, 185, 195, 0.12);
            }
        }

    }

    &__modal-title {
        text-align: center;
        font-family: Aspekta, serif;
        font-weight: bold;
        font-size: 18px;
        color: #242424;
        margin: 12px 0 30px 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
    }

    &-input-check {
        position: absolute;
        bottom: 16px;
        right: 20px;
        cursor: pointer;
    }

    &-error {
        width: 100%;
        max-width: 831px;
        position: relative;
    }

    &-error-cross {
        position: absolute;
        top: -27px;
        right: 20px;
    }

    &-error-text {
        position: absolute;
        top: 0;
        left: 0;
        font-family: Aspekta, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 150%;
        color: #FB8D62;
        margin: 0;
    }

    &__modal-item {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;

        .number {
            flex: 1 1 20%;

            .icon-container {
                display: inline-flex;
                position: relative;
                width: 100%;
                align-items: center;
                justify-content: center;

                .icon {
                    width: 40px;
                    height: 40px;
                    color: #FFB400;
                }

                .icon-number {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 20px;
                    font-weight: bold;
                    color: $main-text;
                }
            }

        }

        .elem {
            display: flex;
            flex: 1 1 80%;

            .qrcode-container {
                flex: 1 1 40%;

                .qrcode-box {
                    display: flex;
                    width: 200px;
                    height: 200px;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #FFB400;

                    .qrcode-img {
                        width: 200px;
                        height: 200px;
                    }
                }
            }

            .text {
                margin: 5px 5px 15px;
            }

            .qrcode-text {
                flex: 1 1 60%;
            }
        }

        .elem > .form-container {
            display: block;
            flex: 1 1 80%;

            .modal-form {
                .modal-label {
                    position: relative;
                    margin: 15px 5px;
                }

                .modal-input {
                    background: #FFFFFF;
                    border: 1px solid #F0F0F0;
                    box-sizing: border-box;
                    border-radius: 5px;
                    font-family: Aspekta, serif;
                    font-weight: normal;
                    font-size: 14px;
                    color: #242424;
                    padding: 10px 30px 10px 10px;
                    min-height: 40px;
                    width: 100%;

                    &--error {
                        border: 1px solid #FB8D62;
                        border-radius: 5px;
                    }
                }
            }

            .modal-form-2 {
                .modal-label {
                    width: 50%;
                }
            }

        }
    }

    &__table-empty {
        text-align: center;
        font-family: Aspekta, serif;
        font-weight: bold;
        font-size: 20px;
        color: #242424;
    }

    #mfa__table {
        background-color: #FFFFFF;
        width: 100%;
        border-radius: 20px;
    }

    #mfa__table thead tr {
        height: 60px;
        font-family: Aspekta, serif;
        font-weight: normal;
        font-size: 14px;
        color: #B2B9C4;
        border-bottom: rgba(178, 185, 196, .2) solid 1px;
    }

    #mfa__table tbody tr {
        height: 85px;
        font-family: Aspekta, serif;
        font-weight: normal;
        font-size: 18px;
        color: #242424;
        border-bottom: rgba(178, 185, 196, .2) solid .5px;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            box-shadow: 0 4px 15px rgba(56, 70, 77, 0.04);
        }
    }

    #mfa__table thead tr th:nth-child(1),
    #mfa__table tbody tr td:nth-child(1) {
        padding-left: 56px;
        box-sizing: border-box;
        width: 40%;
        word-break: break-word;
    }

    #mfa__table thead tr th:nth-child(2),
    #mfa__table thead tr th:nth-child(3),
    #mfa__table tbody tr td:nth-child(2),
    #mfa__table tbody tr td:nth-child(3) {
        width: 25%;
        text-align: center;
    }

    .mfa__btn-delete {
        visibility: hidden;
    }

    #mfa__table tbody tr {
        &:hover .mfa__btn-delete {
            visibility: visible;
        }
    }
}

#mfa__table tbody tr td:nth-child(4) {
    button:nth-child(1) {
        position: relative;

        .delete-token-btn-title {
            @extend .btn-title;
            visibility: visible;
            width: 130px !important;
            left: 42%;
        }
    }
}

#mfaCodeError,
#mfaCodeLengthError {
    display: none;
}

@media (max-width: 576px) {
    .mfa {
        &__header {
            margin: 12px 20px;
        }

        &__assign-btn {
            margin-right: auto;
            border: 0;
            margin-top: 10px;

            &:focus {
                border: 0;
            }
        }

        #mfa__table thead tr {
            height: 60px;
        }

        #mfa__table tbody tr {
            height: 60px;
        }

        #mfa__table thead tr th:nth-child(1),
        #mfa__table tbody tr td:nth-child(1) {
            width: 50%;
            padding-left: 20px;
        }

        #mfa__table thead tr th:nth-child(2),
        #mfa__table thead tr th:nth-child(3),
        #mfa__table tbody tr td:nth-child(2),
        #mfa__table tbody tr td:nth-child(3) {
            width: 25%;
        }
    }
}
</style>
