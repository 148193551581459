import Vue from 'vue'

export const state = () => ({
    userCoupons: null,
    activationCodeError: null,
    isActivationCodeSended: false
})

export const mutations = {
    setUserCoupons: (state, coupons) => {
        state.userCoupons = {
            count: coupons.count,
            rows: coupons.rows.map(item => {
                return {
                    ...item,
                    showCode: false
                }
            })
        }
    },
    /**
     * we get response about activation code from websocket
     * response send to all opened browser's tabs
     * so we need some "isActivationCodeSended" flag
     * to show alert window only in current browser's tab
     */
    setActivationCodeError: (state, status) => {
        if (state.isActivationCodeSended && status) {
            state.activationCodeError = status
            state.isActivationCodeSended = false
        } else if (!status) {
            state.activationCodeError = null
        }
    },
    setIsActivationCodeSended: (state, status) => {
        state.isActivationCodeSended = status
    }
}

export const actions = {
    async getUserCoupons({commit}, data) {
        try {
            const response = await Vue.axios.get(`/private/ui/coupons/user/coupons?page=${data.page}&limit=${data.limit}`)

            commit('setUserCoupons', response.data)
        } catch (error) {
            commit('setUserCoupons', null)
            console.log(error)
        }
    },
    async activateCodeAction({commit}, data) {
        commit('setIsActivationCodeSended', true)
        if (!data.activationCode) return commit('setActivationCodeError', {message: 'Activation code is missing', statusCode: 500, status: 'error'})
        try {
            await Vue.axios.post(`/private/ui/coupons/activate_code`, data)
        } catch (error) {
            if (error.response.status === 500 && error.response.data === 'Invalid code') {
                commit('setActivationCodeError', {message: error.response.data, statusCode: error.response.status, status: 'error'})
            }
        }
    },
}

export const getters = {
    userCoupons: s => s.userCoupons,
    activationCodeError: s => s.activationCodeError,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
