<template>
    <div class="main-protection">
        <div class="main-protection__container">
            <div class="main-protection__content">
                <div class="main-protection__text">
                    <div class="main-protection__title">
                        <p>Advanced Protection and Efficiency Boost</p>
                    </div>
                    <div class="main-protection__sub-title">
                        <p>Streamlined vulnerability management and resource optimization</p>
                    </div>
                </div>
                <div class="main-protection__benefits">
                    <AdvancedProtectionCard :card-data="coverageCardData" />
                    <AdvancedProtectionCard :card-data="saveMillionsCardData" />
                    <AdvancedProtectionCard :card-data="saveTimeCardData" />
                    <AdvancedProtectionCard :card-data="minimizeRisksCardData" />
                    <AdvancedProtectionCard :card-data="improveTeamWorkCardData" />
                    <AdvancedProtectionCard :card-data="getTipsCardData" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AdvancedProtectionCard from './AdvancedProtectionCard.vue';
import streamlineMoney from '../../../assets/images/main/advanced-protection/advanced-streamline-money-icon.svg';
import arrows from '../../../assets/images/main/advanced-protection/advanced-arrows.svg';
import curvedLine from '../../../assets/images/main/advanced-protection/advanced-curved-line.svg';

export default {
    components: {
        AdvancedProtectionCard,
    },

    data() {
        return {
            coverageCardData: {
                title: '100% Vulnerability Coverage',
                description: 'We automatically check mobile apps for all known vulnerabilities, like unauthorized code execution, file theft, and cross-site scripting',
                additionalClasses: 'coverage-card',
            },
            saveMillionsCardData: {
                title: 'Save $Millions with Automation',
                description: 'An automated scanning solution surpasses the capabilities of an average security specialist in every aspect. With faster and more effective scanning, our automation saves companies an average of $1.9 million annually on their security team expenses',
                isBig: true,
                imgSrc: streamlineMoney,
                altText: 'Save $Millions with Automation',
                imgClass: 'streamline-money-icon',
                additionalClasses: 'save-card',
            },
            saveTimeCardData: {
                title: 'Save 40% of Your Team\'s Time for Other Priorities',
                description: 'Our solution automates the entire vulnerability research process, saving your organization valuable time and resources',
                isBig: true,
                imgSrc: arrows,
                altText: 'Save 40% of Your Team\'s Time for Other Priorities',
                imgClass: 'arrows-icon',
                additionalClasses: 'arrow-card',
            },
            minimizeRisksCardData: {
                title: 'Minimize Risks to the Minimum',
                description: 'Our Integration keeps your brand safe and your customers loyal',
                additionalClasses: 'risks-card',
            },
            improveTeamWorkCardData: {
                title: 'Improve Your Team Work',
                description: 'We help you prioritize which vulnerabilities to address first, so your team can focus on the most critical issues and allocate resources efficiently',
                additionalClasses: 'improve-card',
            },
            getTipsCardData: {
                title: 'Get Tips on How to Fix What You Find',
                description: 'With our scanning solution, we pinpoint vulnerabilities and offer practical suggestions to fix and resolve them, making vulnerability management easier',
                isBig: true,
                imgSrc: curvedLine,
                altText: 'Get Tips on How to Fix What You Find',
                imgClass: 'curved-line-icon',
                additionalClasses: 'tips-card',
            },
        };
    },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/variables";
@import "../../../assets/css/fonts";

.main-protection {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin: 7% 10%;
        justify-content: center;
        align-items: center;
        gap: 2.5rem;
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: baseline;
    }

    &__title {
        @include Aspekta-font(700, $dynamic-main-risks-title-font-size, 120%, #0C0D0E);
        letter-spacing: 0em;
    }

    &__sub-title {
        @include Aspekta-font(400, $dynamic-main-limitations-sub-title-font-size, normal, #797E86);
        letter-spacing: 0em;
    }

    &__benefits {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 1.3rem;
    }

}

    @media (max-width: 768px) {
        .main-protection {
            &__content {
                margin: 5rem 1rem;
                gap: 3rem;
            }

            &__title {
                line-height: 130%;
            }

            &__benefits {
                flex-direction: column;
                gap: 0.6rem;
            }
        }
    }
</style>
