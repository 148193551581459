<template>
    <div class="dropzone__canvas">
        <img 
            src="@/assets/images/newScan/drop-icon.svg" 
            alt="" 
        >
        <div class="dropzone__canvas-header">
            Drop your Android or iOS files here
        </div>
        <div class="dropzone__canvas-available-block">
            <div class="dropzone__canvas-android-block">
                <p class="dropzone__canvas-platform-text">Android</p>
                <span class="dropzone__canvas-platform-sign">.apk</span>
                <span class="dropzone__canvas-platform-sign">.aab</span>
            </div>
            <div class="dropzone__canvas-ios-block">
                <p class="dropzone__canvas-platform-text">iOS (XCode project)</p>
                <span class="dropzone__canvas-platform-sign">.zip</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropzoneCanvas',
}
</script>

<style lang="scss">
@import "@/assets/css/variables";

.dropzone__canvas {
    position: absolute;
    width: 100%;
    height: calc(100% - 166px);
    border-radius: 20px;
    top: 0;
    left: 0;
    border: 2px dashed #ADCCFB;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    &-header {
        font-size: 22px;
        font-weight: 600;
    }
    &-available-block {
        font-weight: normal;
        display: flex;
        justify-content: center;
        gap: 30px;
    }
    &-ios-block, &-android-block {
        text-align: center;
    }
    &-platform-text {
        font-size: 12px;
        line-height: 150%;
        color: $ghost-text;
        margin: 0 0 12px 0;
    }
    &-platform-sign {
        font-size: 14px;
        color: $main-text;
        background: #F5F7FA;
        border-radius: 5px;
        padding: 3px 6px;
        margin-right: 8px;
    }
}

@media (max-width: 900px) {
    .dropzone__canvas {
        height: 100%;
    }
}
</style>
