<template>
	<div class="pricing-page">
		<b-container>
			<div class="pricing">
				<div>
					<h1 class="pricing__title">Plans for everyone</h1>
					<p class="pricing__subtitle">Choose a plan that’s right for your business.</p>
				</div>
				<div class="tariff-plans">
					<div class="tariff-plans__block">
						<div class="tariff-plans__icon">
							<img src="../../assets/images/Union.jpg" alt="">
						</div>
						<h2 class="tariff-plans__title">Single Scan</h2>
						<hr class="tariff-plans__line">
						<div class="tariff-plans__box">
							<div class="icon">
								<img src="../../assets/images/check-mark.svg" alt="Oversecured">
							</div>
							<p class="tariff-plans__text">Designed for small teams and startups</p>
						</div>
						<div class="tariff-plans__box">
							<div class="icon">
								<img src="../../assets/images/check-mark.svg" alt="Oversecured">
							</div>
							<p class="tariff-plans__text">Scan any application</p>
						</div>
						<div class="tariff-plans__box">
							<div class="icon">
								<img src="../../assets/images/check-mark.svg" alt="Oversecured">
							</div>
							<p class="tariff-plans__text">Multiple scans are available via Scan Packs</p>
						</div>
						<button class="tariff-plans__link-btn-wrapper tariff-plans__link-btn--black ">
							<b-nav-item :to="{ path: '/scan/create'}">
								Try it now
							</b-nav-item>
						</button>
					</div>

					<div class="tariff-plans__block">
						<div class="tariff-plans__block-triangle">
							<svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M51 0H0L51 51V0Z" fill="#FFB400"/>
							</svg>
						</div>
						<div class="tariff-plans__icon">
							<img src="../../assets/images/integration-icon.svg" alt="Integration">
						</div>
						<h2 class="tariff-plans__title tariff-plans__title--yellow">Integration</h2>
						<hr class="tariff-plans__line">
						<div class="tariff-plans__box">
							<div class="icon">
								<img src="../../assets/images/check-mark.svg" alt="Oversecured">
							</div>
							<p class="tariff-plans__text">Designed for enterprise-level customers</p>
						</div>
						<div class="tariff-plans__box">
							<div class="icon">
								<img src="../../assets/images/check-mark.svg" alt="Oversecured">
							</div>
							<p class="tariff-plans__text">Unlimited scans for integrated apps</p>
						</div>
						<div class="tariff-plans__box">
							<div class="icon">
								<img src="../../assets/images/check-mark.svg" alt="Oversecured">
							</div>
							<p class="tariff-plans__text">Yearly subscription with tech support</p>
						</div>
						<div class="tariff-plans__box">
							<div class="icon">
								<img src="../../assets/images/check-mark.svg" alt="Oversecured">
							</div>
							<p class="tariff-plans__text">CI/CD integrations</p>
						</div>
						<div class="tariff-plans__box">
							<div class="icon">
								<img src="../../assets/images/check-mark.svg" alt="Oversecured">
							</div>
							<p class="tariff-plans__text">Fix vulnerabilities in alpha/beta builds before shipping to application stores</p>
						</div>
						<button class="tariff-plans__link-btn-wrapper">
							<b-nav-item :to="{ path: '/contact-us'}" class="tariff-plans__link-btn">
								Contact Us
							</b-nav-item>
						</button>
					</div>
				</div>
			</div>
		</b-container>
		<div class="comparison-table">
			<b-container>
				<h5 class="comparison-table__title">Table comparing Single Scan and Integration</h5>
				<table id="comparison-table__body">
					<thead>
						<tr>
							<th></th>
							<th>Single Scan</th>
							<th>Integration</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Complete list of vulnerabilities</td>
							<td>+</td>
							<td>+</td>
						</tr>
						<tr>
							<td>Option to export the report as PDF</td>
							<td>+</td>
							<td>+</td>
						</tr>
						<tr>
							<td>Customized report</td>
							<td>+</td>
							<td>+</td>
						</tr>
						<tr>
							<td>Alerts</td>
							<td>-</td>
							<td>+</td>
						</tr>
						<tr>
							<td>Comparison of reports</td>
							<td>-</td>
							<td>+</td>
						</tr>
						<tr>
							<td>Hide vulnerabilities already marked as false positives from new reports</td>
							<td>-</td>
							<td>+</td>
						</tr>
						<!-- leave _Automatically pull new versions from Google Play_ feature for the future -->
						<!-- <tr>
							<td>Automated download from Google Play</td>
							<td>-</td>
							<td>+</td>
						</tr> -->
						<tr>
							<td>Price</td>
							<td>per file</td>
							<td>per app</td>
						</tr>
					</tbody>
				</table>
			</b-container>
		</div>
	</div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.pricing-page {
	background-color: #FAFBFC;
}

.pricing {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &__title {
        font-family: Raleway, serif;
        font-weight: bold;
        font-size: 64px;
        line-height: 121.5%;
        color: #242424;
		margin: 80px 0 0 -4px;

    }

    &__subtitle {
        font-family: Cabin, serif;
        font-weight: bold;
        font-size: 14px;
        color: #706969;
    }
}

.tariff-plans {
    display: flex;
    font-family: Cabin, serif;
    color: #242424;
    justify-content: space-between;
    margin-top: 20px;

    &__block {
        position: relative;
        border: 1px solid #242424;
        box-sizing: border-box;
		background-color: #fff;
        padding: 43px 23px 50px;
        width: 100%;
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    &__block-triangle {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    &__icon {
        width: 43px;
        height: 45px;
    }

    &__title {
        font-weight: bold;
        font-size: 24px;
        margin-top: 25px;

        &--yellow {
            color: #FFB400;
        }
    }

    &__line {
        background-color: #BEBEBE;
        margin: 25px 0;
    }

    &__box {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    &__text {
        font-size: 14px;
        margin: 0 0 0 10px;
        color: #706969;
    }

    &__price {
        text-align: center;
        font-weight: bold;
        font-size: 36px;
        margin-top: 60px;
		margin-bottom: -24px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        p {
            font-size: 32px;
            margin: 0 0 0 7px;
        }
    }

    &__link-btn-wrapper {
        background: #FFB400;
        border-radius: 8px;
        margin: 35px auto 0 auto;
        max-width: 214px;
        width: 100%;
        transition: 0.3s;

        &:hover {
            opacity: 0.7;
        }

        a.nav-link {
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            font-family: Cabin, serif;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
            padding: 12px 0;
            margin: 0;
            width: 214px;
            border-radius: 8px;
            transition: 0.3s;
        }
    }

    &__link-btn--black {
        background: #242424;
        border-radius: 8px;
        margin-top: 113px;

        a.nav-link {
            border:none;
            width: 214px;
        }
    }

    &__link-btn--black-second-var {
        background: white;

        &:hover {
            background: black;
        }

        a.nav-link {
            color: black !important;
            padding: 10px 0;
            border-radius: 8px;
            border: 2px solid black;

            &:hover {
                color: white !important;
                border: 2px solid black;
            }
        }
    }
}

.comparison-table {
	padding-bottom: 40px;

	&__title {
		font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: #242424;
        margin: 40px 0 20px 0px;
	}

	#comparison-table__body {
        border-radius: 20px;
        background-color: white;
        width: 100%;
        transition: 0.3s;
        box-shadow: 0px 4px 15px rgba(56, 70, 77, 0.04);

        &:hover {
            box-shadow: 0px 4px 15px rgba(56, 70, 77, 0.08);
        }
    }

    #comparison-table__body thead tr {
        height: 90px;
        border-bottom: 1px solid #F0F0F0;
    }

    #comparison-table__body thead tr th {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #B2B9C4;
        text-align: center;
    }

    #comparison-table__body thead tr th:nth-child(1) {
        width: 60%;
    }

    #comparison-table__body thead tr th:nth-child(2),
    #comparison-table__body thead tr th:nth-child(3) {
        width: 20%;
    }

    #comparison-table__body tr {
        height: 90px;
    }

    #comparison-table__body tbody tr:not(:last-child) {
        border-bottom: 1px solid #F0F0F0;
    }

    #comparison-table__body tbody tr td:nth-child(1) {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #242424;
        width: 60%;
        padding-left: 40px;
    }

    #comparison-table__body tbody tr td:nth-child(2),
    #comparison-table__body tbody tr td:nth-child(3) {
        text-align: center;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: #242424;
        width: 20%;
    }
}

@media only screen and (max-width: 990px) {
	.pricing-page {
		padding-bottom: 70px;
		background-color: #FAFBFC;
	}

    .pricing {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        &__title {
            font-size: 36px;
            margin-top: 20px;
        }

        &__subtitle {
            margin: 30px 0;
            text-align: center;
        }
    }

    .tariff-plans {
        flex-direction: column;
        padding: 0 20px 0 20px;
        box-sizing: border-box;

        &__block {
            margin-bottom: 36px;
			padding: 43px 40px 50px;
        }

		&__price {
			font-size: 32px;

			p {
				font-size: 28px;
			}
		}

        &__link-btn-wrapper {
			display: flex;
			justify-content: center;

			a.nav-link {
				font-size: 16px !important;
				max-width: 214px;
			}
		}

        &__link-btn--black-second-var {
			a.nav-link {
				width: 214px !important;
			}
		}
    }

	.comparison-table {
		padding: 0 20px 0 20px;

		&__title {
			text-align: center;
		}


	}
	#comparison-table__body thead tr th:nth-child(1) {
		width: 50%;
	}

	#comparison-table__body thead tr td:nth-child(1) {
		width: 50%;
		padding-left: 0px;
	}

	#comparison-table__body thead tr th:nth-child(2),
	#comparison-table__body thead tr th:nth-child(3) {
		width: 100%;
		font-size: 16px;
		font-weight: bold;
		padding: 0 10px 0 10px;
	}

	#comparison-table__body thead tr th:nth-child(3) {
		color: #FFB400;
	}

	#comparison-table__body tbody tr:nth-child(7) td:nth-child(3),
	#comparison-table__body tbody tr:nth-child(7) td:nth-child(2) {
		font-size: 16px;
	}
}
</style>
