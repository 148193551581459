import Vue from 'vue';
import { isEmpty } from '../services/functions';
import { FolderNameEnum } from '../utils/constants/common';

export const state = () => ({
    filters: {},
    isSeverityChecked: {
        'high': false,
        'medium': false,
        'low': false,
    },
    isSeverityOpen: {
        'high': true,
        'medium': true,
        'low': true,
    },
    allSeveritiesUnchecked: false, // New state to track whether all items in the filter are unchecked
    isSidebarVisible: false,
    deductNumber: 1,
});

export const mutations = {
    setFilters(state, value) {
        state.filters = value;
    },
    updateFilter(state, payload) {
        const { severity, vulnerabilityId, checked } = payload;

        const index = state.filters[severity].findIndex(vulnerability => vulnerability.id === vulnerabilityId);

        if (index !== -1) {
            state.filters[severity][index].checked = checked;
        }

        // Update the allSeveritiesUnchecked state
        state.allSeveritiesUnchecked = Object.values(state.filters).every(
            severityItems => severityItems.every(item => !item.checked),
        );
    },
    updateFilterAfterMovingVulnerability(state, { severity, categoryId }) {
        const index = state.filters[severity].findIndex(vulnerability => vulnerability.id === categoryId);

        if (index !== -1) {
            const vulnerability = state.filters[severity][index];

            // Check the count and decrease it if greater than 1
            if (parseInt(vulnerability.count, 10) > 1) {
                vulnerability.count = (parseInt(vulnerability.count, 10) - state.deductNumber).toString();
                vulnerability.count === '0' ? Vue.delete(state.filters, severity) : null;
            } else {
                // Remove the vulnerability from the filter if the count is 1
                state.filters[severity].splice(index, 1);

                // Remove the severity level if there are no categories left in that severity level
                if (state.filters[severity].length === 0) {
                    Vue.delete(state.filters, severity);
                }
            }
        }
    },
    updateAllSeveritiesUnchecked(state) {
        state.allSeveritiesUnchecked = Object.values(state.filters).every(
            severityItems => severityItems.every(item => !item.checked),
        );
    },
    updateSeverityChecked(state, { severity, checked }) {
        state.isSeverityChecked[severity] = checked;
    },
    updateSeverityCheckedIncludingTrialRestriction(state, isTrialRestriction) {
        for (const severity in state.filters) {
            if (Array.isArray(state.filters[severity])) {
                // If the severity exists in filters, update its checked state based on the isTrialRestriction flag
                state.isSeverityChecked[severity] = isTrialRestriction
                    ? severity === 'high' || severity === 'medium'
                        ? false
                        : state.filters[severity].some(item => item.checked)

                    : state.filters[severity].some(item => item.checked);
            }
        }
    },
    updateSeverityOpen(state, payload) {
        const { severity, isOpen } = payload;

        state.isSeverityOpen[severity] = isOpen;
    },
    openAllSeverityDropdowns(state) {
        Object.keys(state.isSeverityOpen).forEach(severity => {
            state.isSeverityOpen[severity] = true;
        });
    },
    clearFilters(state) {
        state.filters = {};
        // reset all categories to be open
        Object.keys(state.isSeverityOpen).forEach(severity => {
            state.isSeverityOpen[severity] = true;
        });
    },
    setSidebarVisiblity: (state, status) => {
        state.isSidebarVisible = status;
    },
    setDeductNumber: (state, number) => {
        state.deductNumber = number;
    },
};

export const actions = {
    async updateFilterAfterMovingVulnerability({ commit, state, rootGetters }, payload) {
        /**
         * When we move vulnerability from All Vulnerabilities folder, or not specified folderName,
         * to other than False Positive folder, we need to stop filter updating process, because
         * that vulnerability will be still present in Vulnerabilities in code.
         */
        const movedFromFolder = rootGetters['folders/movedFromFolder'];
        const movedToFolder = rootGetters['folders/movedToFolder'];

        if (
            movedFromFolder === FolderNameEnum.ALL_VULNERABILITIES
            && movedToFolder !== FolderNameEnum.FALSE_POSITIVES
        ) return;

        try {
            commit('updateFilterAfterMovingVulnerability', payload);
            // Update the allSeveritiesUnchecked state
            commit('jobs/deleteVulnerabilityCategoryIdAfterMoving', { ...payload, deductNumber: state.deductNumber }, { root: true });
            commit('updateAllSeveritiesUnchecked');
            // we need to close the sidebare if there are no filters
            if (isEmpty(state.filters)) commit('setSidebarVisiblity', false);
        } catch (error) {
            console.log('Error in updateFilterAfterMovingVulnerability:', error);
        }
    },
    clearFilters({ commit }) {
        commit('clearFilters');
    },
};

export const getters = {
    filters: state => state.filters,
    isSeverityChecked: state => state.isSeverityChecked,
    isSeverityOpen: state => state.isSeverityOpen,
    allSeveritiesUnchecked: state => state.allSeveritiesUnchecked,
    isSidebarVisible: s => s.isSidebarVisible,
    deductNumber: s => s.deductNumber,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
