<template>
    <div class="container-menu">
        <div class="outer-menu">
            <!-- <router-link 
                class="header-btn"
                to="/product"    
            >
                Product
            </router-link> -->
            <a
                class="header-btn"
                href="https://blog.oversecured.com/"
            >
                Blog
            </a>
            <router-link
                class="header-btn"
                to="/pricing"    
            >
                Pricing
            </router-link>
            <button
                class="header-btn"
                @click="scrollToFAQ"
            >
                FAQ
            </button>
            <router-link 
                class="header-btn"
                :to="{ path: '/partner' }"
            >
                Partner
            </router-link>
            <!-- <router-link
                class="header-btn"
                to=""
            >
                Company
            </router-link> -->
        </div>
        <div class="sign-in-menu">
            <router-link  
                class="sign-in-btn"
                to="/sign-in"
            >
                Sign In
            </router-link>
            <div 
                class="demo-btn"
                @click="requestDemo"
            >
                Request a Demo
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'NewOuterHeader',
    props: {
        isAuthenticated: Boolean,
    },
    methods: {
        login() {
            const redirect = this.$route.query.redirect || null;
            this.$auth.loginWithRedirect({ appState: { targetUrl: 'scans', redirect } });
        },

        requestDemo() {
            this.$router.push('/request-demo'); 
        },

        scrollToFAQ() {
            if (this.$route.path !== '/') {
                this.$router.push('/').then(() => {
                    this.scrollToFaqElement();
                });
            } else {
                this.scrollToFaqElement();
            }
        },
        
        scrollToFaqElement() {            
            const faqElement = document.getElementById('faq');
            const offset = document.querySelector('.navigation-panel') 
                ? document.querySelector('.navigation-panel').offsetHeight : 0;
            window.scrollTo({
                top: faqElement.offsetTop - offset,
                behavior: 'smooth',
            });
        },
    },
};
</script>
<style lang="scss">
@import "../../assets/css/fonts";
@import "../../assets/css/variables.scss";

.container-menu {
    display: flex;
    justify-content: space-between;   
}

.outer-menu {
    display: flex;
    width: $dynamic-outer-menu-width;
    justify-content: space-between;
    align-items: center;
}

.sign-in-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $dynamic-sign-in-menu-gap;
    width: $dynamic-sign-in-menu-width;
}

.sign-in-btn {
    @include Aspekta-font(700, $dynamic-header-font-size, normal, #797E86);
    font-style: normal;
    text-transform: capitalize; 

    &:hover {
        text-decoration: none;
        color: #021631;
    }

    &:active {
        color: #FF7A08;
    }
}

.header-btn {
    background: none;
    @include Aspekta-font(400, $dynamic-header-font-size, normal, #797E86);
    font-style: normal;
    text-transform: capitalize; 
    
    &:hover {
        text-decoration: none;
        color: #021631;
    }

    &:active {
        color: #FF7A08;
    }

}


.demo-btn {
    display: flex;
    padding: 17px 5%; //24px 30px;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;
    border-radius: 50px;
    border: 2px solid  rgba(0, 0, 0, 0.0);
    background: var(--dark-blue, #021631);
    @include Aspekta-font(700, $dynamic-header-font-size, normal, #FFF);
    text-align: center;
    cursor: pointer;

    &:hover {
        background: rgba(2, 22, 49, 0.70);
    }

    &:active {
        border: 2px solid var(--dark-blue, #021631);
        background: var(--white, #FFF);
        color: #0C0D0E;
    }
}



@media (max-width: 834px) {
    
    .header-btn {
        @include Aspekta-font(400, #{$max-header-font-size}px, normal, #021631);
        margin-bottom: 36px;
        text-transform: capitalize;
    }

    .sign-in-btn {
        @include Aspekta-font(700, #{$max-header-font-size}px, normal, #797E86);
        text-align: center;
    }
    
    .demo-btn {
        @include Aspekta-font(700, #{$max-header-font-size}px, normal, #FFF);
        margin-top: 28px;
        padding: 15px 30px;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .container-menu {
        width: auto;
        flex-direction: column;
    }

     .sign-in-menu {
        margin-top: 65px;
        flex-direction: column; 
        width: auto;
        gap: unset;
    }
    
    .outer-menu {
        flex-direction: column; 
        width: auto;
    }
}
</style>
