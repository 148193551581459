<template>
    <div>
        <div class="inner-branch-header">
            <div class="inner-branch-header__breadcrumb-and-button">
                <Breadcrumb
                    :integration-id="integration.id"
                    :integration-name="integration.integrationName"
                    :current-job="integration"
                    :branch-name="integration.defaultBranchName"
                />
                <div class="inner-branch-header-btn__container">
                    <Tooltip
                        v-if="!canAddBranch(integration.status)"
                        position="top"
                        mobile-position="top"
                        :tooltip="tooltipBranchText"
                    >
                        <ConfigurablePopupButton
                            :button-text="'New Branch'"
                            :button-type="'add-branch'"
                            :icon="'add-branch'"
                            :disabled="!canAddBranch(integration.status)"
                            :stop-propagation="true"
                            @buttonClick="openNewBranchModal()"
                        />
                    </Tooltip>
                    <ConfigurablePopupButton
                        v-if="canAddBranch(integration.status)"
                        :button-text="'New Branch'"
                        :button-type="'add-branch'"
                        :icon="'add-branch'"
                        :disabled="!canAddBranch(integration.status)"
                        :stop-propagation="true"
                        @buttonClick="openNewBranchModal()"
                    />
                </div>
            </div>
            <div class="inner-branch-header__name">
                Branches - {{ integration.integrationName }}
            </div>
        </div>
    </div>
</template>
<script>
import { IntegrationStatusEnum } from '../../../utils/constants/common';
import branchIconActive from '../../../assets/images/branch_icon_active.svg';
import branchIconDisabled from '../../../assets/images/branch_icon_disabled.svg';
import Breadcrumb from '../../report/components/Breadcrumb';
import Tooltip from '../../report/components/Tooltip.vue';
import ConfigurablePopupButton from '../../popups/ConfigurablePopupButton';

export default {
    name: 'InnerBranchHeader',
    components: {
        Breadcrumb,
        Tooltip,
        ConfigurablePopupButton,
    },
    props: {
        integration: Object,
    },
    data() {
        return {
            branchIconActive,
            branchIconDisabled,
            tooltipBranchText: 'Adding a branch is available only for Integration in Active status',
        };
    },
    methods: {
        openNewBranchModal() {
            this.$emit('openNewBranchModal');
        },
        canAddBranch(status) {
            return status === IntegrationStatusEnum.ACTIVE;
        },

    },
};
</script>
<style lang="scss">
@import '../../../assets/css/fonts';
@import '../../../assets/css/variables';

.inner-branch-header {
    display: flex;
    flex-direction: column;
    margin: 0 7.5%;

    &.container {
        padding: 0;
    }

    &__breadcrumb-and-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 10px;
        align-items: center;
    }

    &-btn__container {
        margin-left: auto;
    }

    &__name {
        @include Aspekta-font(700, $dynamic-integration-name-font-size, $dynamic-integration-line-height, #021631);
        margin: 40px 0;
    }

}

@media (max-width: 576px) {

    .inner-branch-header {
        margin: 0 20px;
    }

}
</style>

