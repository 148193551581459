<template>
    <div class="inner-branches__wrapper">
        <div
            v-if="branchList && branchList.length && integration"
            class="inner-branches"
        >
            <InnerBranchHeader
                :integration="integration"
                @openNewBranchModal="openNewBranchModal"
            />
            <InnerBranchTable
                ref="InnerBranchTable"
                :branch-list="branchList"
            />
        </div>
        <PageNotFound v-else-if="!showSpinner" />
        <div
            v-if="showSpinner"
            class="inner-branches__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import InnerBranchHeader from '../../../components/innerIntegration/branches/InnerBranchHeader';
import InnerBranchTable from '../../../components/innerIntegration/branches/InnerBranchTable';
import PageNotFound from '../../../components/pageNotFound/PageNotFound';
import Spinner from '../../../components/Spinner';

export default {
    name: 'InnerBranches',
    components: {
        InnerBranchHeader,
        InnerBranchTable,
        PageNotFound,
        Spinner,
    },
    data: () => {
        return {
            showSpinner: false,
            integrationId: null,
        };
    },

    computed: {
        ...mapGetters({
            branchList: 'integrations/branchList',
            integration: 'integrations/integration',
        }),
    },
    async beforeMount() {
        this.showSpinner = true;
        this.integrationId = this.$route.params.id;
        await this.$store.dispatch('integrations/getIntegration', { id: this.integrationId });
        await this.$store.dispatch('integrations/getBranchList', { integrationId: this.integrationId });
        this.showSpinner = false;
    },

    methods: {
        openNewBranchModal() {
            this.$refs.InnerBranchTable.toggleNewBranchModal();
        },
    },
};
</script>
<style lang="scss">
.inner-branches {
    padding: 40px 0 70px;
    background-color: #F8FAFC;
    min-height: 70vh;

    &__wrapper {
        position: relative;
    }

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 10
    }
}
</style>
