<template>
    <div>
        <b-modal
            id="modal-confirm"
            v-model="isShowModal"
            dialog-class="delete-popup__dialog"
            content-class="delete-popup__content"
            body-class="delete-popup__body"
            header-class="delete-popup__header"
            footer-class="delete-popup__footer"
            :hide-footer="true"
            hide-header-close
            centered
            no-fade
            lazy
            static
            @hidden="onHidden"
        >
            <template #modal-header>
                <div class="delete-popup__title">
                    You're about to delete {{ folder && folder.name }}
                </div>
                <p class="delete-popup__description">
                    You`ll no longer be able to move reports here
                </p>
            </template>
            <div class="delete-popup__btns">
                <ConfigurablePopupButton
                    :button-text="'Cancel'"
                    :button-type="'cancel'"
                    :icon="false"
                    :disabled="false"
                    @buttonClick="onHidden"
                />
                <ConfigurablePopupButton
                    re
                    :button-text="'Delete'"
                    :button-type="'delete'"
                    :icon="true"
                    @buttonClick="onDeleteFolder"
                />
            </div>
        </b-modal>
    </div>
</template>

<script>

import ConfigurablePopupButton from '../popups/ConfigurablePopupButton';

export default {
    components: {
        ConfigurablePopupButton,
    },
    props: {
        folder: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            isShowModal: false,
            isDropdownOpen: false,
            screenWidth: window.innerWidth,
            Hover: '#FFE7AD',
            Default: '#949DB8',
            Empty: '#949DB8',
        };
    },
    methods: {
        onHidden() {
            this.$bvModal.hide('modal-confirm');
            this.$emit('toggleFolderSettingsPopUp', true);
            this.isDropdownOpen = false;
        },
        onDeleteFolder() {
            this.$emit('is-confirm',  this.index, this.folder );
            this.$bvModal.hide('modal-confirm');
        },
    },
};
</script>

<style lang='scss' scoped>
@import "../../assets/css/fonts.scss";
@import "../../assets/css/variables.scss";


::v-deep .delete-popup {
    &__dialog {
        width: 500px;
    }
    &__content {
        border-radius: 16px;
        border: none;
        padding: 30px;
        gap: 25px;
    }
    &__body {
        padding: 0;
        margin: .25rem;
    }
    &__header {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border: none;
        padding: 0;
    }
    &__title {
        @include Aspekta-font(600, 18px, normal, #242424);
        letter-spacing: 0.54px;
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__description {
        @include Aspekta-font(400, 14px, 140%, #706969);
        text-align: center;
        margin: 0;
    }
    &__btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    ::v-deep .delete-popup__btns {
        flex-direction: column-reverse;
    }

    ::v-deep .delete-popup__dialog {
        max-width: 92%;
    }

}
</style>
