<template>
    <vue-dropzone
        id="dropzone"
        ref="fileDropzone"
        class="dropzone-block-new"
        :class="{'just-drop': justDrop, response: response}"
        :style="disableDropzone()"
        :options="dropzoneOptions"
        duplicate-check
        use-custom-slot
        :include-styling="false"
        @vdropzone-duplicate-file="onDuplicateFile"
        @vdropzone-error="onError"
        @vdropzone-file-added="onFileAdded"
        @vdropzone-files-added="onFilesAdded"
        @vdropzone-sending="onSending"
        @vdropzone-upload-progress="onUploadProgress"
        @vdropzone-total-upload-progress="onTotalUploadProgress"
        @vdropzone-success="onSuccess"
        @vdropzone-complete="onComplete"
        @vdropzone-queue-complete="onQueueComplete"
        @vdropzone-removed-file="onRemovedFile"
        @vdropzone-drop="onDrop"
    >
        <div class="dropzone-custom-content-main">
            <!-- <div class="dropzone-custom-limit">500 MB max per file</div> -->
            <div
                v-if="!response"
                class="dropzone-custom-title"
            >
                Drag & drop Android or iOS files
            </div>
            <div
                v-else
                class="dropzone-custom-title"
            >
                <img
                    src="@/assets/images/newScan/drop-icon.svg"
                    class="download-icon"
                    alt=""
                >
                Upload Android or iOS files
            </div>
            <div class="dropzone-custom-available-block">
                <div class="dropzone-custom-android-block">
                    <p class="dropzone-custom-platform-text">
                        Android
                    </p>
                    <span class="dropzone-custom-platform-sign">.apk</span>
                    <span class="dropzone-custom-platform-sign">.aab</span>
                </div>
                <div class="dropzone-custom-ios-block">
                    <p class="dropzone-custom-platform-text">
                        iOS (XCode project)
                    </p>
                    <span class="dropzone-custom-platform-sign">.zip</span>
                </div>
            </div>
            <div
                v-if="!response"
                class="subtitle"
            >
                or
            </div>
            <b-button class="dropzone-custom-button-new">
                Upload Files
            </b-button>
        </div>
    </vue-dropzone>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { VueOfflineMixin } from 'vue-offline';
import { validateFile } from '@/services/fileService';
import { onDuplicateFileUpload, template } from '@/services/newScan/newScanFunctions';
import { warningScanNotification, errorScanNotification } from '@/services/notificationService/notificationService';

export default {
    name: 'Dropzone',
    components: {
        vueDropzone: vue2Dropzone,
    },
    mixins: [VueOfflineMixin],
    props: {
        justDrop: Boolean,
        response: Boolean,
    },
    data() {
        let me = this;
        return {
            progressBar: 0,
            useDrop: false,
            order: 1,
            dropzoneOptions: {
                url: '/',
                method: 'put',
                thumbnailWidth: 150,
                maxFilesize: 500,
                acceptedFiles: '.apk, .aab, .zip',
                parallelUploads: 1,
                previewTemplate: me.template(),
                async accept(file, done) {
                    await me.onAccept(file, done);
                },
            },
            duplicateFileNames: [],
        };
    },
    methods: {
        disableDropzone() {
            const params = {
                pointerEvents: 'none',
                cursor: 'default',
            };
            return this.isOffline && params;
        },
        onDuplicateFile(file) {
            file.duplicate = true;
            this.duplicateFileNames.push(file.name);
        },
        onError(file, errorMessage) {
            file.errorMessage = errorMessage;
        },
        onFileAdded(file) {
            // Get the type of file being uploaded (apk, aab or zip)
            const typeFile = file.name.split('.').pop();
            const platform = typeFile === 'zip' ? 'ios' : (typeFile === 'apk' || typeFile === 'aab' ? 'android' : '');
            file.platform = platform;
            file.order = this.order;
            this.order++;
        },
        async onAccept(file, done) {
            if (this.isOffline) return; // if offline - do not upload file

            // Validate the file
            let validate = await validateFile(file);
            if (!validate.valid) {
                done(validate.errorMessage);
            } else if (file.duplicate) {
                done('duplicate');
            } else {
                try {
                    const { data } = await this.axios.post('/private/ui/getFileUploadData', {
                        fileName:  file.name,
                        platform: file.platform,
                    });
                    const { url, bucketKey, price } = data;
                    file.url = url;
                    file.price = price;
                    file.bucketKey = bucketKey;
                    done();
                } catch (error) {
                    done(error);
                }
            }
        },
        onFilesAdded(files) {
            if (this.isOffline) {
                Object.values(files).forEach(file => {
                    this.$refs.fileDropzone.removeFile(file);
                });
            }
            const allFiles = this.returnAllFiles();
            if (this.useDrop) {
                allFiles.unshift(...files);
                this.useDrop = false;
            }
            if(this.duplicateFileNames.length > 0) {
                this.warningScanNotification(this.duplicateFileNames);
            }
            this.$emit('files-added', allFiles);
            this.duplicateFileNames = [];

        },
        onSending(file, xhr) {
            if (this.isOffline) return;
            let _send = xhr.send;
            xhr.timeout = 3000 * 1000; // milliseconds
            xhr.send = () => {
                xhr.onerror = function () {
                    console.log('** An error occurred during the transaction');
                    this.$refs.fileDropzone.removeFile(this.files[file.upload.uuid].file);
                };
                xhr.open('PUT', file.url);
                _send.call(xhr, file);
            };
        },
        onUploadProgress(file, progress) {
            if (this.isOffline) return;
            this.fileName = file.name;
            if (progress !== 0 && progress !== 100) {
                this.progressBar = progress;
                if (progress === 100) {
                    this.progressBar = 0;
                }
            }
            const files = this.returnAllFiles();
            this.$emit('uploading', files);
        },
        onTotalUploadProgress(progress) {
            if (this.isOffline) return;
        },
        onSuccess(file) {
            if (this.isOffline) return;
        },
        onComplete(response) {
            const queuedFiles = this.$refs.fileDropzone.getQueuedFiles();
            if (queuedFiles.length === 0) {
                const rejected = this.$refs.fileDropzone.getRejectedFiles();

                const duplicates = rejected.filter(file => file.duplicate === true);
                if (duplicates.length) {
                    const duplicateNames = duplicates.map(file => file.name);
                    this.warningScanNotification(this, { fileNames: duplicateNames });
                }
            }
        },
        onQueueComplete() {
            const files = this.returnAllFiles();
            this.$emit('queue-complete', files);
        },
        onRemovedFile(removedFile) {
            const files = this.returnAllFiles();
            this.$emit('file-removed', files, removedFile);
        },
        removeFile(file) {
            this.$refs.fileDropzone.removeFile(file);
        },
        returnAllFiles() {
            const rejectedFiles = this.$refs.fileDropzone.getRejectedFiles();
            const acceptedFiles = this.$refs.fileDropzone.getAcceptedFiles();
            const queuedFiles = this.$refs.fileDropzone.getQueuedFiles();
            const uploadingFiles = this.$refs.fileDropzone.getUploadingFiles();
            const uploadedFiles = acceptedFiles.filter(file => {
                return file.status === 'success';
            });
            return [...rejectedFiles, ...uploadingFiles, ...queuedFiles, ...uploadedFiles];
        },
        onDrop() {
            this.useDrop = true;
        },
        onDuplicateFileUpload,
        template,
        warningScanNotification,
        errorScanNotification,
    },
};
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

.dropzone-block-new {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border: 2px dashed #ADCCFB;
  border-radius: 20px;
  &.response {
    background-color: $background-grey;
  }

  .dz-message {
    .dropzone-custom-content-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 70px 0;
        position: relative;
        color: #706969;

      .dropzone-custom-title {
        font-size: 22px;
        font-weight: 700;
        line-height: 150%;
        color: #021631;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
      }
      svg.download-icon {
        width: 57px;
        height: 57px;
      }
      .download-icon {
        transform: rotate(180deg);
      }

      .dropzone-custom-available-block {
        font-family: Cabin;
        font-style: normal;
        font-weight: normal;
        display: flex;
        justify-content: center;
        gap: 30px;
        .dropzone-custom-ios-block, .dropzone-custom-android-block {
            text-align: center;
        }
      }

      .dropzone-custom-platform-text {
        font-size: 12px;
        line-height: 150%;
        color: #706969;
        margin: 0 0 12px 0;
        text-align: left;
      }

      .dropzone-custom-platform-sign {
        font-size: 14px;
        color: #021631;
        background: #F5F7FA;
        border-radius: 5px;
        padding: 3px 6px;
        margin-right: 8px;
      }

      .dropzone-custom-platform-sign-description {
        font-size: 14px;
        color: #706969;
      }

      .dropzone-custom-button-new {
        width: 256px;
        background-color: $button-orange-color;
        border-radius: 8px;
        border: none;
        font-size: 22px;
        line-height: 46px;
        font-weight: 700;
        color: $button-orange-color-text;
        transition: all .3s;
        &:hover {
            color: #fff;
            background-color: $new-orange-color;
            box-shadow: 0px 8px 16px rgba(255, 180, 0, 0.24);
        }
      }
    }
  }

    .dz-preview {
        display: none;
    }
}

.dropzone-block-new.just-drop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: transparent;
    border: none;
    z-index: 11;
    & div {
        display: none;
    }
}

.dropzone-custom-svg-small-permission,
.dropzone-custom-title-small-permission-block-loaded-files,
.dropzone-custom-title-small-permission-block,
.dropzone-custom-available-block-small {
    display: none;
}

@media(max-width: 505px) {
    .dropzone-block-new {
        .dz-message {
            .dropzone-custom-content-main {
                padding-top: 20px;
                padding-bottom: 40px;
            }
        }
    }
}

</style>
