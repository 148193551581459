<template>
    <div>
        <div
            v-if="isPageLoading"
            class="spinner__new-scan"
        >
            <spinner />
        </div>
        <div
            v-else
            class="outer-container"
            :class="[isTablet && 'tablet', isMobile && 'mobile', `${showingStep}-outer`]"
        >
            <div
                v-show="showingStep === 'dropzone'"
                class="dropzone-page"
            >
                <dropzone-header
                    :send-email="isNotifyByEmail"
                    @checked="onSendEmailChecked"
                />
                <div class="dropzone-page__main">
                    <prepaid-block
                        :prepaid="availableScansCount"
                        :use-prepaid="isUsePrepaidScans"
                        @selected="onPrepaidSelected"
                    />
                </div>
            </div>
            <dropzone
                v-show="showingStep === 'dropzone' || showDropZone"
                id="dropzone"
                ref="dropzone"
                :class="{'dropzone-page__main': showingStep === 'dropzone'}"
                :just-drop="showDropZone"
                :response="isTablet || isMobile"
                @files-added="onFilesAdded"
                @uploading="onUploading"
                @file-removed="onFileRemoved"
                @queue-complete="onQueueComplete"
            />
            <div
                v-show="showingStep === 'details'"
                class="scan-details__wrapper"
            >
                <div class="scan-steps__wrapper">
                    <step-container
                        :step-number="1"
                        :is-open="stepNumber === 1 || showStepOne"
                    >
                        <template #headerText>
                            Uploaded Files
                            <span style="font-weight: 400; opacity: .7;">({{ uploadedScansList.length }})</span>
                        </template>
                        <template #headerButton>
                            <button
                                v-if="isDesktop"
                                class="add-more"
                                @click="addMoreFiles"
                            >
                                <span class="plus-icon">+</span>
                                Add More
                            </button>
                        </template>
                        <dropzone-canvas v-if="showDropZone" />
                        <div
                            v-if="!isHideAdvise && isDesktop"
                            class="advice-message"
                        >
                            Drag and drop files here to upload. The drop zone appears when you start dragging files.
                            <button
                                class="advice-message-close"
                                @click.prevent="onAdviceClose"
                            />
                        </div>
                        <add-more-files-buttons
                            v-if="isTablet"
                            @add-more="addMoreFiles"
                            @edit="onFilesEdit"
                        />
                        <new-scan-table
                            :files="uploadedScansList"
                            :is-editing="isFilesEditing"
                            :tablet="isTablet"
                            :mobile="isMobile"
                            @delete="removeFile"
                        />
                        <add-more-files-buttons
                            v-if="isMobile"
                            @add-more="addMoreFiles"
                            @edit="onFilesEdit"
                        />
                        <template #stepButtons>
                            <steps-and-activation-code-buttons
                                class="step-control-buttons-ipad"
                                :show-activation-code-buttons="showActivationCodeButtons"
                                :pay-confirm-button="stepNumber === 3 || mobileRulesConfirmation"
                                :disable-confirm-button="disableConfirmButton() || !mobileRulesConfirmation"
                                :disable-next-button="disableNextButton() || mobileRulesConfirmation"
                                :disable-activation-confirm-button="!activationCode || activationCodeInputError"
                                :disable-back-button="stepNumber === 1"
                                @back="moveBack"
                                @next="moveNext"
                                @confirm-scan="confirmScan"
                                @cancel="cancelActivationCode"
                                @confirm-code="confirmActivationCode"
                            />
                        </template>
                    </step-container>
                    <step-container
                        v-if="showStepTwo"
                        :step-number="2"
                        :is-open="stepNumber === 2"
                    >
                        <template #headerText>
                            Scan Options
                            <span
                                v-if="isDesktop"
                                style="font-weight: 400; opacity: .7;"
                            >(Optional)</span>
                        </template>
                        <template #headerButton>
                            <button
                                class="skip-step"
                                @click.prevent="stepNumber = 3"
                            >
                                Skip <span v-if="isDesktop">this step</span>
                            </button>
                        </template>
                        <scan-options
                            :prepaid-amount="availableScansCount"
                            :prepaid-select="isUsePrepaidScans"
                            :scan-packs="scanPacksInfo"
                            :selected-pack="selectedScanPack"
                            @use-prepaid="onPrepaidSelected"
                            @pack-selected="onScanPackSelect"
                        />
                        <template #stepButtons>
                            <steps-and-activation-code-buttons
                                class="step-control-buttons-ipad"
                                :show-activation-code-buttons="showActivationCodeButtons"
                                :pay-confirm-button="stepNumber === 3"
                                :disable-confirm-button="disableConfirmButton()"
                                :disable-next-button="disableNextButton()"
                                :disable-activation-confirm-button="!activationCode || activationCodeInputError"
                                :disable-back-button="stepNumber === 1"
                                @back="moveBack"
                                @next="moveNext"
                                @confirm-scan="confirmScan"
                                @cancel="cancelActivationCode"
                                @confirm-code="confirmActivationCode"
                            />
                        </template>
                    </step-container>
                    <step-container
                        v-if="showStepThree"
                        :step-number="3"
                        :is-open="stepNumber === 3"
                    >
                        <template #headerText>
                            Payment Details
                        </template>
                        <order-container
                            v-if="isMobile || isTablet"
                            ref="orderSummaryMobile"
                            class="order-summary__wrapper-mobile"
                            :files="uploadedScansList"
                            :prepaid-amount="isUsePrepaidScans ? availableScansCount : 0"
                            :scan-pack="selectedScanPack"
                            :is-payment-details="true"
                            :card-selected="paymentCardSelected"
                            :cards="cards"
                            :show-code-input="showActivationCodeButtons === true"
                            @add-code="showActivationCodeButtons = true"
                            @select-card="showPay"
                            @should-pay="shouldPay"
                            @activation-code="enterActivationCode"
                        />
                        <payment-cards-block
                            ref="paymentCards"
                            class="payments-card__wrapper"
                            :cards="cards"
                            :card-selected="paymentCardSelected"
                            :card-element="cardElement"
                            :stripe="stripe"
                            :stripe-elements="stripeElements"
                            :save-card="isSaveCard"
                            @selected-card="onPaymentCardSelected"
                            @new-card="onNewCardChange"
                            @save-card="onSaveCard"
                        />
                        <template #stepButtons>
                            <steps-and-activation-code-buttons
                                class="step-control-buttons-ipad"
                                :show-activation-code-buttons="showActivationCodeButtons"
                                :pay-confirm-button="stepNumber === 3"
                                :disable-confirm-button="disableConfirmButton()"
                                :disable-next-button="disableNextButton()"
                                :disable-activation-confirm-button="!activationCode || activationCodeInputError"
                                :disable-back-button="stepNumber === 1"
                                @back="moveBack"
                                @next="moveNext"
                                @confirm-scan="confirmScan"
                                @cancel="cancelActivationCode"
                                @confirm-code="confirmActivationCode"
                            />
                        </template>
                    </step-container>
                </div>
                <div
                    v-if="isDesktop"
                    class="order-summary__wrapper"
                >
                    <order-container
                        ref="orderSummary"
                        :files="uploadedScansList"
                        :prepaid-amount="isUsePrepaidScans ? availableScansCount : 0"
                        :scan-pack="selectedScanPack"
                        :show-code-input="showActivationCodeButtons === true"
                        @add-code="showActivationCodeButtons = true"
                        @should-pay="shouldPay"
                        @activation-code="enterActivationCode"
                    >
                        <steps-and-activation-code-buttons
                            class="step-control-buttons-desktop"
                            :show-activation-code-buttons="showActivationCodeButtons"
                            :pay-confirm-button="stepNumber === 3"
                            :disable-confirm-button="disableConfirmButton()"
                            :disable-next-button="disableNextButton()"
                            :disable-activation-confirm-button="!activationCode || activationCodeInputError"
                            :disable-back-button="stepNumber === 1 || !showStepThree"
                            @back="moveBack"
                            @next="moveNext"
                            @confirm-scan="confirmScan"
                            @cancel="cancelActivationCode"
                            @confirm-code="confirmActivationCode"
                        />
                    </order-container>
                </div>
            </div>
        </div>
        <steps-and-activation-code-buttons
            v-if="!isPaying && showingStep !== 'dropzone'"
            class="step-control-buttons-mobile"
            :show-activation-code-buttons="showActivationCodeButtons"
            :pay-confirm-button="stepNumber === 3 || mobileRulesConfirmation"
            :disable-confirm-button="disableConfirmButton() || mobileRulesOfConfirmButton"
            :disable-next-button="disableNextButton() || mobileRulesConfirmation"
            :disable-activation-confirm-button="!activationCode || activationCodeInputError"
            :disable-back-button="stepNumber === 1"
            @back="moveBack"
            @next="moveNext"
            @confirm-scan="confirmScan"
            @cancel="cancelActivationCode"
            @confirm-code="confirmActivationCode"
        />
        <div
            v-show="isPaying"
            class="spinner__paying"
        >
            <spinner />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Dropzone from '@/components/dropzone/Dropzone.vue';
import Spinner from '@/components/Spinner';
import PrepaidBlock from '@/components/newScan/PrepaidBlock.vue';
import DropzoneHeader from '@/components/newScan/DropzoneHeader.vue';
import StepContainer from '@/components/newScan/StepContainer.vue';
import OrderContainer from '@/components/newScan/OrderContainer.vue';
import ScanOptions from '@/components/newScan/ScanOptions.vue';
import PaymentCardsBlock from '@/components/newScan/PaymentCardsBlock.vue';
import NewScanTable from '@/components/newScan/NewScanTable.vue';
import StepsAndActivationCodeButtons from '@/components/newScan/StepsAndActivationCodeButtons.vue';
import AddMoreFilesButtons from '@/components/newScan/AddMoreFilesButtons.vue';
import DropzoneCanvas from '@/components/newScan/DropzoneCanvas.vue';
import { activationCodeNotification, paymentCardNotification, failNotification } from '@/services/notificationService/notificationService';
const STRIPE_PUBLIC_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY;

export default {
    name: 'NewScan',
    components: {
        Spinner,
        Dropzone,
        PrepaidBlock,
        DropzoneHeader,
        StepContainer,
        OrderContainer,
        ScanOptions,
        PaymentCardsBlock,
        NewScanTable,
        StepsAndActivationCodeButtons,
        DropzoneCanvas,
        AddMoreFilesButtons,
    },
    data() {
        return {
            isPageLoading: true,
            showingStep: 'dropzone',
            isFilesUploading: false,
            stepNumber: 1,
            windowWidth: window.innerWidth,
            isNotifyByEmail: true,
            isUsePrepaidScans: true,
            stripe: null,
            stripeElements: null,
            uploadedScansList: [],
            selectedScanPack: null,
            paymentCardSelected: null,
            shouldUserPay: false,
            isSaveCard: true,
            isPaying: false,
            cardElement: {
                cardNumber: null,
                cardExpiry: null,
                cardCvc: null,
            },
            showDropZone: false,
            showActivationCodeButtons: false,
            activationCode: '',
            activationCodeInputError: false,
            correctNewCard: false,
            isHideAdvise: !!this.$cookies.get('hide_advice'),
            isFilesEditing: false,
        };
    },
    computed: {
        ...mapGetters({
            cards: 'stripe/cards',
            trial: 'user/getTrial',
            availableScansCount: 'scanPacks/availableScansCount',
            activationCodeError: 'coupons/activationCodeError',
            scanPacksInfo: 'scanPacks/scanPacksInfo',
        }),
        isDesktop() {
            return this.windowWidth >= 900;
        },
        isTablet() {
            return this.windowWidth < 900 && this.windowWidth > 505;
        },
        isMobile() {
            return this.windowWidth <= 505;
        },
        showStepOne() {
            return this.isUsePrepaidScans && !this.shouldUserPay && this.isDesktop;
        },
        showStepTwo() { 
            return !this.isUsePrepaidScans || this.shouldUserPay || !this.isAvaliblePrepaidScan ;
        },
        showStepThree() {    
            return !this.isUsePrepaidScans || this.shouldUserPay || !this.isAvaliblePrepaidScan ;
        },
        isAvaliblePrepaidScan() {
            return this.filesUploaded().length <= this.availableScansCount;
        },
        mobileRulesConfirmation() {       
            return this.isUsePrepaidScans && this.isAvaliblePrepaidScan 
                && !this.isDesktop ;
        },
        mobileRulesOfConfirmButton() {
            return !this.mobileRulesConfirmation && this.stepNumber !== 3;
        },
    },  
    watch: {
        showingStep(newVal) {
            if (newVal === 'details') {
                this.addDragAndDropListeners();
            } else {
                this.removeDragAndDropListeners();
            }
        },
        stepNumber(newVal) {
            if (newVal === 1) {
                this.addDragAndDropListeners();
            } else {
                this.removeDragAndDropListeners();
            }
        },
        shouldUserPay(val) {       
            if (this.isUsePrepaidScans && val && this.isDesktop) {
                this.shouldUserPay = val;
                this.stepNumber = 1;  
            } else if (this.isUsePrepaidScans && !val && this.isDesktop){
                this.shouldUserPay = val;
                this.stepNumber = 3;          
            }
        },      
        activationCodeError: {
            handler({ message, statusCode }) {
                if (message === 'Invalid code') {
                    this.$refs.orderSummaryMobile?.showError();
                    this.$refs.orderSummary?.showError();
                    this.activationCodeInputError = true;
                    this.activationCodeNotification('invalid');
                } else if (message === 'The code has already been redeemed' && statusCode === 503) {
                    this.$refs.orderSummaryMobile?.showError();
                    this.$refs.orderSummary?.showError();
                    this.activationCodeInputError = true;
                    this.activationCodeNotification('different');
                } else if (statusCode === 200) {
                    this.isUsePrepaidScans = true;
                    this.activationCode = null;
                    this.showActivationCodeButtons = false;
                    this.activationCodeNotification('success');
                }
            },
            deep: true,
        },
        isUsePrepaidScans() {
            this.uploadedScansList = [...this.allPreparedFiles(this.uploadedScansList, this.isUsePrepaidScans, this.availableScansCount, this.selectedScanPack)];
        },
        selectedScanPack() {
            this.uploadedScansList = [...this.allPreparedFiles(this.uploadedScansList, this.isUsePrepaidScans, this.availableScansCount, this.selectedScanPack)];
        },
        availableScansCount() {
            this.uploadedScansList = [...this.allPreparedFiles(this.uploadedScansList, this.isUsePrepaidScans, this.availableScansCount, this.selectedScanPack)];
        },
    },
    async beforeMount() {
        this.$store.commit('user/changeStatusAuthenticating', false);
        // eslint-disable-next-line no-undef
        this.stripe = Stripe(STRIPE_PUBLIC_KEY);
        this.stripeElements = this.stripe.elements();
        await this.$store.dispatch('stripe/fetchCards');
        await this.$store.dispatch('scanPacks/getUserAvailableScans');
        await this.$store.dispatch('scanPacks/getScanPacksInfo');
        if (!this.availableScansCount) {
            this.isUsePrepaidScans = false;
        }
        this.stepNumber = this.isUsePrepaidScans && this.isDesktop ? 3 : this.stepNumber;
        this.isPageLoading = false;
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        this.removeDragAndDropListeners();
    },
    methods: { 
        filesUploaded() {
            return this.uploadedScansList.filter(file => file.status === 'success');
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        onDragEnter() {
            this.showDropZone = true;
        },
        onDragLeave({ screenX, screenY }) {
            if (!screenX && !screenY) {
                this.showDropZone = false;
            }
        },
        onDrop(event) {
            event.preventDefault();
            this.showDropZone = false;
        },
        addMoreFiles() {
            document.querySelector('#dropzone').click();
        },
        onFilesEdit(editing) {
            this.isFilesEditing = editing;
        },
        onFilesAdded(files) {
            this.uploadedScansList = this.allPreparedFiles(files, this.isUsePrepaidScans, this.availableScansCount, this.selectedScanPack);
            this.showingStep = 'details';
            this.isFilesUploading = true;
            this.showDropZone = false;
        },
        onUploading(files) {
            this.uploadedScansList = this.allPreparedFiles(files, this.isUsePrepaidScans, this.availableScansCount, this.selectedScanPack);
        },
        onQueueComplete(files) {
            this.isFilesUploading = false;
            this.uploadedScansList = this.allPreparedFiles(files, this.isUsePrepaidScans, this.availableScansCount, this.selectedScanPack);
        },
        onFileRemoved(files) {
            this.uploadedScansList = this.allPreparedFiles(files, this.isUsePrepaidScans, this.availableScansCount, this.selectedScanPack);
            if (!files.length) {
                this.showingStep = 'dropzone';
            }
        },
        removeFile(file) {
            this.$refs.dropzone.removeFile(file);
        },
        onPrepaidSelected(isSelected) {
            this.isUsePrepaidScans = isSelected;
            this.stepNumber = this.isUsePrepaidScans && this.isDesktop ? 3 : 1;
        },
        onSendEmailChecked(checked) {
            this.isNotifyByEmail = checked;
        },
        onScanPackSelect(packId) {
            if (!packId) this.selectedScanPack = null;
            this.selectedScanPack = this.scanPacksInfo.find(pack => pack.id === packId);
        },
        onPaymentCardSelected(cardId) {
            this.paymentCardSelected = cardId;
            if ((this.isMobile || this.isTablet) && cardId !== null) this.hidePay();
        },
        moveBack() {
            if (this.stepNumber > 1) this.stepNumber--;
        },
        moveNext() {
            if (this.stepNumber < 3) this.stepNumber++;
        },
        disableConfirmButton() {
            return this.isFilesUploading
                || !this.filesUploaded().length
                || (this.shouldUserPay && !this.paymentCardSelected && !this.correctNewCard && this.stepNumber === 3);
        },
        disableNextButton() {
            return this.isFilesUploading
                || !this.filesUploaded().length;
        },
        shouldPay(pay) {
            return this.shouldUserPay = !!pay ;
        },
        onSaveCard(isSave) {
            this.isSaveCard = isSave;
        },
        async confirmScan() {
            this.isPaying = true;
            const files = this.uploadedScansList
                .filter(file => file.status === 'success')
                .map(file => ({
                    sourceBucketKey: file.bucketKey,
                    fileName: file.name,
                    platform: file.platform,
                    jobType: file.jobType,
                }));
            const payRequest = {
                files: files,
                isNotifyByEmail: this.isNotifyByEmail,
                isUsePrepaidScans: this.isUsePrepaidScans,
                scanPackId: this.selectedScanPack && this.selectedScanPack.id,
            };
            let response;
            try {
                if (!this.shouldUserPay) {
                    payRequest.isNewCard = false;
                } else if (this.shouldUserPay && this.correctNewCard) {
                    const { token } = await this.stripe.createToken(this.cardElement.cardNumber);
                    if (token) {
                        payRequest.isNewCard = true;
                        payRequest.cardTokenId = token.id;
                        payRequest.isSaveCard = this.isSaveCard;
                    }
                } else if (this.shouldUserPay && this.paymentCardSelected && !this.correctNewCard) {
                    payRequest.cardId = this.paymentCardSelected;
                    payRequest.isNewCard = false;
                }
                const data = await this.axios.post('/private/ui/pay/scans', payRequest)
                    .then(res => res.data)
                    .catch(res => res);
                response = data.response;
                if (data.paid) {
                    await this.$store.dispatch('user/fetchUserInfo');
                    await this.$router.push('/scans');
                } else {
                    if (response.status === 402) {
                        this.paymentCardNotification('declined');
                        this.$refs.paymentCards.clearNewCard();
                    } else if (response.status === 422) {
                        this.failNotification();
                        this.$refs.paymentCards.clearNewCard();
                    } else {
                        await this.$store.dispatch('user/fetchUserInfo');
                        await this.$router.push('/scans');
                    }
                }
                this.isPaying = false;
            } catch (error) {
                if (response.status === 500) {
                    this.failNotification();
                }
                this.isPaying = false;
            }
        },
        showPay() {
            this.$refs.paymentCards.$el.style.display = 'block';
            this.$refs.orderSummaryMobile.$el.style.display = 'none';
        },
        hidePay() {
            this.$refs.paymentCards.$el.style.display = 'none';
            this.$refs.orderSummaryMobile.$el.style.display = 'flex';
        },
        async confirmActivationCode() {
            this.isPaying = true;
            await this.$store.dispatch('coupons/activateCodeAction', { activationCode: this.activationCode });
            this.isPaying = false;
        },
        cancelActivationCode() {
            this.showActivationCodeButtons = false;
        },
        enterActivationCode(code) {
            this.activationCode = code;
            if (this.activationCodeInputError) this.activationCodeInputError = false;
        },
        onNewCardChange(complete) {
            this.correctNewCard = complete;
        },
        addDragAndDropListeners() {
            window.addEventListener('dragenter', this.onDragEnter);
            window.addEventListener('dragleave', this.onDragLeave);
            window.addEventListener('dragover', event => event.preventDefault());
            window.addEventListener('drop', this.onDrop);
        },
        removeDragAndDropListeners() {
            window.removeEventListener('dragenter', this.onDragEnter);
            window.removeEventListener('dragleave', this.onDragLeave);
            window.removeEventListener('dragover', event => event.preventDefault());
            window.removeEventListener('drop', this.onDrop);
        },
        allPreparedFiles(files, isPrepaid, prepaidAmount = 0, scanPack) {
            const inProgress = files.filter(file => file.status !== 'success');
            const uploaded = files.filter(file => file.status === 'success');
            uploaded.sort((a, b) => a.order - b.order);
            uploaded.forEach((file, index) => {
                if (isPrepaid && prepaidAmount !== 0 && index+1 <= prepaidAmount) {
                    file.jobType = 'PREPAID';
                } else if (scanPack && scanPack.number !== 0 && index+1 <= prepaidAmount+scanPack.number) {
                    file.jobType = 'PREPAID';
                    file.isScanPack = true;
                } else {
                    file.jobType = 'PAID';
                }
            });
            return [...inProgress, ...uploaded];
        },
        onAdviceClose() {
            this.isHideAdvise = true;
            this.$cookies.set('hide_advice', true, 60 * 60 * 24 * 365);
        },
        openDropzone() {
            document.querySelector('#new-dropzone').click();
        },
        activationCodeNotification,
        paymentCardNotification,
        failNotification,
    },
};
</script>

<style lang="scss">
@import "@/assets/css/variables";

.outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    gap: 20px;
    background-color: $background-grey;
    font-family: 'Aspekta';
    &.mobile, &.tablet.dropzone-outer {
        background-color: #fff;
    }
    .dropzone-page {
        min-width: 80vw;
        align-self: center;
        &__main {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 0 auto;
            max-width: 60vw;
        }
    }
    .scan-details__wrapper {
        display: flex;
        gap: 80px;
        .scan-steps__wrapper {
            display: flex;
            flex-direction: column;
            gap: 20px;
            position: relative;
            .advice-message {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                padding: 20px 30px;
                width: 100%;
                font-size: 18px;
                color: $secondary-text;
                border-radius: 20px;
                background-color: $background-grey;
                &-close {
                    background-color: transparent;
                    font-family: sans-serif;
                    font-weight: 200;
                    font-size: 20px;
                    color: $secondary-text;
                    &::before {
                        content: "\2715";
                    }
                }
            }
            .skip-step {
                color: $ghost-text;
                margin-left: auto;
                background-color: transparent;
            }
            .add-more {
                margin-left: auto;
                border: none;
                background-color: transparent;
                z-index: 10;
                color: $main-blue-color;
                .plus-icon {
                    font-size: 22px;
                    font-family: serif;
                    font-weight: 100;
                    margin-right: 5px;
                }
            }
        }
    }
}

.step-control-buttons {
    &-mobile, &-ipad {
        display: none;
    }
}
.order-summary__wrapper-mobile {
    display: none;
}

.spinner__paying {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    padding-top: 10%;
    background-color: #ffffff9a;
}

@media (max-width: 1500px) {
    .outer-container {
        .scan-details__wrapper {
            gap: 40px;
            .scan-steps__wrapper {
                gap: 10px;
            }
        }
    }
}
@media (max-width: 900px) {
    .outer-container {
        .dropzone-page {
            &__main {
                max-width: 80vw;
            }
        }
    }
    .step-control-buttons {
        &-mobile, &-desktop {
            display: none;
        }
        &-ipad {
            display: flex;
            width: 60%;
            margin-left: auto;
        }
    }
    .order.order-summary__wrapper-mobile {
        padding-top: 0;
    }
    .order-summary__wrapper-mobile {
        display: flex;
    }
    .order-summary__wrapper {
       display: none;
    }
    .payments-card__wrapper {
        display: none;
    }
}
@media (max-width: 505px) {
    .outer-container {
        padding: 10px 0;
    }
    .step-control-buttons {
        &-desktop, &-ipad {
            display: none;
        }
        &-mobile {
            display: flex;
            width: 100%;
            position: fixed;
            bottom: 0;
            background-color: #F8FAFC;
            padding: 10px 20px 30px;
        }
    }
}
</style>
