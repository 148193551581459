import Vue from 'vue'

export const state = () => ({
    responseAfterCreatedToken: null,
    apiTokensList: null,
})

export const mutations = {
    setCreatedTokenData: (state, response) => {
        state.responseAfterCreatedToken = response
    },
    setApiTokensList: (state, response) => {
        state.apiTokensList = response
    },
}

export const actions = {
    async getApiTokens({commit}, payload) {
        try {
            const response = await Vue.axios.get(`/private/ui/api_keys`)
            commit('setApiTokensList', response.data)
        } catch (e) {
            console.log(e)
        }
    },

    async deleteToken({dispatch}, id) {
        try {
            await Vue.axios.delete(`/private/ui/api_keys/${id}`)
        } catch (error) {
            console.log(error)
        }
    },

    async createNewToken({commit}, payload) {
        try {
            const response = await Vue.axios.post(`/private/ui/api_keys`, payload)

            commit('setCreatedTokenData', response.data)
        } catch (error) {
            console.log(error)
        }
    }
}

export const getters = {
    responseAfterCreatedToken: s => s.responseAfterCreatedToken,
    apiTokensList: s => s.apiTokensList,

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
