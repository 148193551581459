<template>
    <button
        class="orange-button"
        :class="{ 'no-icon-button': !displayIcon }"
        @mousedown="isClicked = !isClicked"
        @mouseup="isClicked = !isClicked"
        @click="requestDemo"
    >
        {{ title }}
        <TopArrowIcon
            v-if="displayIcon"
            :clicked="isClicked"
            class="arrow-icon"
        />
    </button>
</template>
<script>
import TopArrowIcon from '../../assets/iconTemplates/TopArrowIcon.vue';
export default {
    components: {
        TopArrowIcon,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        displayIcon: {
            type:  Boolean,
            default: true,
        },
        isBlogCard: {
            type:  Boolean,
            default: false,
        },
    },
    data() {
        return {
            isClicked: false,
        };
    },
    methods:{
        requestDemo() {
            if(!this.isBlogCard) {
                this.$router.push('/request-demo');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/variables";
@import "../../assets/css/fonts";

    .arrow-icon {
        height: 30px;
        width: 30px;
    }

    .orange-button {
        @include Aspekta-font(650, $dynamic-main-check-button-font-size, normal, #FFFFFF);
            background-color: $new-orange-color;
            border: 2px solid linear-gradient(0deg, rgba(255, 122, 8, 0.70) 100%), var(--white, #FFF);
            border-radius: 36px;
            padding: 15px 15px 15px 30px;
            text-decoration: none;
            display: flex;
            gap: 15px;
            align-items: center;

            &:hover {
                background: linear-gradient(0deg, rgba(255, 122, 8, 0.70) 0%, rgba(255, 122, 8, 0.70) 100%), var(--white, #FFF);
                border: 2px solid linear-gradient(0deg, rgba(255, 122, 8, 0.70) 0%, rgba(255, 122, 8, 0.70) 100%), var(--white, #FFF);
                animation-duration: 150ms;
                animation-timing-function: ease-out;
            }

            &:active {
                color: #242424;
                border: 2px solid linear-gradient(0deg, rgba(255, 122, 8, 0.70) 0%, rgba(255, 122, 8, 0.70) 100%), var(--white, #FFF);
                background: var(--white, #FFF);
                animation-duration: 200ms;
                animation-timing-function: ease-out;
            }
    }

    .no-icon-button {
        padding: 18px 30px;
    }

    .no-icon-button:active {
        color: $new-orange-color;
        border-color: $new-orange-color;
    }

    @media (max-width: 768px) {
        .orange-button {
            gap: 8px;
            width: max-content;
        }

        .arrow-icon {
            height: 22px;
            width: 22px;
        }
    }
</style>
