<template>
    <div class="scan-history-table__wrapper">
        <div v-if="jobsHistory">
            <div
                v-if="jobsHistory && jobsHistory.length > 0"
                class="scan-list-block"
            >
                <b-table
                    id="scan-history-table"
                    hover
                    :items="jobsHistory"
                    :fields="jobsFields"
                    :current-page="currentPage"
                    :thead-class="`table-header table-header__settings`"
                    class="history__table cabin"
                >
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(file_name)="data">
                        {{ data.item.fileName }}
                    </template>
                    <template #cell(completionTime)="data">
                        <span
                            v-if="!isScanInProgress(data.item.status) || data.item.isDeleted"
                            :style="isLessThenOneMinuteTime(data.item.completionTime)? 'color: #61D4B3;' : ''"
                        >
                            {{ createDateView(data.item.completionTime) }}
                        </span>
                        <span v-else>-</span>
                    </template>
                    <template #cell(status)="data">
                        <div
                            v-if="data.item.isDeleted"
                            class="status-text in_queue nowrap"
                        >
                            Deleted
                        </div>
                        <div
                            v-else-if="isCreated(data.item.status)"
                            class="status-text in_queue nowrap"
                        >
                            Created
                        </div>
                        <div
                            v-else-if="isScanning(data.item.status)"
                            class="status-text scanning nowrap"
                        >
                            Scanning
                        </div>
                        <div
                            v-else-if="isFailed(data.item.status)"
                            class="status-text failed nowrap"
                        >
                            Failed
                        </div>
                        <div
                            v-else-if="isInQueue(data.item.status)"
                            class="status-text in_queue nowrap"
                        >
                            In queue
                        </div>
                        <div
                            v-else-if="isFinished(data.item.status)"
                            class="status-text nowrap"
                        >
                            Finished
                        </div>
                    </template>
                    <template #cell(shared)="data">
                        <img
                            v-if="data.item.shared"
                            src="../../assets/images/myScans/icon_action_show.svg"
                            alt="icon_action_show"
                        >
                        <img
                            v-else
                            src="../../assets/images/myScans/icon_action_hide.svg"
                            alt="icon_action_show"
                        >
                    </template>
                </b-table>
                <Pagination
                    class="scan-history-table__pagination"
                    :count="countJobsHistory"
                    :limit="perPage"
                    :current-page="currentPage"
                    :show-spinner="isShowSpinner"
                    @pageChanged="updatePage"
                />
            </div>
        </div>
        <div
            v-if="isShowSpinner"
            class="scan-history-table__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import Spinner from '../Spinner';
import { JobStatusEnum } from '../../utils/constants/common';
import { mapGetters } from 'vuex';
import { checkAndUpdatePlatformText, createDateView, isLessThenOneMinuteTime } from '../../services/functions';
import Pagination from '../Pagination.vue';

export default {
    components: {
        Spinner,
        Pagination,
    },
    data() {
        return {
            isShowSpinner: false,
            jobsFields: [
                {
                    key: 'platform',
                    label: 'Platform',
                    tdClass: ['table-cell', 'table-column-text-platform', 'table-column-width-10', 'center', 'small-permission__platform'],
                    formatter: 'checkAndUpdatePlatformText',
                },
                {
                    key: 'file_name',
                    label: 'Original File Name',
                    tdClass: ['table-cell', 'table-column-text-name', 'table-column-width-30', 'left', 'small-permission__name'],
                },
                {
                    key: 'appId',
                    label: 'App ID',
                    tdClass: ['table-cell', 'table-column-package-name', 'table-column-width-25', 'center', 'small-permission__package'],
                },
                {
                    key: 'version',
                    label: 'Version',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-5', 'center', 'small-permission__version'],
                },
                {
                    key: 'completionTime',
                    label: 'Completion Time',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-15', 'center', 'status-text in_queue', 'small-permission__updatedAt'],
                },
                {
                    key: 'status',
                    label: 'Status',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-15', 'center', 'small-permission__status'],
                },
                {
                    key: 'shared',
                    label: 'Visibility',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-5', 'center', 'small-permission__shared'],
                },
            ],
            currentPage: 1,
            perPage: 10,
        };
    },
    computed: {
        ...mapGetters({
            jobsHistory: 'jobs/jobsHistory',
            countJobsHistory: 'jobs/countJobsHistory',
        }),
    },
    watch: {
        async currentPage() {
            this.isShowSpinner = true;
            await this.$store.dispatch('jobs/loadJobsHistory', this.currentPage);
            this.isShowSpinner = false;
            setTimeout(function () {
                document.querySelector('body').scrollIntoView();
            }, 0);
        },
    },
    async beforeMount() {
        this.isShowSpinner = true;
        await this.$store.dispatch('jobs/loadJobsHistory');
        this.isShowSpinner = false;
    },
    methods: {
        createDateView,
        isLessThenOneMinuteTime,
        checkAndUpdatePlatformText,
        updatePage(page) {
            this.currentPage = page;
        },
        isCreated(status) {
            return status === JobStatusEnum.CREATED;
        },
        isScanning(status) {
            return status === JobStatusEnum.SCANNING;
        },
        isFailed(status) {
            return status === JobStatusEnum.ERROR;
        },
        isInQueue(status) {
            return status === JobStatusEnum.IN_QUEUE;
        },
        isFinished(status) {
            return status === JobStatusEnum.FINISHED;
        },
        isScanInProgress(status) {
            return this.isCreated(status) || this.isInQueue(status) || this.isScanning(status);
        },
        currencyConverter(value) {
            return `$${value / 100}`;
        },
    },
};
</script>
<style lang="scss">
.scan-history-table {
    &__wrapper {
        position: relative;
        min-height: 200px;
        width: 100%;

        .nowrap {
            white-space: nowrap;
        }

        .scan-list-block {
            width: 100%;
        }
    }

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(255,255,255,0.7);
        z-index: 10;
    }

    &__pagination {
        padding: 0 0 10px 0;
    }
}

@media (max-width: 768px) {
    .scan-history-table {
        &__pagination {
            justify-content: space-between;
            margin-bottom: 40px;
        }
    }
}
</style>
