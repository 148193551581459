<template>
    <div class="brand-must-be-protected">
        <div>
            <img
                v-if="isMobile"
                class="title-mobile"
                src="../../../assets/images/brand-must-be-protected_mobile.svg"
                alt="text"
            >
            <img
                v-else
                class="title-desktop"
                src="../../../assets/images/brand-must-be-protected.svg"
                alt="text"
            >
        </div>
        <div class="cards-column">
            <div class="cards-row">
                <Card
                    number="95"
                    :text="textDescription1"
                    sign="%"
                />
                <Card
                    number="78"
                    :text="textIOS"
                    sign="%"
                />
                <Card
                    number="94"
                    :text="forAndroid"
                    sign="%"
                />
                <Card
                    number="80"
                    :text="forBusiness"
                    sign="%"
                />
            </div>
            <div class="cards-row">
                <Card
                    number="280"
                    :text="textDescription2"
                    sign="days"
                />
                <Card
                    number="Every App"
                    :text="textDescription3"
                    sign=""
                />
            </div>
        </div>
    </div>
</template>
<script>
import Card from './Card.vue';

export default {
    components: { Card },
    data() {
        return {
            windowWidth: window.innerWidth,
            textIOS: 'of iOS apps were found to be vulnerable',
            forAndroid: 'of Android apps were found to be vulnerable',
            forBusiness: 'of businesses take weeks to realize they’ve been hacked',
            textDescription1: 'of all data breaches are the result of human error',
            textDescription2: 'are needed to detect and address a data breach, on average',
            textDescription3: 'Every App Release has a significant security issue, on average',
        };
    },
    computed: {
        isMobile() {
            return this.windowWidth < 768;
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },

};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/variables.scss";
@import "../../../assets/css/fonts";


.brand-must-be-protected{
    position: relative;
    width: 100vw;
    background-color: var(--dark-blue, #02142C);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 160px 0 ;
}
.title{
    margin: 0 0 99px;

    &-desktop{
        width: $dynamic-width-title-brand-must-be-protected;
    }
    &-mobile{
        width: $dynamic-width-title-brand-must-be-protected-mobile;
    }

}

.cards-column{
    margin: 99px $dynamic-card-column-margin 0;
    display: flex;
    flex-direction: column;
    gap: $dynamic-cards-gap;

}
.cards-row{
    display: flex;
    gap: $dynamic-cards-gap;
}

@media (max-width: 768px) {
    .brand-must-be-protected{
        padding: 80px 0 ;
    }
    .cards-row{
        flex-direction: column;
        gap: #{$min-cards-gap}px;
    }
    .cards-column {
        margin-top: 60px;
        width: 90%;
        gap: #{$min-cards-gap}px;
    }
    .title-main{
        width: #{$max-width-title-brand-must-be-protected}px;
    }
}


</style>
