<template>
    <div class="scan-packs">
        <b-container class="scan-packs__container">
            <h1 class="scan-packs__title">
                Single Scan Packs for Individuals
            </h1>
            <h2 class="scan-packs__sub-title">
                Please select how many scans you'd like:
            </h2>
            <div class="scan-packs__radio-box">
                <div
                    v-for="scanPack in scanPacksInfo"
                    :key="scanPack.id"
                    class="scan-packs__radio-box-wrapper"
                >
                    <input
                        :id="scanPack.id"
                        class="input-radio"
                        type="radio"
                        name="pack"
                        :value="scanPack.id"
                        :checked="selectedScanPackId === scanPack.id"
                        @change="handleScanPackInput"
                    >
                    <label
                        :for="scanPack.id"
                        class="label"
                    >
                        <span class="label__title">{{ scanPack.number }} scans</span>
                        <span
                            class="label__box"
                            style="display: flex; flex-direction: row;"
                        >
                            <span class="label__text">
                                $ {{ convertCentsToDollars(scanPack.price) }} per scan
                            </span>
                            <span class="label__radio-toggle" />
                        </span>
                    </label>
                </div>
            </div>
            <p class="scan-packs__text">
                The selected amount of packs will be ready for use immediately following payment.
            </p>
        </b-container>
        <div class="scan-packs__background">
            <b-container class="scan-packs__container scan-packs__payment-container">
                <div class="scan-packs__payment-box">
                    <h2 class="scan-packs__payment-title">
                        Payment Details
                    </h2>
                    <div>
                        <form class="form-scan-packs">
                            <div
                                v-for="card in cards"
                                :key="card.id"
                                class="pay-from-card"
                            >
                                <input
                                    :id="card.id"
                                    :key="card.id"
                                    v-model="selectedCard"
                                    class="test"
                                    name="pay-card-radio"
                                    type="radio"
                                    :value="card.id"
                                    @change="cardRadioHandler"
                                >
                                <label :for="card.id">
                                    <div class="pay-section__card">
                                        <div
                                            v-if="card.brand ==='Visa'"
                                            class="card-wrapper"
                                        >
                                            <img
                                                class="svg__card-icon"
                                                src="../../assets/images/stripe/visa.svg"
                                                alt="visa"
                                            >
                                        </div>
                                        <div
                                            v-if="card.brand ==='Discover'"
                                            class="card-wrapper"
                                        >
                                            <img
                                                class="svg__card-icon"
                                                src="../../assets/images/stripe/discover.svg"
                                                alt="discover"
                                            >
                                        </div>
                                        <div
                                            v-if="card.brand ==='Diners Club'"
                                            class="card-wrapper"
                                        >
                                            <img
                                                class="svg__card-icon"
                                                src="../../assets/images/stripe/diners-club.svg"
                                                alt="diners-club"
                                            >
                                        </div>
                                        <div
                                            v-if="card.brand ==='UnionPay'"
                                            class="card-wrapper"
                                        >
                                            <img
                                                class="svg__card-icon"
                                                src="../../assets/images/stripe/unionpay.svg"
                                                alt="unionpay"
                                            >
                                        </div>
                                        <div
                                            v-if="card.brand ==='American Express'"
                                            class="card-wrapper"
                                        >
                                            <img
                                                class="svg__card-icon"
                                                src="../../assets/images/stripe/american-express.svg"
                                                alt="american-express"
                                            >
                                        </div>
                                        <div
                                            v-if="card.brand ==='JCB'"
                                            class="card-wrapper"
                                        >
                                            <img
                                                class="svg__card-icon"
                                                src="../../assets/images/stripe/jcb.svg"
                                                alt="jcb"
                                            >
                                        </div>
                                        <div
                                            v-if="card.brand ==='MasterCard'"
                                            class="card-wrapper"
                                        >
                                            <img
                                                class="svg__card-icon"
                                                src="../../assets/images/stripe/master-card.svg"
                                                alt="master-card"
                                            >
                                        </div>
                                        <div
                                            v-if="card.brand ==='Unknown'"
                                            class="card-wrapper"
                                        >
                                            <img
                                                class="svg__card-icon"
                                                src="../../assets/images/stripe/unknown.svg"
                                                alt="unknown"
                                            >
                                        </div>
                                    </div>
                                    {{ cardText(card.last4) }}
                                </label>
                            </div>
                            <b-form-radio
                                id="NEW_CARD"
                                v-model="selectedCard"
                                :class="isAddCardFormActive ? 'pay-section__scan-packs__cards-radio-btn' : 'pay-section__scan-packs__cards-radio-btn__opened'"
                                name="add-card-radio"
                                value="NEW_CARD"
                                @change="cardRadioHandler"
                            >
                                <span class="pay-section__title">Add new card</span>
                            </b-form-radio>
                        </form>
                    </div>
                    <div
                        v-if="isAddCardFormActive"
                        class="scan-packs__new-card"
                    >
                        <NewCard
                            :card-element="cardElement"
                            :elements="elements"
                        />
                        <span
                            id="error-card"
                            class="error-card"
                        />
                    </div>
                </div>
                <div class="scan-packs__order-box">
                    <h2 class="scan-packs__payment-title">
                        Order Summary
                    </h2>
                    <div class="scan-packs__order-sub-box">
                        <p class="scan-packs__price-title">
                            Total cost
                        </p>
                        <p class="scan-packs__price">
                            {{ getFinalScanPackPrice(selectedScanPackId, scanPacksInfo) }} USD
                        </p>
                    </div>
                    <div class="scan-packs__payment-radio-box">
                        <div>
                            <input
                                id="activationCode"
                                class="input-radio"
                                type="radio"
                                name="purchaseType"
                                :value="paymentType.activationCode"
                                checked
                                @change="handlePaymentTypeInput"
                            >
                            <label
                                for="activationCode"
                                class="label"
                            >
                                Get activation code
                            </label>
                        </div>
                        <div>
                            <input
                                id="currentAccount"
                                class="input-radio"
                                type="radio"
                                name="purchaseType"
                                :value="paymentType.currentAccount"
                                @change="handlePaymentTypeInput"
                            >
                            <label
                                for="currentAccount"
                                class="label"
                            >
                                Charge to current account
                            </label>
                        </div>
                    </div>
                    <button
                        id="pay-btn"
                        :disabled="!isCardSelected()"
                        class="scan-packs__btn btn-black"
                        :class="{ disabledBtn: !isCardSelected() }"
                        @click="handlePayment(selectedScanPackId, scanPacksInfo, selectedPaymentType)"
                    >
                        Pay
                    </button>
                    <span
                        id="error-pay"
                        class="error-pay"
                    />
                </div>
            </b-container>
        </div>
        <div
            v-if="showActivationCodeModal"
            class="activation-code-modal__wrapper"
        >
            <div class="activation-code-modal">
                <div
                    class="activation-code-modal__close-btn"
                    @click="toggleModal()"
                >
                    <img
                        src="../../assets/images/gray_cross.svg"
                        alt=""
                    >
                </div>
                <h4 class="activation-code-modal__title">
                    Activation code successfully generated!
                </h4>
                <p class="activation-code-modal__text">
                    An email with equal code has been sent
                </p>
                <p class="activation-code-modal__code">
                    {{ activationCode }}
                </p>
                <button
                    class="activation-code-modal__copy-btn"
                    @click="copyCode(activationCode)"
                >
                    Copy To Clipboard
                    <img
                        src="../../assets/images/clipboardCopy.svg"
                        alt=""
                    >
                    <span
                        v-if="isCopiedCode"
                        class="activation-code-modal__copy-link-message"
                        v-text="'Code Copied!'"
                    />
                </button>
            </div>
        </div>
        <div
            v-if="showSpinner"
            class="scan-packs__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import { store } from '../../store';
import {
    convertCentsToDollars,
    isArrayNotEmpty,
} from '../../services/functions';
import { cardText } from '../../services/cards/getCardText';
import Spinner from '../../components/Spinner';
import NewCard from '../../components/NewCard';
import { failNotification, paymentCardNotification } from '@/services/notificationService/notificationService';

const STRIPE_PUBLIC_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY;

export default {
    components: {
        Spinner,
        NewCard,
    },
    data() {
        return {
            selectedScanPackId: null,
            selectedPaymentType: null,
            selectedCard: null,
            showSpinner: false,
            showModalSpinner: false,
            isAddCardFormActive: false,
            showActivationCodeModal: false,
            isCopiedCode: false,
            elements: null,
            isEmailValid: null,
            email: null,
            paymentType: {
                currentAccount: 'CURRENT_ACCOUNT',
                activationCode: 'ACTIVATION_CODE',
            },
            cardElement: {
                cardNumber: null,
                cardExpiry: null,
                cardCvc: null,
            },
        };
    },
    computed: {
        ...mapGetters({
            scanPacksInfo: 'scanPacks/scanPacksInfo',
            cards: 'stripe/cards',
            activationCode: 'scanPacks/activationCode',
            isSuccessPurchase: 'scanPacks/isSuccessPurchase',
        }),
    },
    async mounted() {
        this.showSpinner = true;
        await this.$store.dispatch('scanPacks/getScanPacksInfo');
        await this.$store.dispatch('stripe/fetchCards');
        this.stripe = Stripe(STRIPE_PUBLIC_KEY);
        this.elements = this.stripe.elements();
        if (!isArrayNotEmpty(this.cards)) {
            this.isAddCardFormActive = true;
            this.selectedCard = 'NEW_CARD';
        }
        this.selectedPaymentType = this.paymentType.activationCode;
        if (isArrayNotEmpty(this.scanPacksInfo)) {
            this.selectedScanPackId = this.scanPacksInfo[0].id;
        }
        this.showSpinner = false;
    },
    methods: {
        toggleModal() {
            this.showActivationCodeModal = false;
            this.email = null;
            this.$store.commit('scanPacks/setActivationCode', null);
        },
        copyCode(code) {
            let link = code;
            const el = document.createElement('textarea');
            el.value = link;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.isCopiedCode = true;
            setTimeout(() => {
                this.isCopiedCode = false;
            }, 3000);
        },
        handleScanPackInput(event) {
            this.selectedScanPackId = event.target.value;
        },
        handlePaymentTypeInput(event) {
            this.selectedPaymentType = event.target.value;
        },
        getFinalScanPackPrice(selectedScanPackId, scanPacksInfo) {
            let packPrice = 0;

            if (isArrayNotEmpty(scanPacksInfo)) {
                scanPacksInfo.forEach(scanPack => {
                    if (scanPack.id === selectedScanPackId) {
                        packPrice = scanPack.price * scanPack.number;
                    }
                });
            }

            return this.convertCentsToDollars(packPrice);
        },
        async handlePayment(selectedScanPackId, scanPacksInfo, selectedPaymentType) {
            this.showSpinner = true;
            const cardInfo = await this.getCardInfo();
            let scanPackInfo;

            if (isArrayNotEmpty(scanPacksInfo)) {
                scanPackInfo = scanPacksInfo.find(scanPack => scanPack.id === selectedScanPackId);
            }
            if (selectedPaymentType === this.paymentType.currentAccount && cardInfo) {
                try {
                    this.$store.commit('coupons/setActivationCodeError', null);
                    await this.$store.dispatch('scanPacks/currentAccountPayment', { scanPackId: scanPackInfo.id, cardInfo });
                    this.$router.push('/settings/scan-packs');
                }
                catch(error) {
                    const { status, data } = JSON.parse(error.message);
                    console.log(data);
                    if(status === 402) {
                        this.paymentCardNotification('declined');
                    } else {
                        this.failNotification();
                    }
                    store.commit('scanPacks/setPurchaseSuccessStatus', null);
                }
            } 
            else if (selectedPaymentType === this.paymentType.activationCode && cardInfo) {
                try {
                    await this.$store.dispatch('scanPacks/activationCodePayment', {
                        scanPackId: scanPackInfo.id,
                        cardInfo,
                    });
                    if (cardInfo.isNewCard) {
                        await this.$store.dispatch('stripe/fetchCards');
                        this.isAddCardFormActive = false;
                    }
                    store.commit('scanPacks/setPurchaseSuccessStatus', { status: true, message: 'scans were successfully purchased with activation code', paymentType: 'ACTIVATION_CODE' });
                    this.showActivationCodeModal = true;
                    this.selectedCard = null;
                } catch (error) {
                    const { status, data } = JSON.parse(error.message);
                    console.log(data);
                    if(status === 402) {
                        this.paymentCardNotification('declined');
                    } else {
                        this.failNotification();
                    }
                    store.commit('scanPacks/setPurchaseSuccessStatus', null);
                }
            }
            this.showSpinner = false;
        },
        async getCardInfo() {
            // const displayButton = document.getElementById('pay-btn')
            const displayError = document.getElementById('error-pay');
            displayError.textContent = '';
            // displayButton.disabled = true

            const cardInfo = {
                isNewCard: false,
                cardTokenId: null,
                cardId: null,
            };

            if (this.selectedCard === null || this.selectedCard === 'NEW_CARD') {
                const { token } = await this.stripe.createToken(this.cardElement.cardNumber);

                if (token) {
                    cardInfo.isNewCard = true;
                    cardInfo.cardTokenId = token.id;

                    return cardInfo;
                }
            } else {
                cardInfo.cardId = this.selectedCard;

                return cardInfo;
            }

            displayError.textContent = 'Please enter a valid credit card';

            return null;
        },
        cardRadioHandler(arg) {
            const displayError = document.getElementById('error-pay');
            displayError.textContent = '';
            this.isAddCardFormActive = arg === 'NEW_CARD';
            this.elements = this.stripe.elements();
        },
        isCardSelected() {
            return !!this.selectedCard;
        },
        isArrayNotEmpty,
        cardText,
        convertCentsToDollars,
        failNotification,
        paymentCardNotification,
    },
};
</script>
<style lang="scss">
.scan-packs {
    position: relative;

    * {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        color: #242424;
    }

    &__spinner {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.9);
        z-index: 10;
    }

    &__title {
        margin: 49px 0 19px;
        font-family: Raleway, serif;
        font-weight: bold;
        font-size: 36px;
    }

    &__sub-title {
        margin: 0 0 40px 0;
        font-weight: bold;
    }

    &__container {
        padding: 0 15px 45px 15px;
    }

    &__radio-box {
        display: flex;
        margin-bottom: 30px;

        &-wrapper {
            margin-right: 30px;
        }

        .input-radio {
            position: absolute;
            top: -9999px;
            left: -9999px;
            visibility: hidden;
        }

        .input-radio:checked + .label {
            border: 2px solid #1FA8C7;
        }

        .input-radio:checked + .label > .label__box > .label__radio-toggle {
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 12px;
                height: 12px;
                background: rgba(31, 168, 199, 0.4);
                border-radius: 50%;
            }
        }

        .label {
            position: static;
            display: flex;
            flex-direction: column;
            max-width: 210px;
            min-width: 210px;
            width: 100%;
            padding: 15px 30px 30px 20px;
            border: 2px solid rgba(31, 168, 199, 0.4);
            box-sizing: border-box;
            border-radius: 20px;
            cursor: pointer;

            &__title {
                font-weight: bold;
                white-space: nowrap;
                font-size: 36px;
                margin: 0;
            }

            &__box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 30px;
            }

            &__text {
                font-weight: bold;
                font-size: 17px;
                color: #706969;
                margin: 0;
            }

            &__radio-toggle {
                position: relative;
                margin: 0;
                background-color: white;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid #1FA8C7;
            }
        }
    }

    &__payment-radio-box {
        display: flex;
        flex-direction: column;

        .input-radio {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        .input-radio:checked + .label {
            &::after {
                content: "";
                position: absolute;
                left: 4px;
                top: 4px;
                width: 12px;
                height: 12px;
                background: rgba(31, 168, 199, 0.4);
                box-sizing: border-box;
                border-radius: 50%;
            }
        }

        .label {
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            padding-bottom: 5px;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                width: 20px;
                height: 20px;
                background: #FFFFFF;
                border: 1px solid #1FA8C7;
                box-sizing: border-box;
                border-radius: 50%;
            }
        }
    }

    &__text {
        color: #706969;
        margin: 0;
    }

    &__background {
        min-height: 40vh;
        background: #FAFBFC;
        border: 1px solid #F0F0F0;
        box-sizing: border-box;
    }

    &__payment-container {
        display: flex;
        padding: 50px 15px 50px 15px;
    }

    &__payment-title {
        font-size: 24px;
        font-weight: bold;
    }

    &__order-sub-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 450px;
    }

    &__price-title {
        font-size: 18px;
    }

    &__price {
        font-weight: bold;
        font-size: 36px;
    }

    &__payment-box {
        width: 43%;

        .pay-section__card {
            width: 60px;
            margin-bottom: 0px;
        }

        .pay-section__card-number {
            width: 100%;
        }
    }

    &__order-box {
        width: 57%;
    }

    &__btn {
        max-width: 450px;
        margin-top: 40px;
        height: 65px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 8px;
        font-weight: bold;
        font-size: 24px;
    }

    .btn-black {
        color: #FFFFFF;
        background: #242424;
        border: 2px solid #242424;
        transition: 0.3s;

        &:hover {
            opacity: 0.7;
        }
    }

    .disabledBtn {
        background-color: #7d7d7d;
        border: 2px solid #7d7d7d;
        opacity: 0.7;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        width: 10px;
        height: 10px;
        background: rgba(31, 168, 199, 0.4);
        top: 7px;
        left: -21px;
        border: none;
    }

    .pay-section__scan-packs__cards-radio-btn {
        label.custom-control-label {
            &::after {
                border-radius: 50%;
                background-color: #FAFBFC;;
                border: 1px solid #1FA8C7;
                top: 0.25rem;
                width: 22px;
                height: 27px;
            }

            &::before {
                background-color: #FAFBFC;;
                border: 1px solid #1FA8C7;
                position: absolute;
                top: 0.25rem;
                left: -1.5rem;
                display: block;
                width: 22px;
                height: 27px;
                pointer-events: none;
                content: "";
                background-color: #fff;
                border-radius: 50%;
            }
        }
    }
}

.pay-from-card {
    background-color: #fafbfc;
    display: block;
    margin: 10px 0;
    position: relative;

    label {
      padding: 12px 30px;
      margin: 4px 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
      color: #3C454C;
      cursor: pointer;
      position: relative;
      align-items: center;
      z-index: 2;
      transition: color 300ms ease-in;
      overflow: hidden;
      background: inherit;

      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        background-color: #fff;
        color: #242424;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }

      &:after {
        width: 22px;
        height: 22px;
        content: '';
        border: 2px solid #fafbfc;
        background-color: #fafbfc;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.70659 2.80829L3.37226 5.14429L1.57045 3.48411L0.03125 5.0341L3.37226 8.25895L8.98194 2.59159L9.96673 1.64764L8.42754 0.0976562L7.2932 1.23627L5.71023 2.83033L5.70659 2.80829Z' fill='%23fafbfc' /%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: 4px 4px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 300ms ease-in-out;
      }
    }

    input:checked ~ label {
        color: #242424;
        border: 1px solid #1FA8C7;
        border-radius: 10px;


      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }

      &:after {
        background-color: #fff;
        border: 2px solid #1FA8C7;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.70659 2.80829L3.37226 5.14429L1.57045 3.48411L0.03125 5.0341L3.37226 8.25895L8.98194 2.59159L9.96673 1.64764L8.42754 0.0976562L7.2932 1.23627L5.71023 2.83033L5.70659 2.80829Z' fill='%231FA8C7' /%3E%3C/svg%3E ");
        background-position: 4px 4px;
        background-repeat: no-repeat;
      }
    }

    input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
  }


// codepen formatting
.form-scan-packs {
  padding-right: 67px;
  max-width: 550px;
  margin: 27px 0 19px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  background-color: #fafbfc;
}

// Add New card
// -
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e ") !important;
    background: #fff;
    border: 1px solid #fff;
    background-repeat: no-repeat;
    border-radius: 0;
    background-position: 0px 3px;
}

.scan-packs .pay-section__scan-packs__cards-radio-btn label.custom-control-label::before {
    background-color: #FAFBFC;
    border: 1px solid #1FA8C7;
    position: absolute;
    top: 10px;
    left: -10px;
    display: block;
    width: 1px;
    height: 1px;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border-radius: 50%;
}

.scan-packs .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    width: 22px;
    height: 22px;
    background: #fafbfc;
    top: 4px;
    left: -24px;
    border: none;
}

.scan-packs .custom-radio .custom-control-input ~ .custom-control-label::after {
    width: 22px;
    height: 22px;
    background: #fafbfc;
    top: 4px;
    left: -24px;
    border: none;
}

.scan-packs .custom-radio .custom-control-input ~ .custom-control-label::before {
    background-color: #fafbfc;
    border: 1px solid #fafbfc;
    width: 1px;
    height: 1px;
    top: 5px;
}

.pay-section__scan-packs__cards-radio-btn .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #fff;
    border: 2px solid #1FA8C7;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.70659 2.80829L3.37226 5.14429L1.57045 3.48411L0.03125 5.0341L3.37226 8.25895L8.98194 2.59159L9.96673 1.64764L8.42754 0.0976562L7.2932 1.23627L5.71023 2.83033L5.70659 2.80829Z' fill='%231FA8C7' /%3E%3C/svg%3E");
    background-position: 4px 4px;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    top: 50%;
    -webkit-transform: translate(370px, -50%);
    transform: translate(310px, -50%);
}

.pay-section__scan-packs__cards-radio-btn, .pay-section__scan-packs__cards-radio-btn__opened {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 56px;
    padding-left: 60px;
    /* border: 1px solid; */
    border: 1px solid #1FA8C7;
    border-radius: 10px;

    &__opened {
        border: none;
    }
}

.pay-section__title {
    margin-left: 27px;
}

.error-pay {
    margin: 10px 0;
    display: flex;
    font-family: Cabin, serif;
    font-weight: bold;
    font-size: 16px;
    color: #FB8D62;
}

.activation-code-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 330px;
    min-width: 530px;
    padding: 70px 30px;
    background: #FFFFFF;
    border-radius: 20px;

    &__wrapper {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(65, 62, 62, 0.5);
    }

    &__close-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }

    &__input {
        background: #FFFFFF;
        width: 100%;
        padding: 10px 35px 10px 20px;
        border: 1px solid rgba(31, 168, 199, 0.4);
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Cabin, serif;
        font-size: 14px !important;
        color: #242424;

        &--error,
        &--validation-error,
        &--length-error {
            border: 1px solid #FB8D62;
            border-radius: 5px;
        }

        &__error-text {
            position: absolute;
            top: 0;
            left: 0;
            font-family: Cabin, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 150%;
            color: #FB8D62;
            margin: 0;
        }
    }

    &__title {
        font-family: Raleway, serif !important;
        font-weight: bold !important;
        font-size: 24px !important;
        color: #242424 !important;
    }

    &__text {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        color: #706969;
    }

    &__code {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #1FA8C7;
    }

    &__copy-btn {
        margin-top: 20px;
        background: #FFB400;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Cabin, serif;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        max-width: 253px;
        padding: 10px;
        width: 100%;
        position: relative;

        &:focus {
            border: 2px solid #FFB400;
        }
    }

    &__copy-link-message {
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translate(-50%);
        width: 90px;
        text-align: center;
        height: 23px;
        background-color: black;
        border-radius: 3px;
        color: white;
        font-family: Cabin, serif;
        font-size: 14px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__label {
        position: relative;
        width: 100%;
        max-width: 250px;
    }

    &__email-check {
        position: absolute;
        top: 15px;
        right: 20px;
    }

    &__error {
        width: 100%;
        max-width: 831px;
        position: relative;
    }

    &__error-text {
        position: absolute;
        top: 0;
        left: 0;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px !important;
        line-height: 150%;
        color: #FB8D62 !important;
        margin: 0;
    }

    &__spinner {
        position: absolute;
        top: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60%;
        background-color: white;
        border-radius: 20px;

        div.spinner-wrapper {
            padding: 0 !important;
        }
    }

    &__btn {
        height: 42px;
        max-width: 150px;
        width: 100%;
        background: #FFB400;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        font-weight: bold !important;
        color: #FFFFFF !important;
        transition: 0.3s;

        &:hover {
            opacity: 0.7;
        }
    }
}

#emailError {
    display: none;
}

@media (max-width: 992px) {

    .bv-no-focus-ring {
        width: 100% !important;
    }

    .scan-packs {
        &__payment-container {
            flex-direction: column;
        }

        &__payment-box,
        &__order-box {
            width: 100%;
        }

        &__radio-box {
            overflow: auto;
        }
    }
}

@media (max-width: 425px) {
    .scan-packs {
        &__container {
            padding: 0 15px 20px 15px;
        }

        &__title {
            margin: 20px 0 20px;
            font-size: 30px;
        }

        &__payment-title {
            font-size: 18px;
            margin: 20px 0;
        }

        &__sub-title {
            font-size: 14px;
        }

        &__text {
            font-size: 14px;
            max-width: 245px;
        }

        &__price {
            font-size: 24px;
        }

        &__payment-radio-box {
            .label {
                font-size: 14px;
            }
        }

        &__btn {
            max-width: 450px;
            margin-top: 40px;
            height: 46px;
            width: 100%;
            box-sizing: border-box;
            border-radius: 8px;
            font-weight: bold;
            font-size: 24px;
        }

        &__new-card {
            display: flex;
            justify-content: center;
        }
    }

    .activation-code-modal {
        min-width: 200px;
        padding: 30px 10px;
        margin: 0 10px;

        &__title {
            text-align: center;
            font-size: 18px !important;
        }

        &__text {
            text-align: center;
            font-size: 14px;
        }
    }

    .pay-from-card label,
    .pay-section__scan-packs__cards-radio-btn,
    .pay-section__scan-packs__cards-radio-btn__opened {
        width: 350px;
    }

    .scan-packs .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        left: -16px;
    }

    .pay-section__scan-packs__cards-radio-btn .custom-control-input:checked ~ .custom-control-label::before {
        transform: translate(246px, -50%);
    }
}
</style>
