<template>
    <div>
        <b-container class="paddings">
            <b-row>
                <b-col>
                    <h1 class="sign">PRIVACY</h1>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="last-update">Last updated: September 4, 2023</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <h2 class="privacy-subtitle">Your data is your data. Oversecured is committed to ensuring the privacy of
                        your data. We are further committed to preventing unauthorized access to that data. Our Privacy
                        Policy details what data is collected from our Clients, how we use it, and how it is stored.
                        Personal data processing is not a core activity of our vulnerability research services, as we
                        process only application files or source code without user information.</h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">1. WHO WE ARE</p>
                    <p class="content">Oversecured partners both with the global community of security researchers, who
                        identify relevant security issues in business products or services, as well as with integrated
                        companies that use our Services to improve the security of their applications or business
                        processes. Oversecured is a "Software as a Service" platform helping to identify errors and
                        vulnerabilities, known as the Oversecured Platform, and a website located at oversecured.com and
                        related domains and subdomains, as well as related services, including but not limited to
                        services for identifying application security vulnerabilities, and other services (collectively,
                        the “Services”). Oversecured is a corporation in Delaware. <br><br>
                        We respect your privacy and take safeguarding your data seriously. Please read this Privacy
                        Policy carefully together with the Terms of Use ("Terms"), which govern your use of the
                        Services, to understand what Personal Information (defined below) we collect from you, how we
                        use it, and your choices related to our use of your Personal Information. If you do not agree
                        with this Privacy Policy, please do not use the Services.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">2. WHAT IS PERSONAL INFORMATION?</p>
                    <p class="content">"Personal Information" means any information relating to an identified or
                        identifiable natural person; an identifiable natural person is one who can be identified,
                        directly or indirectly, in particular by reference to an identifier such as a name, an
                        identification number, location data, an online identifier or to one or more factors specific to
                        the physical, physiological, genetic, mental, economic, cultural, or social identity of that
                        natural person. Under specific laws, Personal Information may include any information relating
                        to a household.</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">3. PERSONAL INFORMATION WE PROCESS</p>
                    <p class="content">We process Personal Information that you actively submit to us, that we
                        automatically collect through your use of our Services, and that we collect from third-party
                        sources. We may process your Personal Information with or without automatic means, including
                        collection, recording, organization, structuring, storage, adaptation or alteration, retrieval,
                        consultation, use, disclosure by transmission, dissemination or otherwise making available,
                        alignment or combination, restriction, erasure, or destruction of your Personal Information. We
                        do not sell the Personal Information we collect to other parties. <br><br>
                        3.1 Personal Information that you actively submit to us.<br><br>
                        We collect Personal Information that you actively submit to us through your account, website
                        forms, email subscriptions, surveys, events, conferences, Services, inquiries, and other
                        interactions. You will know when we collect your Personal Information because we will directly
                        ask you for the information. We will require certain Personal Information in order for you to
                        use our Services or for us to be able to contact you. There may also be circumstances where
                        providing Personal Information is optional and does not impact your access to Services.<br><br>
                        3.1.1 Your Account. When you create an Oversecured account, you are required to provide us with
                        profile information, including your email address and password. Oversecured stores this
                        information to help identify you when you log in. At your option the website may remember you
                        for a limited period of time which will result in storage and processing of your data required
                        to identify you in the new sessions. Once you've registered, you create a user profile. Your
                        profile information includes your name, scan history, payment history, company name (if
                        applicable), and any other information you include in the relevant fields. You may need to
                        provide us with other personally identifying information necessary for background and fraud
                        checking purposes where required. <br><br>
                        3.1.2 Email Subscriptions. We actively communicate with subscribers through newsletters,
                        webinars, and education content, and also send emails about product updates, events, the status
                        of the Oversecured Platform, and updates to the third-party service providers (sub-processors)
                        used to process Personal Information. A subscriber may be required to provide their email
                        address and other contact information to receive communications.<br><br>
                        3.1.3 Surveys. We occasionally conduct surveys in order to gather data central to assessing our
                        business objectives and understanding our community. Participation in surveys is always
                        optional. Information provided in surveys is anonymized and aggregated for analysis.<br><br>
                        3.1.4 Applications. The applications of Oversecured customers (or decompiled source code)
                        provided within Integrations or Single Scans will be deleted once the scan is done. During the
                        scans Oversecured processes only the files and source code of the applications, and do not
                        process any Personal Information.<br><br>
                        3.1.5 Contact Us. There are multiple opportunities for you to contact us, including for support,
                        to report a bug, make a suggestion, make a sales inquiry, request a product demonstration,
                        request research, and for corporate service. Online forms collect Personal Information such as a
                        first name, last name, email address, company (if applicable), job title (if applicable), reason
                        for contact, and may provide an option to attach a file. When we contact you in response to your
                        request, we may collect additional Personal Information.<br><br>
                        3.2 Personal Information we automatically collect through your use of the Services.<br><br>
                        We receive some Personal Information automatically when you visit Oversecured Services. This
                        includes information about the device, browser, and operating system you use when accessing our
                        site and Services, your IP address, the website that referred you, which pages you request and
                        visit, and the date and time of each request you make. If you visit the Oversecured Platform
                        when you are logged into your account, we also collect the user identification number we assign
                        you when you open your account.<br><br>
                        3.3 Personal Information we collect from third-party sources.<br><br>
                        We are continually expanding our client reach. As part of our business-to-business marketing, we
                        collect Personal Information from third-party sources to identify individuals who hold relevant
                        job roles in key industries. Personal Information collected generally includes a first name,
                        last name, job title, company name, email address, and phone number. We generally communicate
                        via email or telephone to provide information about Oversecured and offer businesses an
                        opportunity to try out Oversecured Services.<br><br>
                        3.4 Personal Information of minors.<br><br>
                        Applicable laws may restrict our ability to collect Personal Information from minors unless we
                        have first obtained the consent of the minor's parent or guardian. Please note that the
                        definition of a minor varies by jurisdiction and various laws institute age-related
                        requirements. If you are considered a minor and want to get a scan report using our Services,
                        please ask a parent or guardian to submit it for you. Oversecured does not otherwise knowingly
                        collect Personal Information of minors, and the Oversecured Services are not directed to minors.
                        If we become aware that we have collected Personal Information from a minor in conflict with
                        applicable law, we will delete that information or obtain the requisite consent from the minor's
                        parent or guardian.<br><br>
                        3.5 Personal Information we collect using cookies and similar tracking technologies.<br><br>
                        We (and the third-party service providers working on our behalf) use various technologies to
                        collect Personal Information. This may include saving cookies to your device. For information on
                        what cookies are, which ones we use, why we use them, and how you can manage their use, please
                        see our
                        <b-nav-item class="privacy-policy__link" :to="{ path: '/cookies'}">Cookies Policy</b-nav-item>.</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">4. HOW WE USE YOUR PERSONAL INFORMATION</p>
                    <p class="content">We use your Personal Information to operate our Services, fulfill our contractual
                        obligations in our service contracts with our clients, to review and enforce compliance with our
                        Terms, guidelines, and policies, to analyze the use of the Services in order to understand how
                        we can improve our content and service offerings and products, and for administrative and other
                        business purposes. We process Personal Information for sales leads, subscription services,
                        payments, employee training, marketing, data analysis, security monitoring, auditing, research,
                        and to comply with applicable laws, exercise legal rights, and meet tax and other regulatory
                        requirements. <br><br>
                        In this context, the legal basis for our processing of your Personal Information is either the
                        necessity to perform contractual and other obligations, our legitimate business interest as a
                        provider of security services, regulatory requirements, or in some instances your explicit
                        consent.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">5. SHARING OF PERSONAL INFORMATION</p>
                    <p class="content">WE DO NOT SELL YOUR PERSONAL INFORMATION! We may share your Personal Information
                        in the following circumstances: <br><br>
                        5.1 Third-party Service Providers. <br><br>
                        We may share information we collect about you with third-party service providers to perform
                        tasks on our behalf in supporting the Services. The types of service providers, or
                        sub-processors, to whom we entrust Personal Information include: (i) payment providers; (ii)
                        providers of hosting services; (iii) sales and marketing providers; (iv) providers of document
                        and content management tools; (iv) providers of analytic data services; and (v) other services
                        such as system support, subscription services, verification, and ticketing.<br><br>
                        5.2 Regulatory Bodies, Public Authorities, and Law Enforcement.<br><br>
                        We may access and disclose your Personal Information to regulatory bodies if we have a
                        good-faith belief that doing so is required under applicable law or regulations. This may
                        include submitting Personal Information required by tax authorities. We may disclose your
                        Personal Information in response to lawful requests by public authorities or law enforcement,
                        including to meet national security or law enforcement requirements. If we are going to release
                        your Personal Information in this instance, our policy is to provide you with notice unless we
                        are prohibited from doing so by law or court order (including orders under 18 U.S.C. § 2705(b)).<br><br>
                        5.3 Merger, Sale, or Other Asset Transfers.<br><br>
                        If we are involved in a merger, acquisition, financing due diligence, reorganization,
                        bankruptcy, receivership, sale of company assets, or transition of service to another provider,
                        then your Personal Information may be disclosed or transferred as part of such a transaction as
                        permitted by law and/or contract. Should such an event occur, Oversecured will endeavor to
                        direct the transferee to use Personal Information in a manner that is consistent with the
                        Privacy Policy in effect at the time such Personal Information was collected.<br><br>
                        5.4 Other Disclosures.<br><br>
                        Where there is agreement with you that Scan reports are publicly disclosed, then certain
                        information about the report associated with your profile may be published through our Services.
                        We may share Personal Information with our affiliated companies. <br>
                        We may also disclose your Personal Information to exercise or defend legal rights; to take
                        precautions against liability; to protect the rights, property, or safety of Oversecured, other
                        users of our Services, of any other individuals, or of the general public; to maintain and
                        protect the security and integrity of our Services or infrastructure; to protect Oversecured and
                        our Services from fraudulent, abusive, or unlawful uses; or to investigate and defend
                        Oversecured against third-party claims or allegations. <br>
                        Disclosures may be made to courts of law, attorneys and law enforcement, or other relevant third
                        parties in order to meet these purposes. <br>
                        Please note that we share aggregated information and non-identifying information with third
                        parties for industry research and analysis, demographic profiling, and other similar purposes.
                        In addition, our Services may contain links to other websites not controlled by us, and these
                        other websites may reference or link to our Services; we encourage you to read the privacy
                        policies applicable to these other websites. <br>
                        If we transfer Personal Information of individuals located in the European Economic Area
                        ("EEA"), United Kingdom ("UK"), or Switzerland to a third party, Oversecured remains liable for
                        such Personal Information and the actions of that third party. <br><br>
                        5.5 California Consumer Privacy Act of 2018 ("CCPA").<br><br>
                        Pursuant to §§ 1798.110 and 1798.115 of the CCPA, the categories of Personal Information we have
                        collected about consumers and disclosed about consumers for a business purpose in the preceding
                        12 months are: <br>
                        Identifiers such as a real name, alias, postal address, email address, unique personal or online
                        identifier, Internet Protocol address, account name, SSN, driver's license or passport number,
                        or other similar identifiers; <br>
                        Other information that identifies, relates to, describes, or is capable of being associated
                        with, a particular individual, including signature, bank account number, credit card number,
                        debit card number, or any other financial information; <br>
                        Commercial information, including products or services purchased, obtained, or considered; other
                        purchasing or consuming histories or tendencies; <br>
                        Internet or other electronic network activity information, including browsing history, search
                        history, and information regarding a consumer's interaction with an internet website, or
                        advertisement; <br>
                        Professional or employment-related information; <br>
                        Inferences drawn from any of the information identified to create a profile about a consumer
                        reflecting the consumer's preferences, intelligence, abilities, and aptitudes.
                        Please note that not all of this information is collected or disclosed from all consumers using
                        our Services.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">6. RETENTION OF PERSONAL INFORMATION</p>
                    <p class="content">Oversecured retains Personal Information for a reasonable time period to fulfill
                        the processing purposes mentioned above. Personal Information is then archived for time periods
                        required or necessitated by law or legal considerations. When archiving is no longer required,
                        Personal Information is deleted from our records. <br><br>
                        You may choose to disable your Oversecured account at any time. This means your user profile
                        will no longer be visible on the Services. However, for the purposes mentioned above, we may
                        need to retain information within our internal systems. <br><br>
                        We retain Personal Information that we are required to retain to meet our regulatory obligations
                        including tax records and transaction history. We regularly review our retention policies to
                        ensure compliance with our obligations under data protection laws and other regulatory
                        requirements. We regularly audit our databases and archived information to ensure that Personal
                        Information is only stored and archived in alignment with our retention policies.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">7. PROTECTION OF PERSONAL INFORMATION</p>
                    <p class="content">Oversecured uses technical and organizational measures to protect the Personal
                        Information that we store, transmit, or otherwise process against accidental or unlawful
                        destruction, loss, alteration, unauthorized disclosure, or access. We regularly consider
                        appropriate new security technology and methods as we maintain and develop our software and
                        systems. <br><br>
                        However, you should keep in mind that the Services are run on software, hardware, and networks,
                        any component of which may, from time to time, require maintenance or experience problems or
                        breaches of security beyond our control. Please also be aware that despite our best efforts to
                        ensure the security of your data, we cannot guarantee that your information will be 100% secure.
                        <br>
                        Please recognize that protecting your Personal Information is also your responsibility. We urge
                        you to take every precaution to protect your information when you are on the Internet, such as
                        using a strong password, keeping your password secret, and using two-factor authentication. If
                        you have reason to believe that the security of your account might have been compromised (for
                        example, your password has been leaked), or if you suspect someone else is using your account,
                        please let us know immediately.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">8. INTERNATIONAL DATA TRANSFER</p>
                    <p class="content">Your Personal Information may be transferred to, and maintained on, computers
                        located outside of your state, province, country, or other governmental jurisdiction where the
                        privacy laws may not be as protective as those in your jurisdiction. If you are located outside
                        the United States and choose to provide your Personal Information to us, we will transfer your
                        Personal Information to the United States and process it there. Where we transfer your Personal
                        Information, we will take all reasonable steps to ensure that your privacy rights continue to be
                        protected. <br><br>
                        In the case of transfers of data out of Europe, we endeavor to utilize third-party service
                        providers which provide adequate protections that are compliant with the EU General Data
                        Protection Regulation ("GDPR") such as implementing Standard Data Protection Clauses or Binding
                        Corporate Rules.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">9. PRIVACY RIGHTS</p>
                    <p class="content">If you have an Oversecured account, we rely upon you to keep your information up
                        to date. You may edit your profile information and may also choose to disable your Oversecured
                        account at any time by sending a message to <a class="privacy-policy__link--blue"
                                                                       href="mailto:support@oversecured.com">support@oversecured.com</a>.
                        Where we rely upon consent
                        as a legal basis for processing, you may withdraw your consent at any time. Please note the
                        withdrawal of your consent does not affect the lawfulness of processing based on consent before
                        withdrawal. <br>
                        Individuals in the EEA, UK, and Switzerland have certain rights that may be subject to
                        limitations and/or restrictions. These rights include the right to: (i) request access to and
                        rectification or erasure of their Personal Information; (ii) obtain restriction of processing or
                        to object to processing of their Personal Information; and (iii) ask for a copy of their
                        Personal Information to be provided to them, or a third party, in a digital format. If you wish
                        to exercise one of the above-mentioned rights, please send your request to the contact details
                        set out below. Individuals also have the right to lodge a complaint about the processing of
                        their Personal Information with their local data protection authority. <br><br>
                        Personal Information subject rights under the CCPA may also apply to certain individuals and
                        households. These rights include the right to: (i) know what Personal Information is being
                        collected about them, (ii) know whether their Personal Information is sold or disclosed and to
                        whom, (iii) say no to the sale of Personal information, (iv) access their Personal Information,
                        and (v) receive an equal service and price, even if they exercise their privacy rights. You may
                        also contact us with your Personal information inquiries or for assistance in modifying or
                        updating your Personal Information and to exercise any additional applicable statutory rights.
                        We respect the privacy of all individuals and invite you to submit your requests, irrespective
                        of where you reside. Our contact details are provided at the end of this Privacy Policy.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">10. CHANGES TO THIS POLICY</p>
                    <p class="content">We may modify this Privacy Policy from time to time, which will be indicated by
                        changing the date given at the top of this page. The most current version of the Privacy Policy
                        will govern our use of your Personal Information and will always be at
                        <b-nav-item class="privacy-policy__link" :to="{ path: '/privacy'}">
                            https://oversecured.com/privacy
                        </b-nav-item>.
                        If we make changes that we believe will substantially alter
                        your rights, we will notify you by email (sent to the email address specified in your
                        Oversecured account), by means of a notice on our Services prior to the change becoming
                        effective, or as otherwise required by law. In certain cases, we may also seek your consent to
                        further use of your Personal Information where this is required.</p>

                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">11. CONTACT INFORMATION</p>
                    <p class="content">If you would like to contact us with questions or concerns about this Privacy
                        Policy, our privacy practices, or would like to exercise your privacy rights, you may contact us
                        at <a class="privacy-policy__link--blue"
                              href="mailto:support@oversecured.com">support@oversecured.com</a>.
                    </p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.privacy-policy__link {
    &--blue {
        color: #1FA8C7;
    }

    a.nav-link {
        &:hover {
            text-decoration: underline;
            user-select: auto;
        }
    }
}

.sign {
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: #242424;
    margin: 0;
}

.nav-item,
.nav-link {
    display: inline-block;
    list-style: none;
    padding: 0;
    color: #1FA8C7;

    &:hover {
        color: #1FA8C7;
    }
}

.last-update,
.privacy-subtitle,
.title,
.content {
    font-family: Cabin, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #706969;
    margin-top: 30px;
}

.privacy-subtitle {
    line-height: 140%;
    color: #242424;
    margin-top: 85px;
}

.title {
    color: #242424;
    line-height: 140%;
    margin: 40px 0 17px 0;
    font-weight: bold;
}

.content {
    line-height: 140%;
    color: #242424;
}

.paddings {
    padding: 130px 100px 78px;
}

@media (max-width: 991px) {
    .paddings {
        padding: 45px 50px 78px;
    }

    .nav-link {
        font-size: 16px !important;
    }
}

@media (max-width: 376px) {
    .paddings {
        padding: 45px 20px 78px;
    }

    .sign {
        font-size: 30px;
    }

    .last-update,
    .privacy-subtitle,
    .title,
    .content,
    .nav-link {
        font-size: 14px !important;
    }
}
</style>
