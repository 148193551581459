<template>
    <div class="dropzone-page__header">
        <div class="dropzone-page__header-text">
            New Scan
        </div>
        <div 
            v-click-outside="hide"
            style="position: relative;"
        >
            <label 
                ref="sendEmail"
                for="send-email" 
                class="send-email-label"
            >
                Send Email when the scan is complete
                <input 
                    id="send-email"
                    v-model="toggle"
                    type="checkbox"
                    name="send-email"
                >
                <span class="checkmark" />
            </label>
            <button 
                class="minimize"
                @click="toggleMinimize"
            />
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
    name: 'DropzoneHeader',
    directives: { ClickOutside },
    props: {
        sendEmail: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            toggle: this.sendEmail,
        };
    },
    watch: {
        toggle(newValue) {
            this.$emit('checked', newValue);
        },
    },
    methods: {
        toggleMinimize() {
            this.$refs.sendEmail.classList.toggle('open');
        },
        hide() {
            this.$refs.sendEmail.classList.remove('open');
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/css/variables";

.dropzone-page__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
    color: $main-text;
    font-size: 18px;
    font-weight: 600;
    .send-email-label {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        &:hover {
            .checkmark {
                @include checkbox-square-hover("blue");
            }
            #send-email:checked ~ .checkmark {
                @include checkbox-checkmark-checked-hover("blue");
            }
        }
        #send-email {
            position: absolute;
            opacity: 0;
        }
        .checkmark {
            @include checkbox-check("blue")
        }
        #send-email:checked ~ .checkmark {
            @include checkbox-checkmark-checked("blue")
        }
        #send-email:checked ~ .checkmark::after,
        #send-email:checked ~ .checkmark::before {
            display: block;
        }
    }
    &-text {
        font-size: 36px;
        font-weight: 700;
    }
    .minimize {
        display: none;
    }
}

@media (max-width: 900px) {
    .dropzone-page__header {
        .send-email-label {
            display: none;
            &.open {
                display: flex;
                position: absolute;
                width: 310px;
                top: 55px;
                right: 0%;
                padding: 15px;
                border: 2px solid $background-grey;
                border-radius: 8px;
                background-color: #fff;
                box-shadow: $box-shadow-black;
                z-index: 1;
                .checkmark {
                    flex-shrink: 0;
                }
            }
        }
        .minimize {
            display: block;
            position: relative;
            width: 48px;
            height: 48px;
            background-color: $background-grey;
            border-radius: 8px;
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 24px;
                height: 20px;
                top: 50%;
                transform: translate(50%, -50%);
                border: 2px solid $ghost-text;
                border-radius: 6px;
            }
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 9px;
                left: 16px;
                width: 16px;
                height: 16px;
                border-left: 2px solid $ghost-text;
                border-bottom: 2px solid $ghost-text;
                border-radius: 0 0 0 6px;
                transform: rotate(-45deg);
            }
        }
        .send-email-label:has(#send-email:checked) ~ .minimize {
            &::before, &::after {
                border-color: $main-blue-color;
            }
        } 
    }
}
</style>
