<template>
    <div
        v-if="!isPolicyAccepted"
        v-show="isVisible"
        class="new-main-gdpr"
    >
        <div
            class="new-main-gdpr-wrapper">
            <div
                class="new-main-gdpr-wrapper__text"
            >
                This website uses cookies to improve your experience. See our
                <span><router-link
                    :to="'/privacy'"
                    class="new-main-gdpr-wrapper__privacy-policy-link"
                >
                    Privacy Policy</router-link></span>
                to learn more.
            </div>
            <button
                class="new-main-gdpr-wrapper__button"
                @click="accept"
            >
                Accept Cookies
            </button>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { store } from '../../store';

export default {

    computed: {
        ...mapGetters('user', {
            isVisible: 'isVisible',
        }),
        isPolicyAccepted: {
            cache: false,
            get() {
                let privacycookie = this.$cookies.get('privacycookie');
                return privacycookie && privacycookie === 'true';
            },
            set(value) {
                this.$cookies.set('privacycookie', value, 60 * 60 * 24 * 365);
            },
        },
    },
    methods: {
        accept() {
            store.commit('user/toggleIsVisibleCookiesNotification', false);
            this.isPolicyAccepted = true;
        },
    },
};
</script>
<style lang="scss" scoped>

@import "../../assets/css/fonts";
@import "../../assets/css/variables.scss";

    .new-main-gdpr {
        position: fixed;
        right: 20px;
        bottom: 20px;
        background: #FFF;
        z-index: 1000;
        border: 1px solid var(--stroke, #E8E7EF);
        display: inline-flex;
        gap: 25px;
        border-radius: 20px;

        &-wrapper {
            display: flex;
            padding: 20px 30px;

            &__text {
                @include Aspekta-font(400, 16px, normal, #0C0D0E);
                line-height: 140%;
                align-self: center;
                width: 384px;
            }

            &__privacy-policy-link {
                @include Aspekta-font(400, 16px, normal, #3180F6);
                line-height: 140%;
                text-decoration-line: underline;
            }

            &__button {
                padding: 15px 30px;
                align-items: center;
                @include Aspekta-font(700, 16px, normal, #FFF);
                background: #FF7A08;
                border-radius: 100px;

                &:hover {
                    background: linear-gradient(0deg, rgba(255, 122, 8, 0.70) 0%, rgba(255, 122, 8, 0.70) 100%), var(--white, #FFF);
                }

                &:active {
                    border: 2px solid rgba(255, 122, 8, 0.30);
                    background: var(--white, #FFF);
                    color: #FF7A08;
                }
            }
        }
    }

    @media (max-width: 667px ) {

        .new-main-gdpr-wrapper__button {
            @include Aspekta-font(650, 14px, normal, #FFF);
        }
    }

    @media (max-width: 637px ) {

        .new-main-gdpr {
            width: 100%;
            right: 0;
            border-radius: 0;
            bottom: 0;
            &-wrapper {
                flex-direction: column;
                align-items: center;
                padding: 20px 16px;

                &__text {
                    font-size: 14px;
                    width: auto;
                    text-align: center;
                }

                &__privacy-policy-link {
                    font-size: 14px;
                }

                &__button {
                    margin-top: 15px;
                }
            }
        }

    }

</style>
