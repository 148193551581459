import {compareFn, convertCategoryPositionToNumber} from '../services/functions';

export const state = () => ({
    androidCategories: [],
    iosCategories: [],
});

export const mutations = {
    setAndroidCategories(state, categories) {
        state.androidCategories = categories;
    },
    setIosCategories(state, categories) {
        state.iosCategories = categories;
    },
};

export const actions = {
    async fetchAndroidCategories({commit}, categoryListAndroid) {
        try {
            let categories;
            categories = convertCategoryPositionToNumber(categoryListAndroid);
            let sorted = categories.sort(compareFn);
            commit('setAndroidCategories', sorted);
        } catch (e) {
            console.error(e);
            commit('setAndroidCategories', []);
        }
    },
    async fetchIosCategories({commit}, categoryListIos) {
        try {
            categoryListIos.forEach(item => {
                item.position = Number.parseInt(item.position);
            });

            let sorted = categoryListIos.sort(compareFn);

            commit('setIosCategories', sorted);
        } catch (e) {
            console.error(e);
            commit('setIosCategories', []);
        }
    },
};

export const getters = {
    androidCategories: s => s.androidCategories,
    iosCategories: s => s.iosCategories,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
