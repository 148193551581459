<template>
    <div class="inputs__wrapper">
        <div
            class="input-wrapper"
            :class="{ 'input-error': inputErrorData.companyName?.length > 0 }"
        >
            <label for="name">Company Name <span class="orange-star">*</span></label>
            <input
                id="name"
                v-model.trim="inputData.companyName"
                type="text"
                name="name"
                placeholder="Company Name"
                @input="handleInput('companyName')"
            >
            <img
                v-if="inputData.companyName?.length > 0"
                class="clear-name"
                src="../../assets/images/cross_grey.svg"
                alt="cross"
                @click="clearInput('companyName')"
            >
            <span
                v-if="inputData.companyName?.length > 0"
                class="error-text"
            >{{ inputErrorData.companyName }}</span>
        </div>
        <div class="input__wrapper">
            <label
                for="size"
                class="company-size"
            >Company Size <span class="optional"> (Optional)</span></label>
            <div class="custom-radio-group">
                <div class="custom-radio">
                    <input
                        v-model="inputData.companySize"
                        type="radio"
                        name="radio-group"
                        value="Startup"
                        @change="handleInput('companySize')"
                    >
                    <label class="radio-label">Startup</label>
                </div>

                <div class="custom-radio">
                    <input
                        v-model="inputData.companySize"
                        type="radio"
                        name="radio-group"
                        value="Middle"
                        @change="handleInput('companySize')"
                    >
                    <label class="radio-label">Middle</label>
                </div>

                <div class="custom-radio">
                    <input
                        v-model="inputData.companySize"
                        type="radio"
                        name="radio-group"
                        value="Enterprise"
                        @change="handleInput('companySize')"
                    >
                    <label class="radio-label">Enterprise</label>
                </div>
            </div>
        </div>

        <div
            class="input-wrapper"
            :class="{ 'input-error': inputErrorData.securityChallanges?.length > 0 }"
        >
            <label for="challanges">Please share what is the purpose of the meeting <span class="orange-star">*</span></label>
            <textarea
                v-model.trim="inputData.securityChallanges"
                @input="handleInput('securityChallanges')"
            />
            <span
                v-if="inputData.securityChallanges?.length > 0"
                class="error-text"
            >{{ inputErrorData.securityChallanges }}</span>
        </div>
    </div>
</template>
<script>
import { checkFieldCompleteness } from '../../services/functions';

export default {
    props: {
        stepData: Object,
    },
    data() {
        return{
            inputData: {
                companyName: this.stepData ? this.stepData.companyName : '',
                companySize: this.stepData ? this.stepData.companySize : '',
                securityChallanges: this.stepData ? this.stepData.securityChallanges : '',
            },
            inputErrorData: {
                companyName: '',
                securityChallanges: '',
            },
        };
    },
    methods: {
        clearInput(inputField) {
            this.inputData[inputField] = '';
            if (inputField in this.inputErrorData) {
                this.inputErrorData[inputField] = '';
            }
            this.$emit('updateIsDataValid', this.validateInput());
            this.$emit('updateInputData', { ...this.inputData });
            this.$emit('updateIsDataComplete', this.isComplete());
        },
        handleInput(inputField) {
            if (inputField in this.inputErrorData) {
                this.inputErrorData[inputField] = '';
            }
            this.$emit('updateInputData', { ...this.inputData });
            this.$emit('updateIsDataComplete', this.isComplete());
            this.$emit('updateIsDataValid', this.validateInput(inputField));
        },
        isComplete() {
            return Object.keys(this.inputData).every(field => {
                return checkFieldCompleteness(this.inputData[field]);
            }) && this.inputData['companyName']?.length !== 0 && this.inputData['securityChallanges']?.length !== 0;
        },

        validateInput(inputField) {
            const inputValue = this.inputData[inputField];
            if (inputValue !== undefined) {
                const isInputValid = checkFieldCompleteness(inputValue);
                !isInputValid ? this.inputErrorData[inputField] = 'Max input length 1000' : '';
            }
            return this.isComplete;
        },

    },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/variables/variablesForRequestDemo";
@import "../../assets/css/fonts";
    .inputs__wrapper {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        width: 100%;
    }

    .input-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        gap: 8px;
    }

    .input-wrapper label, .company-size {
        @include Aspekta-font(400, 14px, 140%, #797E86);
        letter-spacing: 0em;
    }

    .input-wrapper input:focus-visible, .input-wrapper textarea:focus-visible {
        outline: none;
        border: 1px solid #3180F6;
    }

    .input-error input:focus-visible, .input-error textarea:focus-visible {
        border: 1px solid #FE2027;
    }

    .input-wrapper img {
        height: 11px;
        width: 11px;
        position: absolute;
        bottom: 20px;
        right: 21px;
        cursor: pointer;
    }

    .input-wrapper {
        &:focus-within {
            label {
                color: #3180F6;
            }
        }
    }
    .input-error {
        &:focus-within {
            label {
                color: #FE2027;
            }
        }
    }

    .input-wrapper textarea {
        resize: none;
        height: 90px !important;
    }

    .input-wrapper input, .input-wrapper textarea {
        @include Aspekta-font(450, $dynamic-demo-input-placeholder-font-size, 150%, #0C0D0E);
        letter-spacing: 0em;
        border: 1px solid #E8E7EF;
        padding: 12px 39px 12px 24px;
        border-radius: 10px;
        text-overflow: ellipsis;

            &::placeholder {
                color: #797E86 !important;
            }
    }

    .custom-radio-group {
        display: flex;
        gap: 1rem;

        .custom-radio {
            display: flex;
            align-items: center;
            width: 100%;
            position: relative;
            background-color: #FFF;
            border-radius: 10px;
            z-index: 1;

            input[type="radio"] {
            appearance: none;
            padding: 12px 24px;
            height: 63px;
            width: 100%;
            border: 2px solid #E8E7EF;
            border-radius: 10px;
            cursor: pointer;

                &:checked {
                    border: 1px solid #3180F6;

                    + label {
                        color: #0C0D0E;
                    }
                }
            }

            label {
                @include Aspekta-font(450, $dynamic-demo-input-placeholder-font-size, 150%, #797E86);
                letter-spacing: 0.36px;
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
            }
        }
    }

    .orange-star {
        @include Aspekta-font(700, 14px, 140%, #FF7A08);
    }

    .error-text {
        @include Aspekta-font(400, 12px, 150%, #FE2027);
        position: absolute;
        bottom: -25px;
    }

    @media (max-width: 1000px) {
        .inputs__wrapper {
            gap: 1.8rem;
        }
        .input-wrapper input {
            padding: 18px 35px 18px 16px;
        }

        .input-wrapper img {
            bottom: 25px;
        }

        .custom-radio-group {
            gap: 4px;

            .custom-radio label {
                letter-spacing: 0.28px;
            }

            .custom-radio input[type="radio"] {
                border: 1px solid #E8E7EF;
            }
        }

        .input-wrapper textarea {
            height: 120px !important;
        }
}
</style>
