import Vue from 'vue';

export const state = () => ({
    scanPacksInfo: [],
    userScanPacks: null,
    activationCode: null,
    availableScansCount: 0,
    isSuccessPurchase: null,
});

export const mutations = {
    parseAndSetScanPacksInfo: (state, info) => {
        state.scanPacksInfo = info.filter(item => item.type === "SCAN_PACK");
    },
    setUserScanPacksInfo: (state, scanPacks) => {
        state.userScanPacks = {
            count: scanPacks.count,
            rows: scanPacks.rows.map(item => {
                return {
                    ...item,
                    showCode: false,
                };
            }),
        };
    },
    toggleScanPackCouponShowCode: (state, scanPackActivationCodeId) => {
        state.userScanPacks.rows.forEach(scanPack => {
            if (scanPack.activationCodeId === scanPackActivationCodeId) {
                scanPack.showCode = !scanPack.showCode;
            }
        });
    },
    setActivationCode: (state, code) => {
        state.activationCode = code;
    },
    setAvailableScans: (state, scansCount) => {
        state.availableScansCount = scansCount;
    },
    setPurchaseSuccessStatus: (state, status) => {
        state.isSuccessPurchase = status;
    },
};

export const actions = {
    async getScanPacksInfo({ commit }) {
        try {
            const response = await Vue.axios.get('/private/ui/scan_packs');

            commit('parseAndSetScanPacksInfo', response.data);
        } catch (error) {
            console.log(error);
            commit('parseAndSetScanPacksInfo', []);
        }
    },
    async getUserScanPacks({ commit }, data) {
        const page = data && data.page || 1;
        const limit = data && data.limit || 10;

        try {
            const response = await Vue.axios.get(`/private/ui/scan_packs/user/packs?page=${page}&limit=${limit}`);

            commit('setUserScanPacksInfo', response.data);
        } catch (error) {
            console.log(error);
            commit('setUserScanPacksInfo', null);
        }
    },
    async currentAccountPayment({ commit }, data) {
        try {
            await Vue.axios.post('/private/ui/scan_packs/pay/current_account', data);
            commit('setPurchaseSuccessStatus', { status: true, message: 'scans successfully added', paymentType: 'CURRENT_ACCOUNT' });
        } catch (error) {
            console.log(error);
            let message = 'Something went wrong';
            if (error.response.data === 'Card already added') message = error.response.data;
            commit('setPurchaseSuccessStatus', { status: false, message });
            throw new Error(JSON.stringify(error.response));
        }
    },
    async activationCodePayment({ commit }, data) {
        try {
            const response = await Vue.axios.post('/private/ui/scan_packs/pay/activation_code', data);

            commit('setActivationCode', response.data);
        } catch (error) {
            console.log(error);
            let message = 'Something went wrong';
            if (error.response.data === 'Card already added') message = error.response.data;
            commit('setPurchaseSuccessStatus', { status: false, message });
            commit('setActivationCode', null);
            throw new Error(JSON.stringify(error.response));
        }
    },
    async getUserAvailableScans({ commit }) {
        try {
            const response = await Vue.axios.get('/private/ui/scan_packs/user/available_scans');

            commit('setAvailableScans', response.data);
        } catch (error) {
            console.log(error);
            commit('setAvailableScans', 0);
        }
    },
};

export const getters = {
    scanPacksInfo: s => s.scanPacksInfo,
    userScanPacks: s => s.userScanPacks,
    activationCode: s => s.activationCode,
    availableScansCount: s => s.availableScansCount,
    isSuccessPurchase: s => s.isSuccessPurchase,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
