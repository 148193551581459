<template>
    <div>
        <b-modal
            id="modal-delete"
            v-model="isShowModal"
            dialog-class="delete-popup__dialog"
            content-class="delete-popup__content"
            body-class="delete-popup__body"
            header-class="delete-popup__header"
            footer-class="delete-popup__footer"
            :hide-footer="true"
            hide-header-close
            centered
            no-fade
            lazy
            static
            @hidden="onHidden"
        >
            <template #modal-header>
                <div class="delete-popup__title">
                    You're about to delete {{ deletedFolder && deletedFolder.name }}
                </div>
                <p class="delete-popup__description">
                    This folder still contains vulnerabilities. <br>
                    Please move them to another folder before deleting
                </p>
            </template>
            <div class="delete-dropdown">
                <button
                    class="delete-dropdown__button"
                    :class="{ open: isDropdownOpen }"
                    :aria-expanded="isDropdownOpen"
                    :v-click-outside="() => isDropdownOpen = false"
                    @click.prevent="isDropdownOpen = !isDropdownOpen"
                >
                    <div class="delete-dropdown__button-img-text">
                        <img
                            src="../../assets/images/folders/move-to-icon.svg"
                            alt="move-to"
                        >
                        <span
                            class="delete-dropdown__button-text"
                            :class="{ select: selectedFolder }"
                            :title="selectedFolder && selectedFolder.name"
                        >
                            {{ isDropdownOpen ? 'Move To' : (selectedFolder && selectedFolder.name || 'Select Folder') }}
                        </span>
                    </div>
                    <img
                        src="../../assets/images/folders/arrow-icon.svg"
                        class="delete-dropdown__button-arrow"
                        alt="arrow"
                    >
                </button>
                <ul
                    v-show="isDropdownOpen"
                    class="delete-dropdown__menu"
                    :class="{ open: isDropdownOpen }"
                >
                    <li
                        v-for="folder in folders"
                        :key="folder.id"
                        class="delete-dropdown__menu-item"
                        @click="selectFolder(folder)"
                    >
                        <div class="delete-dropdown__menu-item-folder">
                            <FalsePositiveIcon
                                v-if="folder.name === 'False Positives'"
                                style="margin-bottom: 5px;"
                                :svg-color="folder.isEmptyFolder ? Empty : Default"
                                :svg-width="'24px'"
                                :svg-height="'21px'"
                                alt="delete icon"
                            />
                            <FolderIcon
                                v-else
                                :ref="`FolderIcon${folder.id}`"
                                :svg-color="folder.isEmptyFolder ? Empty : Default"
                                :svg-width="'24px'"
                                :svg-height="'21px'"
                                alt="folder icon"
                            />
                            <div
                                v-if="folder.name !== 'False Positives'"
                                class="folder_list_vulnerabilities_dots"
                            >
                                <div
                                    v-if="checkVulnerabilitySeverityBooleanFunction(folder.statistics, 'high')"
                                    class="red_dot"
                                />
                                <div
                                    v-if="checkVulnerabilitySeverityBooleanFunction(folder.statistics, 'medium')"
                                    class="yellow_dot"
                                />
                                <div
                                    v-if="checkVulnerabilitySeverityBooleanFunction(folder.statistics, 'low')"
                                    class="green_dot"
                                />
                            </div>
                        </div>
                        <div
                            class="delete-dropdown__menu-item-text"
                            :title="folder.name"
                            :class="folder.isEmptyFolder && 'empty'"
                        >
                            {{ folder.name }}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="delete-popup__btns">
                <ConfigurablePopupButton
                    :button-text="'Cancel'"
                    :button-type="'cancel'"
                    :icon="false"
                    :disabled="false"
                    @buttonClick="onHidden"
                />
                <ConfigurablePopupButton
                    re
                    :button-text="'Delete'"
                    :button-type="'delete'"
                    :icon="true"
                    :disabled="!selectedFolder"
                    @buttonClick="onDeleteFolder"
                />
            </div>
        </b-modal>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import FolderIcon from '../../assets/iconTemplates/FolderIcon';
import FalsePositiveIcon from '../../assets/iconTemplates/FalsePositiveIcon';
import { checkVulnerabilitySeverityBooleanFunction } from '../../utils/helper-functions';
import ConfigurablePopupButton from '../popups/ConfigurablePopupButton';

export default {
    components: {
        FolderIcon,
        FalsePositiveIcon,
        ConfigurablePopupButton,
    },
    directives: {
        ClickOutside,
    },
    props: {
        folders: {
            type: Array
        },
    },
    data() {
        return {
            isShowModal: false,
            isDropdownOpen: false,
            selectedFolder: null,
            deletedFolder: null,
            screenWidth: window.innerWidth,
            Hover: '#FFE7AD',
            Default: '#949DB8',
            Empty: '#949DB8',
        };
    },
    methods: {
        selectFolder(folder) {
            this.selectedFolder = folder;
            this.isDropdownOpen = false;
        },
        onHidden() {
            this.$bvModal.hide('modal-delete');
            this.$emit('on-hidden');
            this.selectedFolder = null;
            this.isDropdownOpen = false;
        },
        onDeleteFolder() {
            this.$emit('on-delete', { selectedFolder: this.selectedFolder, deletedFolder: this.deletedFolder });
            this.$bvModal.hide('modal-delete');
        },
        checkVulnerabilitySeverityBooleanFunction,
    },
};
</script>

<style lang='scss' scoped>
@import "../../assets/css/fonts.scss";
@import "../../assets/css/variables.scss";

@mixin vulnDot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: auto 1.5px;
}
@mixin containerFolder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

::v-deep .delete-popup {
    &__dialog {
        width: 500px;
    }
    &__content {
        border-radius: 16px;
        border: none;
        padding: 30px;
        gap: 25px;
    }
    &__body {
        padding: 0;
        margin: .25rem;
    }
    &__header {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border: none;
        padding: 0;
    }
    &__title {
        @include Aspekta-font(600, 18px, normal, #242424);
        letter-spacing: 0.54px;
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__description {
        @include Aspekta-font(400, 14px, 140%, #706969);
        text-align: center;
        margin: 0;
    }
    &__btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        margin-top: 20px;
    }
}
.delete-dropdown {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__button {
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        height: 50px;
        font-weight: 200;
        border-radius: 8px;
        background-color: transparent;
        text-align: left;
        color: #b2b9c4;
        border: 1px solid #F0F1F3;
        &-img-text {
            display: flex;
            gap: 10px;
            width: 95%;
        }
        &-text {
            @include Aspekta-font(450, 18px, 150%, #5C6A7A);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
             &.select {
                color: #5C6A7A;
            }
        }
        &:active {
            background-color: transparent;
            border: 1px solid #b2b9c4;
            color: #b2b9c4;
        }
        &[aria-expanded="true"] {
            border: 1px solid #f0f1f3;
        }
        &-arrow {
            transition: transform 0.5s;
        }
        &.open > &-arrow {
            transform: rotate(180deg);
            transition: transform 0.5s;
        }
    }
    &__menu {
        max-height: 200px;
        overflow: auto;
        list-style: none;
        padding: 0;
        border: 1px solid #f0f1f3;
        border-radius: 16px;

        // Hide scrollbar for Chrome, Safari and Opera
        &::-webkit-scrollbar {
            display: none;
        }
        // Hide scrollbar for IE, Edge and Firefox
        scrollbar-width: none;

        &-item {
            display: flex;
            gap: 20px;
            align-items: center;
            margin: 10px 20px;
            padding-bottom: 10px;
            border-bottom: 1px solid #F0F1F3;
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
            &-folder {
                @include containerFolder;
                .folder_list_vulnerabilities_dots {
                    display: flex;
                    flex-direction: row;
                    height: fit-content;
                    margin-top: 8px;
                    .red_dot {
                        @include vulnDot;
                        background: $main-orange-color;
                    }
                    .yellow_dot {
                        @include vulnDot;
                        background: $main-yellow-color;
                    }
                    .green_dot {
                        @include vulnDot;
                        background: $main-green-color;
                    }
                }
            }
            &-text {
                @include Aspekta-font(450, 18px, 150%, #5C6A7A);
                letter-spacing: 0.36px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &.empty {
                    color: #5C6A7A;
                }
            }
            &:hover {
                cursor: pointer;
                opacity: .5;
                &-text {
                    color: #A6DCE8;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    ::v-deep .delete-popup__btns {
        flex-direction: column-reverse;
    }

    ::v-deep .delete-popup__dialog {
        max-width: 92%;
    }

}
</style>
