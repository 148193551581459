<template>
    <b-container>
        <div class="sign-in">
            <div v-if="authUserData.isRegisteredUser">
                <h1 class="sign-in__title">
                    You aren’t logged in
                </h1>
                <p class="sign-in__paragraph">
                    Please enter your password to Sign In and <br> accept the invite to the Integration.
                </p>
            </div>
            <div v-else>
                <h1 class="sign-in__title">
                    You haven’t been registered yet
                </h1>
                <p class="sign-in__paragraph">
                    Please fill out the form to complete the registration process and <br> accept the invite to the Integration.
                </p>
            </div>
            <form
                class="sign-in__form"
                novalidate
                @submit.prevent="handleForm"
            >
                <div
                    v-if="!authUserData.isRegisteredUser && step === 1"
                    class="sign-up-company__input-wrapper"
                >
                    <input
                        id="companyName"
                        v-model.trim="inputProfileData.companyName"
                        class="input"
                        name="companyName"
                        type="text"
                        @input="checkKeyEvent"
                        @blur="activeInputName = null"
                    >
                    <img
                        v-if="inputProfileData.companyName.length > 0"
                        class="sign-up-researcher__email-check"
                        src="../../assets/images/cross_grey.svg"
                        alt="cross"
                        @click="clearInputAndErrors('companyName')"
                    >
                    <label
                        for="companyName"
                        class="label"
                        :class="{ activeInput: inputProfileData.companyName !== isEmptyInput || activeInputName === 'companyName'}"
                    >
                        Company Name <span class="orange-star">*</span>
                    </label>
                    <span
                        id="companyNameError"
                        class="sign-up-researcher__error"
                    >
                        <img
                            class="sign-up-researcher__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('companyName')"
                        >
                        <span class="sign-up-researcher__error-text">Required field</span>
                    </span>
                </div>
                <div
                    v-if="!authUserData.isRegisteredUser && step === 1"
                    class="sign-up-company__input-wrapper"
                >
                    <input
                        id="website"
                        v-model.trim="inputProfileData.website"
                        class="input"
                        name="website"
                        type="text"
                        @input="checkKeyEvent"
                        @blur="activeInputName = null"
                    >
                    <img
                        v-if="inputProfileData.website.length > 0"
                        class="sign-up-researcher__email-check"
                        src="../../assets/images/cross_grey.svg"
                        alt="cross"
                        @click="clearInputAndErrors('website')"
                    >
                    <label
                        for="website"
                        class="label"
                        :class="{ activeInput: inputProfileData.website !== isEmptyInput || activeInputName === 'website'}"
                    >
                        Website
                    </label>
                </div>
                <div
                    v-if="!authUserData.isRegisteredUser && step === 1"
                    class="sign-up-company__input-wrapper"
                >
                    <input
                        id="address1"
                        v-model.trim="inputProfileData.address1"
                        class="input"
                        name="address1"
                        type="text"
                        @input="checkKeyEvent"
                        @blur="activeInputName = null"
                    >
                    <img
                        v-if="inputProfileData.address1.length > 0"
                        class="sign-up-researcher__email-check"
                        src="../../assets/images/cross_grey.svg"
                        alt="cross"
                        @click="clearInputAndErrors('address1')"
                    >
                    <label
                        for="address1"
                        class="label"
                        :class="{ activeInput: inputProfileData.address1 !== isEmptyInput || activeInputName === 'address1'}"
                    >
                        Address
                    </label>
                </div>
                <div
                    v-if="!authUserData.isRegisteredUser && step === 1"
                    class="sign-up-company__input-wrapper"
                >
                    <input
                        id="country"
                        v-model.trim="inputProfileData.country"
                        class="input"
                        name="country"
                        type="text"
                        @input="checkKeyEvent"
                        @blur="activeInputName = null"
                    >
                    <img
                        v-if="inputProfileData.country.length > 0"
                        class="sign-up-researcher__email-check"
                        src="../../assets/images/cross_grey.svg"
                        alt="cross"
                        @click="clearInputAndErrors('country')"
                    >
                    <label
                        for="country"
                        class="label"
                        :class="{ activeInput: inputProfileData.country !== isEmptyInput || activeInputName === 'country'}"
                    >
                        Country
                    </label>
                </div>
                <div
                    v-if="!authUserData.isRegisteredUser && step === 1"
                    class="sign-up-company__label-wrapper"
                >
                    <div class="sign-up-company__input-wrapper sign-up-company__input-wrapper--small sign-up-company__input-wrapper--margin">
                        <input
                            id="state"
                            v-model.trim="inputProfileData.state"
                            class="input"
                            name="state"
                            type="text"
                            @input="checkKeyEvent"
                            @blur="activeInputName = null"
                        >
                        <img
                            v-if="inputProfileData.state.length > 0"
                            class="sign-up-researcher__email-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                            @click="clearInputAndErrors('state')"
                        >
                        <label
                            for="state"
                            class="label"
                            :class="{ activeInput: inputProfileData.state !== isEmptyInput || activeInputName === 'state'}"
                        >
                            State
                        </label>
                    </div>
                    <div
                        v-if="!authUserData.isRegisteredUser && step === 1"
                        class="sign-up-company__input-wrapper sign-up-company__input-wrapper--small"
                    >
                        <input
                            id="city"
                            v-model.trim="inputProfileData.city"
                            class="input"
                            name="city"
                            type="text"
                            @input="checkKeyEvent"
                            @blur="activeInputName = null"
                        >
                        <img
                            v-if="inputProfileData.city.length > 0"
                            class="sign-up-researcher__email-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                            @click="clearInputAndErrors('city')"
                        >
                        <label
                            for="city"
                            class="label"
                            :class="{ activeInput: inputProfileData.city !== isEmptyInput || activeInputName === 'city'}"
                        >
                            City
                        </label>
                    </div>
                </div>
                <div
                    v-if="!authUserData.isRegisteredUser && step === 1"
                    class="sign-up-company__input-wrapper sign-up-company__input-wrapper--small"
                >
                    <input
                        id="zipCode"
                        v-model.trim="inputProfileData.zipCode"
                        class="input"
                        name="zipCode"
                        type="text"
                        @input="checkKeyEvent"
                        @blur="activeInputName = null"
                    >
                    <img
                        v-if="inputProfileData.zipCode.length > 0"
                        class="sign-up-researcher__email-check"
                        src="../../assets/images/cross_grey.svg"
                        alt="cross"
                        @click="clearInputAndErrors('zipCode')"
                    >
                    <label
                        for="zipCode"
                        class="label"
                        :class="{ activeInput: inputProfileData.zipCode !== isEmptyInput || activeInputName === 'zipCode'}"
                    >
                        ZIP Code
                    </label>
                </div>
                <div
                    v-if="!authUserData.isRegisteredUser && step === 2"
                    class="sign-up-company__input-wrapper"
                >
                    <input
                        id="firstName"
                        v-model.trim="inputData.firstName"
                        class="input"
                        name="firstName"
                        type="text"
                        @input="checkKeyEvent"
                        @blur="activeInputName = null"
                    >
                    <img
                        v-if="inputData.firstName.length > 0"
                        class="sign-up-researcher__email-check"
                        src="../../assets/images/cross_grey.svg"
                        alt="cross"
                        @click="clearInputAndErrors('firstName')"
                    >
                    <label
                        for="firstName"
                        class="label"
                        :class="{ activeInput: inputData.firstName !== isEmptyInput || activeInputName === 'firstName'}"
                    >
                        First Name <span class="orange-star">*</span>
                    </label>
                    <span
                        id="firstNameError"
                        class="sign-up-researcher__error"
                    >
                        <img
                            class="sign-up-researcher__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('firstName')"
                        >
                        <span class="sign-up-researcher__error-text">Required field</span>
                    </span>
                </div>
                <div
                    v-if="!authUserData.isRegisteredUser && step === 2"
                    class="sign-up-company__input-wrapper"
                >
                    <input
                        id="lastName"
                        v-model.trim="inputData.lastName"
                        class="input"
                        name="lastName"
                        type="text"
                        @input="checkKeyEvent"
                        @blur="activeInputName = null"
                    >
                    <img
                        v-if="inputData.lastName.length > 0"
                        class="sign-up-researcher__email-check"
                        src="../../assets/images/cross_grey.svg"
                        alt="cross"
                        @click="clearInputAndErrors('lastName')"
                    >
                    <label
                        for="lastName"
                        class="label"
                        :class="{ activeInput: inputData.lastName !== isEmptyInput || activeInputName === 'lastName'}"
                    >
                        Last Name <span class="orange-star">*</span>
                    </label>
                    <span
                        id="lastNameError"
                        class="sign-up-researcher__error"
                    >
                        <img
                            class="sign-up-researcher__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('lastName')"
                        >
                        <span class="sign-up-researcher__error-text">Required field</span>
                    </span>
                </div>
                <div
                    v-if="authUserData.isRegisteredUser || (!authUserData.isRegisteredUser && step === 2)"
                    class="sign-in__input-wrapper"
                >
                    <img
                        class="sign-in__email-check"
                        src="../../assets/images/greenCheck.svg"
                        alt=""
                    >
                    <input
                        class="input"
                        disabled
                        :value="authUserData.email"
                    >
                    <label
                        for="email"
                        class="label activeInput"
                    >
                        Email <span class="orange-star">*</span>
                    </label>
                </div>
                <div
                    v-if="authUserData.isRegisteredUser || (!authUserData.isRegisteredUser && step === 2)"
                    class="sign-in__input-wrapper"
                >
                    <img
                        id="passwordShow"
                        class="sign-in__toggle-show-password icon"
                        src="../../assets/images/myScans/icon_action_hide.svg"
                        alt=""
                        @click="toggleShowPassword"
                    >
                    <img
                        id="passwordHide"
                        class="sign-in__toggle-show-password sign-in__toggle-show-password--bigger icon"
                        src="../../assets/images/myScans/icon_action_show.svg"
                        alt=""
                        @click="toggleShowPassword"
                    >
                    <span class="sign-in__input-error">{{ authErrors.credentials || authErrors.dbConnecting }}</span>
                    <input
                        id="password"
                        v-model.trim="inputData.password"
                        class="input"
                        name="password"
                        type="password"
                        @input="checkKeyEvent"
                        @blur="activeInputName = null"
                    >
                    <label
                        for="password"
                        class="label"
                        :class="{ activeInput: inputData.password !== isEmptyInput || activeInputName === 'password' }"
                    >
                        Password <span class="orange-star">*</span>
                    </label>
                    <span
                        id="passwordError"
                        class="sign-in__error"
                    >
                        <span class="sign-in__error-text">Required field</span>
                    </span>

                    <span
                        id="passwordLengthError"
                        class="sign-up-company__error sign-up-company__error-length"
                    >
                        <span class="new-password__error-text">
                            Minimum 12 characters
                        </span>
                    </span>
                    <span
                        id="authErrors"
                        class="sign-in__input-error"
                    >
                        {{ authErrors.credentials || authErrors.dbConnecting }}
                    </span>
                </div>
                <div
                    v-if="authUserData.isRegisteredUser"
                    class="sign-in__forgot-password-wrapper"
                >
                    <b-nav-item
                        to="/forgot-password"
                        class="sign-in__text--link"
                    >
                        Forgot Password?
                    </b-nav-item>
                </div>
                <div class="sign-in__toggle-wrapper">
                    <b-form-checkbox
                        v-if="authUserData.isRegisteredUser"
                        id="rememberForTwoWeeks"
                        v-model="rememberForTwoWeeks"
                        name="rememberForTwoWeeks"
                        value="true"
                        unchecked-value="false"
                    >
                        Remember me for 2 weeks
                    </b-form-checkbox>

                    <div
                        v-else-if="step === 2"
                        class="sign-up-company__toggle-wrapper"
                    >
                        <b-form-checkbox
                            id="acceptTermsAndPolicy"
                            v-model="acceptTermsOfUseAndPrivacyPolicy"
                            name="acceptTermsAndPolicy"
                        >
                            By signing up, you agree to our <br>
                            <b-nav-item
                                to="/terms"
                                class="sign-up-company__link"
                            >
                                Terms of Service
                            </b-nav-item>
                            and
                            <b-nav-item
                                to="/privacy"
                                class="sign-up-company__link"
                            >
                                Privacy Policy
                            </b-nav-item>
                        </b-form-checkbox>
                    </div>
                    <button
                        v-if="!authUserData.isRegisteredUser && step === 1"
                        class="sign-in__btn-submit next-step__btn"
                        @click="handleGoToNextStep"
                    >
                        Next Step
                    </button>
                    <button
                        v-if="authUserData.isRegisteredUser"
                        class="sign-in__btn-submit"
                    >
                        Sign In
                    </button>
                    <button
                        v-if="!authUserData.isRegisteredUser && step === 2"
                        class="sign-in__btn-submit"
                        :class="{'btn--disabled': !acceptTermsOfUseAndPrivacyPolicy}"
                        :disabled="!acceptTermsOfUseAndPrivacyPolicy"
                    >
                        Sign Up
                    </button>
                </div>
                <div
                    v-if="authUserData.isRegisteredUser"
                    class="sign-in__link-to-login-wrapper"
                >
                    <b-nav-item
                        to="/sign-up/company"
                        class="sign-in__link "
                    >
                        Create an Oversecured Account
                    </b-nav-item>
                </div>
            </form>
            <div
                v-if="isLoading"
                class="sign-in__spinner"
            >
                <Spinner />
            </div>
        </div>
    </b-container>
</template>
<script>
import {
    checkFormInputsForCompleteness,
    checkFormInputsForErrors,
    checkFieldForError,
    setItemsToLocalStorage,
    checkPasswordLength,
    toggleShowPassword,
    validatePassword,
    hideErrors,
    raiseError,
} from '@/services/functions';
import Spinner from '@/components/Spinner';
import { mapGetters } from 'vuex';
import { redirectUserToZendeskAfterLogin } from '@/services/authorization/routeUser';

export default {
    name: 'QuickAuthorization',
    components: {
        Spinner,
    },
    data() {
        return {
            inputData: {
                password: '',
                firstName: '',
                lastName: '',
            },
            inputProfileData: {
                companyName: '',
                website: '',
                address1: '',
                country: '',
                state: '',
                city: '',
                zipCode: '',
            },
            step: 1,
            acceptTermsOfUseAndPrivacyPolicy: false,
            rememberForTwoWeeks: false,
            isEmailValid: null,
            isLoading: false,
            zendeskData: null,
            isEmptyInput: '',
            activeInputName: null,
        };
    },
    computed: {
        ...mapGetters({
            authErrors: 'authentication/errors',
            userType: 'user/userType',
            authUserData: 'user/authUserData',
        }),
    },
    mounted() {
        if (this.$router.currentRoute.query) {
            this.zendeskData = this.$router.currentRoute.query;
        }
    },
    methods: {
        clearInputAndErrors(inputField) {
            // inputData
            if (inputField === 'firstName') {
                this.inputData.firstName = '';
                hideErrors(['firstNameError']);
            } else if (inputField === 'lastName') {
                this.inputData.lastName = '';
                hideErrors(['lastNameError']);
            }

            // inputProfileData
            if (inputField === 'companyName') {
                this.inputProfileData.companyName = '';
                hideErrors(['companyNameError']);
            } else if (inputField === 'website') {
                this.inputProfileData.website = '';
            } else if (inputField === 'address1') {
                this.inputProfileData.address1 = '';
            } else if (inputField === 'country') {
                this.inputProfileData.country = '';
            } else if (inputField === 'state') {
                this.inputProfileData.state = '';
            } else if (inputField === 'city') {
                this.inputProfileData.city = '';
            } else if (inputField === 'zipCode') {
                this.inputProfileData.zipCode = '';
            }

            document.getElementById(inputField).style.border = '1px solid #F0F0F0';
        },
        async handleForm() {
            if(this.step === 2 || this.authUserData.isRegisteredUser) {
                hideErrors(['passwordError', 'passwordLengthError', 'firstNameError', 'lastNameError', 'companyNameError']);
                this.$store.commit('authentication/clearErrors');
                if (this.authUserData.isRegisteredUser) {
                    await this.signIn();
                } else {
                    await this.signUp();
                }
            }
        },
        async signIn() {
            const loginData = {
                email: this.authUserData.email,
                password: this.inputData.password,
            };
            if (!loginData.password) {
                return raiseError('password', 'passwordError', 'sign-up-researcher__input--validation-error');
            }

            if (!validatePassword(loginData.password)) {
                return checkPasswordLength(
                    { password: loginData.password },
                    'sign-in__input--error',
                );
            }

            if (checkFormInputsForCompleteness(loginData)) {
                await this.sendSignInRequest({ ...loginData, remember_for_two_weeks: this.rememberForTwoWeeks });
            }
        },
        async signUp() {
            const newUserData = {
                company_name: this.inputProfileData.companyName,
                website: this.inputProfileData.website,
                address1: this.inputProfileData.address1,
                country: this.inputProfileData.country,
                state: this.inputProfileData.state,
                city: this.inputProfileData.city,
                zip_code: this.inputProfileData.zipCode,
                email: this.authUserData.email,
                password: this.inputData.password,
                first_name: this.inputData.firstName,
                last_name: this.inputData.lastName,
                type: 'company',
                accept_terms_of_use_and_privacy_policy: this.acceptTermsOfUseAndPrivacyPolicy,
                inviteId: this.authUserData.invitedId,
            };

            if (this.inputData.password) {
                checkPasswordLength(
                    { password: this.inputData.password },
                    'sign-up-company__input--length-error',
                );
            }

            checkFormInputsForErrors(this.inputData, 'sign-up-researcher__input--error');

            if (checkFormInputsForCompleteness(this.inputData) && this.inputProfileData.companyName && validatePassword(this.inputData.password) && this.acceptTermsOfUseAndPrivacyPolicy) {
                await this.sendSignUpRequest(newUserData);
            }
        },

        isAuthError() {
            return this.authErrors && Object.keys(this.authErrors).length !== 0;
        },

        handleGoToNextStep() {
            checkFieldForError('companyName', this.inputProfileData.companyName, 'sign-up-company__input--error');
            if(this.inputProfileData.companyName) {
                this.step = 2;
            }
        },

        async sendSignInRequest(loginData) {
            this.isLoading = true;
            try {
                await this.$auth.login(loginData);
            } catch (err) {
                console.log(err);
            }
            if (!this.isAuthError()) {
                await this.$store.dispatch('user/fetchUserInfo');
                setItemsToLocalStorage([['userType', this.userType]]);
                if (this.canRedirectToZendesk()) {
                    await redirectUserToZendeskAfterLogin();
                } else {
                    this.$router.push(`/accept-invite/${this.authUserData.invitedId}`);
                }
            } else if (this.isAuthError() && this.authErrors.link) {
                await this.$router.push({
                    name: 'ConfirmEmailLink',
                    params: { email: this.authUserData.email },
                });
            }
            this.isLoading = false;
        },

        async sendSignUpRequest(newUserData) {
            const loginData = {
                email: newUserData.email,
                password: newUserData.password,
                remember_for_two_weeks: false,
            };

            try {
                this.isLoading = true;
                await this.$auth.signUpCompany(newUserData);
                await this.$auth.login(loginData);
                this.isLoading = false;

                this.$router.push(`/accept-invite/${this.authUserData.invitedId}`);
            } catch (error) {
                this.isLoading = false;
                console.log(error);
            }
        },

        canRedirectToZendesk() {
            return this.zendeskData && this.zendeskData.brand_id;
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id;
        },
        toggleShowPassword,
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/fonts";

#firstNameError,
#lastNameError,
#passwordError,
#emailError,
#companyNameError {
    display: none;
}

.next-step__btn {
    margin: -20px auto !important;
}

.orange-star {
    color: #FF7A08;
}

.sign-in {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    min-height: 725px;

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        @include Aspekta-font(700, 60px, normal, #242424);
        font-size: calc(31px + (60 - 31) * calc((100vw - 320px) / (1920 - 320)));
        letter-spacing: 0em;
        margin: 36px 0;
    }

    &__paragraph {
        @include Aspekta-font(500, 15px, 24px, #706969);
        text-align: center;
        padding-bottom: 25px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 395px;
    }

    &__label {
        width: 100%;
        margin-bottom: 15px;
        position: relative;
        font-family: Cabin;
        font-style: normal;
        font-size: 12px;
        line-height: 150%;
        color: #B2B9C4;

        &:nth-child(2) {
            margin-bottom: 10px;
        }
    }

    &__email-valid {
        position: absolute;
        top: 45px;
        right: 20px;
    }

    &__toggle-show-password {
        position: absolute;
        right: 11px;
        top: 6px;
        width: 30px;
        cursor: pointer;
    }

    &__input {


        &--error {
            border: 1px solid #FB8D62;
            border-radius: 5px;
        }
    }

    &__input-wrapper {
        position: relative;
        width: 100%;

        .icon {
            margin: 4px;
            width: 22px;
            height: 22px;
        }
    }

    &__input-error {
        position: absolute;
        bottom: 0;
        left: 0;
        font-family: Cabin, serif;
        font-weight: normal;
        font-size: 12px;
        color: #FB8D62;
    }

    &__email-check {
        position: absolute;
        top: 17px;
        right: 20px;
    }

    &__toggle-wrapper {
        width: 100%;
        font-family: Cabin, serif;
        font-weight: normal;
        font-size: 14px;
        color: #706969;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 30px 0 63px;

        label.custom-control-label {
            padding-top: 2px;
        }

        .custom-control-label::after {
            background-color: white;
        }

        .custom-control-input:checked ~ .custom-control-label::after {
            background-color: #A6DCE8;
        }
    }

    &__link {
        display: inline-block;
        font-family: Cabin, serif;
        font-weight: normal;
        align-items: center;

        a {
            color: #FFB400;
            font-size: 18px !important;

            &:hover {
                text-decoration: underline;
                color: #FFB400;
            }
        }
    }

    &__link-to-login-wrapper {
        display: flex;
        align-items: center;
    }

    &__forgot-password-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__text {
        @include Aspekta-font(500, 18px, 150%, #706969);

        &--link {
            a {
                @include Aspekta-font(500, 18px, normal, transparent);
                font-size: 14px !important;
                color: #706969;
                margin: 0 !important;

                &:hover {
                    color: #706969;
                    text-decoration: underline
                }
            }
        }
    }

    &__btn-submit {
        @include Aspekta-font(600, 18px, normal, #FFFFFF);
        background: #FF7A08;
        border: 2px solid #FF7A08;
        border-radius: 8px;
        padding: 10px 40px;
        margin: 28px auto;
        transition: 0.3s;

        &:focus {
            border: 2px solid #FFB400;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    &__email-check {
        position: absolute;
        bottom: 45px;
        right: 20px;
        cursor: pointer;
    }

    &__error {
        width: 100%;
        max-width: 831px;
        position: relative;
    }

    &__error-cross {
        position: absolute;
        top: -54px;
        right: 20px;
        cursor: pointer;
    }

    &__error-text {
        @include Aspekta-font(500, 10px, 150%, #FB8D62);
        position: absolute;
        top: -25px;
        left: 3px;
        margin: 0;
    }
}

.btn--disabled {
    background: #B2B9C4;
    border: 2px solid #B2B9C4;
}

@media (max-width: 768px) {
    .next-step__btn {
        margin: -50px 0px 0px 0px !important;
    }
    .sign-up-company__label-wrapper {
        flex-direction: row;
    }
    .sign-in {
        padding: 0 20px;

        &__title {
            margin: 30px 0;
            text-align: center;
        }

        &__form {
            margin-bottom: 3px;
        }

        &__link--orange {
            a {
                font-size: 16px !important;
            }
        }

        &__toggle-wrapper {
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 23px;
        }

        &__btn-submit {
            padding: 10px 0;
            width: 100%;
            margin: 15px 0 28px 0;
        }
    }
}
</style>
