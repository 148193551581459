<template>
    <div class="success-stories-and-happy-costumers">
        <div class="success-stories-and-happy-costumers__content">
            <div
                class="succes-stories-title-block"
                :class="{ noHover: isMobile }"
            >
                <div class="succes-stories-text-block">
                    <span class="succes-stories-text-block__main">{{ mainText }}</span>
                    <span class="succes-stories-text-block__second">{{ secondText }}</span>
                    <img
                        v-if="!isMobile"
                        class="success-stories-vector"
                        src="../../../assets/images/success-stories/arrow.svg"
                        alt="success-arrow"
                    >
                    <img
                        v-else
                        class="success-stories-vector"
                        src="../../../assets/images/success-stories/arrow_mobile.svg"
                        alt="success-arrow"
                    >
                </div>
                <div class="success-stories-and-happy-costumers__blocks">
                    <div class="block-hover">
                        <span class="block-hover__number">$78 million+</span>
                        <span class="block-hover__text">Saved in security costs</span>
                    </div>
                    <div class="block-hover">
                        <span class="block-hover__number">5 832+</span>
                        <span class="block-hover__text">Vulnerabilities fixed</span>
                    </div>
                    <div class="block-hover">
                        <span class="block-hover__number">1 020 000+</span>
                        <span class="block-hover__text">Working hours saved with automation</span>
                    </div>
                </div>
            </div>
<!--            <div class="carousel-block">-->
<!--                <carousel-->
<!--                    ref="myCarousel"-->
<!--                    :per-page="1"-->
<!--                    :mouse-drag="true"-->
<!--                    :navigation-enabled="!isMobile"-->
<!--                    :pagination-enabled="isMobile"-->
<!--                    :pagination-color="'#727D8D80'"-->
<!--                >-->
<!--                    <slide-->
<!--                        v-for="(card, index) in cards"-->
<!--                        :key="index"-->
<!--                        :data-index="index"-->
<!--                    >-->
<!--                        <StoryCard-->
<!--                            :customer-text="card.customerText"-->
<!--                            :customer-name="card.customerName"-->
<!--                            :customer-position="card.customerPosition"-->
<!--                            :company-text-large="card.companyTextLarge"-->
<!--                            :company-text-small="card.companyTextSmall"-->
<!--                            :company-text-description="card.companyTextDescription"-->
<!--                        />-->
<!--                    </slide>-->
<!--                </carousel>-->
<!--            </div>-->
        </div>
    </div>
</template>
<script>

import StoryCard from './StoryCard.vue';
export default {
    components: {
        StoryCard,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            isNavButtonClicked: false,
            mainText: 'Success Stories and Happy Customers',
            secondText: 'Hear what our customers have to say',
            cards:[
                {
                    customerName: 'Jane Williams',
                    customerPosition: 'VP of Security at Amazon',
                    customerText: `Oversecured has been instrumental in fortifying our digital defenses.
                    With their cutting-edge cybersecurity solutions, we've achieved an unmatched level of protection
                    for our sensitive data and critical systems.
                    Their proactive approach and quick response to potential threats have surpassed our expectations.`,
                    companyTextLarge: '24',
                    companyTextSmall: 'million',
                    companyTextDescription: 'customer records safeguarded from a potential data breach',
                },
                {
                    customerName: 'Robert Taylor',
                    customerPosition: 'Chief Technology Officer at Netflix',
                    customerText: `Choosing Oversecured was the best decision we made for our cybersecurity needs. Their
                    innovative solutions have given us peace of mind, knowing our organization is shielded from cyber threats.
                    The seamless integration of their services and their team's exceptional support have exceeded our expectations.`,
                    companyTextLarge: '1 700',
                    companyTextSmall: '',
                    companyTextDescription: 'working hours saved annually per security specialist',
                },
                {
                    customerName: 'Andrew Smith',
                    customerPosition: 'Chief Information Security Officer at Dropbox',
                    customerText: `Oversecured has been a game-changer for our organization. Their cutting-edge cybersecurity solutions
                    have provided us with an unparalleled level of protection. We feel confident knowing that our data and systems are
                    in safe hands. Their team's expertise and responsiveness have truly exceeded our expectations.`,
                    companyTextLarge: '40',
                    companyTextSmall: '%',
                    companyTextDescription: 'is the number of security expenses saved annually ',
                },
                // {
                //     customerName: 'Kristoffer Blasiak',
                //     customerPosition: 'Information Security Engineer at Google',
                //     customerText: `Oversecured reports provide good context to the potential findings. Oversecured blog posts are
                //     some of the most informative and best documentation of android application vulnerabilities available.`,
                //     companyTextLarge: '27',
                //     companyTextSmall: '%',
                //     companyTextDescription: 'new vulnerabilities added to potential findings based on Oversecured reports ',
                // },
                {
                    customerName: 'Gaurang Bhatnagar',
                    customerPosition: 'Product Security Engineer at VMware',
                    customerText: `Oversecured scanner has proven to be an invaluable asset in pinpointing vulnerabilities and
                    sections of code that might typically be overlooked during manual code reviews. This sophisticated tool has
                    streamlined the security triaging process, delivering accurate results with a notably low rate of false positives.`,
                    companyTextLarge: '95',
                    companyTextSmall: '%',
                    companyTextDescription: 'efficiently identifying vulnerabilities and overlooked code segments',
                },
            ],
        };
    },
    computed: {
        isMobile() {
            return this.windowWidth < 768;
        },

    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {

        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },

    },
};

</script>
<style lang="scss" >
@import "../../../assets/css/variables.scss";
@import "../../../assets/css/variables/variablesForSuccessStories.scss";
@import "../../../assets/css/fonts";

.success-stories-and-happy-costumers {
    width: 100vw;
    background-color: var(--bg-color-2, #F6F6F9);
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;

    &__content {
        display: flex;
        flex-direction: column;
        margin: 9% 0; //236px 311px
    }

    &__blocks {
        display: flex;
        justify-content: center;
        margin: 40px 0;
        gap: 35px;
    }
}


.succes-stories-title-block {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 $dynamic-title-block-margin-l-r;
}

.succes-stories-text-block {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__main {
        @include Aspekta-font(700, $dynamic-text-block-main-font-size, normal, $general-black);
        line-height: 120%; /* 72px */
    }

    &__second {
        @include Aspekta-font(400, $dynamic-text-block-second-font-size, normal, #797E86);
    }
}
.success-stories-vector {
    position: absolute;
    top: $dynamic-success-stories-vector-top;
    left: $dynamic-success-stories-vector-left;
}

.block-hover {
    display: flex;
    padding: 5% $dynamic-block-hover-padding-l-r;//65px 45px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border: 2px solid #F6F6F9;
    transition: transform 0.4s ease-in-out;

    &__number {
        @include Aspekta-font(700, $dynamic-block-hover-number-font-size, normal, $general-black);
        text-align: justify;
    }

    &__text {
        @include Aspekta-font(400, $dynamic-block-hover-text-font-size, normal, $main-text);
        text-align: center;
    }

    &:hover {
        transform: scale(1.04); //padding: 75px 55px;
        border-radius: 24px;
        border: 2px solid var(--stroke, #E8E7EF);
        background: var(--white, #FFF);
    }
}

.noHover {
    pointer-events: none;
}

.carousel-block {
    margin-left: $dynamic-title-block-margin-l-r;
    width: 100%;
    gap: 30px;
    margin-bottom: 8%;

    .VueCarousel {
        margin-left: $dynamic-vueCarousel-margin-l;
    }

    .VueCarousel-slide {
        width: $dynamic-carousel-slider-width;
        flex-basis: auto;
    }
    .VueCarousel-inner {
        width: $dynamic-carousel-slider-width;
    }
    .VueCarousel-wrapper {
        overflow: visible;
    }

    .VueCarousel-navigation {
        position: absolute;
        top: 115%;
        left: $dynamic-vueCarousel-navigation-left;
        display: flex;
        justify-content: space-between;
        width: 10%;
    }

    .VueCarousel-navigation-prev,
    .VueCarousel-navigation-next {
        background-size: contain;
        background-repeat: no-repeat;
        text-indent: -9999px;
        height: $dynamic-vueCarousel-navigation-height; //64px;
        width: 64px;
        transform: none;
        &:hover{
            background-image: url('../../../assets/images/main/main-slider-arrow-hover.svg');
        }
    }


    .VueCarousel-navigation-prev {
        position: relative;
        background-image: url('../../../assets/images/main/main-slider-arrow-left.svg');
        left: -10% !important;
    }

    .VueCarousel-navigation-prev.VueCarousel-navigation--disabled,
    .VueCarousel-navigation-next.VueCarousel-navigation--disabled {
        background-image: url('../../../assets/images/main/main-slider-arrow-right-disable.svg');
        opacity: 1;
    }

    .VueCarousel-navigation-next {
        position: relative;
        background-image: url('../../../assets/images/main/main-slider-arrow-right.svg');
        right: 10% !important;
    }
}

@media ( max-width: 1500px ) {

    .success-stories-vector {
        width: 110px;
    }
}

@media ( max-width: 1100px ) {

    .carousel-block {
        .VueCarousel-navigation-next {
            right: 0% !important;
        }
        .VueCarousel-navigation-prev {
            left: -20% !important;
        }
    }

}

@media ( max-width: 768px ) and (max-height: 1024px) {

    .success-stories-and-happy-costumers__content {
        margin-bottom: 180px;
    }

}

@media ( max-width: 768px ) {

    .carousel-block .VueCarousel {
        margin-left: 0;
    }

    .success-stories-vector{
        width: 72px;
        top: $dynamic-success-stories-vector-top-mobile;
        left: $dynamic-success-stories-vector-left-mobile;
    }

    .succes-stories-title-block {
        margin: 0 20px;
    }
    .succes-stories-text-block {

        &__main {
            line-height: 130%; /* 72px */
            font-size: 32px;
        }
        &__second {
            font-size: 14px;
        }
    }

    .block-hover {
        padding: 0;
        gap: 10px;

        &__number {
            font-size: 24px;
        }
        &__text {
            font-size: 14px;
            color: var(--ghost-text, #797E86);
        }
    }

    .success-stories-and-happy-costumers {

        &__blocks {
        gap: 50px;
        flex-direction: column;
        margin: 115px 0 0;
        }

        &__content{
            margin: 80px 0;
        }
    }

    .carousel-block {
        margin: 60px 0 0;
        display: flex;
        justify-content: center;

        .VueCarousel-slide {
            flex-basis: initial;
        }

        .VueCarousel-wrapper {
            overflow: hidden;
        }
    }
}

@media (min-width: 415px) and (max-width: 663px) {

    .success-stories-vector{
        top: 120px;
    }
}


@media ( max-width: 370px ) {

    .succes-stories-text-block__main {
        font-size: 30px;
    }
}

</style>
