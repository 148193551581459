<template>
    <b-container>
        <div class="choose-role">
            <h1 class="choose-role__title">Welcome to Oversecured!</h1>
            <h6 class="choose-role__subtitle">Please choose the sign-up method that best suits your needs.</h6>
            <div class="choose-role__list-cards">
<!--                todo display: none is temporary-->
<!--                we need to redesign this page-->
                <b-nav-item to="/sign-up/hacker" class="choose-role__card-link" style="display: none">
                    <div class="choose-role__card">
                        <img class="choose-role__card-icon"
                             src="../../assets/images/chooseRoleComponentCardIcon1.svg"
                             alt="oversecured"
                        >
                        <h2 class="choose-role__card-title">I’m a researcher</h2>
                        <hr class="choose-role__card-hr">
                        <p class="choose-role__card-text">
                            I want to scan third-party apps and submit reports to affected companies.
                        </p>
                    </div>
                </b-nav-item>
                <b-nav-item to="/sign-up/company" class="choose-role__card-link">
                    <div class="choose-role__card">
                        <img class="choose-role__card-triangle" src="../../assets/images/triangle.svg" alt="">
                        <img class="choose-role__card-icon"
                             src="../../assets/images/chooseRoleComponentCardIcon2.svg"
                             alt="oversecured"
                        >
                        <h2 class="choose-role__card-title choose-role__card-title--orange">I’m a company</h2>
                        <hr class="choose-role__card-hr">
                        <p class="choose-role__card-text">
                            I want to improve the security of my company's apps and get access to CI/CD features.
                        </p>
                    </div>
                </b-nav-item>
            </div>
        </div>
    </b-container>
</template>
<script>

export default {
}
</script>
<style lang="scss">
.choose-role {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
        font-family: Raleway, serif;
        font-weight: bold;
        font-size: 48px;
        color: #242424;
        margin: 80px 0 40px 0;
    }

    &__subtitle {
        font-family: Cabin, serif;
        font-weight: normal;
        font-size: 18px;
        color: #242424;
        margin-bottom: 44px;
    }

    &__list-cards {
        display: flex;
        justify-content: center;
        margin-bottom: 233px;
    }

    &__card-link {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    &__card {
        position: relative;
        max-width: 345px;
        width: 100%;
        border: 1px solid #242424;
        box-sizing: border-box;
        padding: 40px 27px;
        height: 345px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            box-shadow: 0 4px 15px rgba(102, 124, 131, 0.3);
        }
    }

    &__card-triangle {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    &__card-icon {
        width: 34px;
        height: 34px;
    }

    &__card-title {
        font-family: Cabin, serif;
        font-weight: bold;
        font-size: 24px;
        color: #242424;
        margin-top: 34px;

        &--orange {
            color: #FFB400;
        }
    }

    &__card-hr {
        border: 1px solid #BEBEBE;
        margin: 24px 0 28px 0;
    }

    &__card-text {
        font-family: Cabin, serif;
        font-weight: normal;
        font-size: 14px;
        color: #706969;
    }
}

@media (max-width: 768px) {
    .choose-role {
        padding: 20px;

        &__title {
            font-size: 36px;
            text-align: center;
        }

        &__subtitle {
            text-align: center;
        }

        &__list-cards {
            flex-direction: column;
            margin-bottom: 50px;
        }

        &__card-link {
            &:not(:last-child) {
                margin-right: 0;
            }
        }

        &__card {
            &:hover {
                box-shadow: none;
            }
        }
    }
}
</style>
