import Sockette from 'sockette'
import {refreshTokenRequest} from "./services/authorization/refreshToken";
import {ActivationCouponCodeEnum} from "./utils/constants/common";
import * as Sentry from "@sentry/browser";
const WEBSOCKET_URL = process.env.VUE_APP_SOCKET_URL
const {JobTypeEnum, SessionActionEnum} = require('../src/utils/constants/common')

export default ({store, next}) => {
    const accessToken = localStorage.getItem('accessToken')
    if (process.browser && !!accessToken && accessToken !== 'undefined') {
        const socket = store.getters['jobs/socket']
        if (!socket) {
            const ws = new Sockette(WEBSOCKET_URL, {
                maxAttempts: 10,
                onopen: (e) => {
                    ws.json({
                        action: 'initSocket',
                        data: {
                            token: accessToken
                        }
                    })
                },
                onmessage: async (e) => {
                    const message = JSON.parse(e.data)
                    const _accessToken = localStorage.getItem('accessToken')
                    const _refreshToken = localStorage.getItem('refreshToken');
                    if (message.type === JobTypeEnum.INTEGRATION) {
                        if (_accessToken) {
                            store.dispatch('integrations/updateVersion', message)
                        }
                    } else if (message.type === SessionActionEnum.REVOKE) {
                        try {
                            await refreshTokenRequest(_refreshToken)
                        } catch (error) {
                            console.error(error)
                        }
                    } else if (message.type === ActivationCouponCodeEnum.ACTIVATION_CODE) {
                        if (message.message === 'The code has already been redeemed' && message.statusCode === 503) {
                            store.commit('coupons/setActivationCodeError', {message: message.message, statusCode: message.statusCode, status: 'error'})
                        } else if (message.statusCode === 200) {
                            store.commit('coupons/setActivationCodeError', {message: 'The code was successfully redeemed', statusCode: message.statusCode, status: 'success'})
                            store.dispatch('scanPacks/getUserAvailableScans')
                            store.dispatch('scanPacks/getUserScanPacks')
                        }
                    } else {
                        if (_accessToken) {
                            store.commit('jobs/updateJob', message)
                        }
                    }
                },
                onreconnect: (e) => console.log('Reconnecting...', e),
                onmaximum: (e) => console.log('Stop Attempting!', e),
                onclose: (e) => {
                    console.log('Closed!', e)
                    if(e.code === 1001){
                        ws.open()
                    }
                    if(e.code === 1006){
                        /**
                         * when we get 1006 code Sockette try automatically reconnect
                         * but it always fail(maybe, because Sockette use the same instance for reconnect)
                         * and we have bunch of errors
                         * so here we close connection manually and then open brand new connection
                         */
                        Sentry.captureException(e)
                        ws.close()
                        ws.open()
                    }
                },
                onerror: (e) => {
                    console.log('Error:', e)
                    ws.close()
                }
            })
            store.commit('jobs/setSocket', ws)
        }
    }
        next()
    }
