<template>
    <div class="main-market-apps__wrapper">
        <b-container class="main-market-apps">
            <h2 class="main-market-apps__title">
              Start securing your mobile apps with us! <br> Upload it <span style="color: #FFD365">from any source</span> including application markets:
            </h2>
            <div class="main-market-apps__apps-list">
                <div class="main-market-apps__box">
                    <div class="main-market-apps__icon">
                        <img
                            class="market-icon"
                            src="../../assets/images/main/main_market_google_play.png"
                            alt="main_market_google_play"
                        >
                    </div>
                    <p class="main-market-apps__box-title">Google Play</p>
                </div>
                <div class="main-market-apps__box">
                    <div class="main-market-apps__icon">
                        <img
                            class="market-icon"
                            src="../../assets/images/main/main_market_app_store.png"
                            alt="main_market_app_store"
                        >
                    </div>
                    <p class="main-market-apps__box-title">App Store</p>
                </div>
            </div>
            <b-nav-item
                :to="{ path: '/integrations'}"
                class="main-top__link-login"
            >
                <p class="main-top__link-login-text">Start securing your apps</p>
                <img
                    src="../../assets/images/main/main-summary-arrow.svg"
                    alt="image"
                >
            </b-nav-item>
        </b-container>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
    .main-market-apps {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 110px 0 120px 0;
        font-family: Raleway,serif;
        color: #FFFFFF;

        &__wrapper {
            background-color: #3F3D39;
        }

        &__title {
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            white-space: nowrap;
            line-height: 115.19%;
            max-width: 1341px;
            height: 110px;
            text-align: center;
        }

        &__apps-list {
            display: flex;
            margin-top: 35px;
            padding-left: 90px;
        }

        &__box {
            display: flex;
            align-items: center;

            &:nth-child(1) {
                margin-right: 78px;
            }
        }

        &__box-title {
            margin: 0 0 0 27px;
        }

        &__scan-app-btn {
            display: flex;
            align-items: center;
            margin-top: 65px;
        }

        &__scan-app-btn-text {
            margin: 0 40px 0 0;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
        }
    }

    .main-top__link-login {
        display: flex;
        align-items: center;
        margin-top: 62px;
        cursor: pointer;
        flex-wrap: nowrap;

        & img {
            width: 57px;
            height: 57px;
        }

        &:hover .main-top__link-login-text {
            text-decoration: underline;
        }
    }

    .main-top__link-login-text {
        margin: 0 24px 0;
        font-family: Raleway,serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        height: 29px;
        color: #FFFFFF;
    }

    .market-icon {
        max-width: 67px;
    }


    .main-top__link-login-text-btn {
        margin: 0;
    }

    .nav-link {
        display: flex;
        align-items: center;
    }

    @media (max-width: 1000px) {
        .main-market-apps {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 93px 0 70px 0;
            font-family: Raleway,serif;
            color: #FFFFFF;

            &__wrapper {
                background-color: #3F3D39;
            }

            &__title {
              white-space: break-spaces;
              font-size: 24px;
            }

            &__apps-list {
                display: flex;
                margin-top: 35px;
                padding-left: 10px;
            }

            &__box {
                display: flex;
                align-items: center;

                &:nth-child(1) {
                    margin-right: 10px;
                }
            }

            &__box-title {
                margin: 0 0 0 27px;
            }

            &__scan-app-btn {
                display: flex;
                align-items: center;
                margin-top: 65px;
            }

            &__scan-app-btn-text {
                margin: 0 40px 0 0;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;
            }
        }

      .main-top__link-login {
          margin-top: 50px !important;
      }
      .main-top__link-login-text {
          font-size: 18px;
      }

      .main-top__link-login img {
          width: 50px;
          height: 50px;
      }
    }
</style>
