<template>
    <div class="markdown">
        <b-nav-item :to="{ path: `${makeLinkToAnchorScanConfigurations('')}`}" class="md__link-wrapper">
            <h2 class="md__item-title">Introduction</h2>
        </b-nav-item>
        <p> 
            Oversecured scans Android and iOS mobile apps to detect security vulnerabilities. 
            Android apps are scanned in compiled form, as <code>.apk</code> and <code>.aab</code> files. iOS apps are 
            scanned in source form as a <code>.zip</code> archive.
        </p>
        <p>
            Android apps are first decompiled to Java code, which makes it possible to analyze both apps written 
            in Java and Kotlin-based apps.
        </p>
        <p>
            iOS apps are scanned in source code form as an Xcode project in a <code>.zip</code> archive. 
            Unlike with Android, the source can contain test data which may be reflected in the scan report. 
            To avoid undesirable results of this kind, you can use a special 
            <b-nav-item
                :href="makeSubLinkToAnchorScanConfigurations('The Oversecured.config file', 'configurations/ios')">
                configuration file
            </b-nav-item>.
        </p>
        <p>
            Oversecured also automatically filters vulnerabilities on all platforms that could be false positives. 
            You can 
            <b-nav-item
                :href="makeSubLinkToAnchorScanConfigurations('SmartFilter configuration', 'configurations/smart-filter')">
                disable this filter
            </b-nav-item>
            to view the complete report. On the one hand, this means the report will be full of false positives; on the other, 
            however, it can help you locate all potentially dangerous points in your app.
        </p>
    </div>
</template>

<script>
import {makeLinkToAnchorScanConfigurations, makeSubLinkToAnchorScanConfigurations} from "../../../services/functions";

export default {
    methods: {
        makeLinkToAnchorScanConfigurations,
        makeSubLinkToAnchorScanConfigurations
    }
}
</script>

<style scoped>

</style>
