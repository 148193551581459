const axios = require('axios');
let { getInstance } = require('./auth');

let BASE_URL;
if (process.env.NODE_ENV !== 'production') console.log(process.env.NODE_ENV);

if (process.env.NODE_ENV === 'test') {
    BASE_URL = 'http://localhost:3000/dev';
} else {
    BASE_URL = process.env.VUE_APP_BASE_URL;
}

let headers = {
    'Accept': 'application/json',
    'x-api-key': process.env.VUE_APP_API_KEY,
};

let axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: headers,
});

axiosInstance.interceptors.request.use(
    async config => {
        try {
            let token = await getInstance().getTokenSilently();

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        } catch (e) {
            console.log(e);
            console.log(`unauthenticated request to ${config.url}`);
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            console.log('unauthorized, logging out ...');
            getInstance().logout();
        } else if (error.response && error.response.status === 403) {
            const currentUrl = new URL(location.href);
            const params = `${currentUrl.pathname}${currentUrl.search}`;
            getInstance().forbidden(params);
        }
        return Promise.reject(error);
    });

module.exports = axiosInstance;
