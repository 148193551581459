<template>
    <div>
        <div class="new-integrations__how-it-works-block">
            <h2 class="new-integrations__how-it-works-block-sigh">How it works</h2>
            <p class="new-integrations__how-it-works-block-text">
                Oversecured offers app owners and developers the ability to secure each new version of a mobile app by
                integrating Oversecured into the development process. We will scan the alpha and beta versions of your
                apps and developers will fix the vulnerabilities we discover, then send the protected version to end
                users via App Stores.
            </p>
        </div>
        <div class="new-integrations__illustration-wrapper">
            <img
                class="new-integrations__illustration new-integrations__illustration--large-permission"
                src="../../assets/images/main/main-btob-massive.png"
                alt="illustration"
            />
            <img
                class="new-integrations__illustration new-integrations__illustration--small-permission"
                src="../../assets/images/main/main-btob.png"
                alt="illustration"
            />
            <div class="new-integrations__box new-integrations__box--first">
                <p class="new-integrations__box-number">1.</p>
                <p class="new-integrations__box-text new-integrations__box-text--first">
                    Add your app to the integration list
                </p>
            </div>
            <div class="new-integrations__box new-integrations__box--second">
                <p class="new-integrations__box-number">2.</p>
                <p class="new-integrations__box-text new-integrations__box-text--second">
                    Scan each new version of the app
                </p>
            </div>
            <div class="new-integrations__box new-integrations__box--third">
                <p class="new-integrations__box-number">3.</p>
                <p class="new-integrations__box-text new-integrations__box-text--third">
                    Fix vulnerabilities in the alpha and beta versions
                </p>
            </div>
            <div class="new-integrations__box new-integrations__box--fourth">
                <p class="new-integrations__box-number">4.</p>
                <p class="new-integrations__box-text new-integrations__box-text--fourth">
                    Send users protected apps, without vulnerabilities in release versions
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {}
</script>
<style lang="scss">

.new-integrations {
    &__how-it-works-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 70px 0;

        &-sigh {
            font-family: Raleway, serif;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 115.19%;
            color: #242424;
        }

        &-text {
            font-family: Cabin, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            color: #706969;
            max-width: 551px;
            margin-top: 40px;
        }
    }

    &__illustration-wrapper {
        display: flex;
        padding-left: 150px;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        margin: 100px 0 100px 0;
    }

    &__illustration {
        max-width: 644px;
        max-height: 700px;

        &--small-permission {
            display: none;
        }
    }

    &__box-number {
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        color: #FFB000;
    }

    &__box-text {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: #706969;
        margin: 4px 0 0 8px;

        &--first {
            max-width: 142px;
        }

        &--second,
        &--third {
            max-width: 163px;
        }

        &--fourth {
            max-width: 200px;
        }
    }

    &__box {
        font-family: Cabin, serif;
        font-style: normal;
        position: absolute;
        display: flex;
        align-items: flex-start;

        &--first {
            top: 0;
            left: 16%;
        }

        &--second {
            top: 75px;
            left: 64%;
        }

        &--third {
            top: 280px;
            left: 73%;
        }

        &--fourth {
            top: 616px;
            left: 15%;
        }
    }
}

@media (max-width: 1000px) {
    .new-integrations {
        &__how-it-works-block {
            margin: 90px 0;
            padding: 0 20px;
        }

        &__illustration-wrapper {
            padding-left: 40px;
            justify-content: center;
        }

        &__illustration {
            max-width: 335px;
            max-height: 540px;

            &--large-permission {
                display: none;
            }

            &--small-permission {
                display: block;
                width: 100%;
            }
        }

        &__box-text {

            &--second,
            &--third {
                max-width: 126px;
            }
        }

        &__box {

            &--first {
                top: -55px;
                left: 35%;
            }

            &--second {
                top: 55px;
                left: 62%;
            }

            &--third {
                top: 325px;
                left: 25%;
            }

            &--fourth {
                top: 485px;
                left: 45%;
            }
        }
    }
}

@media (max-width: 400px) {
    .new-integrations {
        &__illustration-wrapper {
            padding-left: 20px;
            justify-content: flex-start;
        }

        &__box {

            &--first {
                top: -55px;
                left: 11%;
            }

            &--second {
                top: 55px;
                left: 62%;
            }

            &--third {
                top: 325px;
                left: 11%;
            }

            &--fourth {
                top: 485px;
                left: 45%;
            }
        }
    }
}
</style>
