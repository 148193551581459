<template>
    <div>
        <div
            v-if="(!isTrialIntegrationRestriction && allVulnerabilitiesCount > 0 && !allSeveritiesUnchecked) || (highSeverityCountForTrial + mediumSeverityCountForTrial === 0 && allVulnerabilitiesCount > 0)"
            class="statistics"
        >
            <div class="statistics__wrapper">
                <!-- HIGH -->
                <div class="statistics__box">
                    <div class="statistics__box__container">
                        <div class="calculation-block">
                            <statistic-circle
                                :value="highSeverityCount"
                                :total="allVulnerabilitiesCount"
                                :width="64"
                                :height="64"
                                :stroke-size="4"
                                severity="high"
                            />
                            <div class="percentage">
                                {{ highSeverityPercentage }}%
                            </div>
                        </div>
                        <div class="description-block">
                            <div class="title">
                                High severity {{ isVulnerabilityAmountPlural(highSeverityCount) }}
                            </div>
                            <div class="details">
                                Can lead to user data being leaked and to a serious violation of the user's privacy
                            </div>
                        </div>
                    </div>
                </div>
                <!-- MEDIUM -->
                <div class="statistics__box">
                    <div class="statistics__box__container">
                        <div class="calculation-block">
                            <statistic-circle
                                :value="mediumSeverityCount"
                                :total="allVulnerabilitiesCount"
                                :width="64"
                                :height="64"
                                :stroke-size="4"
                                severity="medium"
                            />
                            <div class="percentage">
                                {{ mediumSeverityPercentage }}%
                            </div>
                        </div>
                        <div class="description-block">
                            <div class="title">
                                Medium severity {{ isVulnerabilityAmountPlural(mediumSeverityCount) }}
                            </div>
                            <div class="details">
                                May affect the app's level of protection and reduce the level of security
                            </div>
                        </div>
                    </div>
                </div>
                <!-- LOW -->
                <div class="statistics__box">
                    <div class="statistics__box__container">
                        <div class="calculation-block">
                            <statistic-circle
                                :value="lowSeverityCount"
                                :total="allVulnerabilitiesCount"
                                :width="64"
                                :height="64"
                                :stroke-size="4"
                                severity="low"
                            />
                            <div class="percentage">
                                {{ lowSeverityPercentage }}%
                            </div>
                        </div>
                        <div class="description-block">
                            <div class="title">
                                Low severity {{ isVulnerabilityAmountPlural(lowSeverityCount) }}
                            </div>
                            <div class="details">
                                Violate best practices
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TRIAL BANNER -->
        <trialBanner
            v-if="(isTrialIntegrationRestriction) && highSeverityCountForTrial + mediumSeverityCountForTrial > 0"
            :job="job"
            :high-severity-count="highSeverityCountForTrial"
            :medium-severity-count="mediumSeverityCountForTrial"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { isHighSeverityExist, isMediumSeverityExist } from '../../services/functions';
import { isVulnerabilityAmountPlural } from '../../utils/helper-functions';
import statisticCircle from './components/statisticCircle.vue';
import trialBanner from './components/trialBanner.vue';

export default {
    components: {
        statisticCircle,
        trialBanner,
    },
    props: {
        job: Object,
        allVulnerabilitiesCount: {
            type: Number,
            required: true,
            default: 0,
        },
        integrationId: {
            type: String,
            required: false,
            default: null,
        },
        allVulnerabilitiesFolder: {
            type: Array,
            required: false,
            default: () => [],
        },
        falsePositive: Boolean,
        isTrialIntegrationRestriction: Boolean,
    },
    data() {
        return {
            severityCounts: {},
        };
    },
    computed: {
        ...mapGetters({
            allSeveritiesUnchecked: 'filters/allSeveritiesUnchecked',
        }),
        highSeverityCountForTrial() {
            return this.allVulnerabilitiesFolder[0].statistics.find(item => item.severity === 'high')?.count || 0;
        },
        mediumSeverityCountForTrial() {
            return this.allVulnerabilitiesFolder[0].statistics.find(item => item.severity === 'medium')?.count || 0;
        },
        highSeverityCount() {
            return this.severityCounts.high?.count || 0;
        },
        highSeverityPercentage() {
            return Math.round(this.severityCounts.high?.percentage) || 0;
        },
        mediumSeverityCount() {
            return this.severityCounts.medium?.count || 0;
        },
        mediumSeverityPercentage() {
            return Math.round(this.severityCounts.medium?.percentage) || 0;
        },
        lowSeverityCount() {
            return this.severityCounts.low?.count || 0;
        },
        lowSeverityPercentage() {
            return Math.round(this.severityCounts.low?.percentage) || 0;
        },
    },
    watch: {
        job() {
            this.calculateSeverityCounts();
        },
    },
    created() {
        this.calculateSeverityCounts();
    },
    methods: {
        calculateSeverityCounts() {
            let severityPercentages = this.job.statistics.percentageBySeverity;

            this.severityCounts = this.job.statistics.percentageBySeverity.commonAmountVulnerabilitiesBySeverity.reduce((acc, curr) => {
                acc[curr.severity] = {
                    count: curr.count,
                    percentage: severityPercentages[curr.severity] || 0,
                };
                return acc;
            }, {});
        },
        isHighSeverityExist,
        isMediumSeverityExist,
        isVulnerabilityAmountPlural,
    },
};
</script>
<style lang="scss" scoped>
@import '../../assets/css/fonts';
@import '../../assets/css/variables';

// Desktop
.statistics {
    margin-top: 40px;

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr)); /* adjust the min value as needed */
        gap: 20px;
        width: 100%;
    }

    &__box {
        background: #F8FAFC;
        border-radius: 20px;

        &__container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 20px;

            margin: 20px;

            .calculation-block {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-width: 64px;

                .percentage {
                    margin-top: 10px;
                    text-align: center;

                    @include Aspekta-font(650, 22px, 31px, #021631);
                }
            }

            .description-block {
                min-width: 177px;
                min-height: 142px;

                .title {
                    @include Aspekta-font(600, 18px, 26px, #242424);
                }

                .details {
                    margin-top: 10px;

                    @include Aspekta-font(400, 14px, 20px, #5C6A7A);
                    letter-spacing: 0.02em;
                }
            }
        }
    }
}

// Tablet
@media (max-width: 834px) {
    .statistics {
        &__wrapper {
            display: flex;
            flex-direction: column;
        }

        &__box {
            &__container {
                flex-direction: row-reverse;
                justify-content: space-between;

                .description-block {
                    min-height: 80px;
                }

                .calculation-block {

                    .percentage {
                        display: none;
                    }
                }
            }
        }
    }
}

// Mobile
@media (max-width: 576px) {
    .statistics {
        margin-top: 36px;

        &__wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__box {
            background: none;
            &__container {
                margin: 0;
                .description-block {
                    display: none;
                }

                .calculation-block {

                    .percentage {
                        display: none;
                    }
                }
            }
        }
    }

}
</style>
