<template>
    <step-control-buttons
        v-if="!showActivationCodeButtons"
        :confirm-button="payConfirmButton"
        :disable-confirm-button="disableConfirmButton"
        :disable-next-button="disableNextButton"
        :disable-back-button="disableBackButton"
        @back="moveBack"
        @next="moveNext"
        @confirm="confirmScan"
    />
    <activation-code-buttons
        v-else
        :disable-confirm-button="disableActivationConfirmButton"
        @cancel="cancelActivationCode"
        @confirm="confirmActivationCode"
    />
</template>

<script>
import StepControlButtons from '@/components/newScan/StepControlButtons.vue';
import ActivationCodeButtons from '@/components/newScan/ActivationCodeButtons.vue';

export default {
    name: 'StepsAndActivationCodeButtons',
    components: {
        StepControlButtons,
        ActivationCodeButtons,
    },
    props: {
        showActivationCodeButtons: Boolean,
        disableConfirmButton: Boolean,
        disableNextButton: Boolean,
        payConfirmButton: Boolean,
        disableActivationConfirmButton: Boolean,
        disableBackButton: Boolean,
    },
    methods: {
        moveBack() {
            this.$emit('back');
        },
        moveNext() {
            this.$emit('next');
        },
        confirmScan() {
            this.$emit('confirm-scan');
        },
        cancelActivationCode() {
            this.$emit('cancel');
        },
        confirmActivationCode() {
            this.$emit('confirm-code');
        },
    },
};
</script>
