<template>
    <button
        class="popup__button"
        :class="buttonStyles"
        :disabled="disabled"
        @click="buttonClick"
        @mouseover="changeIconColor"
        @mouseout="resetIconColor"
    >
        <DeleteIcon
            v-if="icon === 'delete' || icon === true"
            :svg-color="iconColor"
            :svg-height="20"
            :svg-width="20"
            class="popup__button-icon"
        />
        <UploadFileIcon
            v-if="icon === 'upload-file'"
            :svg-color="iconColor"
            :svg-height="25"
            :svg-width="21"
            class="popup__button-icon"
        />
        <AddBranchIcon
            v-if="icon === 'add-branch'"
            :svg-color="iconColor"
            :svg-height="28"
            :svg-width="29"
            class="popup__button-icon"
        />
        <span class="popup__button-text">
            {{ buttonText }}
        </span>
    </button>
</template>

<script>
import DeleteIcon from '../../assets/iconTemplates/DeleteIcon.vue';
import UploadFileIcon from '../../assets/iconTemplates/UploadFileIcon.vue';
import AddBranchIcon from '../../assets/iconTemplates/BranchIcon.vue';

export default {
    components: {
        DeleteIcon,
        UploadFileIcon,
        AddBranchIcon,
    },
    props: {
        buttonText: {
            type: String,
            required: true,
        },
        buttonType: {
            type: String,
            required: true,
        },
        icon: {
            type: [String, Boolean],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        stopPropagation: {
            type: Boolean,
            default: false,
        },
        preventDefault: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            iconColor: this.disabled ? this.iconDisabledColor : this.iconInitialColor,
        };
    },
    computed: {
        buttonStyles() {
            return {
                'button--cancel': this.buttonType === 'cancel',
                'button--delete': this.buttonType === 'delete',
                'button--create': this.buttonType === 'create' || this.buttonType === 'rename',
                'button--upload': this.buttonType === 'upload',
                'button--add': this.buttonType === 'add',
                'button--add-branch': this.buttonType === 'add-branch',
                'button--disabled': this.disabled,
            };
        },
        iconInitialColor() {
            switch (this.buttonType) {
                case 'delete':
                    return '#FE2027';
                case 'create':
                    return '#3180F6';
                case 'upload':
                    return '#FF7A08';
                case 'add':
                    return '#FFF';
                case 'add-branch':
                    return '#3180F6';
                default:
                    return '#000000'; // Default color
            }
        },
        iconHoverColor() {
            switch (this.buttonType) {
                case 'delete':
                    return '#FFFFFF';
                case 'create':
                    return '#FFFFFF';
                case 'upload':
                    return '#FFFFFF';
                case 'add':
                    return '#FFFFFF';
                case 'add-branch':
                    return '#FFFFFF';
                default:
                    return '#000000'; // Default color
            }
        },
        iconDisabledColor() {
            return '#D9D9D9';
        },
    },
    watch: {
        disabled: function (newVal) {
            this.iconColor = newVal ? this.iconDisabledColor : this.iconInitialColor;
        },
    },
    methods: {
        buttonClick(event) {
            if (this.stopPropagation) {
                event.stopPropagation();
            }
            if (this.preventDefault) {
                event.preventDefault();
            }
            if (!this.disabled) {
                this.$emit('buttonClick');
            }
        },
        changeIconColor() {
            if (!this.disabled) {
                this.iconColor = this.iconHoverColor;
            }
        },
        resetIconColor() {
            this.iconColor = this.disabled ? this.iconDisabledColor : this.iconInitialColor;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

.popup__button {
    @include Aspekta-font(600, 18px, 26px, transparent);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $dynamic-button-horizontal-padding $dynamic-button-vertical-padding;
    width: 100%;
    height: 54px;
    border-radius: 8px;
    text-align: center;
    letter-spacing: 0.03em;
    border: 0;
    cursor: pointer;

    &-icon {
        display: flex;
        fill: currentColor;
        margin-right: 10px;
    }

    &-text {
        color: inherit;
    }

    &.button--cancel {
        @include Aspekta-font(650, $dynamic-button-font-size, normal, #949DB8);
        background: #F5F7FA;
        color: #949DB8;

        &:hover:not(.button--disabled) {
            background: #FFFFFF;
            border: 1px solid #E3E6ED;
            box-shadow: 0px 12px 16px rgba(178, 185, 196, 0.12);
        }
    }

    &.button--delete {
        @include Aspekta-font(650, $dynamic-button-font-size, normal, $error-color);
        background: #FFEDED;

        &:hover:not(.button--disabled) {
            background: $error-color;
            color: #FFEDED;

            box-shadow: 0px 8px 16px rgba(230, 61, 66, 0.12);
        }
    }

    &.button--create {
        color: $main-blue-color;
        background: $background-blue-color;

        &:hover:not(.button--disabled) {
            color: $background-blue-color;
            background: $main-blue-color;
        }
    }

    &.button--upload {
        @include Aspekta-font(650, $dynamic-button-font-size, normal, #FF7A08);
        background: #FFF2E6;

        &:hover:not(.button--disabled) {
            color: #FFF;
            background: #FF7A08;
            box-shadow: 0px 8px 16px 0px rgba(255, 180, 0, 0.24);
        }
    }

    &.button--add {
        @include Aspekta-font(650, $dynamic-button-font-size, normal, #FF7A08);
        border: 2px solid #FFD2AB;
        background: #FFF;

        &:hover:not(.button--disabled) {
            box-shadow: 0px 8px 16px 0px rgba(255, 180, 0, 0.24);
        }
    }

    &.button--add-branch {
        @include Aspekta-font(650, $dynamic-button-font-size, normal, #3180F6);
        border: 2px solid #ADCCFB;
        background: #FFF;

        &:hover:not(.button--disabled) {
            border: 2px solid #3180F6;
            box-shadow: 0px 12px 16px 0px rgba(51, 128, 244, 0.12);
        }

        &:disabled {
            border: 2px solid rgba(217, 217, 217, 0.5);
        }
    }

    &.button--disabled {
        color: #D9D9D9;
        background: #F9F9F9;
        cursor: default;

        &:hover {
            opacity: 0.62;
        }
    }
}

@media (max-width: 767px) {
    .popup__button {

        &.button--cancel,
        &.button--delete,
        &.button--create,
        &.button--upload,
        &.button--add {
            font-weight: 600;
        }

    }
}
</style>
