<template>
    <div class="vulnerability-circle">
        <svg
            :viewBox="viewBox"
            class="circular-chart"
            :style="{ width: `${width}px`, height: `${height}px` }"
        >
            <circle
                class="circle-bg"
                :class="severity"
                :r="radius"
                :cx="width / 2"
                :cy="height / 2"
                :style="{ strokeWidth: strokeSize }"
            />
            <circle
                class="circle"
                :class="severity"
                :r="radius"
                :cx="width / 2"
                :cy="height / 2"
                :style="{ strokeDasharray: `${circumference} ${circumference}`, strokeDashoffset: strokeOffset, strokeWidth: strokeSize, transform: 'rotate(-90deg)', transformOrigin: 'center' }"
            />
            <foreignObject
                :x="(width - textWidth) / 2"
                :y="(height - textHeight) / 2"
                :width="textWidth"
                :height="textHeight"
            >
                <div
                    xmlns="http://www.w3.org/1999/xhtml"
                    class="text-container"
                    :class="severity"
                >
                    {{ value }}
                </div>
            </foreignObject>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: true,
            default: 0,
        },
        total: {
            type: Number,
            required: true,
            default: 0,
        },
        width: {
            type: Number,
            default: 100,
        },
        height: {
            type: Number,
            default: 100,
        },
        strokeSize: {
            type: Number,
            default: 4,
        },
        severity: {
            type: String,
            default: '',
        },
    },
    computed: {
        radius() {
            // Subtract the strokeSize to avoid overflow
            return this.width / 2 - this.strokeSize;
        },
        circumference() {
            return 2 * Math.PI * this.radius;
        },
        strokeOffset() {
            return this.circumference - (this.value / this.total) * this.circumference;
        },
        viewBox() {
            return `0 0 ${this.width} ${this.height}`;
        },
        textWidth() {
            return this.width - 2 * this.textPadding;
        },
        textHeight() {
            return this.height - 2 * this.textPadding;
        },
        textPadding() {
            return this.strokeSize * 2;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

@mixin severity-colors($high-color, $medium-color, $low-color) {
    &.high {
        stroke: $high-color;
        color: $high-color;
    }

    &.medium {
        stroke: $medium-color;
        color: $medium-color;
    }

    &.low {
        stroke: $low-color;
        color: $low-color;
    }
}

.vulnerability-circle {
    display: inline-block;
}

.circular-chart {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

.circle-bg {
    @include severity-colors($background-orange-color, $background-yellow-color, $background-green-color);
    fill: #FFF;
}

.circle {
    @include severity-colors($main-orange-color, $main-yellow-color, $main-green-color);
    fill: none;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.percentage {
    fill: #666;
    text-anchor: middle;
    dominant-baseline: middle;
}

.text-container {
    @include Aspekta-font(600, 18px, 26px, #021631);
    // @include severity-colors($main-orange-color, $main-yellow-color, $main-green-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
  </style>
