<template>
    <div class="markdown">
        <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('travis')}`}" class="md__link-wrapper">
            <h2 class="md__item-title">Using Oversecured with Travis CI</h2>
        </b-nav-item>
        <ol>
            <li>
                <a
                    class="markdown__download-link"
                    @click="handleDownloadFile(`${CONTENT_DOMAIN_NAME}/oversecured.py`, 'oversecured.py')"
                    target="_blank"
                    :href="`${CONTENT_DOMAIN_NAME}/oversecured.py`"
                >
                    Download a Python script
                </a>
                to send the app to be scanned, and
                save it in your project
            </li>
            <li><a href="https://docs.travis-ci.com/user/encryption-keys#usage">Create an encrypted environment
                variable</a> and move <code>OVERSECURED_API_KEY</code> to it
            </li>
            <li>Set <code>OVERSECURED_INTEGRATION_ID</code> and <code>OVERSECURED_BRANCH_NAME</code> in environment variables</li>
            <li>Install the Python <code>requests</code> module by using the command <code>pip install
                requests</code></li>
            <li>Add running <code>oversecured.py</code></li>
        </ol>
        <p>You will end up with a <code>.travis.yml</code> config resembling this:</p>
        <pre class="  language-yml"><code class="  language-yml"><span
            class="token key atrule">language</span><span class="token punctuation">:</span> android
<span class="token key atrule">dist</span><span class="token punctuation">:</span> trusty
<span class="token key atrule">jdk</span><span class="token punctuation">:</span> oraclejdk8

<span class="token key atrule">cache</span><span class="token punctuation">:</span>
  <span class="token key atrule">directories</span><span class="token punctuation">:</span>
  <span class="token punctuation">-</span> $HOME/.gradle/caches/
  <span class="token punctuation">-</span> $HOME/.gradle/wrapper/
  <span class="token punctuation">-</span> $HOME/.android/build<span class="token punctuation">-</span>cache

<span class="token key atrule">env</span><span class="token punctuation">:</span>
 <span class="token key atrule">global</span><span class="token punctuation">:</span>
 <span class="token punctuation">-</span> ANDROID_API=28
 <span class="token punctuation">-</span> ANDROID_BUILD_TOOLS=28.0.3
 <span class="token punctuation">-</span> <span class="token key atrule">secure</span><span
                class="token punctuation">:</span> sBMVnBB/WRWgoAytEFGx4CC5O55Q63h02ZcuBnb1jXcBm0RenoBpzUPtxSseJwDPUA1o/UkuEDDjm3PosT5NF+afcED01VDFMsPVE11K0u6+avYy3jYXqyUEDW3G2o6Wo/2aqPamn++8jskBdS9+Cx9gaFbgxdsSp0Yfu3oJm/4c=
 <span class="token punctuation">-</span> OVERSECURED_INTEGRATION_ID=5e7096e2<span class="token punctuation">-</span>7fed<span
                class="token punctuation">-</span>43f0<span class="token punctuation">-</span>9290<span
                class="token punctuation">-</span>22d03c64cfbf
 <span class="token punctuation">-</span> APK_PATH=$TRAVIS_BUILD_DIR/app/build/outputs/apk/debug/app<span
                class="token punctuation">-</span>debug.apk

<span class="token key atrule">android</span><span class="token punctuation">:</span>
  <span class="token key atrule">components</span><span class="token punctuation">:</span>
  <span class="token punctuation">-</span> build<span class="token punctuation">-</span>tools<span
                class="token punctuation">-</span>28.0.3
  <span class="token punctuation">-</span> android<span class="token punctuation">-</span><span
                class="token number">28</span>
  <span class="token punctuation">-</span> add<span class="token punctuation">-</span>on
  <span class="token punctuation">-</span> extra
  <span class="token punctuation">-</span> extra<span class="token punctuation">-</span>google<span
                class="token punctuation">-</span>m2repository

<span class="token key atrule">before_install</span><span class="token punctuation">:</span>
  <span class="token punctuation">-</span> pyenv install 3.6.2
  <span class="token punctuation">-</span> pyenv global 3.6.2
  <span class="token punctuation">-</span> pip install requests

<span class="token key atrule">script</span><span class="token punctuation">:</span>
<span class="token punctuation">-</span> ./gradlew assembleDebug
<span class="token punctuation">-</span> python ./scripts/oversecured.py $APK_PATH
</code></pre>

    </div>
</template>

<script>
import {makeAnchor, makeLinkToAnchor, makeLinkToAnchorCICD} from "../../../services/functions";
import {makeSubLinkToAnchor} from "@/services/functions";
import {handleDownloadFile} from "@/services/downloadFile";

export default {
    data() {
        return {
            CONTENT_DOMAIN_NAME: process.env.VUE_APP_CONTENT_DOMAIN_NAME
        }
    },
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor,
        makeLinkToAnchorCICD,
        handleDownloadFile,
    }
}
</script>

<style scoped>

</style>