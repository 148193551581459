<template>
    <div class="animation">
        <img
            class="background-image"
            src="../../../assets/images/main/animation-images/scan-report-1.svg"
            alt="scan-report"
        >
        <img
            class="top-image"
            src="../../../assets/images/main/animation-images/high-severity.png"
            alt="high-severity"
        >
    </div>
</template>

<script>
export default {
    name: 'Animation',
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.animation {
  position: relative;
  width: 100%;
  height: $dynamic-hover-block-height;
  flex-shrink: 0;
  overflow: hidden;
}

.top-image {
    position: absolute;
    top: 360px;
    left: 10px;
    width: $dynamic-small-vulnerability-width;
    height: $dynamic-small-vulnerability-height;
    flex-shrink: 0;
    z-index: 4;
    border-radius: 40px;
    transition: transform 1.1s ease;
}

.background-image {
    position: relative;
    top: 20px;
    left: 115px;
    width: $dynamic-scan-width;
    height: $dynamic-scan-height;
    flex-shrink: 0;
    z-index: 2;
    border-radius: 40px;
    transition: transform 1.1s ease;
}

.animation:hover .top-image{
  transform: translateX(-5%) scale(1.08, 1.08);
}

.animation:hover .background-image{
  transform: translateX(-5%) translateY(15px);
}

</style>
