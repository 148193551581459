<template>
    <div class="markdown">
        <b-nav-item :to="{ path: `${makeLinkToAnchor('')}`}" class="md__link-wrapper">
            <!--            <p class="md__anchor">#</p>-->
            <h2 class="md__item-title">Introduction</h2>
        </b-nav-item>
        <p>This is the documentation for the Oversecured v1 API, which allows users to work with
            <b-nav-item class="link" :to="{ path: `/docs/api/single-scans`}">
                Single Scans
            </b-nav-item>
            and
            <b-nav-item class="link" :to="{ path: `/docs/api/integrations`}">
                Integrations
            </b-nav-item>
            to scan mobile apps or new versions of them, to
            <b-nav-item class="link" :to="{ path: `/docs/api/vulnerabilities`}">
                study and work with reports
            </b-nav-item>, and also to
            compare changes in different versions of the same app.
        </p>
        <h3 :id="makeAnchor('Different types of scan')"><a
            :href="makeSubLinkToAnchor('Different types of scan', '')">Different types of scan</a></h3>
        <p>Oversecured offers Single Scan and Integration modes for scanning apps. The difference
            between them is that Single Scan provides the user with a vulnerability report on the file
            that is submitted, with no further support or monitoring of vulnerabilities. This mode is
            therefore best suited to security researchers. Integrations allow users to monitor their
            desired app and receive alerts about vulnerabilities detected in new versions, so this mode
            is more suitable for app owners and developers who want to avoid vulnerabilities or fix them
            rapidly.</p>
        <p>There is also a difference in price. Single Scan is offered at a fixed price per scan.
            Integrations allow users to scan an unlimited number of versions during the subscription
            period.</p>
        <h3 :id="makeAnchor('Getting started with the Oversecured API')"><a
            :href="makeSubLinkToAnchor('Getting started with the Oversecured API', '')">Getting started with
            the Oversecured API</a></h3>
        <p>Before you can start using the API, you need to do the following:</p>
        <ol>
            <li>Create an Oversecured account.</li>
            <li>Create an API Key.</li>
            <li>Make sure you have curl installed on your machine.</li>
        </ol>
        <h3 :id="makeAnchor('Creating an API access key')"><a
            :href="makeSubLinkToAnchor('Creating an API access key', '')">Creating an API access key</a></h3>
        <p>A key can be created in your
            <b-nav-item class="link" :to="{ path: `/settings/api-keys`}" href="https://oversecured.com/settings/api-keys">user profile
            </b-nav-item>. It
            needs to be added to any query to the API. Example:
        </p>
        <pre class="  language-metadata"><code class="  language-metadata">curl https://api.oversecured.com/v1/profile/me \
  -H 'Authorization: ovsrd_d6519328e5b17ebca15bd6fda6b1e7e333cf50cfc55d23998183874633b349abdc969b44a13b8ea3'
</code></pre>
        <h3 :id="makeAnchor('Restrictions')"><a
            :href="makeSubLinkToAnchor('Restrictions', '')">Restrictions</a></h3>
        <p>Users with a <code>hacker</code> account type do not have access to Integrations, only Single
            Scans. Users with <code>company</code> accounts have access to both Single Scans and
            Integrations.</p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { makeAnchor, makeLinkToAnchor } from "../../../services/functions";
import { makeSubLinkToAnchor } from "@/services/functions";
import { GetStartedStepsEnum } from '@/utils/constants/common';

export default {
    computed: {
        ...mapGetters({
            getStartedSteps: 'user/getStartedSteps',
            userType: 'user/userType',
        })
    },
    mounted() {
        const isStepCompleted = this.getStartedSteps.includes(GetStartedStepsEnum.WATCHED_API);
        if (this.userType === 'company' && !isStepCompleted) {
            this.$store.dispatch('user/updateGetStartedSteps', GetStartedStepsEnum.WATCHED_API);
        }
    },
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor,
    }
}
</script>

<style scoped>

</style>
