<template>
    <svg
        :width="isMobile ? '32' : '20'"
        :height="isMobile ? '32' : '20'"
        :viewBox="isMobile ? '0 0 32 32' : '0 0 20 20'"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            :cx="isMobile ? '16' : '10'"
            :cy="isMobile ? '16' : '10'"
            :r="isMobile ? (state === 'passed' ? '6.5' : '8.5') : '7.5'"
            :fill="fillColor"
            stroke="#E8E7EF"
            :stroke-width="isMobile ? '3' : '5'"
        />
    </svg>
</template>
<script>
export default {
        props: {
            state: {
                type: String,
                require: true,
            },

            isMobile: {
                type: Boolean,
                require: true,
            },
    },    
    computed: {
        fillColor() {
            if(this.state === 'active') {
                return '#FF7A08';
            }

            else if(this.state === 'passed') {
                return '#FFD7B5';
            }

            else if(this.isMobile) {
                return '#F6F6F9';
            }

            return '#E8E7EF';
            
        },
    },
};
</script>