<template>
    <div class="scan-packs-history">
        <div class="scan-packs-history__header">
            <h1 class="scan-packs-history__title">
                {{ availableScansCount }} {{ availableScansCount === 1 ? 'scan' : 'scans' }} available
            </h1>
            <div class="scan-packs-history__box">
                <button
                    class="scan-packs-history__btn scan-packs-history__btn--white"
                    @click="toggleModal()"
                >
                    Activate Code
                </button>
                <b-nav-item
                    :to="{ path: '/scans/packs'}"
                    class="scan-packs-history__btn scan-packs-history__btn--link"
                >
                    Buy Packs
                </b-nav-item>
            </div>
        </div>
        <div>
            <div
                v-if="userScanPacks && userScanPacks.rows.length > 0"
                class="scan-packs-history__table"
            >
                <div class="scan-packs-history__table-section">
                    <p class="scan-packs-history__table-item scan-packs-history__table-item--gray">
                        Date
                    </p>
                    <p class="scan-packs-history__table-item scan-packs-history__table-item--gray">
                        Amount
                    </p>
                    <p class="scan-packs-history__table-item scan-packs-history__table-item--gray">
                        Type
                    </p>
                    <p class="scan-packs-history__table-item scan-packs-history__table-item--gray">
                        Status
                    </p>
                    <p class="scan-packs-history__table-item scan-packs-history__table-item--gray">
                        Activation Code
                    </p>
                </div>
                <div
                    v-for="scanPack in userScanPacks.rows"
                    :key="scanPack.id"
                    class="scan-packs-history__table-section"
                >
                    <p class="scan-packs-history__table-item">
                        {{ getTime(scanPack.createdAt) }}
                    </p>
                    <p class="scan-packs-history__table-item scan-packs-history__table-item--number">
                        {{ scanPack.number }} {{ scanPack.number === 0 ? 'scan' : 'scans' }}
                    </p>
                    <p
                        class="scan-packs-history__table-item"
                        :class="getClassPurchaseType(scanPack.purchaseType)"
                    >
                        {{ validatePurchaseType(scanPack.purchaseType) }}
                    </p>
                    <p
                        v-if="!scanPack.activationCodeId"
                        class="scan-packs-history__table-item"
                    >
                        {{ '-' }}
                    </p>
                    <p
                        v-else
                        :class="modifyCouponStatus(scanPack.status)"
                        class="scan-packs-history__table-item"
                    >
                        {{ modifyCouponStatus(scanPack.status) }}
                    </p>
                    <div class="scan-packs-history__table-item">
                        <p
                            v-if="!scanPack.activationCodeId"
                            class="scan-packs-history__table-item"
                        >
                            {{ '-' }}
                        </p>
                        <p
                            v-else-if="scanPack.showCode"
                            :class="`${modifyCouponStatus(scanPack.status)}-code`"
                            class="scan-packs-history__table-item coupon-codes-payment-history__table-item--file-name"
                        >
                            {{ scanPack.activationCode }}
                        </p>
                        <p
                            v-else
                            class="scan-packs-history__table-item coupon-codes-payment-history__table-item--show-code"
                            @click="toggleShowCode(scanPack.activationCodeId)"
                        >
                            Show Code
                        </p>
                    </div>
                </div>
                <Pagination
                    class="scan-packs-history__pagination"
                    :count="userScanPacks.count"
                    :limit="limit"
                    :current-page="currentPage"
                    :show-spinner="showSpinner"
                    @pageChanged="updatePage"
                />
            </div>
            <div v-else>
                <p class="scan-packs-history__table-empty-message">
                    Scan packs have not been purchased yet
                </p>
            </div>
        </div>
        <div
            v-if="showActivationCodeModal"
            class="scan-packs-history__modal-wrapper"
        >
            <div class="scan-packs-history__modal">
                <div class="scan-packs-history__modal-close-btn-wrapper">
                    <div
                        class="scan-packs-history__modal-close-btn"
                        @click="toggleModal()"
                    >
                        <img
                            src="../../assets/images/gray_cross.svg"
                            alt=""
                        >
                    </div>
                </div>
                <p class="scan-packs-history__modal-title">
                    Enter activation code
                </p>
                <input
                    v-model.trim="activationCode"
                    class="scan-packs-history__modal-input"
                    type="text"
                >
                <button
                    class="scan-packs-history__modal-btn"
                    @click="activateCode()"
                >
                    Activate
                </button>
                <div
                    v-if="showModalSpinner"
                    class="scan-packs-history__modal-spinner"
                >
                    <Spinner />
                </div>
            </div>
        </div>
        <div
            v-if="showSpinner"
            class="scan-packs-history__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import {
    convertCentsToDollars,
} from '../../services/functions';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { store } from '../../store';
import { activationCodeNotification, failNotification, successNotification } from '@/services/notificationService/notificationService';
import Pagination from '../Pagination.vue';


export default {
    components: {
        Spinner,
        Pagination,
    },
    data() {
        return {
            selectedScanPackTitle: null,
            showActivationCodeModal: false,
            activationCode: null,
            showSpinner: false,
            showModalSpinner: false,
            currentPage: 1,
            limit: 10,
        };
    },
    computed: {
        ...mapGetters({
            userScanPacks: 'scanPacks/userScanPacks',
            availableScansCount: 'scanPacks/availableScansCount',
            activationCodeError: 'coupons/activationCodeError',
            isSuccessPurchase: 'scanPacks/isSuccessPurchase',
        }),
    },
    watch: {
        async currentPage() {
            this.showSpinner = true;
            await this.$store.dispatch('scanPacks/getUserScanPacks', { page: this.currentPage, limit: this.limit });
            this.showSpinner = false;
        },
        activationCodeError: {
            handler({ message, statusCode }) {
                if (message === 'Invalid code') {
                    this.activationCodeNotification('invalid');
                } else if (message === 'The code has already been redeemed' && statusCode === 503) {
                    this.activationCodeNotification('different');
                } else if (statusCode === 200) {
                    this.activationCodeNotification('success');
                } else if (message === 'Activation code is missing') {
                    //todo add validation to empty input and remove this case
                    this.failNotification();
                }
            },
            deep: true,
        },
    },
    async mounted() {
        this.showSpinner = true;
        if (this.isSuccessPurchase && this.isSuccessPurchase.status
            && this.isSuccessPurchase.paymentType === 'CURRENT_ACCOUNT') {
            this.successNotification('scansCharged');
        }
        await this.$store.dispatch('scanPacks/getUserScanPacks', { page: this.currentPage, limit: this.limit });
        await this.$store.dispatch('scanPacks/getUserAvailableScans');
        this.showSpinner = false;
    },
    methods: {
        async updatePage(page) {
            this.currentPage = page;
        },
        async activateCode() {
            this.showModalSpinner = true;
            await this.$store.dispatch('coupons/activateCodeAction', { activationCode: this.activationCode });
            this.showModalSpinner = false;
            this.showActivationCodeModal = false;
            this.activationCode = null;
        },
        toggleModal() {
            this.showActivationCodeModal = !this.showActivationCodeModal;
        },
        toggleShowCode(scanPackActivationCodeId) {
            this.$store.commit('scanPacks/toggleScanPackCouponShowCode', scanPackActivationCodeId);
        },
        getTime(time) {
            return `${moment(Date.parse(time)).format('MMM Do, y')}`;
        },
        validatePurchaseType(type) {
            if (typeof type === 'string') {
                switch (type) {
                    case 'CURRENT_ACCOUNT':
                        return 'Current Account';
                    case 'ACTIVATION_CODE':
                        return 'Purchase';
                    default:
                        return null;
                }
            } else {
                return null;
            }
        },
        getClassPurchaseType(type) {
            if (typeof type === 'string') {
                switch (type) {
                    case 'CURRENT_ACCOUNT':
                        return 'scan-packs-history__table-item--current-account';
                    case 'ACTIVATION_CODE':
                        return 'scan-packs-history__table-item--activation-code';
                    default:
                        return null;
                }
            } else {
                return null;
            }
        },
        convertCentsToDollars,
        modifyCouponStatus(status) {
            if (status === 'ACTIVE') {
                return 'Active';
            } else if (status === 'DEACTIVATED') {
                return 'Activated';
            }
        },
        activationCodeNotification,
        failNotification,
        successNotification,
    },
};
</script>
<style lang="scss">
.scan-packs-history {
    width: 100%;
    position: relative;
    padding: 10px 0;

    &__spinner {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 20px;
        z-index: 10;
    }

    &__pagination {
        display: flex;
        align-items: center;
        margin: 0 0 0 52px;
        padding: 0;
        height: 90px;
    }

    &__modal-wrapper {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(65, 62, 62, 0.5);
        z-index: 9;
    }

    &__modal {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 230px;
        min-width: 350px;
        padding: 70px 30px;
        background: #FFFFFF;
        border-radius: 20px;
    }

    &__modal-spinner {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 150px;
        border-radius: 20px;

        div.spinner-wrapper {
            padding: 0 !important;
        }
    }

    &__modal-title {
        font-family: Raleway, serif;
        font-weight: bold;
        font-size: 24px;
        color: #242424;
    }

    &__modal-input {
        background: #FFFFFF;
        width: 100%;
        padding: 10px 20px;
        border: 1px solid rgba(31, 168, 199, 0.4);
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Cabin, serif;
        font-size: 14px !important;
        color: #242424;

        &--error,
        &--validation-error,
        &--length-error {
            border: 1px solid #FB8D62;
            border-radius: 5px;
        }

        &__error-text {
            position: absolute;
            top: 0;
            left: 0;
            font-family: Cabin, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 150%;
            color: #FB8D62;
            margin: 0;
        }
    }

    &__modal-close-btn-wrapper {
        position: relative;
        top: -25px;
        left: 130px;
        width: 20px;
        height: 20px;
        z-index: 1000;
    }

    &__modal-close-btn {
        position: absolute;
        cursor: pointer;
    }

    &__modal-btn {
        max-width: 150px;
        width: 100%;
        background: #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        font-weight: bold !important;
        color: #FFFFFF !important;
        transition: 0.3s;
        font-size: 24px;
        margin-top: 20px;
        padding: 5px 0;

        &:hover {
            opacity: 0.7;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
    }

    &__box {
        display: flex;
    }

    &__subtitle {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #242424;
        margin: 0 0 25px 0;
    }

    &__title {
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        color: #242424;
        margin: 0;
    }

    &__btn {
        background: #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
        height: 50px;
        min-width: 185px;
        transition: 0.3s;

        &--white {
            background-color: transparent;
            color: #FFB400;
            margin-right: 30px;
        }

        &--link {
            a.nav-link {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                color: white;
            }
        }

        &:hover {
            opacity: 0.5;
        }
    }

    &__table-empty-message {
        text-align: center;
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        color: #242424;
        margin: 0;
    }

    &__table {
        background: #FFFFFF;
        border-radius: 20px;
    }

    &__table-section {
        display: flex;
    }

    &__table-item {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin: 0;
        width: 20%;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #706969;
        height: 55px;

        &--gray {
            color: #B2B9C4;
        }

        &--current-account {
            color: #1FA8C7;
        }

        &--activation-code {
            color: rgb(97, 212, 179);
        }

        &--number {
            font-size: 18px;
            font-weight: bold;
        }
    }
    .Active {
        color: #52d492;
    }

    .Activated {
        color: #FB8D62;
    }
}
.coupon-codes-payment-history {

    &__table-item {

        &--file-name {
            font-size: 16px;
            color: #1FA8C7;
            font-weight: bold;
            text-align: center;
            animation-name: animateCode;
            animation-duration: 0.2s;
            background-color: transparent;
        }

        &--show-code {
            cursor: pointer;
            text-align: center;
            width: 100%;
        }
    }
    .Activated-code {
        opacity: 0.5;
    }
}

@keyframes animateCode {
    0% {
        transform: translateX(-30px);
    }

    100% {
        background-color: transparent;
        transform: translateX(0px);
    }
}

@media (max-width: 575px) {
    .scan-packs-history {
        padding: 10px 10px;

        &__header {
            flex-direction: column;
            align-items: flex-start;
        }

        &__box {
            flex-direction: column;
        }

        &__title {
            font-size: 26px;
        }

        &__btn {
            &--white {
                margin-right: 0;
            }

            &--link {
                a.nav-link {
                    font-weight: bold;
                }
            }
        }

        &__pagination {
            justify-content: space-between;
        }
    }
}
</style>
