<template>
    <ScanPacks />
</template>
<script>
import ScanPacks from '../components/scanPacks/ScanPacks'
export default {
    components: {
        ScanPacks
    }
}
</script>
<style>

</style>
