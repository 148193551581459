import Vue from 'vue';

export const state = () => ({
    isFolderSettingsOpen: false,
    movedToFolder: null,
    movedFromFolder: null,
    isMovingToFolder: false,
});

export const mutations = {
    setFolderSettings(state, value) {
        state.isFolderSettingsOpen = value;
    },
    setMovedToFolder(state, value) {
        state.movedToFolder = value;
    },
    setMovedFromFolder(state, value) {
        state.movedFromFolder = value;
    },
    setMovingToFolder(state, value) {
        state.isMovingToFolder = value;
    },
};

export const actions = {
    async moveVulnerabilityToFolder({ commit }, { integrationId, versionId, jobId, vulnerabilityId, holderId, folderName }) {
        const body = {
            integrationId,
            versionId,
            jobId,
            vulnerabilityId,
            holderId,
            folderName,
        };
        try {
            return await Vue.axios.post('/private/ui/folders_data/update', body);
        } catch (error) {
            console.log(error);
        }
    },
    async moveAllVulnerabilityToFolder({ commit }, body) {
        try {
            return await Vue.axios.post('/private/ui/folders_data/move_all', body);
        } catch (error) {
            console.log(error);
        }
    },
    async getNumberOfMovingVulnerabilities({ commit }, body) {
        try {
            return await Vue.axios.post('/private/ui/folders_data/move_count', body);
        } catch (error) {
            console.log(error);
        }
    },
};

export const getters = {
    isFolderSettingsOpen: state => state.isFolderSettingsOpen,
    movedToFolder: state => state.movedToFolder,
    movedFromFolder: state => state.movedFromFolder,
    isMovingToFolder: state => state.isMovingToFolder,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
