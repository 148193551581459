import Vue from 'vue'
import axios from "axios";

let BASE_URL;

if (process.env.NODE_ENV === 'test') {
    BASE_URL = 'http://localhost:4000';
} else {
    BASE_URL = process.env.VUE_APP_BLOG_BASE_URL;
}
const axiosInstance = axios.create({
    baseURL: BASE_URL, 
    headers: {
      'Content-Type': 'application/json',
    },
  });


export const state = () => ({
    posts: [],
})

export const mutations = {
    setPosts: (state, response) => {
        state.posts = response.map(post => ({
            ...post,
            cover: `${BASE_URL}/${post.cover}`,
            url: `${BASE_URL}${post.url}`
        }));
    },
}


export const actions = {
    async getPosts({commit}) {
        console.log(process.env.NODE_ENV)
        try {
            const response = await axiosInstance.get(`latest_article.json`);
            commit('setPosts', response.data.posts);
        } catch (e) {
            console.log(e);
        }
    },
}

export const getters = {
    posts: s => s.posts,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
