import Vue from 'vue';
import { setNotFoundJobData } from '../utils/helper-functions';
import { convertErrorMessageForUI, converFolderErrorMessagesForUI, transformVulnerabilitiesIds } from '../services/functions';
import { FolderNameEnum } from '../utils/constants/common';

export const state = () => ({
    jobs: [],
    jobsHistory: null,
    payedJobsHistory: null,
    currentJob: null,
    socket: null,
    currentJobFalsePositives: [],
    amountVulnerabilitiesFalsePosition: null,
    currentJobFalsePositivesStatistics: null,
    timeout: null,
    countJobs: null,
    countJobsHistory: null,
    vulnerabilityPage: [],
    selectedCategory: null,
    selectedVulnerabilitiesIds: {},
    needToFetchJobs: false,
    isSuccessUnlock: null,
    folders: [],
    preventPageLoadDuplicate: false,
    pendingOperations: 0,
    errors: {},
});

export const mutations = {
    setSocket: (state, socket) => {
        state.socket = socket;
    },
    setJobs(state, jobs) {
        state.jobs = jobs;
    },
    setCountJobs(state, count) {
        state.countJobs = count;
    },
    setNeedToFetchJobs(state, value) {
        state.needToFetchJobs = value;
    },
    setCountJobsHistory(state, count) {
        state.countJobsHistory = count;
    },

    updateJob(state, updatedJob) {
        const jobIndex = state.jobs.findIndex(job => job.id === updatedJob.id);
        if (jobIndex !== -1) {
            state.needToFetchJobs = true;
        }
    },
    setCurrentJob(state, currentJob) {
        if (currentJob) {
            if (currentJob.isJobFound && currentJob.data) {
                state.currentJob = currentJob;
                const jobIndex = state.jobs.findIndex(job => job.id === currentJob.data.id);
                if (jobIndex === -1) {
                    state.jobs.push(currentJob.data);
                } else {
                    Vue.set(state.jobs, jobIndex, currentJob.data);
                }
            } else {
                state.currentJob = currentJob;
            }
        } else {
            state.currentJob = currentJob;
        }
    },
    startOperation(state) {
        state.pendingOperations++;
    },
    endOperation(state) {
        if (state.pendingOperations > 0) {
            state.pendingOperations--;
        }
    },
    clearCurrentJob(state) {
        state.currentJob = null;
        state.currentJobFalsePositives = [];
    },
    clearListJobs(state) {
        state.jobs = [];
    },
    setVulnerabilityPage(state, page) {
        state.vulnerabilityPage = page;
    },
    setSelectedCategory(state, category) {
        state.selectedCategory = category;
    },
    clearSelectedCategory(state) {
        state.selectedCategory = null;
    },
    setSelectedVulnerabilityCategoriesIds(state, { id, vulnerabilities }) {
        state.selectedVulnerabilitiesIds[id] = vulnerabilities;
    },
    updateVulnerabilityCategoriesIds(state, { id, vulnerabilities }) {
        Vue.set(state.selectedVulnerabilitiesIds, id, vulnerabilities);
    },
    deleteVulnerabilityCategoryIdAfterMoving(state, { id, categoryId, deductNumber }) {
        if (!state.selectedVulnerabilitiesIds[id]) {
            return;
        }
        const category = state.selectedVulnerabilitiesIds[id].find(
            category => category.categoryId === categoryId,
        );

        if (category) {
            if (parseInt(category.count) > 1) {
                // Decrease count by 1 if greater than 1
                category.count = (parseInt(category.count) - deductNumber).toString();

                if (category.count === '0') {
                    // Delete the category object if the count is 0
                    const index = state.selectedVulnerabilitiesIds[id].indexOf(category);
                    if (index !== -1) {
                        Vue.delete(state.selectedVulnerabilitiesIds, id);
                    }
                }
            } else {
                // Delete the category object if the count is 1
                const index = state.selectedVulnerabilitiesIds[id].indexOf(category);
                if (index !== -1) {
                    Vue.delete(state.selectedVulnerabilitiesIds[id], index);
                }
            }
        }
    },
    deleteFilterVulnerability(state, { id }) {
        Vue.delete(state.selectedVulnerabilitiesIds, id);
    },
    setPreventPageLoadDuplication(state, value) {
        state.preventPageLoadDuplicate = value;
    },
    setJobsHistory(state, history) {
        state.jobsHistory = history;
    },
    setPaidJobsHistory(state, history) {
        state.payedJobsHistory = history;
    },
    setUnlockSuccessStatus: (state, status) => {
        state.isSuccessUnlock = status;
    },
    setFolders(state, folders) {
        state.folders = folders;
    },
    setErrors: (state, response) => {
        state.errors = {
            [response.key]: response.body,
        };
    },
    clearErrors: (state) => {
        state.errors = {};
    },
};

export const actions = {
    async fetchJobs({commit}, page) {
        try {
            const {data} = await Vue.axios.get('/private/ui/jobs', {params: {page: page}});
            commit('setJobs', data.rows);
            commit('setCountJobs', data.count);
            commit('setNeedToFetchJobs', false);
        } catch (e) {
            console.log(e);
        }
    },
    async fetchJobById({commit, getters}, {jobId, folderName, needUpdate}) {
        const currentJob = getters.jobs.find(job => job.id === jobId);
        let job = {};
        if (!needUpdate && currentJob && currentJob.report) {
            commit('setCurrentJob', currentJob);
        } else {
            let response = null;
            try {
                response = await Vue.axios.get(`/private/ui/jobs/${jobId}?folderName=${folderName}`);
                job.data = response.data;
                job.isJobFound = true;
                commit('setCurrentJob', job);
            } catch (error) {
                job = setNotFoundJobData(error);
                await commit('setCurrentJob', job);
            }
        }
    },
    // Getting Page with vulnerabilities
    async chooseAppropriatePageAction({ dispatch }, payload) {
        const { integrationId } = payload;

        const actionToDispatch = integrationId
            ? 'findAndLoadVersionVulnerabilityPage'
            : 'findAndLoadVulnerabilityPage';

        await dispatch(actionToDispatch, payload);
    },

    // For Single Scan
    async findAndLoadVulnerabilityPage({ dispatch, getters }, request) {
        const selectedVulnerabilitiesIds = request.categories ? request.categories : getters.selectedVulnerabilitiesIds;
        const transformedVulnerabilitiesIds = transformVulnerabilitiesIds(selectedVulnerabilitiesIds);
        const url = `/private/ui/jobs/${request.id}/page`;
        const body = {
            ...request,
            filters: {
                vulnerabilityCategoriesIds: transformedVulnerabilitiesIds[request.id],
            },
        };
        await dispatch('handlePageRequest', { url, body });
    },

    // For Integration Version
    async findAndLoadVersionVulnerabilityPage({ dispatch, getters }, request) {
        const selectedVulnerabilitiesIds = request.categories ? request.categories : getters.selectedVulnerabilitiesIds;
        const transformedVulnerabilitiesIds = transformVulnerabilitiesIds(selectedVulnerabilitiesIds);
        const url = `/private/ui/integrations/${request.integrationId}/branches/${request.branchName}/versions/${request.id}/page`;
        const body = {
            ...request,
            filters: {
                vulnerabilityCategoriesIds: transformedVulnerabilitiesIds[request.id],
            },
        };
        await dispatch('handlePageRequest', { url, body });
    },

    async handlePageRequest({ commit }, { url, body }) {
        try {
            const response = await Vue.axios.post(url, body);
            commit('setVulnerabilityPage', response.data);
            if (body.selectedCategoryType) commit('setSelectedCategory', body.selectedCategoryType);

            return response;
        } catch (error) {
            console.log(error);
            let job = setNotFoundJobData(error);
            await commit('setCurrentJob', job);
            return null;
        }
    },

    async deleteJob({commit, dispatch}, {id, page}) {
        let pageCheck = page ? page : 1;
        try {
            let result = await Vue.axios.delete(`/private/ui/jobs/${id}`);
            dispatch('fetchJobs', pageCheck);
            return result;
        } catch (error) {
            commit('setErrors', convertErrorMessageForUI(error.response.data.message));
            return error;
        }
    },
    async changeSharedReport({dispatch}, data) {
        try {
            await Vue.axios.put(`/private/ui/jobs/${data.id}`, {shared: data.shared});
            await dispatch('fetchJobById', {jobId: data.id, folderName: data.folderName});
        } catch (err) {
            console.log(err);
        }
    },
    async unlockTrialReportWithPay({commit}, {jobId, cardId, cardInfo}) {
        try {
            return await Vue.axios.post(`/private/ui/jobs/${jobId}/pay/unlock-trial-report`, {
                cardId,
                cardInfo,
            });
        } catch (error) {
            console.error(error);
            let message = 'Something went wrong';
            commit('setUnlockSuccessStatus', {status: false, message});
            throw new Error(error);
        }
    },
    async unlockTrialReportWithPrepaid({commit}, {jobId}) {
        try {
            return await Vue.axios.post(`/private/ui/jobs/${jobId}/prepaid/unlock-trial-report`);
        } catch (error) {
            console.error(error);
            let message = 'Something went wrong';
            commit('setUnlockSuccessStatus', {status: false, message});
            throw new Error(error);
        }
    },
    async loadJobsHistory({commit}, currentPage) {
        let page = currentPage ? currentPage : 1;
        const {data} = await Vue.axios.get('/private/ui/jobs/history', {params: {page: page}});
        commit('setJobsHistory', data.rows);
        commit('setCountJobsHistory', data.count);
    },
    async loadPayedJobsHistory({commit}, currentPage) {
        let page = currentPage ? currentPage : 1;
        const {data} = await Vue.axios.get('/private/ui/jobs/paid/history', {params: {page: page}});
        commit('setPaidJobsHistory', data);
    },
    /**
     * Folder Actions
     */
    async getFolders({commit}, {jobId}) {
        try {
            const {data} = await Vue.axios.get(`/private/ui/folders?jobId=${jobId}`);
            commit('setFolders', data);
        } catch (err) {
            console.log(err);
        }
    },
    async createNewFolder({commit}, {jobId, name}) {
        try {
            return await Vue.axios.post(`/private/ui/folders?jobId=${jobId}`, {name});
        } catch (error) {
            commit('setErrors', converFolderErrorMessagesForUI(error.response.data));
            return error;
        }
    },
    async deleteFolder({ commit }, { jobId, folderId }) {
        try {
            return await Vue.axios.delete(`/private/ui/folders/${folderId}?jobId=${jobId}`);
        } catch (error) {
            return error;
        }
    },
    async updateFolderName({ commit }, { jobId, folderId, name }) {
        try {
            return await Vue.axios.put(`/private/ui/folders/${folderId}?jobId=${jobId}`, { name });
        } catch (error) {
            commit('setErrors', converFolderErrorMessagesForUI(error.response.data));
            return error;
        }
    },
};

export const getters = {
    socket: s => s.socket,
    jobs: s => s.jobs,
    countJobs: s => s.countJobs,
    jobsHistory: s => s.jobsHistory,
    currentJob: s => s.currentJob,
    countJobsHistory: s => s.countJobsHistory,
    currentJobFalsePositives: s => s.currentJobFalsePositives,
    isSuccessUnlock: s => s.isSuccessUnlock,
    payedJobsHistory: (s) => s.payedJobsHistory,
    vulnerabilityPage: (s) => s.vulnerabilityPage,
    selectedCategory: (s) => s.selectedCategory,
    selectedVulnerabilityFilters: (s) => s.selectedVulnerabilityFilters,
    currentJobFalsePositivesStatistics: (s) => s.currentJobFalsePositivesStatistics,
    amountVulnerabilitiesFalsePosition: (s) => s.amountVulnerabilitiesFalsePosition,
    preventPageLoadDuplicate: (s) => s.preventPageLoadDuplicate,
    selectedVulnerabilitiesIds: (s) => s.selectedVulnerabilitiesIds,
    needToFetchJobs: (s) => s.needToFetchJobs,
    isLoading: s => s.pendingOperations > 0,
    folders: ({ folders }) => {
        /**
         * move false positives folder to the beginning
         * false positives must be the first folder in list
         * we check if false positives exist for shared report
         */
        const isFalsePositive = folders.findIndex(folder => folder.name === FolderNameEnum.FALSE_POSITIVES);
        isFalsePositive !== -1 && folders.unshift(folders.splice(isFalsePositive, 1)[0]);
        return folders;
    },
    errors: s => s.errors,
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
