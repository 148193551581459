<template>
    <b-nav-item v-if="isNotification && isUserAuth" :to="{ path: '/settings/security'}" class="mfa-notify">
        <p class="mfa-notify__text">You don't have MFA assigned. </p>
        <div class="mfa-notify__wrapper">
            <p class="mfa-notify__text mfa-notify__text--orange">
                As a security best practice, we recommend you assign MFA.
            </p>
        </div>
        <button class="mfa-notify__btn-close" @click.stop.prevent="toggleNotification()">
            <img class="mfa-notify__btn-close-img" src="../assets/images/trial-cross.png" alt="trial-cross"/>
        </button>
    </b-nav-item>
</template>
<script>

export default {
    props: {
        isUserAuth: Boolean,
    },
    data() {
        return {
            isNotification: true,
        };
    },
    methods: {
        toggleNotification() {
            this.isNotification = !this.isNotification;
            this.$emit('toggleNotification', false);
        }
    },
}
</script>
<style lang="scss" scoped>
.mfa-notify {
    min-height: 46px;
    height: 100%;
    background-color: #1FA8C7;
    display: flex;
    justify-content: center;
    align-items: center;

    a.nav-link {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 14px;
    }

    &__text {
        display: flex;
        align-items: center;
        margin: 0 3px 0 0;
        font-family: Cabin, serif;
        font-size: 14px;
        line-height: 140%;
        color: #FFFFFF;

        &--orange {
            color: #FFB400;
            font-weight: bold;
        }
    }

    &__btn-close {
        position: absolute;
        top: 9px;
        right: 18px;
        background-color: transparent;
        z-index: 10000;
    }

    &__btn-close-img {
        height: 10px;
        width: 10px;
        background-color: transparent;
    }

    &__wrapper {
        display: flex;
    }
}


@media (max-width: 450px) {
    .mfa-notify {
        justify-content: flex-start;
        align-items: center;
        min-height: 51px;

        &__wrapper {
            display: flex;
        }

        a.nav-link {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 6px 0 6px 20px;
        }
    }
}
</style>
