<template>
    <div class="profile-info">
        <form id="profile-info-form" autocomplete="off">
            <div class="profile-info__form">
                <div class="profile-info__box">
                    <div class="profile-info__form-input-wrapper">
                        <input
                            v-model.trim="profileInfoForm.companyName"
                            id="companyName"
                            name="companyName"
                            class="input"
                            type="text"
                            autocomplete="off"
                            @blur="activeInputName = null"
                            @input="handleInput"
                            @change="handleInput"
                            @keyup.delete="handleInput"

                        >
                        <img v-if="profileInfoForm.companyName.length > 0"
                            @click="clearInputAndErrors('companyName')"
                            class="profile-info__form-input-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                        >
                        <label
                            for="companyName"
                            class="label"
                            v-bind:class="{ activeInput: profileInfoForm.companyName !== isEmptyInput || activeInputName === 'companyName'}"
                        >
                            Company Name
                        </label>
                        <span id="companyNameError" class="profile-info__form-error">
                            <img
                                @click="clearInputAndErrors('companyName')"
                                class="profile-info__form-error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                            >
                            <span class="profile-info__form-error-text">Required field</span>
                        </span>
                        <div id="companyNameLengthError" class="profile-info__form-error">
                            <img
                                @click="clearInputAndErrors('companyName')"
                                class="profile-info__form-error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="profile-info__form-error-text">Max input length 1000</p>
                        </div>
                    </div>
                    <div class="profile-info__form-input-wrapper">
                        <input
                            v-model.trim="profileInfoForm.website"
                            id="website"
                            class="input"
                            type="text" autocomplete="off"
                            @blur="activeInputName = null"
                            @input="handleInput"
                            @change="handleInput"
                            @keyup.delete="handleInput"
                        >
                        <img v-if="profileInfoForm.website.length > 0"
                            @click="clearInputAndErrors('website')"
                            class="profile-info__form-input-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                        >
                        <label for="website" class="label" v-bind:class="{ activeInput: profileInfoForm.website !== isEmptyInput || activeInputName === 'website'}">Website</label>
                        <div id="websiteError" class="profile-info__form-error">
                            <img
                                @click="clearInputAndErrors('website')"
                                class="profile-info__form-error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="profile-info__form-error-text">Required field</p>
                        </div><div id="websiteLengthError" class="profile-info__form-error">
                            <img
                                @click="clearInputAndErrors('website')"
                                class="profile-info__form-error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="profile-info__form-error-text">Max input length 1000</p>
                        </div>
                    </div>
                </div>
                <div class="profile-info__box">
                    <div class="profile-info__form-input-wrapper">
                        <input
                            v-model.trim="profileInfoForm.address1"
                            id="address1"
                            class="input"
                            type="text"
                            autocomplete="off"
                            @blur="activeInputName = null"
                            @input="handleInput"
                            @change="handleInput"
                            @keyup.delete="handleInput"
                        >
                        <img v-if="profileInfoForm.address1.length > 0"
                            @click="clearInputAndErrors('address1')"
                            class="profile-info__form-input-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                        >
                        <label for="address1" class="label" v-bind:class="{ activeInput: profileInfoForm.address1 !== isEmptyInput || activeInputName === 'address1'}">Address 1</label>
                        <div id="address1Error" class="profile-info__form-error">
                            <img
                                @click="clearInputAndErrors('address1')"
                                class="profile-info__form-error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="profile-info__form-error-text">Required field</p>
                        </div>
                        <div id="address1LengthError" class="profile-info__form-error">
                            <img
                                @click="clearInputAndErrors('address1')"
                                class="profile-info__form-error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="profile-info__form-error-text">Max input length 1000</p>
                        </div>
                    </div>
                    <div class="profile-info__form-input-wrapper">
                        <input
                            v-model="address2"
                            id="address2"
                            class="input"
                            type="text"
                            autocomplete="off"
                            @blur="activeInputName = null"
                            @input="handleInput"
                            @change="handleInput"
                            @keyup.delete="handleInput"
                        >
                        <img v-if="address2.length > 0"
                            @click="clearInputAndErrors('address2')"
                            class="profile-info__form-input-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                        >
                        <label for="address2" class="label" v-bind:class="{ activeInput: address2 !== isEmptyInput || activeInputName === 'address2'}">Address 2</label>
                    </div>
                    <div class="profile-info__box--small">
                        <div class="profile-info__form-input-wrapper profile-info__form-input-wrapper--small">
                            <input
                                v-model.trim="profileInfoForm.country"
                                id="country"
                                class="input input--small"
                                type="text"
                                autocomplete="off"
                                @blur="activeInputName = null"
                                @input="handleInput"
                                @change="handleInput"
                                @keyup.delete="handleInput"
                            >
                            <img v-if="profileInfoForm.country.length > 0"
                                @click="clearInputAndErrors('country')"
                                class="profile-info__form-input-check"
                                src="../../assets/images/cross_grey.svg"
                                alt="cross"
                            >
                            <label for="country" class="label" v-bind:class="{ activeInput: profileInfoForm.country !== isEmptyInput || activeInputName === 'country'}">Country</label>
                            <div id="countryError" class="profile-info__form-error">
                                <img
                                    @click="clearInputAndErrors('country')"
                                    class="profile-info__form-error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                >
                                <p class="profile-info__form-error-text">Required field</p>
                            </div>
                            <div id="countryLengthError" class="profile-info__form-error">
                                <img
                                    @click="clearInputAndErrors('country')"
                                    class="profile-info__form-error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                >
                                <p class="profile-info__form-error-text">Max input length 1000</p>
                            </div>
                        </div>
                        <div class="profile-info__form-input-wrapper profile-info__form-input-wrapper--small">
                            <input
                                v-model.trim="profileInfoForm.state"
                                id="state"
                                class="input input--small"
                                type="text"
                                autocomplete="off"
                                @blur="activeInputName = null"
                                @input="handleInput"
                                @change="handleInput"
                                @keyup.delete="handleInput"
                            >
                            <img v-if="profileInfoForm.state.length > 0"
                                @click="clearInputAndErrors('state')"
                                class="profile-info__form-input-check"
                                src="../../assets/images/cross_grey.svg"
                                alt="cross"
                            >
                            <label for="state" class="label" v-bind:class="{ activeInput: profileInfoForm.state !== isEmptyInput || activeInputName === 'state'}">State</label>
                            <div id="stateError" class="profile-info__form-error">
                                <img
                                    @click="clearInputAndErrors('state')"
                                    class="profile-info__form-error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                >
                                <p class="profile-info__form-error-text">Required field</p>
                            </div>
                            <div id="stateLengthError" class="profile-info__form-error">
                                <img
                                    @click="clearInputAndErrors('state')"
                                    class="profile-info__form-error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                >
                                <p class="profile-info__form-error-text">Max input length 1000</p>
                            </div>
                        </div>
                    </div>
                    <div class="profile-info__box--small">
                        <div class="profile-info__form-input-wrapper profile-info__form-input-wrapper--small">
                            <input
                                v-model.trim="profileInfoForm.city"
                                id="city"
                                class="input input--small"
                                type="text"
                                autocomplete="off"
                                @blur="activeInputName = null"
                                @input="handleInput"
                                @change="handleInput"
                                @keyup.delete="handleInput"
                            >
                            <img v-if="profileInfoForm.city.length > 0"
                                @click="clearInputAndErrors('city')"
                                class="profile-info__form-input-check"
                                src="../../assets/images/cross_grey.svg"
                                alt="cross"
                            >
                            <label for="city" class="label" v-bind:class="{ activeInput: profileInfoForm.city !== isEmptyInput || activeInputName === 'city'}">City</label>
                            <div id="cityError" class="profile-info__form-error">
                                <img
                                    @click="clearInputAndErrors('city')"
                                    class="profile-info__form-error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                >
                                <p class="profile-info__form-error-text">Required field</p>
                            </div>
                            <div id="cityLengthError" class="profile-info__form-error">
                                <img
                                    @click="clearInputAndErrors('city')"
                                    class="profile-info__form-error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                >
                                <p class="profile-info__form-error-text">Max input length 1000</p>
                            </div>
                        </div>
                        <div class="profile-info__form-input-wrapper profile-info__form-input-wrapper--small">
                            <input
                                v-model.trim="profileInfoForm.zipcode"
                                class="input input--small"
                                type="text"
                                autocomplete="off"
                                id="zipcode"
                                @blur="activeInputName = null"
                                @input="handleInput"
                                @change="handleInput"
                                @keyup.delete="handleInput"
                            >
                            <img v-if="profileInfoForm.zipcode.length > 0"
                                @click="clearInputAndErrors('zipcode')"
                                class="profile-info__form-input-check"
                                src="../../assets/images/cross_grey.svg"
                                alt="cross"
                            >
                            <label for="zipcode" class="label" v-bind:class="{ activeInput: profileInfoForm.zipcode !== isEmptyInput || activeInputName === 'zipcode'}">ZIP Code</label>
                            <div id="zipcodeError" class="profile-info__form-error">
                                <img
                                    @click="clearInputAndErrors('zipcode')"
                                    class="profile-info__form-error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                >
                                <p class="profile-info__form-error-text">Required field</p>
                            </div>
                            <div id="zipcodeLengthError" class="profile-info__form-error">
                                <img
                                    @click="clearInputAndErrors('zipcode')"
                                    class="profile-info__form-error-cross"
                                    src="../../assets/images/cross.svg"
                                    alt="cross"
                                >
                                <p class="profile-info__form-error-text">Max input length 1000</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="profile-info__btn-submit-wrapper">
                <button v-if="userType === 'hacker'" class="profile-info__btn-submit cabin cabin-bold" type="submit"
                        @click="upgradeToCompany">Upgrade
                </button>
                <button v-else class="profile-info__btn-submit cabin cabin-bold" type="submit" @click="updateProfileData">
                    Save
                </button>
            </div>
            <div v-if="isDataSaving || isLoadingData" class="profile-info__spinner">
                <Spinner/>
            </div>
        </form>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import Spinner from '../Spinner';
import {store} from "@/store";
import {
    checkFormInputsForCompleteness,
    checkFormInputsForErrors,
    checkFormInputsLength,
    validateString,
    hideErrors
} from '@/services/functions';
import {successNotification, failNotification} from '@/services/notificationService/notificationService';

export default {
    components: {
        Spinner,
    },
    data() {
        return {
            profileInfoForm: {
                companyName: "",
                website: "",
                address1: "",
                country: "",
                city: "",
                zipcode: "",
                state: "",
            },
            address2: "",
            isDataSaving: false,
            isDataLoading: true,
            isEmptyInput: "",
            activeInputName: null
        }
    },
    async beforeMount() {
        if (!document.location.pathname.includes('upgrade')) await this.$store.dispatch('user/getProfileInfo')
    },
    computed: {
        ...mapGetters({
            profileInfo: 'user/profileInfo',
            isLoadingData: 'user/isLoadingData',
            userType: 'user/userType'
        })
    },
    watch: {
        profileInfo() {
            this.isDataLoading = false
            if(this.profileInfo){
                this.profileInfoForm.companyName = validateString(this.profileInfo.companyName)
                this.profileInfoForm.website = validateString(this.profileInfo.website)
                this.profileInfoForm.address1 = validateString(this.profileInfo.address1)
                this.profileInfoForm.country = validateString(this.profileInfo.country)
                this.profileInfoForm.city = validateString(this.profileInfo.city)
                this.profileInfoForm.zipcode = validateString(this.profileInfo.zipcode)
                this.profileInfoForm.state = validateString(this.profileInfo.state)
                this.address2 = validateString(this.profileInfo.address2)
            }
        },
    },
    methods: {
        clearInputAndErrors(inputField) {
            if (inputField === 'companyName') {
                this.profileInfoForm.companyName = ''
                hideErrors(['companyNameError', 'companyNameLengthError'])
            } else if (inputField === 'website') {
                this.profileInfoForm.website = ''
                hideErrors(['websiteError', 'websiteLengthError'])
            } else if (inputField === 'address1') {
                this.profileInfoForm.address1 = ''
                hideErrors(['address1Error', 'address1LengthError'])
            } else if (inputField === 'address2') {
                this.address2 = ''
            } else if (inputField === 'country') {
                this.profileInfoForm.country = ''
                hideErrors(['countryError', 'countryLengthError'])
            } else if (inputField === 'city') {
                this.profileInfoForm.city = ''
                hideErrors(['cityError', 'cityLengthError'])
            } else if (inputField === 'zipcode') {
                this.profileInfoForm.zipcode = ''
                hideErrors(['zipcodeError', 'zipcodeLengthError'])
            } else if (inputField === 'state') {
                this.profileInfoForm.state = ''
                hideErrors(['stateError', 'stateLengthError'])
            }
            document.getElementById(inputField).style.border = '1px solid #F0F0F0'
        },
        async updateProfileData(event) {
            event.preventDefault()

            store.commit('authentication/clearErrors')

            const errorIds = ['companyNameError', 'websiteError', 'address1Error', 'countryError', 'cityError', 'zipcodeError',
            'stateError', 'companyNameLengthError', 'websiteLengthError', 'address1LengthError', 'countryLengthError', 'cityLengthError',
            'zipcodeLengthError', 'stateLengthError']
            hideErrors(errorIds)

            checkFormInputsForErrors(this.profileInfoForm, 'profile-info__input--error')
            checkFormInputsLength(this.profileInfoForm, 'profile-info__input--length-error')

            if (checkFormInputsForCompleteness(this.profileInfoForm)) {
                this.isDataSaving = true

                try {
                    this.showSpinner = true
                    await this.updateProfileInfo()
                    this.isDataSaving = false
                    this.showSpinner = false
                    this.successNotification('companyInfoUpdated')
                } catch (e) {
                    this.isDataSaving = false
                    this.failNotification()
                }
            }
        },
        async upgradeToCompany(event) {
            event.preventDefault()

            checkFormInputsForErrors(this.profileInfoForm, 'profile-info__input--error')
            checkFormInputsLength(this.profileInfoForm, 'profile-info__input--length-error')

            if (checkFormInputsForCompleteness(this.profileInfoForm)) {
                this.isDataSaving = true
                try {
                    await this.sendProfileData()
                    this.isDataSaving = false
                    await this.$router.push({
                        name: 'Integrations',
                        params: {showSuccess: true}
                    })
                    await this.$store.dispatch('user/fetchUserInfo')
                } catch (e) {
                    this.isDataSaving = false
                }
            }
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id
        },
        handleInput(event) {
            this.checkKeyEvent(event)

            let inputField = event.target.id
            hideErrors([`${inputField}Error`, `${inputField}LengthError`])
            document.getElementById(`${inputField}`).style.border = '1px solid #F0F0F0'
            store.commit('authentication/clearErrors')
        },
        async sendProfileData() {
            await this.$store.dispatch('user/saveProfileInfo', {
                companyName: this.profileInfoForm.companyName,
                website: this.profileInfoForm.website,
                address1: this.profileInfoForm.address1,
                address2: this.address2,
                country: this.profileInfoForm.country,
                city: this.profileInfoForm.city,
                zipcode: this.profileInfoForm.zipcode,
                state: this.profileInfoForm.state,
            })
        },
        async updateProfileInfo() {
            await this.$store.dispatch('user/updateProfileInfo', {
                companyName: this.profileInfoForm.companyName,
                website: this.profileInfoForm.website,
                address1: this.profileInfoForm.address1,
                address2: this.address2,
                country: this.profileInfoForm.country,
                city: this.profileInfoForm.city,
                zipcode: this.profileInfoForm.zipcode,
                businessType: this.profileInfoForm.businessType,
                state: this.profileInfoForm.state,
            })
        },
        successNotification,
        failNotification
    }
}
</script>
<style lang="scss">
.profile-info {
    margin-bottom: 65px;
    position: relative;
    width: 100%;

    &__btn-submit-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
    }

    &__btn-submit {
        font-size: 24px;
        color: #FFFFFF;
        background: #FFB400;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px 40px;
        transition: 0.3s;

        &:hover {
            opacity: 0.7;
        }

        &:active {
            opacity: 1;
        }
    }

    &__sign {
        font-size: 18px;
        line-height: 150%;
        color: #242424;
        margin: -5px 0 20px 0;
    }

    &__input {

        &--error,
        &--length-error {
            border: 1px solid #FB8D62;
            border-radius: 5px !important;
        }
    }

    &__box {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: 10px;

        &--small {
            flex-direction: row;
        }
    }

    &__form {
        display: flex;
        position: relative;
        width: 100%;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 40px;
    }

    &__form-input-wrapper {
        position: relative;
        width: 100%;
        max-width: 476px;

        &--small {
            display: inline-block;
            max-width: 228px;

            &:nth-child(2) {
                margin-left: 0;
            }
        }
    }

    &__form-error-selcet {
        top: -28px !important;
    }

    &__form-input-check {
        position: absolute;
        bottom: 45px;
        right: 20px;
        cursor: pointer;
    }

    &__form-error {
        width: 100%;
        position: relative;
        top: -20px;
    }

    &__form-error-cross {
        position: absolute;
        top: -38px;
        right: 20px;
        cursor: pointer;
    }

    &__form-error-text {
        position: absolute;
        top: -9px;
        left: 0;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 150%;
        color: #FB8D62;
        margin: 0;
    }
}
.alert-message {
    position: absolute;
}
@media (max-width:1200px) {

    .profile-info__input--small {
        max-width: 476px;
    }
    .profile-info__form-input-wrapper--small {
        display: inline-block;
        max-width: 476px;
    }
}

.profile-info__spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.7);
}

#companyNameError,
#websiteError,
#address1Error,
#countryError,
#stateError,
#cityError,
#zipcodeError,
#companyNameLengthError,
#websiteLengthError,
#address1LengthError,
#countryLengthError,
#stateLengthError,
#cityLengthError,
#zipcodeLengthError {
    display: none;
}


@media (max-width: 768px) {
    .profile-info {
        width: 100%;

        &__btn-submit-wrapper {
            margin-top: 20px;
            margin-right: 20px;
        }

        &__btn-submit {
            font-size: 20px;
            padding: 10px 20px;
        }

        &__sign {
            margin-left: 20px;
        }

        &__form {
            flex-direction: column;
        }

        &__input {
            &--small {
                max-width: 476px;
            }

            &--small:nth-child(1) {
                margin-right: 0;
            }
        }

        &__form-input-wrapper {
            width: 100%;

            &--small {
                display: inline-block;
                max-width: fit-content;

                &:nth-child(2) {
                    margin-left: 0;
                }
            }
        }
    }
}
</style>
