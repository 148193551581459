<template>
    <div class="main-partner">
        <div class="main-partner__container">
            <div class="main-partner__content">
                <div class="main-partner__text">
                    <div class="main-partner__title">
                        <p>Why You Should Become a Partner?</p>
                    </div>
                    <div class="main-partner__sub-title">
                        <p>Explore Our Partner Program Features</p>
                    </div>
                </div>
                <div
                    v-if="!isMobile"
                    class="main-partner__cards"
                >
                    <div
                        v-for="point in points"
                        :key="point.title"
                        class="card-wrapper"
                    >
                        <TrulyMatterCard
                            :icon-src="getIconSrc(point.title)"
                            :icon-alt="point.title"
                            :title="point.title"
                            :description="point.description"
                            :card-class="'partner-card'"
                        />
                    </div>
                </div>
                <div
                    v-else
                    class="main-partner__cards"
                >
                    <carousel
                        :per-page="windowWidth <= 1250 && windowWidth >= 768 ? 2 : 1"
                        :mouse-drag="true"
                        :pagination-color="'rgba(114, 125, 141, 0.5)'"
                    >
                        <slide
                            v-for="point in points"
                            :key="point.title"
                        >
                            <TrulyMatterCard
                                :icon-src="getIconSrc(point.title)"
                                :icon-alt="point.title"
                                :title="point.title"
                                :description="point.description"
                            />
                        </slide>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import check from '../../assets/images/partner/become-a-partner/check.svg';
import support from '../../assets/images/partner/become-a-partner/support.svg';
import scaleBroken from '../../assets/images/partner/become-a-partner/scale-broken.svg';
import hornLine from '../../assets/images/partner/become-a-partner/horn-line.svg';
import TrulyMatterCard from '../main/MainTrulyMatters/TrulyMatterCard.vue';


export default {
    components: {
        TrulyMatterCard,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            points: [
                {
                    title: 'Cybersecurity Expertise',
                    description: 'Benefit from our expertise in mobile app security, gaining access to new markets, acquiring new clients, and boosting revenue while enhancing customer loyalty',
                },
                {
                    title: 'Support and Resources',
                    description: 'Enjoy ongoing support, technical assistance, and a wealth of resources to help you excel as a partner',
                },
                {
                    title: 'Scalability',
                    description: 'Elevate your business by scaling your operations and expanding your client base, all made possible through our adaptable and highly scalable solutions',
                },
                {
                    title: 'Co-Marketing Initiatives',
                    description: 'Collaborate with us on co-marketing campaigns to expand your reach and tap into new markets',
                },
            ],
            icons: {
                'Cybersecurity Expertise': check,
                'Support and Resources': support,
                'Scalability': scaleBroken,
                'Co-Marketing Initiatives': hornLine,
            },
        };
    },
    computed: {
        isMobile() {
            return this.windowWidth <= 1250;
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        getIconSrc(title) {
            return this.icons[title] || null;
        },
    },
};

</script>

<style lang="scss" scoped>
@import "../../assets/css/variables/variablesForPartnerPage";
@import "../../assets/css/fonts";

    .main-partner {
        background-color: #FFF;
        display: flex;
        flex-direction: column;

        &__container {
            display: flex;
            flex-direction: column;
        }

        &__content {
            display: flex;
            flex-direction: column;
            margin: 10rem $dynamic-partner-margin-l-r;
        }

        &__text {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__cards {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-top: 7%;
            gap: $dynamic-partner-gap;
        }

        &__title {
            position: relative;
            width: 40%;
            @include Aspekta-font(700, $dynamic-partner-become-title-font-size, 120%, #242424);
            letter-spacing: 0em;
        }

        &__sub-title {
            @include Aspekta-font(400, $dynamic-partner-become-sub-title-font-size, normal, #797E86);
            letter-spacing: 0em;
        }

        .card-wrapper {
            width: 290px;
        }
    }

    @media (max-width: 1250px) {
        .main-partner {
            &__content {
                margin: 5rem 0rem 5rem 1.25rem;;
            }

            &__text {
                flex-direction: column;
                align-items: flex-start;
            }

            &__title {
                line-height: 130%;
                margin-right: 1rem;
                width: 100%;
            }

            &__sub-title, .orange-word {
                letter-spacing: 0em;
            }

            &__cards {
                flex-direction: column;
                gap: 15px;
                margin-top: 3.3rem;
            }

            .point-card {
                height: 100%;
                border-radius: 24px;
                border: 1px solid #E8E7EF;
                padding: 11% 14% 7% 14%;
                margin-right: 15px;
            }
    }
}

</style>
