<template>
    <div>
        <!-- in viewBox 3rd argument is width 4th argument is height  -->
        <svg :fill="fill" :height="height" viewBox="0 0 21 25" :width="width" xmlns="http://www.w3.org/2000/svg">
            <g clip-rule="evenodd" :fill="fill" fill-rule="evenodd">
                <path d="M7.69165 0C5.24388 0 4.16477 0.215622 3.06946 0.801398C2.09426 1.32294 1.32294 2.09426 0.801397 3.06946C0.215622 4.16477 0 5.24388 0 7.69166V16.3083C0 18.7561 0.215621 19.8352 0.801397 20.9305C1.32294 21.9057 2.09426 22.6771 3.06946 23.1986C4.16477 23.7844 5.24388 24 7.69165 24H9.00026C9.55255 24 10.0003 23.5523 10.0003 23C10.0003 22.4477 9.55255 22 9.00026 22H7.69165C5.35671 22 4.67339 21.7883 4.01266 21.435C3.386 21.0998 2.90017 20.614 2.56503 19.9873C2.21167 19.3266 2 18.6433 2 16.3083V12V7.69166C2 5.35671 2.21167 4.67339 2.56503 4.01266C2.90017 3.386 3.386 2.90017 4.01266 2.56502C4.67339 2.21166 5.35671 2 7.69165 2L9.82614 2.00054C9.88905 2.00094 9.94731 2.00157 10.0015 2.00245L10 7.5C10 8.88071 11.1193 10 12.5 10L17.9976 9.99957C17.9992 10.1031 18 10.2215 18 10.3588V12.9965C18 13.5488 18.4477 13.9965 19 13.9965C19.5523 13.9965 20 13.5488 20 12.9965V10.3588C20 9.56213 19.9722 9.20369 19.878 8.79466C19.7846 8.38946 19.6329 8.02339 19.4125 7.67084C19.1899 7.31494 18.9561 7.04186 18.3927 6.47851L13.5215 1.60727C12.9581 1.04393 12.6851 0.810116 12.3292 0.587545C11.9766 0.367065 11.6105 0.215429 11.2053 0.122042C10.8634 0.0432327 10.5568 0.010899 10 0.00241228V0H7.69165ZM12.1073 3.02149L12 2.915V7.5C12 7.74546 12.1769 7.94961 12.4101 7.99194L12.5 8L17.0873 8.00206C17.0531 7.9675 17.0169 7.93111 16.9785 7.89273L14.5429 5.45711L12.1073 3.02149ZM16 15C16.5523 15 17 15.4477 17 16V19H20C20.5523 19 21 19.4477 21 20C21 20.5523 20.5523 21 20 21H17V24C17 24.5523 16.5523 25 16 25C15.4477 25 15 24.5523 15 24V21H12C11.4477 21 11 20.5523 11 20C11 19.4477 11.4477 19 12 19H15V16C15 15.4477 15.4477 15 16 15Z" />

            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: "UploadFileIcon",
    props: ["svgColor", "svgHeight", "svgWidth"],
    data() {
        return {
            fill: this.svgColor,
            width: this.svgWidth,
            height: this.svgHeight,
        };
    },
    methods: {
        setFill(value) {
            this.fill = value;
        },
    },
};
</script>
