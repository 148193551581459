<template>
    <div
        v-if="isDataAvailable"
        class="main-breadcrumb"
    >
        <div class="main-breadcrumb__integration">
            <b-nav-item
                class="main-breadcrumb__link"
                :to="link"
                :title="integrationName"
            >
                {{ integrationName }}
            </b-nav-item>
            <img
                class="main-breadcrumb__separator"
                src="../../../assets/images/separator_icon.svg"
                alt="separator icon"
            >
        </div>
        <div
            v-if="this.$route.name === 'Version scan'"
            class="main-breadcrumb__version"
        >
            Version {{ currentJob.data.version }}
        </div>
        <div
            v-else-if="this.$route.name === 'Versions compare'"
            class="main-breadcrumb__version"
        >
            Version comparison
        </div>
        <div
            v-else
            class="main-breadcrumb__version"
        >
            Branches list
        </div>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumb',

    props: {
        integrationId: {
            type: String,
            required: true,
        },
        integrationName: {
            type: String,
            required: true,
        },
        currentJob: {
            type: Object,
            required: false,
        },
        branchName: {
            type: String,
            required: true,
        },
    },
    computed: {
        isDataAvailable() {
            return this.integrationId &&
                this.integrationName &&
                this.currentJob &&
                this.$route.name === 'Version scan' ? this.currentJob.data : true;
        },
        link() {
            return {
                path: `/integration/${this.integrationId}/branch/${this.branchName}`,
            };
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../../assets/css/fonts';
@import '../../../assets/css/variables';

.main-breadcrumb {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__integration {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 4px 10px 10px;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
            background: #F5F7FA;

        }
    }

    &__link {
        a.nav-link {
            @include Aspekta-font(400, 14px, 20px, #949DB8);
            letter-spacing: 0.02em;
            display: block !important;
            max-width: 40vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__version {
        @include Aspekta-font(400, 14px, 20px, $main-blue-color);
        letter-spacing: 0.02em;
        word-break: break-all;
        margin: 0;
        border-radius: 8px;
        cursor: default;
        padding: 10px;

        &:hover {
            background: $background-blue-color;
        }
    }

    &__separator {
        margin: 4px 6.63px;
    }
}

@media only screen and (max-width: 768px) {  // tablet breakpoint
    .main-breadcrumb {
        &__integration {
            max-width: 100%;
        }

        &__link {
            a.nav-link {
                max-width: 88vw;
            }
        }
    }

}

  </style>
