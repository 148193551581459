<template>
    <div class="new-intergation-version__wrapper">
        <NewVersionIntegration />
    </div>
</template>
<script>
import NewVersionIntegration from '../../../components/newVersionIntegration/NewVersionIntegration';
export default {
    components: {
        NewVersionIntegration,
    },
};
</script>
<style lang="scss">
    .new-intergation-version__wrapper {
        background-color: #F8FAFC;
        padding: 40px 0 70px;
    }
</style>
