<template>
    <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            :fill="arrowColor"
            d="M19.4596 4.98013C20.0227 5.51006 20.0495 6.39608 19.5196 6.95912L12.4226 14.4996L19.5196 22.0401C20.0495 22.6031 20.0227 23.4892 19.4596 24.0191C18.8966 24.549 18.0105 24.5222 17.4806 23.9591L9.48062 15.4591C8.97326 14.9201 8.97326 14.0792 9.48062 13.5401L17.4806 5.0401C18.0105 4.47706 18.8966 4.45021 19.4596 4.98013Z"/>
    </svg>
</template>

<script>

export default {
    name: 'BackArrowIcon',
    props: {
        disabled: {
            type: Boolean,
            require: true,
        },
    },
    computed: {
        arrowColor() {
            return this.disabled ? '#D9D9D9' : '#949DB8';
        },
    },
};
</script>


