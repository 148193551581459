<template>
    <div class="enhance-security">
        <div class="enhance-security__content">
            <span class="title-text">{{ titleText }}</span>

            <div class="action-section">
                <span class="text">{{ text }}</span>
                <button
                    class="unlock-security-btn"
                    @mousedown="isClicked = !isClicked"
                    @mouseup="isClicked = !isClicked"
                    @click="requestDemo"
                >
                    {{ buttonText }}
                    <TopArrowIcon
                        :clicked="isClicked"
                        class="arrow-icon"
                    />
                </button>
            </div>
            <img
                class="enhance-secutity-vector"
                src="../../assets/images/vector_enhance_security.svg"
                alt="vector"
            >
        </div>
    </div>
</template>
<script>


import TopArrowIcon from '../../assets/iconTemplates/TopArrowIcon.vue';

export default {
    components:{
        TopArrowIcon,
    },
    data() {
        return {
            isClicked: false,
            titleText: 'Enhance Security and Efficiency with Automation',
            text: `Discover how our automated solution saves valuable time and resources.
                            Take control of your cybersecurity with cost-effective automation`,
            buttonText: 'Unlock Security Automation Now',
        };
    },
    methods:{
        requestDemo() {
            this.$router.push('/request-demo');
        },
    },

};
</script>
<style lang="scss" scoped>
@import "../../assets/css/variables/variablesForEnhanceSecurity.scss";
@import "../../assets/css/variables.scss";
@import "../../assets/css/fonts";

.enhance-security {
    width: 100vw;
    background-color: var(--dark-blue, #02142C);
    display: flex;
    position: relative;
    overflow: hidden;
    &__content {
        display: flex;
        margin: 160px $dynamic-content-margin-l-r;
        justify-content: space-between;
    }
}

.action-section {
    padding-left: 6%;
    margin-bottom: 1%;
    width: 58%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title-text {
    width: 36%;
    text-align: left;
    @include Aspekta-font(700, $dynamic-title-text-font-size, normal, #FFF);
    line-height: 120%;
}

.text {
    @include Aspekta-font(400, $dynamic-text-font-size, normal, #FFF);
    opacity: 0.5;
}

.unlock-security-btn {
    width: max-content;
    display: flex;
    @include Aspekta-font(700, $dynamic-unlock-security-btn-font-size, normal, #FFFFFF);
    background-color: $new-orange-color;
    border-radius: 30px;
    padding: 15px 15px 15px 30px;
    text-decoration: none;
    align-items: center;
    z-index: 1;

        &:hover {
            background: linear-gradient(0deg, rgba(255, 122, 8, 0.70) 0%, rgba(255, 122, 8, 0.70) 100%), var(--white, #FFF);
        }

        &:active {
            color: #0C0D0E;
            background-color: #FFF;
        }
}

.arrow-icon {
    margin-left: 15px;
    height: 30px;
    width: 30px;
}

.enhance-secutity-vector {
    position: absolute;
    width: 126%;
    z-index: 0;
    height: 140%;
    top: $dynamic-enhance-secutity-vector-top;
    left: $dynamic-enhance-secutity-vector-left;
}

@media (max-width: 1100px) {
    .enhance-secutity-vector {
        transform: rotate(9deg);
    }
}

@media (max-width: 768px) {
    .arrow-icon {
         height: 22px;
         width: 22px;
    }
    .enhance-security__content {
        align-items: center;
        flex-direction: column;
        margin: 100px $dynamic-content-margin-l-r;
    }

    .action-section {
        width: auto;
        margin-top: 30px;
        margin-bottom: 7rem;//100px
        padding-left: 0;
        text-align: left;
        gap: 50px;
    }

    .title-text {
        width: auto;
        font-size: 32px;
    }

    .text {
        font-size: 14px;
        width: 70%;
    }

    .unlock-security-btn {
         font-size: 14px;
    }
    .enhance-secutity-vector {
        width: 1276px;
        height: 820px;
        transform: rotate(13deg);
        top:$dynamic-enhance-secutity-vector-top-mob;
        left: $dynamic-enhance-secutity-vector-left-mob;
    }

}



</style>
