<template>
    <!-- Calendly inline widget begin -->
    <div
        class="calendly-inline-widget"
        :data-url="calendlyUrl"
        :style="calendlyStyles"
    >
        <Spinner v-if="isLoading" />
    </div>
</template>

<script>
import { sendSlackDemoRequest } from '../../services/slackService/slackService';
import Spinner from '../Spinner.vue';

export default {
    components: {
        Spinner,
    },
    props: {
        stepData: Object,
    },
    data() {
        return {
            calendlyStyles: {
                width: '1155px',
                height: '800px',
            },
            isLoading: true,
        };
    },
    computed: {
        calendlyUrl() {
            const name = this.stepData ? this.stepData.name : '';
            const email = this.stepData ? this.stepData.email : '';
            const a1 = this.stepData ? this.stepData.companyName : '';
            const a2 = this.stepData ? this.stepData.securityChallanges : '';

            return `https://calendly.com/oversecured/30min?name=${name}&email=${email}&a1=${a1}&a2=${a2}`;
        },
    },
    mounted() {
        this.subscribeToCalendlyEvent();
        this.updateCalendlyStyles();

        window.addEventListener('resize', this.updateCalendlyStyles);

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        document.body.appendChild(script);

        const self = this;
        script.onreadystatechange = script.onload = function () {
            const widgetDiv = document.querySelector('.calendly-inline-widget');
            const iframe = widgetDiv.querySelector('iframe');

            iframe.addEventListener('load', function () {
                if (window.Calendly) {
                    self.isLoading = false;

                    window.addEventListener('message', self.handleCalendlyEvent);
                }
            });
        };
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateCalendlyStyles);
        this.unsubscribeFromCalendlyEvent();
    },
    methods: {
        handleCalendlyEvent(e) {
            const isCalendlyEvent =
                e.origin === 'https://calendly.com' &&
                e.data.event &&
                e.data.event.indexOf('calendly.') === 0;

            if (isCalendlyEvent && e.data.event === 'calendly.event_scheduled') {
                gtag('event', 'product_demo_request', { event_category: 'engagement', event_label: 'custom' });
                sendSlackDemoRequest({
                    ...this.stepData,
                });
            }
        },
        subscribeToCalendlyEvent() {
            window.addEventListener('message', this.handleCalendlyEvent);
        },
        unsubscribeFromCalendlyEvent() {
            window.removeEventListener('message', this.handleCalendlyEvent);
        },
        updateCalendlyStyles() {
            if (window.innerWidth <= 1520) {
                this.calendlyStyles.width = '100%';
            } else {
                this.calendlyStyles.width = '1155px';
            }
        },
    },
};
</script>
