<template>
    <div v-if="integration">
        <div class="inner-integration-header">
            <div>
                <p class="inner-integration-header__platform">
                    Platform:<span class="inner-integration-header__platform-value">{{ checkAndUpdatePlatformText(integration.platform) }}</span>
                </p>
                <p class="inner-integration-header__platform">
                    Integration ID:<span class="inner-integration-header__platform-value">{{ integration.id }}</span>
                </p>
                <div class="inner-integration-header__wrapper">
                    <img
                        id="integration_logo"
                        class="inner-integration-header__icon"
                        alt=""
                        :src="integration.signedUrl ? integration.signedUrl : DefaultLogo"
                        @load="isShowSpinner = false"
                        @error="errorLoaded"
                    >
                    <div class="inner-integration-header__box">
                        <div class="inner-integration-header__file-name">
                            {{ integration.integrationName }}
                        </div>
                        <p class="inner-integration-header__text">
                            {{ integration.integrationDescription }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="inner-integration-header__wrapper-links">
                <div class="inner-integration-header__links">
                    <p
                        v-if="renewalRequestStatus()"
                        class="inner-integration-header__renewal-request-text"
                    >
                        Integration update request has been sent
                    </p>
                    <b-nav-item
                        v-if="!isExpired && integration.status !== 'SAMPLE'
                            && countVersionsFinish !== 0
                            && integration.isAllowAddVersion
                            && (isAllowAddVersionToIntegration()|| !integration.limited)"
                        :to="{ path: `/integration/${integration.id}/branch/${branchName}/new-version`}"
                        exact
                        class="inner-integration-header__add-new-version-btn"
                    >
                        Add New Version
                    </b-nav-item>
                    <div
                        v-if="integration.status === 'SAMPLE'|| (integration.status === 'ACTIVE'
                            && countVersionsFinish > 0
                            && (integration.trial && !integration.isAllowAddVersion || integration.limited && !isAllowAddVersionToIntegration()))"
                        class="inner-integration-header__disable_container"
                    >
                        <Tooltip
                            position="top"
                            mobile-position="top"
                            :tooltip="addVersionTooltipText()"
                        >
                            <div
                                class="inner-integration-header__add-new-version-btn inner-integration-header__add-new-version-btn--disable-gray"
                            >
                                Add New Version
                            </div>
                        </Tooltip>
                    </div>
                    <b-nav-item
                        v-if="isExpired && integration.isIntegrationOwner"
                        class="inner-integration-header__add-new-version-btn"
                        :class="{'inner-integration-header__add-new-version-btn--disable': renewalRequestStatus()}"
                        :disabled="renewalRequestStatus()"
                        @click="renewalIntegrationRequest()"
                    >
                        Request Renewal
                    </b-nav-item>
                    <Tooltip
                        position="top"
                        mobile-position="left"
                        :tooltip="'Integration settings'"
                    >
                        <b-nav-item
                            :to="{ path: `/integration/${integration.id}/settings/general`}"
                            exact
                            class="inner-integration-header__settings-btn"
                        >
                            <img
                                src="../../assets/images/settings-btn.svg"
                                class="settings-btn"
                                alt=""
                            >
                        </b-nav-item>
                    </Tooltip>
                </div>
                <p
                    v-if="isIntegrationExpired && integration.status !== 'SAMPLE'"
                    class="inner-integration-header__alert inner-integration-header__alert--red"
                >
                    Expired
                </p>
                <p
                    v-if="!isIntegrationExpired"
                    :class="isLessThan14Days
                        ? 'inner-integration-header__alert inner-integration-header__alert--red'
                        : 'inner-integration-header__alert'"
                >
                    Expires on {{ changeDateFormat(getExpireDate(integration.dateExpiredIntegration)) }}
                </p>
                <p
                    v-if="!isIntegrationExpired && integration.limited"
                    :class="isScansLimit
                        ? 'inner-integration-header__alert inner-integration-header__alert--red'
                        : 'inner-integration-header__alert'"
                >
                    Available Scans: {{ calculateAvailableScans(integration.limitedCount - integration.usedVersionsCount) }}
                </p>
            </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
                <div class="inner-integration-header">
                    <b-skeleton
                        type="button"
                        width="252px"
                        style="margin-top: 29px;"
                    />
                </div>
            </template>
            <div class="inner-integration-header">
                <div class="row">
                    <DropDown
                        :options="branches"
                        :show-label="true"
                        :value="branchId"
                        name="branches"
                        label=""
                        empty-option-label="Select branch"
                        @changeBranch="changeBranch"
                    />
                    <b-nav-item
                        :to="{path: `/integration/${this.$route.params.id}/branches`}"
                        class="all-branches-count"
                    >
                        All ({{ branchList && branchList.length }})
                    </b-nav-item>
                </div>
            </div>
        </b-skeleton-wrapper>

        <div v-if="countVersionsFinish === 0 && versionsInProgress">
            <FirstVersionIntegration
                :is-expired="isExpired"
                :renewal-request="renewalRequestStatus()"
                :integration-status="integration.status"
                :integration="integration"
                :branch-list="branchList"
                :branch-name="branchName"
                @renewalIntegrationRequest="renewalIntegrationRequest"
            />
        </div>
        <div
            v-if="isShowSpinner"
            class="inner-integration-header__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import FirstVersionIntegration from '../newVersionIntegration/FirstVersionIntegration';
import DropDown from './modalWindow/dropDownModal';
import Spinner from '../Spinner';
import { IntegrationStatusEnum } from '@/utils/constants/common';
import DefaultLogo from '../../assets/images/default-integration-icon.png';
import { checkAndUpdatePlatformText } from '../../services/functions';
import { successNotification } from '@/services/notificationService/notificationService';
import Tooltip from '../report/components/Tooltip.vue';

export default {
    components: {
        FirstVersionIntegration,
        DropDown,
        Tooltip,
        Spinner,
    },
    props: {
        isExpired: Boolean,
    },
    data() {
        return {
            DefaultLogo,
            isShowSpinner: false,
            showHintSettingsLabel: false,
            showHintAddVersionLabel: false,
            isLessThan14Days: false,
            isIntegrationExpired: false,
            branchName: '',
            fourTeenDays: 1209600000, // 1209600000 milliseconds = 14 days
            isScansLimit: false,
            integrationId: '',
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            branchList: 'integrations/branchList',
            integration: 'integrations/integrationById',
            countVersionsFinish: 'integrations/countVersionsFinish',
            versionsInProgress: 'integrations/versionsInProgress',
        }),
        branches() {
            return this.branchList && this.branchList.map(branch => ({ value: branch.id, label: branch.name }));
        },
        branchId() {
            const branch = this.branchList && this.branchList.find(branch => branch.name === this.branchName);
            return branch && branch.id;
        },
    },
    watch: {
        '$route.params.branchName': {
            async handler () {
                await this.loadBranchListData();
            },
            deep: true,
        },
    },
    async beforeMount() {
        this.isLoading = true;
        await this.loadBranchListData();
        this.isLoading = false;
    },
    mounted() {
        this.checkExpireTime(this.integration.dateExpiredIntegration);
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        checkAndUpdatePlatformText,
        addVersionTooltipText() {
            if (this.integration.trial && !this.integration.isAllowAddVersion) {
                return 'The number of trial scans has exceeded';
            } else if (this.integration.limited && !this.isAllowAddVersionToIntegration()) {
                return 'The number of Integration scans has exceeded its limits';
            } else if (this.integration.status === 'SAMPLE'){
                return 'New versions cannot be added in the Sample Integration';
            }
        },
        /**
         * This method is called when the user scrolls the page. It hides the hint labels.
         */
        handleScroll() {
            this.showHintSettingsLabel = false;
            this.showHintAddVersionLabel = false;
        },
        isScansLimited(limitedCount) {
            if (limitedCount === 0) this.isScansLimit = true;
            if (limitedCount > 0) this.isScansLimit = false;
        },
        calculateAvailableScans(calculatedData) {
            return calculatedData > 0
                ? calculatedData
                : 0;
        },
        errorLoaded() {
            this.isShowSpinner = false;
            document.getElementById('integration_logo').src = this.DefaultLogo;
        },
        getExpireDate(expireTime) {
            return moment(Date.parse(expireTime)).format('MMM D y');
        },
        checkExpireTime(expireTime) {
            if ((Date.parse(expireTime) - Date.now()) < this.fourTeenDays) {
                this.isLessThan14Days = true;
            }

            if ((Date.parse(expireTime) - Date.now()) < 0) {
                this.isIntegrationExpired = true;
            }
        },
        changeDateFormat(date) {
            const firstElements = date.split(' ').splice(0, 2);
            const restDate = date.split(' ').splice(2);

            return `${firstElements.join(' ')}, ${restDate.join(' ')}`;
        },
        renewalRequestStatus() {
            return this.integration.status === IntegrationStatusEnum.RENEWAL_REQUESTED;
        },
        async renewalIntegrationRequest() {
            this.isShowSpinner = true;
            await this.$store.dispatch('integrations/renewalIntegrationRequest', { integrationId: this.$route.params.id });
            await this.$store.dispatch('integrations/loadIntegrationById', {
                id: this.$route.params.id, branchName: this.branchName, page: 1, limit: 10,
            });
            this.isShowSpinner = false;
            this.successNotification('renewalSend');
        },
        async changeBranch(id) {
            const findBranch = this.branchList.find(branch => branch.id === id);
            if (findBranch.name !== this.branchName) {
                this.$router.push(findBranch.name);
            } else {
                return;
            }
        },
        isAllowAddVersionToIntegration() {
            return this.integration.limitedCount > this.integration.usedVersionsCount;
        },
        async loadBranchListData() {
            this.integrationId = this.$route.params.id;
            this.branchName = this.$route.params.branchName;
            this.$store.commit('integrations/setCurrentBranchName', this.branchName);
            await this.$store.dispatch('integrations/getBranchList', { integrationId: this.$route.params.id });
            this.isScansLimited(this.integration.limitedCount);
        },
        successNotification,
    },
};
</script>
<style lang="scss">
@import "../../assets/css/fonts";
@import "../../assets/css/variables";

a {
    color: #3180F6;
}

.row {
    margin-left: 0;
}

.inner-integration-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 7.5%;

    &__spinner {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 100;
    }

    &__alert-message {
        position: absolute;
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
    }

    &__wrapper-links {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    &__links {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    &__icon {
        margin-top: calc(0px + (10 - 0) * ((100vw - 320px) / (1920 - 320)));
        width: 30px;
        height: 30px;
        border-radius: 5px;
    }

    &__box {
        margin-left: 20px;
    }

    &__file-name {
        @include Aspekta-font(700, $dynamic-integration-name-font-size, $dynamic-integration-line-height, #021631);
        word-break: break-word;
    }

    &__platform {
        @include Aspekta-font(500, $dynamic-integration-description-font-size, normal, #949DB8);
        letter-spacing: $dynamic-integration-description-letter-spacing;
        margin-left: 50px;
        margin-bottom: 10px;

        &-value {
            @include Aspekta-font(400, $dynamic-integration-description-font-size, normal, #5C6A7A);
            letter-spacing: $dynamic-integration-description-letter-spacing;
            padding-left: 6px;

        }
    }

    &__text {
        @include Aspekta-font(400, $dynamic-integration-description-font-size, normal, #949DB8);
        letter-spacing: $dynamic-integration-description-letter-spacing;
        max-width: 549px;
        word-break: break-word;
        margin-top: 8px;
        &__id {
            color: rgba(0,0,0,1);
            margin-top: 10px;
        }
    }

    &__settings-btn {
        background: #FFFFFF;
        border: 2px solid #E3E6ED;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $dynamic-settings-button-horizontal-padding $dynamic-settings-button-vertical-padding;
        transition: 0.3s;
        position: relative;
        cursor: pointer;

        .settings-btn {
            opacity: 1;
            height: $dynamic-settings-image-height;
        }

        .settings-btn:hover {
            opacity: 0.7;
        }

        &:active {
            opacity: 1;
        }
    }

    &__renewal-request-text {
        @include Aspekta-font(400, 14px, normal, #242424);
        margin: 0 10px 5px 5px;
        display: flex;
        align-items: center;
        height: 45px;
        max-width: 150px;
        text-align: center;
    }

    &__add-new-version-btn {
        a.nav-link {
            @include Aspekta-font(650, $dynamic-button-font-size, normal, #FF7A08);
            letter-spacing: $dynamic-button-letter-spacing;
            display: flex;
            justify-content: center;
            background: #FFFFFF;
            border: 2px solid #FFD2AB;
            box-sizing: border-box;
            border-radius: 8px;
            padding: $dynamic-button-horizontal-padding $dynamic-button-vertical-padding;
            margin-right: 15px;
            width: calc(200px + (270 - 200) * ((100vw - 320px) / (1920 - 320)));

            &:hover {
                box-shadow: 0px 8px 16px 0px rgba(255, 180, 0, 0.24);
            }
        }

        &--disable-gray {
            @include Aspekta-font(650, $dynamic-button-font-size, normal, #D9D9D9);
            letter-spacing: $dynamic-button-letter-spacing;
            display: flex;
            justify-content: center;
            background-color: #F9F9F9;
            border: 2px solid #D9D9D9;
            box-sizing: border-box;
            border-radius: 8px;
            padding: $dynamic-button-horizontal-padding $dynamic-button-vertical-padding;
            transition: 0.3s;
            margin-right: 15px;
            width: calc(200px + (270 - 200) * ((100vw - 320px) / (1920 - 320)));
            cursor: default;

            &:hover {
                opacity: 0.7;
            }
            &:active {
                opacity: 1;
            }
        }

        &--disable {
            opacity: 0.5;
        }
    }

    &__alert {
        @include Aspekta-font(400, $dynamic-integration-description-font-size, normal, black);
        letter-spacing: $dynamic-integration-description-letter-spacing;
        width: 100%;
        text-align: right;
        margin-top: 10px;
        margin-bottom: 0px;

        &--red {
            color: #FB8D62;
        }
    }

}

.integration-settings-none {
    display: none;
}

.all-branches-count {
    @include Aspekta-font(400, 14px, normal, #3180F6);
    margin: 42px 0 0 11px;
}

@media (max-width: 1000px) {
    .inner-integration-header {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 0 20px !important;

        &__wrapper {
            display: flex;
            align-items: flex-start;
        }

        &__links {
            flex-direction: row;
            justify-content: flex-end;
            flex-wrap: wrap;
        }

        &__wrapper-links {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }

        &__icon {
            display: none;
            max-width: 30px;
            max-height: 30px;
        }

        &__box {
            margin-left: 0;
        }

        &__file-name {
            font-size: 30px;
            color: #242424;
            margin: -4px 0 0 0;
            max-width: 650px;
            word-break: break-word;
        }

        &__platform {
            display: none;
        }

        &__text {
            font-size: 14px;
            color: #B2B9C4;
            max-width: 549px;
            margin-top: 10px;
        }

        &__settings-label {
            line-height: 21px;
        }

        &__disable_icon_text {
            width: 190px;
        }

        &__add-new-version-btn {
            a.nav-link {
                font-size: 16px !important;
                padding: 10px 22px;
                margin-left: 0px;
                margin-right: 15px;
            }

            &--disable-gray {
                font-size: 16px !important;
                padding: 10px 22px;
                margin-right: 15px;
            }
        }

        &__settings-btn {
            padding: 13.6px;
        }

        &__alert {
            text-align: left;
            margin: 7px 0 0 0;
        }
    }

}

@media (max-width: 576px) {
    .inner-integration-header {
        margin: 0 20px;
    }
}
</style>
