<template>
    <div
        v-if="currenVersionsInProgress && currenVersionsInProgress.length"
        class="inner-integration-progress"
    >
        <div class="inner-integration-progress__header-wrapper">
            <h2 class="inner-integration-progress__sign">
                In progress
            </h2>
        </div>
        <b-table
            v-if="currenVersionsInProgress.length > 0"
            id="progress-inner"
            hover
            :items="currenVersionsInProgress"
            :fields="uploadedFilesFields"
            :thead-class="`table-header__hidden`"
            :tbody-class="`table-body`"
        >
            <template #cell(version)="data">
                <div @click="showReport(data.item)">
                    {{ data.item.version }}
                </div>
            </template>
            <template #cell(progress)="data">
                <div
                    v-if="isScanning(data.item.status)"
                    :key="progressSpinner"
                    class="dot-spin-wrapper"
                >
                    <div class="dot-spin" />
                </div>
            </template>
            <template #cell(status)="data">
                <p
                    v-if="isCreated(data.item.status)"
                    class="status-text inner-integration-progress__in_queue-status"
                >
                    Created
                </p>
                <p
                    v-if="isScanning(data.item.status)"
                    class="status-text inner-integration-progress__scanning-status"
                >
                    Scanning
                </p>
                <p
                    v-if="isFailed(data.item.status)"
                    class="status-text inner-integration-progress__failed-status"
                >
                    Failed
                </p>
                <p
                    v-if="isInQueue(data.item.status)"
                    class="status-text inner-integration-progress__in_queue-status"
                >
                    In queue
                </p>
            </template>
            <template #cell(actions)="data">
                <div class="button__delete">
                    <Tooltip
                        position="top"
                        mobile-position="left"
                        :tooltip="'Delete'"
                    >
                        <button
                            :disabled="showAllList"
                            @click="deleteVersionInProgress(data.item)"
                        >
                            <img
                                class="table__btn-icon"
                                src="../../assets/images/newScan/trash-icon.svg"
                                alt="icon_action_delete"
                            >
                        </button>
                    </Tooltip>
                </div>
                <div
                    v-if="showAllList"
                    class="inner-integration-finished__spinner"
                >
                    <Spinner />
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import {JobStatusEnum} from '../../utils/constants/common';
import Spinner from '@/components/Spinner';
import { mapGetters } from 'vuex';
import Tooltip from '../report/components/Tooltip.vue';

export default {
    name: 'InnerIntegrationsInProgress',
    components: {
        Spinner,
        Tooltip,
    },
    data() {
        return {
            showAllList: false,
            progressSpinner: 1,
            uploadedFilesFields: [
                {
                    key: 'index',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-5', 'center', 'table__small-icon'],
                },
                {
                    key: 'fileName',
                    tdClass: ['table-cell', 'table-column-text-name', 'table-column-width-30', 'left', 'table__small-name'],
                },
                {
                    key: 'progress',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-30', 'left', 'table__small-package'],
                },
                {
                    key: 'status',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-5', 'center', 'table__small-version'],
                },
                {
                    key: 'actions',
                    label: '',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-10', 'center', 'table__small-actions'],
                },
            ],
            integrationId: '',
            branchName: '',
        };
    },
    computed: {
        ...mapGetters({
            currenVersionsInProgress: 'integrations/versionsInProgress',
        }),
    },
    watch: {
        '$route.params.branchName': {
            handler () {
                this.loadVersionInProgressData();
            },
            deep: true,
        },
    },
    beforeMount() {
        this.loadVersionInProgressData();
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        async deleteVersionInProgress(item) {
            this.$parent.showSpinner = true;
            await this.$store.dispatch('integrations/deleteVersionInProgress', {
                id: item.integrationId, branchName: this.branchName, versionId: item.id,
            });
            this.$parent.showSpinner = false;
        },
        showReport(item) {
            this.$router.push(`/integration/${item.integrationId}/branch/${this.branchName}/version/${item.id}`);
        },
        isScanning(status) {
            return status === JobStatusEnum.SCANNING;
        },
        isCreated(status) {
            return status === JobStatusEnum.CREATED;
        },
        isFailed(status) {
            return status === JobStatusEnum.ERROR;
        },
        isInQueue(status) {
            return status === JobStatusEnum.IN_QUEUE;
        },
        filterVersion(listVersion) {
            return listVersion.filter(el => el.status !== 'FINISH');
        },
        async loadVersionInProgressData() {
            this.integrationId = this.$route.params.id;
            this.branchName = this.$route.params.branchName;
            await this.$store.dispatch('integrations/loadVersionsInProgress', {id: this.integrationId, branchName: this.branchName});
        },
        /**
         * This method is called when the user scrolls the page. It hides the hint labels.
         */
        handleScroll() {
            this.hideLabel();
        },
        /**
         * Hide all label hints.
         */
        hideLabel() {
            this.showHintDeleteLabel = null;
        },
    },
};
</script>

<style lang="scss">
@import "../../assets/css/fonts";
@import "../../assets/css/variables";

.spinner_progress {
    position: absolute;
    height: 200%;
    width: 100%;
    background: rgba(256, 256, 256, 0.3);
    top: 0;
    left: 0;
    padding-top: 20%
}

.width-25 {
    width: 25%;
}

.inner-integration-progress {
    @include Aspekta-font(400, 14px, 140%, #B2B9C4);
    overflow: auto;
}

.inner-integration-progress {
    overflow: auto;
    margin-bottom: 50px;

    &__header-wrapper {
        display: flex;
        align-items: center;
        padding: 20px 0;
        margin: 0 7.5%;
    }

    &__sign {
        @include Aspekta-font(600, 18px, 26px, #242424);
        margin: 0;
    }

    &__files {
        @include Aspekta-font(500, 18px, 26px, #949DB8);
        margin: 0 40px 0 10px;

    }

    &__in_queue-status {
        color: #b2b9c4;
    }

    &__scanning-status {
        color: #00b253;
    }

    &__failed-status {
        color: #FB8D62;
    }
}

#progress-inner tbody tr td:nth-child(5) {
    .button__delete {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 40px;
        align-items: center;
    }
}

#progress-inner tbody tr:last-child {
    border-radius: 20px !important;
}

#progress-inner tbody tr:last-child td:first-child {
    border-bottom-left-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

#progress-inner tbody tr:last-child td:last-child {
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
}

#progress-inner {
    width: calc(100% - 15%);
    background-color: white;
    border-radius: 20px;
    margin: 0 auto;
}

#progress-inner thead tr th {
    border: none;
    padding: 20px;
}

#progress-inner tbody tr td {
    padding: 20px 0 !important;
    border-top: none !important;
}

#progress-inner thead tr th:nth-child(3),
#progress-inner thead tr th:nth-child(4),
#progress-inner thead tr th:nth-child(5) {
    position: relative;
    padding-left: 25px;
}

#progress-inner thead tr th:nth-child(4) {
    visibility: hidden;
}

#progress-inner thead tr th:nth-child(4) {
    position: relative;

    &:before {
        left: 35px;
        background-color: rgba(253, 211, 101, 0.5);
        border: 1px solid #FDD365;
    }
}

#progress-inner thead tr th:nth-child(5) {
    position: relative;

    &:before {
        left: 30px;
        background-color: rgba(97, 212, 179, 0.5);
        border: 1px solid #61D4B3;
    }
}

#progress-inner thead tr,
#progress-inner tbody tr {
    text-align: center;
}

#progress-inner tbody tr td:nth-child(2) {
    padding-top: 25px !important;
    @include Aspekta-font(600, 18px, 140%, #242424);
}

.table-vulnerability__positive,
.table-vulnerability__negative {
    @include Aspekta-font(400, 14px, 140%, #61D4B3);
    display: inline-block;
    margin: 0;
    min-width: 30px;
}

.table-vulnerability__negative {
    color: #FB8D62;
}

#progress-inner tbody tr td:nth-child(3),
#progress-inner tbody tr td:nth-child(4),
#progress-inner tbody tr td:nth-child(5) {
    color: #F0F0F0;
}

#progress-inner thead tr th:nth-child(6) {
    color: transparent;
}

.dot-spin-wrapper {
    padding-left: 100px ;
}

@media (max-width: 1120px) {

    #progress-inner {
        width: 1120px;
    }
}

@media (max-width: 1000px) {
    .table__btn-icon {
        visibility: visible !important;
    }
}

@media (max-width: 769px) {
    .inner-integration-finished {
        &__sign {
            margin-left: 20px;
        }
    }

    #finished {
        margin: 0 auto;
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    #progress-inner tbody tr td:nth-child(1) {
        width: 90px !important;
    }

    #progress-inner thead tr th:nth-child(2) {
        width: 180px !important;
    }

    #progress-inner tbody tr td:nth-child(2) {
        width: 180px !important;
    }

    #progress-inner tbody tr td:nth-child(3) {
        p {
            text-align: center;
            margin-left: 30px;
        }
    }

    #progress-inner thead tr th:nth-child(3),
    #progress-inner thead tr th:nth-child(4),
    #progress-inner thead tr th:nth-child(5) {
        width: 150px !important;
    }

    #progress-inner tbody tr td:nth-child(3),
    #progress-inner tbody tr td:nth-child(4),
    #progress-inner tbody tr td:nth-child(5) {
        width: 150px !important;
    }

    #progress-inner tbody tr td:nth-child(6) {
        width: 220px !important;
    }

    #progress-inner thead tr th:nth-child(3),
    #progress-inner thead tr th:nth-child(4),
    #progress-inner thead tr th:nth-child(5) {
        &:before {
            left: 35px;
        }
    }

    #progress-inner thead tr th:nth-child(4) {
        &:before {
            left: 25px;
        }
    }
}

@media (max-width: 576px) {
    .inner-integration-progress {

        &__header-wrapper {
            margin: 0 20px;
        }
    }
}
</style>
