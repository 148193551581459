<template>
    <div>
        <div class="not-auth-container">
            <div class="not-auth-container__header">You aren’t logged in</div>
            <p class="not-auth-container__title">To accept the invite to the Integration you should log in first.</p>
            <button @click="goToAuthPage()" class="not-auth-container__login-btn cabin cabin-bold">
                Login
            </button>
        </div>

        <Spinner v-if="showSpinner" class="spinner"/>
    </div>
</template>

<script>
import Spinner from '../components/Spinner'

export default {
    name: 'NotAuthenticated',
    components: {
        Spinner
    },
    data() {
        return {
            id: this.$route.query.id,
            showSpinner: false
        }
    },
    methods: {
        async goToAuthPage() {
            this.showSpinner = true

            try {
                await this.$store.dispatch('user/checkUserRegistration', {invitedId: this.id})
                this.$router.push('/quick-authorization')
            } catch (err) {
                this.$router.push('/not-found')
            }

            this.showSpinner = false
        }
    }
}
</script>

<style lang="scss" scoped>
* {
    margin: 0;
    padding: 0;
}

@mixin Cabin {
    font-family: Cabin, serif;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    font-size: 14px;
    color: #242424;
}

.page-content {
    display: flex;
    justify-content: center;
    margin: 0 265px;
}

.not-auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 74px;

    &__header {
        font-family: Raleway, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 150%;
        color: #000;
        text-align: center;
    }

    &__title {
        @include Cabin;
        color: #706969;
        width: 252px;
        margin-top: 24px;
        text-align: center;
    }

    &__login-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFB400;
        right: 0;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        color: #FFFFFF;
        transition: 0.3s;
        padding: 10px 40px;
        font-size: 18px;
        cursor: pointer;
        line-height: 22px;
        margin: 36px 0;


        &:hover {
            opacity: 0.7;
        }

        &:active {
            opacity: 1;
        }

        &:focus {
            border: 2px solid #FFB400;
        }
    }

    .spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
    }
}

@media (max-width: 1080px) {
    .page-content {
        margin: 0;
    }
}

@media (max-width: 500px) {

    .invite-container__login-btn {
        padding: 12px 80px;
        width: 335px;
        height: 46px;
    }
}

</style>
