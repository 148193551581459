<template>
    <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="35"
            cy="35"
            r="35"
            :fill="fillColor"
        />
        <g :opacity="opacity">
            <path
                d="M33.1591 35.625H35.6136V43.75M21.5 30H48.5M41.1364 25V20M28.8636 25V20M23.9545 50H46.0455C46.6964 50 47.3208 49.7366 47.7811 49.2678C48.2414 48.7989 48.5 48.163 48.5 47.5V25C48.5 24.337 48.2414 23.7011 47.7811 23.2322C47.3208 22.7634 46.6964 22.5 46.0455 22.5H23.9545C23.3036 22.5 22.6792 22.7634 22.2189 23.2322C21.7586 23.7011 21.5 24.337 21.5 25V47.5C21.5 48.163 21.7586 48.7989 22.2189 49.2678C22.6792 49.7366 23.3036 50 23.9545 50Z"
                :stroke="strokeColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
    </svg>
</template>
<script>
export default {
    props: {
        state: {
            type: String,
            require: true,
        },
    },
    computed: {
        fillColor() {
            if(this.state === 'active') {
                return '#FF7A08';
            }

            return '#E8E7EF';
            
        },
        strokeColor() {
            if(this.state === 'active') {
                return '#FFF';
            }
            return '#797E86';
        },
        opacity() {
            if(this.state === 'active') {
                return 1;
            }
            return 0.4;
        },
    },
};
</script>
