<template>
    <div class="step__control-buttons">
        <button
            class="step__control-back"
            :disabled="disableBackButton"
            @click.prevent="moveBack"
        >
            <BackArrowIcon
                :disabled="disableBackButton"
                alt="back"
            />
        </button>
        <button
            v-if="confirmButton"
            class="step__control-confirm"
            :disabled="disableConfirmButton"
            @click.prevent="confirmScan"
        >
            Confirm
        </button>
        <button
            v-else
            class="step__control-next"
            :disabled="disableNextButton"
            @click.prevent="moveNext"
        >
            Next Step
        </button>
    </div>
</template>

<script>

import BackArrowIcon from '@/assets/iconTemplates/BackArrowIcon.vue';

export default {
    name: 'StepControlButtons',
    components:{
        BackArrowIcon,
    },
    props: {
        confirmButton: {
            type: Boolean,
            default: false,
        },
        disableNextButton: {
            type: Boolean,
            default: true,
        },
        disableConfirmButton: {
            type: Boolean,
            default: true,
        },
        disableBackButton: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        moveBack() {
            this.$emit('back');
        },
        moveNext() {
            this.$emit('next');
        },
        confirmScan() {
            this.$emit('confirm');
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/css/variables";

.step__control {
    &-buttons {
        display: flex;
        gap: 10px;
        margin-top: 30px;
    }
    &-back, &-confirm, &-next {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 63px;
        border-radius: 8px;
        font-size: 22px;
        font-weight: 600;
        padding: 18px 18px;
        transition: all .3s ease-in;
    }
    &-next {
        background-color: $background-blue-color;
        color: $main-blue-color;
        max-height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(:disabled):hover {
            background-color: $main-blue-color;
            color: #fff;
            box-shadow: $box-shadow-blue;
        }
        &:disabled {
            background-color: $background-disabled;
            color: $disabled-text;
            border: none;
            box-shadow: none;
        }
    }
    &-confirm {
        background-color: $main-text;
        color: #fff;
        border: 1px solid $main-text;
        &:hover {
            border: 1px solid $main-text;
            background-color: #fff;
            color: $main-text;
            box-shadow: 0px 12px 16px rgba(36, 36, 36, 0.12);
        }
        &:disabled {
            background-color: $background-disabled;
            color: $disabled-text;
            border: none;
            box-shadow: none;
        }
    }
    &-back {
        width: 63px;
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-ghost;
        color: $ghost-text;
        border: 1px solid $background-ghost;
        &:not(:disabled):hover {
            border: 1px solid $ghost-stroke;
            background-color: #fff;
            box-shadow: $box-shadow-ghost;
        }
        &:disabled {
            background-color: $background-disabled;
            border: none;
            box-shadow: none;
        }

    }
    &-confirm, &-next {
        width: 100%;
    }
}
@media (max-width: 1500px) {
    .step__control {
        &-buttons {
            display: flex;
            gap: 10px;
            margin-top: 30px;
        }
        &-back, &-confirm, &-next {
            border-radius: 8px;
            font-size: 18px;
            font-weight: 600;
            padding: 16px 18px;
        }
        &-confirm, &-next {
            width: 100%;
        }
    }
}
</style>
