<template>
    <div
        class="get-started"
    >
        <spinner
            v-if="isLoading"
            class="spinner"
        />
        <div class="get-started__header">
            <div class="header-main">
                Welcome to <span class="highlight">Oversecured!</span>
            </div>
            <div class="header-text">
                We are glad to see your passion for security, just as we have!
            </div>
        </div>
        <div class="get-started__how-use">
            <div class="get-started__how-use-topic">
                <div class="topic-header">
                    How <span class="highlight">to use</span> Oversecured?
                </div>
                <div class="topic-text">
                    Depending on the processes within the company, the amount of code being created, and the preferences of Oversecured customers, one of these options should be used to check the mobile app code for security.
                </div>
            </div>
            <div class="get-started__how-use-circles">
                <div class="circles-single">
                    <div class="circles-single-header">
                        Web portal
                    </div>
                    <div class="circles-single-text">
                        The web portal is user-friendly but requires human intervention.
                    </div>
                </div>
                <div class="circles-single">
                    <div class="circles-single-header">
                        CI/CD
                    </div>
                    <div class="circles-single-text">
                        The CI/CD plugin can be easily configured with just a few clicks, offering basic functionality.
                    </div>
                </div>
                <div class="circles-single">
                    <div class="circles-single-header">
                        API
                    </div>
                    <div class="circles-single-text">
                        The API provides unlimited features but requires a customer's DevOps engineer for integration and coding.
                    </div>
                </div>
            </div>
        </div>
        <div class="get-started__how-start">
            <div class="get-started__how-start-topic">
                <div class="topic-header">
                    How to Start?
                </div>
                <div class="topic-text">
                    Here are some important steps to help you get started with Oversecured
                </div>
                <img
                    src="@/assets/images/getStarted/blue-line.svg"
                    class="topic-line"
                    alt=""
                >
            </div>
            <div class="get-started__how-start-steps">
                <div
                    v-if="isGetStartedCompleted && !isGetStartedCongrats"
                    ref="congrats"
                    class="congratulations"
                >
                    <div>
                        Congratulations, you've completed all the tasks! If you ever need to revisit this information, it will always be available at the top of the page.
                    </div>
                    <img
                        class="congratulations-image"
                        src="@/assets/images/getStarted/congrats.svg"
                        alt="congrats"
                    >
                </div>
                <div class="start-steps">
                    <div
                        ref="requestIntegration"
                        class="step-card"
                    >
                        <div class="step-card-header">
                            <div :class="['step-number', isStepCompleted(GetStartedStepsEnum.CREATED_INTEGRATION) && 'completed']">
                                <span v-if="!isStepCompleted(GetStartedStepsEnum.CREATED_INTEGRATION)">1</span>
                                <span
                                    v-else
                                    class="step-checkmark"
                                />
                            </div>
                            Integrate Your App
                        </div>
                        <div class="step-card-text">
                            Press the button below to integrate your app with Oversecured:
                        </div>
                        <router-link to="/integrations/create">
                            <button
                                class="step-card-nav"
                                @click="addFromGetStartedAndRedirect(GetStartedStepsEnum.CREATED_INTEGRATION)"
                            >
                                Request Integration
                            </button>
                        </router-link>
                        <div class="step-card-annotation">
                            * You can manage all your integrated apps on
                            <a
                                href="/integrations"
                                target="_black"
                                class="step-link"
                            >Integrations</a> page.<br>
                            ** Check the instruction how to manage your integrated apps with an API
                            <a
                                href="/docs/api/integrations"
                                target="_black"
                                class="step-link"
                            >here</a>.
                        </div>
                    </div>
                </div>
                <div
                    ref="createApiKey"
                    class="start-steps"
                >
                    <div class="step-card">
                        <div class="step-card-header">
                            <div :class="['step-number', isStepCompleted(GetStartedStepsEnum.CREATED_API_KEY) && 'completed']">
                                <span v-if="!isStepCompleted(GetStartedStepsEnum.CREATED_API_KEY)">2</span>
                                <span
                                    v-else
                                    class="step-checkmark"
                                />
                            </div>
                            Create Your API Key
                        </div>
                        <div class="step-card-text">
                            Press the button below and choose the name for your API key:
                        </div>
                        <router-link to="/settings/api-keys">
                            <button
                                class="step-card-nav"
                                @click="addFromGetStartedAndRedirect(GetStartedStepsEnum.CREATED_API_KEY)"
                            >
                                Create an API Token
                            </button>
                        </router-link>
                        <div class="step-card-annotation">
                            * You can manage your API keys in
                            <a
                                href="/settings/api-keys"
                                target="_black"
                                class="step-link"
                            >Settings/API</a>
                        </div>
                    </div>
                </div>
                <div class="start-steps">
                    <div class="step-card">
                        <div class="step-card-header">
                            <div :class="['step-number', isStepCompleted(GetStartedStepsEnum.WATCHED_API) && 'completed']">
                                <span v-if="!isStepCompleted(GetStartedStepsEnum.WATCHED_API)">3</span>
                                <span
                                    v-else
                                    class="step-checkmark"
                                />
                            </div>
                            Check API Documentation
                        </div>
                        <div class="step-card-text">
                            Visit our
                            <router-link
                                to="/docs/api"
                                class="step-link"
                                @click="addFromGetStartedAndRedirect(GetStartedStepsEnum.WATCHED_API)"
                                v-text="`API Documentation`"
                            />
                            page to learn what possibilities it offers and how you can manage it.
                        </div>
                    </div>
                </div>
                <div class="start-steps">
                    <div class="step-card">
                        <div class="step-card-header">
                            <div :class="['step-number', isStepCompleted(GetStartedStepsEnum.WATCHED_CI_CD) && 'completed']">
                                <span v-if="!isStepCompleted(GetStartedStepsEnum.WATCHED_CI_CD)">4</span>
                                <span
                                    v-else
                                    class="step-checkmark"
                                />
                            </div>
                            CI/CD Installation
                        </div>
                        <div class="step-card-text">
                            You can use Oversecured service using a several plugins (like Bitrise, Jenkin, Gradle, CircleCI, etc.)<br>
                            Get all the information about these plugins on our
                            <router-link
                                to="/docs/ci-cd"
                                class="step-link"
                                @click="addFromGetStartedAndRedirect(GetStartedStepsEnum.WATCHED_CI_CD)"
                                v-text="`CI/CD instruction`"
                            />
                            page.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="get-started__questions">
            <div class="get-started__questions-card">
                <div class="questions-card-header">
                    Still have <span class="questions-card-header-line">questions?</span>
                </div>
                <div class="questions-card-block">
                    <div class="card-block-call">
                        <div class="card-block-call-text">
                            It's important for us to provide you with the best experience using Oversecured.
                            <br>
                            <br>
                            We understand that at this stage, your team may require our assistance to complete the integration process.
                            <br>
                            <br>
                            Feel free to contact us anytime.
                            We are here to help.
                        </div>
                        <a
                            class="card-block-call-button"
                            href="https://calendly.com/oversecured/30min"
                            target="_blank"
                        >
                            <img
                                src="@/assets/images/getStarted/call-icon.svg"
                                alt="call"
                            >
                            Book a Call
                        </a>
                    </div>
                    <div class="card-block-text-area">
                        <label
                            for="questionsAria"
                            class="area-text"
                        >
                            Or you can write your question/problem/etc here:
                        </label>
                        <textarea
                            id="questionsAria"
                            v-model="questionText"
                            class="area-field"
                            placeholder="Hi there, We have a question ..."
                        />
                        <button
                            class="area-button"
                            :disabled="!questionText"
                            @click="sendQuestion"
                        >
                            Ask a Question
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GetStartedStepsEnum } from '@/utils/constants/common';
import { successNotification, failNotification } from '@/services/notificationService/notificationService';
import Spinner from '@/components/Spinner.vue';

export default {
    name: 'GetStarted',
    components: { Spinner },
    data() {
        return {
            questionText: '',
            isLoading: false,
            GetStartedStepsEnum,
        };
    },
    computed: {
        ...mapGetters({
            getStartedSteps: 'user/getStartedSteps',
            isGetStartedCompleted: 'user/isGetStartedCompleted',
            toGetStartedFrom: 'user/toGetStartedFrom',
            isGetStartedCongrats: 'user/isGetStartedCongrats',
        }),
    },
    async mounted() {
        this.isLoading = true;
        await this.$store.dispatch('user/fetchUserInfo');
        if (this.toGetStartedFrom === GetStartedStepsEnum.CREATED_INTEGRATION) {
            this.$refs.requestIntegration.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
        if (this.toGetStartedFrom === GetStartedStepsEnum.CREATED_API_KEY) {
            this.$refs.createApiKey.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
        if (this.isGetStartedCompleted && !this.isGetStartedCongrats) {
            this.$store.dispatch('user/updateIsGetStartedCongrats');
            this.$refs.congrats.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
            const image = document.querySelector('.congratulations-image');
            const target = document.querySelector('.congratulations');
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        observer.unobserve(entry.target);
                        image.classList.add('show');
                        this.$confetti.start();
                        setTimeout(() => {
                            this.$confetti.stop();
                        }, 3000);
                    }
                });
            });
            observer.observe(target);
        }
        this.isLoading = false;
    },
    beforeDestroy() {
        this.$store.commit('user/setIsGetStartedCompleted', false);
        this.$store.commit('user/setToGetStartedFrom', null);
    },
    methods: {
        isStepCompleted(stepName) {
            return this.getStartedSteps.includes(stepName);
        },
        addFromGetStartedAndRedirect(step) {
            const isStepCompleted = Object.values(this.getStartedSteps).includes(step);
            if (!isStepCompleted) this.$store.commit('user/setIsFromGetStated', true);
        },
        async sendQuestion() {
            this.isLoading = true;
            const response = await this.$store.dispatch('user/sendQuestion', this.questionText);
            if (response.status === 200) {
                this.questionText = '';
                this.successNotification('questionSend');
            } else {
                this.failNotification();
            }
            this.isLoading = false;
        },
        successNotification,
        failNotification,
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.get-started {
    font-family: 'Aspekta';
    font-size: 14px;
    color: $general-black;
    overflow: hidden;
    .spinner {
        position: fixed;
    }
    &__header {
        padding: 75px 0;
        text-align: center;
        .header-main {
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 10px;
        }
    }
    &__how-use {
        display: flex;
        justify-content: center;
        gap: 10%;
        flex-wrap: wrap;
        background-color: $main-text;
        color: #fff;
        padding: 65px 10%;
        &-topic {
            flex: 1 1 25%;
            padding: 30px 0;
            align-self: center;
            .topic-header {
                font-size: 36px;
                font-weight: 700;
                line-height: 90%;
                margin-bottom: 20px;
            }
        }
        &-circles {
            flex: 0 0 auto;
            width: 645px;
            height: 615px;
            position: relative;
            align-self: center;
            padding: 30px 0;
            .circles-single {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                width: 365px;
                height: 365px;
                border-radius: 50%;
                padding: 0 100px;
                &-header {
                    font-size: 22px;
                    font-weight: 700;
                }
                &-text {
                    font-size: 14px;
                    text-align: center;
                }
                &:nth-child(1) {
                    top: 0;
                    right: 50%;
                    translate: 50%;
                    background: linear-gradient(180deg, rgba(49, 128, 246, 0.00) 0%, rgba(49, 128, 246, 0.35) 100%);
                }
                &:nth-child(2) {
                    bottom: 0;
                    right: 0;
                    background: linear-gradient(320deg, rgba(49, 128, 246, 0.00) 0%, rgba(49, 128, 246, 0.35) 100%);
                }
                &:nth-child(3) {
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(50deg, rgba(49, 128, 246, 0.00) 0%, rgba(49, 128, 246, 0.35) 100%);
                }
            }
        }
    }
    &__how-start {
        display: flex;
        justify-content: center;
        gap: 4%;
        flex-wrap: wrap;
        padding: 70px 10%;
        background-color: $background-grey;
        &-topic {
            flex: 0 1 25%;
            padding-top: 50px;
            .topic-header {
                font-size: 36px;
                font-weight: 700;
                line-height: 90%;
                margin-bottom: 20px;
            }
            .topic-text {
                max-width: 230px;
                margin-bottom: 60px;
            }
            .topic-line {
                margin-left: 40px;
            }
        }
        &-steps {
            flex: 1 1 50%;
            display: flex;
            flex-direction: column;
            padding-top: 50px;
            gap: 25px;
            .start-steps {
                display: flex;
                align-items: center;
                gap: 30px;
                position: relative;
                .step-number {
                    width: 24px;
                    height: 24px;
                    font-size: 14px;
                    border-radius: 50%;
                    background-color: $ghost-border;
                    text-align: center;
                    padding-top: 2px;
                    flex-shrink: 0;
                    font-weight: 550;
                }
                .step-number.completed {
                    background-color: $button-orange-color-text;
                    .step-checkmark {
                        display: block;
                        position: relative;
                        width: 24px;
                        height: 24px;
                        &::after {
                            content: "";
                            position: absolute;
                            left: -2px;
                            bottom: 12px;
                            height: 3px;
                            width: 14px;
                            transform: translateX(10px) rotate(-45deg);
                            background-color: #fff;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            left: -3px;
                            top: 8px;
                            height: 9px;
                            width: 3px;
                            background-color: #fff;
                            transform: translateX(10px) rotate(-45deg);
                        }
                    }
                }
                .step-card {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    width: 100%;
                    padding: 30px 40px;
                    border: 1px solid $ghost-border;
                    border-radius: 20px;
                    background-color: #fff;
                    letter-spacing: 0.56px;
                    &-header {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        font-size: 22px;
                        font-weight: 700;
                    }
                    &-nav {
                        border-radius: 8px;
                        font-size: 17px;
                        font-weight: 600;
                        padding: 16px 26px;
                        transition: all .3s ease-in;
                        border: 2px solid $border-orange-color;
                        color: $button-orange-color-text;
                        width: fit-content;
                        text-decoration: none;
                        &:hover {
                            border-color: $button-orange-color-text;
                            box-shadow: $box-shadow-orange;
                        }
                    }
                    &-annotation {
                        color: $secondary-text;
                    }
                    .step-link {
                        color: $main-blue-color;
                        text-decoration: underline;
                        font-weight: 550;
                    }
                }
            }
        }
        .congratulations {
            position: relative;
            padding: 35px 100px 40px 35px;
            border-radius: 20px;
            background-color: rgba(255, 122, 8, 0.15);
            overflow: hidden;
            &-image {
                position: absolute;
                bottom: -100px;
                right: -100px;
                transition: all 2s;
            }
            &-image.show {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
    &__questions {
        padding: 140px 10%;
        background-color: $main-text;
        &-card {
            display: flex;
            flex-direction: column;
            background-color: $background-grey;
            border: 1px solid $ghost-border;
            border-radius: 20px;
            padding: 60px 7%;
            .questions-card-header {
                font-size: 36px;
                font-weight: 750;
                margin-bottom: 60px;
                display: block;
                text-align: center;
                &-line {
                    position: relative;
                    &::after {
                        content: url('../../assets/images/getStarted/orange-line.svg');
                        position: absolute;
                        bottom: -15px;
                        right: 0;
                    }
                }
            }
            .questions-card-block {
                display: flex;
                justify-content: center;
                gap: 10%;
                .card-block-call {
                    flex: 0 1 50%;
                    &-text {
                        opacity: .7;
                        margin-bottom: 20px;
                    }
                    &-button {
                        border-radius: 8px;
                        font-size: 17px;
                        font-weight: 600;
                        padding: 16px 26px;
                        transition: all .3s ease-in;
                        border: 2px solid $secondary-text;
                        color: $main-text;
                        width: fit-content;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        flex-shrink: 0;
                        &:hover {
                            border-color: $main-text;
                            box-shadow: $box-shadow-black;
                            text-decoration: none;
                        }
                    }
                }
                .card-block-text-area {
                    flex: 0 1 50%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .area-text {
                        opacity: .5;
                    }
                    .area-field {
                        height: 107px;
                        padding: 20px 25px;
                        border: 1px solid $ghost-border;
                        border-radius: 8px;
                        resize: none;
                        &:focus {
                            outline: 1px solid $main-blue-color;
                        }
                    }
                    .area-button {
                        border-radius: 8px;
                        font-size: 17px;
                        font-weight: 600;
                        padding: 16px 26px;
                        transition: all .3s ease-in;
                        border: 2px solid $main-text;
                        color: #fff;
                        background-color: $main-text;
                        width: fit-content;
                        &:hover {
                            background-color: #fff;
                            color: $main-text;
                            box-shadow: $box-shadow-black;
                        }
                        &:disabled {
                            border: 2px solid $background-disabled;
                            color: $disabled-text;
                            background-color: #fff;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }
    span.highlight {
        color: $button-orange-color-text;
    }
}

@media (max-width: 900px) {
    .get-started__how-start {
        flex-direction: column;
        &-topic {
            position: relative;
            height: 270px;
            flex: 0 0 auto;
            align-self: center;
            padding-top: 0;
            .topic-text {
                margin-bottom: 0;
            }
            .topic-line {
                position: absolute;
                margin-left: 0;
                scale: .5;
                rotate: -62deg;
                transform: scaleX(-1);
                top: 32px;
                left: 118px;
            }
        }
        &-steps {
            padding-top: 0;
        }
    }
}
@media (max-width: 710px) {
    .get-started__how-use-circles {
        .circles-single {
            width: 330px;
            height: 330px;
            &:nth-child(2) {
                bottom: 45px;
                right: 30px;
            }
            &:nth-child(3) {
                bottom: 45px;
                left: 30px;
            }
        }
    }
    .get-started__questions-card {
        .questions-card-block {
            flex-direction: column;
            gap: 30px;
            .card-block-call-button {
                margin: 0 auto;
            }
        }
        .card-block-text-area {
            .area-button {
                margin: 0 auto;
            }
        }
    }
}

@media (max-width: 600px) {
    .get-started {
        &__header {
            padding: 75px 30px;
            .header-main {
                font-size: 22px;
            }
        }
        &__how-use {
            display: flex;
            flex-direction: column;
            gap: 30px;
            padding: 65px 6%;
            &-topic {
                align-self: flex-end;
            }
            &-circles {
                width: 330px;
                height: calc(330px*3 - 120px);
                .circles-single {
                    width: 330px;
                    height: 330px;
                    padding: 0 50px;
                    &:nth-child(1) {
                        top: 0;
                        background: linear-gradient(90deg, rgba(49, 128, 246, 0.00) 0%, rgba(49, 128, 246, 0.35) 100%);
                    }
                    &:nth-child(2) {
                        left: unset;
                        top: calc(330px - 60px);
                        background: linear-gradient(90deg, rgba(49, 128, 246, 0.00) 0%, rgba(49, 128, 246, 0.35) 100%);
                        right: unset;
                    }
                    &:nth-child(3) {
                        left: unset;
                        top: calc(330px*2 - 120px);
                        background: linear-gradient(90deg, rgba(49, 128, 246, 0.00) 0%, rgba(49, 128, 246, 0.35) 100%);
                        right: unset;
                    }
                }
            }
        }
        &__how-start {
            padding-right: 5%;
            padding-left: 5%;
            &-topic {
                align-self: flex-start;
            }
        }
        &__questions {
            padding: 140px 5%;
        }
    }
}
</style>
