<template>
    <div class="number-container">
        {{ number }}
    </div>
</template>

<script>
export default {
    props: {
        number: {
            type: Number,
            required: true,
            default: 0,
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

.number-container {
	@include Aspekta-font(750, 96px, 76px, transparent);
	letter-spacing: 3.84px;
    background: linear-gradient(285.65deg, $trial-medium-vulnerability-color 0%, $trial-high-vulnerability-color 103.7%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
</style>
