<template>
    <div>
        <div class="pagination_position">
            <div
                v-if="!compare"
                id="pagination-top"
                class="vulnerability__code-wrapper"
            >
                <div class="vulnerability__sign">
                    Vulnerabilities in the code
                </div>
            </div>
            <Pagination
                v-if="vulnerabilityPage.count > 20 && !compare"
                class="vulnerability__pagination"
                :count="count"
                :limit="limit"
                :show-spinner="showSpinner || refreshLoading"
                :current-page="vulnerabilityPage.page"
                @pageChanged="updatePage"
            />
            <div
                class="code-wrapper"
            >
                <div
                    v-if="vulnerabilities.length > 0 || compare"
                    class="vulnerability__in-code-wrapper"
                >
                    <transition-group
                        name="move-to-folder"
                        :css="isAnimated"
                    >
                        <div
                            v-for="vulnerability in vulnerabilities"
                            :key="vulnerability.id"
                        >
                            <Vulnerability
                                :ref="'vulnerability-' + vulnerability.id"
                                :is-trial-restriction="isTrialRestriction"
                                :vulnerability="vulnerability"
                                :allow-false-positive="allowFalsePositive"
                                :show-buttons="showButtons"
                                :load-page="loadPage"
                                :v-id="vId"
                                :integration-id="integrationId"
                                :current-id="currentId"
                                :compare="vulnerability.format"
                                :compare-page="compare"
                                :is-member="isMember"
                                :is-owner="isOwner"
                                :category="vulnerability.type"
                                :version="version"
                                :current-job="currentJob.data"
                                :screen-width="screenWidth"
                                :folders="filteredFolders"
                                @animated="doAnimated"
                                @vulnerabilityMounted="vulnerabilityMounted"
                                @create-new-folder="openCreateNewFolderPopup"
                                @mouse-enter="handleMouseEnter"
                                @is-mouse-leave="handleMouseLeave"
                            />
                        </div>
                    </transition-group>
                    <div
                        v-if="showSpinner"
                        class="loader_page__style"
                    >
                        <Spinner />
                    </div>
                </div>
            </div>
            <!-- create folder popup -->
            <GeneralPopup
                :title-text="''"
                :popup-id="'CreateNewFolder'"
                :hide-footer="true"
                :hide-header="true"
                :size="'md'"
                @onHidden="handleOnHidden('general-popupCreateNewFolder')"
            >
                <div class="report__create-folder">
                    <img
                        :src="closeModalIcon"
                        alt="close modal"
                        class="modal-close"
                        @click="closeCreateModal('general-popupCreateNewFolder')"
                    >
                    <div class="modal-title">
                        Create New Folder
                    </div>
                    <div class="modal-description">
                        Vulnerability “{{ vulnerabilityTitle }}” will be moved to a new folder
                    </div>
                </div>
                <div class="input_block">
                    <input
                        v-model="newFolderName"
                        name="folderName"
                        class="input create-folder__input"
                        :class="{ error_input: errorsIntegration.folderName || errorsJob.folderName || inputError, active_input: checkForActiveInput(newFolderName) }"
                        type="text"
                        @input="handleInput"
                    >
                    <img
                        v-if="newFolderName.length > 0"
                        class="cross-icon"
                        src="../../assets/images/closeModal_new.svg"
                        alt="cross icon"
                        @click.stop="clearInputAndErrors(['newFolderName'])"
                    >
                    <img
                        v-if="errorsIntegration.folderName || errorsJob.folderName || inputError"
                        class="cross-icon"
                        src="../../assets/images/closeModal_new_error.svg"
                        alt="cross icon"
                        @click.stop="clearInputAndErrors(['newFolderName', 'inputError'])"
                    >
                    <label
                        for="folderName"
                        class="create-folder__label"
                        :class="{ active_input: checkForActiveInput(newFolderName) }"
                    >
                        {{ checkForActiveInput(newFolderName) ? 'Folder Name' : 'Add Folder Name' }}
                    </label>
                    <span
                        id="folderNameError"
                        class="create-folder__error"
                    >
                        <span
                            v-if="errorsIntegration.folderName || errorsJob.folderName"
                            class="create-folder__error-text"
                        >
                            {{ integrationId ? errorsIntegration.folderName : errorsJob.folderName }}
                        </span>
                        <span
                            v-if="inputError"
                            class="create-folder__error-text"
                        >
                            {{ inputError }}
                        </span>
                    </span>
                </div>

                <div class="create-folder__btns">
                    <ConfigurablePopupButton
                        :button-text="'Cancel'"
                        :button-type="'cancel'"
                        :icon="false"
                        :disabled="false"
                        @buttonClick="closeCreateModal('general-popupCreateNewFolder')"
                    />
                    <ConfigurablePopupButton
                        :button-text="'Create Folder'"
                        :button-type="'create'"
                        :icon="false"
                        :disabled="false"
                        @buttonClick="createNewFolder(newFolderName)"
                    />
                </div>
                <div
                    v-if="isShowSpinner"
                    class="spinner__vp-wrapper"
                >
                    <div class="spinner__wp">
                        <Spinner />
                    </div>
                </div>
            </GeneralPopup>
            <!-- cancel move vulnerability to folder -->
            <GeneralPopup
                :title-text="''"
                :popup-id="'UndoMoveToFolder'"
                :hide-footer="true"
                :hide-header="true"
                :size="'md'"
                @onHidden="handleOnHiddenUndo('general-popupUndoMoveToFolder')"
            >
                <div class="report__create-folder__before-move-to">
                    <img
                        :src="createFolderIcon"
                        alt="create folder icon"
                        class="create-folder-icon"
                    >
                    <div class="create-folder-text">
                        Folder {{ newFolderName }} created. Vulnerability moved
                    </div>
                    <button
                        class="undo-create-folder"
                        @click.prevent="undoMoveTo('general-popupUndoMoveToFolder')"
                    >
                        <img
                            :src="undoIcon"
                            class="undo-icon"
                            alt="undo-icon"
                        >
                        Undo
                    </button>
                </div>
            </GeneralPopup>
            <Pagination
                v-if="vulnerabilities.length > 0 && vulnerabilityPage.count > 20 && !compare"
                class="vulnerability__pagination"
                :count="count"
                :limit="limit"
                :show-spinner="showSpinner || refreshLoading"
                :current-page="vulnerabilityPage.page"
                @pageChanged="updatePage"
            />
        </div>
    </div>
</template>

<script>
import Vulnerability from './Vulnerability';
import Spinner from '../../components/Spinner';
import { mapGetters } from 'vuex';
import { FolderNameEnum } from '../../utils/constants/common';
import Pagination from '../Pagination.vue';
import GeneralPopup from '../popups/GeneralPopup';
import ConfigurablePopupButton from '../popups/ConfigurablePopupButton';
import closeModalIcon from '../../assets/images/closeModal_new.svg';
import createFolderIcon from '../../assets/images/folders/add-folder_new.svg';
import undoIcon from '../../assets/images/folders/undo.svg';

export default {
    components: {
        Vulnerability,
        Spinner,
        Pagination,
        GeneralPopup,
        ConfigurablePopupButton,
    },
    props: {
        currentId: {
            type: String,
            default: '',
        },
        isTrialRestriction: {
            type: Boolean,
            default: false,
        },
        allowFalsePositive: {
            type: Boolean,
            default: false,
        },
        showButtons: {
            type: Boolean,
            default: true,
        },
        isMember: {
            type: Boolean,
            default: false,
        },
        isOwner: {
            type: Boolean,
            default: false,
        },
        integrationId: {
            type: String,
            default: '',
        },
        jobId: {
            type: String,
            default: '',
        },
        page: {
            type: Number,
            default: 1,
        },
        vulnerabilities: {
            type: Array,
            default: () => ([]),
        },
        count: {
            type: Number,
            default: 1,
        },
        currentJobFalsePositivesStatistics: {
            type: Object,
            default: () => ({}),
        },
        compare: {
            type: Boolean ,
            default: null,
        },
        category: {
            type: String ,
            default: null,
        },
        version: {
            type: String ,
            default: null,
        },
        versionId: {
            type: String ,
            default: null,
        },
        screenWidth: {
            type : Number ,
            default : 0,
        },
        isRouterQueryUsed: {
            type: Boolean,
            default: false,
        },
        refreshLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            limit: 20,
            currentPage: 1,
            checked: true,
            isShowModal: true,
            vId: this.$route.query.vId,
            isScrollTop: true,
            showSpinner: false,
            branchName: this.$route.params.branchName,
            isAnimated: false,
            // Create folder section
            undoIcon,
            closeModalIcon,
            createFolderIcon,
            isShowSpinner: false,
            resetTimeout: null,
            isCloseCreateFolder: false,
            isCloseUndoModal: false,
            currentQueryFolderName: '',
            newFolderName: '',
            inputError: '',
            vulnerabilityTitle: '',
            vulnerabilityId: '',
        };
    },
    computed: {
        ...mapGetters({
            currentJob: 'jobs/currentJob',
            jobsFolders: 'jobs/folders',
            integrationFolders: 'integrations/integrationFolders',
            vulnerabilityPage: 'jobs/vulnerabilityPage',
            selectedCategory: 'jobs/selectedCategory',
            preventPageLoadDuplicate: 'jobs/preventPageLoadDuplicate',
            isLoading: 'jobs/isLoading',
            errorsIntegration: 'integrations/errors',
            errorsJob: 'jobs/errors',
            isSidebarVisible: 'filters/isSidebarVisible',
            isMovingToFolder: 'folders/isMovingToFolder',
            deductNumber: 'filters/deductNumber',
        }),
        filteredFolders() {
            if (this.compare || (!this.currentJob.data.isOwner && !this.integrationId))
                return [];

            const currentFolder = this.$route.query?.folderName || FolderNameEnum.ALL_VULNERABILITIES;
            let filteredFolders;

            if (this.integrationId) {
                filteredFolders = this.integrationFolders.filter(folder => folder.name !== currentFolder && folder.name !== FolderNameEnum.ALL_VULNERABILITIES);
            } else {
                filteredFolders = this.jobsFolders.filter(folder => folder.name !== currentFolder && folder.name !== FolderNameEnum.ALL_VULNERABILITIES);
            }
            return filteredFolders;
        },
        queryFolderName() {
            return this.$route.query?.folderName || '';
        },
        isCurrentQueryFolderNameChanged() {
            return this.currentQueryFolderName !== this.queryFolderName;
        },
    },
    updated() {
        this.currentPage = this.isCurrentQueryFolderNameChanged ? 1 : this.currentPage;
        this.currentQueryFolderName = this.queryFolderName;
    },
    mounted() {
        this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : this.currentPage;
        this.currentQueryFolderName = this.queryFolderName;
    },
    methods: {
        handleMouseEnter(vulnerabilityCategoryId) {
            this.$emit('mouse-enter', vulnerabilityCategoryId);
        },
        handleMouseLeave() {
            this.$emit('is-mouse-leave', true);
        },
        async updatePage(page) {
            this.isAnimated = false;
            // if category is not selected, scroll to top
            if (!this.selectedCategory) this.toTop();

            // if page is not changed, don't load page
            if (this.currentPage === page) return;

            // if page is changed, load page
            this.currentPage = page;

            // set page to query
            if(Number(this.$route.query?.page) !== this.currentPage) {
                this.$router.replace({ 
                    query: { 
                        ...this.$route.query, 
                        page: this.currentPage,
                    },
                });
            }

            if (!this.preventPageLoadDuplicate && !this.isLoading) await this.loadPage();
            this.$store.commit('jobs/setPreventPageLoadDuplication', false);
        },
        vulnerabilityMounted(id) {
            this.$emit('vulnerabilityMounted', id);
        },
        doAnimated(isAnimated) {
            this.isAnimated = isAnimated;
        },
        toTop() {
            let paginationTop = document.getElementById('pagination-top');
            if (paginationTop) {
                setTimeout(() => {
                    paginationTop.scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }
        },
        
        /**
         * @param {*} disableSpinner if I call the function from another component where is already exist the active spinner
         */
        async loadPage(disableSpinner) {
            if (this.isRouterQueryUsed) return; // don't duplicate page request if '$route.query' watcher in index.vue was triggered
            
            if (!disableSpinner) {
                this.showSpinner = true;
            }
            let categoriesArray = [];
            const originalData = {};

            if (this.$route.query?.categories) {
                const idArray = this.$route.query?.categories.split(',');
                categoriesArray = idArray.map(id => ({ categoryId: id }));
            }
            const key = this.$route.params.id;
            originalData[key] = categoriesArray;

            let payload = {
                id: this.currentId,
                page: this.definePageNumber(),
                limit: this.limit,
                shared: this.shared,
                vulnerabilityId: this.vId,
                integrationId: this.integrationId,
                branchName: this.$route.params.branchName,
                folderName: this.$route.query?.folderName || FolderNameEnum.ALL_VULNERABILITIES,
                reqType: 'GET_PAGE',
                categories: originalData,
            };
            if (payload.page === 0) this.$store.commit('filters/setSidebarVisiblity', false);
            await this.$store.dispatch('jobs/chooseAppropriatePageAction', payload);
            // unset deductNumber to default 1
            this.$store.commit('filters/setDeductNumber', 1);
            this.$store.commit('jobs/setPreventPageLoadDuplication', false);
            this.showSpinner = false;
        },
        definePageNumber() {
            return this.isMovingToFolder
                ? this.currentPage >= Math.ceil((this.vulnerabilityPage.count - this.deductNumber) / this.limit)
                    ? Math.ceil((this.vulnerabilityPage.count - this.deductNumber) / this.limit)
                    : this.currentPage
                : this.currentPage;
        },
        closeModal() {
            this.isShowModal = !this.isShowModal;
        },
        // ⬇︎⬇︎⬇︎ METHODS FOR CREATE FOLDER AND MOVE VULNERABILITY TO THAT FOLDER ⬇︎⬇︎⬇︎
        clearInputAndErrors(itemsToClear) {
            itemsToClear.forEach(item => {
                this[item] = '';
            });
            this.$store.commit('integrations/clearErrors');
            this.$store.commit('jobs/clearErrors');
        },
        handleInput() {
            this.clearInputAndErrors(['inputError']);
        },
        checkForActiveInput(inputValue) {
            return inputValue && inputValue.trim().length >= 0;
        },
        openCreateNewFolderPopup({ vulnerabilityTitle, vulnerabilityId }) {
            this.clearInputAndErrors(['newFolderName', 'inputError']);
            if (vulnerabilityTitle) this.vulnerabilityTitle = vulnerabilityTitle;
            if (vulnerabilityId) this.vulnerabilityId = vulnerabilityId;
            this.$bvModal.show('general-popupCreateNewFolder');
        },
        handleOnHidden(modalId) {
            if (!this.isCloseCreateFolder) {
                this.closeCreateModal(modalId);
            }
            this.isCloseCreateFolder = false;
        },
        handleOnHiddenUndo(modalId) {
            if (!this.isCloseUndoModal) {
                this.undoMoveTo(modalId);
            }
            this.isCloseUndoModal = false;
        },
        closeCreateModal(modalId) {
            this.isCloseCreateFolder = true;
            this.$bvModal.hide(modalId);
            this.clearInputAndErrors(['newFolderName', 'inputError']);
        },
        closeCreateModalWithoutClearErrors(modalId) {
            this.isCloseCreateFolder = true;
            this.$bvModal.hide('general-popupCreateNewFolder');
        },
        async createNewFolder(newFolderName) {
            // create input error "Required field" if input is empty or have only whitespaces
            if (!newFolderName || !newFolderName.trim().length) {
                this.inputError = 'Required field';
                return;
            }

            // create input error "Folder name is too long" if input is more than 50 characters
            if (newFolderName.length > 50) {
                this.inputError = 'Folder name is too long';
                return;
            }

            this.isShowSpinner = true;
            try {
                // for Integration
                let response;
                if (this.integrationId && this.newFolderName) {
                    response = await this.$store.dispatch('integrations/createNewFolder', {
                        integrationId: this.integrationId,
                        name: this.newFolderName,
                    });

                    await this.$store.dispatch('integrations/getFolders', {
                        integrationId: this.integrationId,
                        versionId: this.versionId,
                    });
                }

                // for Single Scan
                else if (this.jobId && this.newFolderName) {
                    response = await this.$store.dispatch('jobs/createNewFolder', {
                        jobId: this.jobId,
                        name: this.newFolderName,
                    });
                    await this.$store.dispatch('jobs/getFolders', { jobId: this.jobId });
                }
                this.isShowSpinner = false;

                response.status === 200
                    ? this.prepareMoveToFolder()
                    : null;
            } catch (error) {
                this.isShowSpinner = false;
                console.log(error);
            }
        },
        prepareMoveToFolder() {
            if (this.resetTimeout) {
                clearTimeout(this.resetTimeout);
            }
            this.closeCreateModalWithoutClearErrors();
            this.$bvModal.show('general-popupUndoMoveToFolder');
            this.resetTimeout = setTimeout(async () => {
                this.isCloseUndoModal = true;
                this.$bvModal.hide('general-popupUndoMoveToFolder');
                this.$refs[`vulnerability-${this.vulnerabilityId}`][0].moveVulnerabilityToFolder(this.newFolderName);
            }, 3000);
        },
        undoMoveTo(modalId) {
            clearTimeout(this.resetTimeout);
            this.isCloseUndoModal = true;
            this.$bvModal.hide('general-popupUndoMoveToFolder');
            this.openCreateNewFolderPopup({ vulnerabilityTitle: this.vulnerabilityTitle, vulnerabilityId: this.vulnerabilityId });
        },
    },
};
</script>
<style lang="scss">
@import '../../assets/css/fonts';
@import '../../assets/css/variables';

.vulnerability__sign {
    @include Aspekta-font(650, 22px, 31px, #021631);
    letter-spacing: 0.66px;
}

.vulnerability__code-wrapper {
    margin: 40px 0px 8px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.vulnerability__pagination {
    justify-content: flex-end;
}

.vulnerability__exception {
    font-family: Cabin, serif;
    font-weight: bold;
    font-size: 24px;
    color: #242424;
    width: 100%;
    text-align: center;
    margin: 100px 0;
}

.vulnerability__in-code-wrapper {
    position: relative;
    height: 100%;
}

.vulnerability__modal-wrapper {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 1000;
    height: 100%;
    max-width: 1120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
}

.vulnerability__modal {
    position: relative;
    max-height: 70vh;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 20px;
    box-shadow: 20px 20px 60px #d9d9d9,
    -20px -20px 60px #ffffff;
}

.vulnerability__modal-close {
    position: absolute;
    top: 30px;
    right: 25px;
    z-index: 1001;
    font-size: 25px;
    height: 40px;
    width: 40px;
    background-color: transparent;
    transition: 0.3s;
    border-radius: 5px;
}

.vulnerability__modal-close:hover {
    background-color: gray;
    color: white;
}

.vulnerability__modal-close:active {
    background-color: black;
    color: white;
}

.vulnerability__modal::-webkit-scrollbar {
    display: none;
}

.loader_page__style {
    position: fixed;
    background: rgba(256, 256, 256, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 1055;
}

.move-to-folder-enter-active,
.move-to-folder-leave-active {
    transition: all 0.7s ease;
}

.move-to-folder-enter-from,
.move-to-folder-leave-to {
    opacity: 0;
    transform: translateX(130px);
}

// Create Folder Popup
.report__create-folder {

    .modal-close {
        display: flex;
        margin-left: auto;
        cursor: pointer;
    }
    .modal-title {
        @include Aspekta-font(600, 18px, normal, #242424);
        letter-spacing: 0.54px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-description {
        @include Aspekta-font(500, 14px, normal, #242424);
        letter-spacing: 0.42px;
        margin-top: 40px;
    }

    &__before-move-to {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .create-folder-icon {
            margin-top: 20px;
        }

        .create-folder-text {
            @include Aspekta-font(400, 14px, 140%, #021631);
            letter-spacing: 0.28px;
            margin: 12px 0 20px;
        }

        .undo-create-folder {
            display: flex;
            flex-direction: row;
            padding: 4px 16px;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            background: #F5F7FA;
            margin-bottom: 20px;
            gap: 4px;

            .undo-icon {
                width: 20px;
                height: 20px;
            }

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.input_block {
    display: flex;
    flex-direction: column;
    position: relative;

    .create-folder {
        &__label {
            @include Aspekta-font(450, 18px, 150%, #949DB8);
            letter-spacing: 0.36px;
            position: absolute;
            top: 59px;
            left: 20px;
            width: 80%;
            transition: font-size 40ms linear, transform 40ms linear;
            -moz-transition: font-size 40ms linear, transform 40ms linear;
            -webkit-transition: font-size 40ms linear, transform 40ms linear;
            z-index: 0;

            &.active_input {
                @include Aspekta-font(400, 14px, 140%, #949DB8);
                letter-spacing: 0.28px;
                transition: none !important;
                transform: none !important;
                top: 17px;
                left: 0px;
            }
        }

        &__input {
            @include Aspekta-font(450, 18px, 150%, #021631);
            letter-spacing: 0.36px;
            margin: 40px 0 30px;
            padding: 10px 45px 10px 17px;
            min-height: 63px;
            position: relative;
            background: transparent;
            z-index: 1;
            outline: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.active_input {
                border: 1px solid #3180F6;
            }

            &.error_input {
                border: 1px solid #FE2027;
            }
        }

        &__error {
            position: absolute;
            top: 102px;

            &-text {
                @include Aspekta-font(400, 12px, 150%, #FE2027);
                letter-spacing: 0.6px;
            }
        }
    }

    .cross-icon {
        position: absolute;
        top: 60px;
        right: 20px;
        z-index: 2;
        cursor: pointer;
    }
}

.create-folder__btns {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
}

// End Create Folder Popup

// Tablet
@media (max-width: 834px) {
    .vulnerability {

        &__code-wrapper {
            margin-top: 20px;
            margin-bottom: 16px;
        }

        &__pagination {
            justify-content: space-between;
        }

        &__in-code-wrapper {
            margin-top: 20px;
        }
    }

    .create-folder__btns {
        flex-direction: column-reverse;
    }
}
</style>
