<template>
    <div>
        <SettingsCompany v-if="userType === 'company'" />
        <Settings v-else-if="userType === 'hacker'" />
        <div
            v-else
            class="settings container"
        >
            <div class="settings__title raleway raleway-bold">
                Settings
            </div>
            <div
                class="settings__link-logout"
                @click="logout"
            >
                Logout
            </div>
        </div>
    </div>
</template>
<script>
import Settings from '../components/settings/Settings';
import SettingsCompany from '../components/settings/SettingsCompany';
import { mapGetters } from 'vuex';

export default {
    components: {
        Settings,
        SettingsCompany,
    },
    computed: {
        ...mapGetters({
            userType: 'user/userType',
        }),
    },
    methods: {
        logout() {
            this.$auth.logout();
            localStorage.removeItem('userType');
        },
    },
};
</script>
<style lang="scss">
.settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 15px;

    &__title {
        font-size: 30px;
        line-height: 150%;
        color: #242424;
    }

    &__link-logout {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        color: #3180F6;
        cursor: pointer;
        text-align: center;

        &:hover {
            text-decoration: underline
        }
    }
}
</style>
