<template>
    <div class="markdown">
        <div>
            <hr>
            <b-nav-item :to="{ path: `${makeLinkToAnchor('uploading')}`}" class="md__link-wrapper">
<!--                <p class="md__anchor">#</p>-->
                <h2 class="md__item-title">Uploading files</h2>
            </b-nav-item>
            <p>To upload integration logos and new version files for Integrations or Single Scans, users need to obtain
                a signed Amazon S3 link, upload the file to it, and pass it to the corresponding endpoint bucket key.
            </p>
            <h3 :id="makeAnchor('Uploading apps')"><a
                :href="makeSubLinkToAnchor('Uploading apps', 'uploading')">Uploading apps</a></h3>
            <p><strong>Endpoint</strong></p>
            <ul>
                <li><code>POST /v1/upload/app</code></li>
            </ul>
            <p><strong>Input parameters</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>platform</td>
                    <td>string</td>
                    <td>app platform</td>
                </tr>
                <tr>
                    <td>file_name</td>
                    <td>string</td>
                    <td>file name of the app version that will be uploaded to the platform</td>
                </tr>
                </tbody>
            </table>
            <p><strong>JSON response</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>bucket_key</td>
                    <td>string</td>
                    <td>Amazon S3 key, needs to be passed in the corresponding query</td>
                </tr>
                <tr>
                    <td>url</td>
                    <td>string</td>
                    <td>signed link where the app file needs to be uploaded</td>
                </tr>
                </tbody>
            </table>
            <p><strong>Using curl</strong></p>
            <div class="code-toolbar"><pre class="  language-metadata"><code
                class="  language-metadata">curl 'https://api.oversecured.com/v1/upload/app' \
  -d '{"platform": "android", "file_name": "app-debug.apk"}' \
  -H "Content-Type: application/json" -X POST \
  -H 'Authorization: {api_token}'
</code></pre>

            </div>
            <p><strong>Example response</strong></p>
            <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
                class="token punctuation">{</span>
  <span class="token property">"bucket_key"</span><span class="token operator">:</span> <span class="token string">"f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/47b72e81-05aa-488b-9876-4f79940ece11/6a573816-857a-432b-af52-7079cb58960f.apk"</span><span
                    class="token punctuation">,</span>
  <span class="token property">"url"</span><span class="token operator">:</span> <span class="token string">"https://redacted.s3.amazonaws.com/f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/47b72e81-05aa-488b-9876-4f79940ece11/6a573816-857a-432b-af52-7079cb58960f.apk?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=AKIAUC5S2Y2XMUGCWT7O%2F20201207%2Fus-east-2%2Fs3%2Faws4_request&amp;X-Amz-Date=20201207T205031Z&amp;X-Amz-Expires=86400&amp;X-Amz-Signature=f6206c1894f321961abe2ac4d956512b315b8fee98c6f959530fde51c014b342&amp;X-Amz-SignedHeaders=host"</span>
<span class="token punctuation">}</span>
</code></pre>

            </div>
            <h3 :id="makeAnchor('Uploading images')"><a :href="makeSubLinkToAnchor('Uploading images', 'uploading')">Uploading images</a></h3>
            <p>The platform supports images of the following types: <code>.png</code>, <code>.jpg</code>
                and
                <code>.jpeg</code>.</p>
            <p><strong>Endpoint</strong></p>
            <ul>
                <li><code>POST /v1/upload/image</code></li>
            </ul>
            <p><strong>Input parameters</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>file_name</td>
                    <td>string</td>
                    <td>file name of the image that will be uploaded to the platform</td>
                </tr>
                </tbody>
            </table>
            <p><strong>JSON response</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>bucket_key</td>
                    <td>string</td>
                    <td>Amazon S3 key, needs to be passed in the corresponding query</td>
                </tr>
                <tr>
                    <td>url</td>
                    <td>string</td>
                    <td>signed link where the image file needs to be uploaded</td>
                </tr>
                </tbody>
            </table>
            <p><strong>Using curl</strong></p>
            <div class="code-toolbar"><pre class="  language-metadata"><code
                class="  language-metadata">curl 'https://api.oversecured.com/v1/upload/image' \
  -d '{"file_name": "logo.png"}' \
  -H "Content-Type: application/json" -X POST \
  -H 'Authorization: {api_token}'
</code></pre>

            </div>
            <p><strong>Example response</strong></p>
            <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
                class="token punctuation">{</span>
  <span class="token property">"bucket_key"</span><span class="token operator">:</span> <span class="token string">"f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/c075b165-c904-4869-b81e-256ecf7f9864.png"</span><span
                    class="token punctuation">,</span>
  <span class="token property">"url"</span><span class="token operator">:</span> <span class="token string">"https://redacted.s3.amazonaws.com/f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/c075b165-c904-4869-b81e-256ecf7f9864.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=AKIAUC5S2Y2XMUGCWT7O%2F20201207%2Fus-east-2%2Fs3%2Faws4_request&amp;X-Amz-Date=20201207T205229Z&amp;X-Amz-Expires=86400&amp;X-Amz-Signature=668b25ce899abdabddf58a9a4c4beb63b2d94c8c5629d77a98a07e871dbfe223&amp;X-Amz-SignedHeaders=host"</span>
<span class="token punctuation">}</span>
</code></pre>

            </div>
        </div>
    </div>
</template>

<script>
import {makeAnchor, makeLinkToAnchor, makeSubLinkToAnchor} from "@/services/functions";

export default {
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor,
    }
}
</script>

<style scoped>

</style>