<template>
    <div v-if="title">
        <hr class="line">
        <div
            :class="{ 'vulnerability-item-active': isDescriptionOpen }"
            class="vulnerability-item"
        >
            <div
                :id="itemId"
                :ref="itemId"
                :href="() => (isDescriptionOpen = !isDescriptionOpen)"
                class="vulnerability-block-title"
                @click.prevent="() => (isDescriptionOpen = !isDescriptionOpen)"
            >
                <span
                    class="dot"
                    :class="colorOfVulnerability(severity)"
                />
                <div class="vulnerability-block-title-column">
                    <a
                        id="vulnerability-title-link"
                        class="vulnerability-title"
                        :href="`${makeLinkToAnchor(title)}`"
                    >
                        {{ title }}
                    </a>
                </div>
                <div
                    class="vulnerability-item__buttons"
                >
                    <Tooltip
                        position="top"
                        mobile-position="left"
                        :tooltip="tooltipCopyText"
                    >
                        <div
                            class="vulnerability-item__buttons-link"
                            @click.stop="onCopyLink(makeLinkToAnchor(title))"
                        >
                            <img
                                src="../../assets/images/vulnerability-copy-link.svg"
                                alt=""
                            >
                        </div>
                    </Tooltip>
                    <div
                        class="vulnerability-item__buttons-btn"
                        :class="{ 'rotate-btn': !isDescriptionOpen }"
                    >
                        <img
                            src="../../assets/images/vulnerability-show-more-btn.svg"
                            alt=""
                        >
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="isDescriptionOpen"
            class="active-description"
        >
            <div
                class="active-description__line"
                :class="colorOfVulnerability(severity)"
            />
            <div class="descrition-wrapper">
                <div class="vulnerability_card_block_text vulnerability_card_block_text--margin">
                    <span class="vulnerability_card_block_title">Vulnerability description</span>
                    <br>
                    <h2>{{ fullDescription }}</h2>
                </div>
                <div class="vulnerability_card_block_text">
                    <span class="vulnerability_card_block_title">Remediation</span>
                    <br>
                    <h2>{{ remediation }}</h2>
                </div>
            </div>
            <div
                v-if="links.length > 0"
                class="vulnerability_card_block_links"
            >
                <span class="vulnerability_card_block_title">Links</span>
                <div
                    v-for="(link, index) in links"
                    :key="index"
                >
                    <a
                        class="vulnerability-link"
                        :href="link"
                    >
                        {{ link }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Tooltip from '../report/components/Tooltip.vue';

export default {
    components: {
        Tooltip,
    },
    props: {
        id: String,
        severity: String,
        position: Number,
        title: String,
        fullDescription: String,
        remediation: String,
        links: Array,
        itemId: String,
        platform: String
    },
    data() {
        return {
            isDescriptionOpen: false,
            vId: this.$route.query.vId,
            showHintCopyLinkLabel: null,
            tooltipCopyText: 'Copy link',
        };
    },
    watch : {
        platform(){
            this.isDescriptionOpen = false;
            this.isCopied=false;
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        levelOfVulnerability(severity) {
            const suffix = 'risk vulnerability';
            let prefix;
            if (severity === 'low') {
                prefix = 'Low';
            } else if (severity === 'medium') {
                prefix = 'Medium';
            } else if (severity === 'high') {
                prefix = 'High';
            }
            return `${prefix} ${suffix}`;
        },
        colorOfVulnerability(severity) {
            if (severity === 'low') {
                return 'green';
            } else if (severity === 'medium') {
                return 'yellow';
            } else if (severity === 'high') {
                return 'red';
            }
        },
        makeAnchor(str) {
            return this.platform+'/'+encodeURIComponent(str)
                .replace(/%20/g, '_');
        },
        makeLinkToAnchor(str) {
            return window.location.origin + '/vulnerabilities' + '#' + this.makeAnchor(str);
        },
        onCopyLink(str) {
            const el = document.createElement('textarea');
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.tooltipCopyText = 'Link Copied!';
            setTimeout(() => this.tooltipCopyText = 'Copy link', 3000);
        },
        /**
         * This method is called when the user scrolls the page. It hides the hint labels.
         */
        handleScroll() {
            this.showHintCopyLinkLabel = null;
        },
    },

};
</script>

<style scoped lang="scss">
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

a#vulnerability-title-link.vulnerability-title {
    color: #000 !important;
    font-size: 18px;
    font-weight: 500;
}

.link {
    position: absolute;
    bottom: 30px;
}

.vulnerability-item {
    cursor: pointer;
    min-height: 90px;
    transition: 0.3s;
    display: flex;
    align-items: center;
}

.vulnerability-item-active,
.vulnerability-item:hover {
    background-color: #FAFBFC;
}

.vulnerability-item__buttons {
    display: flex;
    margin-left: auto;
    gap: 30px;
}

.vulnerability-item__buttons-link {
    position: relative;
    transition: 0.3s;
    border-radius: 20%;
}

.vulnerability-item__buttons-link-message {
    position: absolute;
    top: -32px;
    left: -25px;
    width: 90px;
    text-align: center;
    height: 23px;
    background-color: black;
    border-radius: 3px;
    color: white;
    font-family: Cabin,serif;
    font-size: 14px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vulnerability-item__buttons-link-message::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
    border: 6px solid transparent;
    border-top: 3px solid black;
    z-index: 2;
}

.vulnerability-item__buttons-link:hover,
.vulnerability-item__buttons-btn:hover {
    box-shadow: 0 0 5px black;
    border-radius: 20%;
}

.vulnerability-item__buttons-btn {
    margin-right: 25px;
    transition: 0.3s;
    border-radius: 50%;
}

.vulnerability-item__buttons-btn:hover {
    border-radius: 50%;
}


.dot {
    margin-right: 24px;
    width: 16px;
    height: 16px;
}

.vulnerability-block-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 0;
}

.vulnerability-block-title-column {
    display: flex;
    align-content: center;
    flex-direction: column;
}

.active-description {
    position: relative;
    padding: 30px 70px 55px;
    background-color: #FAFBFC;
}

.active-description__line {
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
}

.descrition-wrapper {
    display: flex;
}

.vulnerability_card_block_text,
.vulnerability_card_block_title {
    font-family: Cabin, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    width: 50%;
    margin-bottom: 20px;
    word-break: break-all;
}

.vulnerability-link {
    font-family: Cabin, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    width: 50%;
    margin-bottom: 20px;
    word-break: break-all;
    color: #3180F6;
}


.vulnerability_card_block_text {
    word-break: break-word;
}

.vulnerability_card_block_text h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    color: #706969;
}

.vulnerability_card_block_title {
    display: inline-block;
    margin-bottom: 25px;
    width: 100%;
}

.vulnerability-link {
    display: inline-block;
    margin: 0 0 12px 0;
    width: 100%;
}

.vulnerability_card_block_text--margin {
    margin-right: 45px;
}

.line {
    margin: 0;
    width: 100%;
}

.rotate-btn {
    transform: rotate(180deg);
}

@media (min-width: 1890px) {
    .vulnerability-title h1 {
        font-size: 18px;
    }

    .vulnerability_card_block_text,
    .vulnerability_card_block_text h2,
    .vulnerability_card_block_title {
        font-size: 16px;
    }

    .vulnerability-link {
        font-size: 16px;
        word-break: break-all;
    }
}

@media (max-width: 770px) {
    .descrition-wrapper {
        flex-direction: column;
    }

    .vulnerability_card_block_text {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .descrition-wrapper {
        flex-direction: column;
    }
    .active-description {
        padding: 20px;
    }

    .vulnerability-block-title-column {
        max-width: 220px;
    }

    .dot {
        margin: 0 15px 0 10px;
    }

    .vulnerability-item__buttons {
        gap: 15px;

        &-btn {
            margin-right: 10px;
        }

        &-link {
            width: 40px;
        }
    }

    .vulnerability-title,
    .vulnerability_card_block_text,
    .vulnerability_card_block_title,
    .vulnerability_card_block_text h2,
    .vulnerability-link {
        font-size: 14px;
    }
}
</style>
