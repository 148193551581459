import Vue from 'vue';
import {store} from './store';
import router from './router.js';

import {
    convertErrorMessageForUI,
    deleteItemsFromLocalStorage,
    setItemsToLocalStorage,
    raiseError
} from '@/services/functions';
import {getAccessTokenHandler} from '@/services/authorization/getAccessToken';
import {loginUserRequest, optCheckRequest} from '@/services/authorization/login';
import {signUpResearcherRequest} from '@/services/authorization/signUpResearcher';
import {signUpCompanyRequest} from '@/services/authorization/signUpCompany';
import {sendForgotPasswordRequest} from '@/services/authorization/sendForgotPassword';
import {logoutRequest} from '@/services/authorization/logout';
import {confirmEmailRequest} from '@/services/authorization/confirmEmail';
import {changeForgotPasswordRequest} from '@/services/authorization/changeForgotPassword';
import {resendConfirmEmailRequest} from '@/services/authorization/resendConfirmEmail';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
    window.history.replaceState({}, document.title, window.location.pathname);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

export const useKeycloakAuth = ({
                                    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
                                    redirectUri = window.location.origin,
                                    ...options
                                }) => {
    if (instance) return instance;

    // The 'instance' is simply a Vue object
    instance = new Vue({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                user: {},
                popupOpen: false,
                error: null
            };
        },
        /** Use this lifecycle method to instantiate the SDK client */
        async created() {
            this.isAuthenticated = !!await getAccessTokenHandler();

            if (this.isAuthenticated) {
                let privacycookie = this.$cookies.get('privacycookie');
                if (!privacycookie) {
                    this.$cookies.set('privacycookie', true, 60 * 60 * 24 * 365);
                    store.commit('user/toggleIsVisibleCookiesNotification', false);
                }
            }

            this.loading = false;
        },
        methods: {
            loginWithRedirect({appState}) {
                // if we went from acceptInvite component
                if (appState.targetUrl.includes('/accept-invite')) {
                    const invitedId = appState.targetUrl.split('/').pop();
                    router.push({name: 'NotAuthenticated', query: {id: invitedId}});
                } else if (appState.redirect) {
                    router.push({name: 'SignIn', query: {redirect: appState.redirect}});
                } else {
                    const query = appState && appState.targetUrl !== 'scans' ? {redirect: appState.targetUrl} : {};
                    router.push({name: 'SignIn', query});
                }
            },
            async optCheck(email) {
                try {
                    store.commit('authentication/clearErrors');
                    const otpCheckRequest = {
                        email: email
                    };
                    return await optCheckRequest(otpCheckRequest);

                } catch (error) {
                    const {errorMessage} = error.response.data;
                    store.commit('authentication/setErrors', convertErrorMessageForUI(errorMessage));
                    raiseError('otp', 'authOtpErrors', 'sign-in__input--error');
                }
            },
            async login(payload) {
                store.commit('authentication/clearErrors');
                try {
                    const response = await loginUserRequest(payload);
                    const accessToken = response.data.access_token;
                    const refreshToken = response.data.refresh_token;
                    const idToken = response.data.id_token || null;

                    setItemsToLocalStorage([
                        ['accessToken', accessToken],
                        ['refreshToken', refreshToken],
                        ['idToken', idToken]
                    ]);

                    this.isAuthenticated = true;
                } catch (error) {
                    const {errorMessage} = error.response.data;
                    let convertedErrorMessage = convertErrorMessageForUI(errorMessage);
                    store.commit('authentication/setErrors', convertedErrorMessage);
                    if (convertedErrorMessage.key === 'otp') {
                        raiseError('otp', 'authOtpErrors', 'sign-in__input--error');
                    } else {
                        raiseError('email', 'authErrors', 'sign-in__input--error');
                        raiseError('password', 'authErrors', 'sign-in__input--error');
                    }
                }
            },
            async signUpResearcher(payload) {
                store.commit('authentication/clearErrors');
                try {
                    return await signUpResearcherRequest(payload);
                } catch (error) {
                    const {errorMessage} = error.response.data;
                    store.commit('authentication/setErrors', convertErrorMessageForUI(errorMessage));
                    raiseError('email', 'authErrors', 'sign-in__input--error');
                }
            },
            async signUpCompany(payload) {
                store.commit('authentication/clearErrors');
                try {
                    await signUpCompanyRequest(payload);
                } catch (error) {
                    const {errorMessage} = error.response.data;
                    store.commit('authentication/setErrors', convertErrorMessageForUI(errorMessage));
                    raiseError('email', 'authErrors', 'sign-in__input--error');
                }
            },
            async sendForgotPassword(payload) {
                store.commit('authentication/clearErrors');
                try {
                    await sendForgotPasswordRequest(payload);
                } catch (error) {
                    const {message} = error.response.data;
                    store.commit('authentication/setErrors', convertErrorMessageForUI(message));
                }
            },
            async confirmEmail(payload) {
                try {
                    await confirmEmailRequest(payload);
                    gtag('event', 'sign_up_confirmed', {
                        event_category: 'engagement',
                        event_label: 'custom',
                        signature: payload.id,
                    });
                } catch (error) {
                    console.log(error, 'error');
                }
            },
            async resendConfirmEmail(payload) {
                try {
                    await resendConfirmEmailRequest(payload);
                } catch (error) {
                    const {message} = error.response.data;
                    store.commit('authentication/setErrors', convertErrorMessageForUI(message));
                }
            },
            async changePassword(payload) {
                try {
                    await changeForgotPasswordRequest(payload);
                } catch (error) {
                    console.log(error, 'error');
                }
            },

            /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
            async getTokenSilently() {
                return await getAccessTokenHandler();
            },

            /** Logs the user out and removes their session on the authorization server */
            async logout() {
                try {
                    const refreshToken = localStorage.getItem('refreshToken');
                    const accessToken = localStorage.getItem('accessToken');

                    if (accessToken && refreshToken) {
                        await logoutRequest(refreshToken);
                    }

                    deleteItemsFromLocalStorage(['accessToken', 'refreshToken', 'idToken', 'userType', 'integrationsCount']);
                    this.isAuthenticated = false;
                    await router.push({name: 'Root'});
                    //use to force reload homepage page after logout to reset vuex store
                    router.go();
                } catch (error) {
                    console.log(error);
                }
            },
            async signIn() {
                return await this.axios.post('/private/ui/users/signin');
            },
            async authHandle() {
                await this.$store.commit('user/isAuthenticated', true);
            },
            async forbidden(query) {
                await router.push({name: 'Forbidden', query: {redirect: query}});
            },

        }
    });

    return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const AuthPlugin = {
    install(Vue, options) {
        Vue.prototype.$auth = useKeycloakAuth(options);
    }
};
