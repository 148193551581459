<template>
    <svg class="error" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="background" fill-rule="evenodd" clip-rule="evenodd" d="M4.92893 4.92893C6.73759 3.12027 9.23882 2 12 2C14.7612 2 17.2624 3.12027 19.0711 4.92893C20.8797 6.73759 22 9.23882 22 12C22 14.7612 20.8797 17.2624 19.0711 19.0711C17.2624 20.8797 14.7612 22 12 22C9.23882 22 6.73759 20.8797 4.92893 19.0711C3.12027 17.2624 2 14.7612 2 12C2 9.23882 3.12027 6.73759 4.92893 4.92893Z"/>
        <path class="main" d="M12 1C8.9629 1 6.21031 2.23334 4.22183 4.22183C2.23334 6.21031 1 8.9629 1 12C1 15.0371 2.23334 17.7897 4.22183 19.7782C6.21031 21.7667 8.9629 23 12 23C15.0371 23 17.7897 21.7667 19.7782 19.7782C21.7667 17.7897 23 15.0371 23 12C23 8.9629 21.7667 6.21031 19.7782 4.22183C17.7897 2.23334 15.0371 1 12 1ZM6.90883 6.90883C8.2138 5.60386 10.0112 4.8 12 4.8C13.9888 4.8 15.7862 5.60386 17.0912 6.90883C18.3961 8.2138 19.2 10.0112 19.2 12C19.2 13.9888 18.3961 15.7862 17.0912 17.0912C15.7862 18.3961 13.9888 19.2 12 19.2C10.0112 19.2 8.2138 18.3961 6.90883 17.0912C5.60386 15.7862 4.8 13.9888 4.8 12C4.8 10.0112 5.60386 8.2138 6.90883 6.90883ZM12 18C13.1045 18 14 17.1046 14 16C14 15.3932 13.7297 14.8495 13.3031 14.4827C13.6706 14.1363 13.9 13.6449 13.9 13.1V7.9C13.9 6.85066 13.0493 6 12 6C10.9506 6 10.1 6.85066 10.1 7.9V13.1C10.1 13.6449 10.3294 14.1363 10.6969 14.4827C10.2702 14.8495 9.99998 15.3932 9.99998 16C9.99998 17.1046 10.8954 18 12 18Z" stroke-width="2"/>
    </svg>
</template>

<script>
export default {
    name: 'ErrorIcon'
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

svg.error {
    .main {
        fill: $main-orange-color;
        stroke: $background-orange-color;
    }
    .background {
        fill: $background-orange-color;
    }
}
</style>