import { render, staticRenderFns } from "./Vulnerability.vue?vue&type=template&id=03fcdd86&scoped=true"
import script from "./Vulnerability.vue?vue&type=script&lang=js"
export * from "./Vulnerability.vue?vue&type=script&lang=js"
import style0 from "./Vulnerability.vue?vue&type=style&index=0&id=03fcdd86&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03fcdd86",
  null
  
)

export default component.exports