<template>
    <div>
        <!-- in viewBox 3rd argument is width 4th argument is height  -->
        <svg :fill="fill" :height="height" viewBox="0 0 20 20" :width="width" xmlns="http://www.w3.org/2000/svg">
            <g clip-rule="evenodd" :fill="fill" fill-rule="evenodd">
                <path d="m12.582 6.73245c.5011.02982.8819.44068.8506.91769l-.5051 7.69226c-.0313.4771-.4629.8396-.964.8098-.5011-.0299-.8819-.4407-.8506-.9177l.505-7.69233c.0314-.47701.463-.83953.9641-.80972z"/>
                <path d="m7.41804 6.73245c-.5011.02982-.88193.44068-.85061.91769l.50505 7.69226c.03132.4771.46293.8396.96403.8098.50109-.0299.88193-.4407.85061-.9177l-.50505-7.69233c-.03132-.47701-.46293-.83953-.96403-.80972z"/>
                <path d="m.909091 2.88462c-.502077 0-.909091.38744-.909091.86538s.407014.86538.909091.86538h1.192349l1.16629 11.84232c.0458.4652.08499.8633.14447 1.1897.06276.3445.15853.6764.35443.9898.29718.4755.73624.8561 1.26132 1.0934.34615.1564.70215.2162 1.06831.2435.34704.0259.76706.0259 1.25795.0259h5.29159c.4909 0 .9109 0 1.2579-.0259.3662-.0273.7222-.0871 1.0684-.2435.525-.2373.9641-.6179 1.2613-1.0934.1959-.3134.2916-.6453.3544-.9898.0595-.3264.0987-.7245.1445-1.1897l1.1663-11.84232h1.1923c.5021 0 .9091-.38744.9091-.86538s-.407-.86538-.9091-.86538h-5.2569c-.4104-1.65387-1.9712-2.88462-3.834-2.88462-1.86278 0-3.42358 1.23075-3.83398 2.88462zm4.165729 13.37948c.04984.506.08298.836.12882 1.0876.04393.2411.08877.3417.12557.4006.11272.1804.27926.3247.47843.4147.06502.0294.17442.0625.43071.0817.26748.0199.61565.0205 1.14956.0205h5.22419c.5339 0 .8821-.0006 1.1495-.0205.2563-.0192.3657-.0523.4308-.0817.1991-.09.3657-.2343.4784-.4147.0368-.0589.0816-.1595.1256-.4006.0458-.2516.0789-.5816.1288-1.0876l1.1472-11.64872h-12.1448zm4.92518-14.53333c-.84618 0-1.57664.47165-1.91708 1.15385h3.83418c-.3405-.6822-1.0709-1.15385-1.9171-1.15385z"/>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    name: "FalsePositiveIcon",
    props: ["svgColor", "svgHeight", "svgWidth"],
    data() {
        return {
            fill: this.svgColor,
            width: this.svgWidth,
            height: this.svgHeight
        }
    },
    methods: {
        setFill(value) {
            this.fill = value;
        }
    }
};
</script>