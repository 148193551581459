
<template>
    <svg
        width="35"
        height="35"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="15"
            cy="15"
            r="15"
            :fill="circleColor"
        />
        <g clip-path="url(#clip0_1570_6957)">
            <path
                d="M16.0834 8.68779C15.9634 8.56652 15.8011 8.49897 15.6323 8.50001C15.4636 8.50105 15.3021 8.57058 15.1835 8.69332C15.0648 8.81606 14.9988 8.98194 14.9998 9.15448C15.0008 9.32702 15.0688 9.49208 15.1889 9.61335L19.8818 14.3543L8.63624 14.3543C8.4675 14.3543 8.30567 14.4228 8.18635 14.5448C8.06703 14.6668 8 14.8322 8 15.0047C8 15.1772 8.06703 15.3427 8.18635 15.4647C8.30567 15.5866 8.4675 15.6552 8.63624 15.6552L19.8805 15.6552L15.1889 20.3942C15.072 20.5161 15.0066 20.68 15.0069 20.8507C15.0072 21.0214 15.0731 21.1851 15.1904 21.3066C15.3077 21.428 15.467 21.4975 15.634 21.4999C15.8009 21.5024 15.9621 21.4377 16.0828 21.3197L21.7638 15.5823C21.8386 15.5067 21.898 15.4167 21.9386 15.3174C21.9791 15.2181 22 15.1117 22 15.0041C22 14.8965 21.9791 14.79 21.9386 14.6908C21.898 14.5915 21.8386 14.5015 21.7638 14.4259L16.0828 8.68779H16.0834Z"
                :fill="arrowColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_1570_6957">
                <rect
                    width="14"
                    height="14"
                    fill="white"
                    transform="matrix(0 1 -1 0 22 8)"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'TopArrowIcon',
    props: {
        clicked: {
            type: Boolean,
            require: true,
        },
    },
    computed: {
        arrowColor() {
            return this.clicked ? '#FFF' : '#0C0D0E';
        },

        circleColor() {
            return this.clicked ? '#FF7A08' : '#FFF';
        },
    },
};
</script>