<template>
    <div class="folder_settings">
        <div class="folder_settings__background">
            <div
                class="folder_settings__close-btn"
                @click="hidePopup"
            >
                <img
                    class="close-btn"
                    :src="closeModal"
                >
            </div>
            <div class="folder_settings__title">
                Manage folders
            </div>
            <div class="folder_settings__sub_title">
                Create custom folders for different scan reports
            </div>
            <div
                ref="FolderList"
                class="folder_settings__list_container"
            >
                <div
                    v-for="(folder, index) in customFolders"
                    :key="folder.id"
                    class="folders_list"
                >
                    <div
                        :ref="`FolderListItem${folder.id}`"
                        class="folders_list_item"
                        :style="isEditFolderName && folderNameIndex === index && 'align-items: flex-start;'"
                    >
                        <div
                            class="folders_list_item_folder"
                            :class="{disabled: isUndoAvailable && undoIndex === index}"
                        >
                            <FolderIcon
                                :ref="`FolderIcon${folder.id}`"
                                :svg-color="folder.isEmptyFolder ? Empty : Default"
                                :svg-width="'24px'"
                                :svg-height="'21px'"
                                alt="folder icon"
                            />
                            <div class="folder_list_vulnerabilities_dots">
                                <div
                                    v-if="checkVulnerabilitySeverityBooleanFunction(folder.statistics, 'high')"
                                    class="red_dot"
                                />
                                <div
                                    v-if="checkVulnerabilitySeverityBooleanFunction(folder.statistics, 'medium')"
                                    class="yellow_dot"
                                />
                                <div
                                    v-if="checkVulnerabilitySeverityBooleanFunction(folder.statistics, 'low')"
                                    class="green_dot"
                                />
                            </div>
                        </div>
                        <div
                            v-if="!isEditFolderName || folderNameIndex !== index"
                            :title="folder.name"
                            :class="{folders_list_name: !isUndoAvailable || undoIndex !== index, folders_list_name_disabled: isUndoAvailable && undoIndex === index, empty: folder.isEmptyFolder}"
                        >
                            {{ folder.name }}
                            <span v-if="isUndoAvailable && undoIndex === index"> deleted</span>
                        </div>
                        <div
                            v-if="(!isUndoAvailable || undoIndex !== index) && (!isEditFolderName || folderNameIndex !== index)"
                            class="folder_actions"
                        >
                            <button
                                class="edit-btn"
                                @click.prevent="setEditFolderName(index, folder)"
                            >
                                <Tooltip
                                    position="left"
                                    mobile-position="left"
                                    :tooltip="tooltipEditText"
                                >
                                    <EditIcon
                                        :svg-color="Default"
                                        :svg-width="'20px'"
                                        :svg-height="'20px'"
                                        alt="edit icon"
                                    />
                                </Tooltip>
                            </button>
                            <button
                                class="delete-btn"
                                @click.stop="openConfirmationPopup(index, folder)"
                            >
                                <Tooltip
                                    position="left"
                                    mobile-position="left"
                                    :tooltip="tooltipDeleteText"
                                >
                                    <div :class="{delete_icon_disabled: isUndoAvailable}">
                                        <DeleteIcon
                                            :svg-color="Default"
                                            :svg-width="'20px'"
                                            :svg-height="'20px'"
                                            alt="delete icon"
                                            class="delete_icon"
                                        />
                                    </div>
                                </Tooltip>
                            </button>
                        </div>
                        <button
                            v-if="isUndoAvailable && undoIndex === index"
                            class="undo_folder__btn"
                            @click.prevent="undoDeleteFolder"
                        >
                            <img
                                class="undo_folder_img"
                                src="../../assets/images/folders/undo-blue.svg"
                                alt="undo-delete"
                            >
                        </button>
                    </div>
                    <div
                        v-if="vulnerabilitiesMoved && targetFolderId === folder.id"
                        class="vulnerabilities_moved"
                    >
                        {{ vulnerabilitiesMoved }} {{ isVulnerabilityAmountPlural(vulnerabilitiesMoved) }} moved
                    </div>
                </div>
            </div>
            <div class="folder_settings__add_folder">
                <button @click="showCreateModal">
                    <CreateFolderIcon
                        :svg-color="'#3180F6'"
                        :svg-width="'25px'"
                        :svg-height="'22px'"
                        alt="add-folder"
                    />
                </button>
                <div
                    class="add_folder__title"
                    @click.prevent="showCreateModal"
                >
                    Create New Folder
                </div>
                <!-- create folder popup -->
                <GeneralPopup
                    ref="CreateFolder"
                    :title-text="''"
                    :popup-id="'CreateFolder'"
                    :hide-footer="true"
                    :hide-header="true"
                    :size="'md'"
                    @onHidden="handleOnHidden"
                >
                    <div class="report__create-folder">
                        <img
                            :src="closeModal"
                            alt="close modal"
                            class="modal-close"
                            @click="closeCreateModal"
                        >
                        <div class="modal-title">
                            Create New Folder
                        </div>
                    </div>
                    <div class="input_block">
                        <input
                            v-model="newFolderName"
                            name="folderName"
                            class="input create-folder__input"
                            :class="{ error_input: errorsIntegration.folderName || errorsJob.folderName || inputError, active_input: checkForActiveInput(newFolderName) }"
                            type="text"
                            @input="handleInput"
                        >
                        <img
                            v-if="newFolderName.length > 0"
                            class="cross-icon"
                            src="../../assets/images/closeModal_new.svg"
                            alt="cross icon"
                            @click.stop="clearInputAndErrors(['newFolderName'])"
                        >
                        <img
                            v-if="errorsIntegration.folderName || errorsJob.folderName || inputError"
                            class="cross-icon"
                            src="../../assets/images/closeModal_new_error.svg"
                            alt="cross icon"
                            @click.stop="clearInputAndErrors(['newFolderName', 'inputError'])"
                        >
                        <label
                            for="folderName"
                            class="create-folder__label"
                            :class="{ active_input: checkForActiveInput(newFolderName) }"
                        >
                            {{ checkForActiveInput(newFolderName) ? 'Folder Name' : 'Add Folder Name' }}
                        </label>
                        <span
                            id="folderNameError"
                            class="create-folder__error"
                        >
                            <span
                                v-if="errorsIntegration.folderName || errorsJob.folderName"
                                class="create-folder__error-text"
                            >
                                {{ integrationId ? errorsIntegration.folderName : errorsJob.folderName }}
                            </span>
                            <span
                                v-if="inputError"
                                class="create-folder__error-text"
                            >
                                {{ inputError }}
                            </span>
                        </span>
                    </div>

                    <div class="create-folder__btns">
                        <ConfigurablePopupButton
                            :button-text="'Cancel'"
                            :button-type="'cancel'"
                            :icon="false"
                            :disabled="false"
                            @buttonClick="closeCreateModal"
                        />
                        <ConfigurablePopupButton
                            :button-text="'Create Folder'"
                            :button-type="'create'"
                            :icon="false"
                            :disabled="false"
                            @buttonClick="createNewFolder(newFolderName)"
                        />
                    </div>
                    <div
                        v-if="showSpinner"
                        class="spinner__vp-wrapper"
                    >
                        <div class="spinner__wp">
                            <Spinner />
                        </div>
                    </div>
                </GeneralPopup>
            </div>
        </div>
        <div
            v-if="showSpinner"
            class="folder_settings__spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>

import { store } from '@/store';
import { mapGetters } from 'vuex';
import Spinner from '../../components/Spinner';
import closeModal from '../../assets/images/closeModal_new.svg';
import ClickOutside from 'vue-click-outside';
import CreateFolderIcon from '../../assets/iconTemplates/CreateFolderIcon';
import FolderIcon from '../../assets/iconTemplates/FolderIcon';
import DeleteIcon from '../../assets/iconTemplates/DeleteIcon';
import EditIcon from '../../assets/iconTemplates/EditIcon';
import EditFolderNameIcon from '../../assets/images/folders/edit-folder-name.svg';
import FolderIconDisabled from '../../assets/images/folders/folder-icon-disabled.svg';
import { checkVulnerabilitySeverityBooleanFunction, isVulnerabilityAmountPlural, isVulnerabilityMovedPlural } from '../../utils/helper-functions';
import GeneralPopup from '../popups/GeneralPopup';
import ConfigurablePopupButton from '../popups/ConfigurablePopupButton';
import Tooltip from './components/Tooltip.vue';
import { successNotification, customSuccessNotification, warningNotification, errorNotification, failNotification } from '@/services/notificationService/notificationService';

export default {
    name: 'FolderSettingsPopUp',
    components: {
        Spinner,
        FolderIcon,
        DeleteIcon,
        EditIcon,
        CreateFolderIcon,
        GeneralPopup,
        ConfigurablePopupButton,
        Tooltip,
    },
    directives: {
        ClickOutside,
    },
    props: {
        customFolders: {
            type: Array,
            default: ()=>[],
        },
        jobId: {
            type: String,
            default: '',
        },
        integrationId: {
            type: String,
            default: '',
        },
        versionId: {
            type: String,
            default: '',
        },
        processingFolder: {
            type: Object,
            default: ()=>({}),
        },
        currentPopUpFolderIndex: {
            type: Number,
            default: null,
        },
        editedFolderName: {
            type: String,  
            default: '',
        },
        folderDeletionAccepted: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            DeleteIcon,
            EditFolderNameIcon,
            FolderIconDisabled,
            closeModal,
            isCloseCreateFolder: false,
            showSpinner: false,
            showName: false,
            timeout: null,
            undoIndex: null,
            undoTimeout: null,
            isUndoAvailable: false,
            folderName: '',
            folderNameIndex: null,
            isEditFolderName: false,
            newFolderName: '',
            vulnerabilitiesMoved: null,
            targetFolderId: null,
            inputError: '',
            tooltipEditText: 'Edit folder',
            tooltipDeleteText: 'Delete folder',
            Hover: '#FFE7AD',
            DefaultText: '#5C6A7A',
            Default: '#949DB8',
            EmptyText: '#5C6A7A',
            Empty: '#949DB8',
        };
    },
    computed: {
        ...mapGetters({
            errorsIntegration: 'integrations/errors',
            errorsJob: 'jobs/errors',
        }),
    },
    watch: {
        customFolders: {
            handler(newVal) {
                newVal.forEach(folder => {
                    // find folder icon ref by folder id
                    const folderIcon = this.$refs[`FolderIcon${folder.id}`] && this.$refs[`FolderIcon${folder.id}`][0];
                    // set folder icon color based on rule
                    folderIcon?.setFill(folder.isEmptyFolder ? this.Empty : this.Default);
                });
            },
        },
        //watch for new folderName 
        editedFolderName(editedName) {
            if (editedName) {
                this.updateFolderName(this.processingFolder.id, editedName);
            }
        },
        //watch for confirmation to delete the folder
        folderDeletionAccepted(isAccepted) {
            if (isAccepted) {
                this.setFolderForDeletion(this.currentPopUpFolderIndex, this.processingFolder);
            }
        },

    },
    methods: {
        clearInputAndErrors(itemsToClear) {
            itemsToClear.forEach(item => {
                this[item] = '';
            });
            store.commit('integrations/clearErrors');
            store.commit('jobs/clearErrors');
        },
        handleInput() {
            this.clearInputAndErrors(['inputError']);
        },
        checkForActiveInput(inputValue) {
            // check if input is not empty and don't have whitespaces
            return inputValue && inputValue.trim().length >= 0;
        },
        hidePopup() {
            this.$store.commit('folders/setFolderSettings', false);
            this.$emit('toggleFolderSettingsPopUp', false);
        },
        hideEditFolder() {
            this.isEditFolderName = false;
            this.folderName = '';
            this.clearInputAndErrors(['folderName', 'inputError']);
        },
        toggleCreateFolder() {
            this.hideEditFolder();
            this.clearInputAndErrors(['newFolderName', 'folderName', 'inputError']);
        },
        async createNewFolder(newFolderName) {
            // create input error "Required field" if input is empty or have only whitespaces
            if (!newFolderName || !newFolderName.trim().length) {
                this.inputError = 'Required field';
                return;
            }

            // create input error "Folder name is too long" if input is more than 50 characters
            if (newFolderName.length > 50) {
                this.inputError = 'Folder name is too long';
                return;
            }

            this.showSpinner = true;
            this.hideEditFolder();
            try {
                // for Integration
                let response;
                if (this.integrationId && this.newFolderName) {
                    response = await this.$store.dispatch('integrations/createNewFolder', {
                        integrationId: this.integrationId,
                        name: this.newFolderName,
                    });

                    response.status === 200 ? this.successNotification('folderCreated') : '';

                    await this.$store.dispatch('integrations/getFolders', {
                        integrationId: this.integrationId,
                        versionId: this.versionId,
                    });
                }

                // for Single Scan
                else if (this.jobId && this.newFolderName) {
                    response = await this.$store.dispatch('jobs/createNewFolder', {
                        jobId: this.jobId,
                        name: this.newFolderName,
                    });

                    response.status === 200 ? this.successNotification('folderCreated') : '';

                    await this.$store.dispatch('jobs/getFolders', { jobId: this.jobId });
                }
                this.showSpinner = false;

                if (response.status === 200) {
                    this.closeCreateModal();
                    this.clearInputAndErrors(['newFolderName', 'folderName', 'inputError']);
                }
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }
        },
        async updateFolderName(folderId, folderName) {
            // create input error "Required field" if input is empty or have only whitespaces
            if (!folderName || !folderName.trim().length) {
                this.inputError = 'Required field';
                return;
            }

            // create input error "Folder name is too long" if input is more than 50 characters
            if (folderName.length > 50) {
                this.inputError = 'Folder name is too long';
                return;
            }
            let response;
            this.showSpinner = true;
            try {
                // for Integration
                if (this.integrationId && folderId && folderName) {
                    response = await this.$store.dispatch('integrations/updateFolderName', {
                        integrationId: this.integrationId,
                        folderId,
                        name: folderName,
                    });

                    await this.$store.dispatch('integrations/getFolders', {
                        integrationId: this.integrationId,
                        versionId: this.versionId,
                    });
                }

                // for Single Scan
                else if (this.jobId && folderId && folderName) {
                    response = await this.$store.dispatch('jobs/updateFolderName', {
                        jobId: this.jobId,
                        folderId,
                        name: folderName,
                    });

                    await this.$store.dispatch('jobs/getFolders', { jobId: this.jobId });
                }
                this.$emit('reload-data');
                this.showSpinner = false;

                // hide edit folder and show notification about updatedFolder if no errors
                if (!this.errorsIntegration.folderName && !this.errorsJob.folderName) {
                    this.hideEditFolder();
                    this.successNotification('folderUpdated') ;
                }

                response.message === 'Request failed with status code 400' ?
                    this.failNotification('alreadyExist') : '';

            } catch (error) {
                this.showSpinner = false;
                this.hideEditFolder();
                console.log(error);
            }
        },
        showCreateModal() {
            this.hideEditFolder();
            this.$emit('toggleFolderSettingsPopUp', false);
            this.$bvModal.show('general-popupCreateFolder');
        },
        closeCreateModal() {
            this.isCloseCreateFolder = true;
            this.$bvModal.hide('general-popupCreateFolder');
            this.$emit('toggleFolderSettingsPopUp', true);
            this.clearInputAndErrors(['newFolderName', 'inputError']);
        },
        handleOnHidden() {
            if (!this.isCloseCreateFolder) {
                this.closeCreateModal();
            }
            this.isCloseCreateFolder = false;
        },
        async deleteFolder(folderId) {
            this.showSpinner = true;
            try {
                // for Integration
                if (this.integrationId && folderId) {
                    let result = await this.$store.dispatch('integrations/deleteFolder', {
                        integrationId: this.integrationId,
                        folderId,
                    });

                    /**
                     * Notify user about folder deletion
                     * 200 - Success;
                     * 207 - Multi-Status: some vulnerabilities were deleted, but not all;
                     * 400 - Bad Request: contains non-deletable vulnerabilities;
                     */
                    if (result.status === 200) {
                        this.successNotification('folderDeleted');
                    } else if (result.status === 207) {
                        this.warningNotification(result.data.message);
                    } else if (result.response.status === 400) {
                        this.errorNotification({ message: result.response.data.message });
                    }

                    await this.$store.dispatch('integrations/getFolders', {
                        integrationId: this.integrationId,
                        versionId: this.versionId,
                    });

                    return result;
                }

                // for Single Scan
                else if (this.jobId && folderId) {
                    let result = await this.$store.dispatch('jobs/deleteFolder', {
                        jobId: this.jobId,
                        folderId,
                    });

                    /**
                     * Notify user about folder deletion
                     * 200 - Success;
                     * else - Error;
                     */
                    result.status === 200 ? this.successNotification('folderDeleted') : this.errorNotification({ message: result.response.data.message });

                    await this.$store.dispatch('jobs/getFolders', { jobId: this.jobId });

                    return result;
                }
                this.showSpinner = false;
                this.closeCreateModal();
            } catch (error) {
                this.showCreateModal();
                this.showSpinner = false;
                console.log(error);
            }
        },
        async moveVulnerabilitiesToFolder(selectedFolder, deletedFolder) {
            this.showSpinner = true;
            let body = this.prepareBodyForMoveVulnerabilitiesToFolder(selectedFolder, deletedFolder);
            let response;
            try {
                response = await this.$store.dispatch('folders/moveAllVulnerabilityToFolder', body);
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }
            return response;
        },
        openConfirmationPopup(index, folder) {
            if(folder.isEmptyFolder) {
                this.$emit('before-delete', index, folder );
            } else {
                this.setFolderForDeletion(index, folder);
            }
        },
        async setFolderForDeletion(index, folder) {
            if (folder.isEmptyFolder) {
                this.hideEditFolder();
                this.isUndoAvailable = true;
                this.undoIndex = index;
                this.undoTimeout = setTimeout( async () => {
                    this.isUndoAvailable = false;
                    let result = await this.deleteFolder(folder.id);
                    result.status === 200 ? this.$emit('deleted', { deletedFolder: folder }) : '';
                    this.showSpinner = false;
                }, 3000);
            } else {
                this.$emit('deleting', { ...folder, index });
            }
        },
        async moveAndDeleteFolder(selectedFolder, deletedFolder) {
            this.hideEditFolder();
            this.isUndoAvailable = true;

            let result;
            if (this.integrationId) {
                // get number of vulnerabilities moved to folder from db
                let body = this.prepareBodyForMoveVulnerabilitiesToFolder(selectedFolder, deletedFolder);
                result = await this.$store.dispatch('folders/getNumberOfMovingVulnerabilities', body);
                this.vulnerabilitiesMoved = result.data.visibleVulnerabilitiesCount + result.data.hiddenVulnerabilitiesCount;
            } else {
                this.vulnerabilitiesMoved = deletedFolder.statistics.reduce((acc, severity) => {
                    return acc += severity.count || 0;
                }, 0);
            }

            this.targetFolderId = selectedFolder.id;
            this.undoIndex = deletedFolder.index;
            this.undoTimeout = setTimeout(async () => {
                this.isUndoAvailable = false;
                this.vulnerabilitiesMoved = null;
                this.targetFolderId = null;
                const response = await this.moveVulnerabilitiesToFolder(selectedFolder, deletedFolder);
                if (response?.status === 200) {
                    this.integrationId
                        ? this.customSuccessNotification(this.prepareSuccessText({
                            visibleVulnerabilitiesCount: result.data.visibleVulnerabilitiesCount,
                            hiddenVulnerabilitiesCount: result.data.hiddenVulnerabilitiesCount,
                        }))
                        : this.customSuccessNotification(this.prepareSuccessText({
                            visibleVulnerabilitiesCount: deletedFolder.statistics.reduce((acc, severity) => {
                                return acc += severity.count || 0;
                            }, 0),
                            hiddenVulnerabilitiesCount: 0,
                        }));
                    this.$emit('reload-data');
                    await this.deleteFolder(deletedFolder.id);
                    this.$emit('deleted', { selectedFolder, deletedFolder });
                } else {
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            }, 5000);
        },
        prepareBodyForMoveVulnerabilitiesToFolder(selectedFolder, deletedFolder) {
            let body = {
                holderId: this.integrationId || this.jobId,
                currentFolderName: deletedFolder.name,
                destinationFolderName: selectedFolder.name,
            };
            if (this.integrationId) {
                body = {
                    ...body,
                    integrationId: this.integrationId,
                    versionId: this.versionId,
                };
            }
            return body;
        },
        prepareSuccessText({ visibleVulnerabilitiesCount, hiddenVulnerabilitiesCount }) {
            let text = '';
            if (visibleVulnerabilitiesCount > 0) {
                text += `${visibleVulnerabilitiesCount} ${this.isVulnerabilityMovedPlural(visibleVulnerabilitiesCount)} moved from the current report`;
            }
            if (hiddenVulnerabilitiesCount > 0) {
                if (text.length > 0) text += ' and '; // To ensure that the "and" is only added when there are visible vulnerabilities too.
                text += `${hiddenVulnerabilitiesCount} ${this.isVulnerabilityMovedPlural(hiddenVulnerabilitiesCount)} moved in other versions`;
            }
            return text;
        },
        undoDeleteFolder() {
            clearTimeout(this.undoTimeout);
            this.isUndoAvailable = false;
            this.undoIndex = null;
            this.vulnerabilitiesMoved = null;
            this.targetFolderId = null;
        },
        setEditFolderName(index, folder) {
            this.$emit('open-edit-popup',index, folder );
        },
   
        checkVulnerabilitySeverityBooleanFunction,
        isVulnerabilityAmountPlural,
        isVulnerabilityMovedPlural,
        failNotification,
        successNotification,
        customSuccessNotification,
        warningNotification,
        errorNotification,
    },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/fonts.scss";
@import "../../assets/css/variables.scss";

@mixin vulnDot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: auto 1.5px;
}
@mixin containerFolder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
}

.folder_settings {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1053;

    /** Background part */
    &__background {
        display: flex;
        flex-direction: column;
        max-height: 90%;
        width: 500px;
        background: #fff;
        border-radius: 16px;
        position: relative;
    }

    &__close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    &__spinner {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 10;
    }
    /** end */

    /** Content part */

    // Header
    &__title {
        @include Aspekta-font(600, 18px, normal, #242424);
        letter-spacing: 0.54px;
        margin: 40px auto 12px;
    }

    &__sub_title {
        @include Aspekta-font(400, 14px, 140%, #706969);
        letter-spacing: 0.28px;
        text-align: center;
        width: 90%;
        padding-bottom: 15px;
        z-index: 10;
        margin: 0 auto;
    }

    // Body (Folder list)
    &__list_container {
        width: 100%;
        max-height: 68vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        // Hide scrollbar for Chrome, Safari and Opera
        &::-webkit-scrollbar {
            display: none;
        }
        // Hide scrollbar for IE, Edge and Firefox
        scrollbar-width: none;

        @mixin folderList {
            display: flex;
            flex-direction: column;
            align-items: left;
            width: 80%;
            margin: 0 auto;
            border-bottom: 1px solid #F0F1F3;
        }

        // folder list exception for first and last item
        .folders_list:first-child {
            @include folderList;
        }

        .folders_list:last-child {
            @include folderList;
            border-bottom: none;
        }


        // folder list all items
        .folders_list {
            @include folderList;
            padding: 15px 0;

            // Hide scrollbar for Chrome, Safari and Opera

            .folders_list_item {
                display: flex;
                align-items: center;
                .folders_list_item_folder {
                    @include containerFolder;
                    &.disabled {
                        opacity: .5;
                    }
                    .folder_list_vulnerabilities_dots {
                        display: flex;
                        flex-direction: row;
                        height: fit-content;
                        margin-top: 8px;
                        .red_dot {
                            @include vulnDot;
                            background: $main-orange-color;
                        }
                        .yellow_dot {
                            @include vulnDot;
                            background: $main-yellow-color;
                        }
                        .green_dot {
                            @include vulnDot;
                            background: $main-green-color;
                        }
                    }
                }
            }

            .folder_actions {
                display: flex;
                margin-left: auto;
                margin-right: 8px;
                position: relative;

                button:nth-child(1) {
                    margin-right: 25px;

                    .edit-folder-btn-title {
                        @extend .btn-title;
                        width: 55px;
                        top: -23px;
                        left: 11px;
                        z-index: 20;
                        visibility: visible;
                    }
                }

                // delete button
                button:nth-child(2){
                    .delete_folder_btn_title {
                        @extend .btn-title;
                        width: 106px;
                        top: -23px;
                        left: 53px;
                        z-index: 20;
                        visibility: visible;

                        &__disabled {
                            @extend .btn-title;
                            width: 106px;
                            top: -45px;
                            left: 37px;
                            z-index: 20;
                            cursor: not-allowed;
                        }
                    }
                }
            }

            .edit_icon {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }

            .edit_icon_disabled {
                width: 20px;
                height: 20px;
                pointer-events: none;
                cursor: not-allowed;
                opacity: 0.5;
            }

            .delete_icon {
                width: 20px;
                height: 20px;
                cursor: pointer;

                &_disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                }

                &_not_allowed {
                    pointer-events: none;
                }
            }

            .folders_list_name {
                @include Aspekta-font(450, 18px, 150%, #5C6A7A);
                letter-spacing: 0.36px;
                // make ... if text is too long
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                // end
                position: relative;
                margin-right: 10px;
                padding-left: 18px;
                &.empty {
                    color: #5C6A7A;
                }

                &_disabled {
                    // @include fontCabin;
                    font-weight: 400;
                    line-height: 20px;
                    font-size: 14px;
                    color: #D9D9D9;
                    position: relative;
                    margin-top: -3px;
                    max-width: 200px;
                    padding-left: 18px;
                    // make ... if text is too long
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // end
                }
            }

            .undo_folder__btn {
                margin-left: auto;
                margin-right: 8px;
                cursor: pointer;
            }

            // edit part
            .edit_folder__input_container {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-left: 16px;
                margin-top: 7px;
                position: relative;

                .edit_folder__input {
                    @include Aspekta-font(450, 18px, 150%, #5C6A7A);
                    border: none;
                    border-bottom: 1px solid #F0F0F0;
                    text-align: left;
                    background: rgba(255,255,255, 0);
                    z-index: 2;

                    &.empty {
                        color: #5C6A7A;
                    }

                    &::placeholder {
                        // @include fontCabin;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 20px;
                        color: red;
                        text-align: left;
                        padding: 5px 0;
                    }

                    &:focus {
                        outline: none;
                    }
                }

                .edit_action_btns {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    margin-top: 26px;

                }
            }
            .vulnerabilities_moved {
                margin-left: 40px;
                align-self: flex-start;
                font-size: 12px;
                font-weight: 400;
                color: #3180F6;
                line-height: 18px;
            }
    }
}

    // Footer (Add folder button)
    &__add_folder {
        width: 80%;
        margin: 0 auto 0;
        padding: 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;


        .add_folder__title {
            @include Aspekta-font(600, 18px, normal, #3180F6);
            letter-spacing: 0.54px;
            margin-left: 15px;
            cursor: pointer;
        }
    }

    // errors
    &__error {
        width: 100%;
        max-width: 831px;
        position: relative;
    }

    &__error-cross {
        position: absolute;
        top: -21px;
        right: 67px;
        cursor: pointer;
        z-index: 3;
    }

    &__error-text {
        @include Aspekta-font(400, 10px, 150%, #FE2027);
        position: absolute;
        top: 0px;
        left: 5px;
        margin: 0;
    }

    /** end */

}

.label {
    @include Aspekta-font(400, 14px, 140%, #949DB8);
    letter-spacing: 0.28px;
    position: absolute;
    top: 5px;
    left: 5px;
    width: 80%;
    transition: font-size 40ms linear, transform 40ms linear;
    -moz-transition: font-size 40ms linear, transform 40ms linear;
    -webkit-transition: font-size 40ms linear, transform 40ms linear;
    z-index: 1;
}

.activeInput{
    @include Aspekta-font(400, 10px, 140%, #949DB8);
    letter-spacing: 0.28px;
    transition: none !important;
    transform: none !important;
    top: -18px;
    left: 0px;
}

.delete_icon {
    display: flex;
    align-items: center;
}

// Create Folder Popup
.report__create-folder {

    .modal-close {
        display: flex;
        margin-left: auto;
        cursor: pointer;
    }
    .modal-title {
        @include Aspekta-font(600, 18px, normal, #242424);
        letter-spacing: 0.54px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.input_block {
    display: flex;
    flex-direction: column;
    position: relative;

    .create-folder {
        &__label {
            @include Aspekta-font(450, 18px, 150%, #949DB8);
            letter-spacing: 0.36px;
            position: absolute;
            top: 59px;
            left: 20px;
            width: 80%;
            transition: font-size 40ms linear, transform 40ms linear;
            -moz-transition: font-size 40ms linear, transform 40ms linear;
            -webkit-transition: font-size 40ms linear, transform 40ms linear;
            z-index: 0;

            &.active_input {
                @include Aspekta-font(400, 14px, 140%, #949DB8);
                letter-spacing: 0.28px;
                transition: none !important;
                transform: none !important;
                top: 17px;
                left: 0px;
            }
        }

        &__input {
            @include Aspekta-font(450, 18px, 150%, #021631);
            letter-spacing: 0.36px;
            margin: 40px 0 30px;
            padding: 10px 45px 10px 17px;
            min-height: 63px;
            position: relative;
            background: transparent;
            z-index: 1;
            outline: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.active_input {
                border: 1px solid #3180F6;
            }

            &.error_input {
                border: 1px solid #FE2027;
            }
        }

        &__error {
            position: absolute;
            top: 102px;

            &-text {
                @include Aspekta-font(400, 12px, 150%, #FE2027);
                letter-spacing: 0.6px;
            }
        }
    }

    .cross-icon {
        position: absolute;
        top: 60px;
        right: 20px;
        z-index: 2;
        cursor: pointer;
    }
}

.create-folder__btns {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
}

// End Create Folder Popup

@media (max-width: 800px) {
    .folder_settings {
        &__background {
            min-width: 90%;
            max-width: 90%;
        }

        &__list_container {
            .folders_list {
                .edit_folder__input_container {
                    .edit_action_btns {
                        flex-direction: column-reverse;
                    }
                }
            }
        }
    }
    
    .create-folder__btns {
        flex-direction: column-reverse;
    }
}
</style>
