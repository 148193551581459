import Vue from 'vue'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/main.scss'
import './assets/css/fonts.scss'

import {BootstrapVue, IconsPlugin, ToastPlugin} from 'bootstrap-vue'
import './assets/css/bootstrapCustomization.scss'
import VueCarousel from 'vue-carousel'
import VueHighlightJS from 'vue-highlightjs'
import xml from 'highlight.js/lib/languages/xml'
import java from 'highlight.js/lib/languages/java'
import swift from 'highlight.js/lib/languages/swift'
import plaintext from 'highlight.js/lib/languages/plaintext'
import 'highlight.js/styles/github.css'
import router from './router'

import store from './store'
import {AuthPlugin} from "./auth";
import axios from './axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import VueLogger from 'vuejs-logger';
import * as Sentry from "@sentry/browser";
import {Vue as VueIntegration} from "@sentry/integrations";
import VueSweetalert2 from 'vue-sweetalert2';
import VueMoment from 'vue-moment';
import VueConfetti from 'vue-confetti';

Vue.use(VueSweetalert2);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ToastPlugin)
Vue.use(VueConfetti)

Vue.use(VueCarousel)

Vue.use(VueHighlightJS, {
    languages: {
        xml,
        java,
        swift,
        plaintext
    }
})

Vue.use(store)

Vue.use(VueMoment);

Vue.use(AuthPlugin, {
    onRedirectCallback: appState => {
        const userTypeFromCookie = Vue.$cookies.get('userType')

        if (userTypeFromCookie === 'company') {
            router.push(
                appState && appState.targetUrl
                    ? 'integrations'
                    : window.location.pathname
            );
        } else {
            router.push(
                appState && appState.targetUrl
                    ? appState.targetUrl
                    : window.location.pathname
            );
        }
    }
});

Vue.use(VueAxios, axios)

Vue.use(VueCookies)

const isProduction = process.env.NODE_ENV === 'production';
const options = {
    isEnabled: !isProduction,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
};

Vue.use(VueLogger, options);

Vue.config.productionTip = false

Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new VueIntegration({Vue, attachProps: true, logErrors: true})],
    ignoreErrors: ["Refused to load the image 'https://www.gstatic.com/images/branding/product/2x/translate_24dp.png'"],
    debug: !isProduction,
    environment: `${process.env.NODE_ENV}-ui`
});

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')


