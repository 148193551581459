<template>
    <div class="markdown">
        <b-nav-item :to="{ path: `${makeLinkToAnchor('profile-and-categories')}`}" class="md__link-wrapper">
            <!--            <p class="md__anchor">#</p>-->
            <h2 class="md__item-title">Profile and Categories</h2>
        </b-nav-item>
        <p>We reveal certain functions of the portal in v1 of the API.
            <b-nav-item class="link" :to="{ path: `/settings`}" href="https://oversecured.com/settings">Adding cards
            </b-nav-item>
            is only possible via the portal, because
            they are stored on Stripe and we never process them ourselves.
        </p>
        <h3 :id="makeAnchor('Obtaining a list of cards')"><a
            :href="makeSubLinkToAnchor('Obtaining a list of cards', 'profile-and-categories')">Obtaining a list of
            cards</a></h3>
        <p><strong>Endpoint</strong></p>
        <ul>
            <li><code>GET /v1/profile/cards</code></li>
        </ul>
        <p><strong>JSON response</strong> Array of</p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>id</td>
                <td>string</td>
                <td>Stripe ID of the card</td>
            </tr>
            <tr>
                <td>brand</td>
                <td>string</td>
                <td>card brand</td>
            </tr>
            <tr>
                <td>last4</td>
                <td>string</td>
                <td>last 4 digits of the card number</td>
            </tr>
            </tbody>
        </table>
        <p><strong>Using curl</strong></p>
        <div class="code-toolbar"><pre class="  language-metadata"><code class="  language-metadata">curl https://api.oversecured.com/v1/profile/cards \
  -H 'Authorization: {api_token}'
</code></pre>

        </div>
        <p><strong>Example response</strong></p>
        <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
            class="token punctuation">[</span><span class="token punctuation">{</span>
  <span class="token property">"id"</span><span class="token operator">:</span> <span class="token string">"card_1HuIasAfmNl9bAedIWiBxKK3"</span><span
                class="token punctuation">,</span>
  <span class="token property">"brand"</span><span class="token operator">:</span> <span
                class="token string">"Visa"</span><span class="token punctuation">,</span>
  <span class="token property">"last4"</span><span class="token operator">:</span> <span
                class="token string">"4242"</span>
<span class="token punctuation">}</span><span class="token punctuation">]</span>
</code></pre>

        </div>
        <h3 :id="makeAnchor('General account data')"><a
            :href="makeSubLinkToAnchor('General account data', 'profile-and-categories')">General account data</a></h3>
        <p><strong>Endpoint</strong></p>
        <ul>
            <li><code>GET /v1/profile/me</code></li>
        </ul>
        <p><strong>JSON response</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>id</td>
                <td>string(uuid)</td>
                <td>user ID</td>
            </tr>
            <tr>
                <td>available_scans</td>
                <td>integer</td>
                <td>the number of available Single Scans, previously purchased or activated using a code</td>
            </tr>
            <tr>
                <td>email</td>
                <td>string</td>
                <td>user email</td>
            </tr>
            <tr>
                <td>account_type</td>
                <td>string</td>
                <td><code>company</code> or <code>hacker</code></td>
            </tr>
            <tr>
                <td>company_info</td>
                <td>object</td>
                <td>data about the company, including name, address and website</td>
            </tr>
            </tbody>
        </table>
        <p><strong>Using curl</strong></p>
        <div class="code-toolbar"><pre class="  language-metadata"><code class="  language-metadata">curl https://api.oversecured.com/v1/profile/me \
  -H 'Authorization: {api_token}'
</code></pre>

        </div>
        <p><strong>Example response</strong></p>
        <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
            class="token punctuation">{</span>
  <span class="token property">"id"</span><span class="token operator">:</span> <span class="token string">"f0fac3d2-5544-4ddd-91c8-15bfd7f087a3"</span><span
                class="token punctuation">,</span>
  <span class="token property">"available_scans"</span><span class="token operator">:</span> <span class="token string">"10"</span><span
                class="token punctuation">,</span>
  <span class="token property">"email"</span><span class="token operator">:</span> <span class="token string">"example@company.com"</span><span
                class="token punctuation">,</span>
  <span class="token property">"account_type"</span><span class="token operator">:</span> <span class="token string">"company"</span><span
                class="token punctuation">,</span>
  <span class="token property">"company_info"</span><span class="token operator">:</span> <span
                class="token punctuation">{</span>
    <span class="token property">"company_name"</span><span class="token operator">:</span> <span class="token string">"Example Inc"</span><span
                class="token punctuation">,</span>
    <span class="token property">"website"</span><span class="token operator">:</span> <span class="token string">"https://example.com/"</span><span
                class="token punctuation">,</span>
    <span class="token property">"address1"</span><span class="token operator">:</span> <span class="token string">"1 Ave"</span><span
                class="token punctuation">,</span>
    <span class="token property">"address2"</span><span class="token operator">:</span> <span class="token string">"Ste 100"</span><span
                class="token punctuation">,</span>
    <span class="token property">"zipcode"</span><span class="token operator">:</span> <span class="token string">"10000"</span><span
                class="token punctuation">,</span>
    <span class="token property">"country"</span><span class="token operator">:</span> <span class="token string">"United States"</span><span
                class="token punctuation">,</span>
    <span class="token property">"state"</span><span class="token operator">:</span> <span class="token string">"NEW YORK"</span><span
                class="token punctuation">,</span>
    <span class="token property">"city"</span><span class="token operator">:</span> <span class="token string">"New York"</span>
  <span class="token punctuation">}</span>
<span class="token punctuation">}</span>
</code></pre>

        </div>
        <h3 :id="makeAnchor('List of categories')"><a
            :href="makeSubLinkToAnchor('List of categories', 'profile-and-categories')">List
            of categories</a></h3>
        <p>Categories supported by the scanning kernel. They are also listed on the
            <b-nav-item class="link" :to="{ path: `/vulnerabilities`}" href="https://oversecured.com/settings">Vulnerabilities
            </b-nav-item> page.
        </p>
        <p><strong>Endpoint</strong></p>
        <ul>
            <li><code>GET /v1/categories?platform={platform}</code></li>
        </ul>
        <p><strong>Input parameters</strong></p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>platform</td>
                <td>string</td>
                <td><code>ios</code> or <code>android</code></td>
            </tr>
            </tbody>
        </table>
        <p><strong>JSON response</strong> <br> <br>Array of</p>
        <table class="table">
            <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>id</td>
                <td>string(uuid)</td>
                <td>category ID</td>
            </tr>
            <tr>
                <td>position</td>
                <td>integer</td>
                <td>position of the category, where a higher position means more critical</td>
            </tr>
            <tr>
                <td>severity</td>
                <td>string</td>
                <td><code>high</code>, <code>medium</code> or <code>low</code></td>
            </tr>
            <tr>
                <td>title</td>
                <td>string</td>
                <td>category title</td>
            </tr>
            <tr>
                <td>description</td>
                <td>string</td>
                <td>description of vulnerabilities in this category</td>
            </tr>
            <tr>
                <td>remediation</td>
                <td>string</td>
                <td>tips on fixing vulnerabilities in this category</td>
            </tr>
            <tr>
                <td>links</td>
                <td>array</td>
                <td>array of links</td>
            </tr>
            </tbody>
        </table>
        <p><strong>Using curl</strong></p>
        <div class="code-toolbar"><pre class="  language-metadata"><code class="  language-metadata">curl https://api.oversecured.com/v1/categories?platform=android \
  -H 'Authorization: {api_token}'
</code></pre>

        </div>
        <p><strong>Example for a single category</strong></p>
        <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
            class="token punctuation">[</span><span class="token punctuation">{</span>
  <span class="token property">"id"</span><span class="token operator">:</span> <span class="token string">"cd85663f-49fd-4838-ba72-80c73aa161ca"</span><span
                class="token punctuation">,</span>
  <span class="token property">"position"</span><span class="token operator">:</span> <span
                class="token number">1050</span><span class="token punctuation">,</span>
  <span class="token property">"severity"</span><span class="token operator">:</span> <span
                class="token string">"high"</span><span class="token punctuation">,</span>
  <span class="token property">"title"</span><span class="token operator">:</span> <span class="token string">"Arbitrary code execution"</span><span
                class="token punctuation">,</span>
  <span class="token property">"description"</span><span class="token operator">:</span> <span class="token string">"Arbitrary code execution gives an attacker unrestricted capabilities and the ability to perform any actions in the context of an attacked application. The attacker thus gains access to all the application's functions and to any sensitive information to which the application has access."</span><span
                class="token punctuation">,</span>
  <span class="token property">"remediation"</span><span class="token operator">:</span> <span class="token string">"To avoid arbitrary code execution, the application should sanitize all data received or change its architecture to prevent unintended access to sensitive components."</span><span
                class="token punctuation">,</span>
  <span class="token property">"links"</span><span class="token operator">:</span> <span
                class="token punctuation">[</span><span class="token string">"https://blog.oversecured.com/Android-arbitrary-code-execution-via-third-party-package-contexts/"</span><span
                class="token punctuation">,</span> <span class="token string">"https://cwe.mitre.org/data/definitions/94.html"</span><span
                class="token punctuation">]</span>
<span class="token punctuation">}</span><span class="token punctuation">]</span>
</code></pre>

        </div>
    </div>
</template>

<script>
import {makeAnchor, makeLinkToAnchor, makeSubLinkToAnchor} from "@/services/functions";

export default {
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor
    }
}
</script>
<style scoped>

</style>
