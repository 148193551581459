<template>
    <div class="markdown">
        <b-nav-item :to="{ path: `${makeLinkToAnchorCICD('circleci')}`}" class="md__link-wrapper">
            <h2 class="md__item-title">Using Oversecured with CircleCI</h2>
        </b-nav-item>
        <p>Oversecured for CircleCI does not yet exist, but you can use Oversecured with CircleCI by following
            these simple points:</p>
        <ol>
            <li>
                <a
                    class="markdown__download-link"
                    @click="handleDownloadFile(`${CONTENT_DOMAIN_NAME}/oversecured.py`, 'oversecured.py')"
                    target="_blank"
                    :href="`${CONTENT_DOMAIN_NAME}/oversecured.py`"
                >
                    Download a Python script
                </a>
                to send the app to be scanned, and
                save it in your project
            </li>
            <li>Set <code>OVERSECURED_API_KEY</code>, <code>OVERSECURED_INTEGRATION_ID</code> and <code>OVERSECURED_BRANCH_NAME</code> Environment
                Variables of your CircleCI project
                <img class="markdown__image"
                     src="../../../assets/images/docs/circleci_env_vars.png"
                     alt="CircleCI Environment Variables">
            </li>
            <li>Install the Python <code>requests</code> module by using the command <code>pip install
                requests</code></li>
            <li>Add a step to your <code>config.yml</code> file to run <code>oversecured.py</code></li>
        </ol>
        <p>You will end up with a config resembling this:</p>
        <pre class="  language-yml"><code class="  language-yml"><span
            class="token key atrule">version</span><span class="token punctuation">:</span> <span
            class="token number">2</span>
<span class="token key atrule">jobs</span><span class="token punctuation">:</span>
  <span class="token key atrule">build</span><span class="token punctuation">:</span>
    <span class="token key atrule">docker</span><span class="token punctuation">:</span>
      <span class="token punctuation">-</span> <span class="token key atrule">image</span><span
                class="token punctuation">:</span> circleci/android<span
                class="token punctuation">:</span>api<span class="token punctuation">-</span><span
                class="token number">27</span>
    <span class="token key atrule">working_directory</span><span class="token punctuation">:</span> ~/repo
    <span class="token key atrule">environment</span><span class="token punctuation">:</span>
      <span class="token key atrule">JVM_OPTS</span><span class="token punctuation">:</span> <span
                class="token punctuation">-</span>Xmx3200m
      <span class="token key atrule">APK_PATH</span><span class="token punctuation">:</span> app/build/outputs/apk/debug/app<span
                class="token punctuation">-</span>debug.apk
    <span class="token key atrule">steps</span><span class="token punctuation">:</span>
      <span class="token punctuation">-</span> checkout
      <span class="token punctuation">-</span> <span class="token key atrule">restore_cache</span><span
                class="token punctuation">:</span>
          <span class="token key atrule">key</span><span class="token punctuation">:</span> jars<span
                class="token punctuation">-</span><span class="token punctuation">{</span><span
                class="token punctuation">{</span> checksum "build.gradle" <span
                class="token punctuation">}</span><span class="token punctuation">}</span><span
                class="token punctuation">-</span><span class="token punctuation">{</span><span
                class="token punctuation">{</span> checksum  "app/build.gradle" <span class="token punctuation">}</span><span
                class="token punctuation">}</span>
      <span class="token punctuation">-</span> <span class="token key atrule">run</span><span class="token punctuation">:</span>
          <span class="token key atrule">name</span><span class="token punctuation">:</span> Build
          <span class="token key atrule">command</span><span class="token punctuation">:</span> ./gradlew assembleDebug
      <span class="token punctuation">-</span> <span class="token key atrule">run</span><span class="token punctuation">:</span>
          <span class="token key atrule">name</span><span class="token punctuation">:</span> Setup Python
          <span class="token key atrule">command</span><span class="token punctuation">:</span> pip install requests
      <span class="token punctuation">-</span> <span class="token key atrule">run</span><span class="token punctuation">:</span>
          <span class="token key atrule">name</span><span class="token punctuation">:</span> Oversecured Scanner
          <span class="token key atrule">command</span><span class="token punctuation">:</span> python ./scripts/oversecured.py $APK_PATH

</code></pre>
        <p>Now your apps can be checked automatically
            <img src="../../../assets/images/docs/circleci_finish.png"
                 class="markdown__image"
                 alt="CircleCI Finish">
        </p>
    </div>
</template>

<script>
import {makeAnchor, makeLinkToAnchor, makeLinkToAnchorCICD} from "../../../services/functions";
import {makeSubLinkToAnchor} from "@/services/functions";
import {handleDownloadFile} from "@/services/downloadFile";

export default {
    data() {
        return {
            CONTENT_DOMAIN_NAME: process.env.VUE_APP_CONTENT_DOMAIN_NAME
        }
    },
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor,
        makeLinkToAnchorCICD,
        handleDownloadFile,
    }
}
</script>

<style scoped>

</style>