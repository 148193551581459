<template>
    <div>
        <b-container class="paddings">
            <b-row>
                <b-col>
                    <h2 class="sign">COOKIES POLICY</h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="last-update">Last updated: April 12, 2021</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="cookies-subtitle">Our Cookies Policy outlines the general policy, practices, and types of cookies
                        that Oversecured may use to improve the service we provide and your experience when visiting our
                        Services. Please refer to our Privacy Policy for additional information and definitions
                        regarding our privacy practices. Cookies are small pieces of text used to store information on
                        web browsers. They are used by many websites to store and receive identifiers and other
                        information on devices such as a mobile phone or computer. Our Services use cookies and other
                        similar technologies (collectively "cookies") in order to provide a better service to you and to
                        generally improve our Services. For example, we may use cookies to help direct you to the
                        appropriate part of our Services by indicating that you are a repeat visitor. We also may use
                        information to present you with resources that are matched to your preferences and to manage and
                        track the effectiveness of our marketing efforts. We also may include tracking pixels, which are
                        small graphic images, in our marketing communications to determine engagement. These cookies may
                        be set by us or by third parties with whom we have partnered to assist in our marketing
                        efforts.
                        <br><br>
                        <br><br>
                        Some portions of our Services are functional without cookies, and you may generally choose
                        whether to accept cookies. Most web browsers are set to accept cookies by default; however, you
                        may be able to delete cookies yourself through your browser's cookie manager. To do so, please
                        follow the instructions provided by your web browser. Please note that disabling cookies will
                        reset your session, disable auto-login, and may adversely affect the availability and
                        functionality of our Services and other resources we can provide to you. To find out more about
                        cookies, including how to see what cookies have been set and how to manage and delete them,
                        visit <a class="cookies__link--blue" href="https://www.allaboutcookies.org/">https://www.allaboutcookies.org/</a>.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">Functional Cookies</p>
                    <p class="content">Functional cookies are necessary to help our Services work as intended. These
                        cookies include, but are not limited to: <br><br>
                        privacycookie – Determines if you have dismissed a notification banner regarding this
                        policy.<br><br>
                        __stripe_mid, __stripe_sid – used for making card payments. The Stripe service uses cookie files
                        that store user information to enable Oversecured to process payments without storing credit
                        card details on its own servers.<br><br>
                        You may disable any of these functional cookies as described above; but if you do, various
                        functions of the Services may be unavailable to you or may not work as intended.
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p class="title">Analytics</p>
                    <p class="content">We use third-party cookies and similar tracking technologies to help us
                        understand how you use our Services and improve your experience. For example, we may use cookies
                        to understand what pages you browsed before submitting a contact request. You can delete these
                        cookies through your browser settings or through the links listed below. The analytics cookies
                        we use include: </p>
                    <ul class="cookies__links-list">
                        <li>
                            Google Analytics – Web traffic tracking. Learn more at
                            <a class="cookies__link--blue" href="https://www.google.com/policies/privacy/">
                                https://www.google.com/policies/privacy/</a>, and to opt out, visit
                            <a class="cookies__link--blue" href="https://tools.google.com/dlpage/gaoptout">
                                https://tools.google.com/dlpage/gaoptout</a>.
                        </li>
                        <li>
                            Marketo (Munchkin) – Analyzes browsing activity to establish a user profile.
                            Learn more at
                            <a class="cookies__link--blue" href="https://legal.marketo.com/cookies/">https://legal.marketo.com/cookies/</a>,
                            and to out opt, visit
                            <a class="cookies__link--blue"
                               href="https://developers.marketo.com/javascript-api/lead-tracking/#opt_out">
                                https://developers.marketo.com/javascript-api/lead-tracking/#opt_out</a>.
                        </li>
                        <li>
                            Bizible – Analyzes browsing activity to establish a user profile. Learn more
                            at <a class="cookies__link--blue"
                                  href="https://www.bizible.com/privacy-policy">https://www.bizible.com/privacy-policy</a>,
                            and to opt out, visit
                            <a class="cookies__link--blue" href="https://www.bizible.com/cookie-opt-out">https://www.bizible.com/cookie-opt-out</a>.
                        </li>
                        <li>
                            TechTarget – Analyzes browsing activity to establish a user profile. Learn
                            more at <a class="cookies__link--blue" href="https://www.techtarget.com/privacy-policy/">https://www.techtarget.com/privacy-policy/</a>
                            and to opt out, visit
                            <a class="cookies__link--blue" href="https://www.techtarget.com/individual-rights-request/">https://www.techtarget.com/individual-rights-request/</a>.
                            Please note that
                            TechTarget will not collect any data when a user is identified by IP address
                            as being based in the EU.
                        </li>
                        <li>
                            Demandbase – Analyzes browsing activity to establish a user profile. Learn
                            more at <a class="cookies__link--blue" href="https://www.demandbase.com/privacy-policy/">https://www.demandbase.com/privacy-policy/</a>.
                        </li>
                        <li>
                            6sense – Analyzes browsing activity to establish a user profile. Learn more
                            at <a class="cookies__link--blue" href="https://6sense.com/privacy-policy/">https://6sense.com/privacy-policy/</a>.
                        </li>
                        <li>
                            Driftt – Analyzes browsing activity to establish a user profile. Learn more at
                            <a class="cookies__link--blue" href="https://www.drift.com/privacy-policy/">https://www.drift.com/privacy-policy/</a>.
                        </li>
                        <li>
                            Uberflip – Analyzes browsing activity to establish a user profile. Learn more
                            at <a class="cookies__link--blue" href="https://www.uberflip.com/legal/#privacy-policy">https://www.uberflip.com/legal/#privacy-policy</a>.
                        </li>
                    </ul>
                    <p class="content">
                        In addition, we may include tracking pixels, which are small graphic images, in our email
                        messages
                        to determine whether the messages were opened and if the links were clicked. If you do not want
                        to
                        receive tracking pixels in emails we send you, you will need to disable HTML images in your
                        email
                        client, but that may affect your ability to view images in other emails you receive.</p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>

.cookies__link--blue {
    color: #1FA8C7;
    word-break: break-word;
}

.sign {
    font-family: Raleway, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: #242424;
    margin: 0;
}

.last-update,
.cookies-subtitle,
.title,
.content {
    font-family: Cabin, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #706969;
    margin-top: 30px;
}

.cookies__links-list {
    font-family: Cabin, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #242424;
}

.cookies-subtitle {
    line-height: 140%;
    color: #242424;
    margin-top: 85px;
}

.title {
    color: #242424;
    line-height: 140%;
    margin: 40px 0 17px 0;
    font-weight: bold;
}

.content {
    line-height: 140%;
    color: #242424;
}

.paddings {
    padding: 130px 100px 78px;
}

@media (max-width: 991px) {
    .paddings {
        padding: 45px 50px 78px;
    }
}

@media (max-width: 376px) {
    .paddings {
        padding: 45px 20px 78px;
    }

    .sign {
        font-size: 30px;
    }

    .last-update,
    .cookies-subtitle,
    .title,
    .content {
        font-size: 14px;
    }

    .cookies__links-list {
        font-size: 14px;
    }
}
</style>
