<template>
    <svg class="branches" :class="variant" width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.71422 8.19363C7.74149 7.56707 8.4285 6.43599 8.4285 5.14272C8.4285 3.17027 6.82952 1.57129 4.85707 1.57129C2.88462 1.57129 1.28564 3.17027 1.28564 5.14272C1.28564 6.43599 1.97265 7.56707 2.99993 8.19363V12.3775C1.97265 13.0041 1.28564 14.1352 1.28564 15.4284C1.28564 16.7217 1.97265 17.8528 2.99993 18.4793V20.5713C2.99993 21.5969 3.83143 22.4284 4.85707 22.4284C5.88271 22.4284 6.71422 21.5969 6.71422 20.5713V18.4795C7.20122 18.1826 7.61122 17.7726 7.90811 17.2856H10.5714C13.0171 17.2856 14.9999 15.3027 14.9999 12.857V8.19363C16.0272 7.56707 16.7142 6.43599 16.7142 5.14272C16.7142 3.17027 15.1152 1.57129 13.1428 1.57129C11.1703 1.57129 9.57136 3.17027 9.57136 5.14272C9.57136 6.43599 10.2584 7.56707 11.2856 8.19363V12.857C11.2856 13.2514 10.9658 13.5713 10.5714 13.5713H7.90811C7.61122 13.0843 7.20122 12.6743 6.71422 12.3774V8.19363ZM4.71422 5.14272C4.71422 5.06384 4.77819 4.99986 4.85707 4.99986C4.93595 4.99986 4.99993 5.06384 4.99993 5.14272C4.99993 5.2216 4.93595 5.28557 4.85707 5.28557C4.77819 5.28557 4.71422 5.2216 4.71422 5.14272ZM12.9999 5.14272C12.9999 5.06384 13.0639 4.99986 13.1428 4.99986C13.2217 4.99986 13.2856 5.06384 13.2856 5.14272C13.2856 5.2216 13.2217 5.28557 13.1428 5.28557C13.0639 5.28557 12.9999 5.2216 12.9999 5.14272ZM4.71422 15.4284C4.71422 15.3496 4.7782 15.2856 4.85707 15.2856C4.93595 15.2856 4.99993 15.3496 4.99993 15.4284C4.99993 15.5073 4.93595 15.5713 4.85707 15.5713C4.77819 15.5713 4.71422 15.5073 4.71422 15.4284Z" stroke-width="2"/>
    </svg>

</template>

<script>
export default {
    name: 'BranchesIcon',
    props: {
        variant: {
            type: String,
            require: true
        }
    }
}
</script>

<style lang='scss'>
@import '@/assets/css/variables.scss';

svg.branches {
    &.created {
        fill: $main-green-color;
        stroke: $background-green-color;
    }
    &.updated {
        fill: $main-blue-color;
        stroke: $background-blue-color;
    }
    &.removed {
        fill: $main-orange-color;
        stroke: $background-orange-color;
    }
}
</style>