var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"limitation-card"},[_c('div',{staticClass:"limitation-card__container"},[_c('div',{staticClass:"limitation-card__content",class:{ 'limitation-card__content-open': _vm.openCardTable }},[_c('div',{staticClass:"limitation-card__text"},[_c('div',{staticClass:"limitation-card__title"},[_c('p',[_vm._v(_vm._s(_vm.cardTitle))])]),_c('div',{staticClass:"limitation-card__description"},[_c('p',[_vm._v(" "+_vm._s(_vm.cardDescription)+" ")])])]),_c('div',{staticClass:"btn_n_table"},[_c('div',{staticClass:"limitation-card__btn"},[_c('button',{class:{ 
                            'limitation-card__btn-pros-and-cons': !_vm.openCardTable, 
                            'limitation-card__btn-clicked': _vm.openCardTable,
                            limitationCircle: _vm.isOpenTable, 
                            limitationOval: !_vm.openCardTable && !_vm.isOpenTable, 
                        },on:{"click":_vm.toggleCardTable,"mouseover":function($event){_vm.isHoverBtn = true},"mouseout":function($event){_vm.isHoverBtn = false}}},[_c('span',{style:(_vm.isOpenTable? 'display: none' : 'display: block')},[_vm._v(" Show Pros & Cons ")]),_c('ArrowIcon',{staticClass:"down-arrow",attrs:{"clicked":_vm.openCardTable,"hovered":_vm.isHoverBtn}})],1)]),_c('div',{class:{ 'limitation-card__table': true, 'open': _vm.openCardTable }},[_c('div',{staticClass:"column"},[_vm._m(0),_c('ul',_vm._l((_vm.pros),function(item,index){return _c('li',{key:index,staticClass:"column-item"},[_c('div',{staticClass:"column-item-title"},[_c('img',{staticClass:"plus-icon small-plus",attrs:{"src":require("../../../assets/images/main/pros-plus-icon.svg"),"alt":"plus"}}),_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"column-item-description"},[_vm._v(" "+_vm._s(item.description)+" ")])])}),0)]),_c('div',{staticClass:"column"},[_vm._m(1),_c('ul',_vm._l((_vm.cons),function(item,index){return _c('li',{key:index,staticClass:"column-item"},[_c('div',{staticClass:"column-item-title"},[_c('img',{staticClass:"minus-icon small-minus",attrs:{"src":require("../../../assets/images/main/cons-minus-icon.svg"),"alt":"minus"}}),_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"column-item-description"},[_vm._v(" "+_vm._s(item.description)+" ")])])}),0)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-title"},[_c('img',{staticClass:"plus-icon",attrs:{"src":require("../../../assets/images/main/pros-plus-icon.svg"),"alt":"plus"}}),_vm._v(" Pros ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-title"},[_c('img',{staticClass:"minus-icon",attrs:{"src":require("../../../assets/images/main/cons-minus-icon.svg"),"alt":"minus"}}),_vm._v(" Cons ")])
}]

export { render, staticRenderFns }