<template>
    <div>
        <PrivacyPolicy />
        <MainMarketApps v-bind:isAuthenticated="isAuthenticated"/>
    </div>
</template>
<script>
import MainMarketApps from "../components/main/MainMarketApps"
import PrivacyPolicy from "../components/privacyPolicy/PrivacyPolicy"

export default {
    components: {
        PrivacyPolicy,
        MainMarketApps
    },
    computed: {
        isAuthenticated: {
            get(){
                return this.$auth.isAuthenticated
            }
        }
    }
}
</script>
