<template>
    <div
        ref="contentWrapper"
        class="main-limitations"
    >
        <div class="main-limitations__container">
            <div class="main-limitations__content">
                <div class="main-limitations__text">
                    <div class="main-limitations__title">
                        <p>Limitations of Current Market Solutions</p>
                    </div>
                    <div class="main-limitations__sub-title">
                        <p>And how companies feel about them</p>
                    </div>
                </div>
                <div
                    v-if="shouldShowCarousel > mobileVersionWidth"
                    class="main-limitations__slider"
                >
                    <carousel
                        ref="carouselRef"
                        :per-page="1"
                        :mouse-drag="false"
                        :navigation-enabled="true"
                        :pagination-color="'rgba(114, 125, 141, 0.5)'"
                    >
                        <slide
                            v-for="(card, index) in cards"
                            :key="index"
                            :data-index="index"
                        >
                            <MarketLimitationCard
                                :card-title="card.title"
                                :card-description="card.description"
                                :pros="card.pros"
                                :cons="card.cons"
                                :is-nav-button-clicked="isNavButtonClicked"
                                :is-dot-button-clicked="isDotButtonClicked"
                            />
                        </slide>
                    </carousel>
                </div>
                <div
                    v-else
                    class="main-limitations__slider"
                >
                    <MarketLimitationCard
                        v-for="(card, index) in cards"
                        :key="index"
                        :card-title="card.title"
                        :card-description="card.description"
                        :pros="card.pros"
                        :cons="card.cons"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MarketLimitationCard from './MarketLimitationCard.vue';
export default {
    components: {
        MarketLimitationCard,
    },

    data() {
        return {
            shouldShowCarousel: window.innerWidth,
            mobileVersionWidth: 1050,
            isNavButtonClicked: false,
            isDotButtonClicked: false,
            currentActiveDot: 0,
            cards: [
                {
                    title: '01. BugBounty Programs',
                    description: 'Finding bugs requires a significant number of skilled researchers. Due to the high demand for talented researchers worldwide, bug bounty programs without relevant rewards may struggle to be effective.',
                    pros: [
                        { title: 'Diverse expertise', description: 'Global researchers\' perspectives' },
                        { title: 'Third-party validation', description: 'External researchers validate security measures independently' },
                        { title: 'Reputation boost', description: 'Implementing bug bounty programs can enhance an organization\'s reputation' },
                    ],
                    cons: [
                        { title: 'Post-release discovery', description: 'Vulnerabilities may be found after app release, creating a potential risk of exploitation before recognition' },
                        { title: 'Lack of skilled researchers', description: 'A shortage of experts poses a challenge to bug bounty effectiveness' },
                        { title: 'High competition for researchers', description: 'Companies need to compete with other bug bounty programs in the market, requiring higher rewards to attract researchers effectively' },
                        { title: 'False positives', description: 'Bug bounty programs may generate numerous false positives, leading to significant time and effort spent on verifying each report for your team' },
                    ],
                },
                {
                    title: '02. Security Audits',
                    description: 'Hiring outside firms can be a pain. It\'s usually expensive, and you don\'t have much control, making it hard to get the desired results.',
                    pros: [
                        { title: 'Expertise', description: 'Outside firms potentially provide unbiased evaluations of the company\'s security measures.' },
                        { title: 'Time-saving', description: 'Outsourcing tasks potentially frees up internal resources for other essential projects' },
                    ],
                    cons: [
                        { title: 'Lack of control', description: 'Reduced ability to direct the audit process, potentially leading to gaps in the assessment and overlooked vulnerabilities' },
                        { title: 'Delayed response time', description: 'Audits requiring extensive time to identify vulnerabilities and prepare recommendations pose a risk of potential exploitation for an extended period, leaving the company exposed to security threats for a longer duration' },
                        { title: 'Expertise and cost concerns', description: 'External firms\' capabilities and expertise may vary, affecting the audit\'s quality and leading to potentially high costs that can strain the company\'s security budget' },
                        { title: 'Integration issues', description: 'Challenges in implementing audit recommendations' },
                    ],
                },
                {
                    title: '03. Open Source Tools',
                    description: 'Open-source tools don\'t have customer support and may not work well with other software. They might not perform well in finding vulnerabilities and also have extremely limited functionality compared to commercial options',
                    pros: [
                        { title: 'Free to use', description: 'No costs involved' },
                        { title: 'Ease of use', description: 'Some open-source tools are really easy to use' },
                        { title: 'Transparency', description: 'Source code accessible and verified by the community' },
                    ],
                    cons: [
                        { title: 'Vulnerability gaps', description: 'Most open-source tools may miss up to 85% of potential vulnerabilities, requiring extra security measures for full protection.' },
                        { title: 'Limited functionality', description: 'Fewer features compared to commercial options' },
                        { title: 'Lack of support', description: 'No dedicated customer support' },
                        { title: 'Compatibility issues', description: 'It may not work well with other software' },
                    ],
                },
            ],
        };
    },

    mounted() {
        this.$nextTick(() => {
            const carouselRef = this.$refs.carouselRef;

            if (carouselRef && carouselRef.$el) {
                const dots = carouselRef.$el.querySelectorAll('.VueCarousel-dot');

                dots.forEach(dot => {
                    dot.addEventListener('click', this.handleDotClick);
                });

                const prevButton = carouselRef.$el.querySelector('.VueCarousel-navigation-prev');
                const nextButton = carouselRef.$el.querySelector('.VueCarousel-navigation-next');

                this.addEventListeners(prevButton);
                this.addEventListeners(nextButton);
            }
        });

        window.addEventListener('resize', this.handleResize);
    },

    destroyed() {
        const carouselRef = this.$refs.carouselRef;

        if (carouselRef && carouselRef.$el) {
            const prevButton = carouselRef.$el.querySelector('.VueCarousel-navigation-prev');
            const nextButton = carouselRef.$el.querySelector('.VueCarousel-navigation-next');
            const dotButtons = carouselRef.$el.querySelectorAll('.VueCarousel-dot');

            this.removeEventListeners(prevButton);
            this.removeEventListeners(nextButton);

            dotButtons.forEach(dot => this.removeDotEventListeners(dot));
        }

        window.removeEventListener('resize', this.handleResize);
    },


    methods: {
        addEventListeners(button) {
            if (button) {
                button.addEventListener('mouseup', this.handleMouseUp);
                button.addEventListener('mousedown', this.handleMouseDown);
            }
        },
        removeEventListeners(button) {
            if (button) {
                button.removeEventListener('mousedown', this.handleMouseDown);
                button.removeEventListener('mouseup', this.handleMouseUp);
            }
        },
        removeDotEventListeners(dot) {
            if (dot) {
                dot.removeEventListener('click', this.handleDotClick);
            }
        },
        handleResize() {
            this.shouldShowCarousel = window.innerWidth;
        },
        handleDotClick() {
            const carouselRef = this.$refs.carouselRef;
            const contentWrapper = this.$refs.contentWrapper;
            const activeDot = carouselRef.$el.querySelector('.VueCarousel-dot--active');
            const value = activeDot.getAttribute('value');
            const match = value.match(/\d+/);

            if (match) {
                const numberValue = parseInt(match[0], 10);
                if (numberValue !== this.currentActiveDot) {
                    const amount = -275 * (this.currentActiveDot - numberValue);
                    this.moveBackground(contentWrapper, amount);
                    this.currentActiveDot = numberValue;
                    this.isDotButtonClicked = true;
                }
            }
            this.$nextTick(() => {
                this.isDotButtonClicked = false;
            });

        },
        handleMouseDown(event) {
            if(!event.target.classList.contains('VueCarousel-navigation--disabled')) {
                this.isNavButtonClicked = true;
            }
        },

        handleMouseUp() {
            this.isNavButtonClicked = false;
            const carouselRef = this.$refs.carouselRef;
            const activeDot = carouselRef.$el.querySelector('.VueCarousel-dot--active');
            const value = activeDot.getAttribute('value');
            const match = value.match(/\d+/);

            const contentWrapper = this.$refs.contentWrapper;
            const prevButton = carouselRef.$el.querySelector('.VueCarousel-navigation-prev');
            const nextButton = carouselRef.$el.querySelector('.VueCarousel-navigation-next');

            if (event.target === prevButton && !prevButton.classList.contains('VueCarousel-navigation--disabled')) {
                this.moveBackground(contentWrapper, -275);
                if(match) {
                    const numberValue = parseInt(match[0], 10);
                    this.currentActiveDot = numberValue - 1;
                }
            } else if (event.target === nextButton && !nextButton.classList.contains('VueCarousel-navigation--disabled')) {
                this.moveBackground(contentWrapper, 275);
                if(match) {
                    const numberValue = parseInt(match[0], 10);
                    this.currentActiveDot = numberValue + 1;
                }
            }

        },

        moveBackground(element, amount) {
            const pseudoElement = window.getComputedStyle(element, '::before');
            const currentBackgroundPosition = parseInt(pseudoElement.backgroundPositionX, 10);
            element.style.setProperty('--background-position-x', (currentBackgroundPosition + amount) + '%');
        },
    },

};
</script>
<style lang="scss">
@import "../../../assets/css/variables";
@import "../../../assets/css/fonts";

.main-limitations {
    --background-position-x: 40%;

    position: relative;
    background-color: #F6F6F9;
    width: 100%;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 5000px;
        height: 1080px;
        background-image: url('../../../assets/images/main/main-limitations-block-background.svg');
        background-repeat: no-repeat;
        background-position: var(--background-position-x) 80%;
        transition: background-position 0.5s;
    }

    &__container {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin: 7% 10%;
        justify-content: center;
        align-items: center;
        gap: 4rem;
    }

    &__text {
        display: flex;
        flex-direction: column;
        width: 50%;
        text-align: center;
        z-index: 2;
    }

    &__slider {
        display: flex;
        flex-direction: column;
        width: 75%;
    }

    &__title {
        @include Aspekta-font(700, $dynamic-main-risks-title-font-size, 130%, #0C0D0E);
        letter-spacing: 0em;
    }

    &__sub-title {
        @include Aspekta-font(400, $dynamic-main-limitations-sub-title-font-size, normal, #797E86);
        letter-spacing: 0em;
    }

    .VueCarousel-navigation {
        position: absolute;
        top: 140px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .VueCarousel-navigation-prev, .VueCarousel-navigation-next {
        background-size: contain;
        background-repeat: no-repeat;
        text-indent: -9999px;
        height: 64px;
        width: 64px;
        transform: none;
    }

    .VueCarousel-navigation-prev:hover, .VueCarousel-navigation-next:hover {
        opacity: 0.7;
    }

    .VueCarousel-navigation-prev {
        position: relative;
        background-image: url('../../../assets/images/main/main-slider-arrow-left.svg');
        left: -10% !important;
    }

    .VueCarousel-navigation-prev.VueCarousel-navigation--disabled {
        background-image: url('../../../assets/images/main/main-slider-arrow-left-disable.svg');
        opacity: 1;
    }
    .VueCarousel-navigation-next.VueCarousel-navigation--disabled {
      background-image: url('../../../assets/images/main/main-slider-arrow-right-disable.svg');
      opacity: 1;
    }

    .VueCarousel-navigation-next {
        position: relative;
        background-image: url('../../../assets/images/main/main-slider-arrow-right.svg');
        right: -10% !important;
    }

}

@media (max-width: 1100px) and (min-width: 768px) {
     .main-limitations {
        .VueCarousel-navigation-prev {
            left: -15% !important;
        }

        .VueCarousel-navigation-next {
            right: -15% !important;
        }

    }

}

@media (max-width: 1050px) {
    .main-limitations {

        &::before {
            background-position: 7% -55px;
        }

        &__content {
            margin: 5rem 1.25rem;
            gap: 2rem;
        }

        &__text {
            width: 100%;
        }

        &__slider {
            width: 100%;
            gap: 15px;
        }
    }
}

</style>
