import Vue from 'vue';
import Router from 'vue-router';
import {
    authGuard,
    authGuardGuest,
    isTypeCompany,
    companyGuard,
    checkStatusIntegration,
    checkAccessToken,
    signOut,
    beforeEnterAuthPage,
    beforeForbiddenPage,
    hackerGuard,
} from './authGuard';
import {store} from './store/index';
import initWebsocketConnection from './initWebsocketConnection';
import multiguard from 'vue-router-multiguard';

import Index from './pages/index';
import PageNotFound from './pages/errorPage';
import ForbiddenPage from './pages/ForbiddenPage';
import Terms from './pages/terms';
import About from './pages/about';
import Pricing from './pages/pricing';
import listIntegrations from './pages/scans/integration/listIntegrations';
import PrivacyPolicy from './pages/privacyPolicy';
import Vulnerabilities from './pages/vulnerabilities';
import CookiesPolicy from './pages/cookies';
import Scans from './pages/scans/index';
import ScanInfo from './pages/scans/_id/index';
import NewScan from './pages/scans/NewScan';
import IntegrationInviteAccept from './pages/scans/integration/IntegrationInviteAccept';
import NotAuthenticated from './pages/NotAuthenticated';
import QuickAuthorization from './pages/auth/quickAuthorization';
import NewIntegration from './components/newIntegration/NewIntegration';
import InnerIntegrations from './pages/scans/integration/innerIntegrations';
import VersionsComparison from './pages/scans/integration/versionsComparison';
import NewVersionIntegration from './pages/scans/integration/NewVersionIntegration';
import IntegrationSettings from '@/components/innerIntegration/IntegrationSettings';
import IntegrationSettingsContainer from './pages/scans/integration/IntegrationSettingsContainer.vue';
import IntegrationMembers from '@/components/innerIntegration/IntegrationMembers';
import IntegrationWebhooks from '@/components/innerIntegration/IntegrationWebhooks';
import profileDataToUpgradeToCompany from './pages/profileDataToUpgradeToCompany';
import SettingsPage from './pages/SettingsPage';
import contactUs from '@/pages/contactUs';
import APISpecification from '@/pages/APISpecification';
import IntroductionMD from '@/components/markdowns/APIDocs/IntroductionMD';
import ProfileAndCategoriesMD from '@/components/markdowns/APIDocs/ProfileAndCategoriesMD';
import IntegrationsMD from '@/components/markdowns/APIDocs/IntegrationsMD';
import SingleScanMD from '@/components/markdowns/APIDocs/SingleScanMD';
import UploadingFilesMD from '@/components/markdowns/APIDocs/UploadingFilesMD';
import WorkingWithVulnerabilitiesMD from '@/components/markdowns/APIDocs/WorkingWithVulnerabilitiesMD';
import chooseRole from '@/pages/auth/chooseRole';
import signUpResearcher from '@/pages/auth/signUpResearcher';
import signUpCompany from '@/pages/auth/signUpCompany';
import signIn from '@/pages/auth/signIn';
import forgotPassword from '@/pages/auth/forgotPassword';
import newPassword from '@/pages/auth/newPassword';
import confirmEmailCode from '@/pages/auth/confirmEmailSuccess';
import confirmEmailMessage from '@/pages/auth/confirmEmailMessage';
import BitriseMD from '@/components/markdowns/CI_CD/BitriseMD';
import CICDIndexMD from '@/components/markdowns/CI_CD/CICDIndexMD';
import JenkinsMD from '@/components/markdowns/CI_CD/JenkinsMD';
import GradleMD from '@/components/markdowns/CI_CD/GradleMD';
import CircleCIMD from '@/components/markdowns/CI_CD/CircleCIMD';
import TravisMD from '@/components/markdowns/CI_CD/TravisMD';
import Configuration_Introduction_MD from '@/components/markdowns/ScanConfigurations/Configuration_Introduction_MD';
import Configuration_iOS_MD from '@/components/markdowns/ScanConfigurations/Configuration_iOS_MD';
import Configuration_SmartFilter_MD from '@/components/markdowns/ScanConfigurations/Configuration_SmartFilter_MD';
import Webhooks_MD from '@/components/markdowns/Webhooks/Webhooks_MD';
import ProfileInfo from './components/settings/ProfileInfo.vue';
import IntegrationHistory from './components/settings/IntegrationHistory.vue';
import CurrentIntegration from './components/settings/CurrentIntegration.vue';
import ScansHistory from './components/settings/ScansHistory.vue';
import CardsBlock from './components/settings/CardsBlock.vue';
import APIKeys from './components/settings/APIKeys.vue';
import Security from './components/settings/Security.vue';
import QuickStartMD from './components/markdowns/QuickStart/QuickStartMD';
import Sessions from './components/settings/Sessions';
import ScanPacks from './pages/scanPacks';
import ScanPacksBlackFriday from './pages/scanPacksBlackFriday';
import ScanPacksProfile from './components/settings/ScanPacks';
import PaymentHistory from './components/settings/PaymentHistory';
import SingleScansPaymentHistory from './components/settings/SingleScansPaymentHistory';
import ScanPacksPaymentHistory from './components/settings/ScanPacksPaymentHistory';
import InnerBranches from './pages/scans/integration/innerBranches';
import {defaultBranchRedirect} from './integrationRouteHelper';
import GetStarted from '@/components/getStarted/GetStarted';
import Partner from '@/pages/partner/index';
import RequestDemo from '@/components/requestDemo/RequestDemo';
import { closeSideBar } from './utils/helper-functions';


Vue.use(Router);

const ABOUT_DESCRIPTION = 'Oversecured is a company working on mobile app security. Our goal is to improve protection for the whole field, by researching and uncovering mobile vulnerabilities, automating the search for them, and also educating developers.';
const CONTACT_US_DESCRIPTION = 'Oversecured is a company working on mobile app security. Our goal is to improve protection for the whole field, by researching and uncovering mobile vulnerabilities, automating the search for them, and also educating developers.';
const routes = [
    {
        path: '/',
        name: 'Root',
        components: {
            default: Index,
        },
        meta: { title: 'Automated Mobile App Security | Oversecured' },
    },
    {
        path: '/403',
        name: 'Forbidden',
        component: ForbiddenPage,
        beforeEnter: beforeForbiddenPage,
        meta: { title: 'Forbidden | Oversecured' },
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: { title: 'Page not found | Oversecured' },
    },
    {
        path: '/not-authenticated',
        name: 'NotAuthenticated',
        components: {
            default: NotAuthenticated,
        },
        beforeEnter: beforeEnterAuthPage,
        meta: { title: 'Not Authenticated | Oversecured' },
    },
    {
        path: '/quick-authorization',
        name: 'QuickAuthorization',
        components: {
            default: QuickAuthorization,
        },
        meta: { title: 'Quick Authorization | Oversecured' },
    },
    {
        path: '/sign-up',
        name: 'Authentication',
        components: {
            default: chooseRole,
        },
        beforeEnter: beforeEnterAuthPage,
        meta: { title: 'Sign Up | Oversecured' },
    },
    {
        path: '/sign-up/hacker',
        name: 'SignUpResercher',
        components: {
            default: signUpResearcher,
        },
        beforeEnter: beforeEnterAuthPage,
        meta: { title: 'Sign Up — Researcher | Oversecured' },
    },
    {
        path: '/sign-up/company',
        name: 'SignUpCompany',
        components: {
            default: signUpCompany,
        },
        beforeEnter: beforeEnterAuthPage,
        meta: { title: 'Sign Up — Company | Oversecured' },
    },
    {
        path: '/sign-in',
        name: 'SignIn',
        components: {
            default: signIn,
        },
        beforeEnter: beforeEnterAuthPage,
        meta: { title: 'Sign In | Oversecured' },
        props: true,
    },
    {
        path: '/sign-out',
        name: 'SignOut',
        meta: { title: 'Sign Out | Oversecured' },
        beforeEnter: multiguard([signOut]),
        props: true,
    },
    {
        path: '/forgot-password',
        name: 'sendForgotPassword',
        components: {
            default: forgotPassword,
        },
        beforeEnter: beforeEnterAuthPage,
        meta: { title: 'Forgot Password | Oversecured' },
    },
    {
        path: '/reset-password/:signature',
        name: 'NewPassword',
        components: {
            default: newPassword,
        },
        beforeEnter: beforeEnterAuthPage,
        meta: { title: 'New Password | Oversecured' },
    },
    {
        path: '/confirm-email/confirmation/:signature',
        name: 'ConfirmEmailConfirmation',
        components: {
            default: confirmEmailCode,
        },
        beforeEnter: beforeEnterAuthPage,
        meta: { title: 'Confirm Email | Oversecured' },
    },
    {
        path: '/confirm-email/link',
        name: 'ConfirmEmailLink',
        components: {
            default: confirmEmailMessage,
        },
        beforeEnter: beforeEnterAuthPage,
        meta: { title: 'Confirm Email | Oversecured' },
    },
    {
        path: '/pricing',
        name: 'Pricing',
        components: {
            default: Pricing,
        },
        meta: { title: 'Pricing | Oversecured' },
    },
    {
        path: '/cookies',
        name: 'CookiesPolicy',
        components: {
            default: CookiesPolicy,
        },
        meta: { title: 'Cookies Policy | Oversecured' },
    },
    {
        path: '/privacy',
        name: 'PrivacyPolicy',
        components: {
            default: PrivacyPolicy,
        },
        meta: { title: 'Privacy Policy | Oversecured' },
    },
    {
        path: '/partner',
        name: 'Partner',
        components: {
            default: Partner,
        },
        meta: { title: 'Partner | Oversecured' },
    },
    {
        path: '/about',
        name: 'About',
        components: {
            default: About,
        },
        meta: {
            title: 'About | Oversecured',
            metaTags: [
                {
                    name: 'description',
                    content: ABOUT_DESCRIPTION,
                },
                {
                    property: 'og:description',
                    content: ABOUT_DESCRIPTION,
                },
                {
                    property: 'twitter:description',
                    content: ABOUT_DESCRIPTION,
                },
            ],
        },
    },
    {
        path: '/terms',
        name: 'Terms',
        components: {
            default: Terms,
        },
        meta: { title: 'Terms of Use | Oversecured' },
    },
    {
        path: '/vulnerabilities',
        name: 'Vulnerabilities',
        components: {
            default: Vulnerabilities,
        },
        meta: { title: 'Vulnerabilities | Oversecured' },
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        components: {
            default: contactUs,
        },
        meta: {
            title: 'Contact Us | Oversecured',
            metaTags: [
                {
                    name: 'description',
                    content: CONTACT_US_DESCRIPTION,
                },
                {
                    property: 'og:description',
                    content: CONTACT_US_DESCRIPTION,
                },
                {
                    property: 'twitter:description',
                    content: CONTACT_US_DESCRIPTION,
                },
            ],
        },
    },
    {
        path: '/docs/',
        name: 'api',
        components: {
            default: APISpecification,
        },
        beforeEnter: multiguard([authGuard]),
        children: [
            {
                path: 'api/',
                component: IntroductionMD,
                meta: { title: 'Introduction — API Documentation | Oversecured' },
            },
            {
                path: 'api/profile-and-categories',
                component: ProfileAndCategoriesMD,
                meta: { title: 'Profile and Categories — API Documentation | Oversecured' },
            },
            {
                path: 'api/integrations',
                component: IntegrationsMD,
                meta: { title: 'Integrations — API Documentation | Oversecured' },
            },
            {
                path: 'api/single-scans',
                component: SingleScanMD,
                meta: { title: 'Single Scans — API Documentation | Oversecured' },
                beforeEnter:multiguard([companyGuard]),
            },
            {
                path: 'api/uploading',
                component: UploadingFilesMD,
                meta: { title: 'Uploading files — API Documentation | Oversecured' },
            },
            {
                path: 'api/vulnerabilities',
                component: WorkingWithVulnerabilitiesMD,
                meta: { title: 'Working with vulnerabilities — API Documentation | Oversecured' },
            },
            {
                path: 'webhooks',
                component: Webhooks_MD,
                meta: { title: 'Working with webhooks | Oversecured' },
            },
            {
                path: 'quick-start',
                component: QuickStartMD,
                meta: { title: 'Quick Start | Oversecured' },
            },
            {
                path: 'ci-cd/',
                component: CICDIndexMD,
                meta: { title: 'Supported CI/CD tools — CI/CD | Oversecured' },
            },
            {
                path: 'ci-cd/bitrise',
                component: BitriseMD,
                meta: { title: 'Bitrise — CI/CD | Oversecured' },
            },
            {
                path: 'ci-cd/jenkins',
                component: JenkinsMD,
                meta: { title: 'Jenkins — CI/CD | Oversecured' },
            },
            {
                path: 'ci-cd/gradle',
                component: GradleMD,
                meta: { title: 'Gradle — CI/CD | Oversecured' },
            },
            {
                path: 'ci-cd/circleci',
                component: CircleCIMD,
                meta: { title: 'CircleCI — CI/CD | Oversecured' },
            },
            {
                path: 'ci-cd/travis',
                component: TravisMD,
                meta: { title: 'Travis CI — CI/CD | Oversecured' },
            },
            {
                path: 'configurations/',
                component: Configuration_Introduction_MD,
                meta: { title: 'Introduction - Scan Configurations | Oversecured' },
            },
            {
                path: 'configurations/ios',
                component: Configuration_iOS_MD,
                meta: { title: 'For iOS - Scan Configurations | Oversecured' },
            },
            {
                path: 'configurations/smart-filter',
                component: Configuration_SmartFilter_MD,
                meta: { title: 'SmartFilter - Scan Configurations | Oversecured' },
            },
        ],
        meta: { title: 'API | Oversecured' },
    },
    {
        path: '/scans',
        name: 'Scans',
        components: {
            default: Scans,
        },
        meta: { title: 'Single Scans | Oversecured' },
        beforeEnter: multiguard([authGuard, checkAccessToken, companyGuard]),
    },
    {
        path: '/scan/info/:id',
        name: 'ScanInfo',
        components: {
            default: ScanInfo,
        },
        beforeEnter: authGuardGuest,
        props: true,
    },
    {
        path: '/scan/create',
        name: 'NewScan',
        components: {
            default: NewScan,
        },
        beforeEnter: multiguard([authGuard, companyGuard]),
        meta: { title: 'New Scan | Oversecured' },
    },
    {
        path: '/settings',
        name: 'Settings',
        components: {
            default: SettingsPage,
        },
        beforeEnter: multiguard([authGuard, checkAccessToken]),
        children: [
            {
                path: 'profile-info',
                component: ProfileInfo,
                meta: { title: 'Settings — Company Info | Oversecured' },
                beforeEnter: hackerGuard,
            },
            {
                path: 'payment-history',
                name: 'Payment History',
                component: PaymentHistory,
                meta: { title: 'Payment History — Company Info | Oversecured' },
                children: [
                    {
                        path: 'single-scans',
                        name: 'Single Scans Payment History',
                        component: SingleScansPaymentHistory,
                        meta: { title: 'Single Scans Payment History — Company Info | Oversecured' },
                    },
                    {
                        path: 'scan-packs',
                        component: ScanPacksPaymentHistory,
                        meta: { title: 'Scan Packs Payment History — Company Info | Oversecured' },
                    },
                ],
            },
            {
                path: 'integration-history',
                component: IntegrationHistory,
                meta: { title: 'Settings — Integration History | Oversecured' },
            },
            {
                path: 'integration/:id',
                component: CurrentIntegration,
                meta: { title: 'Settings — Integration | Oversecured' },
            },
            {
                path: 'scan-history',
                component: ScansHistory,
                meta: { title: 'Settings — Scan History | Oversecured' },
            },
            {
                path: 'scan-packs',
                component: ScanPacksProfile,
                meta: { title: 'Settings — Scan Packs | Oversecured' },
            },
            {
                path: 'sessions',
                component: Sessions,
                meta: { title: 'Settings — Sessions | Oversecured' },
            },
            {
                path: 'cards',
                component: CardsBlock,
                meta: { title: 'Settings — Cards Block | Oversecured' },
            },
            {
                path: 'api-keys',
                component: APIKeys,
                meta: { title: 'Settings — API | Oversecured' },
            },
            {
                path: 'security',
                component: Security,
                meta: { title: 'Settings — Security | Oversecured' },
            },
        ],
        meta: { title: 'Profile Settings | Oversecured' },
        props: true,
    },
    {
        path: '/get-started',
        name: 'GetStarted',
        components: {
            default: GetStarted,
        },
        beforeEnter: multiguard([authGuard, checkAccessToken, hackerGuard]),
        meta: { title: 'Get Started | Oversecured' },
        props: true,
    },
    {
        path: '/integrations',
        name: 'Integrations',
        components: {
            default: listIntegrations,
        },
        beforeEnter: multiguard([authGuard, checkAccessToken]),
        meta: { title: 'Integrations | Oversecured' },
        props: true,
    },
    {
        path: '/integration/:id',
        name: 'IntegrationRedirectRoute',
        beforeEnter: multiguard([authGuard, checkAccessToken, defaultBranchRedirect]),
        props: true,
    },
    {
        path: '/accept-invite/:invitedId',
        name: 'IntegrationInviteAccept',
        components: {
            default: IntegrationInviteAccept,
        },
        beforeEnter: multiguard([authGuard, checkAccessToken]),
        meta: { title: 'Integrations | Oversecured' },
        props: true,
    },
    {
        path: '/integrations/create',
        name: 'NewIntegration',
        components: {
            default: NewIntegration,
        },
        beforeEnter: authGuard,
        meta: { title: 'New Integration | Oversecured' },
    },
    {
        path: '/integration/:id/branch/:branchName',
        name: 'Inner Integration',
        components: {
            default: InnerIntegrations,
        },
        beforeEnter: multiguard([authGuard, checkAccessToken]),
        props: true,
    },
    {
        path: '/integration/:id/branches',
        name: 'IntegrationBranches',
        components: {
            default: InnerBranches,
        },
        beforeEnter: multiguard([authGuard, checkAccessToken]),
        props: true,
    },
    {
        path: '/integration/:id/branch/:branchName/new-version',
        name: 'New Integration Version',
        components: {
            default: NewVersionIntegration,
        },
        beforeEnter: multiguard([authGuard, checkStatusIntegration]),
    },
    {
        path: '/integration/:integrationId/branch/:branchName/version/:id',
        name: 'Version scan',
        components: {
            default: ScanInfo,
        },
        props: { isIntegration: true },
        beforeEnter: authGuard,
    },
    {
        path: '/integration/:integrationId/branch/:branchName/compare',
        name: 'Versions compare',
        components: {
            default: VersionsComparison,
        },
        props: { isIntegration: true },
        beforeEnter: authGuard,
    },
    {
        path: '/integration/:id/settings',
        name: 'Integration Settings',
        components: {
            default: IntegrationSettingsContainer,
        },
        beforeEnter: authGuard,
        meta: { title: 'Integration Settings | Oversecured' },
        children: [
            {
                path: 'general',
                component: IntegrationSettings,
                meta: { title: 'General Settings | Oversecured' },
            },
            {
                path: 'members',
                component: IntegrationMembers,
                meta: { title: 'Members — Integration Settings | Oversecured' },
            },
            {
                path: 'webhooks',
                component: IntegrationWebhooks,
                meta: { title: 'Webhooks — Integration Settings | Oversecured' },
            },
        ],
    },
    {
        path: '/scans/packs',
        name: 'Scan Packs',
        components: {
            default: ScanPacks,
        },
        beforeEnter: authGuard,
        meta: { title: 'Scan Packs | Oversecured' },
    },
    {
        path: '/black-friday',
        name: 'BlackFriday Scan Packs',
        components: {
            default: ScanPacksBlackFriday,
        },
        beforeEnter: authGuard,
        meta: { title: 'Scan Packs | Oversecured' },
    },
    {
        path: '/settings/upgrade',
        name: 'Upgrade to Company',
        components: {
            default: profileDataToUpgradeToCompany,
        },
        beforeEnter: multiguard([authGuard, isTypeCompany]),
        props: true,
        meta: { title: 'Upgrade | Oversecured' },
    },
    {
        path: '/request-demo',
        name: 'RequestDemo',
        components: {
            default: RequestDemo,
        },
        meta: { title: 'Request a Demo | Oversecured' },
    },
];

const router = new Router({
    mode: 'history',
    routes,
    path: '/',
    scrollBehavior (to, from, savedPosition) {

    },
});
router.beforeEach((to, from, next) => {

    const context = {
        to,
        from,
        next,
        store,
    };
    initWebsocketConnection({
        ...context,
    });
    if (to.meta.title) {
        document.title = to.meta.title;
    }

});
router.beforeEach((to, from, next) => {
    closeSideBar(to, from, next);
});

router.afterEach((to) => {
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', to.path]);
    _hsq.push(['trackPageView']);
});

export default router;
