import Vue from "vue";

const VUE_APP_AUTH_BASE_URL = process.env.VUE_APP_AUTH_BASE_URL

export const loginUserRequest = (payload) => {
    return Vue.axios.post(
        `${VUE_APP_AUTH_BASE_URL}/authorization`,
        payload
    )
}

export const optCheckRequest = (payload) => {
    return Vue.axios.post(
        `${VUE_APP_AUTH_BASE_URL}/authorization/otp-check`,
        payload
    )
}
