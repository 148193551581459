const template = () => {
    return `
        <div class="dz-preview dz-file-preview">
            <div class="dz-details">
                <div class="dz-filename cabin"><span data-dz-name></span></div>
            </div>
           <div class="progress-bar-custom">
                <div class="progress progress-striped active" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">
                  <div class="progress-bar progress-bar-success" style="width:0;" data-dz-uploadprogress></div>
                </div>
            </div>
            <div class="dz-details">
                <div class="dz-number cabin"></div>
            </div>
        </div>`;
}

const onDuplicateFileUpload = (file) => {
    file.isDuplicate = true
}

module.exports = {
    template,
    onDuplicateFileUpload
}
