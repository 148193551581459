var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        menu_with_border: this.$route.name === `Version scan` || this.$route.name === `ScanInfo`,
        'new-hover-fixed': _vm.isRedesignedPage, headerLinkIsOpen: _vm.isOpenMenu,
    }},[(_vm.isAuthenticated && _vm.isZeroMfaDevicesAssigned)?_c('MfaNotification',{attrs:{"is-user-auth":_vm.isAuthenticated},on:{"toggleNotification":_vm.toggleMfaNotification}}):_vm._e(),_c('div',{staticClass:"header"},[_c('router-link',{staticClass:"header-brand",attrs:{"to":"/"}},[_c('img',{staticClass:"header-brand-logo",attrs:{"src":require("../assets/images/logo_brand_text.svg"),"alt":"brand"}})]),_c('div',{staticClass:"header__nav"},[(_vm.isAuthPage)?_c('router-link',{staticClass:"link-back",attrs:{"to":"/"}},[_vm._v(" Return to Homepage ")]):_c('div',[(!_vm.isAuthenticated)?_c('NewOuterHeader',{attrs:{"is-authenticated":_vm.isAuthenticated}}):_vm._e(),(_vm.isAuthenticated)?_c('InnerHeader'):_vm._e()],1)],1),_c('div',{class:{
                header__linksOpen: _vm.isOpenMenu,
                header__linksOpen__main_page: _vm.isOpenMenu && _vm.isRedesignedPage,
                header__linksClose: !_vm.isOpenMenu,
                header__linksOpen__with_notification: _vm.isMfaNotification,
            },attrs:{"id":"menu"}},[(_vm.isAuthPage)?_c('b-nav-item',{staticClass:"link-back",attrs:{"to":"/"}},[_vm._v(" Return to Homepage ")]):_c('div',[(!_vm.isAuthenticated)?_c('NewOuterHeader',{attrs:{"is-authenticated":_vm.isAuthenticated}}):_vm._e(),(_vm.isAuthenticated)?_c('InnerHeader'):_vm._e()],1)],1),_c('div',{staticClass:"header__burger-menu",on:{"click":function($event){return _vm.toggleMenu()}}},[_c('div',{class:{ header__burgerItem: !_vm.isOpenMenu, header__burgerItemOpen: _vm.isOpenMenu }}),_c('div',{class:{ header__burgerItem: !_vm.isOpenMenu, header__burgerItemOpen: _vm.isOpenMenu }}),_c('div',{class:{ header__burgerItem: !_vm.isOpenMenu, header__burgerItemOpen: _vm.isOpenMenu }})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }