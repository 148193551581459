<template>
    <div
        ref="folderMenu"
        class="folder_menu"
    >
        <div class="folder_container">
            <!-- all vulnerabilities -->
            <div class="all_vulnerabilities">
                <b-nav-item :to="{ path: definePath('All Vulnerabilities')}">
                    <b-skeleton-wrapper :loading="loadingCheckup('All Vulnerabilities')">
                        <template #loading>
                            <b-skeleton
                                type="text"
                                class="folder_total_vulnerabilities"
                                style="background: #ECECEC;"
                            />
                        </template>
                    </b-skeleton-wrapper>
                    <div
                        v-if="!loadingCheckup('All Vulnerabilities')"
                        ref="AllVulnFolder"
                        class="folder_total_vulnerabilities"
                        :class="{highlighted_folder: checkRouteForHighlightFolder('All Vulnerabilities')}"
                        @mouseenter="showLabel('All Vulnerabilities')"
                        @mouseleave="hideLabel"
                    >
                        <div
                            :class="{
                                folder_total_vulnerabilities__count: !checkRouteForHighlightFolder('All Vulnerabilities'),
                                folder_total_vulnerabilities__count_selected: checkRouteForHighlightFolder('All Vulnerabilities')}"
                        >
                            {{ checkVulnerabilitySeverityNumber(allVulnerabilitiesFolder[0].statistics, 'all') }}
                        </div>
                        <div
                            :class="{
                                folder_total_vulnerabilities__title: !checkRouteForHighlightFolder('All Vulnerabilities'),
                                folder_total_vulnerabilities__title_selected: checkRouteForHighlightFolder('All Vulnerabilities')}"
                        >
                            All
                        </div>
                    </div>
                </b-nav-item>
                <div
                    v-show="showFolderStatisticsLabel !== null && showFolderStatisticsLabel === 'All Vulnerabilities'"
                    class="folder_total_vulnerabilities_label"
                >
                    <div class="folder_total_vulnerabilities_label_count">
                        <div class="folder_total_vulnerabilities_label_count_high">
                            {{ checkVulnerabilitySeverityNumber(allVulnerabilitiesFolder[0].statistics, 'high') }}
                        </div>
                        <span class="folder_total_vulnerabilities_label_count_slash">/</span>
                        <div class="folder_total_vulnerabilities_label_count_medium">
                            {{ checkVulnerabilitySeverityNumber(allVulnerabilitiesFolder[0].statistics, 'medium') }}
                        </div>
                        <span class="folder_total_vulnerabilities_label_count_slash">/</span>
                        <div class="folder_total_vulnerabilities_label_count_low">
                            {{ checkVulnerabilitySeverityNumber(allVulnerabilitiesFolder[0].statistics, 'low') }}
                        </div>
                    </div>
                    <div class="folder_total_vulnerabilities_label_title">
                        All Vulnerabilities
                    </div>
                </div>
            </div>



            <!-- divider -->
            <div style="border-bottom: 1px solid #F0F1F3; width: 75%;" />

            <!-- folder list -->
            <div
                ref="FolderList"
                class="folders_list_container"
                @scroll="hideLabel"
            >
                <div
                    v-for="folder in folderList"
                    :key="folder.id"
                    class="folders_list"
                >
                    <b-nav-item :to="{ path: definePath(folder.name)}">
                        <b-skeleton-wrapper :loading="loadingCheckup(folder.name)">
                            <template #loading>
                                <b-skeleton
                                    type="text"
                                    style="background: #ECECEC;"
                                    :class="{highlighted_folder: checkRouteForHighlightFolder(folder.name)}"
                                    class="folder_list_item"
                                />
                            </template>
                        </b-skeleton-wrapper>
                        <div
                            v-if="!loadingCheckup(folder.name)"
                            :ref="`FolderListItem${folder.id}`"
                            class="folder_list_item"
                            :class="{highlighted_folder: checkRouteForHighlightFolder(folder.name), empty: folder.isEmptyFolder}"
                            @mouseenter="showLabelAndSetFolderIconColor($event, folder)"
                            @mouseleave="hideLabelAndSetFolderIconColor($event, folder)"
                        >
                            <FolderIcon
                                :ref="`FolderIcon${folder.id}`"
                                :svg-color="checkRouteForHighlightFolder(folder.name) ? Selected : folder.isEmptyFolder ? Empty : Default"
                                :svg-width="'24px'"
                                :svg-height="'21px'"
                                alt="folder icon"
                            />
                            <div class="folder_list_vulnerabilities_dots">
                                <div
                                    v-if="checkVulnerabilitySeverityBoolean(folder.statistics, 'high')"
                                    class="red_dot"
                                />
                                <div
                                    v-if="checkVulnerabilitySeverityBoolean(folder.statistics, 'medium')"
                                    class="yellow_dot"
                                />
                                <div
                                    v-if="checkVulnerabilitySeverityBoolean(folder.statistics, 'low')"
                                    class="green_dot"
                                />
                            </div>
                        </div>
                    </b-nav-item>
                    <div
                        ref="FolderListItemName"
                        class="folder_list_item__name"
                        :title="folder.name"
                    >
                        <div
                            :ref="`FolderIconName${folder.id}`"
                            class="folder_name"
                            :style="{ color: checkRouteForHighlightFolder(folder.name) ? SelectedText : folder.isEmptyFolder ? EmptyText : DefaultText }"
                        >
                            {{ folder.name }}
                        </div>
                    </div>
                    <div
                        v-show="showFolderStatisticsLabel !== null && showFolderStatisticsLabel === folder.name"
                        class="folders_list_label"
                    >
                        <div class="folders_list_label_count">
                            <div class="folders_list_label_count_high">
                                {{ checkVulnerabilitySeverityNumber(folder.statistics, 'high') }}
                            </div>
                            <span class="folders_list_label_count_slash">/</span>
                            <div class="folders_list_label_count_medium">
                                {{ checkVulnerabilitySeverityNumber(folder.statistics, 'medium') }}
                            </div>
                            <span class="folders_list_label_count_slash">/</span>
                            <div class="folders_list_label_count_low">
                                {{ checkVulnerabilitySeverityNumber(folder.statistics, 'low') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- false positives and settings -->
            <div
                v-if="integrationId || isJobOwner"
                ref="FalsePositiveAndSettings"
                class="falsepositive_and_settings"
            >
                <!-- divider -->
                <div style="border-bottom: 1px solid #F0F1F3; width: 100%;" />

                <!-- false positives -->
                <b-nav-item :to="{ path: definePath('False Positives') }">
                    <div
                        class="folder_falsepositive"
                        :class="{highlighted_folder_false_positives: checkRouteForHighlightFolder('False Positives'), empty: falsePositivesFolder[0].isEmptyFolder}"
                        @mouseenter="setFalsePositivesFolderIconColor($event, 'False Positives')"
                        @mouseleave="setFalsePositivesFolderIconColor($event, 'False Positives')"
                    >
                        <FalsePositiveIcon
                            ref="FalsePositiveFolderIcon"
                            :svg-color="checkRouteForHighlightFolder('False Positives') ? SelectedFalseIcon : falsePositivesFolder[0].isEmptyFolder ? Empty : Default"
                            :svg-width="'24px'"
                            :svg-height="'24px'"
                            alt="falsepositive icon"
                        />
                    </div>
                </b-nav-item>
                <div
                    ref="falsePositivesFolderName"
                    class="folder_falsepositive__name"
                    :style="{ color: checkRouteForHighlightFolder('False Positives') ? SelectedFalseIcon : falsePositivesFolder[0].isEmptyFolder ? EmptyText : DefaultText }"
                >
                    False Positives
                </div>

                <!-- settings -->
                <div
                    class="folder_settings"
                    :class="{ highlighted_folder: isFolderSettingsOpen }"
                    title="Folder Settings"
                    @click="openFolderSettings()"
                    @mouseenter="setSettingsIconColor"
                    @mouseleave="setSettingsIconColor"
                >
                    <FolderSettingsIcon
                        ref="FolderSettingsIcon"
                        :svg-color="Default"
                        :svg-width="'22px'"
                        :svg-height="'16px'"
                        alt="folder settings icon"
                    />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';
// Icons import
import FalsePositiveIcon from '../../assets/iconTemplates/FalsePositiveIcon';
import FolderIcon from '../../assets/iconTemplates/FolderIcon';
import FolderSettingsIcon from '../../assets/iconTemplates/FolderSettingsIcon';

// Constants import
import { FolderNameEnum } from '../../utils/constants/common';

// Functions import
import { checkVulnerabilitySeverityNumberFunction, checkVulnerabilitySeverityBooleanFunction } from '../../utils/helper-functions';

export default {
    name: 'FolderMenu',
    components: {
        FolderIcon,
        FalsePositiveIcon,
        FolderSettingsIcon,
    },
    props: {
        allVulnerabilitiesFolder: {
            type: Array,
        },
        folderList: {
            type: Array,
        },
        falsePositivesFolder: {
            type: Array,
        },
        versionId: {
            type: String,
        },
        integrationId: {
            type: String,
        },
        jobId: {
            type: String,
        },
        isJobOwner: {
            type: Boolean,
        },
        branchName: {
            type: String,
        },
    },
    data() {
        return {
            // Color rules:
            DefaultText: '#5C6A7A',
            Default: '#949DB8',
            EmptyText: '#5C6A7A',
            Empty: '#949DB8',
            SelectedText: 'rgba(49, 128, 246, 1)',
            Selected: '#3180F6',
            Hover: '#ADCCFB',
            SelectedFalseIcon: '#E63D42',
            // Constants
            isOpenAllVulnerabilitiesFolder: false,
            isRouteContainsQueryParams:  this.$route.query.folderName ? true : false,
            isSelectedFalsePositivesFolder: this.$route.query.folderName === FolderNameEnum.FALSE_POSITIVES ? true : false,
            selectedFolderName: null,
            showFolderStatisticsLabel: null,
        };
    },
    computed: {
        ...mapGetters({
            isFolderSettingsOpen: 'folders/isFolderSettingsOpen',
            isTrialNotification: 'user/isTrialNotification',
            movedToFolder: 'folders/movedToFolder',
            movedFromFolder: 'folders/movedFromFolder',
        }),
    },
    watch: {
        $route() {
            // check if route contains query params and define selected folder info: name and is it false positives folder
            this.isRouteContainsQueryParams = this.$route.query.folderName ? true : false;
            this.selectedFolderName = this.$route.query.folderName || FolderNameEnum.ALL_VULNERABILITIES;
            this.isSelectedFalsePositivesFolder = this.$route.query.folderName === FolderNameEnum.FALSE_POSITIVES ? true : false;
        },
        /**
         * Watch for changes in folderList array
         * @param {Array} newVal - new array of folderList
         * @param {Array} oldVal - old array of folderList
         * @returns {void}
         * or change route
         */
        folderList: {
            handler: function (newVal, oldVal) {
                /* Check if Folder has been added */
                if (newVal.length > oldVal.length) return;

                /* Check if Folder has been deleted */
                if (newVal.length < oldVal.length) {
                    // Define which folder was deleted
                    const deletedFolder = oldVal.filter(folder => !newVal.some(newFolder => newFolder.id === folder.id));
                    // Check if deleted folder name is the same as selected folder name if so change route to all vulnerabilities folder
                    if (deletedFolder[0].name === this.selectedFolderName) {
                        this.$router.push({ path: this.$route.path, query: { folderName: FolderNameEnum.ALL_VULNERABILITIES } });
                    }
                    return;
                }

                /* Check if Folder name updated */
                if (newVal.length === oldVal.length) {
                    /**
                     * Check Empty Folder
                     * (related to event when moving vulnerability to another folder)
                     */
                    this.checkAndUpdateFolderIconsColor(newVal);

                    /**
                     * Check Folder name update
                     * (related to event when updating folder name in folder settings popup)
                     */
                    // Compare all new and old folder names to find changed folder based on id and name
                    const changedFolder = newVal.filter(folder => folder.name !== oldVal.filter(oldFolder => oldFolder.id === folder.id)[0].name);
                    // If after filter we don't have object in array it means that folder name wasn't changed
                    if (changedFolder.length === 0) return;
                    // Define changed folder ID
                    const changedFolderId = changedFolder[0].id;

                    // Define current old folder based on changed folder ID and selected folder name
                    const currentOldFolder = oldVal.filter(folder => folder.name === this.selectedFolderName && folder.id === changedFolderId);
                    // If after filter we have object in array it means that selected folder name was changed and we need to change route to new folder name
                    if (currentOldFolder.length > 0) {
                        this.$router.push({ path: this.$route.path, query: { folderName: changedFolder[0].name } });
                    }
                    return;
                }
            },
            deep: true,
        },
        falsePositivesFolder: {
            handler: function (newVal, oldVal) {
                // Check if false positives folder is empty
                if (!newVal[0].isEmptyFolder) {
                    this.checkAndUpdateFalsePositivesFolderIconColor();
                }
            },
            deep: true,
        },
        /**
         * watch isFolderSettingsOpen to change after closing folder settings popup
         */
        isFolderSettingsOpen(event) {
            this.setSettingsIconColor(event);
        },
        /**
         * watch isTrialNotification to change after closing trial notification
         */
        isTrialNotification() {
            this.handleScroll();
        },
    },
    beforeMount() {
        // define default folder name on first load or when route doesn't contain query params
        this.selectedFolderName = this.$route.query.folderName ? this.$route.query.folderName : FolderNameEnum.ALL_VULNERABILITIES;
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);

    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        /**
         * This method is used to load folders that are going to be changed after moving vulnerability to another folder
         */
        loadingCheckup(folderName) {
            const { movedToFolder, movedFromFolder } = this;

            return folderName === movedToFolder || folderName === movedFromFolder || folderName === FolderNameEnum.ALL_VULNERABILITIES && [movedToFolder, movedFromFolder].includes(FolderNameEnum.FALSE_POSITIVES)
                ? true
                : false;
        },
        /**
         * This method is used to adjust folder menu position and some margin when scrolling
         * 71px is the hight of header menu + 1px of border
         *
         */
        handleScroll() {
            let headerHeight = this.isTrialNotification ? 118 : 72;
            let scrollPosition = headerHeight - window.scrollY;

            if (scrollPosition < 0) {
                this.$refs.folderMenu.style.top = 0;
                this.isJobOwner ? this.$refs.FalsePositiveAndSettings.style.marginBottom = '12px' : '';
            }
            if (scrollPosition >= 0 && scrollPosition <= headerHeight) {
                this.$refs.folderMenu.style.top = `${scrollPosition}px`;
                this.isJobOwner ? this.$refs.FalsePositiveAndSettings.style.marginBottom = `${scrollPosition + 12}px` : '';
            }
        },
        /**
         * This method is used to define path for folder menu links
         * @param {string} folderName - Folder name
         * @returns {string} - Path for folder menu links
         */
        definePath(folderName) {
            let integrationVersionPath;
            let scanPath;
            // If folder name is "All Vulnerabilities" and no query param for folder name exists
            if (folderName === FolderNameEnum.ALL_VULNERABILITIES && !this.$route.query.folderName) {
                // Set path without query params
                integrationVersionPath = `/integration/${this.integrationId}/branch/${this.branchName}/version/${this.versionId}`;
                scanPath = `/scan/info/${this.jobId}`;
            } else {
                // Set path with query params
                integrationVersionPath = `/integration/${this.integrationId}/branch/${this.branchName}/version/${this.versionId}?folderName=${folderName}`;
                scanPath = `/scan/info/${this.jobId}?folderName=${folderName}`;
            }
            return this.integrationId ? integrationVersionPath : scanPath;
        },
        /**
         *  checkRouteForHighlightFolder
         *  @param {String} folderName - folder name that was defined in query params or default folder name
         *  if no query params in route or first load without query params use selectedFolder name that was defined in beforeMount step as "All Vulnerabilities"
         *  then highlight folder "All Vulnerabilities"
         *  otherwise highlight folder that was defined in query params
         */
        checkRouteForHighlightFolder(folderName) {
            return !this.isRouteContainsQueryParams && folderName === FolderNameEnum.ALL_VULNERABILITIES
                ? this.selectedFolderName === FolderNameEnum.ALL_VULNERABILITIES
                : this.selectedFolderName === folderName;
        },
        checkVulnerabilitySeverityNumber(statistics, severity) {
            return checkVulnerabilitySeverityNumberFunction(statistics, severity);
        },
        checkVulnerabilitySeverityBoolean(statistics, severity) {
            return checkVulnerabilitySeverityBooleanFunction(statistics, severity);
        },
        /**
         * hideLabel, showLabel, showLabelAndSetFolderIconColor, setFalsePositivesFolderIconColor
         * are used to hide and show folder statistics label and to set folder icon color
         */
        hideLabel() {
            this.showFolderStatisticsLabel = null;
            const container = this.$refs.FolderList;
            container.style.width = '96px';
            container.style['margin-left'] = '0px';
            container.style['margin-bottom'] = '-16px';
        },
        showLabel(folderName) {
            this.showFolderStatisticsLabel = folderName;
            if (folderName !== FolderNameEnum.ALL_VULNERABILITIES) {
                const container = this.$refs.FolderList;
                container.style.width = '300px';
                container.style['margin-left'] = '204px';
                container.style['margin-bottom'] = '0px';
            }
        },
        showLabelAndSetFolderIconColor(event, folderData) {
            // Check if folder item is cropped
            const folderListItem = this.$refs[`FolderListItem${folderData.id}`][0].getBoundingClientRect();
            const folderListContainer = this.$refs.FolderList.getBoundingClientRect();
            const isItemCropped = folderListItem.top < folderListContainer.top || folderListItem.bottom + 10 > folderListContainer.bottom;

            // If folder item is cropped by container, don't show label
            if (!isItemCropped && !folderData.isEmptyFolder) this.showLabel(folderData.name);

            if (event.type === 'mouseenter' && !this.checkRouteForHighlightFolder(folderData.name)) {
                this.$refs[`FolderIcon${folderData.id}`][0].setFill(this.Hover);
                this.$refs[`FolderIconName${folderData.id}`][0].style.color = this.Selected;
            }

        },
        hideLabelAndSetFolderIconColor(event, folderData) {
            this.hideLabel();
            if (event.type === 'mouseleave' && !this.checkRouteForHighlightFolder(folderData.name)) {
                this.$refs[`FolderIcon${folderData.id}`][0].setFill(this.checkRouteForHighlightFolder(folderData.name)
                    ? this.Selected
                    : folderData.isEmptyFolder ? this.Empty : this.Default);
                this.$refs[`FolderIconName${folderData.id}`][0].style.color = this.checkRouteForHighlightFolder(folderData.name)
                    ? this.Selected
                    : folderData.isEmptyFolder ? this.EmptyText : this.DefaultText;
            }
        },
        checkAndUpdateFolderIconsColor(folderList) {
            folderList.forEach(folder => {
                // find folder icon ref by folder id
                const folderIcon = this.$refs[`FolderIcon${folder.id}`][0];
                // set folder icon color based on rule
                folderIcon?.setFill(this.checkRouteForHighlightFolder(folder.name)
                    ? this.Selected
                    : folder.isEmptyFolder ? this.Empty : this.Default);
            });
        },
        checkAndUpdateFalsePositivesFolderIconColor() {
            const folderIcon = this.$refs.FalsePositiveFolderIcon;
            folderIcon?.setFill(this.checkRouteForHighlightFolder('False Positives')
                ? this.SelectedFalseIcon
                : this.falsePositivesFolder[0].isEmptyFolder ? this.Empty : this.Default);
        },
        /**
         * Set False Positive Folder color
         */
        setFalsePositivesFolderIconColor(event, folderName) {
            if (event.type === 'mouseenter' && !this.checkRouteForHighlightFolder(folderName)) {
                this.$refs.FalsePositiveFolderIcon.setFill(this.SelectedFalseIcon);
                this.$refs.falsePositivesFolderName.style.color = this.SelectedFalseIcon;
            } else if (event.type === 'mouseleave' && !this.checkRouteForHighlightFolder(folderName)) {
                this.$refs.FalsePositiveFolderIcon.setFill(this.checkRouteForHighlightFolder(folderName)
                    ? this.SelectedFalseIcon
                    : this.falsePositivesFolder[0].isEmptyFolder ? this.Empty : this.Default);
                this.$refs.falsePositivesFolderName.style.color = this.checkRouteForHighlightFolder(folderName)
                    ? this.SelectedFalseIcon
                    : this.falsePositivesFolder[0].isEmptyFolder ? this.EmptyText : this.DefaultText;
            }
        },
        /**
         * Folder Settings
         */
        openFolderSettings() {
            this.$store.commit('folders/setFolderSettings', true)
            this.$emit('toggleFolderSettingsPopUp', true);
        },
        setSettingsIconColor(event) {
            event.type === 'mouseenter'
                ? this.$refs.FolderSettingsIcon.setFill(this.Hover)
                : this.$refs.FolderSettingsIcon.setFill(this.isFolderSettingsOpen ? this.Selected : this.Default);
        },
    },
};

</script>
<style lang="scss" scoped>
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

@mixin containerFolder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    width: 54px;
    height: 54px;
    border-radius: 8px;
    cursor: pointer;
}

@mixin vulnDot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: auto 1.5px;
}

@mixin vulnDotLabel {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    right: -8px;
    top: 0;
}

.b-skeleton-animate-wave:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(90deg,transparent 0%, hsla(0,0%,100%,.4) 50%, transparent 100%);
    background-color: #ECECEC;
    animation: b-skeleton-animate-wave 1.75s linear infinite;
}

.folder_menu {
    position: fixed;
    left: 0;
    width: 96px;
    height: 100vh;
    background: #F8FAFC;
    z-index: 1001;

    .folder_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: inherit;

        .all_vulnerabilities {
            position: relative;
            .folder_total_vulnerabilities {
                @include containerFolder;
                margin: 12px auto 12px;

                &:hover {
                    border: 1px solid $border-folder-color;

                    .folder_total_vulnerabilities__count {
                        color: $border-folder-color;
                    }

                    .folder_total_vulnerabilities__title {
                        color: $border-folder-color;
                    }
                }

                &.highlighted_folder {
                    background-color: $background-blue-color;
                }

                &__count {
                    @include Aspekta-font(700, 14px, 21px, $default-text-color);
                    letter-spacing: 0.05em;

                    &_selected {
                        @include Aspekta-font(700, 14px, 21px, $main-blue-color);
                        letter-spacing: 0.05em;
                    }
                }

                &__title {
                    @include Aspekta-font(400, 12px, 18px, $default-text-color);
                    letter-spacing: 0.05em;
                    text-align: center;

                    &_selected {
                        @include Aspekta-font(400, 12px, 18px, $main-blue-color);
                        letter-spacing: 0.05em;
                    }
                }
            }

            .folder_total_vulnerabilities_label {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: absolute;

                width: 226px;
                height: 78px;
                left: 67px;
                top: 0;
                background: #FFFFFF;
                border: 1px solid $border-label-color;
                border-radius: 8px;
                z-index: 10;
                filter: drop-shadow(0px 12px 16px rgba(178, 185, 196, 0.12));

                &_count {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    width: 100%;

                    &_high,
                    &_medium,
                    &_low {
                        position: relative;
                        @include Aspekta-font(650, 22px, 31px, $default-text-color);
                    }

                    &_high::after {
                        @include vulnDotLabel;
                        background-color: $main-orange-color;
                    }
                    &_medium::after {
                        @include vulnDotLabel;
                        background-color: $main-yellow-color;
                    }
                    &_low::after {
                        @include vulnDotLabel;
                        background-color: $main-green-color;
                    }

                    &_slash {
                        @include Aspekta-font(400, 22px, 31px, $border-label-color);
                        margin: 0 8px;
                    }
                }

                &_count::before {
                    content: "";
                    display: block;
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    rotate: 45deg;
                    left: -6.5px;
                    top: 64%;
                    border-left: 1px solid $border-label-color;
                    border-bottom: 1px solid $border-label-color;
                    background-color: #FFFFFF;
                }

                &_title {
                    @include Aspekta-font(400, 14px, 20px, $default-text-color);
                    letter-spacing: 0.02em;
                }
            }
        }

        .folders_list_container::-webkit-scrollbar {
            width: 0px;
            background: transparent;
            padding: 0px;
        }

        .folders_list_container {
            overflow-x: visible;
            overflow-y: scroll;
            -ms-overflow-style: none; /* IE 10+ */
            scrollbar-width: none;
            width: 96px;
            margin-left: 0px;
            margin-bottom: -16px;

            .folders_list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 6px;
                position: relative;
                margin-left: 21px;

                .folder_list_item {
                    @include containerFolder;
                    &:hover {
                        border: 1px solid $border-folder-color;
                    }
                    &.highlighted_folder {
                        background-color: #EBF2FD;
                        &.empty {
                            opacity: .5;
                        }
                    }

                    .folder_list_vulnerabilities_dots {
                        display: flex;
                        flex-direction: row;
                        height: fit-content;
                        margin-top: 8px;

                        .red_dot {
                            @include vulnDot;
                            background: $main-orange-color;
                        }
                        .yellow_dot {
                            @include vulnDot;
                            background: $main-yellow-color;
                        }
                        .green_dot {
                            @include vulnDot;
                            background: $main-green-color;
                        }
                    }

                    &__name {
                        line-height: 19px;
                        text-align: center;
                        margin-top: 4px;
                        margin-left: -8px;
                        width: 96px;
                        margin-bottom: 6px;

                        .folder_name {
                            @include Aspekta-font(400, 12px, 18px, $default-text-color);
                            display: block;
                            max-width: 72px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .folders_list_label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    position: absolute;

                    width: 150px;
                    height: 44px;
                    left: 67px;
                    top: 8%;
                    background: #FFFFFF;
                    border: 1px solid #F5F7FA;
                    border-radius: 8px;
                    z-index: 10;
                    filter: drop-shadow(0px 12px 16px rgba(178, 185, 196, 0.12));

                    &_count {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        &_high,
                        &_medium,
                        &_low {
                            position: relative;
                            @include Aspekta-font(400, 14px, 20px, $default-text-color);
                        }

                        &_high::after {
                            @include vulnDotLabel;
                            width: 5px;
                            height: 5px;
                            background-color: $main-orange-color;
                        }
                        &_medium::after {
                            @include vulnDotLabel;
                            width: 5px;
                            height: 5px;
                            background-color: $main-yellow-color;
                        }
                        &_low::after {
                            @include vulnDotLabel;
                            width: 5px;
                            height: 5px;
                            background-color: $main-green-color;
                        }

                        &_slash {
                            @include Aspekta-font(400, 14px, 20px, $border-label-color);
                            margin: 0 8px;
                        }
                    }

                    &_count::before {
                        content: "";
                        display: block;
                        width: 12px;
                        height: 12px;
                        position: absolute;
                        rotate: 45deg;
                        left: -7px;
                        top: 35%;
                        border-left: 1px solid $border-label-color;
                        border-bottom: 1px solid $border-label-color;
                        background-color: #FFFFFF;
                    }
                }
            }
        }

        .falsepositive_and_settings {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: auto;
            margin-bottom: 84px;

            .folder_falsepositive {
                @include containerFolder;
                margin-top: 12px;
                &:hover {
                    border: 1px solid #FDEDEE;
                }
                &.highlighted_folder_false_positives {
                    background-color: #FDEDEE;
                    &.empty {
                        opacity: .5;
                    }
                }

                &__name {
                    @include Aspekta-font(400, 12px, 18px, $default-text-color);
                    width: 70px;
                    text-align: center;
                    margin-top: 4px;
                }
            }

            .folder_settings {
                @include containerFolder;
                cursor: pointer;
                margin-top: 12px;
                &.highlighted_folder {
                    background-color: #EBF2FD;
                }
                &:hover {
                    border: 1px solid $border-folder-color;
                }
            }
        }

    }
}

@media (max-width: 576px) {
    .folder_menu {
        display: none;
    }
}

</style>
