<template>
    <div class="minimize-risks-automate-devsecops">
        <div class="minimize-risks-automate-devsecops__content">
            <span class="minimize-risks-automate-devsecops__main-text">{{ mainText }}</span>
            <div class="minimize-risks-automate-devsecops__second-text">
                <span class="minimize-risks-automate-devsecops__second-text__top">{{ secondTextTop }}</span>
                <span class="minimize-risks-automate-devsecops__second-text__bottom">{{ secondTextBottom }}</span>
            </div>
        </div>
        <div
            v-if="isDesktop"
            class="cards-content"
        >
            <AnimatedCard
                v-for="(card, index) in cards"
                :key="index"
                :current-index="index"
                :card="card"
                @start-scroll-by-card="startScrollByCard"
            />
        </div>
        <div
            v-else
            :class="{ mobileContent: isMobile, middleContent: isMiddleDesktopSize }"
        >
            <carousel
                ref="myCarousel"
                :per-page="1"
                :mouse-drag="true"
                :navigation-enabled="false"
                :pagination-color="'#727D8D80'"
            >
                <slide
                    v-for="(card, index) in cards"
                    :key="index"
                    :current-index="index"
                >
                    <AnimatedCard
                        :card="card"
                    />
                </slide>
            </carousel>
        </div>
    </div>
</template>
<script>

import AnimatedCard from './AnimatedCard.vue';

export default {
    components: {
        AnimatedCard,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            mainText: 'Minimize Risks, Automate DevSecOps',
            secondTextTop: 'Automate iOS & Android penetration testing for enhanced brand safety and team efficiency.',
            secondTextBottom: 'We aim to fortify your business from cyber threats and their devastating consequences',
            cardText: 'Expert Team',
            buttonText: 'Get Started',
            cards:[
                {
                    title: 'Code Control',
                    description: `Control the quality of all code your team produces,
                    preventing weak points, also conducting R&D to identify new attack vectors`,
                },
                {
                    title: 'Early Checks',
                    description: `We automatically check every piece of your mobile apps to identify
                    vulnerabilities in the early development stages`,
                },
                {
                    title: 'Diverse Analysis',
                    description: `We are using a range of different code analysis approaches
                    to identify all knowable vulnerabilities and we are constantly improving them`,
                },
                {
                    title: 'Expansive Rules',
                    description: 'Our vulnerability rule base is huge and constantly improves',
                },
                {
                    title: 'Expert Team',
                    description: `Our team has white hacker superstars who keep our product relevant
                    to every possible hacking scenario helping to prevent them`,
                },
            ],
        };
    },
    computed: {
        isDesktop(){
            return this.windowWidth > 1620;
        },
        isMiddleDesktopSize(){
            return this.windowWidth > 768 && !this.isDesktop;
        },
        isMobile(){
            return this.windowWidth <= 768;
        },

    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        startScrollByCard( currentIndex ){
            const cardsBlock = document.querySelector('.cards-content');
            const cardWidth = 800;
            const startByIndex= 2; // index from which we start the scroll
            setTimeout(() => {
                cardsBlock.scrollLeft += (currentIndex >= startByIndex)
                    ? cardWidth : -cardWidth;
            }, 350);

        },
    },

};
</script>
<style lang="scss" >
@import "../../../assets/css/variables.scss";
@import "../../../assets/css/variables/variablesForMinimizeRisks.scss";
@import "../../../assets/css/fonts";

.minimize-risks-automate-devsecops {
    width: 100vw;
    background-color: var(--bg-color-2, #F6F6F9);
    display: flex;
    flex-direction: column;
    text-align: center;
    &__content {
        margin: 160px 7% 150px;
        display: flex;
        justify-content: space-between;
    }
    &__main-text {
        width: 45%;
        margin-right: 22%;
        @include Aspekta-font(700, 60px, normal, $general-black);
        line-height: 120%; /* 72px */
        text-align: left;
    }
    &__second-text {
        width: 34%;
        display: flex;
        flex-direction: column;
        @include Aspekta-font(400, 18px, normal, #797E86);
        text-align: left;
        justify-content: space-between;

        &__bottom {
            margin-bottom: 2%;
        }
    }
}


.cards-content {
    margin: 0 7% 160px;
    height: 404px;
    display: flex;
    display: -webkit-box;
    overflow-x: hidden;
    gap: 20px;
    scroll-behavior: smooth;

}
.middleContent {
   margin-bottom: 160px;

    .VueCarousel-slide {
        flex-basis: auto;
        width: $dynamic-VueCarousel-width;
    }
    .VueCarousel-wrapper {
        overflow: visible;
    }
    .VueCarousel-inner {
        width: $dynamic-VueCarousel-width;
    }


}
.mobileContent {
    margin-left: $dynamic-mobile-cards-content-margin-l-r;
    margin-bottom: 80px;

    .VueCarousel {
        width: 100%;
    }

    .VueCarousel-slide {
        width: 300px;
        flex-basis: auto;
    }
    .VueCarousel-inner {
        width: 300px;
    }

}


@media (max-width: 768px ) {

    .minimize-risks-automate-devsecops__main-text {
        line-height: 130%; /* 72px */
        font-size: 32px;
        width: auto;
        margin: 0 ;
    }
    .minimize-risks-automate-devsecops__second-text {
        width: auto;
        font-size: 14px
    }
    .minimize-risks-automate-devsecops__second-text__bottom {
        margin-top: 5%;
    }
    .minimize-risks-automate-devsecops__content {
        margin-top: 80px;
        margin-bottom: 50px;
        gap: 30px;
        flex-direction: column;
    }
}

@media (max-width: 418px ) {

    .minimize-risks-automate-devsecops__main-text {
        font-size: 26px; //30-26
    }

}

</style>

