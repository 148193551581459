<template>
    <div>
        <PageNotFound />
    </div>
</template>
<script>
    import PageNotFound from "../components/pageNotFound/PageNotFound";

    export default {
        components: {
            PageNotFound
        }
    }
</script>

<style></style>
