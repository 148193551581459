<template>
    <div>
        <Pricing />
    </div>
</template>
<script>
    import Pricing from '../components/pricing/Pricing'
    export default {
        components: {
            Pricing
        }
    }
</script>

