import Vue from 'vue';

export const state = () => ({
    mfaDeviceList: [],
    createdDevice: null
});

export const mutations = {
    setMfaDeviceList: (state, devices) => {
        state.mfaDeviceList = devices;
    },
    setCreatedDevice: (state, device) => {
        state.createdDevice = device;
    }
};

export const actions = {
    async fetchMfaDevices({ commit }, payload) {
        try {
            const response = await Vue.axios.get('/private/ui/mfa/devices');
            commit('setMfaDeviceList', response.data);
        } catch (e) {
            console.log(e);
        }
    },

    async createDevice({ commit, dispatch }, { name }) {
        try {
            const response = await Vue.axios.post('/private/ui/mfa/devices', {
                name
            });
            commit('setCreatedDevice', {
                id: response.data.id,
                qrcode: response.data.qrcode,
                secret: response.data.secret
            });
            //refresh list
            dispatch('fetchMfaDevices');
        } catch (e) {
            console.log(e);
        }
    },

    async assignDevice({ commit, dispatch }, payload) {
        try {
            let response = await Vue.axios.post('/private/ui/mfa/devices/' + payload.id, {
                otp1: payload.otp1,
                otp2: payload.otp2
            });
            if (response.data && response.data.error) {
                return {
                    assigned: false,
                    error: response.data.error
                };
            } else {
                //refresh list
                await dispatch('fetchMfaDevices');
                return {
                    assigned: true
                };
            }

        } catch (e) {
            console.log(e);
            return {
                assigned: false,
                error: 'Something went wrong'
            };
        } finally {
            dispatch('clearTempData');
        }
    },

    async clearTempData({ commit }) {
        commit('setCreatedDevice', null);
    },

    async deleteMfaDevice({ dispatch }, id) {
        try {
            await Vue.axios.delete(`/private/ui/mfa/devices/${id}`);

            //refresh list
            await dispatch('fetchMfaDevices');
        } catch (error) {
            console.log(error);
        }
    },
};

export const getters = {
    getMfaDevices: s => s.mfaDeviceList,
    getCreatedDevice: s => s.createdDevice,
    isZeroMfaDevicesAssigned: s => s.mfaDeviceList.length === 0
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
