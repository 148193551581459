<template>
    <div class="severity_category__container">
        <!-- Trial Block -->
        <div
            v-if="isTrialRestriction && totalTrialVulnerabilitiesCount > 0"
            class="trial_restriction__block"
        >
            <div
                v-b-toggle="'trial-restriction-dropdown'"
                class="trial_restriction__dropdown-header"
            >
                <div class="trial_restriction__title">
                    {{ getVulnerabilityText() }}
                </div>
                <img
                    class="severity_category__open-close-icon"
                    style="margin-right: 20px;"
                    :class="{'rotated': isOpenTrialRestriction}"
                    src="../../../assets/images/myScans/open-dropdown-icon.svg"
                >
            </div>
            <b-collapse
                id="trial-restriction-dropdown"
                v-model="isOpenTrialRestriction"
                class="trial_restriction__dropdown-body"
            >
                <div class="trial_restriction__sub-title">
                    Please contact our team for more information about unlocking the report and subscription plans.
                </div>
                <div
                    v-show="isOpenTrialRestriction"
                    class="button"
                >
                    <b-nav-item
                        to="/contact-us"
                    >
                        <button class="trial_restriction__button">
                            Contact Us
                        </button>
                    </b-nav-item>
                </div>
                <div
                    v-for="(items, severityName) in itemsBySeverityTrial"
                    :key="severityName"
                    class="severity_category"
                >
                    <div
                        v-if="checkTrialCategoryVulnExist(severityName)"
                        class="custom-toggle"
                    >
                        <div
                            class="dropdown-header-container"
                        >
                            <div
                                v-b-toggle="'collapse-' + severityName"
                                class="dropdown-header"
                            >
                                <div class="severity_category__title__trial">
                                    {{ severityDescriptions[severityName] }}
                                </div>
                                <img
                                    class="severity_category__open-close-icon"
                                    :class="{'rotated': isTrialSeverityOpen[severityName]}"
                                    src="../../../assets/images/myScans/open-dropdown-icon.svg"
                                >
                            </div>
                        </div>
                    </div>
                    <b-collapse
                        :id="'collapse-' + severityName"
                        v-model="isTrialSeverityOpen[severityName]"
                    >
                        <div
                            v-for="item in items"
                            :key="item.type"
                            class="severity"
                            @mouseenter="hoveredCategoryItem = item"
                            @mouseleave="hoveredCategoryItem = null"
                        >
                            <div
                                class="dropdown_list_item_container"
                            >
                                <div
                                    class="category_item__trial"
                                    :title="item.type"
                                >
                                    {{ item.type }}
                                </div>
                                <div
                                    v-if="hoveredCategoryItem === item && isLargeScreen"
                                    class="category_actions"
                                >
                                    <Tooltip
                                        position="left"
                                        :tooltip="'Vulnerability Description'"
                                    >
                                        <img
                                            class="severity_category__info-icon"
                                            src="../../../assets/images/myScans/info-mark-icon.svg"
                                            alt="info icon"
                                            @click="openModal('general-popupVulnerabilityDescription-' + severityName + '-' + item.id)"
                                        >
                                    </Tooltip>
                                </div>
                                <div
                                    v-else
                                    class="category_responsive_actions"
                                >
                                    <div class="category_count__trial">
                                        {{ item.count }}
                                    </div>
                                    <div
                                        v-if="isSmallScreen || isMediumScreen"
                                        v-b-toggle="'collapse-' + severityName + '-' + item.id"
                                        class="category_more-button"
                                        @click="toggleMore(item)"
                                    >
                                        {{ currentOpenMore === item.id ? 'Less' : 'More' }}
                                    </div>
                                    <div
                                        v-if="isLargeScreen"
                                    >
                                        <Tooltip
                                            position="left"
                                            :tooltip="'Vulnerability Description'"
                                        >
                                            <img
                                                class="severity_category__info-icon"
                                                src="../../../assets/images/myScans/info-mark-icon.svg"
                                                alt="info icon"
                                                @click="openModal('general-popupVulnerabilityDescription-' + severityName + '-' + item.id)"
                                            >
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <b-collapse
                                v-if="currentOpenMore === item.id && (isSmallScreen || isMediumScreen)"
                                :id="'collapse-' + severityName + '-' + item.id"
                                class="category_actions_responsive"
                            >
                                <div
                                    class="category_responsive_actions__button__trial"
                                    :style="{ opacity: currentOpenMore === item.id ? 1 : 0 }"
                                    @click="openModal('general-popupVulnerabilityDescription-' + severityName + '-' + item.id)"
                                >
                                    <Tooltip
                                        position="top"
                                        :tooltip="'Vulnerability Description'"
                                    >
                                        <img
                                            class="severity_category__info-icon"
                                            src="../../../assets/images/myScans/info-mark-icon.svg"
                                            alt="info icon"
                                        >
                                    </Tooltip>
                                </div>
                            </b-collapse>

                            <GeneralPopup
                                :title-text="''"
                                :popup-id="'VulnerabilityDescription-' + severityName + '-' + item.id"
                                :hide-footer="true"
                                :hide-header="true"
                                :size="'lg'"
                                @onHidden="closeModal('general-popupVulnerabilityDescription-' + severityName + '-' + item.id)"
                            >
                                <div class="vulnerability-description-modal">
                                    <img
                                        src="../../../assets/images/closeModal_new.svg"
                                        class="modal-close"
                                        alt="close modal"
                                        @click="closeModal('general-popupVulnerabilityDescription-' + severityName + '-' + item.id)"
                                    >
                                    <div class="content-block">
                                        <div class="description-block">
                                            <div class="modal-title">
                                                Vulnerability Description
                                            </div>
                                            <div class="modal-text">
                                                {{ item.text }}
                                            </div>
                                        </div>
                                        <div class="remediation-block">
                                            <div class="modal-title">
                                                Remediation
                                            </div>
                                            <div class="modal-text">
                                                {{ item.remediation }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="links-block">
                                        <div class="modal-title">
                                            Links
                                        </div>
                                        <div
                                            v-for="link in parseLinks(item.links)"
                                            :key="link"
                                            class="custom-link"
                                            :title="link"
                                            @click.stop="routeToLink(link)"
                                        >
                                            {{ link }}
                                        </div>
                                    </div>
                                </div>
                            </GeneralPopup>
                        </div>
                    </b-collapse>
                </div>
            </b-collapse>
            <div
                v-show="!isOpenTrialRestriction"
                class="button"
            >
                <b-nav-item
                    to="/contact-us"
                >
                    <button class="trial_restriction__button">
                        Contact Us
                    </button>
                </b-nav-item>
            </div>
        </div>

        <!-- Default Filter -->
        <div
            v-for="(items, severityName) in filters"
            :key="severityName"
            class="severity_category"
        >
            <div class="custom-toggle">
                <div
                    class="dropdown-header-container"
                >
                    <b-form-checkbox
                        v-model="isSeverityChecked[severityName]"
                        :class="[severityName, {'checkbox-with-special': setSpecialCheckboxIndicator(items)}]"
                        :checked="allItemsChecked[severityName]"
                        :disabled="isDisabled(severityName)"
                        @change="toggleDropdown(severityName)"
                    />
                    <div
                        v-b-toggle="'collapse-' + severityName"
                        class="dropdown-header"
                    >
                        <div class="severity_category__title">
                            {{ severityDescriptions[severityName] }}
                        </div>
                        <img
                            class="severity_category__open-close-icon"
                            :class="{'rotated': isSeverityOpen[severityName]}"
                            src="../../../assets/images/myScans/open-dropdown-icon.svg"
                        >
                    </div>
                </div>
            </div>
            <b-collapse
                :id="'collapse-' + severityName"
                v-model="isSeverityOpen[severityName]"
            >
                <div
                    v-for="item in items"
                    :key="item.type"
                    class="severity"
                    @mouseenter="hoveredCategoryItem = item"
                    @mouseleave="hoveredCategoryItem = null"
                >
                    <div
                        class="dropdown_list_item_container"
                        :class="{ 'highlight-category': vulnerabilityCategoryId === item.id && !isMouseLeave }"
                    >
                        <b-form-checkbox
                            v-model="item.checked"
                            :class="severityName"
                            :disabled="isDisabled(severityName)"
                            @change="handleCheckboxChange(severityName)"
                        />
                        <div
                            class="category_item"
                            :title="item.type"
                        >
                            {{ item.type }}
                        </div>
                        <div
                            v-if="hoveredCategoryItem === item && isLargeScreen"
                            class="category_actions"
                        >
                            <Tooltip
                                position="left"
                                :tooltip="'Go to Vulnerability'"
                            >
                                <img
                                    class="severity_category__go-to-icon"
                                    :class="{'default-cursor': !item.checked}"
                                    src="../../../assets/images/myScans/go-to-icon.svg"
                                    alt="go to icon"
                                    @click="item.checked && handleSelectCategory(item.type, item.id)"
                                >
                            </Tooltip>
                            <Tooltip
                                position="left"
                                :tooltip="'Vulnerability Description'"
                            >
                                <img
                                    class="severity_category__info-icon"
                                    src="../../../assets/images/myScans/info-mark-icon.svg"
                                    alt="info icon"
                                    @click="openModal('general-popupVulnerabilityDescription-' + severityName + '-' + item.id)"
                                >
                            </Tooltip>
                        </div>
                        <div
                            v-else
                            class="category_responsive_actions"
                        >
                            <div class="category_count">
                                {{ item.count }}
                            </div>
                            <div
                                v-if="isLargeScreen"
                            >
                                <Tooltip
                                    position="left"
                                    :tooltip="'Vulnerability Description'"
                                >
                                    <img
                                        class="severity_category__info-icon"
                                        src="../../../assets/images/myScans/info-mark-icon.svg"
                                        alt="info icon"
                                        @click="openModal('general-popupVulnerabilityDescription-' + severityName + '-' + item.id)"
                                    >
                                </Tooltip>
                            </div>
                            <div
                                v-if="isSmallScreen || isMediumScreen"
                                v-b-toggle="'collapse-' + severityName + '-' + item.id"
                                class="category_more-button"
                                @click="toggleMore(item)"
                            >
                                {{ currentOpenMore === item.id ? 'Less' : 'More' }}
                            </div>
                        </div>
                    </div>
                    <b-collapse
                        v-if="currentOpenMore === item.id && (isSmallScreen || isMediumScreen)"
                        :id="'collapse-' + severityName + '-' + item.id"
                        class="category_actions_responsive"
                    >
                        <div
                            class="category_responsive_actions__button"
                            :style="{ opacity: currentOpenMore === item.id ? 1 : 0 }"
                            @click="openModal('general-popupVulnerabilityDescription-' + severityName + '-' + item.id)"
                        >
                            <Tooltip
                                position="top"
                                :tooltip="'Vulnerability Description'"
                            >
                                <img
                                    class="severity_category__info-icon"
                                    src="../../../assets/images/myScans/info-mark-icon.svg"
                                    alt="info icon"
                                >
                            </Tooltip>
                        </div>
                        <div
                            class="category_responsive_actions__button"
                            :style="{ opacity: currentOpenMore === item.id ? 1 : 0 }"
                            :class="{'default-cursor': !item.checked}"
                            @click="item.checked && handleSelectCategory(item.type, item.id)"
                        >
                            <Tooltip
                                position="top"
                                :tooltip="'Go to Vulnerability'"
                            >
                                <div>
                                    <img
                                        class="severity_category__go-to-icon"
                                        src="../../../assets/images/myScans/go-to-icon.svg"
                                        alt="go to icon"
                                    >
                                </div>
                            </Tooltip>
                        </div>
                    </b-collapse>

                    <GeneralPopup
                        :title-text="''"
                        :popup-id="'VulnerabilityDescription-' + severityName + '-' + item.id"
                        :hide-footer="true"
                        :hide-header="true"
                        :size="'lg'"
                        @onHidden="closeModal('general-popupVulnerabilityDescription-' + severityName + '-' + item.id)"
                    >
                        <div class="vulnerability-description-modal">
                            <img
                                src="../../../assets/images/closeModal_new.svg"
                                class="modal-close"
                                alt="close modal"
                                @click="closeModal('general-popupVulnerabilityDescription-' + severityName + '-' + item.id)"
                            >
                            <div class="content-block">
                                <div class="description-block">
                                    <div class="modal-title">
                                        Vulnerability Description
                                    </div>
                                    <div class="modal-text">
                                        {{ item.text }}
                                    </div>
                                </div>
                                <div class="remediation-block">
                                    <div class="modal-title">
                                        Remediation
                                    </div>
                                    <div class="modal-text">
                                        {{ item.remediation }}
                                    </div>
                                </div>
                            </div>
                            <div class="links-block">
                                <div class="modal-title">
                                    Links
                                </div>
                                <div
                                    v-for="link in parseLinks(item.links)"
                                    :key="link"
                                    class="custom-link"
                                    :title="link"
                                    @click.stop="routeToLink(link)"
                                >
                                    {{ link }}
                                </div>
                            </div>
                        </div>
                    </GeneralPopup>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import GeneralPopup from '../../popups/GeneralPopup';
import Tooltip from './Tooltip.vue';
import { FolderNameEnum } from '../../../utils/constants/common';
import { mapGetters } from 'vuex';
import { isVulnerabilityAmountPlural } from '../../../utils/helper-functions';
import { transformVulnerabilitiesIds, isEmpty, checkIfMobile } from '../../../services/functions';

export default {
    components: {
        Tooltip,
        GeneralPopup,
    },
    props: {
        itemsBySeverity: Object,
        itemsBySeverityTrial: Object,
        isTrialRestriction: Boolean,
        integrationId: String,
        jobId: String,
        selectCategory: Function,
        branchName: {
            type: String,
        },
        isSmallScreen: {
            type: Boolean,
            default: false,
        },
        isMediumScreen: {
            type: Boolean,
            default: false,
        },
        isLargeScreen: {
            type: Boolean,
            default: false,
        },
        allVulnerabilitiesFolder: {
            type: Array,
            required: false,
            default: () => [],
        },
        vulnerabilityCategoryId: String,
        isMouseLeave: Boolean,
    },
    data() {
        return {
            isOpenTrialRestriction: true,
            dropdownOpenItem: null,
            currentOpenMore: null,
            isTrialSeverityOpen: {
                'high': true,
                'medium': true,
            },
            modalTitle: '',
            severityDescriptions: {
                'high': 'High severity',
                'medium': 'Medium severity',
                'low': 'Low severity',
            },
            hoveredCategoryItem: null,
            isFirstPage: false,
        };
    },
    computed: {
        ...mapGetters({
            vulnerabilityPage: 'jobs/vulnerabilityPage',
            folders: 'jobs/folders',
            currentJob: 'jobs/currentJob',
            isMovingToFolder: 'folders/isMovingToFolder',
            selectedVulnerabilitiesIds: 'jobs/selectedVulnerabilitiesIds',
            filters: 'filters/filters',
            isSeverityChecked: 'filters/isSeverityChecked',
            isSeverityOpen: 'filters/isSeverityOpen',
            allSeveritiesUnchecked: 'filters/allSeveritiesUnchecked',
        }),
        isDisabled() {
            return severity => this.isTrialRestriction && (severity === 'high' || severity === 'medium');
        },
        allItemsChecked() {
            let result = {};
            for (const severity in this.filters) {
                result[severity] = this.filters[severity].every(item => item.checked);
            }
            return result;
        },
        highVulnerabilitiesCount() {
            return this.calculateVulnerabilities('high');
        },
        mediumVulnerabilitiesCount() {
            return this.calculateVulnerabilities('medium');
        },
        totalTrialVulnerabilitiesCount() {
            return this.highVulnerabilitiesCount + this.mediumVulnerabilitiesCount;
        },
    },
    watch: {
        itemsBySeverity: {
            handler() {
                this.updateCheckedStatusByCategoriesQuery();
                if (!this.isMovingToFolder) this.$store.commit('filters/setFilters', this.itemsBySeverity);
                this.$store.commit('filters/updateSeverityCheckedIncludingTrialRestriction', this.isTrialRestriction);
            },
            deep: true,
        },
        '$route.query': {
            deep: true,
            async handler() {
                // need to open all dropdowns when route is changed to another folder or page
                if(!this.$route.query?.categories && !this.$route.query?.page) {
                    this.$store.commit('filters/openAllSeverityDropdowns');
                    this.isOpenTrialRestriction = true;
                }
            },
        },
    },
    mounted() {
        this.updateCheckedStatusByCategoriesQuery();
        this.$store.commit('filters/setFilters', this.itemsBySeverity);
        if ((!this.checkIfMobile(window) && window.innerWidth >= 900) && !isEmpty(this.filters)) this.$store.commit('filters/setSidebarVisiblity', true);
        this.$store.commit('filters/updateSeverityCheckedIncludingTrialRestriction', this.isTrialRestriction);
        const selectedVulnerabilities = [];
        for (const severity in this.filters) {
            const severityOpen = this.filters[severity].every(item => !item.checked);
            if (severityOpen) {
                this.updateSeverityOpen(severity);
            }
            this.filters[severity].forEach((vulnerability) => {
                if (vulnerability.checked) {
                    selectedVulnerabilities.push({ categoryId: vulnerability.id, count: vulnerability.count });
                }
            });
        }
        this.$store.commit('jobs/setSelectedVulnerabilityCategoriesIds', { id: this.$route.params.id, vulnerabilities: selectedVulnerabilities });
    },
    methods: {
        setSpecialCheckboxIndicator(items) {
            const selectedVulnerabilitiesIds = this.transformVulnerabilitiesIds(
                this.selectedVulnerabilitiesIds,
            )[this.$route.params.id];

            return selectedVulnerabilitiesIds
                ? items.some(item => !selectedVulnerabilitiesIds.includes(item.id))
                : false;
        },
        updateCheckedStatusByCategoriesQuery() {
            const categories = this.$route.query?.categories;

            if (this.$route.query.hasOwnProperty('categories')) {
                if(categories) {
                    const queryIds = categories.split(',');
                    for (const severity in this.itemsBySeverity) {
                        this.itemsBySeverity[severity].forEach(item => {
                            if (!queryIds.includes(item.id)) {
                                item.checked = false;
                            }
                        });
                    }
                }
                else {
                    for (const severity in this.itemsBySeverity) {
                        this.itemsBySeverity[severity].forEach(item => {
                            item.checked = false;
                        });
                    }
                }
            }
        },
        checkTrialCategoryVulnExist(severityName) {
            return this.itemsBySeverityTrial[severityName] === undefined || this.itemsBySeverityTrial[severityName].length === 0 ? false : true;
        },
        calculateVulnerabilities(severity) {
            // allVulnerabilitiesFolder[0] is AllVulnerabilities folder
            return this.allVulnerabilitiesFolder[0].statistics.find(item => item.severity === severity)?.count || 0;
        },
        getVulnerabilityText() {
            const high = this.highVulnerabilitiesCount > 0 ? 'High' : '';
            const medium = this.mediumVulnerabilitiesCount > 0 ? 'Medium' : '';
            const isBoth = (high && medium) ? ' and ': '';
            return `${high}${isBoth}${medium} severity ${this.isVulnerabilityAmountPlural(this.totalTrialVulnerabilitiesCount, true)} hidden in your subscription plan.`;
        },
        toggleDropdown(severity) {
            const newCheckState = this.isSeverityChecked[severity];
            this.filters[severity].forEach((item, index) => {
                this.$set(this.filters[severity][index], 'checked', newCheckState);
            });
            this.handleCheckboxChange(severity);
        },
        toggleMore(item) {
            if (this.currentOpenMore === item.id) {
                this.currentOpenMore = null; // If the currently open item is clicked again, close it
            } else {
                this.currentOpenMore = item.id; // Otherwise, open the clicked item and close any others
            }
        },
        openModal(modalId) {
            this.$bvModal.show(modalId);
        },
        closeModal(modalId) {
            this.$bvModal.hide(modalId);
        },
        parseLinks(jsonArray) {
            try {
                return JSON.parse(jsonArray);
            } catch(err) {
                return [];
            }
        },
        routeToLink(link) {
            window.open(link);
        },
        async handleCheckboxChange(severity) {
            const selectedVulnerabilitiesIds = [];
            let pageNumber = 1;
            // set special page number flag for further payload page number definition
            this.isFirstPage = this.allSeveritiesUnchecked;

            // we need to get all checked vulnerabilities ids and save them in the store
            for (const severityLevel in this.filters) {
                this.filters[severityLevel].forEach((vulnerability) => {
                    if (vulnerability.checked) {
                        selectedVulnerabilitiesIds.push({ categoryId: vulnerability.id, count: vulnerability.count });
                    }
                    if (vulnerability.checked !== this.filters[severityLevel][vulnerability.id]?.checked) {
                        this.$store.commit('filters/updateFilter', {
                            severity: severityLevel,
                            vulnerabilityId: vulnerability.id,
                            checked: vulnerability.checked,
                        });
                    }
                });
            }

            if (selectedVulnerabilitiesIds.length !== 0) {
                const categoryIds = selectedVulnerabilitiesIds.map(item => item.categoryId);

                this.$router.replace({ 
                    query: { 
                        ...this.$route.query, 
                        categories: categoryIds.join(','),
                    },
                });

                pageNumber = this.definePageNumber(selectedVulnerabilitiesIds);

            }  else if (this.$route.query.categories) {
                this.$router.replace({ 
                    query: { 
                        ...this.$route.query, 
                        categories: '',
                    },
                });
            }

            // Recheck the header checkboxes: if at least one item is checked, the header checkbox should be checked too
            this.$store.commit('filters/updateSeverityCheckedIncludingTrialRestriction', this.isTrialRestriction);

            this.$store.commit('jobs/startOperation'); // start spinner
            // save the selected vulnerabilities ids in the store
            await this.$store.commit('jobs/setSelectedVulnerabilityCategoriesIds', { id: this.$route.params.id, vulnerabilities: selectedVulnerabilitiesIds });

            if(Number(this.$route.query?.page) !== pageNumber) {
                this.$router.replace({ 
                    query: { 
                        ...this.$route.query, 
                        page: pageNumber,
                    },
                });
            }

            // load the page
            const payload = {
                id: this.$route.params.id,
                integrationId: this.integrationId,
                limit: 20,
                branchName: this.branchName,
                folderName: this.$route.query?.folderName || FolderNameEnum.ALL_VULNERABILITIES,
                reqType: 'GET_PAGE',
                shared: this.currentJob?.data.shared,
                page: pageNumber,

            };

            /**
             * If all isSeverityChecked state are false we shouldn't chooseAppropriatePageAction
             * and store commit that will tell Vulnerabilities In Code that All categories are unselected
             */
            if (!this.allSeveritiesUnchecked) {
                await this.$store.dispatch('jobs/chooseAppropriatePageAction', payload);
            } else {
                this.$store.commit('jobs/setPreventPageLoadDuplication', false);
            }

            this.updateSeverityOpen(severity);

            this.$store.commit('jobs/endOperation'); // end spinner
        },

        updateSeverityOpen(severity) {
            const newCheckState = this.isSeverityChecked[severity];

            // open the dropdown if the header checkbox is checked
            if (newCheckState) {
                this.$store.commit('filters/updateSeverityOpen', { severity, isOpen: true });
            }
            // close the dropdown if the header checkbox is unchecked
            else {
                this.$store.commit('filters/updateSeverityOpen', { severity, isOpen: false });
            }
        },
        async handleSelectCategory(categoryType, categoryId) {

            let body = {
                integrationId: this.integrationId,
                branchName: this.$route.params.branchName,
                id: this.$route.params.id,
                categoryId,
                limit: 20,
                folderName: this.$route.query.folderName ? this.$route.query.folderName : FolderNameEnum.ALL_VULNERABILITIES,
                reqType: 'FIND_PAGE_BY_CATEGORY_ID',
                shared: this.currentJob?.data.shared,
                selectedCategoryType: categoryType,
            };

            this.$emit('selectCategory', { categoryType, categoryId, body });
        },
        getTotalCount(vulnerabilities) {
            return vulnerabilities.reduce((total, vulnerability) => total + parseInt(vulnerability.count, 10), 0);
        },
        definePageNumber(selectedVulnerabilitiesIds) {
            const paginationLimit = 20;
            const selectedVulnerabilitiesCount = this.getTotalCount(selectedVulnerabilitiesIds);
            const calculatedPageLimit = Math.ceil(this.getTotalCount(selectedVulnerabilitiesIds) / paginationLimit);

            if (this.vulnerabilityPage.page > calculatedPageLimit) {
                return calculatedPageLimit;
            } else {
                return this.vulnerabilityPage.page;
            }
        },
        isVulnerabilityAmountPlural,
        transformVulnerabilitiesIds,
        checkIfMobile,
        isEmpty,
    },
};
</script>

<style lang="scss">
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

.dropdown-header-container, .dropdown_list_item_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
}

.dropdown-header-container {
    justify-content: flex-start;
    height: 44px;

    .dropdown-header, .trial-restriction-dropdown {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-right: 20px;
        padding: 0;
        position: static !important;
        outline: none;

        &.not-collapsed {
            transform: rotate(0turn);
            -webkit-transform: rotate(0turn);
            outline: none;
        }
    }
}

.trial_restriction__dropdown-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-right: 20px;
    padding: 0;
    position: static !important;
    outline: none;

    &.not-collapsed {
        transform: rotate(0turn);
        -webkit-transform: rotate(0turn);
        outline: none;
    }
}

.severity_category {

    &__container {
        padding-top: 20px;
    }

    &__title {
        @include Aspekta-font(600, 17px, 24px, #021631);
        letter-spacing: 0.34px;
        padding: 10px 0;

        &__trial {
            @include Aspekta-font(600, 17px, 24px, #949DB8);
            letter-spacing: 0.34px;
            padding: 10px 0;

        }
    }

    &__open-close-icon {
        display: flex;
        transition: transform 0.3s ease-in-out;
        margin-left: auto;
    }
}
.highlight-category {
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #E3E6ED; 
}

.dropdown_list_item_container {
    justify-content: flex-start;
    height: 57px;
    padding-left: 10px;
    margin-left: 10px;

    .category_item {
        @include Aspekta-font(400, 14px, 20px, #021631);
        letter-spacing: 0.28px;
        text-decoration: none;
        max-width: 230px;
        margin: 8.5px 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        &__trial {
            @include Aspekta-font(400, 14px, 20px, #949DB8);
            letter-spacing: 0.28px;
            text-decoration: none;
            max-width: 230px;
            margin: 8.5px 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .category_responsive_actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 12px 0 auto;
    }

    .category_more-button {
        @include Aspekta-font(500, 11px, 16.5px, #3180F6);
        letter-spacing: 0.55px;

        &.not-collapsed, &.collapsed {
            position: relative !important;
            transform: rotate(0turn);
            -webkit-transform: rotate(0turn);
            right: 0;
        }
    }

    .category_count {
        @include Aspekta-font(600, 17px, 24px, #5C6A7A);
        letter-spacing: 0.34px;
        margin: 0 auto;

        &__trial {
            @include Aspekta-font(600, 17px, 24px, #949DB8);
            letter-spacing: 0.34px;
            margin: 0 auto;
        }
    }

    .category_actions {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin: 0 20px 0 auto;

        img {
            cursor: pointer;
        }

        .default-cursor {
            cursor: default;
        }


        &__button {
            display: flex;
            padding: 13px 8px;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 40px;

            border-radius: 8px;
            border: 2px solid #E3E6ED;

            &:hover {
                box-shadow: 0px 12px 16px 0px rgba(178, 185, 195, 0.12);
            }
        }
    }
}

.category_actions_responsive {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px 0 8px 20px;
    transition: all 0.2s ease-in-out;
}



.category_responsive_actions {
    display: flex;
    flex-direction: row;
    gap: 8px;

    &__button {
        display: flex;
        padding: 13px 8px;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 40px;

        border-radius: 8px;
        border: 2px solid #E3E6ED;

        cursor: pointer;

        &__trial {
            display: flex;
            padding: 13px 8px;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 40px;

            border-radius: 8px;
            border: 2px solid #E3E6ED;

            cursor: pointer;
        }

        &:hover {
            box-shadow: 0px 12px 16px 0px rgba(178, 185, 195, 0.12);
        }
    }


}

.default-cursor {
    cursor: default !important;
}

.severity:hover {
    background-color: #FFFFFF;
    box-shadow: 0px 12px 16px rgba(178, 185, 196, 0.12);
}

/** MODAL Vulnerability Details */
.vulnerability-description-modal {
    display: flex;
    flex-direction: column;
    position: relative;

    .modal-close {
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
        z-index: 100;
    }

    .modal-title {
        @include Aspekta-font(600, 18px, 26px, #021631);
        margin-bottom: 16px;
    }

    .modal-text {
        @include Aspekta-font(400, 14px, 20px, #5C6A7A);
        letter-spacing: 0.02em;
        word-break: break-word;
    }

    .content-block {
        display: flex;
        flex-direction: row;
        margin: 48px 0px 24px 0px;
        gap: 24px;

        .description-block,
        .remediation-block {
            max-width: 322px;
        }
    }

    .links-block {
        margin-bottom: 24px;
        word-break: break-all;

        .custom-link {
            @include Aspekta-font(500, 14px, 20px, #3180F6);
            text-decoration: none;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

}

/** ⬇︎⬇︎⬇︎ This part is related to TRIAL visualisation ⬇︎⬇︎⬇︎ */

.trial_restriction {

    &__dropdown-header {
        display: flex;
        width: 100%;
    }

    &__dropdown-body {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .button {
            width: 100%;
            padding: 10px 0;
        }

    }

    &__title, &__sub-title {
        @include Aspekta-font(500, 14px, 20px, #949DB8);
        letter-spacing: 0.42px;
        width: 292px;
        height: auto;
        margin-left: 20px;
    }

    &__sub-title {
        margin: 20px 0 10px 20px;
    }

    &__button {
        @include Aspekta-font(600, 17px, normal, #FF7A08);
        letter-spacing: 0.34px;
        width: 100%;
        display: flex;
        padding: 16px 12px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 2px solid #FFD2AB;
        background-color: #FFF;
        margin: 0 20px;
        height: 56px;

        &:hover {
            box-shadow: 0px 8px 16px 0px rgba(255, 180, 0, 0.24);
        }
    }
}

/** ⬇︎⬇︎⬇︎ This part is related to the custom checkbox ⬇︎⬇︎⬇︎ */
.custom-control-input {
    height: 1rem;
}

// remove default checkbox color
.high .custom-control-label::after,
.medium .custom-control-label::after,
.low .custom-control-label::after {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    content: "";
}

.high, .medium, .low {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    cursor: default;
}

.high .custom-control-label,
.medium .custom-control-label,
.low .custom-control-label {
    width: 100%;
    height: 100%;
}

// This part is to remove the blue default outline when clicking on the checkbox
.high .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #FDC7C0;
    box-shadow: none;
}

.high .custom-control-input:focus:checked ~ .custom-control-label::before {
    border-color: #FEEAE7;
    box-shadow: none;
}

.medium .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color:  #FCE79B;;
    box-shadow: none;
}

.medium .custom-control-input:focus:checked ~ .custom-control-label::before {
    border-color: #FDF3CD;
    box-shadow: none;
}

.low .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #B7F3C7;
    box-shadow: none;
}

.low .custom-control-input:focus:checked ~ .custom-control-label::before {
    border-color: #E3FFEA;
    box-shadow: none;
}

// This part is to change the color of the checkbox when it is checked
.high .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #FEEAE7;
    border-color: #FEEAE7;
}

.medium .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #FDF3CD;
    border-color: #FDF3CD;
}

.low .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #E3FFEA;
    border-color: #B7F3C7;
}

// This part is to change the color of the checkbox when it is not checked
.high .custom-control-input ~ .custom-control-label::before {
    border-color: #FDC7C0;
    background-color: rgba(255, 255, 255, 0);
}

.medium .custom-control-input ~ .custom-control-label::before {
    border-color: #FCE79B;
    background-color: rgba(255, 255, 255, 0);
}

.low .custom-control-input ~ .custom-control-label::before {
    border-color: #B7F3C7;
    background-color: rgba(255, 255, 255, 0);
}

// Hover styles when checkbox is not checked
.high .custom-control-input:hover:not(:checked) ~ .custom-control-label::before {
    border: 2px solid #FB7462;
    box-shadow: 0px 8px 16px rgba(251, 141, 98, 0.24);
    background: #FEEAE7;
    border-radius: 6px;
    transform: scale(1.25);
    transform-origin: center center;
}

.medium .custom-control-input:hover:not(:checked) ~ .custom-control-label::before {
    border: 2px solid #F7C602;
    box-shadow: 0px 8px 16px rgba(253, 211, 101, 0.32);
    background-color: #FDF3CD;
    border-radius: 6px;
    transform: scale(1.25);
    transform-origin: center center;

}

.low .custom-control-input:hover:not(:checked) ~ .custom-control-label::before {
    border: 2px solid #4CE074;
    box-shadow: 0px 8px 16px rgba(97, 212, 179, 0.24);
    background-color: #E3FFEA;
    border-radius: 6px;
    transform: scale(1.25);
    transform-origin: center center;

}

// Hover styles when checkbox is checked
.high .custom-control-input:hover:checked ~ .custom-control-label::before {
    border: 2px solid #FDC7C0;
    background-color: #FEEAE7;
    box-shadow: 0px 8px 16px rgba(251, 141, 98, 0.24);
    border-radius: 6px;
    transform: scale(1.25);
    transform-origin: center center;
}

.medium .custom-control-input:hover:checked ~ .custom-control-label::before {
    border: 2px solid #FCE79B;
    box-shadow: 0px 8px 16px rgba(253, 211, 101, 0.32);
    border-radius: 6px;
    transform: scale(1.25);
    transform-origin: center center;
}

.low .custom-control-input:hover:checked ~ .custom-control-label::before {
    border: 2px solid #B7F3C7;
    box-shadow: 0px 8px 16px rgba(97, 212, 179, 0.24);
    border-radius: 6px;
    transform: scale(1.25);
    transform-origin: center center;
}

// This part is to change icon of the checkbox when it is checked
.high .custom-control-input:checked ~ .custom-control-label::after {
    background: url('../../../assets/images/checkbox-icon-high.svg') no-repeat center center;
}

.medium .custom-control-input:checked ~ .custom-control-label::after {
    background: url('../../../assets/images/checkbox-icon-medium.svg') no-repeat center center;
}

.low .custom-control-input:checked ~ .custom-control-label::after {
    background: url('../../../assets/images/checkbox-icon-low.svg') no-repeat center center;
}

.high.checkbox-with-special .custom-control-input:checked ~ .custom-control-label::after {
  background: url('../../../assets/images/checkbox-icon-high_special.svg') no-repeat center center;
}

.medium.checkbox-with-special .custom-control-input:checked ~ .custom-control-label::after {
  background: url('../../../assets/images/checkbox-icon-medium_special.svg') no-repeat center center;
}

.low.checkbox-with-special .custom-control-input:checked ~ .custom-control-label::after {
  background: url('../../../assets/images/checkbox-icon-low_special.svg') no-repeat center center;
}
/**  ⬆︎⬆︎⬆︎ This part is for checkbox ⬆︎⬆︎⬆︎ */

.rotated {
    transform: rotate(180deg);
}

@media (max-width: 834px) {
    .severity:hover {
        background-color: #FFFFFF;
        box-shadow: none;
    }

    .vulnerability-description-modal {
        .content-block {
            flex-direction: column;

            .description-block,
            .remediation-block {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 576px) {
    .category_actions_responsive {
        padding: 8px 20px;
    }

    .dropdown_list_item_container {
        .category_item {
        max-width: 280px;

        &__trial {
            max-width: 320px;
        }
    }
    }
}


</style>
