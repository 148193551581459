<template>
    <div class="trial-banner">
        <div
            class="trial-banner__container"
            :style="{ 'flex-wrap': computedFlexWrap }"
        >
            <customNumber :number="trialVulnerabilitiesCount" />

            <div class="text-and-buttons">
                <h1
                    class="title"
                    v-html="'Vulnerabilities Found'"
                />

                <h1
                    class="description"
                    v-html="getVulnerabilityText()"
                />
                <div class="description">
                    Please contact our team for more information about unlocking the report and subscription plans.
                </div>
                <div
                    class="buttons"
                >
                    <b-nav-item
                        to="/contact-us"
                    >
                        <ConfigurablePopupButton
                            class="contact-us-button"
                            :button-text="'Contact Us'"
                            :icon="false"
                            :disabled="false"
                            button-type="add"
                        />
                    </b-nav-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import customNumber from './customNumber.vue';
import { isSeverityExist } from '../../../services/functions';
import { isVulnerabilityAmountPlural } from '../../../utils/helper-functions';
import DOMPurify from 'dompurify';
import ConfigurablePopupButton from '../../popups/ConfigurablePopupButton.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        customNumber,
        ConfigurablePopupButton,
    },
    props: {
        job: {
            type: Object,
            required: true,
        },
        highSeverityCount: {
            type: Number,
            required: false,
            default: 0,
        },
        mediumSeverityCount: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            severityCounts: {},
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        ...mapGetters( 'filters' ,{
            isSidebarVisible: 'isSidebarVisible',
        }),
        trialVulnerabilitiesCount() {
            return this.highSeverityCount + this.mediumSeverityCount;
        },
        computedFlexWrap(){
            return this.windowWidth < 1000
                && this.trialVulnerabilitiesCount > 99
                && this.isSidebarVisible
                ? 'wrap': '';
        },
    },
    mounted() {
        window.addEventListener('resize',this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        getVulnerabilityText() {
            const high = this.highSeverityCount > 0 ? '<span class="high">High</span>' : '';
            const medium = this.mediumSeverityCount > 0 ? '<span class="medium">Medium</span>' : '';
            const space = '<span>&nbsp;</span>';
            const isBoth = (high && medium) ? space + 'and ' + space : '';
            const dirtyHTML = `${high}${isBoth}${medium}${(high && medium) || high || medium ? space : ''}severity${space}${this.isVulnerabilityAmountPlural(this.trialVulnerabilitiesCount, true, space)}${space}hidden${space}in${space}your${space}subscription${space}plan.`;

            return DOMPurify.sanitize(dirtyHTML);
        },
        isSeverityExist,
        isVulnerabilityAmountPlural,
    },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';



.trial-banner {
    background: linear-gradient(276.01deg, rgba(255, 193, 21, 0.12) 4.87%, rgba(255, 75, 52, 0.12) 137.24%),
                linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border-radius: 20px;
    width: 100%;
    margin-top: 40px;
}

.trial-banner__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    padding: 40px $dynamic-trial-banner-container-vertical-padding;

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        height: auto;
        flex-wrap: wrap;

        .contact-us-button {
            min-width: 174px;
            width: fit-content;
        }
    }
}

.text-and-buttons {
    display: flex;
    max-width: 606px;
    gap: 20px;
    flex-direction: column;

    .title {
        @include Aspekta-font(700, $dynamic-trial-banner-title-font-size, normal, #0C0D0E);
    }

    .description {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        @include Aspekta-font(400, 18px, normal, #0C0D0E);
        letter-spacing: 0.66px;

        ::v-deep .high {
            color: $high-vulnerability-color;
            font-weight: 850;
            width: fit-content;
        }


        ::v-deep .medium {
            color: $medium-vulnerability-color;
            font-weight: 850;
            width: fit-content;
        }
    }
}

@media (max-width: 1200px) {
    .trial-banner__container {
        justify-content: flex-start;
        flex-wrap: nowrap;
    }
}

@media (max-width: 768px) {
    .trial-banner__container {
        padding: 40px 30px;
        flex-wrap: wrap;
    }
}


</style>
