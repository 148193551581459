import Vue from "vue";

const VUE_APP_AUTH_BASE_URL = process.env.VUE_APP_AUTH_BASE_URL

export const signUpResearcherRequest = (payload) => {
    return Vue.axios.post(
        `${VUE_APP_AUTH_BASE_URL}/user/registration`,
        payload
    )
}
