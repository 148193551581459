<template>
  <div class="markdown">
    <p >Webhooks are available to enterprise users who use Integrations. Oversecured notifies users about all changes to versions' status.</p>
    <p><strong>Example delivery</strong></p>
    <div class="code-toolbar">
<pre class="language-json">
POST /payload HTTP/2
Host: localhost:8080
Content-Type: application/json
Content-Length: 6615
 <code class="language-json">
{
  <span class="token property">"action"</span><span class="token operator">: </span><span class="token string">"version_finished"</span><span class="token punctuation">,</span>
  <span class="token property">"integration"</span><span class="token operator">: </span><span class="token punctuation">{</span>
    <span class="token property">"id"</span><span class="token operator">: </span><span class="token string">"5e7096e2-7fed-43f0-9290-22d03c64cfbf"</span><span class="token punctuation">,</span>
    <span class="token property">"name"</span><span class="token operator">: </span><span class="token string">"Oversecured Vulnerable Android App"</span><span class="token punctuation">,</span>
    <span class="token property">"description"</span><span class="token operator">: </span><span class="token string">"This app includes many known vulnerabilities on Android"</span><span class="token punctuation">,</span>
    <span class="token property">"status"</span><span class="token operator">: </span><span class="token string">"ACTIVE"</span><span class="token punctuation">,</span>
    <span class="token property">"expires_at"</span><span class="token operator">: </span><span class="token string">"2023-04-03T15:48:15.946Z"</span><span class="token punctuation">,</span>
    <span class="token property">"platform"</span><span class="token operator">: </span><span class="token string">"android"</span><span class="token punctuation">,</span>
    <span class="token property">"app_id"</span><span class="token operator">: </span><span class="token string">"oversecured.ovaa"</span><span class="token punctuation">,</span>
    <span class="token property">"logo_url"</span><span class="token operator">: </span><span class="token string">"https://redacted.s3.amazonaws.com/f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/77e31a12-82cc-4b67-b3f0-15b244acb5a2.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUC5S2Y2XMUGCWT7O%2F20201205%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20201205T232500Z&X-Amz-Expires=86400&X-Amz-Signature=c87e268706dd1123c023f46a80e85f7a9b34ec9b2516161edf080867a1b5fe33&X-Amz-SignedHeaders=host"</span><span class="token punctuation">,</span>
    <span class="token property">"smart_filter_enabled"</span><span class="token operator">: </span><span class="token boolean">true</span><span class="token punctuation">,</span>
    <span class="token property">"notify_when_done"</span><span class="token operator">: </span><span class="token boolean">true</span>
  <span class="token punctuation">}</span><span class="token punctuation">,</span>
  <span class="token property">"branch_name"</span><span class="token operator">: </span><span class="token string">"main"</span><span class="token punctuation">,</span>
  <span class="token property">"version"</span><span class="token operator">: </span><span class="token punctuation">{</span>
    <span class="token property">"id"</span><span class="token operator">: </span><span class="token string">"49d4c5f6-a804-41c8-b7e8-21136897c528"</span><span class="token punctuation">,</span>
    <span class="token property">"file_name"</span><span class="token operator">: </span><span class="token string">"ovaa_1.1.apk"</span><span class="token punctuation">,</span>
    <span class="token property">"platform"</span><span class="token operator">: </span><span class="token string">"android"</span><span class="token punctuation">,</span>
    <span class="token property">"version_id"</span><span class="token operator">: </span><span class="token string">"1.1"</span><span class="token punctuation">,</span>
    <span class="token property">"status"</span><span class="token operator">: </span><span class="token string">"FINISH"</span><span class="token punctuation">,</span>
    <span class="token property">"version_compare"</span><span class="token operator">: </span><span class="token punctuation">{</span>
      <span class="token property">"high"</span><span class="token operator">: </span><span class="token punctuation">{</span>
        <span class="token property">"fixed"</span><span class="token operator">: </span><span class="token number">0</span><span class="token punctuation">,</span>
        <span class="token property">"created"</span><span class="token operator">: </span><span class="token number">1</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span>
      <span class="token property">"medium"</span><span class="token operator">: </span><span class="token punctuation">{</span>
        <span class="token property">"fixed"</span><span class="token operator">: </span><span class="token number">0</span><span class="token punctuation">,</span>
        <span class="token property">"created"</span><span class="token operator">: </span><span class="token number">0</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span>
      <span class="token property">"low"</span><span class="token operator">: </span><span class="token punctuation">{</span>
        <span class="token property">"fixed"</span><span class="token operator">: </span><span class="token number">0</span><span class="token punctuation">,</span>
        <span class="token property">"created"</span><span class="token operator">: </span><span class="token number">0</span>
      <span class="token punctuation">}</span>
    <span class="token punctuation">}</span><span class="token punctuation">,</span>
    <span class="token property">"completed_at"</span><span class="token operator">: </span><span class="token string">"2022-04-03T22:40:32.662Z"</span>
   <span class="token punctuation">}</span>
<span class="token punctuation">}</span></code></pre>
        </div>
        <p><strong>Possible actions</strong></p>
        <table class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>verson_added</td>
                    <td>User sent a new version for upload</td>
                </tr>
                <tr>
                    <td>version_finished</td>
                    <td>Scan of version was completed</td>
                </tr>
                <tr>
                    <td>version_failed</td>
                    <td>Error occurred when scanning file</td>
                </tr>
                <tr>
                    <td>version_deleted</td>
                    <td>User deleted version</td>
                </tr>
            </tbody>
        </table>
        <p>The HTTP request format for all actions is the same: it includes all known information about the version. If any fields are undefined (e.g. the <code>version_compare</code> field for a failed scan of a version), <code>null</code> is passed as the value of those fields.</p>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.table thead th {
    vertical-align: bottom;
    border-bottom: 3px solid #dee2e6;
}
</style>
