<template>
    <div class="main-top">
        <div class="main-top__wrapper">
            <div class="main-top__content">
                <div class="main-top__content-text">
                    <div class="main-top__content-text-mobile">
                        <div class="main-top__sub-title">
                            Mobile App Security Provider
                        </div>
                        <div class="main-top__title">
                            <p>
                                <span class="main-top__title--line">
                                    Protect
                                    <OrangeLine
                                        class="orange-line"
                                    />
                                </span> Your Company<br>
                                From Data Breaches<br>
                                & Other Cyber Threats
                            </p>
                        </div>
                        <div class="main-top__description">
                            Fortify your Android & iOS apps with our automated scanning
                            technology to minimize brand risks and security costs
                        </div>
                        <div class="main-top__buttons">
                            <OrangeArrowButton
                                :title="'Check Your Vulnerabilities'"
                            />
                            <button
                                class="main-top__buttons--more"
                                @click="requestDemo"
                            >
                                Learn More
                            </button>
                        </div>
                    </div>
                    <div class="main-top__animation-image">
                        <img
                            src="../../../assets/images/main/report-animation-img.svg"
                            alt="animation"
                        >
                    </div>
                    <div class="main-top__achievements">
                        <div class="main-top__achievements--smsng">
                            <img
                                src="../../../assets/images/main/main-top-achievements-samsung.svg"
                            >
                            <img
                                src="../../../assets/images/main/main-top-achievements-samsung-mobile.svg"
                                class="smsng-mobile"
                            >
                            <p>Oversecured ranked #1 in Samsung's mobile vulnerability detection program</p>
                        </div>
                        <div class="main-top__achievements--ada">
                            <img
                                src="../../../assets/images/main/main-top-achievements-ada.svg"
                            >
                            <img
                                src="../../../assets/images/main/main-top-achievements-ada-mobile.svg"
                                class="ada-mobile"
                            >
                            <p>App defense endorses Oversecured as the Go-To CASA Certification Scanning Solution</p>
                        </div>
                    </div>
                </div>
                <!-- animation here -->
                <div class="main-top__content-animation-wrapper">
                    <Animation />
                </div>
            </div>
            <div class="main-top__carousel">
                <Companies />
                <span class="main-top__carousel-note">*they fixed vulnerabilities with our help</span>
            </div>
        </div>
    </div>
</template>
<script>
import Animation from './Animation.vue';
import Companies from '../../../assets/iconTemplates/Companies.vue';
import OrangeLine from '../../../assets/iconTemplates/OrangeLine.vue';
import OrangeArrowButton from '../OrangeArrowButton.vue';

export default {
    components: {
        Animation,
        OrangeLine,
        Companies,
        OrangeArrowButton,

    },
    data() {
        return {
            isClicked: false,
        };
    },
    mounted() {
        this.$emit('hideSpinner');
    },
    methods:{
        requestDemo() {
            this.$router.push('/request-demo');
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/variables";
@import "../../../assets/css/fonts";

    .main-top {
        background-color:#F6F6F9;
        overflow: hidden;

        &__wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            padding-top: 13rem;
        }

        &__content {
            display: flex;
            height: 90%;
            width: 100%;

            &-text {
                display: flex;
                flex-direction: column;
                width: 53%;
                height: 75%;
                gap: 15px;
                margin-left: 7.5%;
            }

            &-animation-wrapper {
                display: flex;
                flex-direction: column;
                width: 79%;
                height: 75%;
            }

        }

        &__animation-image {
            display: none;
            margin-top: 7%;
        }

        &__sub-title {
            position: relative;
            @include Aspekta-font(400, $dynamic-main-top-sub-title-font-size, normal, #242424);
            z-index: 2;
            opacity: 0.5;
            letter-spacing: 0;
            margin-bottom: 2.2rem;
        }

        &__title {
            position: relative;
            @include Aspekta-font(700, $dynamic-main-top-title-font-size, 130%, #242424);
            letter-spacing: $dynamic-button-letter-spacing;
            z-index: 2;
            display: flex;
            width: max-content;

            &--line {
                position: relative;
                display: inline-block;
            }
        }


        &__description {
            position: relative;
            @include Aspekta-font(400, $dynamic-main-top-description-font-size, normal, #797E86);
            z-index: 2;
            margin-right: 20%;
            margin-top: 1%;
        }

        &__buttons {
            display: flex;
            align-items: flex-start;
            white-space: nowrap;
            gap: 15px;
            margin-top: 8%;

            &--more {
                @include Aspekta-font(700, $dynamic-main-check-button-font-size, normal, #0C0D0E);
                letter-spacing: $dynamic-button-letter-spacing;
                background-color: #FFFFFF;
                border-radius: 36px;
                border: 2px solid #E8E7EF;
                padding: 18.5px 30px;
                text-decoration: none;

                &:hover {
                    background-color: #E8E7EF;
                }

                &:active {
                    background-color: #021631;
                    color: #FFFFFF;
                }
            }
        }

        &__achievements {
            display: flex;
            justify-items: flex-start;
            align-items: center;
            @include Aspekta-font(400, $dynamic-main-achievement-font-size, 20px, #797E86);
            margin-top: 7%;
            gap: 5rem;

            &--smsng, &--ada {
                display: flex;
                align-items: flex-start;
                gap: 15px;
            }

        }

        .orange-line {
            position: absolute;
            bottom: -0.1rem;
            right: 0px;
            width: 100%;
        }

        .ada-mobile {
            display: none;
        }
        .smsng-mobile {
            display: none;
        }

        &__carousel {
            margin-top: 12%;

            &-note {
                @include Aspekta-font(400, $dynamic-main-carousel-note-font-size, normal, #797E86);
                display: flex;
                justify-content: flex-end;
                margin-right: 9%;
                padding-bottom: 5%;
                padding-top: 1.5rem;
            }
        }
    }

        @media (max-width: 1850px) and (min-width: 768px) {
            .main-top {
                &__achievements {
                    margin-right: 5%;
                }
            }
        }

        @media (max-width: 1850px) and (min-width: 1645px) {
            .main-top {
                 &__content {
                    &-text {
                        width: 70%;
                    }
                }
            }
        }

        @media (max-width: 1645px) and (min-width: 1400px) {
            .main-top {
                 &__content {
                    &-text {
                        width: 85%;
                    }
                }

                &__achievements {
                    gap: 3rem;
                }
            }
        }

        @media (max-width: 1400px) and (min-width: 600px) {
            .main-top {
                &__achievements {
                    gap: 3rem;
                    flex-direction: column;

                    &--smsng img, &--ada img {
                        display: none;
                    }

                }

                .smsng-mobile, .ada-mobile {
                    display: block;
                }
            }
        }

        @media (max-width: 768px) {
            .main-top {

                &__wrapper {
                    padding-top: 6.5rem;
                }

                &__content {
                    flex-direction: column;

                    &-text {
                        width: 100%;
                        margin-left: 0%;
                        gap: 0px;

                        &-mobile {
                            padding: 0rem 1rem;

                        }
                    }

                    &-animation-wrapper {
                        display: none;
                    }
                }

                &__sub-title {
                    display: none;
                }


                &__animation-image {
                    display: flex;
                    margin-top: 4rem;
                    justify-content: flex-end;

                    & img {
                       height: 360px;
                    }
                }

                &__description {
                    width: 100%;
                    margin: 0.8rem 0rem 2.8rem 0rem;
                }

                &__buttons {
                    &--more {
                        display: none;
                    }
                }

                &__achievements {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 4rem 1.25rem 0rem 1.25rem;
                    margin-top: 0%;
                    gap: 1rem;

                    &--smsng img, &--ada img {
                        display: none;
                    }

                    .smsng-mobile, .ada-mobile {
                        display: block;
                    }

                    &--smsng, &--ada {
                        gap: 1.5rem;
                    }

                }

                &__carousel {
                    margin-top: 5.5rem;

                    &-note {
                        padding: 0.9rem 1.25rem 5.5rem 0rem;
                        margin-right: 0;
                    }
                }

            }

        }

        @media (min-width: 445px) and (max-width: 515px){
            .main-top {
                &__animation-image img {
                    height: 325px;
                }

            }
        }

        @media (max-width: 445px){
            .main-top {
                &__animation-image img {
                    height: 100%;
                    width: 100%;
                }

            }
        }

        @media (min-width: 520px) and (max-width: 768px) {
            .main-top {
                &__achievements {
                    width: 70%;
                }

            }
        }

</style>
