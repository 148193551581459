var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"markdown"},[_c('div',[_c('hr'),_c('b-nav-item',{staticClass:"md__link-wrapper",attrs:{"to":{ path: `${_vm.makeLinkToAnchor('uploading')}`}}},[_c('h2',{staticClass:"md__item-title"},[_vm._v("Uploading files")])]),_c('p',[_vm._v("To upload integration logos and new version files for Integrations or Single Scans, users need to obtain a signed Amazon S3 link, upload the file to it, and pass it to the corresponding endpoint bucket key. ")]),_c('h3',{attrs:{"id":_vm.makeAnchor('Uploading apps')}},[_c('a',{attrs:{"href":_vm.makeSubLinkToAnchor('Uploading apps', 'uploading')}},[_vm._v("Uploading apps")])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('h3',{attrs:{"id":_vm.makeAnchor('Uploading images')}},[_c('a',{attrs:{"href":_vm.makeSubLinkToAnchor('Uploading images', 'uploading')}},[_vm._v("Uploading images")])]),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Endpoint")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('code',[_vm._v("POST /v1/upload/app")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Input parameters")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Comment")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("platform")]),_c('td',[_vm._v("string")]),_c('td',[_vm._v("app platform")])]),_c('tr',[_c('td',[_vm._v("file_name")]),_c('td',[_vm._v("string")]),_c('td',[_vm._v("file name of the app version that will be uploaded to the platform")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("JSON response")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Comment")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("bucket_key")]),_c('td',[_vm._v("string")]),_c('td',[_vm._v("Amazon S3 key, needs to be passed in the corresponding query")])]),_c('tr',[_c('td',[_vm._v("url")]),_c('td',[_vm._v("string")]),_c('td',[_vm._v("signed link where the app file needs to be uploaded")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Using curl")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code-toolbar"},[_c('pre',{staticClass:"language-metadata"},[_c('code',{staticClass:"language-metadata"},[_vm._v("curl 'https://api.oversecured.com/v1/upload/app' \\\n  -d '{\"platform\": \"android\", \"file_name\": \"app-debug.apk\"}' \\\n  -H \"Content-Type: application/json\" -X POST \\\n  -H 'Authorization: {api_token}'\n")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Example response")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code-toolbar"},[_c('pre',{staticClass:"language-json"},[_c('code',{staticClass:"language-json"},[_c('span',{staticClass:"token punctuation"},[_vm._v("{")]),_vm._v("\n  "),_c('span',{staticClass:"token property"},[_vm._v("\"bucket_key\"")]),_c('span',{staticClass:"token operator"},[_vm._v(":")]),_vm._v(" "),_c('span',{staticClass:"token string"},[_vm._v("\"f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/47b72e81-05aa-488b-9876-4f79940ece11/6a573816-857a-432b-af52-7079cb58960f.apk\"")]),_c('span',{staticClass:"token punctuation"},[_vm._v(",")]),_vm._v("\n  "),_c('span',{staticClass:"token property"},[_vm._v("\"url\"")]),_c('span',{staticClass:"token operator"},[_vm._v(":")]),_vm._v(" "),_c('span',{staticClass:"token string"},[_vm._v("\"https://redacted.s3.amazonaws.com/f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/47b72e81-05aa-488b-9876-4f79940ece11/6a573816-857a-432b-af52-7079cb58960f.apk?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUC5S2Y2XMUGCWT7O%2F20201207%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20201207T205031Z&X-Amz-Expires=86400&X-Amz-Signature=f6206c1894f321961abe2ac4d956512b315b8fee98c6f959530fde51c014b342&X-Amz-SignedHeaders=host\"")]),_vm._v("\n"),_c('span',{staticClass:"token punctuation"},[_vm._v("}")]),_vm._v("\n")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("The platform supports images of the following types: "),_c('code',[_vm._v(".png")]),_vm._v(", "),_c('code',[_vm._v(".jpg")]),_vm._v(" and "),_c('code',[_vm._v(".jpeg")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Endpoint")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('code',[_vm._v("POST /v1/upload/image")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Input parameters")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Comment")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("file_name")]),_c('td',[_vm._v("string")]),_c('td',[_vm._v("file name of the image that will be uploaded to the platform")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("JSON response")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Type")]),_c('th',[_vm._v("Comment")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("bucket_key")]),_c('td',[_vm._v("string")]),_c('td',[_vm._v("Amazon S3 key, needs to be passed in the corresponding query")])]),_c('tr',[_c('td',[_vm._v("url")]),_c('td',[_vm._v("string")]),_c('td',[_vm._v("signed link where the image file needs to be uploaded")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Using curl")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code-toolbar"},[_c('pre',{staticClass:"language-metadata"},[_c('code',{staticClass:"language-metadata"},[_vm._v("curl 'https://api.oversecured.com/v1/upload/image' \\\n  -d '{\"file_name\": \"logo.png\"}' \\\n  -H \"Content-Type: application/json\" -X POST \\\n  -H 'Authorization: {api_token}'\n")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Example response")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code-toolbar"},[_c('pre',{staticClass:"language-json"},[_c('code',{staticClass:"language-json"},[_c('span',{staticClass:"token punctuation"},[_vm._v("{")]),_vm._v("\n  "),_c('span',{staticClass:"token property"},[_vm._v("\"bucket_key\"")]),_c('span',{staticClass:"token operator"},[_vm._v(":")]),_vm._v(" "),_c('span',{staticClass:"token string"},[_vm._v("\"f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/c075b165-c904-4869-b81e-256ecf7f9864.png\"")]),_c('span',{staticClass:"token punctuation"},[_vm._v(",")]),_vm._v("\n  "),_c('span',{staticClass:"token property"},[_vm._v("\"url\"")]),_c('span',{staticClass:"token operator"},[_vm._v(":")]),_vm._v(" "),_c('span',{staticClass:"token string"},[_vm._v("\"https://redacted.s3.amazonaws.com/f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/c075b165-c904-4869-b81e-256ecf7f9864.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAUC5S2Y2XMUGCWT7O%2F20201207%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20201207T205229Z&X-Amz-Expires=86400&X-Amz-Signature=668b25ce899abdabddf58a9a4c4beb63b2d94c8c5629d77a98a07e871dbfe223&X-Amz-SignedHeaders=host\"")]),_vm._v("\n"),_c('span',{staticClass:"token punctuation"},[_vm._v("}")]),_vm._v("\n")])])])
}]

export { render, staticRenderFns }