const reportTime = (time) => {
    let t = new Date(time)
    let day = Number(t.getDate()) > 9 ? t.getDate() : '0' + t.getDate()
    let minutes = Number(t.getMinutes()) > 9 ? t.getMinutes() : '0' + t.getMinutes()
    let month = Number(t.getMonth() + 1) > 9 ? Number(t.getMonth() + 1) : '0' + Number(t.getMonth() + 1)
    return day + '.' + month + '.' + t.getFullYear() + ' ' + t.getHours() + ':' + minutes
}

module.exports = {
    reportTime
}
