import {store} from "./index";
import Vue from "vue";

function byField(field) {
    return (a, b) => a[field] < b[field] ? 1 : -1;
}

export const state = () => ({
    categoriesReport: [],
    allVulInCode: [],
    newVulsCount: 0,
    resolvedVulsCount: 0,
    allCategories: [],
    compareByCategories: []
})

export const actions = {
    async getChangesCompare({commit, state}, {newReport, currentId, integration, currentJob}) {
        if (newReport) {
            commit('setCategoriesReport', newReport)
        } else {
            const compareCategories = integration.allVersions.versionsStatusFinish.find((item) => item.id === currentId).compareCategoriesWithPreviousData
            commit('setCategoriesReport', compareCategories ? JSON.parse(compareCategories) : null)
        }
        const compareByCategories = []

        // processing for a categories table
        state.allCategories.forEach((item, index) => {
            for (let key in state.categoriesReport) {
                if (key === item.id) {
                    state.categoriesReport[key].severity = item.severity
                    state.categoriesReport[key].type = item.title

                    const obj = {
                        changes: state.categoriesReport[key],
                        severity: item.severity,
                        type: item.title,
                        category_id : item.id,
                        description : item.description,
                        remediation : item.remediation,
                        links : item.links.replace(/['"]+/g, '').replace(/[\])}[{(]/g, '').split(',')
                    }
                    compareByCategories.push(obj)
                }
            }
        })

        commit('setCompareByCategories', compareByCategories)

    },

    async setVulnerabilitiesInCode({commit, state}, isIntegrationTrial) {
        commit('clearVulnerabilities')
        // processing for a vulnerabilities in code table
        let arr = Object.values(state.compareByCategories);

        // Counting all(high, medium and low) new and resolved vulnerabilities
        arr.forEach(item => {
            if (item.changes.created) {
                commit('increaseNewVulCount', item.changes.created.length)
            }
            if (item.changes.fixed) {
                commit('increaseResolvedVulCount', item.changes.fixed.length)
            }
        })

        // Filtering vulnerabilities by severity if it is integration trial
        if (isIntegrationTrial) {
            arr = arr.filter(item => item.severity !== 'high' && item.severity !== 'medium');
        }
        
        arr.forEach(item => {
            if (item.changes.created && item.changes.created.length > 0) {
                item.changes.created.forEach(el => {
                    commit('setAllVulInCode', {
                        ...el,
                        type: item.type,
                        format: "created",
                        severity: item.changes.severity
                    })
                })
            }
            if (item.changes.fixed && item.changes.fixed.length > 0) {
                item.changes.fixed.forEach(el => {
                    commit('setAllVulInCode', {
                        ...el,
                        type: item.type,
                        format: "fixed",
                        severity: item.changes.severity
                    })
                })
            }
        })
    },
    async getAllVulnerabilitiesCategories({commit, state}) {
        const categories = await Vue.axios.get(`/private/ui/categories`)
        commit('setAllCategories', categories.data)
    },
    async getVulnerabilitiesForCompare({commit}, {integrationId, branchName, vulnerabilities, versionIds}) {
        try {
            const res = await Vue.axios.post(`/private/ui/integrations/${integrationId}/branches/${branchName}/versions/vulnerabilities` ,{
                vulnerabilities,
                versionIds
            })
            commit('setBlocksToAllVulInCode', res.data)
        } catch (e) {
            console.log(e)
        }
    },
}
export const mutations = {
    setCategoriesReport: (state, categoriesReport) => {
        state.categoriesReport = categoriesReport
    },
    setAllVulInCode: (state, obj) => {
        state.allVulInCode = [...state.allVulInCode, obj]
    },
    setBlocksToAllVulInCode: (state, vulnerabilities) => {
        state.allVulInCode = state.allVulInCode.map(vulnerability => {
            vulnerabilities.map(vul => {
                if (vul.id === vulnerability.id) {
                    vulnerability.blocks = JSON.parse(vul.blocks)
                }
            })
            return vulnerability
        })
    },
    increaseNewVulCount: (state, count) => {
        state.newVulsCount += count
    },
    decreaseNewVulCount : (state)=>{
        state.newVulsCount--
    },
    decreaseResolvedVulCount: (state)=>{
        state.resolvedVulsCount--
    },
    increaseResolvedVulCount: (state, count) => {
        state.resolvedVulsCount += count
    },
    clearVulnerabilities: (state) => {
        state.allVulInCode = []
        state.newVulsCount = 0
        state.resolvedVulsCount = 0
    },
    setAllCategories: (state, categories) => {
        state.allCategories = categories
    },
    setCompareByCategories: (state, compareByCategories) => {
        state.compareByCategories = compareByCategories
    }
}


export const getters = {
    categoriesReport: state => state.categoriesReport,
    allVulInCode: state => state.allVulInCode,
    newVulsCount: state => state.newVulsCount,
    resolvedVulsCount: state => state.resolvedVulsCount,
    compareByCategories: state => state.compareByCategories,
    allCategories: state => state.allCategories
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
