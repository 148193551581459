<template>
    <div
        class="dropdown"
        :class="isMobile && 'mobile'"
    >
        <b-dropdown
            id="dropdown-folders"
            toggle-class="dropdown__button"
            menu-class="dropdown__menu"
            variant="light"
            no-caret
            block
            lazy
            @show="showMenu($event)"
            @hide="hideMenu($event)"
        >
            <template
                v-slot:button-content
                class="test"
            >
                <slot name="button-icon" />
                <div class="dropdown__chosen-folder">
                    <div><slot name="folder-name" /></div>
                    <div><slot name="folder-statistics" /></div>
                </div>
                <img
                    class="dropdown__button-arrow"
                    src="../../../assets/images/folders/dropdown-arrow.png"
                >
            </template>
            <b-dropdown-item-button
                v-for="item in items"
                :key="item.id"
                button-class="dropdown__menu-item"
                @click.prevent="clickOnItem(item, $event)"
            >
                <div class="folder">
                    <div v-if="checkFolderName(item.name)">
                        <FolderIcon
                            :ref="`FolderIcon${item.name}`"
                            :svg-color="checkVulnerabilitySeverityNumber(item.statistics, 'all') === 0 ? Empty : Default"
                            :svg-width="'24px'"
                            :svg-height="'21px'"
                            alt="folder icon"
                            class="folder-icon"
                        />
                    </div>
                    <div v-else>
                        <FalsePositiveIcon
                            ref="FalsePositiveFolderIcon"
                            :svg-color="checkVulnerabilitySeverityNumber(item.statistics, 'all') === 0 ? Empty : Default"
                            :svg-width="'24px'"
                            :svg-height="'24px'"
                            alt="folder icon"
                            class="folder-icon"
                        />
                    </div>

                    <div
                        v-if="checkFolderName(item.name)"
                        class="folder_list_vulnerabilities_dots"
                    >
                        <div
                            v-if="checkVulnerabilitySeverityBoolean(item.statistics, 'high')"
                            class="red_dot"
                        />
                        <div
                            v-if="checkVulnerabilitySeverityBoolean(item.statistics, 'medium')"
                            class="yellow_dot"
                        />
                        <div
                            v-if="checkVulnerabilitySeverityBoolean(item.statistics, 'low')"
                            class="green_dot"
                        />
                    </div>
                </div>
                <span
                    class="dropdown__menu-item-text"
                    :title="item.name"
                    :style="{ color: checkVulnerabilitySeverityNumber(item.statistics, 'all') === 0 ? EmptyText : DefaultText }"
                >
                    {{ item.name }}
                </span>
                <span
                    v-if="checkFolderName(item.name)"
                    class="dropdown__menu-item-statistics-count"
                >
                    {{ checkVulnerabilitySeverityNumber(item.statistics, 'all') === 0 ? '' : checkVulnerabilitySeverityNumber(item.statistics, 'all') }}
                </span>
            </b-dropdown-item-button>
        </b-dropdown>
        <div
            v-if="isMobile"
            class="dark-background"
            :class="isDropdownOpen && 'open'"
        />
    </div>
</template>

<script>
import { FolderNameEnum } from '../../../utils/constants/common';
import FolderIcon from '../../../assets/iconTemplates/FolderIcon';
import FalsePositiveIcon from '../../../assets/iconTemplates/FalsePositiveIcon';
import { checkVulnerabilitySeverityBooleanFunction, checkVulnerabilitySeverityNumberFunction } from '../../../utils/helper-functions';
export default {
    name: 'FolderMenuMobile',
    components: {
        FolderIcon,
        FalsePositiveIcon,
    },
    props: {
        items: {
            type: Array,
        },
        integrationId: {
            type: String,
            default: '',
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            // Color rules:
            DefaultText: '#5C6A7A',
            Default: '#949DB8',
            EmptyText: '#5C6A7A',
            Empty: '#949DB8',
            Hover: '#FFE7AD',
            // State
            isDropdownOpen: false,
        };
    },
    watch: {
        // watch for item updates
        items: {
            handler: function (newVal, oldVal) {
                if (newVal) {
                    const falsePositivesFolder = newVal.find(item => item.name === FolderNameEnum.FALSE_POSITIVES);
                    const folders = newVal.filter(item => item.name !== FolderNameEnum.FALSE_POSITIVES);

                    // for each folder check if it is empty and update icon color
                    folders.forEach(folder => {
                        this.checkAndUpdateFolderIconColor(folder.name);
                    });

                    // Check if false positives folder is empty and update icon color
                    if (falsePositivesFolder && !falsePositivesFolder.isEmptyFolder) {
                        this.checkAndUpdateFalsePositivesFolderIconColor();
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        showMenu($event) {
            this.isDropdownOpen = true;
            this.$emit('show', true, $event);
        },
        hideMenu($event) {
            this.isDropdownOpen = false;
            this.$emit('hide', false, $event);
        },
        clickOnItem(item, event) {
            this.$emit('item-click', item, event);
        },
        checkFolderName(name) {
            return name !== FolderNameEnum.FALSE_POSITIVES;
        },
        checkVulnerabilitySeverityBoolean(statistics, severity) {
            return checkVulnerabilitySeverityBooleanFunction(statistics, severity);
        },
        checkVulnerabilitySeverityNumber(statistics, severity) {
            return checkVulnerabilitySeverityNumberFunction(statistics, severity);
        },
        checkAndUpdateFolderIconColor(name) {
            const folder = this.items.find(item => item.name === name);
            const folderIcon = this.$refs[`FolderIcon${name}`];
            if (folderIcon) {
                folderIcon[0]?.setFill(folder.isEmptyFolder ? this.Empty : this.Default);
            }
        },
        checkAndUpdateFalsePositivesFolderIconColor() {
            const falsePositivesFolder = this.items.find(item => item.name === FolderNameEnum.FALSE_POSITIVES);
            const falsePositivesFolderIcon = this.$refs.FalsePositiveFolderIcon;
            if (falsePositivesFolderIcon) {
                falsePositivesFolderIcon[0]?.setFill(falsePositivesFolder.isEmptyFolder ? this.Empty : this.Default);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@mixin linePosition{
    padding: 6.5px 18px;
    display: flex;
    align-items: center;
    gap: 18px;
}
@mixin outlineStyle {
    background: #fff;
    color: #B2B9C3;
    border: 1px solid #F0F1F3;
    border-radius: 16px;

}

@mixin vulnDot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: auto 1.5px;
}

.folder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.folder_list_vulnerabilities_dots {
    display: flex;
    flex-direction: row;
    height: fit-content;
    margin-top: 8px;

    .red_dot {
        @include vulnDot;
        background: #FB8D62;
    }
    .yellow_dot {
        @include vulnDot;
        background: #FDD365;
    }
    .green_dot {
        @include vulnDot;
        background: #61D4B3;
    }
}


::v-deep .dropdown {
    &__chosen-folder {
        text-align: left;
    }

    &__button {
        @include outlineStyle;
        border-radius: 8px;
        @include linePosition;
        &-arrow {
            margin-left: auto;
            width: 12px;
            height: 7px;
            transition: rotate .3s ease-in-out;
        }
        &[aria-expanded="true"] {
            @include outlineStyle;
            border-radius: 8px;
            .dropdown__button-arrow {
                rotate: 180deg;
                transition: rotate .3s ease-in-out;
            }
        }
        &-text {
            font-size: 17px;
        }
    }
    &__menu {
        @include outlineStyle;
        top: 50px !important;
        max-height: 580px;
        overflow: auto;
        padding: 0;

        // Hide scrollbar for Chrome, Safari and Opera
        &::-webkit-scrollbar {
            display: none;
        }
        // Hide scrollbar for IE, Edge and Firefox
        scrollbar-width: none;

        &-item {
            @include linePosition;
            box-shadow: inset 0 -1px 0 0 #F0F1F3;
            &:hover {
                background-color: #F0F1F3;
            }
            &-text {
                color: #706969;
                font-size: 17px;
                font-weight: 400;
                line-height: 150%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &-statistics-count {
                margin-left: auto;
                font-weight: 700;
                font-size: 17px;
                line-height: 150%;

                text-align: center;
                color: #B2B9C3;
            }
        }
    }
.dark-background {
    display: none;
}
}
@media (max-width: 768px) {
    .dropdown.mobile {
        display: block;
        width: 100%;

        ::v-deep .dropdown__button {
            position: relative;
            &[aria-expanded="true"] {
                width: 100%;
                z-index: 1053;
            }
        }
        ::v-deep .dropdown__menu {
            max-height: 400px;
            &.show {
                width: 100%;
                z-index: 1053;
                transform: translate3d(0px, 35px, 0px) !important;
            }
        }
    }
    .dark-background.open {
        display: block;
        position: fixed;
        z-index: 1052;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(65, 62, 62, 0.7);
    }
}
</style>
