<template>
    <div class="partner-unlock">
        <div class="partner-unlock__container">
            <div class="partner-unlock__content">
                <div class="partner-unlock__text">
                    <div class="partner-unlock__title">
                        <p>Ready to Unlock New Opportunities?</p>
                    </div>

                    <div class="partner-unlock__sub-title">
                        <p>Start Your Journey to Profitable Growth</p>
                    </div>
                    <div class="partner-unlock__btn">
                        <OrangeArrowButton
                            :title="'Partner with Confidence'"
                            class="orange-button"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import OrangeArrowButton from '../main/OrangeArrowButton.vue';

export default {
    components: {
        OrangeArrowButton,
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables/variablesForPartnerPage";
@import "../../assets/css/fonts";

    .partner-unlock {
        background-color: #F6F6F9;
        background-image: url('../../assets/images/partner/background-image-unlock-block.svg');
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;

        &__container {
            display: flex;
            flex-direction: column;
        }

        &__content {
            display: flex;
            margin: 8.8rem auto 10rem auto;
        }

        &__text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1.2rem;
        }

        &__sub-title {
            @include Aspekta-font(400, $dynamic-partner-unlock-sub-title-font-size, normal, #0C0D0E);
            letter-spacing: 0em;
            opacity: 0.5;
            align-self: center;
        }

        &__title {
            @include Aspekta-font(700, $dynamic-partner-unlock-title-font-size, 120%, #0C0D0E);
            letter-spacing: 0em;
        }

        &__btn {
            margin-top: 3rem;
        }

        .orange-button {
            font-weight: 700;
        }
    }

    @media (max-width: 768px) { 
        .partner-unlock {
            background-image: url('../../assets/images/partner/background-image-unlock-block-mobile.svg');

            &__title {
                text-align: center;
            }

            &__content {
                margin: 6rem auto 6.5rem auto;
            }

            &__text {
                gap: 0.5rem;
            }

            &__btn {
                margin-top: 2rem;
            }

            .orange-button {
                font-weight: 650;
            }
        }

    }

</style>
