<template>
    <div>
        <div class="sidebar">
            <button
                id="sidebar-btn"
                class="sidebar-btn-open"
                @click="onSidebarShown"
            >
                <img src="../../assets/images/myScans/expand-sidebar-icon.svg">
            </button>
            <b-sidebar
                id="sidebar-right"
                class="sidebar b-sidebar-right"
                right
                no-header
                :visible="isSidebarVisible"
                :no-close-on-route-change="true"
                @hidden="onSidebarHidden"
                @shown="onSidebarShown"
            >
                <div class="sidebar__container">
                    <button
                        class="sidebar-btn-close"
                        @click="onSidebarHidden"
                    >
                        <img src="../../assets/images/myScans/close-sidebar-icon.svg">
                    </button>
                </div>
                <SeverityDropdowns
                    class="severity-dropdowns"
                    :all-vulnerabilities-folder="allVulnerabilitiesFolder"
                    :items-by-severity="itemsBySeverityWithTrialCheck"
                    :items-by-severity-trial="itemsBySeverityOnlyHighAndMediumForTrial"
                    :is-trial-restriction="isTrialRestriction"
                    :integration-id="integrationId"
                    :job-id="job.id"
                    :branch-name="branchName"
                    :is-small-screen="isSmallScreen"
                    :is-medium-screen="isMediumScreen"
                    :is-large-screen="isLargeScreen"
                    :is-mouse-leave="isMouseLeave"
                    :vulnerability-category-id="vulnerabilityCategoryId"
                    @selectCategory="selectCategory"
                />
            </b-sidebar>
            <div
                v-if="isLoading || refreshLoading"
                class="spinner"
            >
                <Spinner />
            </div>
        </div>

        <div class="filter__popup">
            <button
                id="popup-btn"
                class="filter__popup--btn"
                :class="width < 576 ? isSidebarVisible ? 'close-btn' : 'open-btn' : isPopupVisible ? 'close-btn' : 'open-btn'"
            >
                <img
                    v-if=" width < 576 ? !isSidebarVisible : !isPopupVisible"
                    class="open"
                    src="../../assets/images/myScans/mobile_filter.svg"
                    @click="openModal('general-popupFilters')"
                >
                <img
                    v-if=" width < 576 ? isSidebarVisible : isPopupVisible"
                    class="close"
                    src="../../assets/images/myScans/mobile_filter_close.svg"
                    @click="closeModal('general-popupFilters')"
                >
            </button>

            <GeneralPopup
                :title-text="''"
                :popup-id="'Filters'"
                :hide-footer="true"
                :hide-header="true"
                :size="'lg'"
                @onHidden="closeModal('general-popupFilters')"
            >
                <SeverityDropdowns
                    class="severity-dropdowns"
                    :all-vulnerabilities-folder="allVulnerabilitiesFolder"
                    :items-by-severity="itemsBySeverityWithTrialCheck"
                    :items-by-severity-trial="itemsBySeverityOnlyHighAndMediumForTrial"
                    :is-trial-restriction="isTrialRestriction"
                    :integration-id="integrationId"
                    :job-id="job.id"
                    :branch-name="branchName"
                    :is-small-screen="isSmallScreen"
                    :is-medium-screen="isMediumScreen"
                    :is-large-screen="isLargeScreen"
                    @selectCategory="selectCategory"
                />
            </GeneralPopup>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SeverityDropdowns from './components/SeverityDropdowns.vue';
import Spinner from '../../components/Spinner';
import GeneralPopup from '../popups/GeneralPopup';
import { PopupTypeEnum } from '../../utils/constants/common';

export default {
    components: {
        SeverityDropdowns,
        GeneralPopup,
        Spinner,
    },
    props: {
        vulnerabilityCategoriesList: Array,
        refreshLoading: Boolean,
        integrationId: String,
        isTrialRestriction: Boolean,
        job: Object,
        width: Number,
        branchName: {
            type: String,
        },
        allVulnerabilitiesFolder: {
            type: Array,
            required: false,
            default: () => [],
        },
        vulnerabilityCategoryId: String,
        isMouseLeave: Boolean,

    },
    data() {
        return {
            isPopupVisible: false,
        };
    },
    computed: {
        ...mapGetters({
            isTrialNotification: 'user/isTrialNotification',
            isSidebarVisible: 'filters/isSidebarVisible',
            isLoading: 'jobs/isLoading',
        }),
        itemsBySeverity() {
            return this.vulnerabilityCategoriesList.reduce((acc, vulnerability) => {
                // Assume that each vulnerability item has 'severity', 'type' and 'count' properties
                const { severity, type, text, remediation, links, count, id } = vulnerability;

                // Initialize severity array in the accumulator if it doesn't exist
                if (!acc[severity]) {
                    acc[severity] = [];
                }

                // Add the vulnerability to the appropriate severity array
                acc[severity].push({
                    type,
                    text,
                    remediation,
                    links,
                    count,
                    id,
                    checked: true, // Initialize 'checked' state for each item
                });

                return acc;
            }, {});
        },
        itemsBySeverityWithTrialCheck() {
            if (this.isTrialRestriction) {
                return this.itemsBySeverity.low !== undefined
                    ? { low: this.itemsBySeverity.low }
                    : {};
            } else {
                return this.itemsBySeverity;
            }
        },
        itemsBySeverityOnlyHighAndMediumForTrial() {
            return  { high: this.itemsBySeverity.high, medium: this.itemsBySeverity.medium };
        },
        isSmallScreen() {
            return this.width < 576;
        },
        isMediumScreen() {
            return this.width >= 576 && this.width < 900;
        },
        isLargeScreen() {
            return this.width >= 900;
        },
    },
    watch: {
        /**
         * watch isTrialNotification to change after closing trial notification
         */
        isTrialNotification() {
            this.handleScroll();
        },
        /**
         * watch isSidebarVisible to change content padding when sidebar is open or closed
         */
        isSidebarVisible: {
            immediate: true,
            handler(newValue) {
                this.handleSidebarVisibilityChange(newValue);
            },
        },
        width(newWidth, oldWidth) {
            if (oldWidth === 0) return;
            let action;

            if (this.isMediumScreen) {
                // For medium screen
                action = this.isSidebarVisible ? this.openPopupAndCloseSidebar : this.doNothing;

            } else if (this.isLargeScreen || this.isSmallScreen) {
                // For large or small screens
                action = this.isPopupVisible ? this.openSidebarAndClosePopup : this.doNothing;
            }

            if (action) {
                action.call(this);
            }
        },
    },
    beforeMount() {
        this.handleScroll();
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('scroll', this.onScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        openSidebarAndClosePopup() {
            this.$bvModal.hide(PopupTypeEnum.FILTERS);
            this.isPopupVisible = false;
            this.onSidebarShown();
        },

        openPopupAndCloseSidebar() {
            this.onSidebarHidden();
            this.$bvModal.show(PopupTypeEnum.FILTERS);
            this.isPopupVisible = true;
        },

        doNothing() { /* method for better readability */ },
        /**
         * handleSidebarVisibilityChange - change content padding when sidebar is open or closed
         */
        handleSidebarVisibilityChange(newValue) {
            const padding = !newValue ? '0px' : '264px';
            const reportPage = document.querySelector('.report-page');
            const codeWrapper = document.querySelector('.code-wrapper');
            const btnToTop = document.querySelector('.btn-to-top');
            const footer = document.querySelector('.footer');

            this.setDirectInlineStyle(reportPage, { 'padding-right': window.innerWidth < 576 ? '0px' : padding });
            this.setDirectInlineStyle(codeWrapper, { 'padding-right': window.innerWidth < 576 ? '0px' : padding, 'width': window.innerWidth < 576 ? `${window.innerWidth - 30}px` : `${window.innerWidth - 232}px` });
            this.setDirectInlineStyle(btnToTop, { 'right': newValue ? window.innerWidth < 576 ? '24px' : '380px' : '24px' });
            this.setDirectInlineStyle(footer, { 'padding-right': window.innerWidth < 576 ? '0px' : padding });
        },
        /**
         * This method is used to adjust folder menu position and some margin when scrolling
         * 71px is the hight of header menu + 1px of border
         *
         */
        handleScroll() {
            this.$nextTick(() => {
                let headerHeight = this.isTrialNotification ? 118 : 72;
                let scrollPosition = headerHeight - window.scrollY;
                let sidebarMenu = document.getElementById('sidebar-right');
                let sidebarBtn = document.getElementById('sidebar-btn');
                let categoryDropdowns = document.querySelector('.severity-dropdowns');

                if (sidebarMenu || sidebarBtn) {
                    if (scrollPosition < 0) {
                        sidebarMenu.style.top = '0px';
                        sidebarBtn.style.top = '16px';
                        categoryDropdowns.style.height = window.innerWidth < 834 ? 'calc(100%)' : 'calc(100% - 69px)';

                    }
                    if (scrollPosition >= 0 && scrollPosition <= headerHeight) {
                        sidebarMenu.style.top = `${scrollPosition}px`;
                        sidebarBtn.style.top = `${scrollPosition + 16}px`;
                        categoryDropdowns.style.height = window.innerWidth < 834 ? `calc(100% - ${scrollPosition}px)` : `calc(100% - 69px - ${scrollPosition}px)`;
                    }
                }
            });
        },
        async selectCategory({ categoryType, categoryId, body }) {
            this.$store.commit('jobs/startOperation'); // start spinner
            window.innerWidth < 576 ? this.onSidebarHidden() : this.$bvModal.hide('general-popupFilters');

            try {
                await this.$store.dispatch('jobs/chooseAppropriatePageAction', body);
            } finally {
                this.$store.commit('jobs/endOperation'); // end spinner
            }
        },
        setDirectInlineStyle(element, styleObject) {
            if (element) {
                for (const property in styleObject) {
                    element.style[property] = styleObject[property];
                }
            }
        },
        onSidebarHidden() {
            this.$store.commit('filters/setSidebarVisiblity', false);
        },
        onSidebarShown() {
            this.$store.commit('filters/setSidebarVisiblity', true);
        },
        openModal(modalId) {
            if (this.isSmallScreen) {
                this.onSidebarShown();
            } else {
                this.$bvModal.show(modalId);
                this.isPopupVisible = true;
            }
        },
        closeModal(modalId) {
            if (this.isSmallScreen) {
                this.onSidebarHidden();
            } else {
                this.$bvModal.hide(modalId);
                this.isPopupVisible = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>

#popup-btn {
    display: none;
}
.sidebar-btn-open,
.sidebar-btn-close {
    display: flex;
}

.b-sidebar > .b-sidebar-body {
    overflow-y: visible;
}

@mixin sidebar_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 28px;
    height: 28px;

}

.sidebar {
    position: fixed;
    z-index: 50;

    // Open sidebar button
    &-btn-open {
        @include sidebar_btn;
        border: 2px solid #ADCCFB;

        position: fixed;
        top: 87px;
        right: 20px;
        z-index: 9;

        &:hover {
            border: 2px solid #3180F6 !important;
            box-shadow: 0px 12px 16px rgba(51, 128, 244, 0.12);
        }

        &:focus {
            border: 2px solid #ADCCFB;
        }
    }

    // Close sidebar button
    &-btn-close {
        @include sidebar_btn;
        border: 2px solid #E3E6ED;

        &:hover {
            box-shadow: 0px 12px 16px rgba(178, 185, 196, 0.12);
        }
    }

    &__container {
        padding: 20px;
        border-bottom: 1px solid #E3E6ED;
    }

    .spinner {
        position: fixed;
        background: rgba(256, 256, 256, 0.5);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1056;
    }
}

::v-deep .b-sidebar.bg-light {
  background-color: rgba(248, 250, 252, 1) !important;
}


// Filter popup
.filter__popup {


    &--btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        border-radius: 50%;
        bottom: 114px;
        right: 20px;

        &:hover {
            box-shadow: 0px 12px 16px rgba(178, 185, 196, 0.12);
        }
    }

    .open-btn {
        z-index: 1039;
    }

    .close-btn {
        z-index: 1051;
    }


}

.severity-dropdowns {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none;

    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width: 900px) {
    #popup-btn {
        display: flex;
    }

    #sidebar-right {
        display: none;
    }

    .sidebar {
        &-btn-open,
        &-btn-close {
            display: none;
        }

        &__container {
            padding: 0;
        }
    }

    .filter__popup--btn {
        bottom: 50%;
    }
}

</style>
