<template>
    <div class="ready-to-elevate-your-security">
        <div class="ready-to-elevate-your-security__content">
            <span class="main-text">{{ mainText }}</span>
            <span class="second-text">{{ secondText }}</span>

            <button
                class="find-out-btn"
                @mousedown="isClicked = !isClicked"
                @mouseup="isClicked = !isClicked"
                @click="requestDemo"
            >
                {{ buttonText }}
                <TopArrowIcon
                    :clicked="isClicked"
                    class="arrow-icon"
                />
            </button>
            <img
                class="vector"
                src="../../assets/images/Vector 15.svg"
                alt="vector"
            >
        </div>
    </div>
</template>
<script>


import TopArrowIcon from '../../assets/iconTemplates/TopArrowIcon.vue';

export default {
    components:{
        TopArrowIcon,
    },
    data() {
        return {
            isClicked: false,
            mainText: 'Ready to Elevate Your Security?',
            secondText: `Protect your organization with our comprehensive cybersecurity solution.
                Don't settle for limitations – take charge of your digital defense`,
            buttonText: 'Find Out How We Can Help',
        };
    },
    
    methods:{
        requestDemo() {
            this.$router.push('/request-demo'); 
        },
    },
    
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/variables/variablesForReadyToElevateYourSecurity.scss";
@import "../../assets/css/variables.scss";
@import "../../assets/css/fonts";

.ready-to-elevate-your-security {
    width: 100vw;
    background-color: var(--dark-blue, #02142C);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;

    &__content {
        width: 60%;
        margin: 160px 0;
        display: flex;
        flex-direction: column;
        align-items: center
    }
}

.main-text {
    @include Aspekta-font(700, $dynamic-main-text-font-size, normal, #FFF);
    line-height: 120%;
}

.second-text {
    margin: 30px 15%;
    @include Aspekta-font(400, $dynamic-second-text-font-size, normal, #FFF);
    opacity: 0.5;

}

.find-out-btn {
    display: flex;
    margin-top: 66px ;
    @include Aspekta-font(700, 16px, normal, #FFFFFF);
    background-color: $new-orange-color;
    border-radius: 30px;
    padding: 15px 15px 15px 30px;
    text-decoration: none;
    align-items: center;

    &:hover {
        background: linear-gradient(0deg, rgba(255, 122, 8, 0.70) 0%, rgba(255, 122, 8, 0.70) 100%), var(--white, #FFF);
    }

    &:active {
        color: #0C0D0E;
        background-color: #FFF;
    }

}
.arrow-icon {
    margin-left: 15px;
    height: 30px;
    width: 30px;
}
.vector {
    position: absolute;
    width: 245px;
    height: 260px;
    transform: rotate(1.23deg);
    flex-shrink: 0;
    top: $dynamic-vector-top;
    left: $dynamic-vector-left;
}


@media (max-width: 1450px) {

    .ready-to-elevate-your-security__content {
        width: 65%;
    }
}
@media (max-width: 1237px) {

    .ready-to-elevate-your-security__content {
        width: 75%;
    }

    .vector {
        transform: rotate(-15deg);
        width: 165px;
    }
}

@media (max-width: 785px) {  //768px

    .second-text {
        font-size: 14px;
    }

    .arrow-icon {
        margin-left: 8px;
        height: 22px;
        width: 22px;
    }
    .ready-to-elevate-your-security__content {
        margin: 100px 0;
        width: 100%;
        padding: 0 10px;
    }

    .find-out-btn {
        margin-top: 50px;
        font-size: 14px;
        font-weight: 650;
    }

    .vector {
        transform: rotate(-15deg);
        top: 100px;
        left: $dynamic-vector-left-mob;
        width: 150px;
    }

}

@media (max-width: 635px) {

    .vector {
        width: 155px;
        transform: rotate(-45deg);
    }

    .second-text {
        width: 65%;
    }
}



</style>
