<template>
    <div class="integration-webhooks">
        <div class="integration-webhooks-add-btn_container">
            <button
                :class="`integration-webhooks-btn ${isAddBtnDisabled() && 'integration-webhooks-btn_disable'} cabin cabin-bold`"
                :disabled="isAddBtnDisabled()"
                @click="openAddModal"
            >
                Add Webhook
            </button>
        </div>
        <!-- webhooks table -->
        <div
            class="integration-webhooks_container"
            :style="!webhooksList.length && 'justify-content: center'"
        >
            <div
                v-if="webhooksList.length"
                class="integration-webhooks-table_wrapper"
            >
                <b-table
                    id="integration-webhooks-table"
                    class="integration-webhooks-table"
                    hover
                    :items="webhooksList"
                    :fields="webhooksFields"
                    :per-page="perPage"
                    :current-page="currentPage"
                >
                    <template #cell(index)="data">
                        {{ (data.index + 1) + ((currentPage - 1) * perPage) }}
                    </template>
                    <template #cell(url)="data">
                        {{ data.item.url }}
                    </template>
                    <template #cell(actions)="data">
                        <button
                            id="edit-webhook-btn"
                            class="webhook-btn cabin"
                            @click="openEditModal(data.item)"
                            @mouseenter="showLabel($event, data.item.id)"
                            @mouseleave="showHintEditLabel = false"
                        >
                            <p
                                v-show="showHintEditLabel !== null && showHintEditLabel === data.item.id"
                                class="edit-webhook-btn-title"
                            >
                                Edit
                            </p>
                            <img
                                src="../../assets/images/Edit-btn.jpg"
                                alt="edit"
                            >
                        </button>
                        <button
                            id="delete-webhook-btn"
                            class="webhook-btn cabin"
                            @click="openRemoveModal(data.item)"
                            @mouseenter="showLabel($event, data.item.id)"
                            @mouseleave="showHintDeleteLabel = false"
                        >
                            <p
                                v-show="showHintDeleteLabel !== null && showHintDeleteLabel === data.item.id"
                                class="delete-webhook-btn-title"
                            >
                                Delete Webhook
                            </p>
                            <img
                                src="../../assets/images/myScans/icon_action_delete.svg"
                                alt="delete"
                            >
                        </button>
                    </template>
                </b-table>
            </div>
            <Pagination
                v-if="webhooksCount > 0"
                class="integration-webhooks__pagination"
                :count="webhooksCount"
                :limit="perPage"
                :current-page="currentPage"
                :show-spinner="showSpinner"
                @pageChanged="updatePage"
            />
            <div
                v-if="!webhooksList.length"
                class="integration-webhooks-container-text"
            >
                Webhooks allow external services to be notified when certain events
                happen. When the specific events happen, we’ll send a POST request
                to each of the URLs you provide.
            </div>

            <div
                v-if="showSpinner"
                class="integration-webhooks-spinner"
            >
                <Spinner />
            </div>
        </div>
        <!-- Edit/add webhooks modal -->

        <GeneralPopup
            :title-text="showRemoveModal ? 'Delete Webhook?' : showEditModal ? 'Edit Webhook' : 'Add Webhook'"
            :loading="showSpinnerModal"
            @onHidden="closeModal"
        >
            <form
                v-if="!showRemoveModal"
                class="integration-webhooks-form"
                @submit.prevent="handleWebhookForm"
            >
                <div class="integration-webhooks__form-input-wrapper">
                    <input
                        id="payloadUrl"
                        v-model.trim="formData.url"
                        class="input"
                        type="text"
                        name="url"
                        @blur="activeInputName = null"
                        @input="handleInput"
                    >
                    <img
                        v-if="formData.url.length > 0"
                        class="integration-webhooks__input-check"
                        src="../../assets/images/cross_grey.svg"
                        alt="cross"
                        @click.stop="clearInputAndErrors('payloadUrl')"
                    >
                    <label
                        for="payloadUrl"
                        class="label"
                        :class="{ activeInput: formData.url !== isEmptyInput || activeInputName === 'Payload URL'}"
                    >
                        Payload URL
                    </label>
                    <span
                        id="payloadUrlError"
                        class="integration-webhooks__error"
                    >
                        <img
                            class="integration-webhooks__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('payloadUrl')"
                        >
                        <span class="integration-webhooks__error-text">Required field</span>
                    </span>
                    <span
                        id="payloadUrlValidationError"
                        class="integration-webhooks__error"
                    >
                        <img
                            class="integration-webhooks__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('payloadUrl')"
                        >
                        <span class="integration-webhooks__error-text">Invalid url address</span>
                    </span>
                    <span
                        id="commonWebhookError"
                        class="integration-webhooks__error"
                    >
                        <img
                            class="integration-webhooks__error-cross"
                            src="../../assets/images/cross.svg"
                            alt="cross"
                            @click="clearInputAndErrors('payloadUrl')"
                        >
                        <span class="integration-webhooks__error-text integration-webhooks__error-common_text">{{ error.commonWebhookError ? error.commonWebhookError : 'Something went wrong' }}</span>
                    </span>
                    <span class="integration-webhooks-form_span">Content Type</span>
                    <input
                        v-model="formData.contentType"
                        class="integration-webhooks-form_select"
                        name="contentType"
                        disabled
                    >
                </div>
                <div class="integration-webhooks-form_btn" />
            </form>
            <div
                v-else-if="showRemoveModal"
                class="integration-webhooks-modal_text"
            >
                Are you sure you want to delete current webhook?
            </div>
            <template v-slot:ok-button>
                <popup-button
                    :button-text="showRemoveModal ? 'Delete Webhook' : showEditModal ? 'Save Changes' : 'Add Webhook'"
                    @buttonClick="showRemoveModal ? deleteWebhook() : handleWebhookForm()"
                />
            </template>
        </GeneralPopup>
    </div>
</template>

<script>
import Spinner from '../Spinner';
import GeneralPopup from '../popups/GeneralPopup.vue';
import PopupButton from '../popups/PopupButton.vue';
import { mapGetters } from 'vuex';
import { store } from '@/store';
import {
    isUrlValid,
    raiseError,
    isNotEmptyString,
    hideErrors,
} from '@/services/functions';
import { IntegrationStatusEnum } from '../../utils/constants/common';
import { webhookNotification } from '@/services/notificationService/notificationService';
import Pagination from '../Pagination.vue';

export default {
    name: 'IntegrationWebhooks',
    components: {
        Spinner,
        PopupButton,
        GeneralPopup,
        Pagination,
    },
    data() {
        return {
            webhooksFields: [
                {
                    key: 'index',
                    label: '',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-4', 'center'],
                    formatter: 'checkAndUpdatePlatformText',
                },
                {
                    key: 'url',
                    label: 'Payload URL',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-70', 'left'],
                },
                {
                    key: 'actions',
                    label: '',
                    tdClass: ['table-cell', 'table-column-text', 'table-column-width-26', 'left'],
                },
            ],
            currentPage: 1,
            perPage: 5,
            integrationId: '',
            formData: {
                id: '',
                url: '',
                contentType: '',
            },
            showSpinner: false,
            showHintEditLabel: null,
            showHintDeleteLabel: null,
            showAddModal: false,
            showEditModal: false,
            showRemoveModal: false,
            showSpinnerModal: false,
            isWebHookBtnDisabled: false,
            isWebhookValid: null,
            isEmptyInput: '',
            activeInputName: null,
        };
    },
    computed: {
        ...mapGetters({
            webhooksList: 'integrations/webhooksList',
            webhooksCount: 'integrations/webhooksCount',
            error: 'integrations/errors',
            integration: 'integrations/integration',
        }),
    },
    async beforeMount() {
        if (this.integration.status === IntegrationStatusEnum.SAMPLE) return;
        this.showSpinner = true;
        this.integrationId = this.$route.params.id;
        await this.$store.dispatch('integrations/getWebhooks', this.integrationId);
        this.showSpinner = false;
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        updatePage(page) {
            this.currentPage = page;
        },
        /**
         * This method is called when the user scrolls the page. It hides the hint labels.
         */
        handleScroll() {
            this.hideLabel();
        },
        /**
         * Show the label hint for the specified ID.
         *
         * @param {Event} event - The mouse event that triggered the label hint to be shown.
         * @param {string} id - The ID of the label hint to show.
         */
        showLabel(event, id) {
            switch (event.target.id) {
                case 'edit-webhook-btn':
                    this.showHintEditLabel = id;
                    break;
                case 'delete-webhook-btn':
                    this.showHintDeleteLabel = id;
                    break;
            }
        },
        /**
         * Hide all label hints.
         */
        hideLabel() {
            this.showHintEditLabel = null;
            this.showHintDeleteLabel = null;

        },
        clearInputAndErrors(inputField) {
            this.formData.url = '';
            this.isWebhookValid = null;
            store.commit('integrations/clearErrors');
            hideErrors(['payloadUrlError', 'payloadUrlValidationError', 'commonWebhookError']);
            document.getElementById(inputField).style.border = '1px solid #F0F0F0';
        },
        async handleWebhookForm() {
            const url = this.formData.url;
            const contentType = this.formData.contentType;
            hideErrors(['payloadUrlError', 'payloadUrlValidationError', 'commonWebhookError']);

            if (!isNotEmptyString(url)) {
                return raiseError('payloadUrl', 'payloadUrlError', 'integration-webhooks-input__error');
            }
            if (!isUrlValid(url)) {
                return raiseError('payloadUrl', 'payloadUrlValidationError', 'integration-webhooks-input__error');
            }

            this.showSpinnerModal = true;
            if (this.showAddModal) {
                await this.addWebhook(url, contentType);
            }
            if (this.showEditModal) {
                await this.EditWebhook(url, contentType);
            }
            this.showSpinnerModal = false;
        },
        async addWebhook(url, contentType) {
            try {
                const res = await this.$store.dispatch('integrations/addWebhook', { integrationId: this.integrationId, url, contentType });
                if (res && res.status === 200) {
                    await this.getWebhooksAndCloseModals();
                    this.webhookNotification('created');
                } else {
                    //todo remove setTimeout and fix showing errors
                    setTimeout(() => {
                        raiseError('payloadUrl', 'commonWebhookError', 'integration-webhooks-input__error');
                    }, 0);
                }
            } catch (err) {
                console.log(err);
            }
        },
        async EditWebhook(url, contentType) {
            try {
                const res = await this.$store.dispatch('integrations/updateWebhook', { integrationId: this.integrationId, webhookId: this.formData.id, url, contentType });
                if (res && res.status === 200) {
                    await this.getWebhooksAndCloseModals();
                    this.webhookNotification('updated');
                } else {
                    raiseError('payloadUrl', 'commonWebhookError', 'integration-webhooks-input__error');
                }
            } catch (err) {
                console.log(err);
            }
        },
        async deleteWebhook() {
            this.showSpinnerModal = true;
            const res = await this.$store.dispatch('integrations/deleteWebhook', { integrationId: this.integrationId, webhookId: this.formData.id });
            if (res && res.status === 200) {
                await this.getWebhooksAndCloseModals();
                this.webhookNotification('removed');
            }
            this.showSpinnerModal = false;
        },
        async getWebhooksAndCloseModals() {
            await this.$store.dispatch('integrations/getWebhooks', this.integrationId);
            this.closeModal();
        },
        closeModal() {
            this.$bvModal.hide('general-popup');
            this.showAddModal = false;
            this.showEditModal = false;
            this.showRemoveModal = false;
            this.isWebHookBtnDisabled = false;
        },
        openAddModal() {
            this.clearFormData();
            this.formData.contentType = 'application/json';
            this.showAddModal = true;
            this.$bvModal.show('general-popup');
            this.isWebHookBtnDisabled = true;
        },
        openEditModal(item) {
            this.clearFormData();
            this.formData.id = item.id;
            this.formData.url = item.url;
            this.formData.contentType = item.contentType;
            this.showEditModal = true;
            this.$bvModal.show('general-popup');
            this.isWebHookBtnDisabled = true;
        },
        openRemoveModal(item) {
            this.clearFormData();
            this.formData.id = item.id;
            this.formData.url = item.url;
            this.showRemoveModal = true;
            this.$bvModal.show('general-popup');
            this.isWebHookBtnDisabled = true;
        },
        isAddBtnDisabled() {
            return this.isWebHookBtnDisabled || this.showSpinner
                || this.webhooksCount >= 5 || this.integration.status === IntegrationStatusEnum.SAMPLE;
        },
        clearFormData() {
            this.formData.id = '';
            this.formData.url = '';
            this.formData.contentType = '';
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id;
        },
        handleInput(e) {
            this.checkKeyEvent(e);
            this.isWebhookValid = !isUrlValid(this.formData.url);
            this.handleKeyUp(e);
            store.commit('integrations/clearErrors');
        },
        handleKeyUp(event) {
            const element = document.getElementById(`${event.target.id}Error`);
            element.style.display = 'none';
        },
        webhookNotification,
    },
};
</script>


<style lang="scss">
@import "../../../src/assets/css/variables";

@mixin button {
    background: #FFB400;
    right: 0;
    border: 2px solid #FFB400;
    box-sizing: border-box;
    border-radius: 8px;
    color: #FFFFFF;
    transition: 0.3s;
    padding: 10px 40px;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    &:active {
        opacity: 1;
    }

    &:focus {
        border: 2px solid #FFB400;
    }
}

@mixin spinner {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,0.7);
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 10;
}

@mixin modalForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    max-width: 382px;
    padding-bottom: 20px;
}

#payloadUrlError,
#payloadUrlValidationError,
#commonWebhookError {
    display: none;
}

.integration-webhooks-table {

    thead {

        th {
            color: #B2B9C4;
            font-size: 14px;
            line-height: 140%;
            border-bottom: none;
            vertical-align: middle !important;
        }
    }

    th {
        border-top: none !important;
    }

    tr {
        height: 60px;

        & td:nth-child(1) {
            font-size: 17px;
            width: 4%;
            color: #B2B9C3;

        }

        & td:nth-child(2) {
            max-width: 700px;
            width: 81.45%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 17px;
            color: #3180F6;
            padding-right: 10px !important;
        }

        & td:nth-child(3) {
            display: flex;
            flex-direction: row;
            position: relative;
            padding-top: 25px;
            text-align: end;
            margin-bottom: 20px;

            .webhook-btn {
                margin: 0 10px 0 20px;
            }

            #edit-webhook-btn::before {
                content: "_";
                color: #B2B9C3;
                position: absolute;
                bottom: 8px;
                right: 86px;
                font-size: 19px;
            }

            button:nth-child(1) {
                .edit-webhook-btn-title {
                    @extend .btn-title;
                    width: 50px;
                    top: 0;
                    left: 40px;
                    visibility: visible;
                }
            }

            button:nth-child(2) {
                .delete-webhook-btn-title {
                    @extend .btn-title;
                    width: 110px;
                    top: 0;
                    left: 90px;
                    visibility: visible;
                }
            }
        }
    }
}

.integration-webhooks-table.table tbody tr:last-child td {
    border-bottom: none;
}

// hover on row
table.table-hover tbody tr:hover {
    &:hover {
        .webhook-btn {
            visibility: visible;
        }
    }
}

.webhook-btn {
    visibility: hidden;
}

.integration-webhooks {
    position: relative;
    font-family: Cabin, serif;
    width: 100%;

    &_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #fff;
        border-radius: 20px;
        max-width: 100%;
        height: 550px;
    }

    &-modal_text {
        text-align: center;
    }

    &-add-btn_container {
        position: absolute;
        top: -90px;
        right: 0;
    }

    &-btn {
        @include button;
        line-height: 22px;
        margin-top: 5px;


        &_disable {
            background: #B2B9C3;
            border: 2px solid #B2B9C3;
            opacity: 0.6;
            cursor: default;
        }
    }

    &__form {
        display: flex;
        position: relative;
        width: 100%;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 40px;
    }

    &__form-input-wrapper {
        position: relative;
        .input {
            width: 100%;
        }
    }

    &__input-check {
        position: absolute;
        bottom: 110px;
        right: 20px;
        cursor: pointer;
    }

    &-input__error{
        border: 1px solid #FB8D62;
    }

    &__error {
        width: 100%;
        max-width: 832px;
        position: relative;
    }

    &__error-cross {
        position: absolute;
        top: -58px;
        right: 20px;
    }

    &__error-common_text {
        width: 252px;
        top: 45px !important;
    }

    &__error-text {
        position: absolute;
        top: 45px;
        left: 6px;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        color: #FB8D62;
        margin: -71px 0 0 0;
    }

    &-form {
        &_span {
            display: block;
            margin-left: 5px;
            font-size: 12px;
            color: #B2B9C4;
        }

        &_select {
            display: block;
            background: #fff;
            border: 1px solid #f0f0f0;
            box-sizing: border-box;
            border-radius: 5px;
            font-weight: normal;
            font-size: 14px;
            color: #706969;
            padding: 9px 30px 10px 17px;
            min-height: 45px;
            width: 100%;

            option {
                font-size: 15px;
            }
        }

        &_btn {
            display: flex;
            justify-content: center;
        }
    }

    &-container-text {
        max-width: 530px;
        margin: 0 auto;
        padding: 0 15px;
        font-size: 16px;
        text-align: center;
        color: #706969;
    }

    &-spinner {
        @include spinner;
        border-radius: 20px;
    }

    &__pagination {
        padding: 0 0 12px 0;
    }
}

@media (max-width: 1200px) {
    .integration-webhooks-table tr td:nth-child(3) #edit-webhook-btn::before{
        right: 75px;
    }
}

@media (max-width: 1000px) {
    .integration-webhooks {
        justify-content: flex-start;
        width: 100%;
        margin-top: 10px;

        &-add-btn_container {
            position: relative;
            top: -22px;
            margin-left: 180px;
        }

        &-table_wrapper {
            margin-left: 0;
        }

        &-spinner {
            height: 92%;
        }
    }

    .integration-webhooks-table tbody {
        tr {
            & td:nth-child(2) {
                max-width: 235px;
            }

            & td:nth-child(3) {

                button:nth-child(2) {
                    .delete-webhook-btn-title {
                        width: 68px;
                        top: -22px;
                        left: 90px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .integration-webhooks {

        &-add-btn_container {
            margin-left: 90px;
        }

        &__pagination {
            justify-content: space-between;
            margin: 0 !important;
        }
    }
}
</style>
