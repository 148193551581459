import {store} from '../src/store/index'

export const defaultBranchRedirect = async (to, from, next) => {
    const {id} = to.params
    await store.dispatch('integrations/getIntegration', {id: id})
    let integration = store.getters['integrations/integration'];
    if (!integration) {
        next('/404')
    }
    if (integration.defaultBranchName) {
        next(`/integration/${integration.id}/branch/${integration.defaultBranchName}`)
    } else {
        next(`/integrations`)
    }
}
