<template>
    <div>
        <div
            v-if="latestVersion"
            class="statistics"
        >
            <div class="statistics__title">
                Latest version statistics
            </div>
            <b-skeleton-wrapper :loading="isLoading">
                <template #loading>
                    <b-skeleton
                        type="text"
                        height="20px"
                        width="150px"
                        style="margin-bottom: 16px;"
                    />
                </template>
            </b-skeleton-wrapper>
            <p
                v-if="latestVersion && latestVersion.statistics && latestVersion.statistics.count > 0 && !isLoading"
                class="statistics__count"
            >
                {{ getVulnerabilitiesFoundText(latestVersion.statistics.count) }}
            </p>
            <div class="statistics__wrapper">
                <!-- HIGH -->
                <div class="statistics__box">
                    <div class="statistics__box__container">
                        <div class="calculation-block">
                            <b-skeleton-wrapper :loading="isLoading">
                                <template #loading>
                                    <b-skeleton
                                        type="text"
                                        width="64px"
                                        height="65px"
                                        style="border-radius: 50%;"
                                    />
                                    <b-skeleton
                                        type="text"
                                        class="percentage"
                                        height="31px"
                                    />
                                </template>
                            </b-skeleton-wrapper>
                            <statistic-circle
                                v-if="latestVersion && latestVersion.statistics && !isLoading"
                                :value="latestVersion.statistics.severity.high"
                                :total="latestVersion.statistics.count"
                                :width="64"
                                :height="65"
                                :stroke-size="4"
                                severity="high"
                            />
                            <div
                                v-if="!isLoading"
                                class="percentage"
                            >
                                {{ getPercentageBySeverity(3) ? getPercentageBySeverity(3) : 0 }}%
                            </div>
                        </div>
                        <div class="description-block">
                            <b-skeleton-wrapper :loading="isLoading">
                                <template #loading>
                                    <div class="title">
                                        High severity vulnerabilities
                                    </div>
                                </template>
                            </b-skeleton-wrapper>
                            <div
                                v-if="latestVersion && latestVersion.statistics && !isLoading"
                                class="title"
                            >
                                High severity {{ isVulnerabilityAmountPlural(latestVersion.statistics.severity.high) }}
                            </div>
                            <div class="details">
                                Can lead to user data being leaked and to a serious violation of the user's privacy
                            </div>
                        </div>
                    </div>
                </div>
                <!-- MEDIUM -->
                <div class="statistics__box">
                    <div class="statistics__box__container">
                        <div class="calculation-block">
                            <b-skeleton-wrapper :loading="isLoading">
                                <template #loading>
                                    <b-skeleton
                                        type="text"
                                        width="64px"
                                        height="65px"
                                        style="border-radius: 50%;"
                                    />
                                    <b-skeleton
                                        type="text"
                                        class="percentage"
                                        height="31px"
                                    />
                                </template>
                            </b-skeleton-wrapper>
                            <statistic-circle
                                v-if="latestVersion && latestVersion.statistics && !isLoading"
                                :value="latestVersion.statistics.severity.medium"
                                :total="latestVersion.statistics.count"
                                :width="64"
                                :height="64"
                                :stroke-size="4"
                                severity="medium"
                            />
                            <div
                                v-if="!isLoading"
                                class="percentage"
                            >
                                {{ getPercentageBySeverity(2) ? getPercentageBySeverity(2) : 0 }}%
                            </div>
                        </div>
                        <div class="description-block">
                            <b-skeleton-wrapper :loading="isLoading">
                                <template #loading>
                                    <div class="title">
                                        Medium severity vulnerabilities
                                    </div>
                                </template>
                            </b-skeleton-wrapper>
                            <div
                                v-if="latestVersion && latestVersion.statistics && !isLoading"
                                class="title"
                            >
                                Medium severity {{ isVulnerabilityAmountPlural(latestVersion.statistics.severity.medium) }}
                            </div>
                            <div class="details">
                                May affect the app's level of protection and reduce the level of security
                            </div>
                        </div>
                    </div>
                </div>
                <!-- LOW -->
                <div class="statistics__box">
                    <div class="statistics__box__container">
                        <div class="calculation-block">
                            <b-skeleton-wrapper :loading="isLoading">
                                <template #loading>
                                    <b-skeleton
                                        type="text"
                                        width="64px"
                                        height="65px"
                                        style="border-radius: 50%;"
                                    />
                                    <b-skeleton
                                        type="text"
                                        class="percentage"
                                        height="31px"
                                    />
                                </template>
                            </b-skeleton-wrapper>
                            <statistic-circle
                                v-if="latestVersion && latestVersion.statistics && !isLoading"
                                :value="latestVersion.statistics.severity.low"
                                :total="latestVersion.statistics.count"
                                :width="64"
                                :height="64"
                                :stroke-size="4"
                                severity="low"
                            />
                            <div
                                v-if="!isLoading"
                                class="percentage"
                            >
                                {{ getPercentageBySeverity(1) ? getPercentageBySeverity(1) : 0 }}%
                            </div>
                        </div>
                        <div class="description-block">
                            <b-skeleton-wrapper :loading="isLoading">
                                <template #loading>
                                    <div class="title">
                                        Low severity vulnerabilities
                                    </div>
                                </template>
                            </b-skeleton-wrapper>
                            <div
                                v-if="latestVersion && latestVersion.statistics && !isLoading"
                                class="title"
                            >
                                Low severity {{ isVulnerabilityAmountPlural(latestVersion.statistics.severity.low) }}
                            </div>
                            <div class="details">
                                Violate best practices
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { isHighSeverityExist, isMediumSeverityExist } from '../../services/functions';
import { isVulnerabilityAmountPlural } from '../../utils/helper-functions';
import statisticCircle from '../report/components/statisticCircle.vue';

export default {

    components: {
        statisticCircle,
    },
    data() {
        return {
            isLoading: false,
            listPercentage: [],
            integrationId: '',
            branchName: '',
        };
    },
    computed: {
        ...mapGetters({
            latestVersion: 'integrations/latestVersionStatistics',
        }),
    },
    watch: {
        latestVersion() {
            if (this.latestVersion && this.latestVersion.statistics) {
                this.correctPercentage(this.latestVersion.statistics.severity, Number(this.latestVersion.statistics.count));
            }
        },
        '$route.params.branchName': {
            handler () {
                this.loadStatisticsData();
            },
            deep: true,
        },
    },
    beforeMount() {
        this.loadStatisticsData();
    },
    mounted() {
        if (this.latestVersion && this.latestVersion.statistics) {
            this.correctPercentage(this.latestVersion.statistics.severity, Number(this.latestVersion.statistics.count));
        }
    },
    methods: {
        getPercentageBySeverity(severity) {
            if (this.listPercentage[severity - 1]) return this.listPercentage[severity - 1];
        },
        correctPercentage(digit, countVulnerability) {
            this.listPercentage = [];
            if (digit['low']) this.listPercentage.push(Math.ceil(countVulnerability?Number(digit['low'])*100/countVulnerability:0));
            if (digit['medium']) this.listPercentage.push(Math.ceil(countVulnerability?Number(digit['medium'])*100/countVulnerability:0));
            if (digit['high']) this.listPercentage.push(Math.ceil(countVulnerability?Number(digit['high'])*100/countVulnerability:0));
            const sum = this.listPercentage.reduce(function (sum, current) {
                return sum + current;
            }, 0);
            const indexMaxNumber = this.listPercentage.indexOf(Math.max.apply(null, this.listPercentage));
            const indexMinNumber = this.listPercentage.indexOf(Math.min.apply(null, this.listPercentage));
            if (sum < 100 && sum !== 0) {
                this.listPercentage[indexMinNumber] = this.listPercentage[indexMinNumber] + (100 - sum);
            }
            if (sum > 100) {
                this.listPercentage[indexMaxNumber] = this.listPercentage[indexMaxNumber] - (sum - 100);
            }
        },
        async loadStatisticsData() {
            this.isLoading = true;
            this.integrationId = this.$route.params.id;
            this.branchName = this.$route.params.branchName;
            await this.$store.dispatch('integrations/loadLatestVersionStatistics', {id: this.integrationId, branchName: this.branchName});
            this.isLoading = false;
        },
        getVulnerabilitiesFoundText(num) {
            let vulnerabilitiesWord = this.isVulnerabilityAmountPlural(num);
            return `${num} ${vulnerabilitiesWord} found`;
        },
        isHighSeverityExist,
        isMediumSeverityExist,
        isVulnerabilityAmountPlural,
    },
};
</script>
<style lang="scss" scoped>
@import '../../assets/css/fonts';
@import '../../assets/css/variables';

// Desktop
.statistics {
    margin: 40px 7.5%;

    &__title {
        @include Aspekta-font(650, 24px, 33px, #021631);
        margin-bottom: 8px;
    }

    &__count {
        @include Aspekta-font(400, 14px, 20px, #949DB8);
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr)); /* adjust the min value as needed */
        gap: 20px;
        width: 100%;
    }

    &__box {
        background: #fff;
        border-radius: 20px;

        &__container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 20px;

            margin: 20px;

            .calculation-block {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-width: 64px;

                .percentage {
                    margin-top: 10px;
                    text-align: center;

                    @include Aspekta-font(650, 22px, 31px, #021631);
                }
            }

            .description-block {
                min-width: 177px;
                min-height: 142px;

                .title {
                    @include Aspekta-font(600, 18px, 26px, #242424);
                }

                .details {
                    margin-top: 10px;

                    @include Aspekta-font(400, 14px, 20px, #5C6A7A);
                    letter-spacing: 0.02em;
                }
            }
        }
    }
}

// Tablet
@media (max-width: 834px) {
    .statistics {
        &__wrapper {
            display: flex;
            flex-direction: column;
        }

        &__box {
            &__container {
                flex-direction: row-reverse;
                justify-content: space-between;

                .description-block {
                    min-height: 80px;
                }

                .calculation-block {

                    .percentage {
                        display: none;
                    }
                }
            }
        }
    }
}

// Mobile
@media (max-width: 576px) {
    .statistics {
        margin: 36px 20px 40px 20px;

        &__wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__box {
            background: none;
            &__container {
                margin: 0;
                .description-block {
                    display: none;
                }

                .calculation-block {

                    .percentage {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
