<template>
    <div class="content-card">
        <div class="header-card">
            <img
                class="header-card-alert"
                src="../../../assets/images/jam_alert-f.svg"
                alt="alert"
            >
            <span class="header-card-number">{{ number }}</span>
            <span class="header-card-percentage"> {{ sign }} </span>
        </div>
        <span class="text-description">
            {{ text }}
        </span>
    </div>
</template>
<script>


export default {
    props:{
        number: {
            type: String,
            default:'',
        },
        text: {
            type: String,
            default:'',
        },
        sign: {
            type: String,
            default:'%',
        },

    },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/css/variables.scss";
@import "../../../assets/css/fonts";

.content-card{
    display: flex;
    flex: 1;
    padding: 2% 3% 3%;
    flex-direction: column;
    // justify-content: space-between;
    border-radius: 24px;
    border: 2px solid rgba(241, 240, 245, 0.08);
    background: rgba(167, 180, 205, 0.05);
}

.header-card{
    display: flex;

    &-number{
        margin-left: 16px;
        @include Aspekta-font(700, $dynamic-card-header-number-font-size, normal, #FFF);
        align-self: baseline
    }
    &-percentage{
        @include Aspekta-font(700, $dynamic-card-header-persentage-font-size, normal, #FFF);
        margin-left: 10px;
        align-self: baseline;
    }
    &-alert{
        width: $dynamic-card-header-alert-width;
    }
}
.text-description{
    @include Aspekta-font(400,$dynamic-card-text-description-font-size,normal,#FFF);
    text-align: left;
    opacity: 0.5;
    margin-top: 15px
}

@media (max-width: 768px) {

  .content-card{
    padding: 10% 10%;
  }
  .header-card-number{
    font-size: $dynamic-card-header-number-fs-mobile;
  }
  .header-card-percentage{
    font-size: $dynamic-card-header-persentage-fs-mobile;
  }
  .text-description{
    margin-top: 8px;
    font-size: $dynamic-card-text-description-fs-mobile;
  }
  .header-card-alert{
    width: 35px;
  }

}



</style>
