<template>
    <div class="integration-settings">
        <div class="integration-settings__main-wrapper" v-if="integration">
            <form @submit.prevent="onFormSubmit" class="integration-settings__form">
                <div class="integration-settings__icon-box">
                    <button class="integration-settings__save-btn cabin cabin-bold" :disabled="showSpinner">Save Changes</button>
                    <img id="integration_logo" class="integration-settings__int-img" alt=""
                         @error="errorLoaded" :src="logo ? logo : DefaultLogo"
                    >
                    <image-uploader
                        id="integration-settings__load-image-btn"
                        class="integration-settings__load-image-btn"
                        :maxWidth="300"
                        :maxHeight="300"
                        :quality="1"
                        outputFormat="verbose"
                        :preview="false"
                        :capture="false"
                        accept=".png, .jpg, .jpeg"
                        @input="setImage"
                    ></image-uploader>
                    <label
                        class="integration-settings__load-image-label"
                        for="integration-settings__load-image-btn"
                    >
                        Upload New Image <br>
                    </label>
                </div>
                <div class="integration-settings__main">
                    <div class="integration-settings__form-input-wrapper">
                        <input
                            v-model.trim="integrationName"
                            id="settingsIntegrationName"
                            type="text"
                            @input="handleInput"
                            @blur="activeInputName = null"
                            class="input input-long cabin cabin-bold"
                        >
                        <img v-if="integrationName.length > 0"
                            @click="clearInputAndErrors('settingsIntegrationName')"
                            class="integration-settings__form-input-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                        >
                        <label for="settingsIntegrationName" class="label" :class="{ activeInput: integrationName !== isEmptyInput || activeInputName === 'settingsIntegrationName'}">Integration Name</label>
                        <div id="settingsIntegrationNameError" class="integration-settings__form-error">
                            <img
                                @click="clearInputAndErrors('settingsIntegrationName')"
                                class="integration-settings__form-error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="integration-settings__form-error-text">Required field</p>
                        </div>
                        <div id="settingsIntegrationNameLengthError" class="integration-settings__form-error">
                            <img
                                @click="clearInputAndErrors('settingsIntegrationName')"
                                class="integration-settings__form-error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="integration-settings__form-error-text">Max input length 1000</p>
                        </div>
                    </div>
                    <div class="integration-settings__form-input-wrapper">
                        <textarea
                            v-model.trim="integrationDescription"
                            id="settingsIntegrationDescription"
                            @input="handleInput"
                            @blur="activeInputName = null"
                            class="input input-long textarea cabin"
                        >
                        </textarea>
                        <img v-if="integrationDescription.length > 0"
                            @click="clearInputAndErrors('settingsIntegrationDescription')"
                            class="integration-settings__form-input-check"
                            src="../../assets/images/cross_grey.svg"
                            alt="cross"
                        >
                        <label for="settingsIntegrationDescription" class="label" v-bind:class="{ activeInput: integrationDescription !== isEmptyInput || activeInputName === 'settingsIntegrationDescription'}">Description</label>
                        <div id="settingsIntegrationDescriptionError" class="integration-settings__form-error">
                            <img
                                @click="clearInputAndErrors('settingsIntegrationDescription')"
                                class="integration-settings__form-error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="integration-settings__form-error-text">Required field</p>
                        </div>
                        <div id="settingsIntegrationDescriptionLengthError" class="integration-settings__form-error">
                            <img
                                @click="clearInputAndErrors('settingsIntegrationDescription')"
                                class="integration-settings__form-error-cross"
                                src="../../assets/images/cross.svg"
                                alt="cross"
                            >
                            <p class="integration-settings__form-error-text">Max input length 1000</p>
                        </div>
                    </div>
                    <div class="integration-settings__box-wrapper">
                        <div class="integration-settings__box">
                            <b-form-checkbox-group
                                v-model="selected"
                                :options="DoneScanNotifyOptions"
                                size="lg"
                                switches
                                class="switch integration-settings__switch"
                            ></b-form-checkbox-group>
                            <b-form-checkbox-group
                                v-model="selected"
                                :options="ExpireIntegrationNotifyOptions"
                                size="lg"
                                switches
                                class="switch integration-settings__switch"
                            ></b-form-checkbox-group>
                            <!-- leave _Automatically pull new versions from Google Play_ feature for the future -->
                            <!-- <b-form-checkbox-group
                                v-model="selected"
                                :options="PullNewVersion"
                                size="lg"
                                switches
                                class="switch integration-settings__switch"
                            ></b-form-checkbox-group> -->
                        </div>
                        <div class="integration-settings__switch-wrapper">
                            <b-form-checkbox-group
                                v-model="selected"
                                :options="SmartFilterOptions"
                                size="lg"
                                switches
                                class="switch integration-settings__switch"
                            ></b-form-checkbox-group>
                        </div>
                        <div class="integration-settings__icon-alt">
                            <p class="smart-filter-btn-title">SmartFilter excludes improbable and difficult to exploit
                                vulnerabilities from the report.
                                If you don't use this option you should expect a greater number of false positives,
                                although
                                it may help you discover some
                                valid and difficult vulnerabilities.</p>
                            <img class="integration-settings__switch-icon"
                                 src="../../assets/images/integration-settings-toggle-icon.svg" alt="">
                        </div>
                    </div>
                </div>
            </form>
            <div v-if="isShowNotice" class="integration-settings__notice">
                <h2 class="integration-settings__notice-sign cabin">Leave site?</h2>
                <p class="integration-settings__notice-text cabin">Changes you may not be saved.</p>
                <div class="integration-settings__notice-box">
                    <button class="integration-settings__notice-btn cabin">Leave</button>
                    <button class="integration-settings__notice-btn integration-settings__notice-btn--orange cabin">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
        <div v-if="showSpinner" class="integration-settings__spinner">
            <Spinner/>
        </div>
        <PageNotFound v-if="!integration"/>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import {store} from '@/store';
import Spinner from '../Spinner';
import PageNotFound from '../pageNotFound/PageNotFound';
import ImageUploader from 'vue-image-upload-resize';
import DefaultLogo from '../../assets/images/default-integration-icon.png';
import {
    checkFormInputsForCompleteness,
    checkFormInputsForErrors,
    checkFormInputsLength,
    hideErrors
} from '@/services/functions';
import {successNotification, failNotification} from '@/services/notificationService/notificationService';

export default {
    name: 'IntegrationSettings',
    components: {
        Spinner,
        PageNotFound,
        ImageUploader,
    },
    data() {
        return {
			DefaultLogo,
            showSpinner: false,
            integrationName: 'Integration',
            isLogoChanged: false,
            logo: '',
            integrationIconFile: null,
            integrationDescription: '',
            selectedDoneScanNotify: false,
            selectedExpireIntegrationNotify: false,
            selectedSmartFilter: false,
            // leave _Automatically pull new versions from Google Play_ feature for the future
            // selectedPullNewVersion: false,
            selected: [], // Must be an array reference!
            DoneScanNotifyOptions: [
                {text: 'Notify me when the scan has been completed', value: 'DoneScanNotify'},
            ],
            SmartFilterOptions: [
                {text: 'Enable SmartFilter', value: `SmartFilter`},
            ],
            ExpireIntegrationNotifyOptions: [
                {text: 'Notify me when my subscription is about to expire', value: 'ExpireIntegrationNotify'},
            ],
            // leave _Automatically pull new versions from Google Play_ feature for the future
            // PullNewVersion: [
            //     {text: 'Automatically pull new versions from Google Play', value: 'PullNewVersion'},
            // ],
            isShowNotice: false,
            isEmptyInput: "",
            activeInputName: null,
            branchName: null,
            integrationId: null
        }
    },
    async beforeMount() {
        this.showSpinner = true
        this.integrationId = this.$route.params.id
        await this.$store.dispatch('integrations/getIntegration', {id: this.integrationId})
        await this.$store.dispatch('integrations/getBranchList', {integrationId: this.integrationId})
        this.branchList.forEach(branch => {
            if (branch.id === this.integration.defaultBranchId) this.branchName = branch.name
        })
        if (!this.integration) document.title = `Page not found | Oversecured`
        this.showSpinner = false
    },
    watch: {
        integration() {
            this.integrationName = this.integration ? this.integration.integrationName : null
            this.integrationDescription = this.integration ? this.integration.integrationDescription : null
            this.logo = this.integration ? this.integration.signedUrl : null
            if (this.integration.doneScanNotify) {
                this.selected.push('DoneScanNotify')
            } else {
                const index = this.selected.indexOf('DoneScanNotify')
                if (index > -1) this.selected.splice(index, 1)

            }
            if (this.integration.expireIntegrationNotify) {
                this.selected.push('ExpireIntegrationNotify')
            } else {
                const index = this.selected.indexOf('ExpireIntegrationNotify')
                if (index > -1) this.selected.splice(index, 1)

            }
            if (this.integration.smartFilter) {
                this.selected.push('SmartFilter')
            } else {
                const index = this.selected.indexOf('SmartFilter')
                if (index > -1) this.selected.splice(index, 1)
            }
            // leave _Automatically pull new versions from Google Play_ feature for the future
            // if (this.integration.pullNewVersion) {
            //     this.selected.push('PullNewVersion')
            // }
            //  else { const index = this.selected.indexOf('PullNewVersion')
            //     if (index > -1) this.selected.splice(index, 1)
            // }
        }
    },
    computed: {
        ...mapGetters({
            integration: 'integrations/integration',
            urlFromS3ForIcon: 'integrations/urlFromS3ForIcon',
            branchList: 'integrations/branchList'
        }),
    },
    methods: {
        clearInputAndErrors(inputField) {
            if (inputField === 'settingsIntegrationName') {
                this.integrationName = ''
                hideErrors(['settingsIntegrationNameError', 'settingsIntegrationNameErrorLength'])
            } else if (inputField === 'settingsIntegrationDescription') {
                this.integrationDescription = ''
                hideErrors(['settingsIntegrationDescriptionError', 'settingsIntegrationDescriptionErrorLength'])
            }
            document.getElementById(inputField).style.border = '1px solid #F0F0F0'
        },
		errorLoaded() {
			this.showSpinner = false
			document.getElementById("integration_logo").src = this.DefaultLogo
		},
        setImage(file) {
            this.isLogoChanged = true
            this.logo = file.dataUrl

            // Convert dataURL to a File object
            this.integrationIconFile = this.dataURLtoFile(file.dataUrl, file.info)
        },
        dataURLtoFile(dataUrl, fileInfo) {
            // Extract the base64 data from the dataURL.
            const base64Data = dataUrl.split(',')[1];

            // Convert the base64 data to binary format using a buffer.
            const binaryStr = Buffer.from(base64Data, 'base64');

            // Create a new file from the binary data and the provided information.
            return new File([binaryStr], fileInfo.name, { type: fileInfo.type });
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id
        },
        handleInput(e) {
            this.checkKeyEvent(e)
            this.handleKeyUp(e)
            store.commit('authentication/clearErrors')
        },
        handleKeyUp(event) {
            const element = document.getElementById(`${event.target.id}Error`)
            element.style.display = 'none'
        },
        imageLoaded() {
            this.showSpinner = false
        },
        getTogglesInfo(selected) {
            selected.map(el => {
                if (el === 'DoneScanNotify') this.selectedDoneScanNotify = true

                if (el === 'ExpireIntegrationNotify') this.selectedExpireIntegrationNotify = true

                if (el === 'SmartFilter') this.selectedSmartFilter = true

                // leave _Automatically pull new versions from Google Play_ feature for the future
                // if (el === 'PullNewVersion') this.selectedPullNewVersion = true
            })
        },
        checkForErrors() {
            checkFormInputsForErrors({settingsIntegrationName: this.integrationName, settingsIntegrationDescription: this.integrationDescription}, 'integration-settings__input--error')
            checkFormInputsLength({settingsIntegrationName: this.integrationName, settingsIntegrationDescription: this.integrationDescription}, 'integration-settings__input--length-error')
        },
        async onFormSubmit() {
            this.checkForErrors()

            if (checkFormInputsForCompleteness({settingsIntegrationName: this.integrationName, settingsIntegrationDescription: this.integrationDescription})) {
                this.getTogglesInfo(this.selected)

                this.showSpinner = true

                await this.updateSettingsData({
                    data: {
                        integrationName: this.integrationName,
                        integrationDescription: this.integrationDescription,
                        doneScanNotify: this.selectedDoneScanNotify,
                        expireIntegrationNotify: this.selectedExpireIntegrationNotify,
                        smartFilter: this.selectedSmartFilter,
                        isLogoChanged: this.isLogoChanged,
                        fileName: this.isLogoChanged ? this.integrationIconFile.name : '',
                        defaultBranchId: this.integration.defaultBranchId
                    }
                })

                if (this.urlFromS3ForIcon.bucketKeyGenerated) {
                    try {
                        await this.loadImageOnS3()
                    } catch (error) {
                        this.failNotification()
                    }
                }

                await this.$store.dispatch('integrations/getIntegration', {id: this.integrationId})

                this.successNotification('settingsUpdated')
                this.showSpinner = false
            }
        },
        async loadImageOnS3() {
            const xhr = new XMLHttpRequest();
            const urlFromS3ForIcon = this.urlFromS3ForIcon.url;
            const integrationIconFile = this.integrationIconFile;

            xhr.timeout = 3000 * 1000 // milliseconds

            xhr.open('PUT', urlFromS3ForIcon);

            xhr.onload = () => {
                if (xhr.status === 200) {
                    console.log('Upload successful')
                } else {
                    this.showSpinner = false
                    console.log('XHR error:', xhr.statusText)
                }
            };

            xhr.onerror = () => {
                this.showSpinner = false
                console.log('XHR network error')
            }

            xhr.send(integrationIconFile);
        },
        async updateSettingsData(data) {
            await this.$store.dispatch('integrations/updateSettingsData', {
                integrationId: this.integration.id,
                ...data
            })
        },
        successNotification,
        failNotification
    }
}
</script>
<style lang="scss">
@import "../../assets/css/variables";

.alert-message {
    position: absolute;
}

.integration-settings {
    position: relative;
    width: 100%;

    &-container.page-content {
        padding: 0 0 70px 0;
    }

    &__notice {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);
        background-color: white;
        max-width: 402px;
        max-height: 150px;
        width: 100%;
        height: 100%;
        padding: 18px 20px;
        box-shadow: 0 4px 15px rgba(56, 70, 77, 0.04);
        animation: settingsNotice;
        animation-duration: 0.4s;
    }

    &__form-input-check {
        position: absolute;
        bottom: 45px;
        right: 20px;
        cursor: pointer;
    }

    &__form-error {
        width: 100%;
        position: relative;
        top: -30px;
    }

    &__form-error-cross {
        position: absolute;
        top: -25px;
        right: 20px;
        cursor: pointer;
    }

    &__form-error-text {
        position: absolute;
        top: 0;
        left: 0;
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 150%;
        color: #FB8D62;
        margin: 0;
    }

    &__notice-sign {
        font-size: 18px;
        color: #242424;
    }

    &__form {
        display: flex;
    }

    &__icon-box {
        display: flex;
        flex-direction: column;
    }

    &__notice-btn {
        background: #FFFFFF;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 14px;
        color: #FFB400;
        max-width: 95px;
        width: 100%;
        padding: 8px 0;

        &--orange {
            background: #FFB400;
            border: 2px solid #FFB400;
            color: #FAFBFC;
            margin-left: 10px;
        }

        &:hover {
            opacity: 0.8;
        }

        &:active {
            opacity: 1;
        }
    }

    &__notice-box {
        display: flex;
        justify-content: flex-end;
        margin-top: 23px;
    }

    &__notice-text {
        font-size: 14px;
        color: #706969;
    }

    .bv-no-focus-ring {
        margin: 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-content: center;
    }

    &__sign {
        font-size: 30px;
        line-height: 150%;
        color: #242424;
    }

    &__save-btn {
        background: #FFB400;
        position: absolute;
        top: -90px;
        right: 0px;
        border: 2px solid #FFB400;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        padding: 10px 40px;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }

        &:active {
            opacity: 1;
        }

        &:focus {
            border: 2px solid #FFB400;
        }
    }

    &__main-wrapper {
        background-color: white;
        padding: 40px 50px;
        display: flex;
        width: 100%;
        border-radius: 20px;
    }

    &__int-img {
        width: 80px;
        height: 80px;
        border-radius: 5px;
    }

    &__main {
        display: flex;
        flex-direction: column;
        margin-left: 50px;
        width: 100%;
    }

    &__box-wrapper {
        margin-top: 20px;
        display: flex;
        align-items: flex-start;
    }

    &__box {
        display: flex;
        flex-direction: column;
        margin: 0 46px 20px 0;
    }

    &__switch {
        &:nth-child(2),
        &:nth-child(3) {
            margin-top: 20px;
        }
    }

    &__switch-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__icon-alt {
        position: relative;
        margin-right: 110px;

        .smart-filter-btn-title {
            @extend .btn-title;
            width: 500px;
            left: -30%;
            top: -75px;
            padding: 5px;
        }

        &:hover {
            .smart-filter-btn-title {
                visibility: visible;
            }
        }

    }
    &__form-input-wrapper {
        position: relative;
    }

    &__input,
    &__texarea {
        &--error {
            border: 1px solid #FB8D62;
            border-radius: 5px !important;
        }
    }

    .custom-control-label span {
        font-family: Cabin, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: #242424;
    }

    .custom-control-label {
        display: flex;
        align-items: flex-end;
        height: 26px;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #1FA8C7;
        background-color: rgba(31, 168, 199, 0.4);
    }

    .custom-switch {
        width: 100%;
    }

    &__spinner {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 20px;
        z-index: 10;
    }
}

.integration-settings__load-image-btn {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.integration-settings__load-image-label {
    display: flex;
    max-width: 80px;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
    font-size: 13px;
    border-bottom: 1px solid transparent;
    text-align: center;

    &:hover {
        text-decoration: underline;
    }
}

#settingsIntegrationNameError,
#settingsIntegrationDescriptionError,
#settingsIntegrationNameLengthError,
#settingsIntegrationDescriptionLengthError {
    display: none;
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
    .integration-settings {

        &__icon-alt {
            position: relative;
            top: -25px;
            right: -164px;
            margin-right: 496px;

            .smart-filter-btn-title {
                width: 500px;
                left: -3px;
                top: -82px;
                padding: 5px;

            }

            &:hover {
                .smart-filter-btn-title {
                    visibility: visible;
                }
            }
        }

        &__switch-icon {
            margin: 0;
        }

        &__box-wrapper {
            margin-top: 20px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }

        &__switch-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
        }
    }

    img {
        vertical-align: middle;
        border-style: none;
    }
}

@media (max-width: 1000px) {
    .integration-settings {

        .bv-no-focus-ring {
            margin: 0;
            width: 100%;
        }

        &__sign {
            margin: 0 0 0 20px;
        }

        &__form {
            flex-direction: column;
        }

        &__main-wrapper {
            flex-direction: column;
            padding: 0 20px;
            background-color: #fff;
        }

        &__box-wrapper {
            margin-top: 20px;
            display: flex;
            align-items: flex-start;

            flex-direction: column;
        }

        &__box {
            display: flex;
            margin: 0;
            width: 100%;
        }

        &__switch {
            &:nth-child(2) {
                margin-top: 15px;
            }

            &--margin {
                margin-right: 0 !important;
            }
        }

        &__main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 30px 0 0 0;
            width: 100%;
        }

        &__save-btn {
            width: 100%;
            display: block;
            position: relative;
            top: 0;
            right: 0;
            max-width: 184px;
            padding: 10px 0;
            margin: 60px auto 20px;
        }

        .custom-control-label span {
            font-family: Cabin, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            color: #242424;
            width: 296px;
            word-wrap: break-word;
        }

        .custom-control-label {
            display: flex;
            align-items: flex-end;
            height: 26px;
        }

        &__switch-wrapper {
            margin-top: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        &__int-img {
            margin-top: 20px;
        }

        &__icon-alt {
        position: relative;
        top:-25px;
        left: 160px;

            .smart-filter-btn-title {
                max-width: 370px;
                left: 0;
                top: -490%;
                padding: 5px;
            }

            &:hover {
                .smart-filter-btn-title {
                    visibility: visible;
                }
            }
        }

        &__switch-icon {
            margin: 0;
        }
    }
}

@media (max-width: 768px) {

    .nav-side-bar {
        width: 100% !important;
        padding: 0 20px 0 20px !important;
        justify-content: center !important;
        overflow: hidden !important;
    }

    .integration-settings-container {
        background-color: #FAFBFC;
        padding: 20px 0 70px 0 !important;
    }

    .integration-settings {

        &__icon-alt {
        position: relative;
        top: -25px;
        left: 160px;

            .smart-filter-btn-title {
                max-width: 270px;
                left: 1px;
                top: -570%;
                padding: 5px;
            }

            &:hover {
                .smart-filter-btn-title {
                    visibility: visible;
                }
            }

        }

        &__switch-icon {
            margin: 0;
        }

        &__save-btn {
            margin: 30px auto 20px;
        }


    }
}

@media (max-width: 375px) {
    .integration-settings {
        .custom-control-label span {
           width: 260px;
        }
    }

}

@keyframes settingsNotice {
    0% {
        top: -150px;
    }

    50% {
        top: 10px;
    }

    70% {
        top: -5px;
    }

    100% {
        top: 0;
    }
}
</style>
