<template>
    <div>
        <TermsList />
        <MainMarketApps v-bind:isAuthenticated="isAuthenticated"/>
    </div>
</template>
<script>
    import MainMarketApps from "../components/main/MainMarketApps";
    import TermsList from '../components/terms/TermsList'
    import {mapGetters} from "vuex";
    export default {
        components: {
            TermsList,
            MainMarketApps
        },
        computed: {
            isAuthenticated: {
                get(){
                    return this.$auth.isAuthenticated
                }
            }
        },
    }
</script>
