<template>
    <div>
        <!-- in viewBox 3rd argument is width 4th argument is height  -->
        <svg
            :fill="fill"
            :height="height"
            viewBox="0 0 29 28"
            :width="width"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                clip-rule="evenodd"
                :fill="fill"
                fill-rule="evenodd"
            >
                <path d="M10.0339 8.82928C11.1992 8.41748 12.0339 7.30621 12.0339 6C12.0339 4.34314 10.6908 3 9.03394 3C7.37708 3 6.03394 4.34314 6.03394 6C6.03394 7.30621 6.86871 8.41748 8.03394 8.82928V15.1707C6.86871 15.5825 6.03394 16.6938 6.03394 18C6.03394 19.3062 6.86871 20.4175 8.03394 20.8293V24C8.03394 24.5522 8.48169 25 9.03394 25C9.58618 25 10.0339 24.5522 10.0339 24V20.8293C10.8864 20.528 11.562 19.8524 11.8632 19H16.0339C18.2429 19 20.0339 17.209 20.0339 15V8.82928C21.1992 8.41748 22.0339 7.30621 22.0339 6C22.0339 4.34314 20.6908 3 19.0339 3C17.3771 3 16.0339 4.34314 16.0339 6C16.0339 7.30621 16.8687 8.41748 18.0339 8.82928V15C18.0339 16.1045 17.1384 17 16.0339 17H11.8632C11.562 16.1476 10.8864 15.472 10.0339 15.1707V8.82928ZM10.0339 6C10.0339 6.55231 9.58624 7 9.03394 7C8.48163 7 8.03394 6.55231 8.03394 6C8.03394 5.44769 8.48163 5 9.03394 5C9.58624 5 10.0339 5.44769 10.0339 6ZM19.0339 7C19.5862 7 20.0339 6.55231 20.0339 6C20.0339 5.44769 19.5862 5 19.0339 5C18.4816 5 18.0339 5.44769 18.0339 6C18.0339 6.55231 18.4816 7 19.0339 7ZM9.03394 19C9.58624 19 10.0339 18.5523 10.0339 18C10.0339 17.4477 9.58624 17 9.03394 17C8.48163 17 8.03394 17.4477 8.03394 18C8.03394 18.5523 8.48163 19 9.03394 19Z" />

            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: "BranchIcon",
    props: ["svgColor", "svgHeight", "svgWidth"],
    data() {
        return {
            fill: this.svgColor,
            width: this.svgWidth,
            height: this.svgHeight,
        };
    },
    methods: {
        setFill(value) {
            this.fill = value;
        },
    },
};
</script>
