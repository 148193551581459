import Vue from 'vue';
import {setItemsToLocalStorage} from '../services/functions';
import { checkGetStartedCompleted } from '../utils/helper-functions';
import { GetStartedStepsEnum } from '../utils/constants/common';
const VUE_APP_AUTH_BASE_URL = process.env.VUE_APP_AUTH_BASE_URL;

export const state = () => ({
    isAuthenticatingNow: false,
    isTrialNotification: true,
    isMfaNotification: true,
    isVisible: true,
    sessionsList: [],
    sessionsCount: 0,
    trial: {
        isTrial: false,
        scansAreLeft: 0,
    },
    profileInfo: {},
    userType: null,
    isLoadingData: false,
    maxFreeScans: 3,
    authUserData: {},
    showMessageTrialReport: false,
    showDeleteReportNotification: false,
    getStartedSteps: [],
    isGetStartedCompleted: false,
    isFromGetStated: false,
    toGetStartedFrom: null,
    isGetStartedCongrats: false,
});
export const getters = {
    isAuthenticatingNow: s => s.isAuthenticatingNow,
    isTrialNotification: s => s.isTrialNotification,
    isMfaNotification: s => s.isMfaNotification,
    sessionsList: s => s.sessionsList,
    sessionsCount: s => s.sessionsCount,
    maxFreeScans: s => s.maxFreeScans,
    isVisible: s => s.isVisible,
    profileInfo: s => s.profileInfo,
    getTrial: s => s.trial,
    userType: s => s.userType,
    isLoadingData: s => s.isLoadingData,
    authUserData: s => s.authUserData,
    showMessageTrialReport: s => s.showMessageTrialReport,
    showDeleteReportNotification: s => s.showDeleteReportNotification,
    getStartedSteps: s => s.getStartedSteps,
    isGetStartedCompleted: s => s.isGetStartedCompleted,
    isFromGetStated: s => s.isFromGetStated,
    toGetStartedFrom: s => s.toGetStartedFrom,
    isGetStartedCongrats: s => s.isGetStartedCongrats,
};
export const mutations = {
    changeStatusAuthenticating: (state, status) => {
        state.isAuthenticatingNow = status;
    },
    toggleIsVisibleCookiesNotification: (state, status) => {
        state.isVisible = status;
    },
    setTrialNotification: (state, status) => {
        state.isTrialNotification = status;
    },
    setMfaNotification: (state, status) => {
        state.isMfaNotification = status;
    },
    setProfileInfo: (state, profile) => {
        state.profileInfo = profile;
    },
    setTrial(state, trial) {
        state.trial = trial;
    },
    setUserType(state, type) {
        if (Vue.$cookies.get('userType') !== type) {
            Vue.$cookies.set('userType', type);
        }
        state.userType = type;
    },
    toggleIsLoadingData: (state, status) => {
        state.isLoadingData = status;
    },
    setSessions: (state, sessions) => {
        state.sessionsList = sessions;
    },
    setSessionsCount: (state, count) => {
        state.sessionsCount = count;
    },
    setAuthUserData: (state, userData) => {
        state.authUserData = {...state.authUserData, ...userData};
    },
    setShowMessageTrialReport(state, show) {
        state.showMessageTrialReport = show;
    },
    setShowDeleteReportNotification(state, show) {
        state.showDeleteReportNotification = show;
    },
    setGetStartedSteps(state, steps) {
        state.getStartedSteps = steps;
    },
    setIsGetStartedCompleted(state, isCompleted) {
        state.isGetStartedCompleted = isCompleted;
    },
    setIsFromGetStated(state, isFromGetStated) {
        state.isFromGetStated = isFromGetStated;
    },
    setToGetStartedFrom(state, toGetStartedFrom) {
        state.toGetStartedFrom = toGetStartedFrom;
    },
    setIsGetStartedCongrats(state, isGetStartedCongrats) {
        state.isGetStartedCongrats = isGetStartedCongrats;
    },
};
export const actions = {
    async fetchUserInfo({commit}, page) {
        try {
            const {data} = await Vue.axios.get('/private/ui/users/info');
            setItemsToLocalStorage([['integrationsCount', data.integrationsCount]]);

            if (data.getStartedStepsCompleted) {
                const getStartedSteps = data.getStartedStepsCompleted.split(',');
                const isCongrats = data.isGetStartedCongrats;
                commit('setGetStartedSteps', getStartedSteps);
                commit('setIsGetStartedCompleted', checkGetStartedCompleted(getStartedSteps, Object.values(GetStartedStepsEnum)));
                commit('setIsGetStartedCongrats', isCongrats);
            }

            if (data.userId) {
                gtag('config', 'UA-171786448-1', {'user_id': `${data.userId}`});
            }
            if (data.trial) {
                commit('setTrial', data.trial);
            } else {
                commit('setTrial', {
                    isTrial: false,
                    scansAreLeft: 0,
                });
            }
            commit('setUserType', data.type);
        } catch (e) {
            console.log(e);
        }
    },
    async saveProfileInfo({commit}, profileInfo, info) {
        try {
            await Vue.axios.post('/private/ui/users/upgrade', profileInfo);
        } catch (e) {
            console.log(e);
        }
    },
    async sendBusinessContact({commit}, payload) {
        try {
            return await Vue.axios.post('/private/ui/contact/business', payload);
        } catch (e) {
            console.log(e);
        }
    },
    async sendQuestion({commit}, question) {
        try {
            return await Vue.axios.post('/private/ui/contact/question', {question, source: 'Portal'});
        } catch (e) {
            console.log(e);
        }
    },
    async updateProfileInfo({commit}, profileInfo, info) {
        try {
            await Vue.axios.post('/private/ui/profile', profileInfo);
        } catch (e) {
            console.log(e);
        }
    },
    async getProfileInfo({commit}, profileInfo) {
        try {
            commit('toggleIsLoadingData', true);
            const res = await Vue.axios.get('/private/ui/profile', profileInfo);
            commit('setProfileInfo', res.data);
            commit('toggleIsLoadingData', false);

        } catch (e) {
            console.log(e);
            commit('toggleIsLoadingData', false);
        }
    },
    async getUserSessions({commit}, payload) {
        try {
            const response = await Vue.axios.get(`${VUE_APP_AUTH_BASE_URL}/user/sessions?limit=${payload.limit}&page=${payload.page}`);
            commit('setSessions', response.data.rows);
            commit('setSessionsCount', response.data.count);
        } catch (e) {
            console.log(e);
        }
    },
    async deactivateAllExpiredSessions() {
        try {
            await Vue.axios.put(`${VUE_APP_AUTH_BASE_URL}/user/deactivate-expired-sessions`);
        } catch (e) {
            console.log(e);
        }
    },
    async revokeUserSessions({}, sessionId) {
        try {
            await Vue.axios.post(`${VUE_APP_AUTH_BASE_URL}/user/revoke-session`, {sessionId});
        } catch (e) {
            console.log(e);
        }
    },

    async checkUserRegistration({commit}, {invitedId}) {
        try {
            const res = await Vue.axios.get(`private/ui/profile/check-registration/${invitedId}`);

            commit('setAuthUserData', {...res.data, invitedId});
        } catch (err) {
            console.log(err);
            throw new Error(err);
        }
    },

    async updateGetStartedSteps({commit}, step) {
        try {
            await Vue.axios.post('/private/ui/users/get-started', {step});
        } catch (e) {
            console.log(e);
        }
    },
    async updateIsGetStartedCongrats({commit}) {
        try {
            await Vue.axios.post('/private/ui/users/congrats');
        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
