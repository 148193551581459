<template>
    <div class="branch-modal" v-click-outside="closeModalOutside">
        <div @click="closeModal" class="branch-modal__close-btn">
            <img src ="../../../assets/images/closeModal.svg" alt="close btn"/>
        </div>
        <div class="branch-modal__title">
            {{isEditModal ? 'Edit' : 'New'}} branch
        </div>
        <form @submit.prevent="handleForm" class="branch-modal-form__form">
            <div class="branch-modal__form-input-wrapper">
                <input
                    v-model.trim="formData.branchName"
                    id="branchName"
                    class="input"
                    type="text"
                    name="name"
                    @blur="activeInputName = null"
                    @input="handleInput"
                >
                <img v-if="formData.branchName.length > 0"
                    @click.stop="clearInputAndErrors('branchName')"
                    class="branch-modal-form__input-check"
                    src="../../../assets/images/cross_grey.svg"
                    alt="cross"
                >
                <label for="branchName" class="label" v-bind:class="{ activeInput: formData.branchName !== isEmptyInput || activeInputName === 'Branch name'}">Branch Name</label>
                <span id="branchError" class="branch-modal-form__error">
                    <img
                        @click="clearInputAndErrors('branchName')"
                        class="branch-modal-form__error-cross"
                        src="../../../assets/images/cross.svg"
                        alt="cross"
                    >
                    <span class="branch-modal-form__error-text">Required field</span>
                </span>
                <span id="branchValidationError" class="branch-modal-form__error">
                    <img
                        @click="clearInputAndErrors('branchName')"
                        class="branch-modal-form__error-cross"
                        src="../../../assets/images/cross.svg"
                        alt="cross"
                    >
                    <span class="branch-modal-form__error-text">Invalid branch name</span>
                </span>
                <span id="branchCommonError" class="branch-modal-form__error">
                    <img
                        @click="clearInputAndErrors('branchName')"
                        class="branch-modal-form__error-cross"
                        src="../../../assets/images/cross.svg"
                        alt="cross"
                    >
                    <span class="branch-modal-form__error-text">{{branchError}}</span>
                </span>
            </div>
            <b-form-checkbox
                v-if="isEditModal && !modalData.isDefault"
                class="branch-modal-form__checkbox"
                name="defaultBranch"
                v-model="formData.isDefault"
                value="true"
                unchecked-value="false"
            >
                Make Default
            </b-form-checkbox>

            <div class="branch-modal-form__btn-wrapper">
                <button type="submit" class="branch-modal-form__btn cabin cabin-bold">
                    {{isEditModal ? 'Save Changes' : 'Create Branch'}}
                </button>
            </div>
        </form>

        <div v-if="showSpinnerModal" class="branch-modal__spinner">
            <Spinner />
        </div>
    </div>
</template>

<script>
import Spinner from '../../Spinner'
import ClickOutside from 'vue-click-outside'
import {
    hideErrors,
    raiseError
} from '@/services/functions'
import {store} from '@/store'
export default {
    name: 'BranchModal',
    components: {
        Spinner
    },
    directives: {
        ClickOutside
    },
    data() {
        return {
            formData: {
                branchName: '',
                isDefault: false
            },
            isEmptyInput: "",
            activeInputName: null
        }
    },
    props: {
        modalData: Object,
        showSpinnerModal: Boolean,
        isEditModal: Boolean,
        branchError: String
    },
    beforeMount() {
        this.formData.branchName = this.modalData.branchName
        this.formData.isDefault = this.modalData.isDefault
    },
    watch: {
        branchError() {
            if (this.branchError) {
                return raiseError('branchName', 'branchCommonError', 'branch-modal-form__input--error')
            }
        }
    },
    methods: {
        clearInputAndErrors(inputField) {
            this.formData.branchName = ''
            store.commit('integrations/clearBranchError')
            hideErrors(['branchError', 'branchValidationError', 'branchCommonError'])
            document.getElementById(inputField).style.border = '1px solid #F0F0F0'
        },
        clearData() {
            this.formData.branchName = ''
            this.formData.isDefault = false
        },
        closeModal() {
            this.$emit('closeModal')
            this.clearData()
        },
        handleForm() {
            this.$store.commit('integrations/setBranchError', null)
            if (!this.formData.branchName) {
                return raiseError('branchName', 'branchError', 'branch-modal-form__input--error')
            }
            if (this.formData.branchName.length >= 28) {
                return raiseError('branchName', 'branchValidationError', 'branch-modal-form__input--error')
            }
            this.$emit('handleForm', this.formData)
        },
        checkKeyEvent(event) {
            this.activeInputName = event.target.id
        },
        handleInput(e) {
            this.checkKeyEvent(e)
            store.commit('integrations/clearBranchError')
        },
        closeModalOutside() {
            if (this.showSpinnerModal) return
            this.closeModal()
        }
    }
}
</script>

<style lang="scss">
@mixin modalForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    width: 100%;
    max-width: 382px;
    padding-bottom: 20px;
}
@mixin button {
    background: #FFB400;
    right: 0;
    border: 2px solid #FFB400;
    box-sizing: border-box;
    border-radius: 8px;
    color: #FFFFFF;
    transition: 0.3s;
    padding: 10px 40px;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    &:active {
        opacity: 1;
    }

    &:focus {
        border: 2px solid #FFB400;
    }
}
@mixin spinner {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,0.7);
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 100;
}
#branchError,
#branchValidationError,
#branchCommonError {
    display: none;
}
.branch-modal {
    @include modalForm;
    position: relative;

    &__close-btn {
        align-self: end;
        margin: 15px 15px 0 0;
        cursor: pointer;
        position: relative;
    }

    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        word-break: break-all;
        letter-spacing: .025em;
        margin: 5px 0 20px 0;
        max-width: 370px;
        line-height: 150%;
        text-align: center;
        color: #242424;
    }

    &__spinner {
        @include spinner;
        position: absolute;
        align-content: center;
        border-radius: 10px;
        max-width: 382px;
    }
    &__form {
        display: flex;
        position: relative;
        width: 100%;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 40px;
    }

    &__form-input-wrapper {
        position: relative;
        width: 255px;
    }

    &-form {
        &__input-check {
            position: absolute;
            bottom: 45px;
            right: 12px;
            cursor: pointer;
        }

        &__checkbox {
            margin-bottom: 10px;
            margin-left: 10px;
            top: -10px;
            font-size: 16px;
            label {
                padding-top: 0px;
            }
        }
        &__error {
            width: 100%;
            max-width: 831px;
            position: relative;
        }

        &__error-cross {
            position: absolute;
            right: 12px;
            top: -56px;
            cursor: pointer;
        }

        &__error-text {
            position: absolute;
            top: -30px;
            left: 3px;
            font-family: Cabin, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 150%;
            color: #FB8D62;
            margin: 0;
        }
        &__btn-wrapper {
            display: flex;
            justify-content: center;
        }
        &__btn {
            @include button;
        }
    }
}
</style>
