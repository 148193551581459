<template>
    <div class="card__wrapper">
        <label v-for="pack in packs" 
            :key="pack.id"
            :for="`scan-pack-${pack.id}`"
            class="card__pack"
        >
            <div class="card__header">
                {{pack.number}} scans
            </div>
            <div class="card__text">
                $ {{pack.price/100}} per scan
                <input 
                    ref="packInput"
                    type="checkbox"
                    :id="`scan-pack-${pack.id}`"
                    :name="`scan-pack-${pack.id}`"
                    :value="pack.id"
                    @click="toggle"
                >
                <span class="checkmark"></span>
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: 'ScanPackCards',
    props: ['packs', 'selectedPack'],
    data() {
        return {
            packChecked: this.selectedPack && this.selectedPack.id,
        }
    },
    mounted() {
        const packElements = this.$refs.packInput
        if (this.packChecked) {
            packElements.forEach(pack => {
                if (pack.value === this.packChecked) {
                    pack.checked = true
                }
            })
        } 
    },
    methods: {
        toggle({target}) {
            const packElements = this.$refs.packInput
            if (target.checked) {
                packElements.forEach(pack => {
                    if (pack.value !== target.value) {
                        pack.checked = false
                    }
                })
                this.packChecked = target.value
            } else {
                this.packChecked = false
            }
            this.$emit('pack', this.packChecked)
        },
    }
}
</script>

<style lang="scss">
@import "../../assets/css/variables";

label {
    margin: 0;
}

.card {
    &__wrapper {
        display: flex;
        gap: 20px;
        overflow: auto;
        white-space: nowrap;
        padding: 20px 10px;
    }
    &__header {
        font-size: 34px;
        font-weight: 700;
        color: $main-text;
    }
    &__pack {
        width: 100%;
        border: 2px solid #ADCCFB;
        border-radius: 20px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        user-select: none;
        transition: all .3s;
        & input {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
        }
        &:hover, &:has(input:checked) {
            cursor: pointer;
            border: 2px solid $main-blue-color;
            box-shadow: 0px 12px 16px rgba(51, 128, 244, 0.12);
            transition: all .3s;
            .checkmark {
                border: 2px solid $main-blue-color;
                box-shadow: 0px 12px 16px rgba(51, 128, 244, 0.12);
                transition: all .3s;
            }
        }
    }
    &__text {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 400;
        color: $ghost-text;
        .checkmark {
            position: absolute;
            right: 0;
            height: 16px;
            width: 16px;
            border: 2px solid #ADCCFB;
            border-radius: 50%;
            &:after {
                content: "";
                position: absolute;
                display: none;
                top: 1px;
                left: 1px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $main-blue-color;
            }
        }
        input:checked ~ .checkmark:after {
            display: block;
        }
    }
}

@media (max-width: 1500px) {
    .card {
        &__header {
            font-size: 22px;
        }
        &__pack {
            min-width: 170px;
        }
        &__text {
            font-size: 14px;
        }
    }
}
@media (max-width: 505px) {
    .card {
        &__header {
            font-size: 36px;
        }
        &__pack {
            min-width: 200px;
        }
    }
}
</style>
