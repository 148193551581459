<template>
    <div>
        <div
            v-if="isVulnerability()"
            class="wrapper"
        >
            <div
                v-if="highWidthPercent"
                class="line-elem red"
                :style="{ width: highWidthPercent + '%' }"
            />
            <div
                v-if="mediumWidthPercent"
                class="line-elem yellow"
                :style="{ width: mediumWidthPercent + '%' }"
            />
            <div
                v-if="lowWidthPercent"
                class="line-elem green"
                :style="{ width: lowWidthPercent + '%' }"
            />
        </div>
        <p
            v-else
            class="no-found"
        >
            No vulnerabilities found
        </p>
    </div>
</template>

<script>
export default {
    props: {
        highWidthPercent: {
            type: Number,
            default: 0,
        },
        mediumWidthPercent: {
            type: Number,
            default: 0,
        },
        lowWidthPercent: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        isVulnerability() {
            return !(!this.highWidthPercent && !this.mediumWidthPercent && !this.lowWidthPercent);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/fonts.scss';
@import '@/assets/css/variables.scss';

.wrapper {
    display: flex;
    height: 12px;
    width: 100%;
}

.no-found {
    margin: 0;
    font-family: Cabin,serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
}

.line-elem {
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 1.5px;
    margin-left: 1.5px;
    &.red {
        background: $background-orange-color;
        border: 1px solid $main-orange-color;
    }

    &.yellow {
        background: $background-yellow-color;
        border: 1px solid $main-yellow-color;
    }

    &.green {
        background: $background-green-color;
        border: 1px solid $main-green-color;
    }
}
</style>
